import { Store } from "@ngxs/store";
import { firstValueFrom } from "rxjs";
import { merge, pick } from 'lodash';

import * as API from "@hiptraveler/data-access/api";
import * as Itinerary from '..';
import { getWindowRef, globalStateManagementStoreKey } from "@hiptraveler/common";

export async function getItineraryByPageTitle(pageTitle: string, ItineraryApi: API.ItineraryApiService, callback: (basicInfo: API.BasicInfoData) => void): Promise<Partial<Itinerary.ItineraryStateModel> | any> {

  const store = getWindowRef()?.[globalStateManagementStoreKey] as Store;
  const basicInfoSnapshot = store?.selectSnapshot(Itinerary.ItineraryState.basicInfo);
  const snapshotResponse = { basicInfo: basicInfoSnapshot } as any;
  
  try {

    const basicInfoResponse = basicInfoSnapshot ? snapshotResponse : await firstValueFrom(ItineraryApi.getItineraryByPageTitle(pageTitle));
    const itineraryId = basicInfoResponse?.basicInfo?.id || '';
    const basicInfoList = [];
    const itineraryTrips = [];
    let moreResults = true;
    let count = 1;

    callback(basicInfoResponse?.basicInfo);

    do {
      const itineraryTrip = await firstValueFrom(ItineraryApi.getItineraryTripByIdAndCount(itineraryId, count));
      basicInfoList.push(itineraryTrip?.basicInfo || null);
      itineraryTrips.push(itineraryTrip?.itinerary?.actDateMap || null);
      moreResults = !!itineraryTrip?.itinerary?.moreResults;
      count++;
    } while (moreResults);

    basicInfoSnapshot || API.validateResponse(basicInfoResponse, { ignoreKey: 'basicInfo' });

    const basicInfoVale = {
      ...(basicInfoResponse?.basicInfo || basicInfoSnapshot),
      ...pick(basicInfoList[0], [ 'autoSummary', 'travelInfo', 'autoGenerated', 'pubDateString' ])
    };

    const baseResult: Partial<Itinerary.ItineraryStateModel> = {
      basicInfo: basicInfoVale,
      actDateMap: merge({}, ...itineraryTrips),
      travelAgents: []
    };

    if (basicInfoResponse?.basicInfo?.hasItinerary) {
      if (!getWindowRef()?.location?.pathname?.includes('/compose/itinerary/')) {
        const taResponse = await firstValueFrom(ItineraryApi.getTravelAgentList(itineraryId)).catch(() => null);
        baseResult.travelAgents = taResponse?.travelAgents || [];
      }
      return baseResult;
    }

    return baseResult;
  } catch (value: any) {

    return basicInfoSnapshot
      ? { basicInfo: basicInfoSnapshot }
      : { error: value?.error };
  }
}
