import { Injectable } from '@angular/core';
import { filter, Observable, tap } from 'rxjs';

import { EFAStateServiceService, SummaryItems } from '../../shared';

@Injectable()
export class SummaryService {

  private pageVisited: boolean = false;

  constructor(
    private stateService: EFAStateServiceService
  ) { }
  
  get summaryItems$(): Observable<SummaryItems> {
    return this.stateService.summaryItems$;
  }
  
  get summaryItems(): SummaryItems {
    return this.stateService.summaryItems$$.value;
  }

  screenExitObserver(callback: (requesting: boolean) => void): void {
    this.stateService.screenViewValue$.pipe(
      filter(e => e !== 'screen8' && this.pageVisited),
      tap(() => {
        callback(false);
        this.pageVisited = false;
        this.stateService.pauseBrandInfoUXState$$.next(true);
        this.stateService.summaryItems$$.next([]);
      })
    ).subscribe();
  }

}
