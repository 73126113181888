import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { filter } from 'rxjs';

import { FilestackService } from '@hiptraveler/core/filestack';
import { CoverImageService } from './cover-image.service';

@Component({
  selector: 'cover-image',
  templateUrl: './cover-image.component.html',
  styleUrls: ['./cover-image.component.scss', './cover-image-responsiveness.scss'],
  viewProviders: [ FilestackService, CoverImageService ]
})
export class CoverImageComponent implements OnInit {

  @Input() dialog: MatDialogRef<any>;

  coverImage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup,
    public service: CoverImageService
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.form.patchValue(this.form.value));

    this.service.coverImage$.pipe(filter(Boolean)).subscribe((url: string | null) => {
      this.coverImage = url!;
    });

  }

}
