import { Component, ElementRef, ViewChild } from '@angular/core';

import { DestinationSpecialtiesService } from './destination-specialties.service';
import { SearchLocationData } from '@hiptraveler/common';

@Component({
  selector: 'destination-specialties',
  templateUrl: './destination-specialties.component.html',
  styleUrls: ['./destination-specialties.component.scss'],
  host: { class: 'ta-register-registration-form-inline' },
  viewProviders: [ DestinationSpecialtiesService ]
})
export class DestinationSpecialtiesComponent {

  @ViewChild('searchLocation') input: ElementRef<HTMLInputElement>;

  constructor(
    public service: DestinationSpecialtiesService
  ) { }

  async locationChanges(locationData: SearchLocationData): Promise<void> {
    this.input.nativeElement.value = '';
    this.input.nativeElement.disabled = true;
    await this.service.appendLocation(locationData, this.input);
    this.input.nativeElement.disabled = false;
  }

}
