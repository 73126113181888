import * as API from '@hiptraveler/data-access/api';
import { ProfileStateField } from './profile.model';

export class GetProfileDetails {
  static readonly type = '[Profile State] GetProfileDetails ';
  constructor(public userId: string) { }
}

export class GetItineraryResults {
  static readonly type = '[Profile State] GetItineraryResults ';
  constructor(public data: API.GetProfileSearchResultsDto) { }
}

export class GetProfileStoryResults {
  static readonly type = '[Profile State] GetProfileStoryResults ';
  constructor(public data: API.GetProfileSearchResultsDto) { }
}

export class GetProfileFavoriteResults {
  static readonly type = '[Profile State] GetProfileFavoriteResults ';
  constructor(public data: API.GetProfileSearchResultsDto) { }
}

export class GetProfileUploadResults {
  static readonly type = '[Profile State] GetProfileUploadResults ';
  constructor(public data: API.GetProfileSearchResultsDto) { }
}

export class GetTravelAgent {
  static readonly type = '[Profile State] GetTravelAgent ';
  constructor(public travelAgentId: string) { }
}

export class GetAmbassador {
  static readonly type = '[Profile State] GetAmbassador ';
  constructor(public ambassadorId: string) { }
}

export class UpdateProfileContent {
  static readonly type = '[Profile State] UpdateProfileContent';
  constructor(public data: API.UpdateProfileDto) { }
}

export class UpdatePassword {
  static readonly type = '[Profile State] UpdatePassword';
  constructor(public data: API.UpdatePasswordDto) { }
}

export class UpdateProfileSettings {
  static readonly type = '[Profile State] UpdateProfileSettings';
  constructor(public data: API.UpdateProfileSettingsDto) { }
}

export class UpdatePlacesVisited {
  static readonly type = '[Profile State] UpdatePlacesVisited';
  constructor(public data: API.UpdatePlacesVisitedDto) { }
}

export class SaveTravelAgent {
  static readonly type = '[Profile State] SaveTravelAgent';
  constructor(public data: API.SaveTravelAgentDto) { }
}

export class SaveAmbassador {
  static readonly type = '[Profile State] SaveAmbassador';
  constructor(public data: API.SaveAmbassadorDto) { }
}

export class SavePlaceDetails {
  static readonly type = '[Profile State] SavePlaceDetails';
  constructor(public data: API.TourDataDto) { }
}

export class FollowProfileById {
  static readonly type = '[Profile State] FollowProfileById';
  constructor(public profileId: string) { }
}

export class ToggleProfileResultLike {
  static readonly type = '[Profile State] ToggleProfileResultLike';
  constructor(public data: API.ToggleLikeItineraryDto<API.ProfileResultField>) { }
}

export class VerifyStateWithAuthStateChanges {
  static readonly type = '[Profile State] VerifyStateWithAuthStateChanges';
  constructor(public authUserId: string, public authFollowing: string[]) { }
}

export class DeleteAccountProfileById {
  static readonly type = '[Profile State] DeleteAccountProfileById';
  constructor(public data: API.DeleteAccountDto) { }
}

export class GetFollowingUserResults {
  static readonly type = '[Profile State] GetFollowingUserResults';
  constructor(public data: API.GetProfileSearchResultsDto) { }
}

export class GetFollowersUserResults {
  static readonly type = '[Profile State] GetFollowersUserResults';
  constructor(public data: API.GetProfileSearchResultsDto) { }
}

export class PartialResetProfileState {
  static readonly type = '[Profile State] PartialResetProfileState';
  constructor(public fields: ProfileStateField[]) { }
}

export class ResetProfileState {
  static readonly type = '[Profile State] ResetProfileState';
  constructor() { }
}
