import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { StateChange } from 'ng-lazyload-image';

import { DialogType } from '../../common';

@Component({
  selector: 'asset-image',
  templateUrl: './asset-image.component.html',
  styleUrls: ['./asset-image.component.scss'],
  host: { class: 'search-result-dialog-asset-image' },
  encapsulation: ViewEncapsulation.None
})
export class AssetImageComponent implements AfterViewInit {

  @Output() selected = new EventEmitter<void>();

  @Input() dialogType: DialogType;
  @Input() sourceImg: string;
  @Input() size: string = 'default';
  @Input() highlightImage?: string;
  @Input() bannerImage?: string;
  @Input() sliderImage?: string;
  @Input() selectImage?: string;
  @Input() imageAlt?: string = 'Highlight image';

  loader: boolean = true;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private cdRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    const width = this.elementRef.nativeElement.offsetWidth;

    const highlightPlaceholder = this.elementRef.nativeElement.querySelectorAll('.asset-image--highlight-image')[0] as HTMLElement;

    if (highlightPlaceholder && this.highlightImage === '') {
      highlightPlaceholder.style.width = `${width}px`;
    }
  }

  lazyloadStateChangeFn(event: StateChange): void {
    switch (event.reason) {
      case 'setup':
      case 'observer-emit':
      case 'start-loading': {
        this.loader = true;
        break;
      }
      case 'mount-image':
      case 'loading-succeeded':
      case 'loading-failed': {
        this.loader = false;
        break;
      }
    }
    this.cdRef.detectChanges();
  }

}
