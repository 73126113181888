import { ChatCompletionMessageParam } from "openai/resources";
import { BehaviorSubject, firstValueFrom, from, map, Observable } from "rxjs";

import { Conversation } from ".";
import { markdownToHTML } from "@hiptraveler/common";

export function call<T>(promise: T): T {
  return firstValueFrom(from(promise as any)) as any;
}

export function promptToCompletionParam(prompts: string[]): ChatCompletionMessageParam[] {
  return prompts.map((content: string) => ({
    role: 'system', content
  }))
}

export function toMessages(conversations: Conversation[]): ChatCompletionMessageParam[] {
  return conversations
    .filter(conversation => !conversation.system) 
    .map(({ message, sender }: Conversation) => ({
      role: sender ? 'user' : 'assistant',
      content: message
    }));
}

export function parseConversations(conversations$$: BehaviorSubject<Conversation[]>): Observable<Conversation[]> {
  return conversations$$.pipe(
    map(conversations => conversations.map((conversation: Conversation) => ({
      ...conversation, message: markdownToHTML(conversation.message)
    })))
  );
}
