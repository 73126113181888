import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { DialogType } from '../common';

@Component({
  selector: 'result-details-overlay',
  template: `
    <activity-details [data]="image" [dialogType]="dialogType" component=""></activity-details>
  `,
  styleUrls: ['./result-details-overlay.component.scss'],
  host: { class: 'search-result-dialog-result-details-overlay' },
  encapsulation: ViewEncapsulation.None
})
export class ResultDetailsOverlayComponent {

  @Input() image: ImageResultData;
  @Input() dialogType: DialogType;

}
