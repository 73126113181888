import { StateContext } from "@ngxs/store";
import { EMPTY, OperatorFunction, catchError } from "rxjs";

import { subdomain } from "@hiptraveler/common";
import { SearchStateField } from "../search.model";

export function catchNoResultFound(ctx: StateContext<any>, field: SearchStateField): OperatorFunction<any, any> {

  if (field === 'travelAgents' && subdomain() === 'hiptraveler') {
    ctx.patchState({ [field]: [], pagination: { moreResults: false, page: -1 } });
    return catchError(() => EMPTY);
  }

  return catchError((response) => {
    response?.error === 'No Results found' && ctx.patchState({
      [field]: [], pagination: { moreResults: false, page: -1 }
    });
    return EMPTY;
  });
}
