import { AuthInterceptor } from './auth.interceptor';
import { CookieInterceptor } from './cookie.interceptor';
import { SearchRequestInterceptor } from './search-request.interceptor';

import { RequestCancellationInterceptor as _RequestCancellationInterceptor } from './request-cancellation.interceptor';
import { DynamicRequestCancellationInterceptor } from './dynamic-request-cancellation.interceptor';
import { SearchRequestCancellationInterceptor } from './search-request-cancellation.interceptor';
import { ProfileSearchRequestCancellationInterceptor } from './profile-search-request-cancellation.interceptor';
import { ApiInterceptor } from './api.interceptor';

const RequestCancellationInterceptor = [
  _RequestCancellationInterceptor,
  DynamicRequestCancellationInterceptor,
  SearchRequestCancellationInterceptor,
  ProfileSearchRequestCancellationInterceptor,
  ApiInterceptor
];

export const AppInterceptors = [
  AuthInterceptor,
  CookieInterceptor,
  SearchRequestInterceptor,
  RequestCancellationInterceptor
];
