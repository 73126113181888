<div class="contact-info ht-dialog-elevation">

  <ng-container *ngIf="((hotelInformation$ | async)?.addInfo || (imageInformation$ | async)?.addInfo) as info;else inProgress">
    <h2 translate="search-result.contact-information"></h2>
  
    <div class="contact-info--item-list">
      <div class="contact-info--item item-website item-link" [externalLink]="info?.website" *ngIf="info?.website">
        <mat-icon svgIcon="search-result-dialog-result-content.website"></mat-icon>
        <p>{{ info?.website | cleanUrlText }}</p>
      </div>
      <div class="contact-info--item item-phone" *ngIf="info?.phone || info?.phone_alt">
        <mat-icon svgIcon="search-result-dialog-result-content.phone"></mat-icon>
        <p>{{ info?.phone || info?.phone_alt }}</p>
      </div>
      <a class="contact-info--item item-email item-link" [href]="'mailto:'+info?.email" *ngIf="info?.email">
        <mat-icon svgIcon="search-result-dialog-result-content.email"></mat-icon>
        <p>{{ info?.email | cleanUrlText }}</p>
      </a>
      <div class="contact-info--item item-whatsapp" *ngIf="info?.whatsapp">
        <mat-icon svgIcon="search-result-dialog-result-content.whatsapp"></mat-icon>
        <p>{{ info?.whatsapp }}</p>
      </div>
      <div class="contact-info--item item-twitter_x item-link" [externalLink]="info?.twitter" *ngIf="info?.twitter">
        <mat-icon svgIcon="search-result-dialog-result-content.twitter-x"></mat-icon>
        <p>{{ info?.twitter | cleanUrlText }}</p>
      </div>
      <div class="contact-info--item item-facebook item-link" [externalLink]="info?.facebook" *ngIf="info?.facebook">
        <mat-icon svgIcon="search-result-dialog-result-content.facebook"></mat-icon>
        <p>{{ info?.facebook | cleanUrlText }}</p>
      </div>
      <div class="contact-info--item item-instagram item-link" [externalLink]="info?.instagram" *ngIf="info?.instagram">
        <mat-icon svgIcon="search-result-dialog-result-content.instagram"></mat-icon>
        <p>{{ info?.instagram | cleanUrlText }}</p>
      </div>
      <div class="contact-info--item item-pinterest item-link" [externalLink]="info?.pinterest" *ngIf="info?.pinterest">
        <mat-icon svgIcon="search-result-dialog-result-content.pinterest"></mat-icon>
        <p>{{ info?.pinterest | cleanUrlText }}</p>
      </div>
    </div>
  </ng-container>
  <ng-template #inProgress>
    <h2 translate="search-result.contact-information"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate"></mat-progress-spinner>
  </ng-template>
    
</div>
