import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ExternalLinkDirectiveModule } from '@hiptraveler/common';

import { ContactInformationComponent } from './contact-information.component';
import { CleanUrlTextPipe } from './clean-url-text.pipe';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ExternalLinkDirectiveModule
];

const COMPONENTS = [
  ContactInformationComponent
];

const PIPES = [
  CleanUrlTextPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    ContactInformationComponent
  ]
})
export class ContactInformationModule { }
