import { filter, has, omit, uniqBy } from 'lodash';
import { GeoJSONFeatureCollection, GeoJSONProperties, SearchResultData } from "@hiptraveler/common";
import { GeoJsonProp } from './leaflet-map.interface';

export function toGeoJSON(results: SearchResultData[]): GeoJSONFeatureCollection<GeoJsonProp> {
  return {
    type: 'FeatureCollection',
    features: results.map((result: SearchResultData) => ({
      type: 'Feature',
      geometry: {  ...(result.location as any) },
      properties: {
        ...omit(result, [ 'location', 'address' ]) as SearchResultData,
        ...result.address,
        location: result.locationName,
        id: result.id
      } as GeoJsonProp
    }))
  };
}

export function calculateMiddlePoint(coordinates: number[]): number[] {
  const latitudes = coordinates.map((coord: any) => coord?.[0] || 0);
  const longitudes = coordinates.map((coord: any) => coord?.[1] || 0);

  const avgLatitude = latitudes.reduce((acc: any, lat: any) => acc + lat, 0) / latitudes.length;
  const avgLongitude = longitudes.reduce((acc: any, lon: any) => acc + lon, 0) / longitudes.length;

  return [ avgLatitude, avgLongitude ].reverse();
}

export function parseTextData(value: string): string {
  value = value && !value.includes('undefined') ? value : '-';
  return value;
}

export function parseGeoJson(result: GeoJSONFeatureCollection<GeoJSONProperties>): void {

  result.features = uniqBy(result.features, feature =>
    feature?.geometry?.coordinates?.join(',')
  );

  result.features = filter(result.features, feature => 
    has(feature.geometry, 'coordinates')
  );
}
