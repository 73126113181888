import { Component, OnInit } from '@angular/core';

import { SiteNavigationService } from './site-navigation.service';
import { NavbarControlStateService, AppListenerService, WidgetStateService } from '@hiptraveler/common';
import { slideInFromTop } from '@hiptraveler/animations';

@Component({
  selector: 'app-site-navigation',
  templateUrl: './site-navigation.component.html',
  styleUrls: ['./site-navigation.component.scss'],
  viewProviders: [ SiteNavigationService ],
  animations: [ slideInFromTop(300) ]
})
export class SiteNavigationComponent implements OnInit {

  mobileView: boolean;
  navbarVisibility: boolean;

  constructor(
    public service: SiteNavigationService,
    public widgetService: WidgetStateService,
    public navbarControl: NavbarControlStateService,
    public appListener: AppListenerService
  ) { }

  ngOnInit(): void {

    this.appListener.clientWidth$.subscribe((width: number) => {
      this.mobileView = width < 700;
    });

    this.service.navbarVisibility$.subscribe((state: boolean) => {
      this.navbarVisibility = state
    });
  }

}
