import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, switchMap } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { clientVID } from '@hiptraveler/common';

@Pipe({
  name: 'imageDataButtonName'
})
export class ImageDataButtonNamePipe implements PipeTransform {

  constructor(
    private store: Store,
    private translate: TranslateService
  ) { }

  transform(value: ImageResultData): Observable<string> {
    return this.store.select(UserState.authenticated).pipe(
      map((authenticated: boolean) => {
        const visitorOwner = this.store.selectSnapshot(ItineraryState.basicInfo)?.author?.authorProfId === clientVID()
        if (authenticated || visitorOwner) {
          return value.imgCategory === 'Chill time' 
            ? 'itinerary.button-text-add-activity'
            : 'itinerary.button-text-private-note';
        } else {
          return 'itinerary.add-trip';
        }
      }),
      switchMap((v: string) => this.translate.get(v))
    );
  }

}

@Pipe({
  name: 'hotelDataButtonName'
})
export class HotelDataButtonNamePipe implements PipeTransform {

  constructor(
    private store: Store,
    private translate: TranslateService
  ) { }

  transform(_: unknown): Observable<string> {
    return this.store.select(UserState.authenticated).pipe(
      map((authenticated: boolean) => {
        const visitorOwner = this.store.selectSnapshot(ItineraryState.basicInfo)?.author?.authorProfId === clientVID()
        return authenticated || visitorOwner ? 'itinerary.button-text-private-note' : 'itinerary.add-trip';
      }),
      switchMap((v: string) => this.translate.get(v))
    );
  }

}
