import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResultDetailsOverlayComponent } from './result-details-overlay.component';
import { ActivityDetailsModule } from './../result-details/activity-details/activity-details.module';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ActivityDetailsModule
];

const COMPONENTS = [
  ResultDetailsOverlayComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ResultDetailsOverlayComponent
  ]
})
export class ResultDetailsOverlayModule { }
