<div class="search-results">

  <profile-card
    [active]="!!(community.profId | following | async)"
    [processing]="!!(communityService.processing$ | processing: community.profId| async)"
    [profileCardHover]="community"
    (profileClicked)="communityService.navigateToProfile(community)"
    (followClicked)="communityService.followByResultData(community)"
    *ngFor="let community of dataAccess.followingResults$ | async;" [@opacityFade]>
    <img profileImage [lazyLoad]="community.profPic" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" [alt]="community.profName">
    <profile-name>{{ community.profName }}</profile-name>
    <profile-location>{{ community.profLoc }}</profile-location>
    <profile-action-button [active]="!!(community.profId | following | async)"></profile-action-button>
  </profile-card>
  
  <ng-container *ngIf="!(dataAccess.followingResults$ | async)">
  
    <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
    </ngx-skeleton-loader>
  
  </ng-container>
  
  <ng-container *ngIf="(dataAccess.followingResults$ | async)?.length === 0">
    <p class="ht-no-results" style="text-align: center;" translate="profile.no-following-yet"></p>
  </ng-container>

</div>
