import { NgModule } from '@angular/core';

import { ToggleFavoriteDirective } from './toggle-favorite.directive';

const DIRECTIVES = [
  ToggleFavoriteDirective
];

@NgModule({
  declarations: DIRECTIVES,
  exports: [ ToggleFavoriteDirective ]
})
export class ToggleFavoriteDirectiveModule { }
