import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, of } from 'rxjs';

import { ActivityDate, currentLang, SearchPageControlStateService } from '@hiptraveler/common';

@Pipe({
  name: 'addedToCurrentDay'
})
export class AddedToCurrentDayPipe implements PipeTransform {

  constructor(
    private router: Router,
    private searchPageControl: SearchPageControlStateService
  ) { }

  transform(activityId: string): Observable<boolean> {

    if (this.router.url.includes(`/${currentLang()}/itinerary/`)) return of(true);

    return this.searchPageControl.activityDate$.pipe(
      map((activityDate: ActivityDate | null) => {
        const hotelContents = !!activityDate?.actDateMap?.[activityDate?.day!].HotelArray?.some(e => e.id === activityId);
        const imageContents = !!activityDate?.actDateMap?.[activityDate?.day!].ImgArray?.some(e => e.id === activityId);
        return !(hotelContents || imageContents)
      })
    );
  }

}
