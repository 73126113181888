<ng-container *ngIf="userAuthenticated$ | async;else defaultView">
  <ng-container *ngIf="itineraries$ | async as itineraries;else emptyList">

    <!-- Itineraries -->
    <div class="menu--display-itineraries">
      <div class="menu--display-itinerary"
        *ngFor="let itinerary of itineraries;let isFirst = first;let isLast = last"
        [ngClass]="{ first: isFirst, last: isLast }"
        [class.active]="false"
        (click)="selectItinerary(itinerary)">
        <mat-icon svgIcon="experience-finder-screen-shared.itinerary-icon-svg"></mat-icon>
        <p>{{ itinerary.title }}</p>
      </div>
    </div>

  </ng-container>
</ng-container>

<ng-template #defaultView>
  <ng-container *ngIf="authenticated$ | async;else emptyList">
    <mat-progress-spinner mode="indeterminate" class="primary"></mat-progress-spinner>
  </ng-container>
</ng-template>

<ng-template #emptyList>

  <ng-container *ngIf="itineraries$ | async as itineraries;else planTripView">

    <!-- Itineraries -->
    <div class="menu--display-itineraries">
      <div class="menu--display-itinerary"
        *ngFor="let itinerary of itineraries;let isFirst = first;let isLast = last"
        [ngClass]="{ first: isFirst, last: isLast }"
        [class.active]="false"
        (click)="selectItinerary(itinerary)">
        <mat-icon svgIcon="experience-finder-screen-shared.itinerary-icon-svg"></mat-icon>
        <p>{{ itinerary.title }}</p>
      </div>
    </div>

  </ng-container>
  <ng-template #planTripView>

  <!-- Plan a new Trip - action button -->
  <div class="menu--display-action" (click)="stateService.dialogView$$.next('plan-trip')">
    <p translate="exp-finder.plan-new-trip"></p>
  </div>
  <!-- Plan a new Trip - icon -->
  <div class="menu--display-photo" [ngStyle]="{
    'background-image': tripsImage$ | async,
    'background-size': '80% !important',
    'background-position-x': 'center !important',
    'background-position-y': '110% !important'
  }"></div>

  </ng-template>

</ng-template>
