import { PickerOptions } from "./filestack.model";

import { currentLang } from "@hiptraveler/common";

export const defaultClientOptions: PickerOptions = {
  accept: [ "image/*" ],
  minFiles: 1,
  maxFiles: 1,
  maxSize: 1024 * 1024 * 200,
  concurrency: 1,
  errorsTimeout: 9000,
  viewType: 'grid',
  supportEmail: 'swati@hiptraveler.com',
  uploadInBackground: false,
  lang: currentLang()
}
