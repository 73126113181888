import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CategoriesService } from './categories.service';

@Component({
  selector: 'categories',
  template: `
    <div class="category--checkboxes">
      <mat-checkbox *ngFor="let category of service.categories;" [value]="category.label" (change)="checkboxChanged($event.checked, category.label)">{{ category.translation | translate }}</mat-checkbox>
    </div>
  `,
  styleUrls: ['./categories.component.scss'],
  viewProviders: [ CategoriesService ],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup,
    public service: CategoriesService
  ) { }

  checkboxChanged(checked: boolean, label: string) {

    const amenities = this.form.get('gygTour')?.get('amenities')! as FormArray;
    const value: string[] = amenities.value || [];

    checked 
      ? amenities.push(new FormControl(label))
      : amenities.removeAt(value.indexOf(label))
  }

}
