import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DontSellInfoModule as _DontSellInfoModule } from '@hiptraveler/ui/dont-sell-info';

import { DontSellInfoComponent } from './dont-sell-info.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  TranslateModule
];

const LIBRARIES = [
  _DontSellInfoModule
];

const COMPONENTS = [
  DontSellInfoComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: DontSellInfoComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ],
})
export class DontSellInfoModule { }
