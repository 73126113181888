import { Injectable } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';

import { LIST_PANEL_PANEL_BACKDROP_CLASS, LIST_PANEL_PANEL_CLASS } from '@hiptraveler/common';

@Injectable()
export class ListOverlayService {

  constructor(
  ) { }

  initializeOverlay(overlay: CdkConnectedOverlay, travelStyle: string, callback: (visibility: boolean) => void): void {

    if (!overlay) return;

    overlay.width = '232px';
    overlay.hasBackdrop = true;
    overlay.panelClass = LIST_PANEL_PANEL_CLASS + '-filter-overlay';
    overlay.backdropClass = LIST_PANEL_PANEL_BACKDROP_CLASS + '-filter-overlay';
    overlay.offsetX = 80;
    overlay.offsetY = 400/2 - 20/2;
    overlay.height = 360;
    overlay.width = 460;
    
    if (travelStyle === '') {
      overlay.offsetX = 180;
      overlay.offsetY = -130;
    }

    overlay.lockPosition = true;

    overlay.attach.subscribe(() => {
    });
    
    overlay.detach.subscribe(() => {
      callback(false);
    });
    
    overlay.backdropClick.subscribe(() => {
      callback(false);
    });
  }

}
