
<feature-card itineraryCard toggleFavorite="itineraries" [id]="itinerary.id" [itinerary]="itinerary" *ngFor="let itinerary of service.itineraries$ | async">

  <img featureCardImage [lazyLoad]="itinerary.coverImage.imgUrl" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="itinerary.pageTitle" [@opacityFade]>
  <feature-card-date>{{ itinerary.numDays }}</feature-card-date>
  <img featureCardItineraryImage [lazyLoad]="itinerary?.author?.authorProfPic!" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" [alt]="itinerary.pageTitle">
  <feature-card-itinerary-title>{{ itinerary.title }}</feature-card-itinerary-title>
  <feature-card-itinerary-snippet [class.no-content]="!itinerary.snippet">{{ itinerary.snippet }}</feature-card-itinerary-snippet>

</feature-card>

<ng-container *ngIf="!(service.itineraries$ | async)">

  <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
  </ngx-skeleton-loader>

</ng-container>

<ng-container *ngIf="(service.itineraries$ | async)?.length === 0">
  <blank-itinerary></blank-itinerary>
</ng-container>
