import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { LanguageSupportService } from './language-support.service';

@Component({
  selector: 'language-support',
  templateUrl: './language-support.component.html',
  styleUrls: ['./language-support.component.scss'],
  host: { class: 'ta-register-registration-form-inline' },
  viewProviders: [ LanguageSupportService ],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSupportComponent implements OnInit {

  constructor(
    public service: LanguageSupportService
  ) { }

  ngOnInit(): void {
    this.service.initializeFormObserver();
    this.service.formValue$.subscribe((value: string[]) => {
      this.service.languages = this.service.languages.map(item => {
        item.checked = value.includes(item.code);
        return item;
      });
    });
  }

}
