import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondDuration'
})
export class SecondDurationPipe implements PipeTransform {

  transform(seconds: number): string {
    if (seconds < 60) {
      return `${seconds} secs`;
    } else if (seconds < 3600) {
      const mins = Math.floor(seconds / 60);
      return `${mins} min${mins > 1 ? 's' : ''}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hr${hours > 1 ? 's' : ''}`;
    }
  }

}

@NgModule({
  declarations: [ SecondDurationPipe ],
  exports:      [ SecondDurationPipe ]
})
export class SecondDurationPipeModule { }
