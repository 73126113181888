<div class="navbar--actions">

  <ng-container *ngFor="let button of (navbarControl.activeNavbarActionButtons$ | async);let i = index">

    <!-- Button markup if configured as an icon button -->
    <div class="navbar--action-icon-button" [executeNavbarAction]="button" [class.disabled]="!(navbarControl.navbarActionButtonState$ | async)" (pendingButton)="pendingButton = $event" *ngIf="button?.icon;else default">
      <mat-icon class="navbar--action-icon-button-handle" [svgIcon]="'navbar.'+button?.icon!"></mat-icon>
    </div>

    <ng-template #default>
      <div
        class="navbar--action-button"
        [executeNavbarAction]="button"
        [class]="button?.size+' '+button?.theme+' '+i" 
        [class.responsive]="button?.responsiveIcon"
        [class.responsive-icon]="button?.responsiveIcon"
        [class.responsive-text]="button?.responsiveText"
        [class.disabled]="!(navbarControl.navbarActionButtonState$ | async)"
        (pendingButton)="pendingButton = $event">

        <!-- Button text name -->
        <p matRipple class="navbar--action-button-text">{{ (button?.name || '') | translate }}</p>
        
        <!-- Responsive Button text name -->
        <ng-container *ngIf="button && button.responsiveText && (button.name !== pendingButton) && (responsiveText$ | async)">
          <p matRipple class="navbar--action-button-text responsive ">{{ (button?.responsiveText || '') | translate }}</p>
        </ng-container>

        <!-- Close icon divider -->
        <div class="navbar--action-button-divider" *ngIf="button?.closeButton"></div>

        <!-- Close icon if the button is configured as having a close icon button. -->
        <div class="navbar--action-button-event" matRipple *ngIf="button?.closeButton">
          <mat-icon>close</mat-icon>
        </div>

        <!-- Spinner when processing -->
        <mat-progress-spinner mode="indeterminate" [diameter]="20" [strokeWidth]="3"></mat-progress-spinner>

        <!-- The icon of the button, if the button is configured  -->
        <!-- as a button without text and only as a button with an icon inside. -->
        <ng-container *ngIf="button && button.responsiveIcon">
          <mat-icon class="navbar--action-button-responsive-icon" [svgIcon]="'navbar.'+button.responsiveIcon"></mat-icon>
        </ng-container>

      </div>
    </ng-template>

  </ng-container>

</div>
