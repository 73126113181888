import { Component, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { opacityFadeIn } from '@hiptraveler/animations';
import { ListView } from '../common';

@Component({
  selector: 'highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss'],
  animations: [ opacityFadeIn() ]
})
export class HighlightsComponent extends ListView {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup
  ) {
    super()
  }

  get formArray(): FormArray {
    return this.form.get('highlights') as FormArray;
  }

}
