import { Directive, HostListener, Inject, Input } from '@angular/core';
import { Store } from '@ngxs/store';

import { WINDOW } from '@hiptraveler/common';
import { Selection } from '../common';
import { BrandState } from '@hiptraveler/data-access/brand';

@Directive({
  selector: '[socialMediaButtonAction]'
})
export class SocialMediaButtonActionDirective {

  @Input() socialMediaButtonAction: Selection;

  constructor(
    @Inject(WINDOW) private window: any,
    private store: Store
  ) { }

  @HostListener('click')
  hostClicked(): void {

    const brand = this.store.selectSnapshot(BrandState.campaignCustom)?.socialLinks?.[this.socialMediaButtonAction] || ''

    switch (this.socialMediaButtonAction) {
      case 'facebook': {
        this.window.open(`https://www.facebook.com/${brand}/`, '_blank');
        break;
      }
      case 'youtube': {
        this.window.open(`https://www.youtube.com/${brand}/`, '_blank');
        break;
      }
      case 'twitter': {
        this.window.open(`https://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=${brand}&original_referer=${window.location.origin}/`, '_blank');
        break;
      }
    }
  }

}
