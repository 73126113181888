import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subject, catchError, finalize, map, takeUntil, tap } from 'rxjs';

import { MessagingApiService } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AppListenerService, CDNReaderService, RegexPattern, WINDOW, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';

type OptionAction = 'blog-creator' | 'trip-planner' | 'tourism-board-solutions' | 'destination-specialist-program' | 'become-ambassador' | 'media' | 'jobs' | 'copyright';

@Injectable()
export class FooterService {

  processingNewSignUp: boolean;
  authHandle$ = new Subject<void>();

  form: FormGroup = this.fb.group({
    name: [ '', [ Validators.required, Validators.pattern(RegexPattern.profileName) ] ],
    email: [ '', [ Validators.required, Validators.pattern(RegexPattern.email) ] ]
  });

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(WINDOW) private window: any,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private messagingApi: MessagingApiService,
    public efStateService: ExperienceFinderStateService,
    private i18n: TranslateService,
    private snackbar: SnackbarService,
    private cdnReader: CDNReaderService
  ) { }

  get footerImage$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.footer').pipe(
      map((e: any) => e.value)
    );
  } 

  stateObserver(): void {
    this.appListener.navigationStart$.subscribe(() => {
      this.resetAuthHandle();
    });
  }

  navigate(path: string): void {
    this.router.navigate([ currentLang(), path ]);
  }

  subscribe(): void {
   
    this.form.invalid && this.snackbar.open({ message: 'Invalid input format. ', duration: 5000 });

    if (this.processingNewSignUp || this.form.invalid) return;
    this.processingNewSignUp = true;

    this.messagingApi.newSignUp(this.form.value).pipe(
      finalize(() => {
        this.processingNewSignUp = false;
      }),
      catchError(() => {
        this.snackbar.open({ message: 'Something went wrong. If the issue persists, reach out to support@hiptraveler.com for assistance.', duration: 5000 });
        return EMPTY;
      })
    ).subscribe(() => {
      this.snackbar.open({ message: this.i18n.translations?.[currentLang()]?.['snackbar']?.['something-went-wrong'] });
      this.form.reset();
    });
  }

  optionAction(option: OptionAction): unknown {

    switch (option) {
      case 'blog-creator': {
        if (!this.store.selectSnapshot(UserState.authenticated)) {
          this.resetAuthHandle();
          const emitHandleKey = 'blogCreatorOptionSelected';
          this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
          this.appListener.globalSignalListener(emitHandleKey).pipe(
            tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
            takeUntil(this.authHandle$)
          ).subscribe(() => this.optionAction('blog-creator'));
          return;
        }
        this.router.navigateByUrl(`${currentLang()}/compose/new-story`);
        break;
      }
      case 'trip-planner': {
        this.efStateService.overlayState$$.next(true);
        break;
      }
      case 'tourism-board-solutions': {
        this.window.open('https://www.hiptraveler.net/' ,'_break');
        break;
      }
      case 'destination-specialist-program': {
        this.router.navigate([ currentLang(), 'travel-agent-register' ]);
        break;
      }
      case 'become-ambassador': {
        this.router.navigate([ currentLang(), 'ambassador' ]);
        break;
      }
      case 'media': {
        this.window.open('mailto:media@hiptraveler.com' ,'_top');
        break;
      }
      case 'jobs': {
        this.window.open('mailto:jobs@hiptraveler.com' ,'_top');
        break;
      }
      case 'copyright': {
        this.window.open('mailto:copyright@hiptraveler.com' ,'_top');
        break;
      }
    }
    return;
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
