import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollElementBorderDirectiveModule } from '@hiptraveler/common';
import { ResultDetailsModule } from './../result-details/result-details.module';
import { HighlightCarouselModule } from './highlight-carousel/highlight-carousel.module';
import { AboutModule } from './about/about.module';
import { InformationModule } from './information/information.module';
import { MeetingPointModule } from './meeting-point/meeting-point.module';
import { RelatedToursModule } from './related-tours/related-tours.module';
import { HighlightBannerModule } from './highlight-banner/highlight-banner.module';
import { ReviewsModule } from './reviews/reviews.module';
import { HourDetailsModule } from './hour-details/hour-details.module';
import { AboutResultModule } from './about-result/about-result.module';
import { ContactInformationModule } from './contact-information/contact-information.module';
import { ImageCarouselModule } from './image-carousel/image-carousel.module';

import { ResultContentComponent } from './result-content.component';
import { AlignSizeDirective } from './align-size.directive';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ScrollElementBorderDirectiveModule,
  ResultDetailsModule,
  HighlightCarouselModule,
  AboutModule,
  InformationModule,
  MeetingPointModule,
  RelatedToursModule,
  HighlightBannerModule,
  ReviewsModule,
  HourDetailsModule,
  AboutResultModule,
  ContactInformationModule,
  ImageCarouselModule
];

const COMPONENTS = [
  ResultContentComponent
];

const DIRECTIVES = [
  AlignSizeDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    ResultContentComponent
  ]
})
export class ResultContentModule { }
