import { Pipe, PipeTransform } from '@angular/core';

const HEAD_CLASS = 'itinerary-activity-day-list-activity-content-wrapper-head';

@Pipe({
  name: 'displayText'
})
export class DisplayTextPipe implements PipeTransform {

  /**
   * Transforms the input string into formatted HTML.
   *
   * @param value The input string to transform.
   * @returns The formatted HTML string.
   */
  transform(value: string): string {

    let sections: any[] = value?.split('\n\n').map(section => ({ origin: section, content: '', key: section.includes('\n') ? 'sectionHead' : 'content' }))

    sections = sections.map((section: any) => {
      if (section.key === 'sectionHead') {
        section.content = section.origin.split('\n').map((section: any, index: number) => {
          return index === 0 ? `<p class="${HEAD_CLASS}"><strong>${section}</strong></p>` : `<p>${section}</p>`;
        }).join('');
      } else {
        section.content = `<p>${section.origin}</p>`;
      }
      return section.content;
    });

    sections = sections.map((section: string, index: number) => {

      if (index === 0 && section.includes(`class="${HEAD_CLASS}"`)) {
        section = section.replace(` class="${HEAD_CLASS}"`, '');
      }
      return section;
    })

    return sections.join('');
  }

}
