import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[scrollToView]'
})
export class ScrollToViewDirective implements OnInit {

  @Input('scrollToView') id?: string;

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.id && this.element.nativeElement.setAttribute('fragment', this.id);
    // setTimeout(() => window.scrollTo({ top: 1700, behavior: 'smooth' }), 0);
  }

  @HostListener('click')
  hostClick(): void {
    this.id && this.router.navigate([], {
      fragment: this.id,
      preserveFragment: false
    });
  }

}
