<div class="certifications-licenses">

  <p class="section-title" translate="reg-form.cert-licenses"></p>

  <div class="certifications-licenses--form">

    <p class="section-sub-header" translate="reg-form.travel-advisor"></p>

    <div class="activities--chip-selection">
      <div class="activities--chip-selection-item" (click)="selectOption(option)" *ngFor="let option of taOptions;">
        <p class="activities--chip-selection-item-content" matRipple [class.active]="operatorSelection === option">{{ option }}</p>
      </div>
      <ng-container *ngIf="operatorSelection === 'IATA'">
        <mat-form-field underlined appearance="outline" [@opacityFade]>
          <mat-label>{{ 'reg-form.iata-id' | translate }}</mat-label>
          <input matInput placeholder="Enter your IATA Id" name="certificate-id" type="text" [(ngModel)]="iataId" (ngModelChange)="certIdChanged()">
        </mat-form-field>
      </ng-container>
    </div>
    
    <p class="section-sub-header" translate="reg-form.tour-operator"></p>

    <div class="activities--chip-selection">
      <div class="activities--chip-selection-item" (click)="selectOption(option)" *ngFor="let option of toOptions;">
        <p class="activities--chip-selection-item-content" matRipple [class.active]="operatorSelection === option">{{ option }}</p>
      </div>
      <ng-container *ngIf="!tourSelection?.certificate;else customTourOperator">
        <div class="activities--chip-selection-item custom-style">
          <p class="activities--chip-selection-item-content" matRipple (click)="openNewOperatorDialog()" translate="reg-form.other"></p>
        </div>
      </ng-container>
      <ng-template #customTourOperator>
        <div class="activities--chip-selection-item" (click)="openNewOperatorDialog()">
          <p class="activities--chip-selection-item-update" translate="reg-form.update"></p>
          <p class="activities--chip-selection-item-content active" matRipple>{{ tourSelection?.certificate }}</p>
        </div>
      </ng-template>
    </div>

  </div>

</div>
