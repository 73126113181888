<form class="auth-dialog--form" [class.disabled]="service.resendProcessing" [formGroup]="service.form" (ngSubmit)="service.verifyOtpCode()" (keydown)="changeField($event)">

  <p class="auth-dialog--form-title">{{ 'auth.verification' | translate }}</p>
  
  <p class="auth-dialog--form-sub-title">{{ 'auth.otp-instruction' | translate }}</p>
  
  <div class="auth-dialog--form-field-opt">
    <input name="otp-code1" type="number" onwheel="this.blur()" formControlName="code1" #code1 (click)="cursorActive('code1', code1)">
    <input name="otp-code2" type="number" onwheel="this.blur()" formControlName="code2" #code2 (click)="cursorActive('code2', code2)">
    <input name="otp-code3" type="number" onwheel="this.blur()" formControlName="code3" #code3 (click)="cursorActive('code3', code3)">
    <input name="otp-code4" type="number" onwheel="this.blur()" formControlName="code4" #code4 (click)="cursorActive('code4', code4)">
    <input name="otp-code5" type="number" onwheel="this.blur()" formControlName="code5" #code5 (click)="cursorActive('code5', code5)">
    <input name="otp-code6" type="number" onwheel="this.blur()" formControlName="code6" #code6 (click)="cursorActive('code6', code6)">
  </div>

  <ui-action-button type="submit" [pending]="!!(service.pending$$ | async)">{{ 'auth.verify' | translate }}</ui-action-button>

  <ng-container *ngIf="(service.countdownTimer$$ | async)! === service.resendCodeTimer;else resendCodeDuration">
    <p class="auth-dialog--form-info-action" [ngClass]="{ pending: service.resendProcessing }">
      {{ 'auth.resend-code-label' | translate }}
      <span (click)="service.resendOtpCode()" translate="auth.resend-code"></span>
    </p>
  </ng-container>
  <ng-template #resendCodeDuration>
    <p class="auth-dialog--form-info-action" translate="auth.resend-code-timer" [translateParams]="{ value: service.countdownTimer$$ | async }"></p>
  </ng-template>

  <p class="auth-dialog--form-action">
    <mat-icon svgIcon="auth-dialog.caret-back-icon-svg"></mat-icon>
    <span (click)="backToForgotPasswordClicked.emit()" translate="auth.back-to-forgot-password"></span>
  </p>

</form>
