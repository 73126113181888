import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ItineraryActionBubbleComponent } from './itinerary-action-bubble.component';

const MODULES = [
  CommonModule,
  OverlayModule,
  MatRippleModule,
  MatIconModule,
  MatProgressSpinnerModule
];

const COMPONENTS = [
  ItineraryActionBubbleComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ItineraryActionBubbleComponent
  ]
})
export class ItineraryActionBubbleModule { }
