import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autoWidth]'
})
export class AutoWidthDirective implements AfterViewInit {

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngAfterViewInit(): void {

    const element = this.element.nativeElement.getElementsByClassName('travel-agent-register-specialization').item(0) as HTMLDivElement;
    
    element.style.display = 'none';

    const width = this.element.nativeElement.offsetWidth;

    element.style.display = 'block';
    element.style.width = `${width}px`;
  }

}
