import { Component, Input } from '@angular/core';

import { ActivityDateMedia } from '@hiptraveler/data-access/api';

@Component({
  selector: 'media-card',
  template: `
    <ng-container *ngIf="data.type === 'youtube'">
      <div class="media-card">
        <iframe [src]="data.src | safeUrl" frameborder="0" class="mat-elevation-z4" allowfullscreen></iframe>
      </div>
    </ng-container>
  `,
  styleUrls: ['./media-card.component.scss']
})
export class MediaCardComponent {

  @Input() data: ActivityDateMedia;

}
