import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs';

import { ProfileState } from '@hiptraveler/data-access/profile';
import { UserProfile } from '@hiptraveler/data-access/api';
import { WINDOW } from '@hiptraveler/common';

type Social = 'twitter' | 'facebook' | 'youtube' | 'pinterest' | 'instagram' | 'tiktok' | 'website';

@Directive({
  selector: '[socialExternalLink]'
})
export class SocialExternalLinkDirective implements AfterViewInit, OnDestroy {

  @Input() socialExternalLink: Social;

  constructor(
    @Inject(WINDOW) private window: any,
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private store: Store
  ) { }

  ngAfterViewInit(): void {
    this.elementActiveClassObserver();
  }
  
  ngOnDestroy(): void {
    this.renderer.removeClass(this.element.nativeElement, 'active')
  }

  @HostListener('click')
  hostClicked(): unknown {
    const socialLink = this.store.selectSnapshot(ProfileState.profileDetails)?.connectWith;
    if (!socialLink?.[this.socialExternalLink]) return;

    switch (this.socialExternalLink) {
      case 'facebook': return this.window.open(socialLink.facebook, '_blank');
      case 'twitter': return this.window.open(socialLink.twitter, '_blank');
      case 'youtube': return this.window.open(socialLink.youtube, '_blank');
      case 'pinterest': return this.window.open(socialLink.pinterest, '_blank');
      case 'instagram': return this.window.open(socialLink.instagram, '_blank');
      case 'tiktok': return this.window.open(socialLink.tiktok, '_blank');
      case 'website': return this.window.open(socialLink.website, '_blank');
    }
  }

  elementActiveClassObserver(): void {
    this.store.select(ProfileState.profileDetails).pipe(
      filter(Boolean),
      take(1)
    ).subscribe((profile: UserProfile) => {
      profile.connectWith?.hasOwnProperty(this.socialExternalLink)
        && this.renderer.addClass(this.element.nativeElement, 'active')
    });
  }

}
