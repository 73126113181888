import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChatCompletion } from 'openai/resources';
import { firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { intersection, uniqBy } from 'lodash';

import { BrandInfo, Experience, SearchApiService } from '@hiptraveler/data-access/api';
import { BrandState, BrandStateModel } from '@hiptraveler/data-access/brand';
import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { responseToValue } from '@hiptraveler/features/custom-map-autocomplete';
import { EFAStateServiceService, CarouselFilterType, ScreenValue, carouselScreenViewPrompts, parseBrandState, inputLocationPrompt, parseStateLocation } from '..';
import { DEFAULT_BRAND_NAME, subdomain } from '@hiptraveler/common';

@Injectable()
export class CarouselService {

  constructor(
    private store: Store,
    private searchApi: SearchApiService,
    private completions: ChatCompletionsService,
    private stateService: EFAStateServiceService
  ) { }

  get brandState(): BrandStateModel | null {
    let brandState = this.store.selectSnapshot(BrandState.state);
    brandState = parseBrandState(brandState)
    if (brandState?.experiences) {
      brandState.experiences = brandState.experiences.concat(this.stateService.locations$$.value as any);
      brandState.experiences = uniqBy(brandState.experiences, 'code');
    }
    return brandState;;
  }

  get brandState$(): Observable<BrandStateModel | null> {
    return this.stateService.locations$.pipe(
      switchMap(locations => this.store.select(BrandState.state).pipe(
        map(parseBrandState),
        map(state => {
          if (state?.experiences) {
            state.experiences = state?.experiences?.concat(locations as any);
            state.experiences = uniqBy(state.experiences, 'code');
          }
          return state;
        })
      ))
    );
  }

  getDataByFilter(selectedFilter: CarouselFilterType, store?: 'select' | 'snapshot'): any {

    if (store === 'snapshot') {
      const state: Partial<BrandInfo> | null = this.brandState;
      return (state?.experiences || []).filter(e => e.category === selectedFilter) as Partial<Experience>[];
    }

    return this.brandState$.pipe(
      map((state: Partial<BrandInfo> | null) => (state?.experiences || []).filter(e => e?.category === selectedFilter))
    ) as Observable<Partial<Experience>[]>;
  }

  getDataFromResponseByFilter(selectedFilter: CarouselFilterType, response: string[]): string[] {
    const state: Partial<BrandInfo> | null = this.brandState;
    const fromState: string[] = (state?.experiences || []).filter(e => e.category === selectedFilter).map(e => e.code || '').filter(Boolean);
    return intersection(fromState, response);
  }

  observeInputChanges(screen: ScreenValue, filter: CarouselFilterType): void {
    this.stateService.getInputMessageByScreen(screen).subscribe(async (inputMessage: string) => {
      try {
        this.stateService.inputPending$$.next(true);
        this.stateService.pauseBrandInfoUXState$$.next(false);
        const travelStyleLabels = this.getDataByFilter(filter, 'snapshot').map((e: any) => e.code || '').filter(Boolean);
        const chatCompletion = await this.completions.sendMessage(
          inputMessage, carouselScreenViewPrompts(travelStyleLabels)
        );
        const response = await this.getResponse(chatCompletion, inputMessage);

        this.stateService.patchFormValue({
          carouselData: { ...this.stateService.formValue.carouselData, [screen]: response }
        });
      } catch (e) { }
      finally {
        this.stateService.inputPending$$.next(false);
        this.stateService.pauseBrandInfoUXState$$.next(true);
      }
    });
  }

  private async getResponse(chatCompletion: ChatCompletion, inputMessage: string): Promise<string[]> {

    let response = await JSON.parse(chatCompletion.choices[0].message.content || '[]') as string[];

    if (!response?.length && subdomain() === DEFAULT_BRAND_NAME) {
      const chatCompletion = await this.completions.sendMessage(inputMessage, inputLocationPrompt);
      const locationResponse = chatCompletion.choices[0].message.content || '';
      const autocomplete = await firstValueFrom(this.searchApi.getAutocompleteLocation({ q: locationResponse }).pipe(
        map(e => responseToValue(e))
      ));
      const experience = parseStateLocation(autocomplete || [])?.[0];
      this.stateService.appendLocation(experience);
      response = [ experience?.code || '' ];
    }

    return response;
  }

}
