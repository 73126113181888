import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ProfileState, ProfileStateModel } from '@hiptraveler/data-access/profile';
import { ProfileStateService, ProfileTabSelection } from '../../common';

@Injectable()
export class UserActivityService {

  @Select(ProfileState.state) profileState$: Observable<ProfileStateModel | null>;
  @Select(ProfileState.placesVisited) placesVisited$: Observable<any[] | null>;

  constructor(
    private stateService: ProfileStateService
  ) { }

  get selection$(): Observable<ProfileTabSelection> {
    return this.stateService.profileTabSelection$$.asObservable();
  }

  switchTab(selection: ProfileTabSelection): void {
    this.stateService.profileTabSelection$$.next(selection);
  }

}
