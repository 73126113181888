import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { AboutComponent } from './about.component';
import { AdditionalInfoContentDirective } from './additional-info-content.directive';
import { AboutThisTourContentDirective } from './about-this-tour-content.directive';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  AboutComponent
];

const DIRECTIVES = [
  AdditionalInfoContentDirective,
  AboutThisTourContentDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    AboutComponent
  ]
})
export class AboutModule { }
