import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CustomActivityDialogView } from './custom-activity-dialog-component-index';
import { CommonComponents, ScrollElementBorderDirectiveModule } from './common';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';

import { CustomActivityDialogComponent } from './custom-activity-dialog.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  CustomActivityDialogView,
  CommonComponents,
  DialogCloseButtonModule,
  ScrollElementBorderDirectiveModule
];

const COMPONENTS = [
  CustomActivityDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class CustomActivityDialogModule { }
