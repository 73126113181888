import { Injectable } from '@angular/core';
import { Observable, Subject, delay, takeUntil } from 'rxjs';

import { RegistrationFormStateService } from '../common';

@Injectable()
export class CertificationsLicensesService {

  subscription$ = new Subject<void>();

  constructor(
    private stateService: RegistrationFormStateService
  ) { }

  get formValue$(): Observable<any> {
    return this.stateService.form.valueChanges.pipe(
      delay(0),
      takeUntil(this.subscription$)
    );
  }

  updateCertificate(value: { certId: string; certType: string }): void {
    this.stateService.form.patchValue(value);
  }

  initializeFormObserver(): void {
    this.stateService.initializeFormObserver(() => this.subscription$.next());
  }

}
