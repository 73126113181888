import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';

import { MyTripsComponent } from './my-trips.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  TranslateModule
];

const LIBRARIES = [
  MobileNavbarViewModule,
];

const COMPONENTS = [
  MyTripsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class MyTripsModule { }
