import { StateContext, Store } from "@ngxs/store";

import { AddItineraryResponse, validateResponse } from "@hiptraveler/data-access/api";
import { ItineraryStateModel } from "..";
import { setActiveNavbarButtons, addUserItineraryByAddItineraryResponse } from ".";
import { getWindowRef, globalStateManagementStoreKey, isWidget, NavbarControlStateService } from "@hiptraveler/common";

export function addItinerary(ctx: StateContext<ItineraryStateModel>, response: AddItineraryResponse, navbarControl: NavbarControlStateService): void {

  const store: Store = getWindowRef()[globalStateManagementStoreKey];

  addUserItineraryByAddItineraryResponse(store, response);

  if (isWidget()) {
    return validateResponse(response, { ignoreKey: 'itiMap' });
  }

  if (!response?.data?.itiMap?.basicInfo) {
    const defaultErrorMessage = 'Something went wrong. Please try again.';
    throw { error: defaultErrorMessage };
  }

  setActiveNavbarButtons(response, navbarControl);

  ctx.patchState({
    basicInfo: response.data.itiMap.basicInfo,
    actDateMap: response.data.itiMap.itinerary.actDateMap
  });
}
