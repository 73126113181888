<div class="search-results">

  <feature-card actionCard actionButton toggleFavorite="hotelResults" type="hotel" [id]="hotel.id" [starRating]="hotel.starRating" [featureCardHover]="hotel" (click)="service.searchResultDialog.open(hotel, 'hotel')" *ngFor="let hotel of hotelResults;" [@opacityFade]>

    <itinerary-action-bubble [active]="!!(service.searchPageControl.activityDate$ | async) && (hotel.id | validateStatus: (service.actDate$ | async))">
      <itinerary-action [activityId]="hotel.id" [hotel]="true"></itinerary-action>
    </itinerary-action-bubble>
    <img featureCardImage [lazyLoad]="hotel.thumbnail" [defaultImage]="('hotel' | searchSvgPath | async)!" [errorImage]="('hotel' | searchSvgPath | async)!" [alt]="hotel.name">
    <feature-card-price [currency]="hotel?.lowestOffer?.currency" [amount]="hotel?.lowestOffer?.rate" *ngIf="hotel?.lowestOffer?.rate"></feature-card-price>
    <feature-card-title>{{ hotel.name }}</feature-card-title>
    <feature-card-location>{{ hotel.city }} {{ hotel.countryCd }}</feature-card-location>
    <feature-card-rating>{{ hotel.guestRating }}</feature-card-rating>
    <feature-card-reviews>{{ hotel.numReviews }}</feature-card-reviews>
  
  </feature-card>

  <feature-card actionCard actionButton toggleFavorite="imageResults" type="amusement" type="amusement" [id]="adventure.id" [starRating]="adventure?.tourData?.overallRating" [featureCardHover]="adventure" (click)="service.searchResultDialog.open(adventure, 'adventure')" *ngFor="let adventure of adventureResults;" [@opacityFade]>

    <itinerary-action-bubble [active]="!!(service.searchPageControl.activityDate$ | async) && (adventure.id | validateStatus: (service.actDate$ | async))">
      <itinerary-action [activityId]="adventure.id"></itinerary-action>
    </itinerary-action-bubble>
    <img featureCardImage [lazyLoad]="adventure?.thumbnail || ''" [defaultImage]="('adventure' | searchSvgPath | async)!" [errorImage]="('adventure' | searchSvgPath | async)!" [alt]="adventure.name">
    <feature-card-price [currency]="adventure.tourData?.price?.currency" [amount]="adventure?.tourData?.price?.amount"></feature-card-price>
    <feature-card-title class="amusement">{{ adventure.name }}</feature-card-title>
    <feature-card-subtitle>{{ adventure | searchResultLocation }}</feature-card-subtitle>
    <feature-card-rating-reviews>{{ adventure?.tourData?.numRating || 0 }}</feature-card-rating-reviews>
    <feature-card-duration [duration]="adventure?.duration"></feature-card-duration>
  
  </feature-card>

  <feature-card actionCard actionButton toggleFavorite="imageResults" type="consume" [id]="food.id" [featureCardHover]="food" (click)="service.searchResultDialog.open(food, 'food')" *ngFor="let food of foodResults;" [@opacityFade]>

    <itinerary-action-bubble [active]="!!(service.searchPageControl.activityDate$ | async) && (food.id | validateStatus: (service.actDate$ | async))">
      <itinerary-action [activityId]="food.id"></itinerary-action>
    </itinerary-action-bubble>
    <img featureCardImage [lazyLoad]="food?.thumbnail || ''" [defaultImage]="('foodAndDrinks' | searchSvgPath | async)!" [errorImage]="('foodAndDrinks' | searchSvgPath | async)!" [alt]="food.name">
    <feature-card-title class="amusement">{{ food.name }}</feature-card-title>
    <feature-card-subtitle>{{ food | searchResultLocation }}</feature-card-subtitle>
    <img featureCardReviewImage [lazyLoad]="food?.yelpData?.ratingImgUrl!" defaultImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" errorImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" width="63" height="12" [alt]="food.name">
    <feature-card-rating-reviews>{{ food?.yelpData?.reviewCnt || 0 }}</feature-card-rating-reviews>
  
  </feature-card>

  <feature-card itineraryCard toggleFavorite="itineraryResults" navigateToTrip [itinerary]="itinerary" [id]="itinerary.id" *ngFor="let itinerary of (dataAccess.itineraryResults$ | async);" [@opacityFade]>
    <img featureCardImage [lazyLoad]="itinerary?.coverImage?.imgUrl!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="itinerary?.pageTitle">
    <feature-card-date>{{ itinerary?.numDays }}</feature-card-date>
    <feature-card-itinerary-title>{{ itinerary?.title }}</feature-card-itinerary-title>
    <feature-card-itinerary-snippet [class.no-content]="!itinerary?.snippet">{{ itinerary?.snippet }}</feature-card-itinerary-snippet>
  </feature-card>

  <feature-card itineraryCard toggleFavorite="blogResults" [id]="itinerary?.id || ''" *ngFor="let itinerary of dataAccess.blogResults$ | async;" [@opacityFade]>
    <img featureCardImage [lazyLoad]="itinerary.coverImage?.imgUrl!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="itinerary.pageTitle">
    <feature-card-itinerary-title>{{ itinerary.title }}</feature-card-itinerary-title>
    <feature-card-itinerary-snippet [class.no-content]="!itinerary.snippet">{{ itinerary.snippet }}</feature-card-itinerary-snippet>
  </feature-card>

  <ng-container *ngIf="withViewContent === 0">

    <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
    </ngx-skeleton-loader>
  
  </ng-container>
  
  <ng-container *ngIf="withViewContent === 2">
    <p class="ht-no-results" style="text-align: center;" translate="profile.no-favorites-yet"></p>
  </ng-container>

</div>
