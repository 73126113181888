<div class="app-story-page-view router-page-container" navbarActionListener [class.processing]="common.processing$ | async" *ngIf="selections">

  <feature-page-banner [title]="(service.basicInfo$ | async)?.title!" [owner]="(common.owner$ | async)!" [coverImage]="(service.basicInfo$ | async)?.coverImage!" [author]="(service.basicInfo$ | async)?.author!" [selections]="selections"></feature-page-banner>

  <div class="story">

    <div class="story--label-container"></div>
  
    <div class="story--content">
      <div class="story--content-section" *ngIf="service.hasStoryContent$ | async">

        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
            <p translate="view-page.story"></p>
          </div>
        </div>
        
        <div [id]="selections[0].scrollToViewId"></div>
        <div>
          <story-content></story-content>
        </div>

      </div>
      <div class="story--content-section story--content-section-date-list">
        
        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
            <p translate="view-page.itinerary"></p>
            <activity-date-list view></activity-date-list>
          </div>
        </div>
        
        <activity-date-list-container [count]="2" [actDate]="service.actDate$">
          <div [id]="selections[1].scrollToViewId"></div>
          <activity-day-list view type="itinerary"></activity-day-list>
        </activity-date-list-container>

      </div>
      <div class="story--content-section story--content-section-travel-details" *ngIf="(service.basicInfo$ | async)?.locationList?.length">

        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
            <p translate="view-page.travel"></p>
          </div>
        </div>
        
        <div [id]="selections[2].scrollToViewId"></div>
        <div>
          <story-travel-agents></story-travel-agents>
          <travel-details [@opacityFade] (selectedLocation)="travelOptions.selectedLocation = $event"></travel-details>
          <travel-options [@opacityFade]></travel-options>
        </div>

      </div>
      <div class="story--content-section" *ngIf="(service.checklist$ | async) && (common.owner$ | async) && (service.authenticated$ | async)">

        <div class="story--content-section-label" [@opacityFade]>
          <div class="story--content-section-label-container">
            <p translate="view-page.checklist"></p>
          </div>
        </div>
        
        <div [id]="selections?.[3]?.scrollToViewId"></div>
        <div>
          <checklist [@opacityFade]></checklist>
        </div>

      </div>
      <div class="story--content-section">

        <div class="story--content-section-label">
          <div class="story--content-section-label-container">
          </div>
        </div>
        
        <div>
          <travel-share-handles [@opacityFade]></travel-share-handles>
        </div>

      </div>
    </div>

    <div class="story--map-wrapper">
      <ng-container *ngIf="true">
        <div class="story--map" leafletMap id="itinerary-view-page-map" [@opacityFadeIn]></div>
      </ng-container>
    </div>
  </div>

</div>

<travel-options style="position: absolute;opacity: 0;z-index: -1000;"></travel-options>
