import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchPageControlStateService } from '@hiptraveler/common';
import { ItineraryActionPanelService } from './itinerary-action-panel.service';
import { ItineraryActionPanelDataAccessService } from './itinerary-action-panel-data-access.service';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';

@Component({
  selector: 'itinerary-action',
  templateUrl: './itinerary-action-panel.component.html',
  styleUrls: ['./itinerary-action-panel.component.scss'],
  host: { class: 'itinerary-action-panel' },
  viewProviders: [ ItineraryActionPanelService, ItineraryActionPanelDataAccessService, PromptDialogActionService ]
})
export class ItineraryActionPanelComponent implements OnInit {

  @Input() activityId: string;
  @Input() hotel: boolean;
  @Input() actionPanel: string;

  constructor(
    private store: Store,
    public searchPageControl: SearchPageControlStateService,
    public service: ItineraryActionPanelService,
  ) { }

  ngOnInit(): void {
    this.service.activityId = this.activityId;
  }

  /**
   * Replaces an existing itinerary activity with new data.
   */
  replaceItineraryActivity(): void {
    const data = this.searchPageControl.replaceItineraryActivity$$.value;
    data && this.service.replaceItineraryActivity(data);
  }

  /**
   * Adds a new activity to the current day in the itinerary.
   */
  addToCurrentDay(): void {
    const activity = this.searchPageControl.activityDate$$.value;
    const actDate = this.store.selectSnapshot(ItineraryState.actDate);
    const selected = actDate?.find(e => e.day === activity?.day)
    if (activity && selected) {
      this.service.addToDate(selected);
    }
  }

}
