import { Component, ViewEncapsulation } from '@angular/core';

import { Selection, defaultSelection } from './common';

@Component({
  selector: 'connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConnectComponent {

  activeSelection: Selection = defaultSelection;

}
