import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'social-media-field',
  templateUrl: './social-media-field.component.html'
})
export class SocialMediaFieldComponent {

  @Input() form: FormGroup;

}
