import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, switchMap, takeUntil, tap } from 'rxjs';

import { EFAStateServiceService, ScreenValue, SummaryItems } from '../../shared';
import { getPlaceholderByScreen } from './placeholder-text';
import { promiseDelay } from '@hiptraveler/common';

@Injectable()
export class InputControlService {

  inputPlaceholder$$ = new BehaviorSubject<string>(getPlaceholderByScreen('screen1'));
  inputPlaceholder$ = this.inputPlaceholder$$.asObservable().pipe(
    switchMap(placeholder => this.stateService.isMobile$.pipe(
      map(state => state ? 'Message HipTraveler GPT' : placeholder)
    ))
  );

  overlayVisibilityRegister$$ = new BehaviorSubject<boolean>(false);
  overlayVisibility$$ = new BehaviorSubject<boolean>(false);
  overlayVisibility$ = this.overlayVisibility$$.asObservable();

  constructor(
    private element: ElementRef<HTMLElement>,
    private stateService: EFAStateServiceService
  ) { }

  get summaryTags$(): Observable<SummaryItems> {
    return this.stateService.summaryItems$
  }

  observeRequest(): void {
    this.stateService.inputPending$.subscribe((state: boolean) => {
      this.element.nativeElement.classList[state ? 'add' : 'remove']('disabled');
    });

    this.stateService.summaryItems$.pipe(
      tap(summaryItems => {
        !summaryItems?.length && this.closeOverlay();
      })
    ).subscribe();
  }

  registerOverlayTrigger(value: boolean): void {
    this.overlayVisibilityRegister$$.next(value);
    value || this.triggerCloseOverlay(); 
  }

  overlayTriggerMouseOver(): void {
    if (
      !this.stateService.summaryItems$$.value?.length
      || this.stateService.inputPending$$.value
    ) return;
    this.overlayVisibility$$.next(true);
  }

  async triggerCloseOverlay(): Promise<void> {
    await promiseDelay(100);
    if (this.overlayVisibilityRegister$$.value) return;
    this.closeOverlay();
  }

  closeOverlay(): void {
    this.overlayVisibilityRegister$$.next(false);
    this.overlayVisibility$$.next(false);
  }

  screenChangesObserver(): void {
    this.stateService.screenViewValue$.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      takeUntil(this.stateService.subscription$)
    ).subscribe((screen: ScreenValue) => {
      this.inputPlaceholder$$.next(getPlaceholderByScreen(screen));
    });
  }

}
