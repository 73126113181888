<dialog-close-button style="left: 0;"></dialog-close-button>

<ng-container *ngIf="(service.locationData$ | async)?.gallery as gallery">

  <div class="overlay-carousel">

    <div class="overlay-carousel--active">
      <carousel-button left (click)="nextImage('prev')" [@opacityFade]></carousel-button>
      <img [lazyLoad]="activePhoto || gallery?.[0]?.gImgUrl || ''" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="gallery?.[0]?.caption">  
      <carousel-button right (click)="nextImage('next')" [@opacityFade]></carousel-button>
    </div>
  
    <div class="overlay-carousel--slider" mouseDrag>
      <div class="overlay-carousel--slider-image" [class.active]="currentIndex === i" *ngFor="let photo of gallery || [];let i = index;">
        <img [lazyLoad]="photo?.gImgUrl || ''" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="photo?.caption" (click)="updateHighlightImage(gallery, i)" [@opacityFade]>
      </div>
    </div>

    <p class="overlay-carousel--credits" [innerHTML]="gallery?.[currentIndex]?.attributionTxt"></p>

  </div>

</ng-container>

