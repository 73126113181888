import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FormModule } from './form/form.module';
import { ExternalViewModule } from './external-view/external-view.module';
import { ProcessingViewModule } from './processing-view/processing-view.module';
import { ActionButtonModule } from '../shared';

import { PlanTripService } from './plan-trip.service';
import { TripPlanningComponent } from './plan-trip.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  FormModule,
  ExternalViewModule,
  ProcessingViewModule,
  ActionButtonModule
];

const PROVIDERS = [
  PlanTripService
];

const COMPONENTS = [
  TripPlanningComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  providers: [
    PROVIDERS
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TripPlanningComponent
  ]
})
export class PlanTripModule { }
