import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { EFAStateServiceService } from '../../shared';

@Directive({
  selector: '[messageChatDynamic]'
})
export class MessageChatDynamicDirective implements AfterViewInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    private stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    this.observerContentHeight();
  }

  observerContentHeight(): void {

    const element = this.element.nativeElement;

    this.stateService.conversations$.subscribe(() => {
      if (element.clientHeight >= 48) {
        element.classList.add('margins');
      }
    });
  }

}
