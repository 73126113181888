import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { TripDetailContentComponent } from './trip-detail-content.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ButtonsModule
];


const COMPONENTS = [
  TripDetailContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TripDetailContentComponent
  ]
})
export class TripDetailContentModule { }
