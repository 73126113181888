import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserState } from '@hiptraveler/data-access/user';

@Injectable()
export class PostActionButtonService {

  @Select(UserState.id) id$: Observable<string | null>;
  @Select(UserState.field('picUrl')) picUrl$: Observable<string | null>;
  @Select(UserState.field('uTitle')) uTitle$: Observable<string | null>;

}
