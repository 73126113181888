import { Directive, HostListener } from '@angular/core';

import { FilterClearService } from '../common';

@Directive({
  selector: '[clearAction]'
})
export class ClearActionDirective {

  constructor(
    private filterClear: FilterClearService
  ) { }

  @HostListener('click')
  async hostClicked(): Promise<void> {
    this.filterClear.clear();
  }

}
