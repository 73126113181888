import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionCompletion, Actions, Store, ofActionCompleted } from '@ngxs/store';
import { Subject, delay, filter, firstValueFrom, map, takeUntil, tap } from 'rxjs';

import { AuthAction } from '@hiptraveler/data-access/auth';
import { BrandState } from '@hiptraveler/data-access/brand';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { ProfileAction } from '@hiptraveler/data-access/profile';
import { DataAccessService, ProfileStateService, ProfileTabSelection } from './common';
import { NavbarControlStateService, RequestCancellationService, WINDOW } from '@hiptraveler/common';

@Injectable()
export class ProfileService implements OnDestroy {

  subscription$ = new Subject<void>();
  
  constructor(
    @Inject(WINDOW) private window: any,
    @Optional() private dataAccess: DataAccessService,
    private route: ActivatedRoute,
    private store: Store,
    private actions$: Actions,
    private requestCancellation: RequestCancellationService,
    private stateService: ProfileStateService,
    private ncStateService: NavbarControlStateService
  ) { }

  private get tabSelection(): ProfileTabSelection | null {
    return this.window.location.pathname.split('/')?.[4] || null;
  }

  private get userId(): string {
    return this.route.snapshot.params['user'] || 'u';
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ProfileAction.ResetProfileState);
    this.requestCancellation.cancelAllProfileSearchRequests();
    this.subscription$.next();
  }

  initStateService(): void {
    this.stateService.profileTabSelection$$.next(this.tabSelection || 'stories');
  }

  authenticationObserver(): void {
    this.actions$.pipe(
      ofActionCompleted(AuthAction.GetUserDetails),
      filter((completion: ActionCompletion<AuthAction.GetUserDetails, Error>) => completion.result.successful),
      delay(150),
      map(() => this.store.selectSnapshot(UserState.state)),
      filter(Boolean),
      tap((userState: UserStateModel) => this.store.dispatch(new ProfileAction.VerifyStateWithAuthStateChanges(
        userState.id,
        userState.following
      ))),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  async getProfileDetails(title?: string): Promise<void> {
    if (this.stateService.initialProcessing$$.value) return;
    this.stateService.initialProcessing$$.next(true);
    await firstValueFrom(this.store.dispatch(new ProfileAction.ResetProfileState));
    await firstValueFrom(this.store.select(BrandState.state).pipe(filter(Boolean)));
    await this.dataAccess.getProfileDetails(title || this.userId);
    this.stateService.initialProcessing$$.next(false);
  }

  profileNavigationListener(): void {
    this.ncStateService.navbarProfileClicked$$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.getProfileDetails();
    });
  }

}
