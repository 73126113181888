import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ComposeBlogFormModule } from '../compose-blog-form/compose-blog-form.module';

import { NewBlogComponent } from './new-blog.component';
import { DispatchObserverDirectiveModule, BlogNavbarActionDirectiveModule } from '../common';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ComposeBlogFormModule,
  DispatchObserverDirectiveModule,
  BlogNavbarActionDirectiveModule
];

const COMPONENTS = [
  NewBlogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: NewBlogComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class NewBlogModule { }
