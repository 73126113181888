import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

import { SidenavComponent } from './sidenav.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  TranslateModule
];

const COMPONENTS = [
  SidenavComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SidenavComponent
  ]
})
export class SidenavModule { }
