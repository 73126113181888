import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, delay, map, switchMap, take } from 'rxjs';

import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { ProfileTabSelection, SettingsView } from './profile.model';
import { getWindowRef, LOCATION } from '@hiptraveler/common';

const segments = getWindowRef()?.location?.pathname?.split('/');
const selection = segments?.[segments?.length - 1] as ProfileTabSelection;

@Injectable()
export class ProfileStateService {

  profileTabSelection$$ = new BehaviorSubject<ProfileTabSelection>(selection);
  profileSettingsView$$ = new BehaviorSubject<boolean>(false);
  uploadImageProcessing$$ = new BehaviorSubject<boolean>(false);
  settingsView$$ = new BehaviorSubject<SettingsView>('profile');
  saveClicked$$ = new Subject<unknown>();
  initialProcessing$$ = new BehaviorSubject<boolean>(false);

  loadMoreEvent$$ = new Subject<number>();
  loadMoreEvent$ = this.loadMoreEvent$$.asObservable();

  loadMoreProgressState$$ = new BehaviorSubject<boolean>(false);
  loadMoreProgressState$ = this.loadMoreProgressState$$.asObservable();

  constructor(
    @Inject(LOCATION) private location: any,
    private store: Store,
    router: Router,
    ngLocation: Location
  ) {

    this.profileTabSelection$$.asObservable().pipe(delay(0)).subscribe((selection: ProfileTabSelection) => {
      const validSelections: ProfileTabSelection[] = [ 'about', 'favorites', 'itineraries', 'stories', 'uploads', 'followers', 'follows' ];
      const path = [ ...router.url.split('/') ];
      path[path.length - 1] = validSelections.includes(selection) ? selection : 'stories';
      ngLocation.replaceState(path.join('/'));
    });

    this.profileTabSelection$$.asObservable().pipe(take(1)).subscribe((selection: ProfileTabSelection) => {
      (!this.store.selectSnapshot(UserState.id) && selection === 'uploads') && this.profileTabSelection$$.next('stories');
    });
  }

  get userId(): string {
    return decodeURI(this.location.pathname.split('/')[3]);
  }

  get selfProfile$(): Observable<boolean> {
    return this.store.select(UserState.state).pipe(
      switchMap((state: UserStateModel | null) => this.store.select(ProfileState.profileDetails).pipe(
        map((profileDetails) => profileDetails?.profileId === state?.profId)
      ))
    );
  }

  get travelAgent$(): Observable<boolean> {
    return this.store.select(UserState.state).pipe(
      map((state: UserStateModel | null) => state?.userType === 'htAgent')
    );
  }

  get postButton$(): Observable<boolean> {
    return this.profileTabSelection$$.asObservable().pipe(
      map(e => e === 'stories' || e === 'itineraries'),
      switchMap((visibility: boolean) => this.store.select(UserState.id).pipe(
        map((authenticated: unknown) => visibility && !!authenticated)
      )),
      switchMap((state: boolean) => this.selfProfile$.pipe(
        map((selfProfile: boolean) => state && selfProfile)
      ))
    );
  }

  get uploadButton$(): Observable<boolean> {
    return this.profileTabSelection$$.asObservable().pipe(
      map(e => e === 'uploads'),
      switchMap((visibility: boolean) => this.selfProfile$.pipe(
        map((state: unknown) => visibility && !!state)
      ))
    );
  }

}
