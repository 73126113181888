import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SendMessageDialogService } from './send-message-dialog.service';

@Component({
  selector: 'app-send-message-dialog',
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.scss'],
  viewProviders: [ SendMessageDialogService ]
})
export class SendMessageDialogComponent {

  messageControl = new FormControl(['']);

  constructor(
    public service: SendMessageDialogService
  ) { }

}
