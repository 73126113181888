import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { SearchResultDialogComponent } from './search-result-dialog.component';
import { preventExperienceFinderBubbleStateKey } from '@hiptraveler/common';
import { DialogType } from './common';

export const SEARCH_RESULT_ID = 'search-result-dialog';

@Injectable()
export class SearchResultDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open<T = any>(data: T, type: DialogType, id?: string): MatDialogRef<SearchResultDialogComponent, T> | unknown {
    sessionStorage.setItem(preventExperienceFinderBubbleStateKey, '1');

    (data as any)['dialogType'] = type;
    const timestamp = new Date().toISOString();
    const searchResultId = SEARCH_RESULT_ID + `${id ? `-${id}-${timestamp}` : ''}`;
    const dialog = this.dialog.getDialogById(searchResultId);
    if (dialog) return dialog.close();
    return this.dialog.open(SearchResultDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: searchResultId,
      panelClass: SEARCH_RESULT_ID + '-overlay-panel',
      // backdropClass: SEARCH_RESULT_ID + '-overlay-panel-backdrop',
      width: 'clamp(0px, 100%, 1300px)',
      minWidth: 0,
      maxWidth: 'auto',
      height: '100vh',
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data
    })
  }

}
