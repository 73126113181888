import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { delay } from 'rxjs';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { TripFilterType } from '../../experience-finder.model';

@Component({
  selector: 'item-selection',
  templateUrl: './item-selection.component.html',
  styleUrls: ['./item-selection.component.scss']
})
export class ItemSelectionComponent implements OnInit {

  @Input() selectedFilter?: TripFilterType;
  @Input() mobile?: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public stateService: ExperienceFinderStateService
  ) { }

  ngOnInit(): void {
    this.stateService.selectedFilter$$.pipe(delay(100)).subscribe(this.navigateToLeft.bind(this));
  }

  get selectedContainerId(): string {
    const filterSuffix = this.selectedFilter ? `-${this.selectedFilter}` : '';
    return `item-selection${filterSuffix}`;
  }

  addValueToSelection(value: string): void {
    const selectedFilters = this.stateService.selectedFilters$$.value;
    const updatedFilters = selectedFilters.includes(value)
      ? selectedFilters.filter(x => x !== value)
      : [ ...selectedFilters, value ];
    this.stateService.selectedFilters$$.next(updatedFilters);
  }

  navigateToLeft(): void {
    if (isPlatformServer(this.platformId)) return;
    const element = this.document.getElementById(`${this.selectedContainerId}`);
    if (!element) return;
    const OFFSET = 528;
    element.scrollTo({
      left: element.scrollLeft - OFFSET, behavior: 'smooth',
    });
  }
  
  navigateToRight(): void {
    if (isPlatformServer(this.platformId)) return;
    const element = this.document.getElementById(`${this.selectedContainerId}`);
    if (!element) return;
    const OFFSET = 528;
    const multiplier = element.scrollLeft/OFFSET;
    element.scrollTo({
      left: OFFSET * (multiplier+1), behavior: 'smooth',
    });
  }

}
