import { ChangeDetectorRef, Directive, NgModule, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, filter, map, skip, take, takeUntil, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { NavbarControlStateService, currentLang } from '@hiptraveler/common';
import { ComponentStateService } from '@hiptraveler/features/itinerary';

/**
 * Parses the story content by replacing certain HTML tags with others.
 * @param content - The original story content.
 * @returns The parsed story content with modified HTML tags.
 */
function parseStoryContent(content?: string): string | undefined {
  return content?.replace('<figure', '<p').replace('</figure>', '</p>').replace(' class="ht-trvlg-figure"', '').replace(' class="blog-center-img"', ' class="content-editable-inserted-image"') ;
}

/**
 * @Directive({
 *   selector: '[dispatchObserver]'
 * })
 * Directive that observes data changes from various sources and dispatches updates to other components.
 */
@Directive({
  selector: '[dispatchObserver]'
})
export class DispatchObserverDirective implements OnInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private navbarControl: NavbarControlStateService,
    private componentState: ComponentStateService,
  ) { }

  ngOnInit(): void {
    this.basicInfoObserver();
    this.itinerariesObserver();
    this.componentStateObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.navbarControl.navbarTitleState$$.next('');
  }

  get itineraryPage(): boolean {
    return this.router.url.includes(`/${currentLang()}/compose/itinerary`);
  }

  basicInfoObserver(): void {
    // Observe changes in basicInfo from the store and update component state
    this.store.select(ItineraryState.basicInfo).pipe(
      filter(Boolean),
      tap((basicInfo: Partial<BasicInfoData> | null) => this.componentState.patch({
        storyTitle: basicInfo?.title,
        storyContent: parseStoryContent(basicInfo?.content),
        hasStoryContent: !!basicInfo?.blogContent,
        locationList: basicInfo?.locationList?.map(e => ({
          locId: e?.id || e?.gPlaceId || e?.countryId  || '',
          name: e?.city || '',
          country: e?.country || '',
          location: e?.formattedAddr || '',
          latitude: +(e.latitude || '0'),
          longitude: +(e.longitude || '0')
        })),
        bannerAttribution: basicInfo?.coverImage?.attribution,
        bannerImage: {
          url: basicInfo?.coverImage?.imgUrl,
          filename: basicInfo?.title
        }
      })),
      take(1)
    ).subscribe();
  }

  itinerariesObserver(): void {

    if (!this.itineraryPage) return;

    // Observe changes in itineraries and update the story title
    this.store.select(UserState.itineraries).pipe(
      map(e => e?.find(e => e.id === this.store.selectSnapshot(ItineraryState.basicInfo)?.id)),
      filter(Boolean),
      skip(1),
      tap(itinerary => this.componentState.patch({ storyTitle: itinerary.title })),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  componentStateObserver(): void {
    // Observe state processing changes and update the navbar action button state
    this.componentState.stateProcessing$.pipe(
      tap((state: boolean) => {
        this.cdRef.detectChanges();
        this.navbarControl.navbarActionButtonState$$.next(!state);
      })
    ).subscribe();
  }

}

@NgModule({
  declarations: [ DispatchObserverDirective ],
  exports:      [ DispatchObserverDirective ]
})
export class DispatchObserverDirectiveModule { }
