
<profile-selection *ngIf="profileSelectionVisibility"></profile-selection>

<loader-screen></loader-screen>

<div class="experience-finder-ai--host" hostObserver hostScroll #host>

  <ng-template #brandHeader></ng-template>

  <ng-template #inputControl></ng-template>

  <!-- Views -->

  <ng-template #welcomeView></ng-template>

  <ng-template #mainSelection></ng-template>

  <ng-template #carouselTravel></ng-template>

  <ng-template #carouselActivities></ng-template>

  <ng-template #carouselLocation></ng-template>

  <ng-template #calendar></ng-template>

  <ng-template #clientInfo></ng-template>

  <ng-template #summary></ng-template>

</div>
