import { Directive, HostListener, Inject } from '@angular/core';

import { LOCATION, RequestCancellationService, currentLang, promiseDelay } from '@hiptraveler/common';
import { DataAccessService } from '../common';
import { FiltersService } from '../filters.service';

@Directive({
  selector: '[applyAction]'
})
export class ApplyActionDirective {

  constructor(
    @Inject(LOCATION) private location: any,
    private requestCancellation: RequestCancellationService,
    private dataAccess: DataAccessService,
    private service: FiltersService
  ) { }

  @HostListener('click')
  async hostClicked(): Promise<void> {
    const form = this.service.form.value;
    const path = (this.location.pathname.replace(`/${currentLang()}`, '') || '/').replace('/', '');
    this.requestCancellation.cancelAllSearchRequests();

    await promiseDelay(100);

    path.includes('experiences') && this.dataAccess.adventures(form);
    path.includes('thingstodo') && this.dataAccess.adventures(form);
    path.includes('hotels') && this.dataAccess.hotels(form);
    path.includes('foodanddrink') && this.dataAccess.foods(form);
  }

}
