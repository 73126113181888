
var windowRef: any = {};

export function setWindowRef(client: any): void {
  windowRef = client;
}

export function getWindowRef(): any {
  return windowRef;
}
