import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthRegisterDialogService } from './auth-register-dialog.service';
import { inOutCollapse } from '@hiptraveler/animations';
import { PrivacyPolicyDialogActionService, TermsAndConditionsDialogActionService } from '@hiptraveler/dialogs/terms-and-privacy-policy-dialog';

@Component({
  selector: 'app-auth-register-dialog',
  templateUrl: './auth-register-dialog.component.html',
  styleUrls: ['./auth-register-dialog.component.scss'],
  viewProviders: [ AuthRegisterDialogService, PrivacyPolicyDialogActionService, TermsAndConditionsDialogActionService ],
  animations: [ inOutCollapse() ],
  encapsulation: ViewEncapsulation.None
})
export class AuthRegisterDialogComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) private isWidget: boolean,
    public dialogRef: MatDialogRef<AuthRegisterDialogComponent>,
    public service: AuthRegisterDialogService,
    public privacyPolicy: PrivacyPolicyDialogActionService,
    public termsAndConditions: TermsAndConditionsDialogActionService,
  ) { }

  ngOnInit(): void {
    this.service.initializeForm();
    // this.service.openTermsDialog();
    // this.service.openPolicyDialog();
  }

  ngOnDestroy(): void {
    this.service.subscription$.next();
    if (!this.isWidget) {
      this.service.pending$$.value || this.service.openLoginDialog();
    }
  }

  controlError(control: string): boolean {
    if (this.requiredControl(control)) return false;
    return this.service.form.get(control)?.errors !== null;
  }

  requiredControl(control: string): boolean {
    const errors = this.service.form.get(control)?.errors;
    return errors?.hasOwnProperty('required') && errors?.['required'];
  }

}
