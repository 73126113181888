import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { filter, firstValueFrom } from 'rxjs';
import * as moment from 'moment';

import { AddReservationDto, ItineraryReservation } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ComposePrivateNoteDialogComponent } from './compose-private-note-dialog.component';
import { clientVID } from '@hiptraveler/common';

@Injectable()
export class ComposePrivateNoteDialogService {

  form: FormGroup = this.fb.group({
    // Configurations
    id: [ '' ], dbId: [ '' ], objId: [ '' ], param: [ '' ], isBooked: [ true ],
    // Form fields
    notes: [ '' ], reference: [ '' ], contact: [ '' ], objName: [ '' ], location: [ '' ],
    checkIn: [ '' ], checkOut: [ '' ],
    // To parse
    startDate: [ '' ], endDate: [ '' ]
  });

  saveNotePending: boolean;
  deleteNotePending: boolean;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private dialogRef: MatDialogRef<ComposePrivateNoteDialogComponent>,
    private store: Store
  ) { }

  get reservation(): ItineraryReservation | undefined {
    const reservations = this.store.selectSnapshot(ItineraryState.itineraryReservations) || [];
    return reservations.find(e => e?.dbId === this.parsedFormValue.dbId);
  }

  authenticationObserver(): void {
    const visitorOwner = this.store.selectSnapshot(ItineraryState.basicInfo)?.author?.authorProfId === clientVID();
    this.store.select(AuthState.authenticated).pipe(
      filter(e => !e && !visitorOwner)
    ).subscribe(() => this.dialogRef.close());
  }

  setFormDates(data: Partial<AddReservationDto>): void {
    this.form.patchValue({
      startDate: moment(data?.checkIn?.date, "MM/DD/YYYY").toDate(),
      endDate: moment(data?.checkOut?.date, "MM/DD/YYYY").toDate()
    });
  }

  async saveNote(): Promise<void> {

    this.dialogRef.disableClose = true;

    try {
      this.saveNotePending = true;
      await firstValueFrom(this.store.dispatch(new ItineraryAction.AddReservation({
        ...this.parsedFormValue,
        id: this.reservation?.id || 'untitled',
        vId: clientVID()
      })));
      this.dialogRef.close();
    } finally {
      this.saveNotePending = false;
      this.dialogRef.disableClose = false;
    }
  }

  async deleteNote(): Promise<void> {

    this.dialogRef.disableClose = true;

    try {
      this.deleteNotePending = true;
      await firstValueFrom(this.store.dispatch(new ItineraryAction.DeleteReservation({
        id: this.reservation?.id || 'untitled'
      })));
      this.dialogRef.close();
    } finally {
      this.deleteNotePending = false;
      this.dialogRef.disableClose = false;
    }
  }

  private get parsedFormValue(): AddReservationDto {

    const formValue = this.form.value;

    const checkInDate = formValue.startDate ? `${(formValue.startDate.getMonth() + 1).toString().padStart(2, '0')}/${formValue.startDate.getDate().toString().padStart(2, '0')}/${formValue.startDate.getFullYear()}` : '';
    const checkOutDate = formValue.endDate ? `${(formValue.endDate.getMonth() + 1).toString().padStart(2, '0')}/${formValue.endDate.getDate().toString().padStart(2, '0')}/${formValue.endDate.getFullYear()}` : '';

    formValue.checkIn = { date: checkInDate, time: '' };
    formValue.checkOut = { date: checkOutDate, time: '' };

    delete formValue.startDate;
    delete formValue.endDate;

    return formValue
  }

}
