import { Injectable } from '@angular/core';

import { AppListenerService } from '@hiptraveler/common';

const MUTATION_OBSERVER_CONFIG: MutationObserverInit = { attributes: true };

@Injectable()
export class LocationDropdownStateService {

  constructor(
    private appListener: AppListenerService
  ) { }

  observe(element: HTMLDivElement): void {

    const bodyElement: HTMLBodyElement | null = element.parentElement?.parentElement as any;

    if (!bodyElement) return;

    setTimeout(() => {
      const pacContainer = bodyElement.getElementsByClassName('pac-container');
      const elements = Array.from(pacContainer) as HTMLDivElement[];
      const element = elements?.find(e => +e.style.left.replace('px', '') < 30);

      if (!element) return;

      const observer = new MutationObserver((mutationsList: MutationRecord[]) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            const display = (mutation.target as HTMLDivElement).style.display !== 'none';
            this.appListener.searchFilterDropdownState$$.next(display);
          }
        }
      });
  
      observer.observe(element, MUTATION_OBSERVER_CONFIG);
    }, 1000);


  }

}
