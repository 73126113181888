import { Component, Input, ViewEncapsulation } from '@angular/core';

import { TravelAgentData } from '@hiptraveler/data-access/api';

@Component({
  selector: 'ht-ta-card',
  templateUrl: './ta-card.component.html',
  styleUrls: ['./ta-card.component.scss'],
  host: { class: 'ht-ta-card' },
  encapsulation: ViewEncapsulation.None
})
export class TaCardComponent {

  @Input() travelAgent: TravelAgentData;
  @Input() tags?: string[];

}
