import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { DialogToolbarActionsComponent } from './dialog-toolbar-actions.component';
import { TranslateModule } from '@ngx-translate/core';

const MODULES = [
  CommonModule,
  MatRippleModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule
];

const COMPONENTS = [
  DialogToolbarActionsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    DialogToolbarActionsComponent
  ]
})
export class DialogToolbarActionsModule { }
