import { Injectable } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';

import { serverSideShellHTML } from './server-side-shell-html';
import { isWidget } from '@hiptraveler/common';

@Injectable()
export class ServerSideShellService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private elementRef: ElementRef<HTMLDivElement>,
    private renderer: Renderer2
  ) { }

  initialize(): void {

    if (isWidget()) return;

    if (isPlatformBrowser(this.platformId) && this.element.parentElement) {

      const appShell: HTMLDivElement = this.element.parentElement?.parentElement?.getElementsByClassName('app-shell').item(0) as any;

      if (!appShell) return

      appShell.style.position = 'fixed';
      appShell.style.opacity = '0';
      appShell.style.zIndex = '-20';

      while (appShell.firstChild) {
        this.renderer.removeChild(appShell, appShell.firstChild);
      }
    }

    if (isPlatformServer(this.platformId) && this.element.parentElement) {

      const appShell = this.element.parentElement.parentElement?.getElementsByClassName('app-shell').item(0);
  
      const tempDiv = this.renderer.createElement('div');
      tempDiv.innerHTML = serverSideShellHTML;
  
      Array.from(tempDiv.childNodes).forEach((node: any) => {
        this.renderer.appendChild(appShell, node);
      });
    }
  }

  private get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

}
