import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { ProfileResultData } from '@hiptraveler/data-access/api';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';

function findParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
  let parent = element?.parentElement;
  while (parent && !parent.classList.contains(className)) {
    parent = parent?.parentElement;
  }
  return parent || null;
}

@Directive({
  selector: '[profileCardHover]'
})
export class ProfileCardHoverDirective {

  @Output() profileClicked = new EventEmitter<void>();
  @Output() followClicked = new EventEmitter<void>();

  @Input('profileCardHover') data: ProfileResultData;

  constructor(
    private leafletControl: LeafletMapControlStateService
  ) { }

  @HostListener('click', ['$event'])
  hostClicked(event: PointerEvent): void {
    try {
      const target = event?.target as HTMLElement | undefined;
      const value = !!target?.className?.toString()?.includes('profile-card--action');
      const contentActionContainer = findParentWithClass(event?.target as HTMLElement, 'profile-card--action') as HTMLElement;

      !value && !contentActionContainer ? this.profileClicked.emit() : this.followClicked.emit();
    } finally {}
  }

  @HostListener('mouseenter') mouseEnter(): void {
    this.leafletControl.activeSearchResultData$$.next({
      id: this.data.profId || 'unknown',
      title: this.data?.profName || '',
      image: this.data?.profPic || 'https://cdn.filestackcontent.com/A5yG40niNR3q9y7GBglCLz/resize=width:150/auto_image/compress/https://graph.facebook.com/1950539548299920/picture?width=280&height=280',
      locationName: this.data?.profLoc || '',
      address: {
        display_address: this.data?.profLoc || ''
      },
      location: {
        coordinates: [ +this.data.latitude, +this.data.longitude ],
        type: 'Point'
      }
    });
  }

}
