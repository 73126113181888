import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { currentLang } from '@hiptraveler/common';

@Injectable()
export class FooterNavigationService {

  @Select(BrandState.hiptravelerBrand) hiptravelerBrand$: Observable<BrandCampaign>;

  constructor(
    private router: Router
  ) { }

  navigate(option: 'terms' | 'policy' | 'dnsmpi'): void {
    switch (option) {
      case 'terms': {
        this.router.navigate([ currentLang(), 'about-us', 'legal', 'terms' ]);
        break;
      }
      case 'policy': {
        this.router.navigate([ currentLang(), 'about-us', 'legal', 'privacyPolicy' ]);
        break;
      }
      case 'dnsmpi': {
        this.router.navigate([ currentLang(), 'dnsmpi' ]);
        break;
      }
    }
  }

}
