<div class="home-menu">

  <div class="home-menu--display">
    
    <img
      class="home-menu--display-photo object"
      [src]="customStyles?.tripPlannerSvg"
      *ngIf="((service.brandCampaign$ | async)?.campaignCustom?.customCss) as customStyles;else defaultImageView">

    <ng-template #defaultImageView>
      <div class="home-menu--display-photo background" [ngStyle]="{ 'background-image': defaultImage }"></div>
    </ng-template>

  </div>

  <div class="home-menu--selection">

    <p class="selection--title" translate="exp-finder.exp-finder-title" [translateParams]="{ value: service.brandName$ | async }"></p>
    <div class="selection--brand"><p translate="exp-finder.powered-by"></p> <p class="selection--action">HipTraveler</p></div>
    <p class="selection--info" translate="exp-finder.exp-finder-description-default"></p>

    <div class="selection--buttons">
      <div class="selection--button" matRipple (click)="stateService.dialogView$$.next('travel-style')">
        <mat-icon svgIcon="experience-finder-screen-shared.planning-icon-svg"></mat-icon>
        <div class="selection--button-info">
          <p class="selection--button-info-text" translate="exp-finder.start-planning"></p>
        </div>
      </div>
      <div class="selection--button" matRipple (click)="stateService.dialogView$$.next('auth-menu')">
        <mat-icon svgIcon="experience-finder-screen-shared.create-profile-icon-svg"></mat-icon>
        <div class="selection--button-info">
          <p class="selection--button-info-text"translate="exp-finder.create-a-profile"></p>
          <p class="selection--button-info-subtext"translate="exp-finder.save-your-trips"></p>
        </div>
      </div>
    </div>

    <p class="selection--details">
      <span translate="exp-finder.exp-finder-access-default"></span>
      <span class="selection--details-icon">
        <span class="selection--details-icon-indicator"></span>
        <mat-icon svgIcon="experience-finder-screen-shared.fav-notif-icon-svg"></mat-icon>
      </span>
      <span translate="exp-finder.page-header"></span>
    </p>

  </div>

  <action-button (click)="stateService.dialogView$$.next('travel-style')">{{ 'exp-finder.button-text-next' | translate }}</action-button>

</div>
