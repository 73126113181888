import { Component } from '@angular/core';

@Component({
  selector: 'use-policy-and-copyright',
  templateUrl: './use-policy-and-copyright.component.html',
  styleUrls: ['../terms-and-privacy-policy-styles.scss'],
})
export class UsePolicyAndCopyrightComponent {

  get currentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

}
