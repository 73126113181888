import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ItineraryDateViewMapModule } from '@hiptraveler/features/leaflet-map';
import { ActivityDayListModule, ActivityDateListModule } from '@hiptraveler/features/itinerary';
import { EditableSelectionsModule, StoryImageModule } from '../common';
import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { ComposeBlogFormComponent } from './compose-blog-form.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ItineraryDateViewMapModule,
  ActivityDayListModule,
  ActivityDateListModule,
  EditableSelectionsModule,
  StoryImageModule,
  CustomMapAutocompleteModule,
  ButtonsModule
];

const COMPONENTS = [
  ComposeBlogFormComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ComposeBlogFormComponent
  ]
})
export class ComposeBlogFormModule { }
