import { ProfileResultField, SearchResultField } from "@hiptraveler/data-access/api";

export function getHipObjectTypeByField(field?: SearchResultField | ProfileResultField): 'album' | 'image' | 'hotel' {

  if (field && [ 'adventures', 'foods', 'imageResults' ].includes(field)) {
    return 'image';
  }

  if (field && [ 'hotels', 'hotelResults' ].includes(field)) {
    return 'hotel';
  } 
  
  if (field && [ 'itineraries', 'itineraryResults', 'blogResults' ].includes(field)) {
    return 'album';
  }
  
  return 'image';
}
