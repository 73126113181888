import { Component, OnDestroy, OnInit } from '@angular/core';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'connect-content',
  templateUrl: './connect-content.component.html',
  styleUrls: ['../search.component.scss'],
  animations: [ opacityFadeIn(600) ]
})
export class ConnectContentComponent implements OnInit, OnDestroy {

  visibility: boolean = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.visibility = true;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.visibility = false;
  }

}
