import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { StepperHeaderModule } from './stepper-header/stepper-header.module';
import { TravelExperienceModule } from './travel-experience/travel-experience.module';
import { SpecializationModule } from './specialization/specialization.module';
import { LanguageSupportModule } from './language-support/language-support.module';
import { DestinationSpecialtiesModule } from './destination-specialties/destination-specialties.module';
import { CertificationsLicensesModule } from './certifications-licenses/certifications-licenses.module';
import { AgentDetailsModule } from './agent-details/agent-details.module';
import { ActivitiesModule } from './activities/activities.module';
import { PromoCodeModule } from './promo-code/promo-code.module';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ActiveSelectionModule, RegistrationFormStateService } from './common';

import { RegistrationFormComponent } from './registration-form.component';
import { TitleByTypePipe } from './title-by-type.pipe';
import { ButtonTextByTypePipe } from './button-text-by-type.pipe';

const MODULES = [
  CommonModule,
  RouterModule,
  TranslateModule
];

const LIBRARIES = [
  StepperHeaderModule,
  TravelExperienceModule,
  SpecializationModule,
  LanguageSupportModule,
  DestinationSpecialtiesModule,
  CertificationsLicensesModule,
  AgentDetailsModule,
  ActivitiesModule,
  PromoCodeModule,
  ButtonsModule,
  ActiveSelectionModule
];

const PROVIDERS = [
  RegistrationFormStateService
];

const COMPONENTS = [
  RegistrationFormComponent
];

const PIPES = [
  TitleByTypePipe,
  ButtonTextByTypePipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  providers: [
    PROVIDERS
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    RegistrationFormComponent
  ]
})
export class RegistrationFormModule { }
