import { Directive, Inject, OnDestroy, PLATFORM_ID } from "@angular/core";
import { isPlatformServer } from "@angular/common";
import { Observable, Subject, filter, takeUntil, tap } from "rxjs";

@Directive()
export class SectionContent implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  checkElementState(element: HTMLElement, $: Observable<unknown[] | null>): void {

    if (isPlatformServer(this.platformId)) return;

    $.pipe(
      filter(Boolean),
      tap((response) => {
        element.style.display = response.length ? 'block' : 'none';
      }),
      takeUntil(this.subscription$)
    ).subscribe()
  }

}
