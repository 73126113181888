import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-container',
  template: `
    <div class="mat-elevation-z2" [ngClass]="{ 'auto-height': autoHeight === '', 'flexible-height': flexibleHeight === ''  }">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: [`./card-container.component.scss`]
})
export class CardContainerComponent {
  @Input() autoHeight: string;
  @Input() flexibleHeight: string;
}
