import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

import { TravelAgentData } from '@hiptraveler/data-access/api';
import { StoryTravelAgentsService } from './story-travel-agents.service';

/**
 * A pipe that determines whether a given travel agent ID is being followed in the story processing.
 *
 * @example
 * ```html
 * <div *ngIf="travelAgent | followProcessing">Currently following this agent's story</div>
 * 
 */
@Pipe({
  name: 'followProcessing'
})
export class FollowProcessingPipe implements PipeTransform {

  constructor(
    private service: StoryTravelAgentsService
  ) { }

  /**
   * Transforms the input travel agent data to an Observable that emits whether the agent is being followed.
   *
   * @param {TravelAgentData} data - The travel agent data containing the ID.
   * @returns {Observable<boolean>} An Observable that emits true if the agent is being followed, false otherwise.
   */
  transform({ profId }: TravelAgentData): Observable<boolean> {
    return this.service.processing$.pipe(
      map((following: string[]) => following.includes(profId))
    );
  }

}

@NgModule({
  declarations: [ FollowProcessingPipe ],
  exports:      [ FollowProcessingPipe ]
})
export class FollowProcessingModule { }
