import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { SectionActionComponent } from './section-action.component';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  SectionActionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SectionActionComponent
  ]
})
export class SectionActionModule { }
