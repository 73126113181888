import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'star-ratings',
  template: `
    <div class="ratings" [attr.data-rating]="rating">
      <ng-container *ngFor="let option of rating | calculateRating;">
        <mat-icon svgIcon="common.star-svg" *ngIf="option === 1"
          [ngStyle]="{ width: size+'px', height: size+'px', transform: 'translate('+offsetX+'px, '+offsetY+'px)' }">
        </mat-icon>
        <mat-icon svgIcon="common.star-off-svg" *ngIf="option === 0"
          [ngStyle]="{ width: size+'px', height: size+'px', transform: 'translate('+offsetX+'px, '+offsetY+'px)' }">
        </mat-icon>
        <mat-icon svgIcon="common.star-half-svg" *ngIf="option === 2"
          [ngStyle]="{ width: size+'px', height: size+'px', transform: 'translate('+offsetX+'px, '+offsetY+'px)' }">
        </mat-icon>
      </ng-container>
    </div>
  `,
  styles: [`
    .ratings {
      display: inline-flex;
      align-items: center;
      height: 18px;
    }
    .ratings .mat-icon {
      transform: translateY(-6px);
      margin: 0 -1.3px;
    }
  `],
  host: { class: 'star-ratings' }
})
export class StarRatingsComponent implements AfterViewInit {

  @Input() rating: number = 0;
  @Input() offsetY: number = 0;
  @Input() offsetX: number = 0;
  @Input() size: number = 0;

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngAfterViewInit(): void {
    if (this.rating === 0) {
      this.element.nativeElement.style.opacity = '0';
    }
  }

}
