import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { PlacesDialogComponent } from './places-dialog.component';

export const PROFILE_DIALOG_ID = 'custom-activity';

@Injectable()
export class PlacesDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<PlacesDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(PROFILE_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(PlacesDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: PROFILE_DIALOG_ID,
      panelClass: PROFILE_DIALOG_ID + '-overlay-panel',
      width: '600px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      maxHeight: 'auto',
      scrollStrategy: this.overlay.scrollStrategies.block()
    })
  }
  
}
