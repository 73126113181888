import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ActivityInputOverlayService } from './activity-input-overlay.service';
import { AppListenerService } from '@hiptraveler/common';
import { ListItem, ListItemType } from '../../list-items';

@Component({
  selector: 'app-activity-input-overlay',
  templateUrl: './activity-input-overlay.component.html',
  styleUrls: ['./activity-input-overlay.component.scss'],
  host: { class: 'search-page-root-filters-mobile-activity-input-overlay' },
  viewProviders: [ ActivityInputOverlayService ],
  encapsulation: ViewEncapsulation.None
})
export class ActivityInputOverlayComponent implements OnInit, OnDestroy {

  type: ListItemType = 'exp';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActivityInputOverlayComponent>,
    private appListener: AppListenerService,
    public service: ActivityInputOverlayService
  ) { }

  ngOnInit(): void {
    this.appListener.mapsAutocompleteShadowState$$.next(false);
  }

  ngOnDestroy(): void {
    this.appListener.mapsAutocompleteShadowState$$.next(true);
  }

  toggleSelection(value: string, travelStyle: boolean): void {

    const variable = travelStyle ? 'selectedTravelStyles$$' : 'selectedItems$$';
    let selected = this.data.filtersService[variable].value.map((e: any) => e.value);
    selected.includes(value)
      ? selected = selected.filter((e: any) => e !== value)
      : selected.push(value);

    const results = this.data.listItems.filter((listItem: ListItem) => selected.includes(listItem.value));
    this.data.filtersService[variable].next(results);
  }

  clearFilters(): void {
    this.dialogRef.close(2)
    this.data.filtersService.clearFilters();
    this.data.filtersService.panelFilterClearClicked$$.next();
  }

}
