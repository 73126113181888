
<div class="ta-card--header">
  <ng-content select="[taBackgroundImage]"></ng-content>
  <ng-content select="[taProfileImage]"></ng-content>
  <ng-content select="ta-flag"></ng-content>
</div>

<div class="ta-card--content">
  <ng-content select="ta-name"></ng-content>
  <ng-content select="ta-location"></ng-content>
</div>

<div class="ta-card--tags" style="opacity: 0;">
  <p>
    <span *ngFor="let tag of tags;let isLast = last">
      {{ tag | lowercase }}<span class="divider" *ngIf="!isLast">•</span>
    </span>
  </p>
</div>

<div class="ta-card--actions">
  <ng-content select="ta-action"></ng-content>
  <ng-content select="ta-action"></ng-content>
</div>
