import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

import { CarouselFilterType, EFAStateServiceService, FormCarouselData } from '../../shared';
import { getScreen } from './get-screen';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  constructor(
    private stateService: EFAStateServiceService
  ) { }

  transform(value: string, selectedFilter: CarouselFilterType): Observable<boolean> {
    return this.stateService.carouselData$.pipe(
      map((data: FormCarouselData) => {
          
        const screen = getScreen(selectedFilter);
        if (screen && [ 'screen3', 'screen4', 'screen5' ].includes(screen)) {
          return (data as any)?.[screen]?.includes(value)
        }

        return false;
      })
    );
  }

}
