import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AssetImageModule } from '../../common';

import { HighlightSelectComponent } from './highlight-select.component';

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  AssetImageModule
];

const COMPONENTS = [
  HighlightSelectComponent,
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    HighlightSelectComponent
  ]
})
export class HighlightSelectModule { }
