import { Component, ElementRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BasicInfoData, ItineraryData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchState } from '@hiptraveler/data-access/search';
import { SectionContent } from '../section-content';
import { subdomain } from '@hiptraveler/common';

@Component({
  selector: 'travel-agents-content',
  templateUrl: './travel-agents-content.component.html',
  styleUrls: ['../search.component.scss']
})
export class TravelAgentsContentComponent extends SectionContent implements AfterViewInit {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;
  @Select(SearchState.itineraries) itineraries$: Observable<ItineraryData[] | null>;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private element: ElementRef<HTMLElement>,
    private store: Store
  ) {
    super(platformId);
  }

  ngAfterViewInit(): void {

    if (subdomain() === 'hiptraveler') {
      this.element.nativeElement.style.display = 'none';
    }

    this.checkElementState(this.element.nativeElement, this.store.select(SearchState.travelAgents));
  }

}
