import { getWindowRef, PromiseReject, PromiseResolve } from "@hiptraveler/common";

export function mediumEditorClient(): Promise<any> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    const windowRef = getWindowRef();

    let
      bodyElement: HTMLBodyElement | null = null,
      scriptElement: HTMLScriptElement | null = null;
  
    if (typeof windowRef === 'object' && windowRef) {
      bodyElement = windowRef?.document?.body as HTMLBodyElement;
      scriptElement = windowRef?.document?.createElement('script') as HTMLScriptElement;
    }
  
    if (!bodyElement || !scriptElement) return;

    scriptElement.src = 'https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/js/medium-editor.min.js';
    scriptElement.async = true;

    bodyElement.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {

        resolve(MediumEditor);
        console.log('Medium Editor script loaded.');
      } catch (innerError) {

        reject(innerError);
        console.error('Error initializing Medium Editor:', innerError);
      }
    };
  });
}
