import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, AfterViewInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs';

import { PlacesDialogService } from './places-dialog.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-places-dialog',
  templateUrl: './places-dialog.component.html',
  styleUrls: ['./places-dialog.component.scss'],
  viewProviders: [ PlacesDialogService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class PlacesDialogComponent implements AfterViewInit {

  @ViewChild('streetAddress') streetAddress: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public service: PlacesDialogService
  ) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.service.placesVisited$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => {
      this.streetAddress.nativeElement.value = '';
    });
  }

}
