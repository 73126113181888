<div class="custom-activity-processing-overlay" *ngIf="stateService.formProcessing$$ | async"></div>

<dialog-close-button style="left: 0;"></dialog-close-button>

<div class="custom-activity">

  <dialog-toolbar-actions scrollElementBorder (actionClicked)="service.saveChanges()"></dialog-toolbar-actions>

  <card-container>
    <card-section noPaddingBottom>
      <cover-image [dialog]="dialog"></cover-image>
    </card-section>
  </card-container>

  <card-container>
    <card-section [header]="'custom-activity-dialog.section.title.gallery-images' | translate">
      <gallery-images></gallery-images>
    </card-section>
  </card-container>

  <card-container>
    <card-section [header]="'custom-activity-dialog.section.title.title' | translate">
      <main-info></main-info>
    </card-section>
  </card-container>

  <card-container>
    <card-section [header]="'custom-activity-dialog.section.title.about-activity' | translate">
      <about></about>
    </card-section>
  </card-container>

  <card-container>
    <card-section [header]="'custom-activity-dialog.section.title.category' | translate">
      <categories></categories>
    </card-section>
  </card-container>

  <card-container>
    <card-section [header]="'custom-activity-dialog.section.title.highlights' | translate">
      <highlights></highlights>
    </card-section>
    <card-section [header]="'custom-activity-dialog.section.title.whats-included' | translate">
      <included></included>
    </card-section>
    <card-section [header]="'custom-activity-dialog.section.title.whats-not-included' | translate">
      <not-included></not-included>
    </card-section>
  </card-container>

  <card-container autoHeight>
    <card-section [header]="'custom-activity-dialog.section.title.contact-info' | translate">
      <contact-info></contact-info>
    </card-section>
  </card-container>

  <card-container flexibleHeight>
    <card-section [header]="'custom-activity-dialog.section.title.hours-prices' | translate">
      <hours-prices></hours-prices>
    </card-section>
  </card-container>

</div>
