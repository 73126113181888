export const clientInfoPrompts: string[] = [
  `
    The user will input the number of travelers in this format: 'X adults, Y children, Z infants'. Extract the count of adults, children, and infants separately. For example, if the user types '2 adults, 1 child, 1 infant', the output should be:
    Adults: 2
    Children: 1
    Infants: 1
  `,
  `
    The user might describe the travelers in a sentence, like 'We are 2 adults, 1 child, and 1 infant'. Parse the sentence to extract the number of adults, children, and infants. For instance, if the user inputs '3 adults and 2 children', output should be:
    Adults: 3
    Children: 2
    Infants: 0
  `,
  `
    The user may input general phrases such as 'Family of 4 with 2 kids'. Determine the number of adults, children, and infants from these descriptions. If uncertain, assume unspecified travelers are adults. For example, for input 'Family of 4 with 2 kids', output:
    Adults: 2
    Children: 2
    Infants: 0
  `,
  `
    The user might describe the group in various formats, like '2 adults with 2 kids', 'Just me and my toddler', or '3 adults, 2 kids, 1 baby'. Extract the count of adults, children, and infants based on their description. For example:
    - '2 adults with 2 kids' should yield:
      Adults: 2
      Children: 2
      Infants: 0
    - 'Just me and my toddler' should yield:
      Adults: 1
      Children: 1
      Infants: 0
    - '3 adults, 2 kids, 1 baby' should yield:
      Adults: 3
      Children: 2
      Infants: 1
  `,
  `
    The user may use descriptions like 'Couple with a baby' or 'Single adult with two children'. Interpret such phrases to identify the number of adults, children, and infants. For example:
    - 'Couple with a baby' should yield:
      Adults: 2
      Children: 0
      Infants: 1
    - 'Single adult with two children' should yield:
      Adults: 1
      Children: 2
      Infants: 0
  `,
  `
    Response in this format:

    interface FormClientInfo {
      adults: number;
      children: number;
      infants: number;
    }

    Make sure to return these screen_7_client_info_data as a stringified JSON
    ex. "{ "adults": "2", "children": "2", "infants": "1" }"
  `
];

/*

  Supported user inputs

  // Standard Input Format
  
  2 adults, 1 child, 1 infant
  3 adults, 2 children
  1 adult, 2 infants
  
  // Single Sentence Input
  
  We are 2 adults, 1 child, and 1 infant
  Just me and my 3 kids
  Traveling with 2 adults and 2 children
  
  // General Phrasing
  
  Family of 4 with 2 kids
  Group of 6 with 1 baby
  Family trip for 2 adults and 3 children
  
  // Flexible and Mixed Inputs
  
  2 adults with 2 kids
  Just me and my toddler
  3 adults, 2 kids, 1 baby
  Couple with a newborn
  Single adult with two children
  
  // Count Interpretation for Phrases

  Couple with a baby
  Single traveler
  Family of 5 with 3 children
  One adult, 2 kids, and an infant
  Two adults, 1 child
  One parent and two toddlers
  Group of 10 with 3 children and 2 infants
  
  // Edge Cases

  3 adults only
  2 kids, no adults
  One infant
  2 adults, no kids
  One adult and one infant

*/
