import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MoreContentDetailsDialogService } from './more-content-details-dialog.service';
import { DialogData } from './more-content-details-dialog-action.service';

@Component({
  selector: 'app-more-content-details-dialog',
  template: `
    <dialog-close-button></dialog-close-button>

    <h1>{{ data.label | translate }}</h1>

    <mat-dialog-content>
      <div [innerHTML]="data.display | storyTextDisplay | safeHtml"></div>
    </mat-dialog-content>
  `,
  styles: [`
    #more-content-details h1 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    #more-content-details p {
      letter-spacing: 0.03em;
    }
    #more-content-details {
      color: var(--ht-text-color);

      @media screen and (max-width: 550px) {
        border-radius: 0 !important;
      }
    }
  `],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ MoreContentDetailsDialogService ]
})
export class MoreContentDetailsDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public ref: MatDialogRef<MoreContentDetailsDialogComponent>,
    public service: MoreContentDetailsDialogService
  ) { }

}
