<dialog-close-button></dialog-close-button>

<h1 translate="hotel-offers.title"></h1>

<div class="hotel-offers--list" scrollElementBorder adjustOffset>

  <div class="hotel-offers--list-item" [class.last]="isLast" *ngFor="let offer of data;let isLast = last">
    <div>
      <img [src]="offer.logo" alt="offer logo" draggable="false">
      <p class="ht-digit">{{ offer.currency | parseCurrency: offer.avgRate }}</p>
      <p>{{ offer.meal_plan }}</p>
    </div>
    <div>
      <ui-action-button (click)="navigateOffer(offer?.deeplink)">{{ 'hotel-offers.button-text' | translate }}</ui-action-button>
    </div>
  </div>

</div>
