import { Component, OnDestroy, OnInit } from '@angular/core';

import { CommunityService } from '@hiptraveler/modules/page--community';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { RequestCancellationService, profilePlaceholder } from '@hiptraveler/common';
import { DataAccessService, skeletons } from '../../../common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'followers-tab',
  templateUrl: './followers-tab.component.html',
  viewProviders: [ CommunityService, AuthDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ]
})
export class FollowersTabComponent implements OnInit, OnDestroy {

  profilePlaceholder: string = profilePlaceholder;
  skeletons: number[] = skeletons;

  constructor(
    private requestCancellation: RequestCancellationService,
    public communityService: CommunityService,
    public dataAccess: DataAccessService
  ) { }

  ngOnInit(): void {
    this.dataAccess.getProfileFollowersUserResults();
  }

  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
    this.requestCancellation.cancelAllProfileSearchRequests();
    this.dataAccess.resetProfileStateByField([ 'followerResults', 'pagination' ]);
  }

}
