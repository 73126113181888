import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, distinctUntilChanged, map, skip, takeUntil, tap } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { AppListenerService } from '@hiptraveler/common';

@Directive({
  selector: '[facebookIframe]'
})
export class FacebookIframeDirective implements OnInit, OnDestroy {

  parentElement: HTMLDivElement;
  destroy$ = new Subject<void>();
  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private store: Store,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {

    this.parentElement = this.renderer.parentNode(this.element.nativeElement);

    this.destroy$.next()
    setTimeout(() => this.setInitialIframe());

    this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth <= 750),
      distinctUntilChanged(),
      skip(1),
      tap(() => this.destroy$.next()),
      takeUntil(this.subscription$)
    ).subscribe((state: boolean) => state ? this.setInitialIframe(300) : this.setInitialIframe());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.subscription$.next();
  }

  private setInitialIframe(width: number = 500): void {

    const iframeElement = this.getFBIframeElement(width);
    this.parentElement.appendChild(iframeElement);

    this.destroy$.asObservable().subscribe(() => {
      this.renderer.removeChild(this.parentElement, iframeElement);
    });
  }

  private getFBIframeElement(width: number = 500): HTMLIFrameElement {

    const brand = this.store.selectSnapshot(BrandState.campaignCustom)?.socialLinks?.facebook;

    const source = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${brand}&tabs=timeline&width=${width}&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=717552431619054&autoLogAppEvents=1`;

    const iframeElement = this.renderer.createElement('iframe') as HTMLIFrameElement;

    iframeElement.src = source;
    iframeElement.style.border = 'none';
    iframeElement.style.overflow = 'hidden';
    iframeElement.allow = 'autoplay; clipboard-write; encrypted-media; picture-in-picture;';
    iframeElement.classList.add('facebook-iframe');

    this.renderer.setAttribute(iframeElement, 'scrolling', 'no');
    this.renderer.setAttribute(iframeElement, 'frameborder', '0');
    this.renderer.setAttribute(iframeElement, 'allowfullscreen', 'true');
    
    return iframeElement;
  }

}

