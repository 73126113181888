import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { CustomActivityDialogService } from './custom-activity-dialog.service';
import { DialogStateService } from './common/dialog-state.service';
import { RequestCancellationService, currentLang, getWindowRef, selectedClientLangBeforeAction } from '@hiptraveler/common';

@Component({
  selector: 'app-custom-activity-dialog',
  templateUrl: './custom-activity-dialog.component.html',
  styleUrls: ['./custom-activity-dialog.component.scss', './custom-activity-dialog-responsiveness.scss'],
  viewProviders: [ CustomActivityDialogService, DialogStateService ],
  encapsulation: ViewEncapsulation.None
})
export class CustomActivityDialogComponent implements OnInit, OnDestroy {

  constructor(
    public dialog: MatDialogRef<CustomActivityDialogComponent>,
    public translation: TranslateService,
    public requestCancellation: RequestCancellationService,
    public service: CustomActivityDialogService,
    public stateService: DialogStateService
  ) { }

  ngOnInit(): void {
    getWindowRef()[selectedClientLangBeforeAction] = currentLang();
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
    this.translation.use(getWindowRef()?.[selectedClientLangBeforeAction] || 'en');
  }

}
