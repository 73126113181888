import { Pipe, PipeTransform } from '@angular/core';

import { ListItem } from '../../list-items';

@Pipe({
  name: 'selection'
})
export class SelectionPipe implements PipeTransform {

  transform(item: ListItem, selected: string[]): boolean {
    return selected.includes(item.value);
  }

}
