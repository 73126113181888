import { Provider } from "@angular/core";

import { FormSubmitService } from "./form-submit.service";
import { FormFeatureService } from "./form-feature.service";
import { FormEventService } from "./form-event.service";

export * from './form-submit.service';
export * from './form-feature.service';
export * from './form-event.service';

export const viewProviders: Provider[] = [
  FormSubmitService,
  FormFeatureService,
  FormEventService
];
