import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { BehaviorSubject, Subject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollListenerService {

  #scrollPositionClient$$ = new BehaviorSubject<number>(0);
  scrollPositionClient$ = this.#scrollPositionClient$$.asObservable().pipe(
    distinctUntilChanged()
  );

  #scrollPosition$$ = new Subject<number>();
  scrollPosition$ = this.#scrollPosition$$.asObservable().pipe(
    distinctUntilChanged()
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) { }

  runScrollObserver(): void {
    if (isPlatformServer(this.platformId)) return;
    const scrollTop = this.document.documentElement.scrollTop || this.document.body.scrollTop;
    this.#scrollPosition$$.next(scrollTop);
    this.#scrollPositionClient$$.next(scrollTop);
  }

}
