import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';

import { NewOperatorDialogComponent } from './new-operator-dialog.component';

const MODULES = [
  CommonModule,
  FormsModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  DialogCloseButtonModule
];

const COMPONENTS = [
  NewOperatorDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class NewOperatorDialogModule { }
