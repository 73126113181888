import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { TripAdvisorModule } from '../../components';
import { ExternalLinkDirectiveModule, ParseCurrencyPipeModule } from '@hiptraveler/common';

import { PriceOffersComponent } from './price-offers.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  TripAdvisorModule,
  ParseCurrencyPipeModule,
  ExternalLinkDirectiveModule
];

const COMPONENTS = [
  PriceOffersComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    PriceOffersComponent
  ]
})
export class PriceOffersModule { }
