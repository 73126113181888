import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { HighlightCarouselService } from './highlight-carousel.service';
import { DialogType, SearchResultDialogControlService, SearchResultDialogStateService, animationDelay } from '../../common';
import { opacityFade } from '@hiptraveler/animations';
import { SearchPageControlStateService, WINDOW } from '@hiptraveler/common';

@Component({
  selector: 'highlight-carousel',
  templateUrl: './highlight-carousel.component.html',
  styleUrls: ['../highlight-styles.scss'],
  host: { class: 'highlight' },
  viewProviders: [ HighlightCarouselService, AuthDialogActionService, PromptDialogActionService ],
  animations: [ opacityFade(animationDelay) ],
  encapsulation: ViewEncapsulation.None
})
export class HighlightCarouselComponent {

  @Input() data?: ImageResultData;
  @Input() dialogType: DialogType;
  @Input() ltsSupport: boolean;
  @Input() addToTripSignal: Subject<boolean>;
  
  activePhoto?: string;
  currentIndex: number = 0;

  constructor(
    @Inject(WINDOW) private window: any,
    private store: Store,
    public service: HighlightCarouselService,
    public searchPageControl: SearchPageControlStateService,
    public stateService: SearchResultDialogStateService,
    public control: SearchResultDialogControlService
  ) { }

  nextImage(option: 'next' | 'prev'): void {

    const photos = this.store.selectSnapshot(ItineraryState.imageInformation)?.photos || [];

    if (option === 'prev' && (this.currentIndex === 0)) {
      this.currentIndex = photos.length;
      this.activePhoto = photos?.[this.currentIndex];
    }
    if (option === 'prev') {
      this.activePhoto = photos?.[--this.currentIndex];
    }
    if (option === 'next' && this.currentIndex < photos.length) {
      this.activePhoto = photos?.[++this.currentIndex];
    }
    if (option === 'next' && (this.currentIndex === photos.length)) {
      this.currentIndex = 0;
      this.activePhoto = photos?.[this.currentIndex];
    }
  }

  updateHighlightImage(photos: string[] | null, index: number): void {
    this.activePhoto = (photos || [])[index];
    this.currentIndex = index;
  }

  goToAffiliateSource(source?: string): void {
    source && this.window.open(source, '_blank');
  }

}
