import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, firstValueFrom, map, takeUntil, tap } from 'rxjs';

import { BrandCampaign, UserProfile } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ProfileAction, ProfileState, ProfileStateModel } from '@hiptraveler/data-access/profile';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { AppListenerService, pendingAuthProcessKey } from '@hiptraveler/common';

@Injectable()
export class UserInfoService implements OnDestroy {

  @Select(UserState.id) id$: Observable<string | null>;
  @Select(ProfileState.profileDetails) profileDetails$: Observable<UserProfile | null>;
  @Select(BrandState.hiptravelerBrand) hiptraveler$: Observable<Partial<BrandCampaign> | null>;

  followProcessing$$ = new BehaviorSubject<boolean>(false);
  authHandle$ = new Subject<void>();

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private sendMessageDialog: SendMessageDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }

  get followStatus$(): Observable<boolean> {
    return this.store.select(ProfileState.state).pipe(
      map((state: ProfileStateModel | null) => !!state?.followStatus)
    );
  }

  get picUrl$(): Observable<string> {
    return this.store.select(ProfileState.profileDetails).pipe(
      map((userState: UserProfile | null) => userState?.profileImgUrl || '')
    );
  }

  sendMessage(): void {
    
    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'profileSendMessage';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(this.sendMessage.bind(this));
      return;
    }

    const profileDetails = this.store.selectSnapshot(ProfileState.profileDetails)!;
    this.sendMessageDialog.open({ profileId: profileDetails.profileId });
  }
  
  async follow(): Promise<void> {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'profileFollow';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(this.follow.bind(this));
      return;
    }

    const profileState = this.store.selectSnapshot(ProfileState.state)!;
    if (this.followProcessing$$.value || profileState.followStatus) return;
    try {
      this.followProcessing$$.next(true);
      await firstValueFrom(this.store.dispatch(new ProfileAction.FollowProfileById(profileState.profileDetails.profileId)))
    } finally {
      this.followProcessing$$.next(false);
    }
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
