
<ng-container *ngIf="(route.data | async)?.['pageType'] === 'terms-and-conditions'">
  <terms-and-conditions></terms-and-conditions>
</ng-container>

<ng-container *ngIf="(route.data | async)?.['pageType'] === 'privacy-policy'">
  <privacy-policy></privacy-policy>
</ng-container>

<contact-view></contact-view>

<br><br>
