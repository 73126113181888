import { Component, ElementRef, OnInit } from '@angular/core';
import { map, skip, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { MeetingPointService } from './meeting-point.service';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'meeting-point',
  templateUrl: './meeting-point.component.html',
  styleUrls: ['./meeting-point.component.scss'],
  viewProviders: [ MeetingPointService ],
  host: { class: 'content' },
  animations: [ opacityFade(animationDelay) ]
})
export class MeetingPointComponent implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: MeetingPointService
  ) { }

  ngOnInit(): void {
    this.service.imageInformation$.pipe(
      skip(1),
      map((response: ImageResultData) => {
        const visibility = 
        !!response?.tourData?.logistics?.departureTimeComments
        || !!response?.tourData?.logistics?.departDetails;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
  }

}
