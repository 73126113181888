
<div class="activity-date-list-skeleton">

  <ng-container *ngIf="actDate | async;else activityDayListSkeleton">

    <ng-content></ng-content>

  </ng-container>
  <ng-template #activityDayListSkeleton>

    <ng-container *ngFor="let _ of loop;let isLast = last">
    
      <div class="skeleton--line"></div>
      
      <div class="skeleton--header">
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
      </div>
      
      <div class="skeleton--content">
        <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        <div class="skeleton--content-child1">
          <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
        </div>
      </div>
    
      <ng-container *ngIf="!isLast">
        <br><br>
      </ng-container>
    
    </ng-container>

  </ng-template>

</div>
