import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { AuthState } from '@hiptraveler/data-access/auth';
import { AuthRegisterDialogActionService } from './auth-register-dialog/auth-register-dialog-action.service';
import { AuthDialogService } from './auth-dialog.service';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';
import { hiptravelerLogoBase46 } from './hiptraveler-logo-base64';
import { DialogDataView } from './auth-dialog-action.service';
import { AppListenerService, isWidget, pendingAuthProcessKey } from '@hiptraveler/common';

enum DialogView {
  Login,
  ForgotPassword,
  ForgotPasswordVerification,
  ForgotPasswordCreatePassword,
}

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
  viewProviders: [ AuthRegisterDialogActionService, AuthDialogService ],
  animations: [ opacityFade(), opacityFadeIn() ],
  encapsulation: ViewEncapsulation.None
})
export class AuthDialogComponent implements OnInit, OnDestroy {

  hiptravelerLogoBase46: string = hiptravelerLogoBase46;
  dialogView: DialogView;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogDataView,
    private element: ElementRef<HTMLElement>,
    private appListener: AppListenerService,
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    private store: Store,
    public service: AuthDialogService,
    private authRegisterDialogActionService: AuthRegisterDialogActionService
  ) { }

  ngOnInit(): void {

    isWidget() && this.wrapperElement?.add('widget');

    this.service.observeAuthentication();
    this.service.brandCampaignStateObserver();
    this.appListener.allowButtonNavbarVisibilityEvent();

    // this.createAccount();
    // this.forgotPassword();
    // this.forgotPassword('verification');
    // this.forgotPassword('create-password');
    // return;
    switch (this.data) {
      case 'login': return this.initialView();
      case 'register': return this.createAccount();
      case 'forgot-password': return this.forgotPassword();
      default: this.initialView();
    }
  }

  ngOnDestroy(): void {
    isWidget() || this.service.snackbar.dismiss();
    this.appListener.bottomNavbarVisibility$$.next(true);
    this.store.selectSnapshot(AuthState.authenticated) || sessionStorage.removeItem(pendingAuthProcessKey);
  }
  
  get wrapperElement(): DOMTokenList | undefined {
    return this.element.nativeElement.parentElement?.parentElement?.classList;
  }
  
  initialView(): void {
    this.dialogView = DialogView.Login;
  }

  forgotPassword(option?: 'verification' | 'create-password'): void {
    !option && (this.dialogView = DialogView.ForgotPassword);
    option === 'verification'    && (this.dialogView = DialogView.ForgotPasswordVerification);
    option === 'create-password' && (this.dialogView = DialogView.ForgotPasswordCreatePassword);
    this.updateViewWrapperClass(option);
  }

  updateViewWrapperClass(option?: 'verification' | 'create-password'): void {
    this.wrapperElement?.remove('verification', 'create-password')
    if (!isWidget() || !option) return;
    this.wrapperElement?.add(option);
  }
  
  createAccount(): void {
    this.appListener.preventButtonNavbarVisibilityEvent();
    (this.authRegisterDialogActionService.open() as any)?.afterOpened().subscribe(
      () => this.dialogRef.close()
    );
  }

}
