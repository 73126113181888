import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'about',
  template: `
    <mat-form-field [formGroup]="form" style="display: block;">
      <mat-label translate="custom-activity-dialog.section.form.enter-description"></mat-label>
      <textarea matInput [placeholder]="'custom-activity-dialog.section.form.enter-description' | translate" type="text" rows="8" name="description" formControlName="desc"></textarea>
    </mat-form-field>
  `
})
export class AboutComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup
  ) { }

}
