import { Pipe, PipeTransform } from '@angular/core';

import { FiltersStateService } from '../common';
import { ListItem } from '../list-items';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'checked'
})
export class CheckedPipe implements PipeTransform {

  constructor(
    public filtersService: FiltersStateService
  ) { }

  transform(value: ListItem): Observable<boolean> {
    return this.filtersService.selectedItems$$.asObservable().pipe(
      map((selected: ListItem[]) => !!selected.find(e => e.value === value.value))
    );
  }

}
