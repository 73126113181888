<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-booking-url"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-booking-url' | translate" type="text" name="booking" formControlName="booking">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-wikipedia-url"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-wikipedia-url' | translate" type="text" name="wiki" formControlName="wiki">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-personal-website"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-personal-website' | translate" type="text" name="website" formControlName="website">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-email"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-email' | translate" type="text" name="email" formControlName="email">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-phone"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-phone' | translate" type="text" name="phone" formControlName="phone">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-whatsapp"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-whatsapp' | translate" type="text" name="whatsapp" formControlName="whatsapp">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-twitter"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-twitter' | translate" type="text" name="twitter" formControlName="twitter">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-facebook"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-facebook' | translate" type="text" name="facebook" formControlName="facebook">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-instagram"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-instagram' | translate" type="text" name="instagram" formControlName="instagram">
</mat-form-field>

<mat-form-field [formGroup]="formGroup">
  <mat-label translate="custom-activity-dialog.section.form.enter-pinterest"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-pinterest' | translate" type="text" name="pinterest" formControlName="pinterest">
</mat-form-field>
