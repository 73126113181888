import { NgModule } from '@angular/core';

import { MouseDragDirective } from './mouse-drag.directive';

const DIRECTIVES = [
  MouseDragDirective
];

@NgModule({
  declarations: DIRECTIVES,
  exports: [ MouseDragDirective ]
})
export class MouseDragDirectiveModule { }
