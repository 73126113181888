<form class="auth-dialog--form" (ngSubmit)="service.forgotPassword()" [formGroup]="service.form">

  <p class="auth-dialog--form-title" translate="auth.forgot-password"></p>
  
  <p class="auth-dialog--form-sub-title" translate="auth.enter-email-instruction"></p>
  
  <div class="auth-dialog--form-field">
    <mat-form-field>
      <mat-label>{{ 'auth.enter-email' | translate }}</mat-label>
      <input matInput type="text" name="email" formControlName="email">
      <mat-error *ngIf="controlError('email')">{{ 'auth.invalid-email-format' | translate }}</mat-error>
      <mat-error *ngIf="requiredControl('email')">{{ 'auth.email-required' | translate }}</mat-error>
    </mat-form-field>
  </div>

  <ui-action-button type="submit" [pending]="!!(service.pending$$ | async)" ripple>{{ 'auth.send-link' | translate }}</ui-action-button>

  <p class="auth-dialog--form-action" *ngIf="!service.isWidget">
    <mat-icon svgIcon="auth-dialog.caret-back-icon-svg"></mat-icon>
    <span (click)="allSignInOptionsClicked.emit()" translate="auth.all-sign-in-options"></span>
  </p>

</form>
