import { Pipe, PipeTransform } from '@angular/core';

import { TranslationService } from '@hiptraveler/common';

interface Duration {
  value: number;
  unit: string
}

@Pipe({
  name: 'unitTranslation'
})
export class UnitTranslationPipe implements PipeTransform {

  constructor(
    private i18n: TranslationService
  ) { }

  transform({ unit, value }: Duration): string {

    if (unit?.includes('minute') || unit?.includes('mins')) {
      return value > 1 ? this.text('minutes') : this.text('minute');
    }

    if (unit?.includes('hour') || unit?.includes('hrs')) {
      return value > 1 ? this.text('hours') : this.text('hour');
    }

    if (unit?.includes('day')) {
      return value > 1 ? this.text('days') : this.text('day');
    }
    
    return '';
  }

  text(value: string): string {
    return this.i18n.getText('cards', `duration-${value}`);
  }

}
