<div class="activities">

  <p class="section-title" translate="reg-form.select-activities"></p>

  <div class="activities--chip-selection">
    <ng-container *ngIf="service.experiences$ | async as experiences">
      <div class="activities--chip-selection-item" *ngFor="let experience of experiences">
        <p translate class="activities--chip-selection-item-content" matRipple [class.active]="selection.includes(experience?.code!)" (click)="toggleSelection(experience.code)">{{ (experience?.tname || experience?.name || '') | translate }}</p>
      </div>
      <div class="activities--chip-selection-item" *ngFor="let activity of customActivities">
        <p translate class="activities--chip-selection-item-content active" matRipple (click)="toggleSelection(activity, true)">
          <mat-icon>close</mat-icon>{{ activity }}
        </p>
      </div>
    </ng-container>
    <div class="activities--chip-selection-item custom-style" (click)="openNewActivity()">
      <p translate class="activities--chip-selection-item-content" matRipple>
        <mat-icon>add</mat-icon>
        <span translate="reg-form.add-custom"></span>
      </p>
    </div>
  </div>
  
</div>
