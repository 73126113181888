<form class="overlay-trip-planning--form" [formGroup]="formFeature.form" (ngSubmit)="submit()" customStyles>

  <div class="overlay-trip-planning--form-field" customMapAutocomplete emitOnly (locationChanged)="locationChanged($event)">
    <mat-icon>location_on</mat-icon>
    <input type="text" [placeholder]="'exp-finder.placeholder-destination' | translate" name="place" autocomplete="off" formControlName="place" #placeAutocomplete>
  </div>
  <div class="overlay-trip-planning--form-field" (click)="travelDurationSelectForm.open()">
    <input type="text" [placeholder]="'exp-finder.placeholder-duration' | translate" readonly [value]="formFeature.form.value.days | selectDisplay: formFeature.travelDurationSelect$ | async">
    <mat-select formControlName="days" #travelDurationSelectForm>
      <mat-option *ngFor="let experience of formFeature.travelDurationSelect$ | async" [value]="experience.code">
        {{ experience.name ? (experience.name | translate) : '' }}
      </mat-option>
    </mat-select>
  </div>

  <div class="overlay-trip-planning--form-field-wrapper">
    <p class="overlay-trip-planning--form-field-label" translate="exp-finder.exact-dates"></p>
    <div class="overlay-trip-planning--form-field" (click)="picker.open()">
      <mat-icon>date_range</mat-icon>
      <input type="text" [placeholder]="'exp-finder.placeholder-date-range' | translate" readonly name="date-range" [value]="formFeature.dateRangeDisplay$$ | async">
      <mat-form-field appearance="fill">
        <mat-date-range-input [rangePicker]="picker" [min]="minDate">
          <input matStartDate [placeholder]="'exp-finder.placeholder-start-date' | translate" name="start-date" formControlName="startDate">
          <input matEndDate [placeholder]="'exp-finder.placeholder-end-date' | translate" name="end-date" formControlName="endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="experience-finder-trip-planning-form-date-picker-panel" #picker>
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel (click)="formFeature.clearDateField()">Clear</button>
            <button mat-raised-button matDatepickerApply>Apply</button>
          </mat-datepicker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <mat-radio-group formControlName="itinerary">
    <ng-container *ngIf="formFeature.hiptravelerBrand$ | async;else nonHipTravelerBrand">

      <mat-radio-button value="own" disableRipple>
        {{ 'exp-finder.build-itinerary' | translate }}
      </mat-radio-button>
      <mat-radio-button value="skip" disableRipple>
        {{ 'exp-finder.skip-itinerary' | translate }}
      </mat-radio-button>

    </ng-container>
    <ng-template #nonHipTravelerBrand>

      <mat-radio-button value="auto" disableRipple>
        {{ 'exp-finder.have-ht-build' | translate }} {{ 'exp-finder.build-itinerary-2' | translate }}
      </mat-radio-button>
      <mat-radio-button value="own" disableRipple>
        {{ 'exp-finder.build-itinerary' | translate }}
      </mat-radio-button>

    </ng-template>
  </mat-radio-group>

</form>
