import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";

import { ActivityDateData } from "@hiptraveler/data-access/api";
import { PromptDialogActionService, PromptDialogStateService } from "@hiptraveler/dialogs/prompt-dialog";
import { currentLang, getWindowRef, i18nServiceStoreKey } from "@hiptraveler/common";

interface DialogCaseParam {
  promptDialog: PromptDialogActionService;
  promptDialogState: PromptDialogStateService;
  activity: ActivityDateData;
}

function getText(value: string): string {
  const i18n: TranslateService = getWindowRef()[i18nServiceStoreKey];
  return i18n.translations[currentLang()]['itinerary-action'][`${value}`] || '';
}

async function executeCallback({ promptDialog, promptDialogState, activity }: DialogCaseParam, callback: (activity: ActivityDateData) => Promise<void>) {
  try {
    promptDialog.instance.disableClose = true;
    promptDialogState.updateButtonState(1, true);
    await callback(activity);
  } finally {
    promptDialog.close();
    promptDialog.instance.disableClose = false;
    promptDialogState.updateButtonState(1, false);
  }
}

/**
 * Function to handle a scenario where an item is already included in the trip
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 */
export function itemAlreadyIncluded({ promptDialog }: DialogCaseParam, callback: () => void): void {
  promptDialog.open({
    title: getText('dialog-title-info'),
    message: getText('dialog-desc-item-exists'),
    buttons: [
      { name: getText('dialog-button-add-different-day'), theme: 'primary', executable: () => {
        promptDialog.close();
        callback();
        console.log('@@@ ', 'dialog-button-add-different-day');
      } },
      { name: getText('dialog-button-add-anyway'), theme: 'secondary', executable: () => {
        promptDialog.close();
        console.log('@@@ ', 'dialog-button-add-anyway');
      } }
    ]
  });
}

/**
 * Function to handle a scenario where a multi-day tour is being added on a day with existing tours
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 */
export function addingMultiDayTour({ promptDialog }: DialogCaseParam, callback: () => void): void {
  promptDialog.open({
    title: getText('dialog-title-conflict'),
    message: getText('dialog-desc-multi-day-tour-limit'),
    buttons: [
      { name: getText('dialog-button-close'), theme: 'secondary', executable: () => {
        promptDialog.close();
      } },
      { name: getText('dialog-button-add-different-day'), theme: 'primary', executable: () => {
        promptDialog.close();
        callback();
      } }
    ]
  });
}

/**
 * Handle cases where an activity requires island hopping
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 * @param {(activity: ActivityDateData) => Promise<void>} callback - Callback to execute upon confirmation.
 */
export function requireIslandHopping({ promptDialog, promptDialogState, activity }: DialogCaseParam, callback: (activity: ActivityDateData) => Promise<void>, secondaryCallback: () => void, thirdCallback: () => void): void {
  const ref = promptDialog.open({
    title: getText('dialog-title-island-hop'), // Case #5, add-to-trip.pdf
    message: getText('dialog-desc-activity-requirements'),
    buttons: [
      { name: getText('dialog-button-add-different-day'), theme: 'secondary', executable: () => {
        promptDialog.close();
        secondaryCallback();
      } },
      { name: getText('dialog-button-add-anyway'), theme: 'primary', progressSpinner: true, executable: async () => {
        executeCallback({ promptDialog, promptDialogState, activity }, callback);
      } }
    ]
  });
  ref.beforeClosed().pipe(filter(e => !e)).subscribe(() => {
    promptDialog.close();
    thirdCallback();
  });
}

/**
 * Handle cases where a multi-day tour is already added for the day
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 */
export function multiDayTourAdded({ promptDialog }: DialogCaseParam, callback: () => void): void {
  promptDialog.open({
    title: getText('dialog-title-conflict'), // Case #4, add-to-trip.pdf
    message: getText('dialog-desc-multi-day-tour-exists'),
    buttons: [
      { name: getText('dialog-button-close'), theme: 'secondary', executable: () => {
        promptDialog.close();
      } },
      { name: getText('dialog-button-add-different-day'), theme: 'primary', executable: () => {
        promptDialog.close();
        callback();
      } }
    ]
  });
}

/**
 * Handle cases where adding an activity would cause a day overflow
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 * @param {(activity: ActivityDateData) => Promise<void>} callback - Callback to execute upon confirmation.
 */
export function dayOverflowError({ promptDialog, promptDialogState, activity }: DialogCaseParam, callback: (activity: ActivityDateData) => Promise<void>, secondaryCallback: () => void): void {
  promptDialog.open({
    title: getText('dialog-title-conflict'), // Case #2, add-to-trip.pdf
    message: getText('dialog-desc-activity-limit'),
    buttons: [
      { name: getText('dialog-button-add-different-day'), theme: 'secondary', executable: () => {
        promptDialog.close();
        secondaryCallback()
      } },
      { name: getText('dialog-button-add-anyway'), theme: 'primary', progressSpinner: true, executable: async () => {
        executeCallback({ promptDialog, promptDialogState, activity }, callback);
      } }
    ]
  });
}

/**
 * Handle adding a multi-day tour to the trip
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 * @param {(activity: ActivityDateData) => Promise<void>} callback - Callback to execute upon confirmation.
 */
export function addMultiDayTour({ promptDialog, promptDialogState, activity }: DialogCaseParam, callback: (activity: ActivityDateData) => Promise<void>, secondaryCallback: () => void): void {
  promptDialog.open({
    title: getText('dialog-title-conflict'), // Case #3, add-to-trip.pdf
    message: getText('dialog-desc-multi-day-tour-addition'),
    buttons: [
      { name: getText('dialog-button-add-different-day'), theme: 'secondary', executable: () => {
        promptDialog.close();
        secondaryCallback();
      } },
      { name: getText('dialog-button-add-activity'), theme: 'primary', progressSpinner: true, executable: async () => {
        executeCallback({ promptDialog, promptDialogState, activity }, callback);
      } }
    ]
  });
}

/**
 * Handles an activity conflict error.
 * Displays a dialog informing the user and providing options
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 * @param {(activity: ActivityDateData) => Promise<void>} callback - Callback to execute upon confirmation.
 */
export function activityConflictError({ promptDialog, promptDialogState, activity }: DialogCaseParam, callback: (activity: ActivityDateData) => Promise<void>, secondaryCallback: () => void): void {
  promptDialog.open({
    title: getText('dialog-title-conflict'), // Case #1, add-to-trip.pdf
    message: getText('dialog-desc-conflict-duration'),
    buttons: [
      { name: getText('dialog-button-add-different-day'), theme: 'secondary', executable: () => {
        promptDialog.close();
        secondaryCallback();
      } },
      { name: getText('dialog-button-add-anyway'), theme: 'primary', progressSpinner: true, executable: async () => {
        executeCallback({ promptDialog, promptDialogState, activity }, callback);
      } }
    ]
  });
}

/**
 * Informs the user to select a different POI or continue without it due to missing location data.
 *
 * @param {DialogCaseParam} params - Parameters for dialog interaction.
 */
export function incompleteLocationInformation({ promptDialog }: DialogCaseParam): void {
  promptDialog.open({
    title: getText('dialog-title-info'),
    message: getText('incomplete-loc_message'),
    buttons: [
      { name: getText('dialog-button-close'), theme: 'secondary', executable: () => {
        promptDialog.close();
      } }
    ]
  });
}
