import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, firstValueFrom, takeUntil, tap } from 'rxjs';

import { ActivityDateData, FoodData } from '@hiptraveler/data-access/api';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { AppListenerService, SearchLocationData, SearchLocationService, SearchPageControlStateService, WINDOW, queryStringToObject, cleanObject, brandCampaignId, RequestCancellationService, brandSite } from '@hiptraveler/common';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { DataAccess, DataService, StateOption } from './data.service';

@Injectable()
export class FoodsDataService extends DataService implements DataAccess {

  @Select(SearchState.foods) food$: Observable<FoodData[]>;
  @Select(ItineraryState.actDate) actDate$: Observable<ActivityDateData[] | null>;

  constructor(
    @Inject(WINDOW) private window: any,
    private route: ActivatedRoute,
    private store: Store,
    public searchPageControl: SearchPageControlStateService,
    public searchResultDialog: SearchResultDialogActionService,
    private requestCancellation: RequestCancellationService,
    appListener: AppListenerService,
    searchLocation: SearchLocationService,
    leafletControl: LeafletMapControlStateService
  ) {
    super(appListener, searchLocation, leafletControl);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get viewDisplayItems$(): Observable<FoodData[]> {
    return this.getViewDisplayItems(this.store.select(SearchState.foods));
  }

  get searchResults$(): Observable<FoodData[] | null> {
    return this.getSearchResultData(this.store.select(SearchState.foods));
  }

  async initializeDataAccess(): Promise<void> {
    await this.getBrandCampaign(this.store);
    this.getResultsByLocationData({ default: true });
    this.locationChanges(this);
    this.searchResultLocations(this.food$, 'food');
    this.observeLoadMoreEventClicks();
  }

  async getResultsByLocationData(value?: StateOption): Promise<void> {
    await this.resetSearchState(this, [ 'pagination' ]);
    if (value?.default) {
      return brandSite()
        ? this.getBrandContentByData(1)
        : this.getSearchResultsByLocation(this.searchLocation.data)
    }
    this.getSearchResultsByLocation(this.searchLocation.data)
  }

  getSearchResultsByLocation(data: SearchLocationData | null): void {
    if (brandSite() && !this.searchLocation.placename) return this.getBrandContentByData(1);
    this.requestCancellation.cancelAllSearchRequests();

    this.dispatchActionRequest(async () => {
      await firstValueFrom(this.store.dispatch(new SearchAction.GetFoodsByQuery({
        ...cleanObject(data), ...queryStringToObject(this.window.location.search)
      })));
      this.appListener.loadMoreEventState$$.next(false);
    });
  }

  getBrandContentByData(page: number): void {
    if (this.searchLocation.placename) return this.getSearchResultsByLocation(this.searchLocation.data)
    
    this.dispatchActionRequest(async () => {
      await firstValueFrom(this.store.dispatch(new SearchAction.GetFoodsContent({
        cId: brandCampaignId(), brand: 'cFoods', page
      })));
      this.appListener.loadMoreEventState$$.next(false);
    });
  }

  observeLoadMoreEventClicks(): void {
    this.appListener.loadMoreEventState$.pipe(
      filter(Boolean),
      tap(() => this.observeLoadMoreEvent({
        store: this.store,
        route: this.route
      }, this.getBrandContentByData.bind(this), this.getSearchResultsByLocation.bind(this))),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
