import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[toLocaleString]'
})
export class LocaleStringDirective implements AfterViewInit {

  constructor(
    private element: ElementRef<HTMLDivElement>,
  ) { }

  ngAfterViewInit(): void {
    
    const paragraph = this.element.nativeElement;

    const span = paragraph.querySelectorAll('span')[0];
    const rating = +span.innerHTML
    span.innerHTML = rating.toLocaleString();

    const singular = rating === 1;
    if (singular) {
      paragraph.innerHTML = paragraph.innerHTML.replace('reviews', 'review');
    }
  }

}

export const FeatureCardUIDirectives = [
  LocaleStringDirective
];
