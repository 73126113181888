import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, concatMap, delay, from, of, takeUntil, tap } from 'rxjs';
import { flatMap } from 'lodash';

const MESSAGES: string[] = [
  'Loading... your travel style',
  'Loading... experiences',
  'Loading... activities',
  'Searching... points of interest',
  'Searching... places to stay',
  'Searching... places to dine',
  'Compiling itinerary...'
];

const INTERVAL_DURATION: number = 2000;
const ANIMATION_DURATION: number = 300;

@Component({
  selector: 'processing-view',
  template: `
    <blub-circle></blub-circle>
    
    <div class="processing-view--info">
      <ng-container *ngIf="message as message">
        <p [@messageChange]>{{ message }}</p>
      </ng-container>
    </div>
  `,
  styleUrls: ['./processing-view.component.scss'],
  host: { class: 'experience-finder-screen-plan-trip-processing-view' },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('messageChange', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate(`${300}ms ease-out`, style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate(`${300}ms ease-out`, style({ opacity: 0, transform: 'translateY(20px)' }))
      ])
    ])
  ],
})
export class ProcessingViewComponent implements OnInit, OnDestroy {

  messages: string[] = ((array) => flatMap(array, item => ['', item]))(MESSAGES);

  message: string;
  currentIndex: number = 0;
  timerSubscription$ = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.runMessageTimer();
  }

  ngOnDestroy(): void {
    this.timerSubscription$.next();
  }

  private runMessageTimer(): void {
    from(this.messages).pipe(
      concatMap((item: string, index: number) => {
        const state = (index & 1) === 1;
        if (index === 13) this.resetMessageTimer();
        return of(item).pipe(delay(
          index === 0 ? 0 : state ? ANIMATION_DURATION : INTERVAL_DURATION
        ));
      }),
      tap((message: string) => (this.message = message)),
      takeUntil(this.timerSubscription$)
    ).subscribe()
  }

  private resetMessageTimer(): void {
    setTimeout(() => {
      this.timerSubscription$.next();
      this.message = '';
      this.runMessageTimer()
    }, INTERVAL_DURATION);
  }

}
