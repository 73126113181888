import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { PostActionButtonComponent } from './post-action-button.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  LazyLoadImageModule,
  TranslateModule
];

const COMPONENTS = [
  PostActionButtonComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    PostActionButtonComponent
  ]
})
export class PostActionButtonModule { }
