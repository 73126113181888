import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { TaCardModule } from '@hiptraveler/ui/ta-card';
import { ActionActiveModule } from '@hiptraveler/features/itinerary';

import { TravelAgentsComponent } from './travel-agents.component';
import { FollowProcessingPipe } from './follow-processing.pipe';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  TaCardModule,
  ActionActiveModule
];

const COMPONENTS = [
  TravelAgentsComponent
];

const PIPES = [
  FollowProcessingPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [ 
    TravelAgentsComponent
  ]
})
export class TravelAgentsModule { }
