import { Component, ViewEncapsulation } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { SnackbarService } from '@hiptraveler/snackbar';
import { PlanTripService } from './../plan-trip.service';
import { CDNReaderService } from '@hiptraveler/common';

@Component({
  selector: 'external-view',
  templateUrl: './external-view.component.html',
  styleUrls: ['./external-view.component.scss'],
  host: { class: 'experience-finder-screen-plan-trip-external-view' },
  encapsulation: ViewEncapsulation.None
})
export class ExternalViewComponent {

  constructor(
    private stateService: ExperienceFinderStateService,
    private snackbar: SnackbarService,
    public planTrip: PlanTripService,
    private cdnReader: CDNReaderService
  ) { }

  get hiptravelerLogoImage$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.appCommon').pipe(
      map((e: any) => e['hiptraveler-logo'])
    );
  } 

  copyToClipboard(state: boolean): void {
    state
      ? this.snackbar.open({ message: 'Copied to clipboard.' })
      : this.snackbar.open({ message: 'Please try again.' });;
  }

  back(): void {
    this.stateService.dialogView$$.next('travel-style');
  }

}
