import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfileStateService, ProfileTabSelection } from '../../common';

@Injectable()
export class UserInfoMobileToolbarService {

  constructor(
    private stateService: ProfileStateService
  ) { }

  get selection$(): Observable<ProfileTabSelection> {
    return this.stateService.profileTabSelection$$.asObservable();
  }

  switchTab(selection: ProfileTabSelection): void {
    this.stateService.profileTabSelection$$.next(selection);
  }

}
