import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { filter, firstValueFrom, map, switchMap, takeUntil, tap } from 'rxjs';

import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { CarouselService, EFAStateServiceService, FORM_VALUE, parseStateLocation, ScreenView } from '../shared';
import { getRequestPayload } from './request-payload';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';

const LOCATIONS_CDN = 'https://d1npdhl5v1lqq4.cloudfront.net/assets/clients/locations/hip-default-locations.json';

@Directive({
  selector: '[hostObserver]'
})
export class HostObserverDirective implements OnInit, OnDestroy {

  constructor(
    private element: ElementRef<HTMLElement>,
    private http: HttpClient,
    private store: Store,
    private stateService: EFAStateServiceService,
    private carousel: CarouselService
  ) { }

  ngOnInit(): void {

    this.stateService.summaryItems$$.next([]);

    this.setupLocationCdn();
    this.formSubmitObserver();
    this.testObserver();

    // Development Test Cases
    this.devLoggers();
  }

  ngOnDestroy(): void {
    this.stateService.screenViewValue$$.next(null);
    this.stateService.formValue$$.next(FORM_VALUE);
  }

  private setupLocationCdn(): void {
    this.store.select(BrandState.state).pipe(
      filter(state => !!state && !state?.cLocations),
      switchMap(() => this.http.get(LOCATIONS_CDN).pipe(map((e: any) => e?.locations || []))),
      tap((e) => this.stateService.locations$$.next(parseStateLocation(e))),
      takeUntil(this.stateService.subscription$)
    ).subscribe();
  }

  private formSubmitObserver(): void {
    this.stateService.formSubmit$.asObservable().subscribe(async () => {
    
      this.toggleLoaderDisplay(true);
      this.stateService.inputPending$$.next(true);
      this.stateService.pauseBrandInfoUXState$$.next(false);

      const formValue = this.stateService.formValue;
      const summaryItems = [ ...(this.stateService.summaryItems$$.value || []) ];

      try {
        await firstValueFrom(this.store.dispatch(new ItineraryAction.AddItinerary(getRequestPayload({
          formValue: this.stateService.formValue$$.value,
          brandState: this.carousel.brandState,
          authenticated: this.store.selectSnapshot(UserState.authenticated)
        }))));
        this.stateService.patchFormValue(FORM_VALUE);
        this.stateService.summaryItems$$.next([]);
        this.stateService.scrollToScreen$$.next(ScreenView.screen1);
      } catch (error) {
        this.stateService.patchFormValue(formValue);
        this.stateService.summaryItems$$.next(summaryItems);
      }

      this.toggleLoaderDisplay(false);
      this.stateService.inputMessage$$.next('');
      this.stateService.inputPending$$.next(false);
      this.stateService.pauseBrandInfoUXState$$.next(true);
    });
  }

  private testObserver(): void {
    this.stateService.formValue$.subscribe((formValue) => {

      const payload = getRequestPayload({
        formValue,
        brandState: this.carousel.brandState,
        authenticated: this.store.selectSnapshot(UserState.authenticated)
      });

      console.log('@@@ formValue', JSON.stringify(payload, null, 2));
    });
  }

  private toggleLoaderDisplay(state: boolean): void {
    const parentElement = this.element.nativeElement.parentElement!;
    const loaderScreen = parentElement.getElementsByClassName('experience-finder-ai-loader-screen')[0]! as HTMLElement;

    if (state) {
      loaderScreen.style.zIndex = '3';
      loaderScreen.style.display = 'block';
    } else {
      loaderScreen.style.zIndex = '0';
      loaderScreen.style.display = 'none';
    }
  }

  // Development Test Cases

  private devLoggers(): void {

    this.stateService.formValue$.subscribe((res) => {
      console.log('@@@ state :: formValue', res);
    });

    this.stateService.screenViewValue$.subscribe((res) => {
      console.log('@@@ state :: screenViewValue', res);
    });

    this.stateService.summaryItems$.subscribe((res) => {
      console.log('@@@ state :: summaryItems', res);
    });
  }

}
