import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

import { DontSellInfoModule } from '@hiptraveler/ui/dont-sell-info';

import { NotificationsFormComponent } from './notifications-form.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  TranslateModule
];

const LIBRARIES = [
  DontSellInfoModule
];

const COMPONENTS = [
  NotificationsFormComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    NotificationsFormComponent
  ]
})
export class NotificationsFormModule { }
