import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  host: { class: 'ht-profile-card' },
  encapsulation: ViewEncapsulation.None
})
export class ProfileCardComponent {

  @Input() active: boolean;
  @Input() processing: boolean;

}
