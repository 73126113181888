<div class="user-info-mobile-toolbar mat-elevation-z2" [@opacityFade]>

  <div class="user-info-mobile-toolbar--top"></div>
  <div class="user-info-mobile-toolbar--bottom">
    <p [class.active]="(service.selection$ | async) === 'stories'" (click)="service.switchTab('stories')" translate="profile.stories-option"></p>
    <p [class.active]="(service.selection$ | async) === 'about'" (click)="service.switchTab('about')" translate="profile.about-option"></p>
    <p [class.active]="(service.selection$ | async) === 'itineraries'" (click)="service.switchTab('itineraries')" translate="profile.itineraries-option"></p>
    <p [class.active]="(service.selection$ | async) === 'favorites'" (click)="service.switchTab('favorites')" translate="profile.favorites-option"></p>
    <p [class.active]="(service.selection$ | async) === 'uploads'" (click)="service.switchTab('uploads')" translate="profile.my-uploads-option"></p>
  </div>

</div>
