import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotelOffer } from '@hiptraveler/data-access/api';
import { WINDOW } from '@hiptraveler/common';

@Component({
  selector: 'app-hotel-offers-dialog',
  templateUrl: './hotel-offers-dialog.component.html',
  styleUrls: ['./hotel-offers-dialog.component.scss'],
  host: { class: 'app-hotel-offers-dialog' },
  encapsulation: ViewEncapsulation.None
})
export class HotelOffersDialogComponent {

  constructor(
    @Inject(WINDOW) private window: any,
    @Inject(MAT_DIALOG_DATA) public data: HotelOffer[]
  ) { }

  navigateOffer(url?: string): void {
    url && this.window.open(url, '_blank');
  }

}
