import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subject, debounceTime, delay, filter, firstValueFrom, takeUntil, tap } from 'rxjs';

import { toCheckInDateRange } from '@hiptraveler/data-access/api';
import { ViewService, FiltersStateService, param, paramObject } from '.';
import { FiltersService } from '../filters.service';
import { ListItem } from '../list-items';
import { WINDOW, cleanObject, objectToQueryString, queryStringToObject, urlHash } from '@hiptraveler/common';

@Injectable()
export class FiltersStateListenerService implements OnDestroy {

  subscription$ = new Subject<void>();

  #previousSearchPage: string = '';

  constructor(
    @Inject(WINDOW) private window: any,
    private location: Location,
    private service: FiltersService,
    private viewService: ViewService,
    private filterState: FiltersStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  filterServiceListener(): void { // Methods facade
    this.searchPageNavigationObserver();
    this.loadUrlQueryToState();
    this.dateParamListener();
    this.keywordParamListener();
    this.selectedKeyListener();
  }

  searchPageNavigationObserver(): void {
    this.filterState.searchPage$.pipe(takeUntil(this.subscription$)).subscribe((searchPage: string) => {
      this.#previousSearchPage = searchPage;
    });
    this.filterState.searchPage$$.pipe(
      filter(Boolean),
      filter(() => !!this.#previousSearchPage),
      delay(0),
      takeUntil(this.subscription$)
    ).subscribe(() => {
      this.service.form.patchValue({ query: '', checkInDate: '', checkOutDate: '' });
      this.filterState.clearFilters();
    });
  }

  private loadUrlQueryToState(): void { // Patch url query parameters to state on site load/visit/init

    const query = queryStringToObject(this.window.location.search);
    setTimeout(() => this.service.form.patchValue({
      query: query['keyword'],
      checkInDate: query['checkInDate'] ? new Date(query['checkInDate']) : '',
      checkOutDate: query['checkInDate'] ? new Date(query['checkOutDate']) : ''
    }));

    this.viewService.listItems$.pipe(
      tap(({ data, activities }) => {
        const processFilters = (key: string, results: ListItem[]) => 
        results.filter(e => query[key]?.split('%2C')?.includes(e.value));
        this.filterState.updateSelectedItems(processFilters('activities', data));
        this.filterState.updateSelectedItems(processFilters('amenities', data));
        this.filterState.updateSelectedTravelStyles(processFilters('experiences', activities));
        this.filterState.updateSelectedTravelStyles(processFilters('travelStyle', activities));
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private dateParamListener(): void { // Append check in and out dates to url query parameter
    this.service.form.valueChanges.pipe(
      takeUntil(this.subscription$)
    ).subscribe(({ checkInDate, checkOutDate }) => this.setUrlByQuery({
      ...queryStringToObject(this.window.location.search),
      ...toCheckInDateRange({ checkInDate, checkOutDate })
    }));
  }

  private keywordParamListener(): void { // Append keyword to url query parameter
    this.service.form.get('query')?.valueChanges.pipe(
      debounceTime(350),
      takeUntil(this.subscription$)
    ).subscribe((value: string) => this.setUrlByQuery({
      ...queryStringToObject(this.window.location.search),
      keyword: value
    }));
  }

  private selectedKeyListener(): void { // Append filter selections to url query parameter

    this.filterState.selectedItemKeys$.subscribe((values: string[]) => this.setUrlByQuery({
      ...queryStringToObject(this.window.location.search),
      [param(this.filterState.searchPage$$.value)]: `${values.join('%2C')}${urlHash(this.window) || ''}`
    }));

    this.filterState.selectedTravelStyleKeys$.subscribe(async (values: string[]) => {
      const listItems = (await firstValueFrom(this.viewService.listItems$)).activities;
      this.setUrlByQuery({
        ...queryStringToObject(this.window.location.search),
        ...queryStringToObject(paramObject(values, listItems))
      });
    });
  }

  private setUrlByQuery(query: any): void { // Utility method to append object values to query parameter
    if (!this.#previousSearchPage) return;
    query = cleanObject(query);
    const newUrl = `${this.window.location.pathname}${objectToQueryString(query) ? '?' : ''}${objectToQueryString(query)}`;
    this.location.replaceState(newUrl);
  }

}
