import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, Observable, Subject, catchError, combineLatest, delay, filter, fromEvent, map, switchMap, take, takeUntil, tap } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { EventAction } from '@hiptraveler/data-access/event';
import { WINDOW } from '@hiptraveler/common';
import { DialogType } from './model';

@Directive({
  selector: '[searchResultAction]'
})
export class SearchResultActionDirective implements OnDestroy, AfterViewInit {

  @Input() image: ImageResultData;
  @Input() hotel: HotelResultData;
  @Input() dialogType: DialogType;

  subscription$ = new Subject<void>();

  constructor(
    @Inject(WINDOW) private window: any,
    private element: ElementRef<HTMLDivElement>,
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    
    this.observeClickNavigation();

    combineLatest([
      this.store.select(ItineraryState.imageInformation),
      this.store.select(ItineraryState.hotelInformation)
    ]).pipe(
      delay(1000),
      takeUntil(this.subscription$)
    ).subscribe(this.observeClickNavigation.bind(this));
    
    this.store.select(ItineraryState.imageInformation).pipe(
      filter(Boolean), delay(1000), take(1),
      switchMap(() => this.clickNavigation([...this.getElementsByClass('tour-review--information-navigation')], '#reviews-wrapper')),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private observeClickNavigation(): void {

    this.clickNavigation([    
      ...this.getElementsByClass('details--title-open-in-new'),
      this.getElementByClass('price-offers--view-deal'),
      this.getElementByClass('result-actions--book-contact')
    ]).subscribe();
    
    this.clickNavigation([
      ...this.getElementsByClass('tour-info--check-availability')
    ], '#booking-assistant').subscribe();
  }

  private getElementByClass(value: string): HTMLElement {
    return this.element.nativeElement.getElementsByClassName(value).item(0) as HTMLElement;
  }

  private getElementsByClass(value: string): HTMLElement[] {
    return this.element.nativeElement.querySelectorAll(`.${value}`) as any || [];
  }

  private clickNavigation(elements: HTMLElement[], hash: string = ''): Observable<Event> {
    return fromEvent(elements?.filter(e => !!e) || [], 'click').pipe(
      catchError(() => EMPTY),
      tap(() => this.contentNavigation(hash)),
      takeUntil(this.subscription$)
    )
  }

  private contentNavigation(hash: string = ''): unknown {
    switch (this.dialogType) {
      case 'adventure': return this.adventureNavigation(hash);
      case 'hotel': return this.hotelNavigation();
      case 'food': return this.foodNavigation();
    }
  }

  private adventureNavigation(hash: string): void {
    this.store.dispatch(new EventAction.NavigateToExternalLinkEvent({
      actionId: this.image.id, actionType: 'checkavailability', objType: 'image'
    }));
    this.window.open(`${this.image.imgSource || this.image.tourData?.tourUrl}${hash}`, '_blank');
  }
  
  private hotelNavigation(): void {
    this.store.dispatch(new EventAction.NavigateToExternalLinkEvent({
      actionId: this.hotel.id, actionType: 'checkavailability', objType: 'hotel'
    }));
    this.window.open(`${this.hotel.lowestOffer?.deeplink || this.hotel.officialSite}`, '_blank');
  }

  private foodNavigation(): void {
    this.store.dispatch(new EventAction.NavigateToExternalLinkEvent({
      actionId: this.image.id, actionType: 'checkavailability', objType: 'image'
    }));
    this.window.open(`${this.image?.taDataMap?.webUrl || this.image?.yelpData?.yelpUrl || this.image.tourData?.tourUrl || this.image.imgSource}`, '_blank');
  }

}
