import { Component } from '@angular/core';

import { SettingsFormService } from './settings-form.service';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { ProfileStateFormService } from '../../../common';

@Component({
  selector: 'settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
  viewProviders: [ SettingsFormService, PromptDialogActionService ]
})
export class SettingsFormComponent {

  constructor(
    public service: SettingsFormService,
    public stateFormService: ProfileStateFormService
  ) { }

  togglePasswordVisibility(event: HTMLInputElement | any): void {
    event.type = event.type === 'password' ? 'text' : 'password'
  }

}
