import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, Inject, PLATFORM_ID } from '@angular/core';
import { debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import Typed from 'typed.js';

import { EFAStateServiceService, ScreenValue } from '../../shared';

@Directive({
  selector: '[textTyped]'
})
export class TextTypedDirective implements AfterViewInit {

  typed?: Typed;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.typedTextSetup();
    this.brandHeaderTypeState();

    this.stateService.screenViewValue$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.typedTextSetup.bind(this));
  }

  async typedTextSetup(screen: ScreenValue = 'screen2'): Promise<void> {

    const screen8State = await firstValueFrom(this.stateService.summaryItems$);

    const value = {
      screen8State: !!screen8State?.length
    };
    
    this.typed = new Typed('#brand-header-highlight', {
      strings: [ getHighlightTextByScreen(screen, value) ],
      typeSpeed: 15,
      showCursor: false,
      startDelay: 300,
    });
  }

  brandHeaderTypeState(): void {
    this.stateService.brandHeaderTypeState$$.asObservable().pipe(
      debounceTime(150)
    ).subscribe((state: boolean) => {
      if (state) {
        this.typed?.start();
      } else {
        this.typed?.destroy();
      }
    });
  }

}

function getHighlightTextByScreen(screen: ScreenValue, value: { screen8State: boolean }): string {
  if (screen === 'screen3') {
    return 'Whats your Travel Style?';
  }
  if (screen === 'screen4') {
    return 'What are your favorite Activities?';
  }
  if (screen === 'screen5') {
    return 'Where are you headed, Traveler?';
  }
  if (screen === 'screen6') {
    return 'When would you like to hit the road?';
  }
  if (screen === 'screen7') {
    return 'How many travelers for the trip?';
  }
  if (screen === 'screen8') {
    return value.screen8State
      ? 'Looks like an amazing trip! Here\'s a summary of your preferences:'
      : 'Start planning your amazing trip!';
  }
  return 'How can I help you today?';
}
