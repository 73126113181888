import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BlogDataAccessService, TravelOptionsComponent } from '@hiptraveler/features/itinerary';
import { TravelStoryService } from './travel-story.service';
import { CommonService } from './common.service';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { AppListenerService, ToolbarOption, currentLang, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

const ITINERARY_SCROLL_ID = 'travel-story-page-itinerary-section';

@Component({
  selector: 'app-travel-story',
  templateUrl: './travel-story.component.html',
  styleUrls: ['../../features/itinerary/itinerary-page-styles.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ TravelStoryService, CommonService, BlogDataAccessService, AuthDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn(2000) ]
})
export class TravelStoryComponent implements OnInit {

  @ViewChild(TravelOptionsComponent) travelOptions: TravelOptionsComponent;

  selections: ToolbarOption[];

  constructor(
    private i18n: TranslateService,
    public service: TravelStoryService,
    public common: CommonService,
    private appListener: AppListenerService
  ) { }
  
  ngOnInit(): void {
    getWindowRef()[requestEndpointParamValue] = 'blog';
    this.selectionUIObserver();
    this.service.observerScrollPosition();
    this.service.getPageData().subscribe();
    setTimeout(() => this.common.focusMode('on'));

    this.appListener.languageLoaded$.subscribe(() => {
      this.selections = [
        { name: this.getText('story'), scrollToViewId: 'travel-story-page-story-section' },
        { name: this.getText('itinerary'), scrollToViewId: ITINERARY_SCROLL_ID },
        { name: this.getText('travel'), scrollToViewId: 'travel-story-page-travel-section' }
      ];
    });
  }

  /**
   * Observes story content components.
   * Checks if the component has or has no content for UI display.
   * Sets the scroll view ID to hidden if no content is present.
   * 
   * @returns {void}
   */
  selectionUIObserver(): void {
    this.selections && this.service.hasItinerary$.subscribe((state: boolean) => {
      this.selections[1].scrollToViewId = state ? ITINERARY_SCROLL_ID : 'hidden';
      this.selections = this.selections;
    });
  }

  private getText(value: string): string {
    return this.i18n.translations?.[currentLang()]?.['view-page']?.[value] || value;
  }

}
