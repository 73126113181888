<ng-container *ngIf="(selections | headers) | async as headers">
  <div optionHighlight [ngClass]="{
      component: component === '',
      'scroll-to-view': scrollToView === '',
      'disable-highlight': disableHighlight === '' }">

    <div 
      [class]="'toolbar--option ' + header.scrollToViewId"
      *ngFor="let header of headers;let i = index"
      [routerLink]="header?.url ? '/' + translateService.currentLang + '/' + header.url : null"
      [scrollToView]="header.scrollToViewId"
      [attr.data-url]="header.url">
      <div class="toolbar--option-border"></div>
      <div class="toolbar--option-text-wrapper">
        <p class="toolbar--option-text">{{ header | optionName | async }}</p>
      </div>
    </div>
    <div
      class="toolbar--option"
      [routerLink]="translateService.currentLang + '/travel'"
      data-url="travel"
      *ngIf="travelSelection === ''">
      <div class="toolbar--option-border"></div>
      <div class="toolbar--option-text-wrapper">
        <p class="toolbar--option-text" translate="navbar.toolbar-text-travel"></p>
      </div>
    </div>
    <div
      class="toolbar--option last"
      [routerLink]="translateService.currentLang + '/community'"
      data-url="community"
      *ngIf="connectSelection === ''">
      <div class="toolbar--option-border"></div>
      <div class="toolbar--option-text-wrapper">
        <p class="toolbar--option-text" translate="navbar.toolbar-text-connect"></p>
      </div>
    </div>

    <!-- <div class="toolbar--option-swipe-indicator" *ngIf="swipeIcon === ''">
      <mat-icon>swipe_right</mat-icon>
    </div> -->
  
  </div>
</ng-container>
