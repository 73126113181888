import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { CardRating, CardRatingReviews, CardReviews } from './functions';

@Component({
  selector: 'feature-card-price',
  template: '<p>{{ currency | parseCurrency: amount: true }} <span><ng-content></ng-content></span></p>',
  host: { class: 'feature-card-price' }
})
export class FeatureCardPriceComponent implements OnInit {
  @Input() currency?: string;
  @Input() amount?: number;
  constructor(private element: ElementRef<HTMLElement>) { }
  ngOnInit(): void {
    this.element.nativeElement.hidden = !this.amount;
  }
}

@Component({
  selector: 'feature-card-date',
  template: `
    <div>
      <p><ng-content></ng-content></p>
      <p>{{ 'cards.day' | translate }}</p>
      <p>{{ 'cards.itinerary' | translate }}</p>
    </div>
  `,
  host: { class: 'feature-card-date' }
})
export class FeatureCardDateComponent {}

@Component({
  selector: 'feature-card-title',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'feature-card-title' }
})
export class FeatureCardTitleComponent {}

@Component({
  selector: 'feature-card-subtitle',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'feature-card-subtitle' }
})
export class FeatureCardSubTitleComponent {}

@Component({
  selector: 'feature-card-description',
  template: '<p [innerHTML]="description"></p>',
  host: { class: 'feature-card-description' }
})
export class FeatureCardDescriptionComponent {
  @Input() description?: string | null;
}

@Component({
  selector: 'feature-card-location',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'feature-card-location' }
})
export class FeatureCardLocationComponent {}

@Component({
  selector: 'feature-card-rating',
  template: `<p toLocaleString>{{ 'cards.outstanding' | translate }} <span><ng-content></ng-content></span></p>`,
  host: { class: 'feature-card-rating' }
})
export class FeatureCardRatingComponent extends CardRating {}

@Component({
  selector: 'feature-card-rating-reviews',
  template: `<p toLocaleString><span><ng-content></ng-content></span> {{ 'cards.reviews' | translate }}</p>`,
  host: { class: 'feature-card-rating-reviews' }
})
export class FeatureCardRatingReviewsComponent extends CardRatingReviews {}

@Component({
  selector: 'feature-card-reviews',
  template: `<p toLocaleString>{{ 'cards.based-on' | translate }} <span><ng-content></ng-content></span> {{ 'cards.reviews' | translate }}</p>`,
  host: { class: 'feature-card-reviews' }
})
export class FeatureCardReviewsComponent extends CardReviews {}

@Component({
  selector: 'feature-card-duration',
  template: `
    <ng-container *ngIf="duration | durationString as string;else noContent">
      <mat-icon>schedule</mat-icon> <p>:</p> <p>{{ string }}</p>
    </ng-container>
    <ng-template #noContent>
      <p class="no-content">-</p>
    </ng-template>
  `,
  host: { class: 'feature-card-duration' }
})
export class FeatureCardDurationComponent {
  @Input() duration?: number
}

@Component({
  selector: 'feature-card-itinerary-title',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'feature-card-itinerary-title' }
})
export class FeatureCardItineraryTitleComponent {}

@Component({
  selector: 'feature-card-itinerary-snippet',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'feature-card-itinerary-snippet' }
})
export class FeatureCardItinerarySnippetComponent {}

export const FeatureCardExportComponents = [
  FeatureCardPriceComponent,
  FeatureCardDateComponent,
  FeatureCardTitleComponent,
  FeatureCardSubTitleComponent,
  FeatureCardDescriptionComponent,
  FeatureCardLocationComponent,
  FeatureCardRatingComponent,
  FeatureCardRatingReviewsComponent,
  FeatureCardReviewsComponent,
  FeatureCardDurationComponent,
  FeatureCardItineraryTitleComponent,
  FeatureCardItinerarySnippetComponent
];

export const FeatureCardComponents = [
  FeatureCardExportComponents
];
