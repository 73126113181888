import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs';

import { BrandCampaign, UserProfile } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { NavbarControlStateService, ScrollListenerService, ToolbarOption, TranslationService } from '@hiptraveler/common';
import { ProfileStateService, ProfileTabSelection } from '../common';

@Injectable()
export class BannerService implements OnDestroy {

  @Select(UserState.id) id$: Observable<string | null>;
  @Select(ProfileState.profileDetails) profileDetails$: Observable<UserProfile | null>;

  subscription$ = new Subject<void>();
  uploadProgress$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private store: Store,
    private scrollListener: ScrollListenerService,
    private navbarControl: NavbarControlStateService,
    private i18n: TranslationService,
    private stateService: ProfileStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.navbarControl.navbarToolbarPosition$$.next('none');
    this.navbarControl.navbarSearchBarVisibility$$.next(true);
  }

  get selections(): ToolbarOption[] {
    return [
      { name: 'stories-option', url: 'profile/_PROFILE/stories' },
      { name: 'about-option', url: 'profile/_PROFILE/about' },
      { name: 'itineraries-option', url: 'profile/_PROFILE/itineraries' },
      { name: 'favorites-option', url: 'profile/_PROFILE/favorites' },
      { name: 'my-uploads-option', url: 'profile/_PROFILE/uploads' },
    ].map((selection: ToolbarOption) => {
      return { name: this.i18n.getText('profile', selection.name), url: this.parseSelectionUrl(selection) };
    });
  }

  get picUrl$(): Observable<string> {
    return this.store.select(ProfileState.profileDetails).pipe(
      map((userState: UserProfile | null) => userState?.profileImgUrl || '')
    );
  }

  get navbarAppend$(): Observable<boolean> {
    return this.scrollListener.scrollPosition$.pipe(
      map((scrollTop: number) => scrollTop > 400),
      distinctUntilChanged(),
      takeUntil(this.subscription$)
    );
  }

  get coverImage$(): Observable<string> {
    return this.store.select(ProfileState.profileDetails).pipe(
      switchMap((profileDetails: UserProfile | null) => this.store.select(BrandState.brandCampaign).pipe(
        map((brandCampaign: Partial<BrandCampaign> | null) => profileDetails?.coverImage?.original || brandCampaign?.cCoverImg?.cover || '')
      ))
    );
  }

  navbarAndToolbarObserver(): void {

    this.navbarControl.navbarToolbarPosition$$.next('navbar');

    this.navbarAppend$.subscribe((state: boolean) => {
      this.navbarControl.navbarElevationState$$.next(!state);
      this.navbarControl.navbarSearchBarVisibility$$.next(!state);
    });
  }

  routeChangesObserver(): void {

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      takeUntil(this.subscription$)
    ).subscribe((event: NavigationEnd) => {
      const tab = event.url.split('/').pop()!.split('#')[0].split('?')[0] as ProfileTabSelection;
      this.stateService.profileTabSelection$$.next(tab);
    });
  }

  private parseSelectionUrl(selection: ToolbarOption): string {
    return selection.url!.replace('_PROFILE', this.stateService.userId);
  }

}
