import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { DialogType } from '../common';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'buttonVisibility'
})
export class ButtonVisibilityPipe implements PipeTransform {

  constructor(
    private store: Store
  ) { }

  transform(type: DialogType): Observable<boolean> {

    if (type === 'adventure') {
      return this.store.select(ItineraryState.imageInformation).pipe(
        map((image: ImageResultData | null) => !!image?.imgSource || !!image?.tourData?.tourUrl)
      );
    }
    
    if (type === 'food') {
      return this.store.select(ItineraryState.imageInformation).pipe(
        map((image: ImageResultData | null) => !!image?.taDataMap?.webUrl || !!image?.yelpData?.yelpUrl || !!image?.tourData?.tourUrl || !!image?.imgSource)
      );
    }

    return this.store.select(ItineraryState.hotelInformation).pipe(
      map((hotel: HotelResultData | null) => !!hotel?.lowestOffer?.deeplink || !!hotel?.officialSite)
    );
  }

}

@NgModule({
  declarations: [ ButtonVisibilityPipe ],
  exports:      [ ButtonVisibilityPipe ]
})
export class ButtonVisibilityPipeModule { }
