import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { StarRatingsModule } from '@hiptraveler/ui/star-ratings';
import { ParseCurrencyPipeModule, ToLocalePipeModule } from '@hiptraveler/common';

import { RelatedToursComponent } from './related-tours.component';
import { ReviewTextPipe } from './rating-count.pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  StarRatingsModule,
  ParseCurrencyPipeModule,
  ToLocalePipeModule
];

const COMPONENTS = [
  RelatedToursComponent
];

const PIPES = [
  ReviewTextPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    RelatedToursComponent
  ]
})
export class RelatedToursModule { }
