import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AuthorData, CoverImage } from '@hiptraveler/data-access/api';
import { NavbarControlStateService, ToolbarOption, currentLang, profilePlaceholder } from '@hiptraveler/common';
import { ExperienceFinderStateService } from '../experience-finder-widget';
import { PageBannerService } from './page-banner.service';

@Component({
  selector: 'feature-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss' , './page-banner-responsiveness.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ PageBannerService ]
})
export class PageBannerComponent implements OnInit {

  @Input() title?: string;
  @Input() owner?: boolean;
  @Input() coverImage?: Partial<CoverImage>;
  @Input() author?: AuthorData;
  @Input() selections?: ToolbarOption[];

  profilePlaceholder: string = profilePlaceholder;

  constructor(
    private router: Router,
    public service: PageBannerService,
    public navbarControl: NavbarControlStateService,
    private stateService: ExperienceFinderStateService
  ) { }

  get efState$(): Observable<boolean> {
    return this.stateService.overlayState$;
  }

  ngOnInit(): void {
    this.service.scrollPositionObserver();
  }

  navigateToUserProfile(): void {
    this.author?.authorTitle
      && this.router.navigateByUrl(`/${currentLang()}/profile/${this.author?.authorTitle}/stories`);
  }

}
