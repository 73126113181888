import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CreatePasswordService } from './create-password.service';
import { AUTH_DIALOG_ID } from '../../auth-dialog-action.service';
import { isWidget, RequestCancellationService } from '@hiptraveler/common';

@Component({
  selector: 'auth-dialog-forgot-password-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['../forgot-password.component.scss', './create-password.component.scss'],
  viewProviders: [ CreatePasswordService ]
})
export class CreatePasswordComponent implements OnInit, OnDestroy {

  @Output() backToForgotPasswordClicked = new EventEmitter();
  @Output() backToLoginClicked = new EventEmitter();
  inputType: string = 'password';

  constructor(
    private dialog: MatDialog,
    public service: CreatePasswordService,
    private requestCancellation: RequestCancellationService
  ) { }
  
  ngOnInit(): void {
    this.service.initializeForm();
    this.service.successListener$$.subscribe(() => {
      const dialog = this.dialog.getDialogById(AUTH_DIALOG_ID);
      isWidget() ? dialog?.close() : this.backToLoginClicked.emit();
    });
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
  }

  controlError(control: string): boolean {
    if (this.requiredControl(control)) return false;
    return this.service.form.get(control)?.errors !== null;
  }

  requiredControl(control: string): boolean {
    const errors = this.service.form.get(control)?.errors;
    return errors?.hasOwnProperty('required') && errors?.['required'];
  }

  passwordMismatchControl(): boolean {
    const errors = this.service.form.get('confirm')?.errors;
    return errors?.hasOwnProperty('passwordMismatch') && errors?.['passwordMismatch'];
  }

}
