import { Component } from '@angular/core';

import { UserInfoMobileToolbarService } from './user-info-mobile-toolbar.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'user-info-mobile-toolbar',
  templateUrl: './user-info-mobile-toolbar.component.html',
  styleUrls: ['./user-info-mobile-toolbar.component.scss'],
  viewProviders: [ UserInfoMobileToolbarService ],
  animations: [ opacityFade() ]
})
export class UserInfoMobileToolbarComponent {

  constructor(
    public service: UserInfoMobileToolbarService
  ) { }

}
