import { AfterViewInit, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { delay, filter, take, takeUntil, tap } from 'rxjs';

import { FiltersService } from './filters.service';
import { AppListenerService, SearchLocationData, SearchLocationService } from '@hiptraveler/common';
import { ListItem } from './list-items';
import { opacityFade } from '@hiptraveler/animations';
import * as Common from './common';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  host: { class: 'search-page-root-filter' },
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(100) ]
})
export class FiltersComponent implements OnInit, AfterViewInit {

  @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
  @ViewChild('searchLocation') input: ElementRef<HTMLInputElement>;

  minDate = new Date().toISOString();

  constructor(
    public appListener: AppListenerService,
    private searchLocation: SearchLocationService,
    public service: FiltersService,
    public filtersService: Common.FiltersStateService,
    public view: Common.ViewService,
    private filterStateListener: Common.FiltersStateListenerService,
    private filterClear: Common.FilterClearService
  ) { }

  ngOnInit(): void {
    this.service.listPanelApplyActionListener();
    this.filterStateListener.filterServiceListener();
  }

  ngAfterViewInit(): void {

    this.initializeCheckboxState();
    this.service.routeChangeObserver();
    this.service.initializeFormState();
    this.service.filterVisibilityObserver().subscribe();

    this.service.filterVisibilityObserver().pipe(filter(Boolean), delay(100), take(1)).subscribe(() => {
      this.service.filters$$.next(true);
    });

    this.searchLocation.searchLocation$$.asObservable().pipe(
      tap(value => (value || this.filterClear.clear())),
      takeUntil(this.service.subscription$)
    ).subscribe()
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Escape' && event.key !== 'Esc') return;
    this.service.hideOverlay()
  }

  locationChanged(locationData: SearchLocationData): void {
    this.searchLocation.updateSearchLocation(locationData);
    this.service.form.patchValue({
      location: locationData.location
    });
    setTimeout(() => this.service.hideOverlay(), 300);
  }

  toggleSelection(listItems: ListItem[], value: string): void {
    let selected = this.filtersService.selectedItems$$.value.map(e => e.value);
    selected.includes(value)
      ? selected = selected.filter(e => e !== value)
      : selected.push(value);
    this.filtersService.selectedItems$$.next(
      listItems.filter((listItem: ListItem) => selected.includes(listItem.value))
    );
  }

  private initializeCheckboxState(): void {
    this.filtersService.selectedItemKeys$.pipe(
      filter(Boolean), delay(100), takeUntil(this.service.subscription$),
      tap((values: string[]) => this.checkboxes.forEach((checkbox: MatCheckbox) => {
        checkbox.checked = values.includes(checkbox.id);
      }))
    ).subscribe();
  }
  
}
