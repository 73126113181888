import { Component, Input, OnInit } from '@angular/core';

import { StepperHeaderService } from './stepper-header.service';
import { RegistrationFormStateService, Type } from '../common';

@Component({
  selector: 'stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss'],
  viewProviders: [ StepperHeaderService ]
})
export class StepperHeaderComponent implements OnInit {

  @Input() type: Type;

  constructor(
    public service: StepperHeaderService,
    public stateService: RegistrationFormStateService
  ) { }

  ngOnInit(): void {
    this.service.scrollObserver();
  }

}
