import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

import { currentLang } from '@hiptraveler/common';

@Pipe({
  name: 'viewTitle'
})
export class ViewTitlePipe implements PipeTransform {

  constructor(
    private router: Router
  ) { }

  transform(_: unknown): string {
    const url = this.router.url;
  
    return url.includes(`${currentLang()}/hotels`) ? 'search-page.amenities' :
           url.includes(`${currentLang()}/foodanddrink`) ? 'search-page.add-your-cuisine-style' :
           'search-page.add-your-travel-style';
  }

}
