import { Injectable, OnDestroy } from '@angular/core';
import { ActionCompletion, ActionType, Actions, ofActionCompleted, ofActionDispatched } from '@ngxs/store';
import { Observable, Subject, delay, filter, takeUntil, tap } from 'rxjs';

@Injectable()
export class AnalyticsService implements OnDestroy {

  subscription$ = new Subject<void>();
  
  constructor(
    private actions$: Actions
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  #actionCompletedPending: boolean;

  protected observeActionCompleted(Action: ActionType, callback: () => Observable<unknown>, callback2?: (param: ActionCompletion<any, any>) => boolean): void {
    this.actions$.pipe(
      ofActionCompleted(Action),
      delay(100),
      filter(() => !this.#actionCompletedPending),
      filter((param: ActionCompletion<any, any>) => callback2 ? callback2(param) : true),
      filter((param: ActionCompletion<any, any>) => param.result.successful),
      tap(() => callback().subscribe()),
      tap(() => this.modifyEmitState(v => this.#actionCompletedPending = v)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  #actionDispatchedPending: boolean;

  protected observeActionDispatched(Action: ActionType, callback1: (param: any) => Observable<unknown>, callback2?: (param: any) => boolean): void {
    this.actions$.pipe(
      ofActionDispatched(Action),
      delay(100),
      filter(() => !this.#actionDispatchedPending),
      filter((param: any) => callback2 ? callback2(param) : true),
      tap((param: any) => callback1(param).subscribe()),
      tap(() => this.modifyEmitState(v => this.#actionDispatchedPending = v)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private modifyEmitState(callback: (state: boolean) => void): void {
    callback(true);
    setTimeout(() => callback(false), 100);
  }

}
