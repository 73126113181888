import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';

import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ProfileListService, skeletons } from '@hiptraveler/modules/search-page-root';
import { CommunityService } from './community.service';
import { RequestCancellationService, profilePlaceholder } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  viewProviders: [ ProfileListService, CommunityService, AuthDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunityComponent implements OnInit, OnDestroy {

  profilePlaceholder: string = profilePlaceholder;
  skeletons: number[] = skeletons;

  constructor(
    private cdRef: ChangeDetectorRef,
    public service: ProfileListService,
    public communityService: CommunityService,
    private requestCancellation: RequestCancellationService
  ) { }
  
  ngOnInit(): void {
    this.changeDetectionObserver();
    this.service.initializeDataAccess();
    this.communityService.initializeState();
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
    this.requestCancellation.cancelAllSearchRequests();
  }

  private changeDetectionObserver(): void {
    this.service.communities$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.communities$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.communities$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.communities$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
  }

}
