import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'processing'
})
export class ProcessingPipe implements PipeTransform {

  transform(index: number, progressStates$: Observable<boolean[]>): Observable<boolean> {
    return progressStates$.pipe(
      map((progressStates: boolean[]) => progressStates[index])
    );
  }

}
