import { ActivityDateData, UpdateItineraryActivityResponse } from "@hiptraveler/data-access/api";
import { ActivityDate } from "@hiptraveler/common";

export function hasTours(activityDay: ActivityDateData): boolean {
  return !!activityDay.ImgArray && activityDay.ImgArray.length > 0;
}

export function isMultiDayTour(selectedSearchResult?: any): boolean {
  return selectedSearchResult?.tourData?.isMultiDayTour || false;
}

export function isIslandHopping(activityDay: ActivityDateData, response: UpdateItineraryActivityResponse | null, selectedSearchResult?: any): boolean {

  const dayTimeActivity = response?.actDateMap?.[activityDay.day]?.dayTimeActivity?.find(e => e.objId === selectedSearchResult?.id);
        
  return dayTimeActivity?.medium === 'fly' || dayTimeActivity?.medium === 'ferry';
}

export function poiAlreadyExists(activityDay: ActivityDateData, selectedSearchResult?: any): boolean {

  const resultValue = activityDay.ImgArray?.find(
    e => e?.id === selectedSearchResult?.id
  );

  return !!resultValue;
}

export function exceedsOneDayDuration(activityDay: ActivityDateData, selectedSearchResult?: any): boolean {

  const DAY_IN_SECONDS: number = 43200; // 12 hours

  const totalTourDurationInActivityDay = (activityDay.ImgArray || [])
    .map(e => e.duration || 0)
    .reduce((sum, current) => sum + current, 0);

  const selectedPOIDuration = selectedSearchResult?.duration || 0;

  const totalDuration = totalTourDurationInActivityDay + selectedPOIDuration;

  return totalDuration > DAY_IN_SECONDS;
}

export function insufficientDaysAvailable(activityDayStore: ActivityDate | null, activityDay: ActivityDateData, selectedSearchResult?: any): boolean {

  const selectedDay = activityDay.day;
  const activityDaysLength = activityDayStore?.actDate?.length || 0;
  const leftSlice = activityDayStore?.actDate.slice(0, selectedDay) || [];

  const daysAvailable = activityDaysLength - leftSlice.length + 1;

  const SECONDS_IN_AN_HOUR = 3600;
  const _hrDuration = (selectedSearchResult?.duration || 0) / SECONDS_IN_AN_HOUR;
  const _dayDuration = _hrDuration / 12;

  return daysAvailable < _dayDuration;
}
