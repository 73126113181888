import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, delay, map, Observable } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { NavbarButtonData, NavbarControlStateService } from '@hiptraveler/common';

@Injectable()
export class NavbarBrandLogoService {

  hasButtons$: Observable<boolean> = this.navbarControl.activeNavbarActionButtons$.pipe(
    map((buttons: NavbarButtonData[] | null) => !!buttons?.length)
  );

  logo$$ = new BehaviorSubject<string>('');
  logo$ = this.logo$$.asObservable().pipe(delay(0));

  logoPlaceholder: string = 'assets/img/blank-white.webp';

  constructor(
    private store: Store,
    private navbarControl: NavbarControlStateService
  ) { }

  get brandInfo$(): Observable<boolean> {
    return this.store.select(BrandState.brandCampaign).pipe( map(e => !!e) );
  }

}
