<ht-ta-card [travelAgent]="travelAgent" [tags]="travelAgent?.tags" *ngFor="let travelAgent of (dataAccess.travelAgents$ | async)?.slice(0, 3)" [@opacityFade]>
  <img taBackgroundImage [lazyLoad]="travelAgent.coverImage || ''" [defaultImage]="(service.coverImageFallback$ | async)!" [errorImage]="(service.coverImageFallback$ | async)!" alt="Travel Agent Brand Affiliation Image" draggable="false">
  <img taProfileImage [lazyLoad]="travelAgent.profImg || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" alt="Travel Agent Profile Image" draggable="false">
  <ta-flag>{{ 'view-page.specialist' | translate }}</ta-flag>
  <ta-name (click)="service.navigateToAgentProfile(travelAgent)">{{ travelAgent.name }}</ta-name>
  <ta-location>{{ travelAgent.location }}</ta-location>
  <ta-action svgIcon="common.follow-svg" [processing$]="travelAgent | followProcessing" [actionActive]="travelAgent.taId" (click)="service.followByResultData(travelAgent)">{{ 'view-page.follow' | translate }}</ta-action>
  <ta-action icon="mail" (click)="service.sendTravelAgentMessage(travelAgent.profId)">{{ 'view-page.message' | translate }}</ta-action>
</ht-ta-card>

<ng-container *ngIf="!(dataAccess.travelAgents$ | async)">

  <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons$ | async;" [@opacityFadeIn]>
  </ngx-skeleton-loader>
  <div style="width: 10px;height: 0;"></div>

</ng-container>

<ng-container *ngIf="(dataAccess.travelAgents$ | async)?.length === 0">
  <p class="ht-no-results" translate="search-page.no-results-found"></p>
</ng-container>
