import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { ExperienceFinderAi } from './experience-finder-ai';
import { CarouselService, EFAStateServiceService } from '../shared';
import { opacityFade } from '@hiptraveler/animations';
import { isWidget } from '@hiptraveler/common';

@Component({
  selector: 'app-experience-finder-ai',
  templateUrl: './experience-finder-ai.component.html',
  styleUrls: ['./experience-finder-ai.component.scss', './experience-finder-ai-responsiveness.scss'],
  host: { class: 'experience-finder-ai mat-elevation-z2' },
  providers: [ ChatCompletionsService, EFAStateServiceService, CarouselService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class ExperienceFinderAiComponent extends ExperienceFinderAi implements OnDestroy, AfterViewInit {

  constructor(
    private stateService: EFAStateServiceService
  ) {
    super();
  }

  get profileSelectionVisibility(): boolean {
    return isWidget();
  }

  ngOnDestroy(): void {
    this.stateService.subscription$.next();
  }
  
  ngAfterViewInit(): void {
    this.stateService.authInitialize();
    this.initializeComponents();
  }

}
