import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { AmenityDetailsComponent } from './amenity-details.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule
];


const COMPONENTS = [
  AmenityDetailsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    AmenityDetailsComponent
  ]
})
export class AmenityDetailsModule { }
