import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { map, skip, take } from 'rxjs';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { ReviewsService } from './reviews.service';
import { inOutCollapse, opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  host: { class: 'content search-result-dialog-result-content-reviews' },
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ ReviewsService ],
  animations: [ inOutCollapse(96, 250), inOutCollapse(36, 250), opacityFade(animationDelay) ]
})
export class ReviewsComponent implements OnInit {

  @Input() data?: HotelResultData;

  progressSeeMore: boolean = false;

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: ReviewsService
  ) { }

  ngOnInit(): void {
    (this.service.hotelInformation$ as any).pipe(
      skip(1),
      map((response: HotelResultData) => {
        const visibility = !!response && !!response?.guestRating;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
  }

}
