import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { PoweredByHiptravelerModule } from '@hiptraveler/ui/powered-by-hiptraveler';
import { ActionButtonModule } from '../shared';
import { FormModule } from './form/form.module';

import { RegistrationMenuComponent } from './registration-menu.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatButtonModule,
  TranslateModule
];

const LIBRARIES = [
  PoweredByHiptravelerModule,
  ActionButtonModule,
  FormModule
];

const COMPONENTS = [
  RegistrationMenuComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    RegistrationMenuComponent
  ]
})
export class RegistrationMenuModule { }
