import { Directive, HostListener, Inject } from '@angular/core';

import { Location } from '@angular/common';
import { cleanObject, objectToQueryString, queryStringToObject, WINDOW } from '@hiptraveler/common';
import { DataAccessService } from '../common';
import { FiltersService } from '../filters.service';
import { ProfileListService } from '../../data-services';

@Directive({
  selector: '[clearNameAction]'
})
export class  ClearNameActionDirective {

  constructor(
    @Inject(WINDOW) private window: any,
    private location: Location,
    private profileList: ProfileListService,
    private dataAccess: DataAccessService,
    private service: FiltersService
  ) { }

  @HostListener('click')
  async hostClicked(): Promise<void> {

    if (!this.service.form.value.query) return;
    this.clearNameQuery();

    const form = this.service.form.value;

    switch (this.service.path) {
      case 'thingstodo':   return this.dataAccess.adventures(form);
      case 'hotels':       return this.dataAccess.hotels(form);
      case 'foodanddrink': return this.dataAccess.foods(form);
      case 'community':    return this.profileList.getResultsByLocationData();
    }
  }

  clearNameQuery(): void {
    this.service.form.patchValue({ query: '' });
    const query = cleanObject({
      ...queryStringToObject(this.window.location.search),
      keyword: ''
    });
    const newUrl = `${this.window.location.pathname}${objectToQueryString(query) ? '?' : ''}${objectToQueryString(query)}`;
    this.location.replaceState(newUrl);
  }

}
