import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, map, take, tap } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AUTH_DIALOG_ID } from './auth-dialog-action.service';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class AuthDialogService {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;
  @Select(BrandState.hiptravelerBrand) hiptraveler$: Observable<unknown | null>;

  constructor(
    private dialog: MatDialog,
    private store: Store,
    public snackbar: SnackbarService
  ) { }

  get logo$(): Observable<string> {
    return this.brandCampaign$.pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => brandCampaign?.cLogo?.headerImg || '')
    );
  }

  observeAuthentication(): void {
    this.store.select(AuthState.authenticated).pipe(
      filter(Boolean),
      tap(() => {
        const dialog = this.dialog.getDialogById(AUTH_DIALOG_ID);
        dialog?.close();
      }),
      take(1)
    ).subscribe();
  }

  brandCampaignStateObserver(): void {
    this.brandCampaign$.pipe(
      filter(Boolean),
      tap(() => this.snackbar.dismiss())
    ).subscribe();
  }

}
