
<navbar-view (actionClicked)="dialogRef.close()">
  <navbar-action iconLeft="add" (click)="addToTrip()">{{ 'Add to Trip' }}</navbar-action>
</navbar-view>

<!-- <dialog-close-button style="left: 0;" (click)="dialogRef.close()"></dialog-close-button> -->
<dialog-close-button-lts style="left: 0;" (click)="dialogRef.close()"></dialog-close-button-lts>

<div class="search-result" searchResultAction [image]="(service.imageInformation$ | async) || data" [hotel]="(service.hotelInformation$ | async) || data" [dialogType]="service.dialogType" [class.expired]="(service.imageInformation$ | async)?.tourExpire">

  <result-actions [image]="data" [hotel]="data" [dialogType]="service.dialogType"></result-actions>

  <result-content [image]="data" [hotel]="data" [dialogType]="service.dialogType" [addToTripSignal]="addToTripSignal$" scrollElementBorder></result-content>

  <result-details [image]="data" [hotel]="data" [dialogType]="service.dialogType" scrollElementBorder></result-details>

</div>

<result-details-overlay searchResultAction [image]="data" [dialogType]="service.dialogType" class="mat-elevation-z2"></result-details-overlay>
