import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { UserState } from '@hiptraveler/data-access/user';

@Pipe({ name: 'following' })
export class FollowedPipe implements PipeTransform {

  constructor(private store: Store) { }

  transform(userId: string): Observable<boolean> {
    return this.store.select(UserState.following).pipe(
      map((following: string[] | null) => !!following?.includes(userId))
    )
  }

}

@Pipe({ name: 'processing' })
export class ProcessingPipe implements PipeTransform {

  transform(processing$: Observable<string[]>, userId: string): Observable<boolean> {
    return processing$.pipe(
      map((following: string[]) => following.includes(userId))
    );
  }

}

export const ProfileCardExportPipes = [
  FollowedPipe,
  ProcessingPipe
];

export const ProfileCardPipes = [
  ProfileCardExportPipes
];
