import { NgModule } from '@angular/core';

import { SkyscannerWidgetDirective } from './skyscanner-widget.directive';

const DIRECTIVES = [
  SkyscannerWidgetDirective
];

@NgModule({
  declarations: DIRECTIVES,
  exports: [ SkyscannerWidgetDirective ]
})
export class SkyscannerWidgetDirectiveModule { }
