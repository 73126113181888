import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultPageRootComponent } from './default-page-root.component';

const defaultPageRootRoutes: Routes = [
  {
    path: 'profile',
    loadChildren: () => import ('../page--profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'ambassador',
    loadChildren: () => import ('../page--ambassador/ambassador.module').then(m => m.AmbassadorModule)
  },
  {
    path: 'dnsmpi',
    loadChildren: () => import ('../page--dont-sell-info/dont-sell-info.module').then(m => m.DontSellInfoModule)
  },
  {
    path: 'travel',
    loadChildren: () => import ('../page--travel/travel.module').then(m => m.TravelModule)
  },
  {
    path: 'travel-agent-register',
    loadChildren: () => import ('../page--travel-agent-register/travel-agent-register.module').then(m => m.TravelAgentRegisterModule)
  },
  {
    path: 'about-us/legal/terms',
    loadChildren: () => import ('../page--terms-and-privacy-policy/terms-and-privacy-policy.module').then(m => m.TermsAndPrivacyPolicyModule),
    data: { pageType: 'terms-and-conditions' }
  },
  {
    path: 'about-us/legal/privacyPolicy',
    loadChildren: () => import ('../page--terms-and-privacy-policy/terms-and-privacy-policy.module').then(m => m.TermsAndPrivacyPolicyModule),
    data: { pageType: 'privacy-policy' }
  }
];

const routes: Routes = [
  {
    path: '',
    component: DefaultPageRootComponent,
    children: defaultPageRootRoutes
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class DefaultPageRootRoutingModule { }
