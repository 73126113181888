
<ng-container *ngIf="navbarAppend">

  <!-- Navbar User image wrapper -->
  <div class="banner-toolbar--user-image-wrapper mat-elevation-z4" [class.processing]="uploadImageProcessing" [@opacityFade]>
  
    <!-- Navbar User image processing UX and hover UI -->
    <div class="banner-toolbar--user-image-overlay" matRipple uploadProfilePhoto *ngIf="stateService.selfProfile$ | async">
      <ng-container *ngIf="stateService.uploadImageProcessing$$| async;else defaultHoverUI">
        <mat-progress-spinner mode="indeterminate" theme="white" [diameter]="30" [@opacityFade]></mat-progress-spinner>
      </ng-container>
      <ng-template #defaultHoverUI>
        <mat-icon svgIcon="page-profile-user-info.photo-svg" [@opacityFade]></mat-icon>
      </ng-template>
    </div>
  
    <!-- Navbar User image -->
    <ng-container *ngIf="service.id$ | async;else notAuthenticated">
      <img class="banner-toolbar--user-image" [lazyLoad]="(service.picUrl$ | async)!" [defaultImage]="authenticatedProfilePlaceholder" [errorImage]="authenticatedProfilePlaceholder" draggable="false" alt="Profile image"> 
    </ng-container>
    <ng-template #notAuthenticated>
      <img class="banner-toolbar--user-image" [lazyLoad]="(service.picUrl$ | async)!" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" alt="Profile image"> 
    </ng-template>
  
  </div>
  
  <!-- Navbar User full name -->
  <ng-container *ngIf="(service.profileDetails$ | async) as profile">
    <p class="banner-toolbar--user-name" [@slideInFromTop]>{{ profile.firstName }} {{ profile.lastName }}</p>
  </ng-container>

</ng-container>

<div class="banner">

  <ng-container *ngIf="navbarAppend">
    <div class="banner--toolbar-elevation banner--navbar-elevation" [@opacityFade]></div>

    <div class="banner--toolbar" [class.processing]="uploadImageProcessing" [@slideInFromTop]>

      <!-- Navbar toolbar selections -->
      <toolbar class="page-toolbar--component" component disableHighlight [selections]="selections"></toolbar>

    </div>
  </ng-container>

  <!-- Update banner image button component -->
  <div class="banner--image-overlay" [class.self-profile]="stateService.selfProfile$ | async" [class.uploading]="service.uploadProgress$$ | async">
    <ui-action-button theme="outlined-white-k" uploadCoverPhoto>{{ 'profile.change-cover' | translate }}</ui-action-button>
    <mat-progress-spinner mode="indeterminate" theme="white" diameter="50"></mat-progress-spinner>
  </div>

  <!-- Banner image -->
  <img class="banner-image" [lazyLoad]="(service.coverImage$ | async)!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Brand banner image"> 
  
</div>
