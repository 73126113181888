import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { FormEventService, FormFeatureService, FormSubmitService, viewProviders } from './view-providers';
import { SearchLocationData, SearchLocationService } from '@hiptraveler/common';
import { PlanTripService } from '../plan-trip.service';

@Component({
  selector: 'plan-trip-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  host: { class: 'experience-finder-screen-plan-trip-form' },
  encapsulation: ViewEncapsulation.None,
  viewProviders
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('placeAutocomplete') placeAutocomplete: ElementRef<HTMLInputElement>;

  minDate = new Date().toISOString();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public formFeature: FormFeatureService,
    public formSubmit: FormSubmitService,
    public formEvent: FormEventService,
    private planTrip: PlanTripService,
    private searchLocation: SearchLocationService
  ) { }

  ngOnInit(): void {
    this.planTrip.externalViewState$$.next(false);
    this.searchLocation.data && this.locationChanged(this.searchLocation.data);
    this.searchLocation.searchLocation$.subscribe(value => this.locationChanged(value));
  }

  ngOnDestroy(): void {
    this.planTrip.externalViewState$$.next(false);
  }

  ngAfterViewInit(): void {

    if (isPlatformServer(this.platformId)) return;

    this.formEvent.initialize({ placeAutocomplete: this.placeAutocomplete.nativeElement });
    this.formFeature.syncDaysAndDateRangeInputFields();
  }

  locationChanged(value: SearchLocationData): void {
    const { locId: place_id, location: formatted_address, name, latitude, longitude } = value;
    const location: any = {
      lat: () => +(latitude || '0'),
      lng: () => +(longitude || '0')
    };
    this.formFeature.form.patchValue({ place: name });
    setTimeout(() => {
      this.placeAutocomplete.nativeElement.value = name || '';
      this.formFeature.placeResult = { place_id, formatted_address, name, geometry: { location } };
    }, 10);
  }
  
  submit(): void {
    this.formSubmit.submit({ placeResult: this.formFeature.placeResult });
  }

}
