import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { SearchResultDialogMapModule } from '@hiptraveler/features/leaflet-map';
import { ItineraryActionModule } from '@hiptraveler/features/itinerary-action';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { StarRatingsModule } from '@hiptraveler/ui/star-ratings';
import { ToggleFavoriteDirectiveModule, AssetImageModule } from '../../common';

import { HighlightBannerComponent } from './highlight-banner.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule,
  NgxSkeletonLoaderModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  SearchResultDialogMapModule,
  ItineraryActionModule,
  ButtonsModule,
  StarRatingsModule,
  ToggleFavoriteDirectiveModule,
  AssetImageModule
];

const COMPONENTS = [
  HighlightBannerComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    HighlightBannerComponent
  ]
})
export class HighlightBannerModule { }
