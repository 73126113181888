import { Component } from '@angular/core';

import { TravelExperienceService } from './travel-experience.service';

@Component({
  selector: 'travel-experience',
  templateUrl: './travel-experience.component.html',
  styleUrls: ['./travel-experience.component.scss'],
  host: { class: 'ta-register-registration-form-inline' },
  viewProviders: [ TravelExperienceService ]
})
export class TravelExperienceComponent {

  constructor(
    public service: TravelExperienceService
  ) { }

}
