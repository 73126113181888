import { Experience, LocationData } from "@hiptraveler/data-access/api";
import { BrandStateModel } from "@hiptraveler/data-access/brand";

export function parseBrandState(state: BrandStateModel | null): BrandStateModel | null { // To do: for demo purposes only

  const experiences = (state?.experiences || []).filter(e => e.category !== 'experience');

  const additionalTravelStyleData = (state?.experiences || [])
    .filter(e => e.category === 'experience')
    .map((e) => ({ ...e, category: 'travelStyle' }))
    .filter(e => e.category === 'travelStyle');

  if (state?.experiences && state?.cLocations) {
    const locations = parseStateLocation(state.cLocations);
    return { ...state, experiences: [ ...experiences, ...additionalTravelStyleData, ...locations ] }
  }

  return { ...state, experiences: [ ...experiences, ...additionalTravelStyleData ] }
}

export function parseStateLocation(locations?: LocationData[]): Partial<Experience>[] {
  return locations?.map((e) => ({
    id: e.id,
    name: e.formattedAddr,
    code: e.formattedAddr.toLowerCase().replace(/,/g, '').replace(/\s+/g, '_'),
    img: e?.coverImg?.imgThumbnail || '',
    category: 'experience'
  } as any)) || [];
}
