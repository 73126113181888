import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ENVIRONMENT, Environment, WINDOW, subdomain, isWidget, currentLang, AppListenerService, pendingAuthProcessKey } from '@hiptraveler/common';
import { MainMenuView } from '../experience-finder.model';

@Injectable()
export class MainMenuService implements OnDestroy {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;

  #authHandler: boolean;
  authHandle$ = new Subject<void>();

  constructor(
    @Inject(WINDOW) private window: any,
    @Inject(ENVIRONMENT) private environment: Environment,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    public stateService: ExperienceFinderStateService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }
  
  stateObserver(): void {
    this.stateService.overlayState$.subscribe((state: boolean) => {
      this.#authHandler = state;
      state || this.resetAuthHandle();
    });
  }

  get isWidget(): boolean {
    return isWidget();
  }

  optionSelected(view: MainMenuView): unknown {
    if (!this.#authHandler) return;

    this.stateService.mainMenuView$$.next(view);

    const user = this.store.selectSnapshot(UserState.state);

    const domain = this.environment.local ? 'localhost:4200' : 'hiptraveler.com';
    const pathSubdomain = `${subdomain() === 'hiptraveler' ? '' : subdomain() + '.'}`;
    const userProfileUrl = `http://${pathSubdomain}${domain}/${currentLang()}/profile/${user?.uTitle}`;
    const userProfileUrlDirect = `/${currentLang()}/profile/${user?.uTitle}`;

    switch (view) {
      case 'new_trip': {
        this.stateService.mainMenuView$$.next('new_trip');
        this.stateService.dialogView$$.next('travel-style');
        return;
      }
      case 'plan_a_trip': {
        if (!user || !isWidget()) return;
        const url = `${isWidget() ? userProfileUrl : userProfileUrlDirect}/itineraries`;
        isWidget() ? this.window.open(url, '_blank') : this.router.navigateByUrl(url);
        return;
      }
      case 'my_favorites': {
        if (!this.store.selectSnapshot(UserState.authenticated)) {
          this.resetAuthHandle();
          const emitHandleKey = 'mainMenuMyFavorites';
          this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
          this.appListener.globalSignalListener(emitHandleKey).pipe(
            tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
            takeUntil(this.authHandle$)
          ).subscribe(() => this.optionSelected('my_favorites'));
          return;
        }
        const url = `${isWidget() ? userProfileUrl : userProfileUrlDirect}/favorites`;
        isWidget() ? this.window.open(url, '_blank') : this.router.navigateByUrl(url);
        return;
      }
      case 'new_travelogue': {
        if (!this.store.selectSnapshot(UserState.authenticated)) {
          const emitHandleKey = 'mainMenuNewTravelogue';
          this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
          this.appListener.globalSignalListener(emitHandleKey).pipe(
            tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
            takeUntil(this.authHandle$)
          ).subscribe(() => this.optionSelected('new_travelogue'));
          return;
        }
        this.router.navigateByUrl(`${currentLang()}/compose/new-story`);
        return;
      }
      case 'profile': {
        if (!user || !isWidget()) return;
        const url = `${isWidget() ? userProfileUrl : userProfileUrlDirect}/stories`;
        isWidget() ? this.window.open(url, '_blank') : this.router.navigateByUrl(url);
        return;
      }
      case 'logout': {
        this.store.dispatch(new AuthAction.SignOut);
        return;
      }
    }
    return;
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
