import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as SocialApi from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class SocialApiService {

  constructor(
    private http: HttpClient
  ) { }

  getYoutubeSearch(brand: string): Observable<SocialApi.GetYoutubeSearchResponse> {
    return this.http.get<SocialApi.GetYoutubeSearchResponse>(`/trip-planner/searchYoutube/${brand}`);
  }

  getInstagramSearch(id: string): Observable<any> {
    return this.http.get<any>(`/trip-planner/getUserInstaImages/${id}`);
  }

}
