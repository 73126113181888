import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'navbar-view',
  template: `
    <div class="mobile-navbar-view--wrapper mat-elevation-z2">
      <div class="mobile-navbar-view">
        <mat-icon (click)="actionClicked.emit()">west</mat-icon>
        <ng-content select="navbar-action"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./mobile-navbar-view.component.scss'],
  host: { class: 'navbar-view' }
})
export class MobileNavbarViewComponent {

  @Output() actionClicked = new EventEmitter();

}
