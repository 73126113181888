import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TravelAgentsModule } from './travel-agents/travel-agents.module';

import { TravelAgentsContentComponent } from './travel-agents-content.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  TravelAgentsModule
];

const COMPONENTS = [
  TravelAgentsContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TravelAgentsContentComponent
  ]
})
export class TravelAgentsContentModule { }
