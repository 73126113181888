import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SkyscannerViewModule } from '@hiptraveler/features/skyscanner-view';

import { TravelComponent } from './travel.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  SkyscannerViewModule
];

const COMPONENTS = [
  TravelComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: TravelComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class TravelModule { }
