import { AfterViewInit, Directive, ElementRef, HostListener, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, filter, takeUntil } from 'rxjs';

import { AuthRegisterDialogService } from './auth-register-dialog.service';
import { PlaceResult, initializeGoogleMapsPlacesAutocomplete, promiseDelay } from '@hiptraveler/common';

@Directive({
  selector: '[locationFieldInput]'
})
export class LocationFieldInputDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();
  inputSelectionSelected: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef<HTMLInputElement>,
    public service: AuthRegisterDialogService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {

    this.inputSelectionSelected = false;
    
    isPlatformBrowser(this.platformId) && initializeGoogleMapsPlacesAutocomplete(this.element, { default: true }).pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe((response: PlaceResult) => {
      this.inputSelectionSelected = true;
      this.service.form.patchValue({ userLoc: response?.formatted_address, location: response?.formatted_address });
    });
  }

  @HostListener('blur')
  async onBlur(): Promise<void> {
    promiseDelay(100);
    if (this.inputSelectionSelected) return;
    this.element.nativeElement.value = '';
    this.inputSelectionSelected = false;
  }

  @HostListener('input')
  onInput(): void {
    this.inputSelectionSelected = false;
  }

}
