import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ActivityDateData, ActivityDateDateData } from '@hiptraveler/data-access/api';
import { TranslationService } from '@hiptraveler/common';

@Pipe({
  name: 'datePlaceholder'
})
export class DatePlaceholderPipe implements PipeTransform {

  value$$ = new BehaviorSubject<string>('');

  constructor(
    private translate: TranslateService,
    private translation: TranslationService
  ) { }

  transform(activity: ActivityDateData, data?: ActivityDateDateData): Observable<string> {

    if (activity.hasMultiDayTour) return this.value$$.asObservable();

    if (!data) {

      this.value$$.next(`${this.translation.getText('itinerary', 'day')} ${activity.day} `);

      this.translate.onLangChange.asObservable().subscribe((event: LangChangeEvent) => {
        const text = event.translations?.['itinerary']?.['day'] || 'Day';
        this.value$$.next(`${text} ${activity.day} `);
      });
    }

    return this.value$$.asObservable();
  }

}
