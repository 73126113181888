import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';

import { GalleryImagesComponent } from './gallery-images.component';
import { UploadImagesDirective } from './upload-images.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatButtonModule,
  LazyLoadImageModule,
  TranslateModule
];

const COMPONENTS = [
  GalleryImagesComponent
];

const DIRECTIVES = [
  UploadImagesDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    GalleryImagesComponent
  ]
})
export class GalleryImagesModule { }
