import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { currentLang } from '@hiptraveler/common';

@Pipe({
  name: 'calendarDay'
})
export class CalendarDayPipe implements PipeTransform {

  constructor(
    private i18n: TranslateService
  ) { }

  transform(value: number): string {
    const days = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
  
    if (value >= 0 && value < days.length) {
      return this.getTranslation(days[value]);
    }

    return this.getTranslation('day');
  }

  getTranslation(value: string): string {
    return this.i18n.translations?.[currentLang()]?.['search-result']?.[value] || '';
  }

}
