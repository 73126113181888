import { Inject, Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable()
export class GalleryImagesService {

  constructor(
    @Inject(MAT_DIALOG_DATA) private form: FormGroup,
  ) { }

  get control(): FormArray {
    return this.form.get('tourMedia')! as FormArray;
  }

  get galleryImages$(): Observable<string[]> {
    return this.form.get('tourMedia')!.valueChanges;
  }

  removeImageByIndex(index: number) {
    this.control.removeAt(index);
  }

}
