import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { AppListenerService } from '@hiptraveler/common';

@Directive({
  selector: '[appClientWidthListener]'
})
export class ClientWidthListenerDirective implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef<HTMLDivElement>,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {

    if (isPlatformServer(this.platformId)) return;

    this.appListener.clientWidth$$.next(
      this.element.nativeElement.clientWidth
    );

    const observer = new ResizeObserver((entries: readonly ResizeObserverEntry[]) => {
      const width = entries[0].contentRect.width;
      this.appListener.clientWidth$$.next(width);
    });

    observer.observe(this.element.nativeElement);
  }

}
