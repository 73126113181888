<div class="story--image" [matTooltip]="'compose.change-cover-tooltip' | translate">

  <!-- Banner photo attribution -->
  <p class="story--image-attribution-text" [innerHTML]="(componentState$$ | async)?.bannerAttribution"></p>

  <!-- Banner photo -->
  <ng-container *ngIf="(componentState$$ | async)?.bannerImage as bannerImage">
    <img [lazyLoad]="bannerImage.url || 'https://www.hiptraveler.com/assets/img/business/contactimg.jpg'" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="bannerImage.filename">
  </ng-container>

  <!-- Change cover action option -->
  <div class="story--image-overlay-background" (click)="openFilePicker('banner')">
    <p><mat-icon>image</mat-icon> {{ 'compose.change-cover' | translate }}</p>
  </div>

  <div class="story--image-overlay">

    <!-- Banner overlay title text -->
    <ng-container *ngIf="navbarControl.navbarTitleState$$ | async as text">
      <p class="story--image-overlay-banner-text">{{ text }}</p>
    </ng-container>

    <!-- Banner overlay location items -->
    <div class="story--image-overlay-itineraries" *ngIf="itineraries?.length">
      <ng-container *ngFor="let itinerary of itineraries;">
        <p>{{ itinerary }}</p>
        <divider-svg-icon></divider-svg-icon>
      </ng-container>
    </div>

  </div>

</div>

<!-- Banner processing UX -->
<div class="story--image-pending" *ngIf="(componentState$$ | async)?.bannerProcessing">
  <mat-progress-spinner class="primary" mode="indeterminate"></mat-progress-spinner>
</div>
