import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';

import { ActivityDateData, ActivityDate as ActivityDateMap, UpdateItineraryActivityDto, UpdateItineraryActivityResponse } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ReplaceItineraryActivity } from '@hiptraveler/common';

export interface ActivityDate extends ActivityDateData {
  itineraryId?: string;
  actDate: ActivityDateData[];
  actDateMap: ActivityDateMap;
}

@Injectable({
  providedIn: 'root'
})
export class SearchPageControlStateService {

  activityDate$$ = new BehaviorSubject<ActivityDate | null>(null);
  activityDate$ = this.activityDate$$.asObservable();

  featureCardProcessing$$ = new BehaviorSubject<boolean>(false); // Toggle pointer events non of feature-card components
  featureCardProcessing$ = this.featureCardProcessing$$.asObservable();

  replaceItineraryActivity$$ = new BehaviorSubject<ReplaceItineraryActivity | null>(null);
  replaceItineraryActivity$ = this.replaceItineraryActivity$$.asObservable();

  requestCancellation$$ = new Subject<void>();
  requestCancellation$ = this.requestCancellation$$.asObservable();

  islandHopPayload$$ = new BehaviorSubject<UpdateItineraryActivityDto | null>(null);
  islandHopResponse$$ = new BehaviorSubject<UpdateItineraryActivityResponse | null>(null);
  islandHopRequestState$$ = new BehaviorSubject<boolean>(false);
  
  constructor(
    private store: Store
  ) { }

  get itineraryActionProcessing$(): Observable<boolean> {
    return this.featureCardProcessing$.pipe(
      map((state: boolean) => {
        return state 
          && (
            !!this.store.selectSnapshot(ItineraryState.imageInformation)
            || !!this.store.selectSnapshot(ItineraryState.hotelInformation)
          )
      })
    );
  }

}
