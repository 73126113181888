import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FormService } from './form.service';

@Component({
  selector: 'login-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  viewProviders: [ FormService ],
  host: {
    class: 'login-form'
  }
})
export class FormComponent implements OnInit {

  @Output() forgotPasswordClicked = new EventEmitter();

  constructor(
    public service: FormService
  ) { }

  ngOnInit(): void {
    this.service.initializeForm();
  }

  controlError(control: string): boolean {
    if (this.requiredControl(control)) return false;
    return this.service.form.get(control)?.errors !== null;
  }

  requiredControl(control: string): boolean {
    const errors = this.service.form.get(control)?.errors;
    return errors?.hasOwnProperty('required') && errors?.['required'];
  }

}
