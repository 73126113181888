import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { Store } from '@ngxs/store';
import { Observable, Subject, filter, map, takeUntil } from 'rxjs';

@Directive({
  selector: '[customInputBorder]'
})
export class CustomInputBorderDirective implements OnInit, OnDestroy {

  @Input() targetClass: string;
  @Input() targetElement: string;
  
  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store
  ) { }

  get borderRadius$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => brandCampaign?.campaignCustom?.customCss?.borderTopLeftRadius || ''),
      filter(Boolean)
    );
  }

  ngOnInit(): void {
    this.borderRadius$.pipe(takeUntil(this.subscription$)).subscribe((borderTopLeftRadius: string) => {
      if (this.targetClass) return this.modifyUIByTargetClass(borderTopLeftRadius);
      if (this.targetElement) return this.modifyUIByTargetElement(borderTopLeftRadius);
      this.modifyUIByElementRef(borderTopLeftRadius);
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  modifyUIByElementRef(borderTopLeftRadius: string): void {
    const element = this.element.nativeElement;
    element.style.borderRadius = '4px';
    element.style.borderTopLeftRadius = borderTopLeftRadius;
  }

  modifyUIByTargetClass(borderTopLeftRadius: string): void {
    const element = this.element.nativeElement.querySelector(`.${this.targetClass}`) as HTMLElement;
    element.style.borderRadius = '4px';
    element.style.borderTopLeftRadius = borderTopLeftRadius;
  }
  
  modifyUIByTargetElement(borderTopLeftRadius: string): void {
    const element = this.element.nativeElement.querySelector(this.targetElement) as HTMLElement;
    element.style.borderRadius = '4px';
    element.style.borderTopLeftRadius = borderTopLeftRadius;
  }

}
