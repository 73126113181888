import { Directive, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { LOCATION, currentLang, promiseDelay } from '@hiptraveler/common';

@Directive({
  selector: '[twitterIframe]'
})
export class TwitterIframeDirective implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  constructor(
    @Inject(LOCATION) private location: any,
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private store: Store
  ) { }

  async ngOnInit(): Promise<void> {

    const bodyElement = this.document.body as HTMLBodyElement;

    const scriptElement = this.renderer.createElement('script') as HTMLScriptElement;
    scriptElement.src = 'https://platform.twitter.com/widgets.js';
    scriptElement.async = true;

    bodyElement.appendChild(scriptElement);

    const parentElement = this.renderer.parentNode(this.element.nativeElement);

    await promiseDelay(100);

    const iframeId = 'twitter-widget-1';
    const iframeCreator = 'hiptraveler';
    const brand = this.store.selectSnapshot(BrandState.campaignCustom)?.socialLinks?.twitter;

    const source = `https://syndication.twitter.com/srv/timeline-profile/screen-name/${brand}?creatorScreenName=${iframeCreator}&amp;embedId=${iframeId}&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;lang=${currentLang()}&amp;origin=${this.origin}&amp;siteScreenName=${iframeCreator}&amp`;

    const iframeElement = this.renderer.createElement('iframe') as HTMLIFrameElement;

    iframeElement.id = iframeId;
    iframeElement.src = source;
    iframeElement.classList.add('twitter-iframe');

    parentElement.replaceChild(iframeElement, this.element.nativeElement);

    this.destroy$.asObservable().subscribe(() => {
      this.renderer.removeChild(parentElement, iframeElement);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  get origin(): string {
    return encodeURIComponent(this.location.origin);
  }

}
