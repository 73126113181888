export class RegexPattern {

  static email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static password: RegExp = /(?=.{7,})/;
  static profileName: RegExp = /^[a-zA-Z\u00C0-\u024F'-]{2,}(?:\s[a-zA-Z\u00C0-\u024F'-]{2,})*$/;
  static alpha: RegExp = /^[a-zA-Z ]+$/;
  static alphaSpecialChar: RegExp = /^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœñÑ.'`'\-\s]+$/;
  static numeric: RegExp = /^[0-9]+$/;
  static alphaNumeric: RegExp = /^[a-zA-Z0-9 ]+$/;
  static alphaNumericSpecial: RegExp = /^[a-zA-Z0-9,.<>?/\\:;'"\{\}\[\]\-`~_=+!@#$%^&*\(\)\s]+$/;
  static alphaNumericSpecialLetters: RegExp = /^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœñÑ]+$/;
  static htmlTag: RegExp = /^<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>$/;

  // Internalization compatible
  static alphaI18n: RegExp = /^[a-zA-Z\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;
  static alphaNumericI18n: RegExp = /^[a-zA-Z0-9\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;
  static alphaNumericSpecialI18n: RegExp = /^[a-zA-Z0-9,.<>?/\\:;'"\{\}\[\]\-`~_=+!@#$%^&*\(\)\s|\u00c0-\u01ff|\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]+$/;

  // Social url
  static webUrl: RegExp = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/;
  static twUrl: RegExp = /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/;
  static instagramUrl: RegExp = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+\/?$/;
  static fbUrl: RegExp = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]+\/?$/;
  static pintUrl: RegExp = /^(https?:\/\/)?(www\.)?pinterest\.com\/[a-zA-Z0-9-_]+\/?$/;
  static youtubeUrl: RegExp = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?[a-zA-Z0-9_-]+$/;
  static tiktokUrl: RegExp = /^(https?:\/\/)?(www\.)?tiktok\.com\/(@[a-zA-Z0-9_-]+\/video\/[0-9]+|v\/[0-9]+|@?[a-zA-Z0-9_-]+)$/;

}
