import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ActionButtonModule } from '../shared';

import { HomeMenuComponent } from './home-menu.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ActionButtonModule
];

const COMPONENTS = [
  HomeMenuComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    HomeMenuComponent
  ]
})
export class HomeMenuModule { }
