import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[featureCard]'
})
export class FeatureCardDirective implements AfterViewInit {

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngAfterViewInit(): void {
    
    const itineraryRatingReviews = this.element.nativeElement.querySelector('.itinerary-rating-reviews') as HTMLElement;
    const itineraryRatingReviewsTarget = itineraryRatingReviews?.querySelector('p span')?.innerHTML;
    if (itineraryRatingReviewsTarget?.startsWith('0') && itineraryRatingReviews.parentElement) {
      itineraryRatingReviews.parentElement.style.opacity = '0';
    }
    
    const itineraryReviews = this.element.nativeElement.querySelector('.itinerary-reviews') as HTMLElement;
    const itineraryReviewsTarget = itineraryReviews?.querySelector('p span')?.innerHTML;
    if (itineraryReviewsTarget?.startsWith('0') && itineraryReviews.parentElement) {
      itineraryReviews.parentElement.style.opacity = '0';
    }
  }

}
