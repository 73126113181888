import { Inject, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, delay, distinctUntilChanged, filter, take, tap } from 'rxjs';

import { ProfileState } from '@hiptraveler/data-access/profile';
import { FormSelection, SelectionTargetPosition } from './registration-form.model';

export const PATCH_DELAY: number = 1000;

@Injectable()
export class RegistrationFormStateService {

  taSaved$$ = new BehaviorSubject<boolean>(false);
  submitProcessing$$ = new BehaviorSubject<boolean>(false);

  activeSelection$$ = new BehaviorSubject<FormSelection>('none');
  activeSelection$ = this.activeSelection$$.asObservable().pipe(distinctUntilChanged());

  selectionTargetPosition$$ = new BehaviorSubject<SelectionTargetPosition>({});

  selectionTargetPosition(target: FormSelection, position: number): void {
    const value = { ...this.selectionTargetPosition$$.value };
    value[target] = +position.toFixed(0);
    this.selectionTargetPosition$$.next(value);
  }

  getTargetPosition(target: FormSelection): number {
    const value = { ...this.selectionTargetPosition$$.value };
    return value?.[target] || 0;
  }

  form: FormGroup = this.fb.group({
    id: [ 'untitled' ],
    active: [ false ],
    type: [ 'htAgent', [ Validators.required ] ],
    firstName: [ '', [ Validators.required ] ],
    lastName: [ '', [ Validators.required ] ],
    email: [ '', [ Validators.required ] ],
    location: [ '', [ Validators.required ] ],
    certId: [ '' ],
    certType: [ '' ],
    promoCode: [ '' ],
    social: this.fb.group({
      facebook: [ '' ],
      instagram: [ '' ],
      tiktok: [ '' ],
      twitter: [ '' ],
      youtube: [ '' ],
      pinterest: [ '' ],
      website: [ '' ],
      rss: [ '' ]
    }),
    membership: this.fb.group({
      plan: [ 'premium' ],
      type: [ null ],
      subId: [ null ],
      method: [ null ],
      renewalDate: [ null ],
      subActive: [ true ]
    }),
    trExp: this.fb.group({
      desc: [ '' ]
    }),
    categories: this.fb.array([]),
    subCategories: this.fb.array([]),
    destinations: this.fb.array([]),
    lang: this.fb.array([]),
  });

  get socialForm(): FormGroup        { return this.form.get('social')        as FormGroup; }
  get membershipForm(): FormGroup    { return this.form.get('membership')    as FormGroup; }
  get trExpForm(): FormGroup         { return this.form.get('trExp')         as FormGroup; }
  get categoriesForm(): FormArray    { return this.form.get('categories')    as FormArray; }
  get subCategoriesForm(): FormArray { return this.form.get('subCategories') as FormArray; }
  get destinationsForm(): FormArray  { return this.form.get('destinations')  as FormArray; }
  get langForm(): FormArray          { return this.form.get('lang')          as FormArray; }

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store
  ) { }

  initializeFormObserver(callback: () => void): void {
    this.store.select(ProfileState.travelAgent).pipe(
      filter(Boolean),
      delay(PATCH_DELAY + 100),
      tap(callback.bind(this)),
      take(1)
    ).subscribe();
  }

  resetForm(): void {
    this.form.reset();
    this.socialForm.reset();
    this.membershipForm.reset();
    this.trExpForm.reset();
    this.categoriesForm.clear();
    this.subCategoriesForm.clear();
    this.destinationsForm.clear();
    this.langForm.clear();
  }

}
