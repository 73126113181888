import { AfterViewInit, Component, ElementRef } from '@angular/core';

import { ChecklistService } from './checklist.service';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  viewProviders: [ ChecklistService, SearchResultDialogActionService ],
  animations: [ opacityFade() ]
})
export class ChecklistComponent implements AfterViewInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: ChecklistService
  ) { }

  ngAfterViewInit(): void {
    this.service.initializeHostState(this.element.nativeElement);
  }

}
