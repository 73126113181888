import { NgModule } from '@angular/core';

import { ExternalLinkDirective } from './external-link.directive';

const DIRECTIVES = [
  ExternalLinkDirective
];

@NgModule({
  declarations: DIRECTIVES,
  exports: [ ExternalLinkDirective ]
})
export class ExternalLinkDirectiveModule { }
