import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { UserInfoMobileComponent } from './user-info-mobile.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  UserInfoMobileComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    UserInfoMobileComponent
  ]
})
export class UserInfoMobileModule { }
