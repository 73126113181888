import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { RequestCancellationService } from '@hiptraveler/common';
import { CodeKeyType, VerificationService } from './verification.service';

@Component({
  selector: 'auth-dialog-forgot-password-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['../forgot-password.component.scss', './verification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ VerificationService ]
})
export class VerificationComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() backToForgotPasswordClicked = new EventEmitter();
  @Output() createPasswordClicked = new EventEmitter();
  
  @ViewChild('code1', { static: false }) code1: ElementRef<HTMLInputElement>;
  @ViewChild('code2', { static: false }) code2: ElementRef<HTMLInputElement>;
  @ViewChild('code3', { static: false }) code3: ElementRef<HTMLInputElement>;
  @ViewChild('code4', { static: false }) code4: ElementRef<HTMLInputElement>;
  @ViewChild('code5', { static: false }) code5: ElementRef<HTMLInputElement>;
  @ViewChild('code6', { static: false }) code6: ElementRef<HTMLInputElement>;

  constructor(
    public service: VerificationService,
    private requestCancellation: RequestCancellationService
  ) { }
  
  ngOnInit(): void {
    this.service.initializeForm();
    this.service.successListener$$.subscribe(() => this.createPasswordClicked.emit());
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
  }
  
  ngAfterViewInit(): void {
    this.service.otpFocusChangesObserver({
      code1: this.code1.nativeElement, code2: this.code2.nativeElement,
      code3: this.code3.nativeElement, code4: this.code4.nativeElement,
      code5: this.code5.nativeElement, code6: this.code6.nativeElement
    });
  }

  cursorActive(key: CodeKeyType, input: HTMLInputElement): void {
    const formControl = this.service.form.get(key);
    this.service.activeCodeField$$.next(key);
    input.value = '';
    setTimeout(() => {
      formControl?.setValue(formControl.value, { emitEvent: false });
    });
  }

  changeField(event: KeyboardEvent): void {
    const fieldCode = +this.service.activeCodeField$$.value?.replace('code', '')!;
    if (event.key === 'ArrowLeft' && (fieldCode-1 !== 0)) {
      const nextField = `code${fieldCode-1}` as CodeKeyType;
      this[nextField].nativeElement.focus();
      this[nextField].nativeElement.click();
      this.service.activeCodeField$$.next(nextField);
    }
    if (event.key === 'ArrowRight' && (fieldCode+1 !== 7)) {
      const nextField = `code${fieldCode+1}` as CodeKeyType;
      this[nextField].nativeElement.focus();
      this[nextField].nativeElement.click();
      this.service.activeCodeField$$.next(nextField);
    }
  }

  controlError(control: string): boolean {
    if (this.requiredControl(control)) return false;
    return this.service.form.get(control)?.errors !== null;
  }

  requiredControl(control: string): boolean {
    const errors = this.service.form.get(control)?.errors;
    return errors?.hasOwnProperty('required') && errors?.['required'];
  }

}
