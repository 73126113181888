import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, delay, distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';

import { Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { nonHT } from './form-helper';
import { ExperienceFinderStateService, formStoreKey } from '@hiptraveler/features/experience-finder';
import * as Common from '@hiptraveler/common';

@Injectable()
export class FormFeatureService {

  @Select(BrandState.hiptravelerBrand) hiptravelerBrand$: Observable<unknown | null>;

  #placeResult: Common.PlaceResult = null;

  dateRangeDisplay$$ = new BehaviorSubject<string>('');

  travelDurationSelect$: Observable<Partial<Experience>[]> = this.i18n.getTranslation(Common.currentLang()).pipe(
    map((translations: any) => [
      { name: `${this.getText(translations, 'traveling-for')} 1 ${this.getText(translations, 'day')}`, code: '1-day', img: '' },
      ...Array.from({ length: 29 }, (_, index) => ({
        name: `${this.getText(translations, 'traveling-for')} ${index + 2} ${this.getText(translations, 'days')}`,
        code: `${index + 2}-days`
      }))
    ])
  );

  form: FormGroup = this.fb.group({
    place: [ null ],
    days: [ null ],
    startDate: [ null ],
    endDate: [ null ],
    itinerary: [ nonHT() ? 'auto' : 'own' ]
  });

  set placeResult(placeResult: Common.PlaceResult) {
    this.#placeResult = placeResult;
  }

  get placeResult(): Common.PlaceResult {
    const session = sessionStorage.getItem(formStoreKey);
    const sessionValue = JSON.parse(session || '{}');
    const sessionPlaceResult = session ? {
      ...sessionValue.placeResult,
      geometry: {
        location: {
          lat: () => +(sessionValue.lat || '0'),
          lng: () => +(sessionValue.lng || '0')
        }
      }
    } : null
    return this.#placeResult || sessionPlaceResult;
  }

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private i18n: TranslateService,
    private stateService: ExperienceFinderStateService
  ) { }

  syncDaysAndDateRangeInputFields(): void {
    this.daysFieldFormObserver();
    this.startDateFormObserver();
    this.endDateFormObserver();
    this.storeStorage();
  }

  clearDateField(): void {
    this.form.patchValue({ startDate: '', endDate: '', days: null });
  }

  private storeStorage(): void {

    const sessionFormValue = sessionStorage.getItem(formStoreKey);

    if (sessionFormValue) {
      if (!this.stateService.overlayState$$.value) return;
      const formValue = JSON.parse(sessionFormValue);

      this.placeResult = {
        ...formValue.placeResult,
        geometry: {
          location: {
            lat: () => +(formValue.lat || '0'),
            lng: () => +(formValue.lng || '0')
          }
        }
      };

      if (formValue?.days?.includes('NaN')) {
        delete formValue.days;
      }

      setTimeout(() => this.form.patchValue(formValue));
    }

    this.form.valueChanges.pipe(delay(500)).subscribe((form: any) => {
      if (!this.stateService.overlayState$$.value) return;
      const value = JSON.parse(sessionStorage.getItem(formStoreKey) || '{}');
      const valuePlaceResult = value?.placeResult;
      const sessionPlaceResult = this.placeResult || valuePlaceResult;

      if (form?.days?.includes('NaN')) {
        delete form.days;
      }

      sessionStorage.setItem(formStoreKey, JSON.stringify({
        ...form,
        placeResult: sessionPlaceResult,
        lat: this.#placeResult?.geometry?.location?.lat() || value?.lat,
        lng: this.#placeResult?.geometry?.location?.lng() || value?.lng
      }, Common.removeCircularReferences()));
    });
  }

  private daysFieldFormObserver(): void {
    this.form.get('days')?.valueChanges.subscribe((value: string) => {

      if (!this.form.value.startDate) return;

      const days = +value?.split('-')?.[0];
      const endDate = this.startDate.toDate();
      endDate.setDate(endDate.getDate() + days-1);
      this.form.patchValue({ endDate: Common.isWidget() ? endDate : moment(endDate) }, { emitEvent: false });
      this.modifyDateRangeDisplay({ startDate: this.startDate, endDate: moment(endDate) });
    });
  }

  private startDateFormObserver(): void {
    this.form.get('startDate')?.valueChanges.pipe(
      filter(Boolean),
      distinctUntilChanged(),
    ).subscribe(() => this.dateRangeDisplay$$.next(``));
  }

  private endDateFormObserver(): void {
    this.form.get('endDate')?.valueChanges.pipe(
      map((value: Moment) => moment.isMoment(value) ? value : moment(value)),
    ).subscribe((endDate: Moment) => {
      const timeDifference = endDate.toDate().getTime() - this.startDate.toDate().getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.form.patchValue({ days: `${daysDifference+1}-days` }, { emitEvent: false });
      this.modifyDateRangeDisplay({ startDate: this.startDate, endDate });
    });
  }

  private get startDate(): Moment {
    const formStartDate = this.form.value.startDate;
    return moment.isMoment(formStartDate) ? formStartDate : moment(formStartDate);
  }

  private modifyDateRangeDisplay({ startDate, endDate }: { startDate: Moment, endDate: Moment }): void {
    const localeConfig: Intl.DateTimeFormatOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };
    const format = (date: any) => moment(date)?.toDate()?.toLocaleDateString('en-US', localeConfig);
    const startDateDisplay = format(startDate) === 'Invalid Date' ? '' : format(startDate);
    const endDateDisplay = format(endDate) === 'Invalid Date' ? '' : format(endDate);
    this.dateRangeDisplay$$.next(startDateDisplay ? `${startDateDisplay.replace(/\//g, '-')} to ${endDateDisplay.replace(/\//g, '-')}` : '');
  }

  private getText(translations: any, value: string): string {
    const text = translations?.['exp-finder']?.[value];
    return text ? text : '';
  }

}
