import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { ClientActionModule } from './client-action/client-action.module';

import { ClientInfoComponent } from './client-info.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule
];

const LIBRARIES = [
  ClientActionModule
];

const COMPONENTS = [
  ClientInfoComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ClientInfoComponent
  ]
})
export class ClientInfoModule { }
