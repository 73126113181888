import { Component } from '@angular/core';

import { SearchBannerActionsService } from './search-banner-actions.service';

@Component({
  selector: 'search-banner-actions',
  templateUrl: './search-banner-actions.component.html',
  styleUrls: ['./search-banner-actions.component.scss'],
  viewProviders: [ SearchBannerActionsService ]
})
export class SearchBannerActionsComponent {

  constructor(
    public service: SearchBannerActionsService
  ) { }

}
