import { ModuleWithProviders, NgModule } from '@angular/core';

import { FILESTACK_CLIENT } from './filestack-core.token';
import { filestackClient } from './filestack-client';

@NgModule()
export class FilestackCoreModule {

  static forRoot(value: { apiKey: string }): ModuleWithProviders<FilestackCoreModule> {
    return {
      ngModule: FilestackCoreModule,
      providers: [
        { provide: FILESTACK_CLIENT, useValue: filestackClient(value.apiKey) }
      ]
    };
  }

}
