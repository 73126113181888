import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dialog-close-button',
  template: `
    <button mat-icon-button matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
  `,
  styles: [`
    .dialog-close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
    .dialog-close-button
    .mat-icon {
      color: #4d4d4d;
    }
  `],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog-close-button' }
})
export class DialogCloseButtonComponent { }
