import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { AboutSectionComponent } from './about-section.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  AboutSectionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    AboutSectionComponent
  ]
})
export class AboutSectionModule { }
