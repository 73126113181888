import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { SearchResultDialogMapModule } from '@hiptraveler/features/leaflet-map';
import { ItineraryActionModule } from '@hiptraveler/features/itinerary-action';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { MouseDragDirectiveModule } from '@hiptraveler/common';
import { ToggleFavoriteDirectiveModule, AssetImageModule } from '../../common';

import { HighlightCarouselComponent } from './highlight-carousel.component';
import { AuthorImageDirective } from './author-image.directive';
import { PhotoLengthDirective } from './photo-length.directive';
import { AuthorImageVisibilityDirective } from './author-image-visibility.directive';
import { ActionBubbleLayoutDirective } from './action-bubble-layout.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatIconModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  SearchResultDialogMapModule,
  ItineraryActionModule,
  ButtonsModule,
  MouseDragDirectiveModule,
  ToggleFavoriteDirectiveModule,
  AssetImageModule
];

const COMPONENTS = [
  HighlightCarouselComponent
];

const DIRECTIVES = [
  AuthorImageDirective,
  PhotoLengthDirective,
  AuthorImageVisibilityDirective,
  ActionBubbleLayoutDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    HighlightCarouselComponent
  ]
})
export class HighlightCarouselModule { }
