import { Pipe, PipeTransform } from '@angular/core';

import { ListItem, ListItemType } from '../../list-items';

@Pipe({
  name: 'typeFilter',
  pure: false
})
export class TypeFilterPipe implements PipeTransform {

  transform(value: ListItem[], type?: ListItemType): ListItem[] {
    if (!type) return value;
    return value.filter(e => e.type === type);
  }

}
