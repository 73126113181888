<navbar-view (actionClicked)="dialogRef.close()"></navbar-view>
<br><br>

<h1>{{ null | viewTitle | translate }}</h1>

<ng-container *ngIf="service.selectionList;else nonSelection">

  <p class="activity-input--section-title">Experience</p>

  <ng-container *ngFor="let display of data.listItems | filterData: 'exp';">
    <ng-container *ngIf="data.filtersService.selectedTravelStyleKeys$ | async as selected">
      <p class="activity-input--section-items" (click)="toggleSelection(display.value, true)" [class.active]="display | selection: selected">
        <span>{{ display.name }}</span>
        <mat-icon *ngIf="display | selection: selected">done</mat-icon>
      </p>
    </ng-container>
  </ng-container>
  
  <hr>
  
  <p class="activity-input--section-title" translate="search-page.travel-style"></p>

  <ng-container *ngFor="let display of data.listItems | filterData: 'travelStyle';">
    <ng-container *ngIf="data.filtersService.selectedTravelStyleKeys$ | async as selected">
      <p class="activity-input--section-items" (click)="toggleSelection(display.value, true)" [class.active]="display | selection: selected">
        <span>{{ display.name }}</span>
        <mat-icon *ngIf="display | selection: selected">done</mat-icon>
      </p>
    </ng-container>
  </ng-container>

  <hr>
  
  <p class="activity-input--section-title" translate="search-page.activities"></p>

  <ng-container *ngFor="let display of data.listItems | filterData: 'activities';">
    <ng-container *ngIf="data.filtersService.selectedItemKeys$ | async as selected">
      <p class="activity-input--section-items" (click)="toggleSelection(display.value, false)" [class.active]="display | selection: selected">
        <span>{{ display.name }}</span>
        <mat-icon *ngIf="display | selection: selected">done</mat-icon>
      </p>
    </ng-container>
  </ng-container>

</ng-container>
<ng-template #nonSelection>
  
  <ng-container *ngFor="let display of data.listItems | filterData: 'amenities';">
    <ng-container *ngIf="data.filtersService.selectedItemKeys$ | async as selected">
      <p class="activity-input--section-items" (click)="toggleSelection(display.value, false)" [class.active]="display | selection: selected">
        <span>{{ display.name }}</span>
        <mat-icon *ngIf="display | selection: selected">done</mat-icon>
      </p>
    </ng-container>
  </ng-container>

</ng-template>

<div class="activity-input--actions">

  <ui-action-button theme="outlined-primary" (click)="clearFilters()">{{ 'search-page.clear-filters' | translate }}</ui-action-button>
  <ui-action-button (click)="dialogRef.close(1)">{{ 'search-page.lets-go' | translate }}</ui-action-button>

</div>
