import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, filter, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { skeletonVisibilityDelay } from '../common';

@Directive({
  selector: '[alignSize]'
})
export class AlignSizeDirective implements AfterViewInit {

  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngAfterViewInit(): void {

    const width = this.element.nativeElement.clientWidth;

    setTimeout(() => {
      this.element.nativeElement.style.width = `${width}px`;
    }, skeletonVisibilityDelay);

    this.imageInformation$.pipe(
      filter(Boolean),
      take(1)
    ).subscribe(() => this.element.nativeElement.style.width = `${width}px`);
  }

}
