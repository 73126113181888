import { Component } from '@angular/core';

import { UserInfoService } from '../user-info/user-info.service';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { AppListenerService, authenticatedProfilePlaceholder, profilePlaceholder } from '@hiptraveler/common';
import { ProfileStateService } from '../../common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'user-info-mobile',
  templateUrl: './user-info-mobile.component.html',
  styleUrls: ['./user-info-mobile.component.scss'],
  host: { class: 'page-profile-activity-content-user-info-mobile' },
  viewProviders: [ UserInfoService, SendMessageDialogActionService, AuthDialogActionService ],
  animations: [ opacityFade() ]
})
export class UserInfoMobileComponent {

  profilePlaceholder: string = profilePlaceholder;
  authenticatedProfilePlaceholder: string = authenticatedProfilePlaceholder;

  constructor(
    public appListener: AppListenerService,
    public service: UserInfoService,
    public stateService: ProfileStateService
  ) { }

}
