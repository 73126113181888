import { getWindowRef, PageType, subdomain } from "@hiptraveler/common";

const findInspirationText: string = `Find your inspiration and create your own experience by using our ${brandName()} trip planner. Build your custom itinerary using our trip planner by filtering results based on your custom travel styles.`;

function brandName(): string {
  return subdomain()[0].toUpperCase() + subdomain().slice(1);
}

function getLocation(): string | null {
  
  const path = getWindowRef()?.location?.pathname;
  const location = decodeURIComponent(path?.split('/')?.[3] || '');

  return location ? location?.[0].toUpperCase() + location?.slice(1) : null;
}

export function homeHeadingValue(): Record<PageType, string> {
  return {

    // shell--default-page-root
  
    travel: findInspirationText,
    ambassador: findInspirationText,
    dnsmpi: `Do Not Sell My Personal Information`,
    privacyPolicy: `Privacy Policy and Terms of Service set for this website.`,
    terms: `Privacy Policy and Terms of Service set for this website.`,
    profile: findInspirationText,
    ['travel-agent-register']: findInspirationText,
  
    // shell--search-page-root
  
    search: getLocation()
      ? `${getLocation()} Trip Planner | Plan your next trip | Explore things to do, tour, activities by adding your custom travel styles and build your next trip by using our ${brandName()} trip planner`
      : findInspirationText,
    experiences: getLocation()
      ? `Read travel stories about ${getLocation()} and get inspired to create your own experience by using our ${brandName()} blog creator or trip planner | read blogs, plan a trip, create a travelogue and share experiences.`
      : `Hip experiences with itineraries blended in. HipTravelers share their travel experiences by adding an itinerary to a travel blog and recommend must see places and things to do.`,
    thingstodo: getLocation()
      ? `Find things to do in ${getLocation()}. Build a custom itinerary using our ${brandName()} trip planner by adding your travel style.`
      : `Things to do | ${brandName()} | Explore things to do, tour, activities by adding your custom travel styles and build your next trip`,
    hotels: getLocation()
      ? `Find best deals on hotels on ${brandName()} for ${getLocation()} and filter results by your custom travel style`
      : `Find best deals on hotels on ${brandName()} and filter results by your custom travel style`,
    foodanddrink: getLocation()
      ? `Find best restaurants in ${getLocation()}. Build your custom itinerary by using ${brandName()} trip planner and add in your travel style.`
      : `Find best restaurants for ${brandName()} and filter results by your custom travel style`,
    community: getLocation()
      ? `${getLocation()} Trip Planner | Plan your next trip | Explore things to do, tour, activities by adding your custom travel styles and build your next trip by using our ${brandName()} trip planner`
      : `Connect with fellow hip travelers here. Search them by their names. Share your experiences as well follow other travelers travel stories.`,
  
  };
}
