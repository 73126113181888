import { NgModule } from '@angular/core';

import { ApplyActionDirective } from './apply-action.directive';
import { ClearActionDirective } from './clear-action.directive';
import { NameFilterActionDirective } from './name-filter-action.directive';
import { ClearNameActionDirective } from './clear-name-action.directive';
import { TravelDatesFieldDirective } from './travel-dates-field.directive';
import { ToLocalTimePipe } from './to-local-time.pipe';
import { CheckedPipe } from './checked.pipe';
import { CbLabelI18nPipe } from './cb-label-i18n.pipe';

const DIRECTIVES = [
  ApplyActionDirective,
  ClearActionDirective,
  NameFilterActionDirective,
  ClearNameActionDirective,
  TravelDatesFieldDirective
];

const PIPES = [
  ToLocalTimePipe,
  CheckedPipe,
  CbLabelI18nPipe
];

@NgModule({
  declarations: [
    DIRECTIVES,
    PIPES
  ],
  exports: [
    DIRECTIVES,
    PIPES
  ]
})
export class UtilsModule { }
