import { chromaNamespaceKey, getWindowRef, PromiseReject, PromiseResolve } from "@hiptraveler/common";

declare const chroma: any;

export function chromaClient(): Promise<any> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    const windowRef = getWindowRef();

    let
      bodyElement: HTMLBodyElement | null = null,
      scriptElement: HTMLScriptElement | null = null;
  
    if (typeof windowRef === 'object' && windowRef) {
      bodyElement = windowRef?.document?.body as HTMLBodyElement;
      scriptElement = windowRef?.document?.createElement('script') as HTMLScriptElement;
    }
  
    if (!bodyElement || !scriptElement) return;

    scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/chroma-js/2.4.2/chroma.min.js';
    scriptElement.async = true;

    bodyElement.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {

        getWindowRef()[chromaNamespaceKey] = chroma;

        resolve(chroma);
        console.log('Chroma script loaded.');
      } catch (innerError) {

        reject(innerError);
        console.error('Error initializing Chroma:', innerError);
      }
    };
  });
}
