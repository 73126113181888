import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CheckboxLabel } from '../common';
import { currentLang } from '@hiptraveler/common';

@Pipe({
  name: 'cbLabelI18n'
})
export class CbLabelI18nPipe implements PipeTransform {

  constructor(
    private i18n: TranslateService
  ) { }

  transform($: Observable<CheckboxLabel>): Observable<CheckboxLabel> {
    return $.pipe(
      map((value: string) => {
        return this.i18n.translations?.[currentLang()]?.['search-page']?.[value];
      })
    );
  }

}
