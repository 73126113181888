import { Component, Input } from '@angular/core';

import { HotelResultData } from '@hiptraveler/data-access/api';

@Component({
  selector: 'policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss'],
  host: { class: 'content' }
})
export class PolicyDetailsComponent {

  @Input() data: HotelResultData;

}
