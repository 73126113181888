import { Metadata, ObserverConfig, getLocationByPath, metaTagValue, Param } from "@hiptraveler/features/meta-tag";
import { PageType } from "@hiptraveler/common";

function getConditionParam(param: PageType): string {
  if (param === 'privacyPolicy' || param === 'terms') {
    return `about-us/legal/${param}`;
  } else {
    return param;
  }
}

function validateDefaultAndSearchPage({ config, param }: Param, callback: (metadata: Metadata) => void): void {
  
  if (config?.url?.includes('/travel-story/')) return;
  if (!config?.url?.includes(`/${config.lang}/${getConditionParam(param)}`)) return;

  callback(
    metaTagValue({
      location: config?.metadata?.callback?.['location'] || getLocationByPath(config.url),
      url: config.url
    })[param]
  );
}

export function observeDefaultAndSearchPage(config: ObserverConfig, callback: (metadata: Metadata) => void): void {

  const params: PageType[] = [
    'travel', 'ambassador', 'dnsmpi', 'privacyPolicy', 'terms', 'profile', 'travel-agent-register',
    'search', 'experiences', 'thingstodo', 'hotels', 'foodanddrink', 'community',
  ];

  params.forEach((param: PageType) => validateDefaultAndSearchPage(
    { config, param },
    (value: Metadata) => callback({ ...value, ...(config?.metadata || {}) })
  ));
}
