import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';

@Injectable()
export class RegistrationMenuService {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;

}
