import { Component, ElementRef, OnInit, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { ChatCompletionsService, Conversation } from '@hiptraveler/core/openai';
import { HiptravelerGptService } from './hiptraveler-gpt.service';
import { DefaultUiService } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-hiptraveler-gpt',
  templateUrl: './hiptraveler-gpt.component.html',
  styleUrls: ['./hiptraveler-gpt.component.scss'],
  host: { class: 'page-hiptraveler-gpt' },
  viewProviders: [ DefaultUiService, HiptravelerGptService, ChatCompletionsService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn(400) ]
})
export class HiptravelerGptComponent implements OnInit {

  @ViewChild('conversationBox') conversationBox: ElementRef<HTMLDivElement>
  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>

  contentInput: string = '';
  pending: boolean = false;

  constructor(
    @Self() private defaultUI: DefaultUiService,
    public service: HiptravelerGptService,
    private chatCompletions: ChatCompletionsService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.chatCompletions.authInitialize();
    this.conversationScrollBottom();
  }

  get conversations$(): Observable<Conversation[]> {
    return this.chatCompletions.conversations$;
  }

  sendMessage(): void {

    if (!this.contentInput) return;

    this.toggleInputState(true);

    this.chatCompletions.sendMessage(this.contentInput).then(() => {
      this.toggleInputState(false);
      this.conversationScrollBottom();
    }).catch((error) => {
      this.toggleInputState(false);
      this.conversationScrollBottom();
      this.snackbar.open({ message: error?.message, duration: 5000 });
    });
  }

  conversationScrollBottom(): void {
    if (!this.conversationBox?.nativeElement) return;
    setTimeout(() => (this.conversationBox.nativeElement.scrollTop = this.conversationBox.nativeElement.scrollHeight));
  }

  toggleInputState(state: boolean): void {

    const element = this.inputField.nativeElement;

    if (state) {
      element.style.pointerEvents = 'none';
      element.value = '';
      this.conversationScrollBottom();
      setTimeout(() => {
        this.pending = true;
        this.conversationScrollBottom();
      }, 300);
    } else {
      this.pending = false;
      element.style.pointerEvents = 'stroke';
    }
  }

  resetConversation(): void {
    this.pending = false;
    this.conversationScrollBottom();
    this.chatCompletions.clearConversations();
  }

}
