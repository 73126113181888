import { AboutTabModule } from './about-tab/about-tab.module';
import { FavoritesTabModule } from './favorites-tab/favorites-tab.module';
import { ItinerariesTabModule } from './itineraries-tab/itineraries-tab.module';
import { StoriesTabModule } from './stories-tab/stories-tab.module';
import { UploadsTabModule } from './uploads-tab/uploads-tab.module';
import { FollowersTabModule } from './followers-tab/followers-tab.module';
import { FollowsTabModule } from './follows-tab/follows-tab.module';

export const SelectionTabs = [
  AboutTabModule,
  FavoritesTabModule,
  ItinerariesTabModule,
  StoriesTabModule,
  UploadsTabModule,
  FollowersTabModule,
  FollowsTabModule
];
