<div class="agent-details">

  <div activeSelection class="agent-details--link-id" id="general-information"></div>
  <div class="agent-details--profile">

    <p translate="reg-form.contact-info"></p>

    <ng-container *ngIf="service.userState$ | async as user;else notAuthenticatedUI">

      <ng-container *ngIf="user?.picUrl as picUrl;else missingPicUrl">
        <img [lazyLoad]="picUrl || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" [alt]="user?.fullName">
      </ng-container>
      <ng-template #missingPicUrl>
        <mat-icon svgIcon="registration-form-agent-details.profile-svg"></mat-icon>
      </ng-template>
      
    </ng-container>
    <ng-template #notAuthenticatedUI>
      <mat-icon svgIcon="registration-form-agent-details.profile-svg"></mat-icon>
    </ng-template>

    <ui-action-button size="medium" (click)="service.importProfile()">{{ 'reg-form.button-text-import-profile' | translate }}</ui-action-button>

    <p class="agent-details--profile-head" translate="reg-form.socials"></p>

    <p class="agent-details--profile-content" translate="reg-form.authorize-socials"></p>

  </div>

  <form class="agent-details--form" [formGroup]="service.form">
    
    <p class="section-sub-header" translate="reg-form.profession"></p>

    <mat-radio-group formControlName="type">
      <mat-radio-button [value]="typeData.value" required disableRipple *ngFor="let typeData of service.typeData;">{{ typeData.display | translate }}</mat-radio-button>
    </mat-radio-group>

    <p class="section-sub-header" translate="reg-form.personal-details"></p>

    <div class="agent-details--form-fields">
      <mat-form-field underlined appearance="outline">
        <mat-label>{{ 'reg-form.first-name' | translate }}</mat-label>
        <input matInput type="text" name="first-name" formControlName="firstName">
      </mat-form-field>
      <mat-form-field underlined appearance="outline">
        <mat-label>{{ 'reg-form.last-name' | translate }}</mat-label>
        <input matInput type="text" name="last-name" formControlName="lastName">
      </mat-form-field>
      <mat-form-field underlined appearance="outline">
        <mat-label>{{ 'reg-form.email' | translate }}</mat-label>
        <input matInput type="text" name="email" formControlName="email">
      </mat-form-field>
      <mat-form-field underlined appearance="outline" customMapAutocomplete emitOnly position="absolute-relative" [cmaTopOffset]="50" (locationChanged)="locationChanges($event)">
        <mat-label>{{ 'reg-form.location' | translate }}</mat-label>
        <input matInput type="text" name="location" autocomplete="off" formControlName="location">
      </mat-form-field>
    </div>

    <p class="section-sub-header" translate="reg-form.social-media"></p>

    <ng-container [ngSwitch]="type">
      <social-media-field [form]="service.form" *ngSwitchCase="'travelAgent'"></social-media-field>
      <social-media-link (socialClicked)="socialClicked.emit($event)" [form]="service.form" *ngSwitchCase="'ambassador'"></social-media-link>
    </ng-container>

  </form>

</div>
