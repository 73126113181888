import { Component, Input } from '@angular/core';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ActivityDetailsService } from './activity-details.service';
import { opacityFade } from '@hiptraveler/animations';
import { DialogType, SearchResultDialogControlService, animationDelay } from '../../common';

@Component({
  selector: 'activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
  viewProviders: [ ActivityDetailsService ],
  animations: [ opacityFade(animationDelay) ]
})
export class ActivityDetailsComponent {
  
  @Input() data?: ImageResultData;
  @Input() dialogType: DialogType;
  @Input() component: string;

  constructor(
    public service: ActivityDetailsService,
    public control: SearchResultDialogControlService
  ) { }

}
