import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup
  ) { }

  get formGroup(): FormGroup {
    return this.form.get('otherInfo') as FormGroup;
  }

}
