import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ProfileCardComponent } from './profile-card.component';
import * as UIComponent from './profile-card-ui';
import * as UIDirectives from './profile-card-directive';
import { ProfileCardPipes, ProfileCardExportPipes } from './profile-card-pipe';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  ProfileCardComponent,
  UIComponent.ProfileCardNameComponent,
  UIComponent.ProfileCardLocationComponent,
  UIComponent.ProfileCardActionButtonComponent
];

const DIRECTIVES = [
  UIDirectives.ProfileCardImageDirective,
  UIDirectives.ProfileCardHoverDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    ProfileCardPipes
  ],
  exports: [
    ProfileCardComponent,
    UIComponent.ProfileCardNameComponent,
    UIComponent.ProfileCardLocationComponent,
    UIComponent.ProfileCardActionButtonComponent,
    UIDirectives.ProfileCardImageDirective,
    UIDirectives.ProfileCardHoverDirective,
    ProfileCardExportPipes
  ]
})
export class ProfileCardModule { }
