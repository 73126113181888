import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';

import { EventStateModel } from '.';
import { EventAction } from '@hiptraveler/data-access/event';

export const EVENT_STATE_TOKEN = new StateToken<EventStateModel>('state_event');

export const eventStateDefaults: any = null;

@State<EventStateModel>({
  name: EVENT_STATE_TOKEN,
  defaults: eventStateDefaults
})

@Injectable()
export class EventState {

  @Action(EventAction.NavigateToExternalLinkEvent)
  navigateToExternalLinkEvent(_: StateContext<EventStateModel>) {}

  @Action(EventAction.ExecuteShareEvent)
  executeShareEvent(_: StateContext<EventStateModel>) {}

}
