import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';

import { CoverImageComponent } from './cover-image.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatRippleModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  LazyLoadImageModule,
  TranslateModule
];

const COMPONENTS = [
  CoverImageComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CoverImageComponent
  ]
})
export class CoverImageModule { }
