import * as API from '@hiptraveler/data-access/api';
import { SearchStateField, UpdateSearchResultLikePayload } from './search.model';

export class GetLocationDataByQuery {
  static readonly type = '[Search State] GetLocationDataByQuery';
  constructor(public query: API.SearchLocationDataActionQuery) { }
}

export class GetHotelsContent {
  static readonly type = '[Search State] GetHotelsContent';
  constructor(public data: API.BrandContentDto) { }
}

export class GetAdventuresContent {
  static readonly type = '[Search State] GetAdventuresContent';
  constructor(public data: API.BrandContentDto) { }
}

export class GetFoodsContent {
  static readonly type = '[Search State] GetFoodsContent';
  constructor(public data: API.BrandContentDto) { }
}

export class GetTravelAgentsContent {
  static readonly type = '[Search State] GetTravelAgentsContent';
  constructor(public data: API.BrandContentDto) { }
}

export class GetItinerariesContent {
  static readonly type = '[Search State] GetItinerariesContent';
  constructor(public data: API.BrandContentDto) { }
}

export class GetHotelsByQuery {
  static readonly type = '[Search State] GetHotelsByQuery';
  constructor(public query: API.SearchHotelsActionQuery) { }
}

export class GetAdventuresByQuery {
  static readonly type = '[Search State] GetAdventuresByQuery';
  constructor(public query: API.SearchAdventuresActionQuery) { }
}

export class GetFoodsByQuery {
  static readonly type = '[Search State] GetFoodsByQuery';
  constructor(public query: API.SearchFoodsActionQuery) { }
}

export class GetItinerariesByQuery {
  static readonly type = '[Search State] GetItinerariesByQuery';
  constructor(public query: API.SearchItinerariesActionQuery) { }
}

export class GetCommunitiesByQuery {
  static readonly type = '[Search State] GetCommunitiesByQuery';
  constructor(public query: API.SearchCommunitiesActionQuery) { }
}

export class GetHotelsTermByQuery {
  static readonly type = '[Search State] GetHotelsTermByQuery';
  constructor(public query: API.SearchHotelsTermByQuery) { }
}

export class GetAdventureTermByQuery {
  static readonly type = '[Search State] GetAdventureTermByQuery';
  constructor(public query: API.SearchAdventuresTermByQuery) { }
}

export class GetFoodsTermByQuery {
  static readonly type = '[Search State] GetFoodsTermByQuery';
  constructor(public query: API.SearchFoodsTermByQuery) { }
}

export class ToggleSearchResultLike {
  static readonly type = '[Search State] ToggleSearchResultLike';
  constructor(public data: API.ToggleLikeItineraryDto<API.SearchResultField>) { }
}
export class UpdateSearchResultLike {
  static readonly type = '[Search State] UpdateSearchResultLike';
  constructor(public data: UpdateSearchResultLikePayload) { }
}

export class ResetSearchState {
  static readonly type = '[Search State] ResetSearchState';
  constructor(public fields?: SearchStateField[]) { }
}
