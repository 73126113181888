import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { RequestCancellationService } from '@hiptraveler/common';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { FavoritesTabService } from './favorites-tab.service';
import { skeletons, DataAccessService } from '../../../common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'favorites-tab',
  templateUrl: './favorites-tab.component.html',
  viewProviders: [ FavoritesTabService, SearchResultDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ]
})
export class FavoritesTabComponent implements OnInit, OnDestroy {

  skeletons: number[] = skeletons;
  foodResults: ImageResultData[] = [];
  adventureResults: ImageResultData[] = [];
  hotelResults: HotelResultData[] = [];
  withViewContent: number = 0;

  constructor(
    private cdRef: ChangeDetectorRef,
    private requestCancellation: RequestCancellationService,
    public service: FavoritesTabService,
    public dataAccess: DataAccessService
  ) { }

  ngOnInit(): void {
    this.dataAccess.getProfileFavoriteResults((success: boolean) => {
      this.withViewContent = success ? 1 : 2;
    });
    this.observeChangeDetection();
  }
  
  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
    this.requestCancellation.cancelAllProfileSearchRequests();
    this.dataAccess.resetProfileStateByField([ 'imageResults', 'hotelResults', 'pagination' ]);
    this.foodResults = [];
    this.hotelResults = [];
    this.adventureResults = [];
  }

  private observeChangeDetection(): void {
    this.dataAccess.searchResults$.subscribe(([ imageResults, hotelResults ]) => {
      this.hotelResults = hotelResults || [];
      this.foodResults = imageResults?.filter(e => e.imgCategory.toLowerCase()?.includes('food')) || [];
      this.adventureResults = imageResults?.filter(e => !e.imgCategory.toLowerCase()?.includes('food')) || [];
      this.cdRef.detectChanges();
    });
  }

}
