<ng-container *ngIf="(service.tours$ | async)?.length">
  <h2 [@opacityFade] translate="itinerary.tours"></h2>
  
  <div class="checklist--items">
    <ng-container *ngFor="let data of service.tours$ | async;let i = index">
      <div class="checklist--item" [title]="data?.name" [@opacityFade] *ngIf="data?.name">

        <div class="checklist--item-indicator">
          <mat-icon mouseTransition [imageData]="data" [state$]="data.id | checklistBoxState" (activated)="service.toggleChecklistItem(tourChecklistBox, data)" (deactivated)="service.toggleChecklistItem(tourChecklistBox, data)" #tourChecklistBox>radio_button_unchecked</mat-icon>
          <mat-progress-spinner class="primary" mode="indeterminate" diameter="30" strokeWidth="2.5"></mat-progress-spinner>
        </div>

        <ng-container *ngIf="data?.imgCategory === 'Food and Drink';else adventureDialogUX">
          <p><span (click)="service.searchResultDialog.open(data, 'food')">{{ data?.name }}</span></p>
        </ng-container>
        <ng-template #adventureDialogUX>
          <p><span (click)="service.searchResultDialog.open(data, 'adventure')">{{ data?.name }}</span></p>
        </ng-template>

        <ng-container *ngIf="data?.imgCategory !== 'Food and Drink' && data?.imgSource">
          <ui-action-button size="medium" [externalLink]="data?.imgSource">{{ 'itinerary.book' | translate }}</ui-action-button>
        </ng-container>

        <button mat-icon-button [matMenuTriggerFor]="toursMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #toursMenu="matMenu">
          <button mat-menu-item (click)="service.openPrivateNote(data, 'image')">
            <span>{{ ((data.id | checklistBoxState: false | async) ? 'itinerary.update-your-reservation' : 'itinerary.add-a-reservation') | translate }}</span>
          </button>
        </mat-menu>

      </div>
    </ng-container>
  </div>
  
  <br>
</ng-container>

<ng-container *ngIf="(service.hotels$ | async)?.length">
  <h2 [@opacityFade] translate="itinerary.hotels"></h2>
  
  <div class="checklist--items">
    <ng-container *ngFor="let data of service.hotels$ | async;let i = index">
      <div class="checklist--item" [title]="data?.name" [@opacityFade] *ngIf="data?.name">

        <div class="checklist--item-indicator">
          <mat-icon mouseTransition [hotelData]="data" [state$]="data.id | checklistBoxState" (activated)="service.toggleChecklistItem(hotelChecklistBox, data)" (deactivated)="service.toggleChecklistItem(hotelChecklistBox, data)" #hotelChecklistBox>radio_button_unchecked</mat-icon>
          <mat-progress-spinner class="primary" mode="indeterminate" diameter="30" strokeWidth="2.5"></mat-progress-spinner>
        </div>

        <p><span (click)="service.searchResultDialog.open(data, 'hotel')">{{ data?.name }}</span></p>

        <ng-container *ngIf="data?.officialSite">
          <ui-action-button size="medium" [externalLink]="data?.officialSite">{{ 'itinerary.book' | translate }}</ui-action-button>
        </ng-container>

        <button mat-icon-button [matMenuTriggerFor]="hotelsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #hotelsMenu="matMenu">
          <button mat-menu-item (click)="service.openPrivateNote(data, 'hotel')">
            <span>{{ ((data.id | checklistBoxState: false | async) ? 'itinerary.update-your-reservation' : 'itinerary.add-a-reservation') | translate }}</span>
          </button>
        </mat-menu>

      </div>
    </ng-container>
  </div>
  
  <br>
</ng-container>
