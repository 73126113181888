import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { map, skip, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { InformationService } from './information.service';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  host: { class: 'content' },
  viewProviders: [ InformationService ],
  animations: [ opacityFade(animationDelay) ]
})
export class InformationComponent implements OnInit {
  
  @Input() data?: ImageResultData;

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: InformationService
  ) { }

  ngOnInit(): void {
    this.service.imageInformation$.pipe(
      skip(1),
      map((response: ImageResultData) => {
        const visibility = 
        !!response?.tourData?.addInfo?.length
        || !!response?.tourData?.highlights?.length
        || !!response?.tourData?.inclusions?.length
        || !!response?.tourData?.exclusions?.length;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
  }

}
