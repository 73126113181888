import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class ActivityDateListService {

  @Select(ItineraryState.actDateArr) actDate$: Observable<ActivityDateData[] | null>

}
