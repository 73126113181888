import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject, fromEvent, takeUntil, tap } from 'rxjs';

import { HotelResultData, ImageResultData, SearchData } from '@hiptraveler/data-access/api';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';

@Directive({
  selector: '[featureCardHover]'
})
export class FeatureCardHoverDirective implements OnDestroy, AfterViewInit {

  @Input('featureCardHover') data: SearchData;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private leafletControl: LeafletMapControlStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    fromEvent(this.eventElements, 'click').pipe(
      tap((event: Event) => event.stopPropagation()),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  @HostListener('mouseenter') mouseEnter(): void {
    const data = this.data as ImageResultData & HotelResultData;
    this.leafletControl.activeSearchResultData$$.next({
      id: data.id || 'unknown',
      title: data.name || '',
      image: data.imgUrl || '',
      address: data.address,
      location: {
        ...data.loc,
        coordinates: [ data?.loc?.coordinates?.[1] || 0, data?.loc?.coordinates?.[0] || 0 ]
      },
      locationName: data.location || `${data.city} ${data.countryCd}`
    });
  }

  private get eventElements(): HTMLDivElement[] {
    return [
      this.elementByClass('feature-card--favorite-button'),
      this.elementByClass('itinerary-action-button'),
      this.elementByClass('itinerary-action-bubble')
    ].filter(e => !!e) as HTMLDivElement[];
  }

  private elementByClass(elementClass: string): HTMLDivElement {
    return this.element.nativeElement.getElementsByClassName(elementClass).item(0) as HTMLDivElement;
  }

}
