import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { AdventuresDataService } from '@hiptraveler/modules/search-page-root';
import { AppListenerService } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  viewProviders: [ AdventuresDataService, SearchResultDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ],
})
export class ActivitiesComponent implements OnInit {

  skeletons: number[] = Array(2).fill(0);

  constructor(
    public service: AdventuresDataService,
    public appListenerService: AppListenerService
  ) { }

  ngOnInit(): void {
    this.service.initializeDataAccess();
  }

  get horizontalCard$(): Observable<boolean> {
    return this.appListenerService.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth > 750)
    )
  }

}
