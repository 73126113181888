<div class="search-banner-actions">
  <ng-container *ngIf="service.locationData$ | async as locationData">

    <h1>{{ 'search-page.experience' | translate }} {{ locationData.city }}</h1>

    <div class="banner--selection-container">
      <div class="banner--selection">
        <div class="banner--selection-left">
          <p [innerHTML]="locationData?.coverImg?.attributionTxt"></p>
        </div>
        <banner-selection-items [locationData]="locationData"></banner-selection-items>
        <div class="banner--selection-right"></div>
      </div>
    </div>

    <div class="banner--image">
      <img [lazyLoad]="locationData?.coverImg?.imgUrl!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="locationData?.formattedAddr">
      <div class="banner--image-overlay"></div>
      <p [innerHTML]="locationData?.coverImg?.attributionTxt"></p>
    </div>
    
  </ng-container>
</div>
