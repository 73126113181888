import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SocialMediaType } from '../../common';

@Component({
  selector: 'social-media-link',
  templateUrl: './social-media-link.component.html'
})
export class SocialMediaLinkComponent {

  @Output() socialClicked = new EventEmitter<SocialMediaType>();

  @Input() form: FormGroup;

}
