import { Component, ViewEncapsulation } from '@angular/core';

import { ItineraryActionService } from './../itinerary-action.service';
import { ItineraryAction } from '../itinerary-action';
import { ItineraryActionPanelService } from '../itinerary-action-panel/itinerary-action-panel.service';
import { ItineraryActionPanelDataAccessService } from '../itinerary-action-panel/itinerary-action-panel-data-access.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'itinerary-action-button',
  template: `

    <div matRipple class="itinerary-action-button-content mat-elevation-z2" matRipple [class.active]="active" (click)="toggleItineraryAction(actionButtonContent)" #actionButtonContent>
      <mat-icon>{{ active ? 'done' : 'add' }}</mat-icon>
      <span>{{ (active ? 'itinerary-action.added' : 'itinerary-action.add-trip') | translate }}</span>
      <div class="itinerary-action-button-content--overlay-origin" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="visibility || panelVisibility">
      <ng-container *ngIf="actionPanel !== ''">
        <ng-container *ngTemplateOutlet="itineraryAction"></ng-container>
      </ng-container>
    </ng-template>
    
    <ng-container *ngIf="actionPanel === '' && panelVisibility">
      <div class="action-panel-overlay" [@opacityFade] (click)="closeItineraryAction()" #actionPanelOverlay></div>
      <ng-container *ngTemplateOutlet="itineraryAction" [@opacityFade]></ng-container>
    </ng-container>

    <ng-template #itineraryAction>
      <ng-content select="itinerary-action"></ng-content>
    </ng-template>
`,
  styleUrls: ['./itinerary-action-button.component.scss'],
  host: { class: 'itinerary-action-button' },
  providers: [ ItineraryActionService, ItineraryActionPanelService, ItineraryActionPanelDataAccessService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class ItineraryActionButtonComponent extends ItineraryAction { }
