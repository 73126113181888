import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { flattenDeep } from 'lodash';

/**
 * Pipe for formatting story text into paragraphs, handling empty paragraphs.
 */
@Pipe({
  name: 'storyTextDisplay'
})
export class StoryTextDisplayPipe implements PipeTransform {

  /**
   * Transforms the input string by formatting it into paragraphs, removing empty paragraphs.
   *
   * @param value The input string to be formatted.
   * @returns The formatted string with paragraphs.
   */
  transform(value: string): string {
    const newText = value.split('\n\n').map(e => e.split('\n'));

    let transform = flattenDeep(newText)
      .map(e => `<p>${e}</p>`)
      .join('')
      .replace(/ class=\"\"/g, '')
      .replace(/<p><br><\/p>/g, '')
      .replace(/<p><\/p>/g, '');

    return transform;
  }

}

@NgModule({
  declarations: [ StoryTextDisplayPipe ],
  exports:      [ StoryTextDisplayPipe ]
})
export class StoryTextDisplayPipeModule { }
