import { Inject, Injectable, PLATFORM_ID, Provider } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, OperatorFunction, catchError } from 'rxjs';

import { ApiService } from '@hiptraveler/common';

function catchServerError(request: HttpRequest<unknown>): OperatorFunction<any, any> {
  return catchError((response: HttpErrorResponse) => {
    if (!request.url.includes('/assets/i18n')) {
      throw response;
    }
    return EMPTY;
  })
}

@Injectable()
export class _ApiInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private apiService: ApiService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (isPlatformServer(this.platformId) && request.url.includes('./assets/i18n/')) {
      return next.handle(request).pipe( catchServerError(request) );
    }

    if (isPlatformBrowser(this.platformId) && this.apiService.hosted) {
      return next.handle(request.clone({ url: this.apiService.parseUrl(request.url) }));
    }

    if (isPlatformServer(this.platformId) && this.apiService.validateUrl(request.url)) {
      return next.handle(request.clone({ url: this.apiService.parseUrl(request.url) }));
    }

    return next.handle(request);
  }

}

export const ApiInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _ApiInterceptor,
  multi: true,
}
