import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';

import { LeafletMap, LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';

@Directive({
  selector: '[leafletMap]',
  host: { class: 'ht-custom-leaflet-map' }
})
export class SearchResultDialogMapDirective extends LeafletMap implements OnInit {

  @Input() data?: ImageResultData | HotelResultData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    router: Router,
    elementRef: ElementRef<HTMLElement>,
    leafletControl: LeafletMapControlStateService
  ) {
    super(router, elementRef, leafletControl);
  }
  
  ngOnInit(): void {

    if (isPlatformServer(this.platformId)) return;
   
    this.setMap();

    const location: any = [ +this.data?.latitude! || 0, +this.data?.longitude! || 0 ];
    const data: any = {
      id: this.data?.id || 'unknown',
      title: this.data?.name || '',
      image: this.data?.imgUrl || '',
      address: this.data?.address,
      location: {
        ...this.data?.loc,
        coordinates: location
      },
      locationName: this.data?.location
    }

    this.setMapView([ location[0]-0.0055, location[1] ]);
    this.setMarker({ location, data, icon: this.markerIcon.adventureMarkerHighlightIcon })
  }

}
