<div class="specialization">
  <div activeSelection class="specialization--link-id" id="your-specialties"></div>

  <p class="section-title" translate="reg-form.specialize-exp"></p>

  <carousel-button left (click)="navigateSlider('left')"></carousel-button>
  <carousel-button right (click)="navigateSlider('right')"></carousel-button>
  <div class="specialization--slider">
    <div id="specialization--slider-item-start"></div>
    <div class="specialization--slider-item" *ngFor="let experience of service.experiences$ | async;let i = index">
      <div class="specialization--slider-item-content" (click)="toggleSelection(i)">
        <div class="specialization--slider-item-content-highlight" [class.active]="selection.includes(i)" matRipple>
          <mat-icon [svgIcon]="selection.includes(i) ? 'registration-form-specialization.check-svg' : 'registration-form-specialization.add-svg'"></mat-icon>
        </div>
      </div>
      <p translate>{{ (experience?.tname || experience?.name || '') | translate }}</p>
    </div>
    <div id="specialization--slider-item-end"></div>
  </div>

</div>
