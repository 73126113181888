import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-activity-dialog',
  templateUrl: './new-activity-dialog.component.html',
  styleUrls: ['./new-activity-dialog.component.scss']
})
export class NewActivityDialogComponent {

  constructor(
    public ref: MatDialogRef<NewActivityDialogComponent>
  ) { }

}
