import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';

import { ExternalLinkDirectiveModule } from '@hiptraveler/common';

import { YelpReviewsComponent } from './yelp-reviews.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ExternalLinkDirectiveModule
];

const COMPONENTS = [
  YelpReviewsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    YelpReviewsComponent
  ]
})
export class YelpReviewsModule { }
