import { Component, Inject, Input } from '@angular/core';

import { TripActivityDataMap } from '@hiptraveler/data-access/api';
import { WINDOW } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'trip-advisor',
  templateUrl: './trip-advisor.component.html',
  styleUrls: ['./trip-advisor.component.scss'],
  animations: [ opacityFade(animationDelay) ]
})
export class TripAdvisorComponent {

  @Input() data?: TripActivityDataMap | null;

  constructor(
    @Inject(WINDOW) private window: any
  ) { }

  navigateToExternal(url: string): void {
    url && this.window.open(url, '_blank');
  }

}
