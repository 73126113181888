import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CoverImageComponent } from './cover-image.component';

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  CoverImageComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CoverImageComponent
  ]
})
export class CoverImageModule { }
