import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ComposePrivateNoteDialogService } from './compose-private-note-dialog.service';
import { Reservation } from './compose-private-note-dialog-action.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-compose-private-note-dialog',
  templateUrl: './compose-private-note-dialog.component.html',
  styleUrls: ['./compose-private-note-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ ComposePrivateNoteDialogService ],
  animations: [ opacityFade() ]
})
export class ComposePrivateNoteDialogComponent implements OnInit {

  minDate: string = new Date().toISOString();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Reservation,
    public ref: MatDialogRef<ComposePrivateNoteDialogComponent>,
    public service: ComposePrivateNoteDialogService
  ) { }

  ngOnInit(): void {
    this.service.authenticationObserver();
    this.service.setFormDates(this.data);
    this.service.form.patchValue(this.data);
  }

}
