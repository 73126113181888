<div class="skyscanner-view">

  <img [src]="travelImage$ | async" [alt]="activeWidget">

  <div class="skyscanner-view--selection">
    <p (click)="activeWidget = 'FlightSearchWidget'" [class.active]="activeWidget === 'FlightSearchWidget'" translate="skyscanner.flights"></p>
    <p>|</p>
    <p (click)="activeWidget = 'CarHireWidget'" [class.active]="activeWidget === 'CarHireWidget'" translate="skyscanner.rental-cars"></p>
  </div>

  <div class="skyscanner-view--content" skyscannerWidget="FlightSearchWidget" [ngClass]="{ active: activeWidget === 'FlightSearchWidget' }"></div>
  <div class="skyscanner-view--content" skyscannerWidget="CarHireWidget" [ngClass]="{ active: activeWidget === 'CarHireWidget' }"></div>

</div>
