import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reviewText'
})
export class ReviewTextPipe implements PipeTransform {

  transform = (localeRating?: string): string => {
    const rating = +(localeRating?.replace(',', '') || 0);
    return rating ? `${rating} review${rating > 1 ? 's' : ''}` : '';
  };

}
