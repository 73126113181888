import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { ForgotPasswordComponent } from './forgot-password.component';
import { VerificationComponent } from './verification/verification.component';
import { CreatePasswordComponent } from './create-password/create-password.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
];

const COMPONENTS = [
  ForgotPasswordComponent,
  VerificationComponent,
  CreatePasswordComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ForgotPasswordComponent,
    VerificationComponent,
    CreatePasswordComponent
  ]
})
export class ForgotPasswordModule { }
