import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { CertificationsLicensesService } from './certifications-licenses.service';
import { DialogData, NewOperatorDialogActionService } from './new-operator-dialog';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'certifications-licenses',
  templateUrl: './certifications-licenses.component.html',
  styleUrls: ['./certifications-licenses.component.scss'],
  host: { class: 'ta-register-registration-form-inline' },
  viewProviders: [ CertificationsLicensesService, NewOperatorDialogActionService ],
  animations: [ opacityFade() ]
})
export class CertificationsLicensesComponent implements OnInit {

  taOptions: string[] = [ 'CTA', 'CTC', 'CTIE', 'VTA', 'CCC', 'ACC', 'MCC', 'IATA' ];
  toOptions: string[] = [ 'USTOA', 'ATTA', 'ACTA' ];
  operatorOptions: string[] = this.taOptions.concat(this.toOptions);
  operatorSelection: string = '';
  tourSelection: any = {};
  iataId: string = '';

  constructor(
    private service: CertificationsLicensesService,
    public newOPeratorDialog: NewOperatorDialogActionService
  ) { }

  ngOnInit(): void {
    this.service.initializeFormObserver();
    this.service.formValue$.subscribe((value: any) => {
      this.operatorSelection = value.certType;
    });
  }

  selectOption(option: string): void {
    if (option !== 'IATA') (this.iataId = '');
    this.tourSelection = {};
    this.operatorSelection = option;
    this.service.updateCertificate({ certId: '', certType: option });
  }

  openNewOperatorDialog(): void {
    const ref = this.newOPeratorDialog.open(this.tourSelection) as MatDialogRef<unknown, DialogData>;
    ref.beforeClosed().pipe(filter(Boolean)).subscribe((value: DialogData) => {
      this.operatorSelection = '';
      this.tourSelection = value;
      this.service.updateCertificate({ certId: value.id, certType: value.certificate });
    });
  }

  certIdChanged(): void {
    this.service.updateCertificate({ certId: this.iataId, certType: this.operatorSelection });
  }

}
