import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { EFAStateServiceService } from '../../../shared';
import { InputControlService } from '../input-control.service';

@Component({
  selector: 'summary-tags',
  templateUrl: './summary-tags.component.html',
  styleUrls: ['./summary-tags.component.scss'],
  host: { class: 'input-control-summary-tags' },
  encapsulation: ViewEncapsulation.None
})
export class SummaryTagsComponent implements OnInit, OnDestroy {

  constructor(
    public stateService: EFAStateServiceService,
    public hostService: InputControlService
  ) { }

  ngOnInit(): void {
    this.stateService.scrolling$$.next(true);
  }
  
  ngOnDestroy(): void {
    this.stateService.scrolling$$.next(false);
  }

}
