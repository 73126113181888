
<header (active)="activeSelection = $event"></header>

<div [class]="'connect--social ' + activeSelection">
  <ng-container [ngSwitch]="activeSelection">
  
    <ng-container *ngSwitchCase="'facebook'">
      <div facebookIframe></div>
    </ng-container>
  
    <ng-container *ngSwitchCase="'instagram'">
      <div instagramIframe></div>
    </ng-container>
  
    <ng-container *ngSwitchCase="'youtube'">
      <div youtubeIframe></div>
    </ng-container>
  
    <ng-container *ngSwitchCase="'twitter'">
      <div twitterIframe></div>
    </ng-container>
  
  </ng-container>
</div>
