import { Component, AfterViewInit, ViewEncapsulation, ElementRef, OnDestroy, Input } from '@angular/core';
import { isWidget } from '@hiptraveler/common';
import { Store } from '@ngxs/store';
import { Subject, tap } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { RequestPendingSkeletonService } from './request-pending-skeleton.service';

@Component({
  selector: 'request-pending-skeleton',
  templateUrl: './request-pending-skeleton.component.html',
  styleUrls: ['./request-pending-skeleton.component.scss'],
  host: { class: 'experience-finder-request-pending-skeleton' },
  viewProviders: [ RequestPendingSkeletonService ],
  encapsulation: ViewEncapsulation.None
})
export class RequestPendingSkeletonComponent implements AfterViewInit, OnDestroy {

  @Input() mobile: string;
  
  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store,
    private service: RequestPendingSkeletonService
  ) { }

  ngAfterViewInit(): void {
    this.hostElementVisibility();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  hostElementVisibility(): void {

    const hostElement = this.element.nativeElement;

    if (isWidget()) {

      this.service.toggleChildrenVisibility(hostElement.parentElement!, false);
      hostElement.style.display = 'block';
  
      this.store.select(BrandState.brandCampaign).pipe(
        tap(brandCampaign => {
          hostElement.style.display = brandCampaign ? 'none' : 'block';
          this.service.toggleChildrenVisibility(hostElement.parentElement!, !brandCampaign);
        })
      ).subscribe();
    } else {
      hostElement.style.display = 'none';
      this.service.toggleChildrenVisibility(hostElement.parentElement!, false);
    }
  }

}
