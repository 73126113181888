import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  constructor(
    private stateService: ExperienceFinderStateService
  ) { }

  transform(value: string): Observable<boolean> {
    return this.stateService.selectedFilters$$.asObservable().pipe(
      map((values: string[]) => values.includes(value))
    );
  }

}
