import { ElementRef, Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { filter } from 'rxjs';

import { defaultMetaTagData, Metadata } from '@hiptraveler/features/meta-tag';
import { AppListenerService, getWindowRef, SUPPORTED_LANG } from '@hiptraveler/common';
import { pageIdByUrl, searchAndDefaultPageCallback, validateItineraryAndBlogViewPage } from './home-heading-observer.helper';

@Injectable()
export class HomeHeadingObserverService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private elementRef: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private router: Router,
    private appListener: AppListenerService
  ) { }

  observe(): void {
    if (!isPlatformServer(this.platformId) && !isPlatformBrowser(this.platformId)) return;

    this.searchAndDefaultPage(this.appendHeadingElement.bind(this));
    this.itineraryAndBlogViewPage(this.appendHeadingElement.bind(this));
  }

  searchAndDefaultPage(callback: (heading: string) => void): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(() => {

      const path: string = getWindowRef()?.location?.pathname || '';

      if (SUPPORTED_LANG.some(e => path.slice(1) === e)) {
        return callback('Collect and share your travel experiences. Plan your next adventure. Become a part of the HipTraveler community.');
      };

      searchAndDefaultPageCallback(callback.bind(this));
    });
  }

  itineraryAndBlogViewPage(callback: (heading: string) => void): void {
    this.appListener.metaData$.pipe(
      filter(e => !e.title.includes(defaultMetaTagData.title)),
      filter(validateItineraryAndBlogViewPage.bind(this))
    ).subscribe((_: Metadata) => callback(`${_.title} ${pageIdByUrl(_.url)}`));
  }

  private appendHeadingElement(heading: string): void {
    if (!this.element.parentElement) return;

    const body = this.element.parentElement.parentElement;
    const appShell = this.element.parentElement.parentElement?.getElementsByClassName('app-shell').item(0);
    const appHeading = body?.getElementsByClassName('app-heading').item(0);

    if (appHeading) {
      this.renderer.removeChild(body, appHeading);
    }

    const serverSideContentElement = this.renderer.createElement('h1');
    const text = this.renderer.createText(heading);
    this.renderer.addClass(serverSideContentElement, 'app-heading');
    this.renderer.setStyle(serverSideContentElement, 'opacity', 0);
    this.renderer.setStyle(serverSideContentElement, 'position', 'fixed');
    this.renderer.appendChild(serverSideContentElement, text);
    this.renderer.insertBefore(body, serverSideContentElement, appShell);
  }

  get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

}
