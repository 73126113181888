import { Component } from '@angular/core';

@Component({
  selector: 'or-divider',
  template: `
    <div class="auth-dialog--or">
      <div class="auth-dialog--or-text"><span>or</span></div>
      <div class="auth-dialog--or-divider"></div>
    </div>
  `,
  styleUrls: ['./or-divider.component.scss'],
  host: {
    class: 'or-divider'
  }
})
export class OrDividerComponent {}
