import { Component, ElementRef, OnInit } from '@angular/core';
import { skip, take, tap } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { RelatedToursService } from './related-tours.service';
import { SearchResultDialogActionService } from '../../search-result-dialog-action.service';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'related-tours',
  templateUrl: './related-tours.component.html',
  styleUrls: ['./related-tours.component.scss'],
  viewProviders: [ RelatedToursService, SearchResultDialogActionService ],
  host: { class: 'content' },
  animations: [ opacityFade(animationDelay) ]
})
export class RelatedToursComponent implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: RelatedToursService,
    public searchResultDialog: SearchResultDialogActionService
  ) { }

  ngOnInit(): void {
    this.service.resultRelatedTours$.pipe(
      skip(1),
      tap((relatedTours: ImageResultData[]) => {
        const visibility = !!relatedTours && !!relatedTours?.length;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe()
  }

  openTour(result: ImageResultData): void {
    const { imageInformation, resultRelatedTours } = { ...this.service.cachedData };
    const dialogRef: any = this.searchResultDialog.open(result, 'adventure', result.id);
  
    if (dialogRef?.afterClosed) {
      dialogRef.afterClosed().subscribe(() => {
        this.service.store.dispatch(new ItineraryAction.GetCachedDialogResultByData(imageInformation, resultRelatedTours));
      });
    }
  }

}
