import { Component, Input } from '@angular/core';

import { ButtonTheme } from '@hiptraveler/common';

@Component({
  selector: 'ui-action-button',
  template: `
    <div matRipple 
      [class]="theme + ' ' + size + ' mat-elevation-z' + elevation"
      [matRippleDisabled]="rippleDisabled"
      [ngClass]="{
        disabled, pending,
        icon: icon || iconRight || iconLeft,
        rounded: rounded === '',
        hoverElevation: hoverElevation === ''
      }">

      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="20" [strokeWidth]="3"
        *ngIf="pending;else loaded">
      </mat-progress-spinner>

      <ng-template #loaded>
        <button [type]="type">
          <mat-icon *ngIf="icon || iconLeft">{{ icon || iconLeft }}</mat-icon>
          <ng-content></ng-content>
          <mat-icon *ngIf="iconRight">{{ iconRight }}</mat-icon>
        </button>
      </ng-template>

    </div>
  `,
  styleUrls: ['./action-button.component.scss'],
  host: { class: 'ui-action-button' }
})
export class ActionButtonComponent {

  @Input() type: string = 'button';
  @Input() icon: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() size: 'default' | 'medium' | 'small' = 'default';
  @Input() theme: ButtonTheme = 'primary';
  @Input() disabled: boolean;
  @Input() pending: boolean;
  @Input() rounded: string;
  @Input() rippleDisabled: boolean;
  @Input() elevation: string = '0';
  @Input() hoverElevation: string;

}
