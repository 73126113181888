import { Directive } from '@angular/core';

@Directive({
  selector: '[itineraryImage]',
  host: { class: 'itinerary-image' }
})
export class ItineraryFeatureCardImageDirective {}

@Directive({
  selector: '[itineraryReviewImage]',
  host: { class: 'itinerary-review-image' }
})
export class ItineraryFeatureCardReviewImageDirective {}

import { FeatureCardDirective } from './feature-card.directive';

export const FeatureCardExportDirectives = [
  ItineraryFeatureCardImageDirective,
  ItineraryFeatureCardReviewImageDirective,
  FeatureCardDirective
];

export const FeatureCardDirectives = [
  FeatureCardExportDirectives
];

