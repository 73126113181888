
<p style="font-size: 34px;font-weight: bold;" translate="use-policy.1"></p>

<br>

<p translate="use-policy.2"></p>
<p translate="use-policy.3"></p>
<p translate="use-policy.4"></p>
<p translate="use-policy.5"></p>

<br>

<p style="font-size: 34px;font-weight: bold;" translate="use-policy.6"></p>

<br>

<p translate="use-policy.7"></p>

<p [innerHTML]="'use-policy.8' | translate"></p>

<p translate="use-policy.9"></p>

<ol>
  <li translate="use-policy.10"></li>
  <li translate="use-policy.11"></li>
  <li translate="use-policy.12"></li>
  <li>
    {{ 'use-policy.13' | translate }}
    <ul>
      <li translate="use-policy.14"></li>
      <li translate="use-policy.15"></li>
    </ul>
  </li>
  <li translate="use-policy.16"></li>
</ol>

<p [innerHTML]="'use-policy.17' | translate"></p>

<p translate="use-policy.18"></p>

<p [innerHTML]="'use-policy.19' | translate"></p>

<br>

<p style="text-align: center;margin-bottom: 32px;" [innerHTML]="'use-policy.20' | translate | translateParam: currentYear"></p>
