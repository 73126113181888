import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './header.component';
import { SocialMediaButtonActionDirective } from './social-media-button-action.directive';
import { FollowNamePipe } from './follow-name.pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  HeaderComponent,
];

const DIRECTIVES = [
  SocialMediaButtonActionDirective
];

const PIPES = [
  FollowNamePipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
