import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, firstValueFrom, map, switchMap, takeUntil, tap } from 'rxjs';

import { ProfileApiService } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';

@Injectable()
export class DontSellInfoService {

  @Select(UserState.authenticated) authenticated$: Observable<boolean>;

  processing: boolean;
  subscription$ = new Subject<void>();

  form: FormGroup = this.fb.group({
    dnsmpi: [ false ]
  });

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private profileApi: ProfileApiService
  ) { }

  get notAuthenticated$(): Observable<boolean> {
    return this.store.select(AuthState.authenticated).pipe(
      switchMap((authenticated: boolean) => this.actionPending$.pipe(
        map((actionPending: boolean) => !authenticated && !actionPending)
      ))
    );
  }

  get actionPending$(): Observable<boolean> {
    return this.store.select(AuthState.authenticated).pipe(
      switchMap((auth: boolean) => this.store.select(UserState.authenticated).pipe(
        map((user: boolean) => auth && !user)
      ))
    );
  }

  authenticationObserver(): void {
    this.store.select(UserState.state).pipe(
      tap((profile: UserStateModel | null) => this.form.patchValue({ dnsmpi: !!profile?.dnsmpi })),
      takeUntil(this.subscription$),
    ).subscribe();
  }

  async savePreferences(): Promise<void> {

    if (this.processing) return;
    this.processing = true;

    try {
      await firstValueFrom(this.profileApi.updateProfileSettings({ dnsmpi: this.form.value.dnsmpi }));
    } finally {
      this.processing = false;
    }
  }

}
