import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class MeetingPointService {

  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;

}
