import { ScreenValue } from "../../shared";

export function getPlaceholderByScreen(screen: ScreenValue): string {
  if (screen === 'screen3') {
    return 'Describe your travel style here...';
  }
  if (screen === 'screen4') {
    return 'Favorite activities?';
  }
  if (screen === 'screen5') {
    return 'Choose or let us pick the best for you...';
  }
  if (screen === 'screen6') {
    return `If you don't know the dates, enter the # of days here...`;
  }
  if (screen === 'screen7') {
    return 'Enter the number of travelers and their age...';
  }
  if (screen === 'screen8') {
    return 'You can delete tags above, add tags here, and then send it!';
  }
  return 'Type your request here...';
}
