import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TripViewRootRoutingModule } from './trip-view-page-root-routing.module';

import { TripViewPageRootComponent } from './trip-view-page-root.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  TripViewRootRoutingModule
];

const COMPONENTS = [
  TripViewPageRootComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class TripViewPageRootModule { }
