import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { ForgotPasswordService } from './forgot-password.service';
import { RequestCancellationService } from '@hiptraveler/common';

@Component({
  selector: 'auth-dialog-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  viewProviders: [ ForgotPasswordService ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Output() allSignInOptionsClicked = new EventEmitter();
  @Output() verifyOtpOpened = new EventEmitter();

  constructor(
    public service: ForgotPasswordService,
    private requestCancellation: RequestCancellationService
  ) { }
  
  ngOnInit(): void {
    this.service.initializeForm();
    this.service.successListener$$.subscribe(() => this.verifyOtpOpened.emit());
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
  }

  controlError(control: string): boolean {
    if (this.requiredControl(control)) return false;
    return this.service.form.get(control)?.errors !== null;
  }

  requiredControl(control: string): boolean {
    const errors = this.service.form.get(control)?.errors;
    return errors?.hasOwnProperty('required') && errors?.['required'];
  }

}
