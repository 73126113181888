<form class="cover-image" [formGroup]="form">

  <div class="cover-image--image-container" matRipple (click)="service.addCoverImage(dialog, form)">
    <ng-container *ngIf="coverImage;else defaultUI">
      <img [lazyLoad]="coverImage" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Cover image"> 
    </ng-container>
    <ng-template #defaultUI>
      <p translate="custom-activity-dialog.section.text.add-cover-image"></p>
      <p translate="custom-activity-dialog.section.text.image-requirements"></p>
      <mat-icon>add</mat-icon>
    </ng-template>
  </div>

  <mat-form-field formGroupName="otherInfo">
    <mat-label translate="custom-activity-dialog.section.form.image-attribution"></mat-label>
    <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-attribution' | translate" type="text" formControlName="licence" name="licence">
  </mat-form-field>

</form>
