import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PromptDialogStateService } from './prompt-dialog-state.service';
import { PromptDialogData } from './prompt-dialog.model';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  animations: [ opacityFade() ]
})
export class PromptDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogData,
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    public stateService: PromptDialogStateService
  ) { }

  ngOnInit(): void {
    this.stateService.setProgressToDefault();
  }

}
