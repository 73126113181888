import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ComposeItineraryModule } from './../compose-itinerary-form/compose-itinerary.module';
import { DispatchObserverDirectiveModule } from '../common';

import { ModifyItineraryComponent } from './modify-itinerary.component';
import { NavbarActionDirective } from './navbar-action.directive';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ComposeItineraryModule,
  DispatchObserverDirectiveModule
];

const COMPONENTS = [
  ModifyItineraryComponent
];

const DIRECTIVES = [
  NavbarActionDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: ModifyItineraryComponent
    }])
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
})
export class ModifyItineraryModule { }
