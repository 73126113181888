import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, map, takeUntil, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { BlogDataAccessService, preventRemoveKey, ViewService } from '@hiptraveler/features/itinerary';
import { AppListenerService, currentLang, getWindowRef, NavbarControlStateService, ScrollListenerService } from '@hiptraveler/common';
import { CommonService } from './common.service';

@Injectable()
export class TravelStoryService extends ViewService implements OnDestroy {

  @Select(ItineraryState.actDate) actDate$: Observable<unknown>;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private appListener: AppListenerService,
    private dataAccess: BlogDataAccessService,
    private common: CommonService,
    navbarControl: NavbarControlStateService,
    scrollListener: ScrollListenerService
  ) {
    super(navbarControl, scrollListener);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.common.destroyComponentState();
  }

  get basicInfo$(): Observable<BasicInfoData> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map((basicInfo) => {
        if (basicInfo?.content) {
          basicInfo.content = basicInfo?.content?.replace(/<a href="/g, '<a target="_blank" href="');
        }
        return basicInfo as any;
      })
    )
  }

  get hasItinerary$(): Observable<boolean> {
    return this.basicInfo$.pipe(
      map(value => !!value?.hasItinerary),
      takeUntil(this.dataAccess.subscription$)
    );
  }

  getPageData(): Observable<Params> {
    return this.route.params.pipe(
      filter(() => {
        return !this.appListener.previousUrl$$.value.includes(`/${currentLang()}/compose`)
      }),
      tap(async (param) => {
        getWindowRef()[preventRemoveKey] = undefined;
        await this.common.resetState()
        this.dataAccess.getBlogData(this.ngOnDestroy.bind(this));
        console.log('Param →', param['blog']);
      })
    );
  }

}
