import { Directive, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import * as Feature from '@hiptraveler/features/meta-tag';
import { AppListenerService, ENVIRONMENT, Environment } from '@hiptraveler/common';

@Directive({
  selector: '[appMetatag]',
  providers: [ Feature.Providers ]
})
export class MetaTagDirective extends Feature.MetaTag implements OnInit, OnDestroy {

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(ENVIRONMENT) environment: Environment,
    private searchAndDefaultPage: Feature.SearchAndDefaultPageService,
    private itineraryAndBlogPage: Feature.ItineraryAndBlogPageService,
    private metaTagService: Feature.MetaTagService,
    meta: Meta,
    titleService: Title,
    appListener: AppListenerService
  ) {
    super(platformId, environment, meta, titleService, appListener);
  }

  ngOnInit(): void {
    this.searchAndDefaultPage.observe();
    this.itineraryAndBlogPage.observe();
    this.metaTagService.metaData$.subscribe(this.setTags.bind(this));
  }

  ngOnDestroy(): void {
    this.metaTagService.subscription$.next();
  }

}
