export const calendarPrompts = [
  `
    The user will input travel dates in the format 'YYYY-MM-DD to YYYY-MM-DD'. Extract the start date and end date from their input. If they enter '2024-11-10 to 2024-11-15', output should be:
    Start Date: 2024-11-10
    End Date: 2024-11-15
  `,
  `
    If the user enters a duration like '7 days' without specific dates, assume today's date is YYYY-MM-DD and calculate the start and end dates. For example, if today is '2024-11-08' and the user types '7 days', output should be:
    Start Date: 2024-11-08
    End Date: 2024-11-15
  `,
  `
    If the user inputs a general time frame like 'next week' or 'in two weeks', interpret it as a date range starting from the following Monday. For example, if today is '2024-11-08' and the user types 'next week', the output should be:
    Start Date: 2024-11-11
    End Date: 2024-11-17
  `,
  `
    The user may input dates in various formats:
    - If they enter a specific date range like 'YYYY-MM-DD to YYYY-MM-DD', extract those dates directly.
    - If they enter a number of days, like '10 days', calculate the end date starting from today's date.
    - If they use general terms like 'next month' or 'in two weeks', interpret these and calculate the start and end dates accordingly.
    Output in the following format:
    Start Date: YYYY-MM-DD
    End Date: YYYY-MM-DD
  `,
  `
    Response in this format: { start: "YYYY-MM-DD", end: "YYYY-MM-DD" }
    Make sure to return this screen_6_calendar_data as a stringified JSON
    ex. "{ "start": "2024-11-08", "end": "2024-11-15" }"
  `
];


/*

  Supported user inputs

  // Specific Date Range Inputs

  2024-11-10 to 2024-11-15
  2024-12-01 to 2024-12-10
  2024-11-20 to 2024-11-25

  // Duration in Days Inputs

  5 days
  10 days
  15 days from today
  3 days

  // General Time Frame Inputs

  next week
  in two weeks
  next month
  in three days
  starting from next Monday for a week
  this weekend
  mid-January
  end of the year

  // Flexible or Mixed Inputs

  I want to travel for 7 days starting January 8
  Any dates between December 15 and December 25
  From the start of January to mid-January
  Sometime next week for 5 days
  In a month for 10 days
  Starting January 1 for two weeks

*/