import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, firstValueFrom, takeUntil } from 'rxjs';

import { BasicInfoData, HotelResultData, ImageResultData, ItineraryData, ProfileResultData } from '@hiptraveler/data-access/api';
import { ProfileAction, ProfileState, ProfileStateField } from '@hiptraveler/data-access/profile';
import { StateService } from './state-service';
import { ProfileStateService } from './profile-state.service';
import { SnackbarService } from '@hiptraveler/snackbar';
import { currentLang } from '@hiptraveler/common';

@Injectable()
export class DataAccessService extends StateService implements OnDestroy {

  @Select(ProfileState.blogResults) blogResults$: Observable<Partial<BasicInfoData>[] | null>;
  @Select(ProfileState.imageResults) imageResults$: Observable<ImageResultData[] | null>;
  @Select(ProfileState.itineraryResults) itineraryResults$: Observable<ItineraryData[] | null>;
  @Select(ProfileState.followingResults) followingResults$: Observable<ProfileResultData[] | null>;
  @Select(ProfileState.followerResults) followerResults$: Observable<ProfileResultData[] | null>;

  constructor(
    private router: Router,
    private stateService: ProfileStateService,
    private snackbar: SnackbarService,
    store: Store
  ) {
    super(store);
  }

  get searchResults$(): Observable<[ ImageResultData[] | null, HotelResultData[] | null ]> {
    return combineLatest([
      this.store.select(ProfileState.imageResults),
      this.store.select(ProfileState.hotelResults)
    ]).pipe( takeUntil(this.subscription$) );
  }

  async getProfileDetails(userId: string): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new ProfileAction.GetProfileDetails(userId)));
    } catch (value: any) {
      this.router.navigate([ '/', currentLang() ]);
      setTimeout(() => this.snackbar.open({ message: 'Profile does not exist. Please try again.', duration: 5000 }), 500);
    }
  }

  async getItineraryResults(page: number = 1): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      this.loadMoreObserver(userId, ProfileAction.GetItineraryResults);
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.GetItineraryResults({ userId, page })))
      } finally { }
    });
  }

  async getProfileStoryResults(page: number = 1): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      this.loadMoreObserver(userId, ProfileAction.GetProfileStoryResults);
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.GetProfileStoryResults({ userId, page })))
      } finally { }
    });
  }

  async getProfileUploadResults(page: number = 1): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      this.loadMoreObserver(userId, ProfileAction.GetProfileUploadResults);
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.GetProfileUploadResults({ userId, page })))
      } finally { }
    });
  }

  async getProfileFavoriteResults(callback: (success: boolean) => void, page: number = 1): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      this.loadMoreObserver(userId, ProfileAction.GetProfileFavoriteResults, callback);
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.GetProfileFavoriteResults({ userId, page })))
        callback(true);
      } catch (error) {
        callback(false);
      }
    });
  }

  async getProfileFollowingUserResults(page: number = 1): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      this.loadMoreObserver(userId, ProfileAction.GetFollowingUserResults);
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.GetFollowingUserResults({ userId, page })))
      } finally { }
    });
  }

  async getProfileFollowersUserResults(page: number = 1): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      this.loadMoreObserver(userId, ProfileAction.GetFollowersUserResults);
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.GetFollowersUserResults({ userId, page })))
      } finally { }
    });
  }

  private loadMoreObserver(userId: string, DispatchAction: any, callback?: (success: boolean) => void): void {
    this.stateService.loadMoreEvent$.pipe(takeUntil(this.subscription$)).subscribe(async (page: number) => {
      try {
        this.stateService.loadMoreProgressState$$.next(true);
        await firstValueFrom(this.store.dispatch(new DispatchAction({ userId, page })))
        callback && callback(true);
      } catch (error) {
        callback && callback(false);
      } finally {
        this.stateService.loadMoreProgressState$$.next(false);
      }
    });
  }

  resetProfileStateByField(fields: ProfileStateField[]): void {
    this.store.dispatch(new ProfileAction.PartialResetProfileState(fields));
  }

}
