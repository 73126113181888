import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProfileComponent } from './profile.component';

import { ShellComponent } from './shell.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '', component: ShellComponent,
        children: [
          { path: ':user', redirectTo: ':user/stories', pathMatch: 'full' },
          { path: ':user/:navigation', component: ProfileComponent }
        ]
      },
      {
        path: '**',
        component: ProfileComponent
      }
    ])
  ],
  declarations: [
    ShellComponent
  ]
})
export class ShellModule { }
