<banner></banner>

<div class="content--wrapper">
  <div class="content--wrapper-highlight">

    <sidenav></sidenav>

    <activity-content [class.full-view]="stateService.profileSettingsView$$ | async"></activity-content>

    <ng-container *ngIf="!(stateService.profileSettingsView$$ | async)">
      <map-content [@opacityFadeIn]></map-content>
    </ng-container>

  </div>
</div>
