import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AuthRegisterDialogComponent } from './auth-register-dialog.component';

export const AUTH_REGISTER_DIALOG_ID = 'auth-register-dialog-overlay-wrapper-id';

@Injectable()
export class AuthRegisterDialogActionService {

  constructor(
    public overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<AuthRegisterDialogComponent, unknown> | unknown {

    const dialog = this.dialog.getDialogById(AUTH_REGISTER_DIALOG_ID);
    if (dialog) return dialog.close();

    return this.dialog.open(AuthRegisterDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: AUTH_REGISTER_DIALOG_ID,
      panelClass: AUTH_REGISTER_DIALOG_ID.replace('-id', ''),
      width: '460px',
      maxWidth: 'auto',
      minWidth: 0,
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop() 
    });
  }
  
}
