import { Pipe, PipeTransform } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'dateString'
})
export class DateStringPipe implements PipeTransform {

  transform(data: ActivityDateData, index: number): string {
    return data?.date ? `${data?.date?.week_day}, ${data?.date?.month} ${data?.date?.day}` : `Day ${index+1}`;
  }

}
