<dialog-close-button></dialog-close-button>

<h1 translate="reg-form.custom-activity"></h1>

<mat-form-field appearance="outline">
  <mat-label>{{ 'reg-form.activity-name' | translate }}</mat-label>
  <input matInput [placeholder]="'reg-form.custom-activity-b' | translate" name="activity" type="text" #input (keydown.enter)="ref.close(input.value)">
</mat-form-field>

<ui-action-button size="medium" (click)="ref.close(input.value)">{{ 'reg-form.button-text-submit' | translate }}</ui-action-button>
