import { Inject, Injectable } from '@angular/core';
import { Client, PickerInstance, PickerOptions } from './filestack.model';

import { defaultClientOptions, FILESTACK_CLIENT } from '.';

@Injectable()
export class FilestackService {

  constructor(
    @Inject(FILESTACK_CLIENT) private filestackClient: Promise<Client>,
  ) { }

  async openFilestackPicker(pickerOptions: PickerOptions): Promise<PickerInstance> {

    let pickerInstance: PickerInstance | null = null;

    pickerOptions = { ...defaultClientOptions,  ...pickerOptions };

    pickerInstance = (await this.filestackClient)?.picker(pickerOptions) || null;
    pickerInstance?.open();

    return pickerInstance!;
  }

}
