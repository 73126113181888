import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, distinctUntilChanged, filter, firstValueFrom, interval, map, switchMap, take, takeUntil, tap } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { SearchAction, SearchState, SearchStateField } from '@hiptraveler/data-access/search';
import { AppListenerService, SearchLocationData, SearchLocationService, SearchResultDataType, currentLang, getWindowRef, promiseDelay, subdomain } from '@hiptraveler/common';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { Component } from './data-services.interface';

export * from './data-services.interface';

@Injectable()
export class DataService {

  subscription$ = new Subject<void>();

  protected pendingRequest: boolean;

  constructor(
    public appListener: AppListenerService,
    public searchLocation: SearchLocationService,
    public leafletControl: LeafletMapControlStateService
  ) { }

  protected get searchPage(): boolean {
    return this.searchLocation.rootSearchRoute
      || getWindowRef()?.location?.pathname.includes(`/${currentLang()}/search/`)
  }

  protected getBrandCampaign(store: Store): Promise<unknown> {
    return firstValueFrom(store.select(BrandState.brandCampaign).pipe(filter(Boolean)));
  }

  protected getViewDisplayItems<T>($: Observable<T[] | null>): Observable<T[]> {
    return $.pipe(
      map(response => response || []),
      switchMap((result: T[]) => this.appListener.clientWidth$.pipe(
        map((clientWidth: number) => {
          if (clientWidth <= 1050) {
            return result.slice(0, 4)
          }
          if (clientWidth > 1400 || clientWidth <= 1100) {
            return result.slice(0, 6)
          }
          return result.slice(0, 4);
        })
      ))
    );
  }

  protected getSearchResultData<T>($: Observable<T[] | null>): Observable<T[]> {
    return $.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev?.length === curr?.length
      }),
      switchMap(results => interval(0).pipe(
        take(results?.length || 0),
        map(index => results?.slice(0, index + 1) || [])
      )),
      filter(e => !!e?.length),
      takeUntil(this.subscription$)
    );
  }

  protected locationChanges(component: Component): void {

    // Request default search results if the location is removed.
    this.searchLocation.searchLocationState$.pipe(
      filter(e => !e),
      tap(async() => {
        this.pendingRequest = false;
        await this.resetSearchState(component);
        component.getResultsByLocationData({ default: true });
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    // Request new search results with every location change.
    this.searchLocation.searchLocation$.pipe(
      tap(async () => {
        this.pendingRequest = false;
        await this.resetSearchState(component);
        component.getResultsByLocationData();
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  protected observeLoadMoreEvent(
    option: { store: Store, route: ActivatedRoute },
    callback1: (value: number) => void,
    callback2: (value: SearchLocationData) => void
  ): void {
    const data = this.searchLocation.data || {} as any;
    const currentPage = option.store.selectSnapshot(SearchState.pagination)?.page;
    if (currentPage && !Object.keys(data).length && subdomain() !== 'hiptraveler') {
      callback1(currentPage + 1);
      return;
    }
    currentPage && callback2({
      ...data,
      name: option.route.snapshot.params['location'] || '',
      searchCnt: `${currentPage + 1}`
    })
  }

  protected async resetSearchState(component: Component, fields?: SearchStateField[]): Promise<void> {
    if (this.checkEmptySearchState(component)) return;
    await firstValueFrom(component.store.dispatch(new SearchAction.ResetSearchState(fields)));
  }

  protected searchResultLocations(observer$: Observable<any>, type: SearchResultDataType): void {
    observer$.pipe(
      tap((res) => !res && this.leafletControl.allSearchResultsData$$.next([])),
      filter(Boolean),
      tap((response) => this.leafletControl.allSearchResultsData$$.next(response.map((item: any) => ({
        id: item.id || 'unknown',
        title: item.name || '',
        image: item.imgUrl || '',
        address: item.address,
        location: item.loc,
        locationName: item.location || `${item.city} ${item.countryCd}`,
        type
      })))),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  protected profileListResultLocations(observer$: Observable<any>, type: SearchResultDataType): void {
    observer$.pipe(
      tap((res) => !res && this.leafletControl.allSearchResultsData$$.next([])),
      filter(Boolean),
      tap((response) => this.leafletControl.allSearchResultsData$$.next(response.map((item: any) => ({
        id: item.profId || 'unknown',
        title: item.profName || '',
        image: item.profPic || 'https://cdn.filestackcontent.com/A5yG40niNR3q9y7GBglCLz/resize=width:150/auto_image/compress/https://graph.facebook.com/1950539548299920/picture?width=280&height=280',
        locationName: item?.profLoc || '',
        address: {
          display_address: item?.profLoc || ''
        },
        location: {
          coordinates: [ +item.latitude, +item.longitude ].reverse(),
          type: 'Point'
        },
        type
      })))),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  protected async dispatchActionRequest(callback: () => Promise<void>): Promise<void> {
    if (this.pendingRequest) return;
    try {
      this.pendingRequest = true;
      await promiseDelay(50);
      await callback();
    } catch (error: any) {
      if (error?.name === 'EmptyError') return;
      console.log('@@@ ', error);
    } finally {
      this.pendingRequest = false;
    }
  }

  private checkEmptySearchState(component: Component): boolean {
    return !component.store.selectSnapshot(SearchState.state)?.adventures
      && !component.store.selectSnapshot(SearchState.state)?.communities
      && !component.store.selectSnapshot(SearchState.state)?.foods
      && !component.store.selectSnapshot(SearchState.state)?.hotels
      && !component.store.selectSnapshot(SearchState.state)?.itineraries
      && !component.store.selectSnapshot(SearchState.state)?.pagination;
  }

}
