import { Component, AfterViewInit, Input, HostListener, Output, EventEmitter } from '@angular/core';

import { EditableSelectionsService } from './editable-selections.service';
import { opacityFade } from '@hiptraveler/animations';
import { Coordinates, EditableSelectionStatus } from './editable-selections.model';
import { PLACEHOLDER_ATTR } from './editable-selections.dom-helper';

@Component({
  selector: 'editable-selections',
  templateUrl: './editable-selections.component.html',
  styleUrls: ['./editable-selections.component.scss'],
  viewProviders: [ EditableSelectionsService ],
  animations: [ opacityFade() ]
})
export class EditableSelectionsComponent implements AfterViewInit {

  @Output() stateChanged = new EventEmitter<EditableSelectionStatus>();
  @Output() yPositionChange = new EventEmitter<string>();

  @Input() editable: HTMLDivElement;

  constructor(
    public service: EditableSelectionsService
  ) { }

  ngAfterViewInit(): void {
    this.service.hostStateObserver(this.editable);

    this.service.editorNewLineAndDeleteListener(this.editable, () => {
      this.stateChanged.emit(EditableSelectionStatus.InsertText)
      this.editable.setAttribute(PLACEHOLDER_ATTR, '');
    })

    this.service.coordinates$.subscribe((coordinates: Coordinates | null) => {
      coordinates && this.yPositionChange.emit(coordinates.top);
    });

    this.service.showSelection$$.subscribe((state: any) => {
      const editableParent = this.editable.parentElement;
      const placeholderClass = 'host--content-videoUrl-placeholder';
      const placeholder = editableParent?.getElementsByClassName(placeholderClass).item(0) as HTMLParagraphElement;
      if (!placeholder) return;
      placeholder.style.opacity = state ? '1' : '0';
    });
  }

  /**
   * Listens to the Medium text editor's status changes and updates the host status for the UX.
   *
   * @param {EditableSelectionStatus} selection - Medium text editor status types
   * @returns {void}
   */
  changeHostState(selection?: EditableSelectionStatus): void {
    this.service.changeHostStateByElement(this.editable, selection);
    this.stateChanged.emit(selection);
  }

  /**
   * This method listens for the Escape key press event on the document.
   * When the key is pressed, it calls the service to hide the host element.
   * 
   * @returns {void}
   */
  @HostListener('document:keydown.escape')
  onEscapeKey(): void {
    this.service.hideHostElement();
  }

}
