<div class="activity-date-list" scrollElementBorder [class.view]="view === ''">

  <ng-container *ngIf="(service.actDate$ | async)?.length;else labelDatesNetworkLoading">

    <activity-date
      [activity]="activity"
      [index]="i+1"
      [hideClose]="((service.actDate$ | async)?.length || 0) < 2"
      [scrollToView]="'activity-day-' + activity.day"
      *ngFor="let activity of (service.actDate$ | async);let length = count;let i = index"></activity-date>

    <ng-container *ngIf="view !== ''">
      <activity-date createAction></activity-date>
    </ng-container>

  </ng-container>
  <ng-template #labelDatesNetworkLoading>

    <ng-container *ngIf="view !== ''">
      <activity-date createAction [networkLoading]="true"></activity-date>
    </ng-container>

  </ng-template>

</div>
