import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'card-section',
  template: `

    <h2 class="card-selection--header-text" *ngIf="header">{{ header }}</h2>
    <div class="card-selection--space-bottom" [ngStyle]="{ 'padding-bottom': noPaddingBottom === '' ? '0' : '16px' }">
      <ng-content></ng-content>
    </div>

  `,
  styleUrls: [`./card-section.component.scss`],
  host: { class: 'custom-activity-dialog-card-section' },
  encapsulation: ViewEncapsulation.None
})
export class CardSectionComponent {
  @Input() header: string;
  @Input() noPaddingBottom: string;
}
