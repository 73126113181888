import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'itinerary-feature-card',
  templateUrl: './itinerary-feature-card.component.html',
  styleUrls: ['./itinerary-feature-card.component.scss'],
  host: { class: 'ht-itinerary-feature-card' },
  encapsulation: ViewEncapsulation.None
})
export class ItineraryFeatureCardComponent implements OnInit {

  @Output() positionMoved = new EventEmitter<'up' | 'down'>();

  @Input() starRating?: number;
  @Input() type: 'hotel' | 'consume' | 'amusement';
  @Input() buttonActions: boolean = true;

  ratings: number[];

  ngOnInit(): void {
    if (this.type !== 'amusement' && this.starRating) {
      this.ratings = Array.from({ length: this.starRating }, () => 0);
    }
  }

}
