<ng-container *ngIf="userAuthenticated$ | async;else defaultView">
  <ng-container *ngIf="blogs$ | async as blogs;else emptyList">

    <!-- Itineraries -->
    <div class="menu--display-itineraries">
      <div class="menu--display-itinerary"
        *ngFor="let blog of blogs;let isFirst = first;let isLast = last"
        [ngClass]="{ first: isFirst, last: isLast }"
        [routerLink]="[lang,'travel-story',blog.pageTitle]"
        [class.active]="false"
        (click)="stateService.overlayState$$.next(false)">
        <mat-icon svgIcon="experience-finder-screen-shared.itinerary-icon-svg"></mat-icon>
        <p>{{ blog.title }}</p>
      </div>
    </div>

  </ng-container>
</ng-container>

<ng-template #defaultView>
  <ng-container *ngIf="authenticated$ | async;else emptyList">
    <mat-progress-spinner mode="indeterminate" class="primary"></mat-progress-spinner>
  </ng-container>
</ng-template>

<ng-template #emptyList>
  
  <!-- New Travelogue - action button -->
  <div class="menu--display-action" (click)="actionClicked.emit();">
    <p translate="exp-finder.new-travelogue"></p>
  </div>
  <!-- New Travelogue - icon -->
  <div class="menu--display-photo" [ngStyle]="{
    'background-image': travelogueImage$ | async,
    'background-size': '70%',
    'background-position-x': 'center',
    'background-position-y': '110%'
  }"></div>

</ng-template>
