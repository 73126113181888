import { AfterViewInit, Directive, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Provider } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AppListenerService, ScrollListenerService } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import * as Service from './services';

const providers: Provider[] = [
  Service.GlobalObjectsService,
  Service.AuthObserverService,
  Service.RequiredStateService,
  Service.SearchLocationStateService,
  Service.LocationDropdownStateService,
  Service.DataAccessObserverService,
  Service.BrowserStorageInitService,
  Service.DispatchListenerService,
  Service.RootBrandStylesObserverService,
  Service.WidgetObserverService,
  Service.ServerSideShellService,
  Service.HomeHeadingObserverService,
  Service.ItineraryProviders,
  AuthDialogActionService,
  PromptDialogActionService,
];

@Directive({
  selector: '[appRequiredState]',
  providers
})
export class RequiredStateDirective implements OnInit, AfterViewInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef<HTMLDivElement>,
    private appListener: AppListenerService,
    private scrollListener: ScrollListenerService,
    private service: Service.RequiredStateService,
    private auth: Service.AuthObserverService,
    private globalObjects: Service.GlobalObjectsService,
    private searchLocationState: Service.SearchLocationStateService,
    private locationDropdownState: Service.LocationDropdownStateService,
    private dataAccessObserver: Service.DataAccessObserverService,
    private browserStorageInit: Service.BrowserStorageInitService,
    private dispatchListener: Service.DispatchListenerService,
    private rootBrandStylesObserver: Service.RootBrandStylesObserverService,
    private itineraryObserver: Service.ItineraryObserverService,
    private widgetObserver: Service.WidgetObserverService,
    private homeHeadingObserver: Service.HomeHeadingObserverService,
    private serverSideShell: Service.ServerSideShellService
  ) { }

  ngOnInit(): void {

    if (this.appListener.invalidRequest) return;

    this.observeOnScroll();
    this.globalObjects.initialize();
    this.browserStorageInit.initialize();
    this.auth.observe();
    this.searchLocationState.observe();
    this.dataAccessObserver.observe();
    this.dispatchListener.observe();
    this.rootBrandStylesObserver.observe();
    this.itineraryObserver.observe();
    this.locationDropdownState.observe(this.element.nativeElement);
  }

  ngAfterViewInit(): void {
      
    if (this.appListener.invalidRequest) return;

    this.service.setup();
    this.serverSideShell.initialize();
    this.homeHeadingObserver.observe();
    this.widgetObserver.observe();
  }

  @HostListener('window:scroll', ['$event'])
  private observeOnScroll(): void {
    this.scrollListener.runScrollObserver();
  }

  @HostListener('window:load', ['$event'])
  observeMouseMove(): void {
    isPlatformBrowser(this.platformId)
      && this.appListener.mapVisibilityState$$.next(true);
  }

  keyState: boolean;
  @HostListener('window:keyup')
  keyup(): void {
    this.keyState = false;
  }
  @HostListener('window:keydown', ['$event'])
  keydown(event: KeyboardEvent): void {
    if (this.keyState) return;
    this.keyState = true;
    this.appListener.keyboardEvent$$.next(event);
  }

}
