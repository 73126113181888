import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';

import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ListOverlayModule } from './list-overlay/list-overlay.module';
import { CustomInputBorderDirectiveModule } from '@hiptraveler/common';
import { UtilsModule } from './utils';

import { DataAccessProviders, FormStylesDirectiveModule, FormLocationDirectiveModule } from './common';
import { FiltersComponent } from './filters.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatNativeDateModule,
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatDatepickerModule,
  TranslateModule
];

const LIBRARIES = [
  CustomMapAutocompleteModule,
  ButtonsModule,
  ListOverlayModule,
  CustomInputBorderDirectiveModule,
  UtilsModule,
  FormStylesDirectiveModule,
  FormLocationDirectiveModule
];

const COMPONENTS = [
  FiltersComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  providers: [
    DataAccessProviders
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    FiltersComponent
  ]
})
export class FiltersModule { }
