import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';

import { SpecializationService } from './specialization.service';
import { Store } from '@ngxs/store';
import { BrandState } from '@hiptraveler/data-access/brand';

@Component({
  selector: 'specialization',
  templateUrl: './specialization.component.html',
  styleUrls: ['./specialization.component.scss'],
  host: { class: 'travel-agent-register-specialization' },
  viewProviders: [ SpecializationService ],
  encapsulation: ViewEncapsulation.None
})
export class SpecializationComponent implements OnInit {

  selection: number[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public service: SpecializationService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.service.initializeFormObserver();
    this.service.formValue$.subscribe((value: string[]) => {
      const experiences = this.store.selectSnapshot(BrandState.experiences) || [];
      this.selection = value.map(e => experiences.map(e => e.code).indexOf(e));
    });
  }

  toggleSelection(index: number): void {
    const indexInSelection = this.selection.indexOf(index);
    if (indexInSelection !== -1) {
      this.selection.splice(indexInSelection, 1);
      this.service.removeSpecialization(indexInSelection);
    } else {
      this.selection.push(index);
      this.service.addSpecialization(index);
    }
  }

  navigateSlider(direction: 'left' | 'right'): void {
    if (isPlatformServer(this.platformId)) return;
    const OFFSET = 248;
    const element = this.document.getElementsByClassName('specialization--slider')[0];
    if (!element) return;
    const multiplier = direction === 'left' ? -1 : 1;
    element.scrollTo({
      left: element.scrollLeft + OFFSET * multiplier,
      behavior: 'smooth'
    });
  }

}
