import { SUPPORTED_LANG, SupportedLanguage, getWindowRef } from "..";

const defaultClient = { location: { pathname: '' } };

export function currentLang(): SupportedLanguage {
  const _ = getWindowRef() || defaultClient;
  if (!_?.location?.pathname) return 'en';
  const langFromPathname = _.location.pathname.split('/')[1] as SupportedLanguage;
  return SUPPORTED_LANG.includes(langFromPathname) ? langFromPathname : 'en';
}

export function getLanguageName(): string {
  switch (currentLang()) {
    case 'en':
      return 'English';
    case 'fr':
      return 'French';
    case 'de':
      return 'German';
    case 'es':
      return 'Spanish';
    case 'it':
      return 'Italian';
    case 'zh':
      return 'Chinese';
    default:
      return 'Unknown';
  }
}
