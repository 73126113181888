import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { delay, filter, take, tap } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AppListenerService, ScrollListenerService } from '@hiptraveler/common';
import { FormFeatureService } from './form-feature.service';
import { nonHT } from './form-helper';

@Injectable()
export class FormEventService {

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private scrollListener: ScrollListenerService,
    private leafletControl: LeafletMapControlStateService,
    private stateService: ExperienceFinderStateService,
    private formFeature: FormFeatureService
  ) { }

  initialize({ placeAutocomplete }: { placeAutocomplete: HTMLInputElement }): void {
    this.brandCampaignDataObserver();
    this.searchResultDataListener();
    this.domScrollObserver(placeAutocomplete);
  }

  private brandCampaignDataObserver(): void {
    this.store.select(BrandState.brandCampaign).pipe(
      filter(Boolean),
      delay(0),
      tap(({ campaignCustom }: Partial<BrandCampaign>) => {
        const autoGen = campaignCustom?.hasAutoItineraryGenerator;
        this.formFeature.form.patchValue({
          itinerary: autoGen ? (nonHT() ? 'auto' : 'own') : 'own'
        });
      }),
      take(1)
    ).subscribe();
  }

  private searchResultDataListener(): void {

    if (!this.stateService.overlayState$$.value || !sessionStorage.getItem('fcant')) return;
    
    const data = this.leafletControl.activeSearchResultData$$.value;
    data && setTimeout(() => {
      sessionStorage.removeItem('fcant');
      this.formFeature.form.patchValue({ place: data.address.city || data.address.state || data.address.poi });
    }, 300);
  }

  private domScrollObserver(input: HTMLInputElement): void {
    this.scrollListener.scrollPosition$.subscribe(() => {
      if (!input.value) return;

      const value = { ...this.appListener.mapsAutocompleteState$$.value };
      Object.keys(value).forEach((key: string) => {
        value[key] = false;
      });
      this.appListener.mapsAutocompleteState$$.next(value);

      const dispatchEvent = (value: string): void => {
        input.value = value;
        const event = new Event('input', { bubbles: true, cancelable: true });
        input.dispatchEvent(event);
      }

      dispatchEvent(`${input.value} `);
      setTimeout(() => dispatchEvent(input.value.trim()));
    });
  }

}
