
<!-- To do: Implement i18n -->

<div class="external-view--back" matRipple (click)="back()">
  <mat-icon>arrow_back</mat-icon>
</div>

<img [src]="hiptravelerLogoImage$ | async" draggable="false" alt="HipTraveler Logo">

<p class="external-view--description">It seems you have a popup blocker enabled. For the best experience, please disable it. Alternatively, you can view your itinerary by clicking the link below.</p>

<p class="external-view--link" matTooltip="Copy to clipboard" [cdkCopyToClipboard]="planTrip.itineraryRedirectUrl" (cdkCopyToClipboardCopied)="copyToClipboard($event)">{{ planTrip.itineraryRedirectUrl }}</p>

<p class="external-view--link-note">Click to copy to clipboard.</p>
