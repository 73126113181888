import { currentLang, getWindowRef, PageType, SUPPORTED_LANG, SupportedLanguage } from "@hiptraveler/common";
import { homeHeadingValue } from "./home-heading-value";

export function pageIdByUrl(url?: string): string {
  return SUPPORTED_LANG.map((lang: SupportedLanguage) => {
    return url?.split(`/${lang}/`)?.[1]?.split('/')?.[1];
  }).filter(Boolean)[0] || '';
}

export function validateItineraryAndBlogViewPage(): boolean {

  const pathname = getWindowRef()?.location?.pathname.toLowerCase();

  return pathname?.includes(`/${currentLang()}/itinerary/`)
    || pathname?.includes(`/${currentLang()}/travel-story/`);
}

export function searchAndDefaultPageCallback(callback: (heading: string) => void): void {

  const params: PageType[] = [
    'travel', 'ambassador', 'dnsmpi', 'privacyPolicy', 'terms', 'profile', 'travel-agent-register',
    'search', 'experiences', 'thingstodo', 'hotels', 'foodanddrink', 'community',
  ];

  const path: string = getWindowRef()?.['location']?.['pathname'] || '';
  const matchedParams = params.filter(e => path?.includes(`/${e}`))?.[0];

  if (matchedParams?.length > 0) callback(homeHeadingValue()[matchedParams])
}
