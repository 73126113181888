import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store, createSelector } from '@ngxs/store';
import { tap } from 'rxjs';

import { SearchStateModel, SearchAction } from '.';
import * as API from '@hiptraveler/data-access/api';
import * as Helper from './helpers';
import { UserState } from '@hiptraveler/data-access/user';

export const SEARCH_STATE_TOKEN = new StateToken<SearchStateModel>('state_search');

export const searchStateDefaults: any = null;

@State<SearchStateModel>({
  name: SEARCH_STATE_TOKEN,
  defaults: searchStateDefaults
})

@Injectable()
export class SearchState {

  @Selector()
  static state(state: SearchStateModel): SearchStateModel | null {
    return state || null;
  }

  @Selector()
  static locationData(state: SearchStateModel): API.LocationData | null {
    return state?.locationData || null;
  }

  @Selector()
  static hotels(state: SearchStateModel): API.HotelData[] | null {
    return state?.hotels?.filter(e => e.name) || null;
  }

  @Selector()
  static adventures(state: SearchStateModel): API.AdventureData[] | null {
    return Helper.mapAdventureResponse(state?.adventures)
  }

  @Selector()
  static foods(state: SearchStateModel): API.FoodData[] | null {
    return state?.foods?.filter(e => e.name) || null;
  }

  @Selector()
  static travelAgents(state: SearchStateModel): API.TravelAgentData[] | null {
    return state?.travelAgents || null;
  }

  @Selector()
  static itineraries(state: SearchStateModel): API.ItineraryData[] | null {
    return state?.itineraries || null;
  }

  @Selector()
  static communities(state: SearchStateModel): API.ProfileResultData[] | null {
    return state?.communities || null;
  }

  @Selector()
  static pagination(state: SearchStateModel): API.SearchResultPagination | null {
    return state?.pagination || null;
  }

  static searchResult(activityId: string): any {
    return createSelector([SearchState], (state: SearchStateModel) => {
      const searchResults: any = [ ...(state?.hotels || []), ...(state?.adventures || []), ...(state?.foods || []) ];
      return searchResults.find((e: any) => e['id'] === activityId) || null;
    });
  }

  constructor(
    private store: Store,
    private searchApi: API.SearchApiService
  ) { }

  @Action(SearchAction.GetLocationDataByQuery, { cancelUncompleted: true })
  getLocationDataByQuery(ctx: StateContext<SearchStateModel>, action: SearchAction.GetLocationDataByQuery) {
    return this.searchApi.getLocationDataByQuery(action.query).pipe(
      tap((response: API.SearchLocationDataResponse) => {
        API.validateResponse(response, { ignoreKey: 'query' });
        ctx.patchState({ locationData: response.locationInfo || null });
      })
    );
  }
 
  @Action(SearchAction.GetHotelsContent, { cancelUncompleted: true })
  getHotelsContent(ctx: StateContext<SearchStateModel>, { data: query }: SearchAction.GetHotelsContent) {
    return this.searchApi.getBrandContent(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'hotels'),
      tap((data: API.HotelsContentResponse) => ctx.patchState({
        hotels: Helper.uniqBy(ctx, data, 'hotels', 'cHotels'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.page || '1') }
      }))
    );
  }
 
  @Action(SearchAction.GetAdventuresContent, { cancelUncompleted: true })
  getAdventuresContent(ctx: StateContext<SearchStateModel>, { data: query }: SearchAction.GetAdventuresContent) {
    return this.searchApi.getBrandContent(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'adventures'),
      tap((data: API.AdventuresContentResponse) => ctx.patchState({
        adventures: Helper.uniqBy(ctx, data, 'adventures', 'brandTours'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.page || '1') }
      }))
    );
  }
 
  @Action(SearchAction.GetFoodsContent, { cancelUncompleted: true })
  getFoodsContent(ctx: StateContext<SearchStateModel>, { data: query }: SearchAction.GetFoodsContent) {
    return this.searchApi.getBrandContent(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'foods'),
      tap((data: API.FoodsContentResponse) => ctx.patchState({
        foods: Helper.uniqBy(ctx, data, 'foods', 'brandDineList'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.page || '1') }
      }))
    );
  }
 
  @Action(SearchAction.GetTravelAgentsContent, { cancelUncompleted: true })
  getTravelAgentsContent(ctx: StateContext<SearchStateModel>, { data: query }: SearchAction.GetTravelAgentsContent) {
    return this.searchApi.getBrandContent(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'travelAgents'),
      tap((data: API.TravelAgentsContentResponse) => ctx.patchState({
        travelAgents: Helper.uniqBy(ctx, data, 'travelAgents', 'agentsList'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.page || '1') }
      }))
    );
  }
 
  @Action(SearchAction.GetItinerariesContent, { cancelUncompleted: true })
  getItineraryContent(ctx: StateContext<SearchStateModel>, { data: query }: SearchAction.GetItinerariesContent) {
    return this.searchApi.getBrandContent(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'itineraries'),
      tap((data: API.ItinerariesContentResponse) => ctx.patchState({
        itineraries: [
          ...Helper.uniqBy(ctx, data, 'itineraries', 'brandItineraries'),
          ...Helper.uniqBy(ctx, data, 'itineraries', 'cBlogs'),
        ],
        pagination: { moreResults: !!data?.moreResults, page: +(query.page || '1') }
      }))
    );
  }
 
  @Action(SearchAction.GetHotelsByQuery, { cancelUncompleted: true })
  getHotelsByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetHotelsByQuery) {
    return this.searchApi.getHotelsByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'hotels'),
      tap(({ data }: API.SearchHotelsResponse) => ctx.patchState({
        hotels: Helper.uniqBy(ctx, data, 'hotels', 'hotelList'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetAdventuresByQuery, { cancelUncompleted: true })
  getAdventureByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetAdventuresByQuery) {
    return this.searchApi.getAdventuresByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'adventures'),
      tap(({ data }: API.SearchAdventuresResponse) => ctx.patchState({
        adventures: Helper.uniqBy(ctx, data, 'adventures', 'imgResult'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetFoodsByQuery, { cancelUncompleted: true })
  getFoodByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetFoodsByQuery) {
    return this.searchApi.getFoodsByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'foods'),
      tap(({ data }: API.SearchFoodsResponse) => ctx.patchState({
        foods: Helper.uniqBy(ctx, data, 'foods', 'imgResult'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetItinerariesByQuery, { cancelUncompleted: true })
  getItinerariesByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetItinerariesByQuery) {
    return this.searchApi.getItinerariesByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'itineraries'),
      tap(({ data }: API.SearchItinerariesResponse) => ctx.patchState({
        itineraries: Helper.uniqBy(ctx, data, 'itineraries', 'itiMap'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetCommunitiesByQuery, { cancelUncompleted: true })
  getCommunitiesByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetCommunitiesByQuery) {
    return this.searchApi.getCommunitiesByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'communities'),
      tap(({ data }: API.SearchCommunitiesResponse) => ctx.patchState({
        communities: Helper.uniqBy(ctx, data, 'communities', 'profileResult', 'profId'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetHotelsTermByQuery, { cancelUncompleted: true })
  getHotelsTermByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetHotelsTermByQuery) {
    return this.searchApi.getHotelsTermByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'hotels'),
      tap(({ data }: API.SearchHotelsResponse) => ctx.patchState({
        hotels: query.searchCnt === '1' ? (data?.hotelList || []) : Helper.uniqBy(ctx, data, 'hotels', 'hotelList'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetAdventureTermByQuery, { cancelUncompleted: true })
  getAdventureTermByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetAdventureTermByQuery) {
    return this.searchApi.getAdventuresTermByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'adventures'),
      tap(({ data }: API.SearchAdventuresResponse) => ctx.patchState({
        adventures: query.searchCnt === '1' ? (data?.imgResult || []) : Helper.uniqBy(ctx, data, 'adventures', 'imgResult'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.GetFoodsTermByQuery, { cancelUncompleted: true })
  getFoodsTermByQuery(ctx: StateContext<SearchStateModel>, { query }: SearchAction.GetFoodsTermByQuery) {
    return this.searchApi.getFoodsTermByQuery(query).pipe(
      tap(API.validateResponse),
      Helper.catchNoResultFound(ctx, 'foods'),
      tap(({ data }: API.SearchFoodsResponse) => ctx.patchState({
        foods: query.searchCnt === '1' ? (data?.imgResult || []) : Helper.uniqBy(ctx, data, 'foods', 'imgResult'),
        pagination: { moreResults: !!data?.moreResults, page: +(query.searchCnt || '1') }
      }))
    );
  }

  @Action(SearchAction.ToggleSearchResultLike)
  toggleSearchResultLike(ctx: StateContext<SearchStateModel>, { data }: SearchAction.ToggleSearchResultLike) {
    return this.searchApi.toggleLikeItinerary(data).pipe(
      tap(API.validateResponse),
      tap((response: API.ToggleLikeItineraryResponse) => {
        const updatedSearchResults = data.hipType
          ? Helper.removeUserLike(ctx.getState()?.[data.field]!, data.id, data.userId, response.data!.hipCount) 
          : Helper.addUserLike(ctx.getState()?.[data.field]!, data.id, data.userId, response.data!.hipCount);
        ctx.patchState({ [data.field]: updatedSearchResults });
      })
    );
  }

  @Action(SearchAction.UpdateSearchResultLike)
  updateSearchResultLike(ctx: StateContext<SearchStateModel>, { data }: SearchAction.UpdateSearchResultLike) {
    const userId = this.store.selectSnapshot(UserState.id) || '';
    Helper.modifyResultFavorites(ctx, data, userId);
  }

  @Action(SearchAction.ResetSearchState)
  resetSearchState(ctx: StateContext<SearchStateModel>, { fields }: SearchAction.ResetSearchState) {
    ctx.patchState(Helper.resetState(ctx, fields));
  }

}
