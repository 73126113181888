import { Component, Input } from '@angular/core';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay, DialogType } from '../../../common';

@Component({
  selector: 'tour-information',
  templateUrl: './tour-information.component.html',
  styleUrls: ['./tour-information.component.scss'],
  animations: [ opacityFade(animationDelay) ]
})
export class TourInformationComponent {

  @Input() data?: ImageResultData;
  @Input() dialogType: DialogType;
  @Input() imageInformation?: ImageResultData | null;

}
