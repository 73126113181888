import { Component, Inject, OnInit, Self, ViewEncapsulation } from '@angular/core';

import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { TravelAgentRegisterService } from './travel-agent-register.service';
import { DefaultUiService, WINDOW } from '@hiptraveler/common';

@Component({
  selector: 'app-travel-agent-register',
  templateUrl: './travel-agent-register.component.html',
  styleUrls: ['./travel-agent-register.component.scss', './travel-agent-register-responsiveness.scss'],
  host: { class: 'page--travel-agent-register' },
  viewProviders: [ DefaultUiService, TravelAgentRegisterService, AuthDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class TravelAgentRegisterComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: any,
    @Self() private defaultUI: DefaultUiService,
    public service: TravelAgentRegisterService
  ) { }

  ngOnInit(): void {
    this.service.validateUser();
  }

  tourAgentApply(): void {
    this.window.scrollTo(0, 420);
  }

}
