import { appWindowObjectPrefix, getWindowRef } from "@hiptraveler/common";


export const itinerariesGlobalKey = `${appWindowObjectPrefix}_search_state_itineraries_query`;

export function setItinerariesQuery(query: any): void {
  getWindowRef()[itinerariesGlobalKey] = query;
}
export function getItinerariesQuery(): any | undefined {
  return getWindowRef()?.[itinerariesGlobalKey];
}


export const adventuresGlobalKey = `${appWindowObjectPrefix}_search_state_adventures_query`;

export function setAdventuresQuery(query: any): void {
  getWindowRef()[adventuresGlobalKey] = query;
}
export function getAdventuresQuery(): any | undefined {
  return getWindowRef()?.[adventuresGlobalKey];
}


export const hotelsGlobalKey = `${appWindowObjectPrefix}_search_state_hotels_query`;

export function setHotelsQuery(query: any): void {
  getWindowRef()[hotelsGlobalKey] = query;
}
export function getHotelsQuery(): any | undefined {
  return getWindowRef()?.[hotelsGlobalKey];
}


export const foodsGlobalKey = `${appWindowObjectPrefix}_search_state_foods_query`;

export function setFoodsQuery(query: any): void {
  getWindowRef()[foodsGlobalKey] = query;
}
export function getFoodsQuery(): any | undefined {
  return getWindowRef()?.[foodsGlobalKey];
}


export const communitiesGlobalKey = `${appWindowObjectPrefix}_search_state_communities_query`;

export function setCommunitiesQuery(query: any): void {
  getWindowRef()[communitiesGlobalKey] = query;
}
export function getCommunitiesQuery(): any | undefined {
  return getWindowRef()?.[communitiesGlobalKey];
}
