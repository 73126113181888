import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FeatureCardModule } from '@hiptraveler/ui/feature-card';
import { BlankItineraryModule } from './blank-itinerary/blank-itinerary.module';

import { ExperiencesComponent } from './experiences.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  FeatureCardModule,
  BlankItineraryModule
];

const COMPONENTS = [
  ExperiencesComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: ExperiencesComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ExperiencesModule { }
