import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';

import { UserState } from '@hiptraveler/data-access/user';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { PromptDialogActionService, PromptDialogButton } from '@hiptraveler/dialogs/prompt-dialog';
import { addItineraryRequestPending, currentLang, getWindowRef, NavbarButtonData, NavbarControlStateService, ofActionDispatchedSignOutNoAuthState } from '@hiptraveler/common';
import { CommonService } from './common.service';

@Injectable()
export class CloseOpenTripButtonObserverService {

  openTripButton: NavbarButtonData;

  constructor(
    private router: Router,
    private store: Store,
    private navbarControl: NavbarControlStateService,
    private authDialog: AuthDialogActionService,
    private promptDialog: PromptDialogActionService,
    private commonService: CommonService
  ) { }

  observe(): void {

    let prevHasOpenTripButton = false;
    let prevHasPreviewButton = false;

    this.navbarControl.activeNavbarActionButtons$.pipe(
      tap((buttons: NavbarButtonData[] | null) => {

        const openTripButton = buttons?.find(e => e?.name?.includes('navbar.action-text-open-trip'));
        const hasPreviewButton = !!buttons?.find(e => e?.action === 'composePreview');

        if (openTripButton) {
          this.openTripButton = openTripButton;
        }

        if (!openTripButton && prevHasOpenTripButton && !hasPreviewButton) {

          if (new RegExp(`/${currentLang()}/(itinerary|compose)/`).test(this.router.url)) return;
          
          if (!this.store.selectSnapshot(UserState.authenticated)) return this.openSaveTripPromptDialog();

          this.commonService.removeOpenTripProcess();
        }
        
        prevHasOpenTripButton = !!openTripButton;
        prevHasPreviewButton = hasPreviewButton;
      })
    ).subscribe();
  }

  private openSaveTripPromptDialog(): void {

    if (getWindowRef()?.[addItineraryRequestPending] || getWindowRef()?.[ofActionDispatchedSignOutNoAuthState]) return;

    const loginOrRegisterButton: PromptDialogButton = {
      name: 'exp-finder.log-in-register',
      theme: 'primary',
      progressSpinner: true,
      executable: () => {
        this.authDialog.open();
        ref.close();
      }
    };

    const closeButton: PromptDialogButton = {
      name: 'exp-finder.close',
      theme: 'secondary',
      progressSpinner: true,
      executable: () => {
        this.commonService.removeOpenTripProcess();
        ref.close(true);
      }
    };

    const ref: any = this.promptDialog.open({
      title: 'exp-finder.save-your-trip',
      message: 'exp-finder.create-a-profile-to-save-your-trip',
      buttons: [ loginOrRegisterButton, closeButton ]
    });

    ref?.afterClosed().subscribe((res: any) => {
      if (res) return;
      this.navbarControl.setActiveNavbarButtons([ this.openTripButton ]);
    });
  }

}
