import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { RequestPendingSkeletonComponent } from './request-pending-skeleton.component';

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule
];

const COMPONENTS = [
  RequestPendingSkeletonComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    RequestPendingSkeletonComponent
  ]
})
export class RequestPendingSkeletonModule { }
