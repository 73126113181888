import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { ActionCompletion, Actions, ofActionCompleted, ofActionDispatched } from '@ngxs/store';
import { Subject, filter, takeUntil, tap } from 'rxjs';

import { ActivityDateService } from './activity-date.service';
import { ComponentStateService, ItineraryStateService } from '../../common';

@Component({
  selector: 'activity-date',
  templateUrl: './activity-date.component.html',
  styleUrls: ['./activity-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ ActivityDateService ]
})
export class ActivityDateComponent implements OnInit, OnDestroy {

  @Input() activity: ActivityDateData;
  @Input() networkLoading: boolean;
  @Input() createAction: string;
  @Input() hideClose: boolean;
  @Input() index: number;

  loading: boolean = false;
  subscription$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    public service: ActivityDateService,
    public itineraryState: ItineraryStateService,
    public componentState: ComponentStateService
  ) { }

  ngOnInit(): void {

    this.actions$.pipe(
      ofActionDispatched(ItineraryAction.AddItineraryDay),
      tap(() => (this.loading = true)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.actions$.pipe(
      ofActionCompleted(ItineraryAction.AddItineraryDay),
      filter((completion: ActionCompletion<ItineraryAction.AddItineraryDay>) => completion.result.successful),
      tap(() => (this.loading = false)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
