<feature-card actionCard actionButton toggleFavorite="adventures" type="amusement" [id]="adventure.id" [starRating]="adventure?.tourData?.overallRating" [featureCardHover]="adventure" [ngClass]="{ horizontal: horizontalCard$ | async }" *ngFor="let adventure of (service.adventures$ | async)?.slice(0, 4);" (click)="service.searchResultDialog.open(adventure, 'adventure')" [@opacityFade]>

  <itinerary-action-button [active]="!!(service.searchPageControl.activityDate$ | async) && (adventure.id | validateStatus: (service.actDate$ | async))" actionPanel>
    <itinerary-action [activityId]="adventure.id" actionPanel></itinerary-action>
  </itinerary-action-button>
  <img featureCardImage [lazyLoad]="adventure?.thumbnail || ''" [defaultImage]="('adventure' | searchSvgPath | async)!" [errorImage]="('adventure' | searchSvgPath | async)!" [alt]="adventure.name">
  <feature-card-price [currency]="adventure.tourData?.price?.currency" [amount]="adventure?.tourData?.price?.amount"></feature-card-price>
  <feature-card-title class="amusement">{{ adventure.name }}</feature-card-title>
  <feature-card-subtitle>{{ adventure | searchResultLocation }}</feature-card-subtitle>
  <feature-card-description [description]="adventure?.tourData?.tourAbstract || adventure?.imgDesc"></feature-card-description>
  <feature-card-rating-reviews>{{ adventure?.tourData?.numRating || 0 }}</feature-card-rating-reviews>
  <feature-card-duration [duration]="adventure?.duration"></feature-card-duration>

</feature-card>

<ng-container *ngIf="!(service.adventures$ | async)">

  <ngx-skeleton-loader class="search-result-card horizontal" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
  </ngx-skeleton-loader>

</ng-container>

<ng-container *ngIf="(service.adventures$ | async)?.length === 0">
<p class="ht-no-results" translate="search-page.no-results-found"></p>
</ng-container>
