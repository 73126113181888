import { Location } from "@angular/common";

import { currentLang, getWindowRef, SearchLocationData } from "@hiptraveler/common";

interface LocationObject {
  country: string;
  formattedAddr: string;
  id: string;
  location: string;
};

export function toLocationObject(location?: SearchLocationData): LocationObject {
  return {
    id: location?.locId || '',
    location: location?.name || '',
    country: location?.country || '',
    formattedAddr: location?.location || ''
  };
}

export function syncPageTitle(location: Location, pageTitle?: string): void {

  let newPageUrl = '';

  if (getWindowRef()?.location?.pathname?.includes('itinerary') && pageTitle) {
    newPageUrl = `/${currentLang()}/compose/itinerary/${pageTitle}`;
  }

  if (getWindowRef()?.location?.pathname?.includes('travel-story') && pageTitle) {
    newPageUrl = `/${currentLang()}/compose/travel-story/${pageTitle}`;
  }

  newPageUrl && setTimeout(() => {
    location.replaceState(newPageUrl);
  }, 500);
}
