<ng-container *ngIf="defaultImage">

<div class="experience-finder-host mat-elevation-z6" [ngClass]="{ processing: (state.processing$$ | async), active: (state.overlayState$ | async) }">

  <ng-container *ngIf="service.dialogView as dialogView">
    <ng-container *ngTemplateOutlet="
      dialogView === 'auth-menu' ? authMenuView 
      : dialogView === 'reg-menu' ? registrationMenuView 
      : defaultMenuView">
    </ng-container>
  </ng-container>

  <!-- Registration menu view -->
  <ng-template #registrationMenuView>
    <registration-menu></registration-menu> 
  </ng-template>

  <!-- Auth menu view -->
  <ng-template #authMenuView>
    <auth-menu [defaultImage]="defaultImage"></auth-menu>
  </ng-template>

  <!-- Default dialog menu view -->
  <ng-template #defaultMenuView>

    <!-- Main menu view -->
    <ng-container *ngIf="service.dialogView === 'main-menu';else selectionView">

      <!-- Authenticated view -->
      <!-- Only display when app is not a widget -->
      <main-menu [defaultImage]="defaultImage" *ngIf="(service.authenticated$ | async) || !service.isWidget"></main-menu>
      
      <!-- Not-authenticated view -->
      <!-- Only display when app is a widget -->
      <ng-container *ngIf="!(service.authenticated$ | async) && service.isWidget">
        <selection-view>
          <home-menu [defaultImage]="defaultImage"></home-menu>
        </selection-view>
      </ng-container>

    </ng-container>

    <ng-template #selectionView>
      <selection-view [ngSwitch]="service.dialogView">
        <!-- Travel style view -->
        <travel-style *ngSwitchCase="'travel-style'"></travel-style>
        <!-- Plan trip view -->
        <plan-trip [defaultImage]="defaultImage" *ngSwitchCase="'plan-trip'"></plan-trip>
      </selection-view>
    </ng-template>

  </ng-template>

  <!-- Brand info request pending UX -->
  <request-pending-skeleton></request-pending-skeleton>    

</div>

</ng-container>
