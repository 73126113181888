import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { MeetingPointComponent } from './meeting-point.component';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  MeetingPointComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    MeetingPointComponent
  ]
})
export class MeetingPointModule { }
