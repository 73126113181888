import { ChangeDetectorRef, Component, Input } from '@angular/core';

import { AuthorData, CoverImage } from '@hiptraveler/data-access/api';
import { StateChange } from 'ng-lazyload-image';

@Component({
  selector: 'cover-image',
  template: `
    <div class="cover-image">

      <p class="cover-image--title">{{ title }}</p>
      <p class="cover-image--attribution" [innerHTML]="coverImage?.attribution"></p>
      <div class="cover-image--overlay" [style]="'background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(' + coverImage?.imgUrl || 'assets/img/blank.webp' + ')'" [ngClass]="{ 'loaded': !loader }"></div>

      <div class="cover-image--skeleton" [ngClass]="{ 'loading': loader }">
        <ngx-skeleton-loader count="1" appearance="line" animation="pulse" [theme]="{ height: '100%', 'border-radius': 0 }"></ngx-skeleton-loader>
      </div>
      
      <img [lazyLoad]="coverImage?.imgUrl!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [ngClass]="{ 'loaded': !loader }" (onStateChange)="lazyloadStateChangeFn($event)">
        
    </div>
  `,
  styleUrls: ['./cover-image.component.scss']
})
export class CoverImageComponent {

  @Input() title?: string;
  @Input() coverImage?: Partial<CoverImage>;
  @Input() author?: AuthorData;

  loader: boolean = false;

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  lazyloadStateChangeFn(event: StateChange): void {
    switch (event.reason) {
      case 'setup':
      case 'observer-emit':
      case 'start-loading': {
        this.loader = true;
        break;
      }
      case 'mount-image':
      case 'loading-succeeded':
      case 'loading-failed': {
        this.loader = false;
        break;
      }
    }
    this.cdRef.detectChanges();
  }

}
