export type DataSelectionKey = 'travel_style' | 'activities' | 'location' | 'calendar' | 'client_info';

export interface DataSelection {
  key: DataSelectionKey;
  head: string;
  info: string;
  icon: string;
  display: 'block' | 'none';
}

export const DATA_SELECTIONS: DataSelection[] = [
  {
    key: 'travel_style',
    head: 'Pick Your Travel Style',
    info: 'Choose a travel style that suits you best! Tap here to explore options.',
    icon: 'explore',
    display: 'none'
  },
  {
    key: 'activities',
    head: 'Choose Your Favorite Activities',
    info: 'Select the activities you enjoy most on your trips. Tap here to add them!',
    icon: 'star',
    display: 'none'
  },
  {
    key: 'location',
    head: 'Decide Where You\'re Headed',
    info: 'Where would you like to go? Tap here to pick your dream destination.',
    icon: 'place',
    display: 'block'
  },
  {
    key: 'calendar',
    head: 'Set Your Travel Dates',
    info: 'Choose when you’d like to travel. Tap here to pick your dates!',
    icon: 'date_range',
    display: 'block'
  },
  {
    key: 'client_info',
    head: 'Add Travelers for the Trip',
    info: 'Who’s joining you? Tap here to add fellow travelers.',
    icon: 'group',
    display: 'block'
  }
]
