import { ChangeDetectorRef, Directive, HostListener, Input, NgModule, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom, takeUntil, tap } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { AppListenerService, pendingAuthProcessKey } from '@hiptraveler/common';
import { DialogType, SearchResultDialogStateService } from '../common';

@Directive({
  selector: '[toggleFavorite]'
})
export class ToggleFavoriteDirective implements OnDestroy {

  @Input() image: ImageResultData;
  @Input() hotel: HotelResultData;
  @Input() dialogType: DialogType;

  authHandle$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private stateService: SearchResultDialogStateService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }

  @HostListener('click')
  async hostClick(): Promise<void> {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'communityPageFollowByResultData';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.hostClick());
      return;
    }

    const userId = this.store.selectSnapshot(UserState.id) || '';
    setTimeout(() => this.cdRef.detectChanges());

    if (this.dialogType === 'hotel') {
      const userLikes = this.store.selectSnapshot(ItineraryState.hotelInformation)?.likes || [];
      if (this.stateService.favoriteProcessing$$.value || !this.hotel.id || !userId) return;
  
      try {
        this.stateService.favoriteProcessing$$.next(true);
        await firstValueFrom(this.store.dispatch(new ItineraryAction.ToggleLikeItinerary({
          id: this.hotel.id,
          userId: userId.slice(0, 7),
          hipType: userLikes?.map(e => e.slice(0, 7)).includes(userId.slice(0, 7)),
          field: ''
        }, 'hotel')));
      } catch (response) {
      } finally {
        this.stateService.favoriteProcessing$$.next(false);
        setTimeout(() => this.cdRef.detectChanges());
      }
      return;
    }

    const userLikes = this.store.selectSnapshot(ItineraryState.imageInformation)?.userLikes || [];
    if (this.stateService.favoriteProcessing$$.value || !this.image.id || !userId) return;

    try {
      this.stateService.favoriteProcessing$$.next(true);
      await firstValueFrom(this.store.dispatch(new ItineraryAction.ToggleLikeItinerary({
        id: this.image.id,
        userId: userId.slice(0, 7),
        hipType: userLikes.map(e => e.slice(0, 7)).includes(userId.slice(0, 7)),
        field: ''
      }, 'image')));
    } catch (response) {
    } finally {
      this.stateService.favoriteProcessing$$.next(false);
      setTimeout(() => this.cdRef.detectChanges());
    }
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}

@NgModule({
  declarations: [ ToggleFavoriteDirective ],
  exports:      [ ToggleFavoriteDirective ]
})
export class ToggleFavoriteDirectiveModule { }
