import { AuthenticateSocialMediaDto, ResetPasswordDto, SignInDto, SignOutDto, SignUpDto } from "@hiptraveler/data-access/api";

export class SetAccessToken {
  static readonly type = '[Auth State] SetAccessToken';
  constructor(public accessToken: string) { }
}

export class Register {
  static readonly type = '[Auth State] Register';
  constructor(public data: SignUpDto) { }
}

export class Login {
  static readonly type = '[Auth State] Login';
  constructor(public data: SignInDto) { }
}

export class RefreshSessionWithCookies {
  static readonly type = '[Auth State] RefreshSessionWithCookies';
  constructor(public cId?: string) { }
}

export class SignOut {
  static readonly type = '[Auth State] SignOut';
  constructor() { }
}

export class SignOutNoAuth {
  static readonly type = '[Auth State] SignOutNoAuth';
  constructor() { }
}

export class AuthenticateWithFacebook {
  static readonly type = '[Auth State] AuthenticateWithFacebook';
  constructor(public data: AuthenticateSocialMediaDto) { }
}

export class AuthenticateWithGoogle {
  static readonly type = '[Auth State] AuthenticateWithGoogle';
  constructor(public data: AuthenticateSocialMediaDto) { }
}

export class ForgotPassword {
  static readonly type = '[Auth State] ForgotPassword';
  constructor(public data: ResetPasswordDto) { }
}

export class VerifyOtpCode {
  static readonly type = '[Auth State] VerifyOtpCode';
  constructor(public token: string, public otpCode: string) { }
}

export class ChangePassword {
  static readonly type = '[Auth State] ChangePassword';
  constructor(public token: string, public pass: string) { }
}

export class GetUserDetails {
  static readonly type = '[Auth State] GetUserDetails';
  constructor() { }
}
