<div class="navbar--brand-logo" [ngClass]="{ buttons: service.hasButtons$ | async }">
  
  <button mat-icon-button [matMenuTriggerFor]="languageMenu" *ngIf="!(hiptravelerBrand$ | async) && (brandCampaign$ | async)">
    <mat-icon>language</mat-icon>
  </button>

  <mat-menu #languageMenu="matMenu">
    <button mat-menu-item *ngFor="let languageSupport of ('' | languageSupport | async);" (click)="changeLanguage.emit(languageSupport.langCode)">
      {{ languageSupport.language }}
    </button>
  </mat-menu>

  <img campaignLogo [lazyLoad]="(service.logo$ | async)!" [defaultImage]="logoPlaceholder" [errorImage]="logoPlaceholder" alt="logo" width="157" height="auto" [routerLink]="translateService.currentLang" [ngStyle]="{ opacity: (service.brandInfo$ | async) ? '1' : '0' }">

  <navbar-actions *ngIf="mobileView && (service.hasButtons$ | async)"></navbar-actions>

</div>
