import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ActionButtonComponent } from './action-button.component';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule
];

const COMPONENTS = [
  ActionButtonComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActionButtonComponent
  ]
})
export class ActionButtonModule { }
