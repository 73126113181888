import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ItineraryFeatureCardComponent } from './itinerary-feature-card.component';
import { FeatureCardComponents, FeatureCardExportComponents } from './itinerary-feature-card-ui';
import { FeatureCardDirectives, FeatureCardExportDirectives } from './itinerary-feature-card-directive';
import { HalfRatingPipe, ParseCurrencyPipe } from './feature-card-pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  ItineraryFeatureCardComponent
];

const PIPES = [
  ParseCurrencyPipe,
  HalfRatingPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    FeatureCardComponents,
    FeatureCardDirectives,
    PIPES
  ],
  exports: [
    ItineraryFeatureCardComponent,
    FeatureCardExportComponents,
    FeatureCardExportDirectives
  ]
})
export class ItineraryFeatureCardModule { }
