import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";

import { MainMenuComponent } from './main-menu.component';
import { NewTravelogueComponent } from './new-travelogue/new-travelogue.component';
import { PlanATripComponent } from './plan-a-trip/plan-a-trip.component';

const MODULES = [
  CommonModule,
  RouterModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  MainMenuComponent,
  NewTravelogueComponent,
  PlanATripComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    MainMenuComponent
  ]
})
export class MainMenuModule { }
