import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { ResultActionsModule } from './result-actions/result-actions.module';
import { ResultDetailsModule } from './result-details/result-details.module';
import { ResultContentModule } from './result-content/result-content.module';
import { ResultDetailsOverlayModule } from './result-details-overlay/result-details-overlay.module';
import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';
import { ScrollElementBorderDirectiveModule } from '@hiptraveler/common';

import { SearchResultDialogComponent } from './search-result-dialog.component';
import { SearchResultActionDirective } from './common';

const MODULES = [
  CommonModule,
  MatDialogModule
];

const LIBRARIES = [
  ButtonsModule,
  DialogCloseButtonModule,
  ResultActionsModule,
  ResultDetailsModule,
  ResultContentModule,
  ResultDetailsOverlayModule,
  MobileNavbarViewModule,
  ScrollElementBorderDirectiveModule
];

const COMPONENTS = [
  SearchResultDialogComponent
];

const DIRECTIVES = [
  SearchResultActionDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ]
})
export class SearchResultDialogModule { }
