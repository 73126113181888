import { filter, includes } from "lodash";

import { BrandState } from "@hiptraveler/data-access/brand";
import { getWindowRef, globalStateManagementStoreKey } from "@hiptraveler/common";
import { CarouselScreenValue, FormValue, parseBrandState, SummaryItems } from "..";

interface Option {
  data: SummaryItems;
  index: number;
  formValue: FormValue
}

interface ParsedClientInfoData {
  label: string;
  state: boolean;
}

function parsedClientInfoData(data: any): ParsedClientInfoData {
  const [ _, label ] = data?.['output']?.toLowerCase()?.split(' ');
  return { label, state: data?.['source'] === 'clientInfo' };
}

export function syncRemovedSummaryItems(option: Option, callback: (form: Partial<FormValue> | null) => void): void {

  const target = option.data![option.index];
  const carouselScreen = target?.['carouselData'] as CarouselScreenValue;
  const { carouselData, clientInfo } = { ...option.formValue };
  const { label, state } = parsedClientInfoData(target);

  if (state) {
    callback({ clientInfo: { ...clientInfo, [label]: 0 } });
  }

  if (carouselScreen) {

    const brandState = getWindowRef()[globalStateManagementStoreKey].selectSnapshot(BrandState.state);
    const value = parseBrandState(brandState)?.experiences || [];
    
    const filteredCarouselData = 
      filter(value, (obj: any) => includes(carouselData[carouselScreen], obj.code))
      .filter(e => e.name !== target['output'])
      .map((e: any) => e.code);
  
    callback({ carouselData: { ...carouselData, [carouselScreen]: filteredCarouselData } });
  }

}
