import { Directive, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, map, take, tap } from 'rxjs';

import { BrandCampaign, CampaignLogo } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { subdomain } from '@hiptraveler/common';
import { NavbarBrandLogoService } from './navbar-brand-logo.service';

@Directive({
  selector: '[campaignLogo]'
})
export class CampaignLogoDirective implements OnInit {

  constructor(
    private elementRef: ElementRef<HTMLImageElement>,
    private store: Store,
    private service: NavbarBrandLogoService
  ) { }

  ngOnInit(): void {
    this.setImageTag_SRC();
  }

  setImageTag_SRC(): void {

    if (subdomain() === 'hiptraveler') {
      this.elementRef.nativeElement.src = 'assets/img/logos/hiptraveler-logo-full-default.webp';
      this.service.logo$$.next(this.elementRef.nativeElement.src);
      return;
    }

    const fallbackImage = 'assets/img/blank-white.webp';
    
    this.elementRef.nativeElement.src = fallbackImage;

    this.store.select(BrandState.brandCampaign).pipe(
      filter(Boolean),
      map((brandCampaign: Partial<BrandCampaign>) => brandCampaign.cLogo),
      filter(Boolean),
      tap((campaignLogo: Partial<CampaignLogo>) => {
        this.service.logo$$.next(campaignLogo?.headerImg || fallbackImage);
        this.elementRef.nativeElement.style.width = `${this.calculateImageWidth(campaignLogo.width, campaignLogo.height)}px`;
        this.elementRef.nativeElement.style.height = 'auto';
      }),
      take(1)
    ).subscribe();
  }

  private calculateImageWidth(width?: number, height?: number): number{

    if (!width || !height) return 157;

    const scale = width / height;
    let scale_height = 0;

    if(scale <2) {
      scale_height = 50 / height;
      scale_height = Math.round( scale_height * 10) / 10;
    } else if(scale>2 && scale<3.5) {
      scale_height = 35 / height;
      scale_height = Math.round( scale_height * 10) / 10;
    } else if(scale >3.5 && scale<5) {
      scale_height = 30 / height;
      scale_height = Math.round( scale_height * 10) / 10;
    } else {
      scale_height = 20 / height;
      scale_height = Math.round( scale_height * 10) / 10;
    }

    return width*scale_height
  }

}
