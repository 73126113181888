import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, filter, map, startWith } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { currentLang } from '@hiptraveler/common';
import { ListItem, amenities, cuisine } from '../list-items';

const search_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink' ];
const name_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink', 'community' ];
const community_allowedRoutes = [ 'community' ];
const travelStyle_allowedRoutes = [ 'experiences', 'search', 'thingstodo', 'hotels', 'foodanddrink' ];
const filterActions_allowedRoutes = [ 'thingstodo', 'hotels', 'foodanddrink' ];

export type CheckboxLabel = 'Cuisine' | 'Activities' | 'Amenities' | 'List';

@Injectable()
export class ViewService {

  constructor(
    private store: Store,
    private router: Router
  ) { }

  get listItems$(): Observable<{ display: ListItem[], data: ListItem[], activities: ListItem[] }> {
    const listItemsByPath = (route: string): { display: ListItem[], data: ListItem[], activities: ListItem[] } => {
      const activities = (this.store.selectSnapshot(BrandState.experiences) || []).map((experience: Partial<Experience>) => ({
        type: experience?.category === 'activity' ? 'activities' : experience?.category === 'experience' ? 'exp' : 'travelStyle',
        name: experience?.name || '',
        value: experience?.code || ''
      })) as ListItem[];
      const path = route?.split('/')?.[2]?.split('?')?.[0]?.split('#')?.[0];
      switch (path) {
        case 'foodanddrink': return {
          activities: activities.concat(cuisine), data: cuisine,
          display: cuisine.slice(0, 5)
        };
        case 'hotels': return {
          activities: activities.concat(amenities), data: amenities,
          display: amenities.slice(0, 5)
        };
        case 'thingstodo': return {
          activities, data: activities,
          display: activities.filter(e => e.type === 'activities').slice(0, 5)
        };
        default: return {
          activities, data: activities,
          display: activities.filter(e => e.type === 'activities').slice(0, 5)
        };
      }
    };
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => listItemsByPath(event.url?.split('/')?.[2])),
      startWith(listItemsByPath(this.router.url))
    );
  }

  get searchVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => search_allowedRoutes.some(e => event?.url?.split('/')?.[2]?.includes(e))),
      startWith(search_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get nameVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => name_allowedRoutes.some(e => event?.url?.split('/')?.[2]?.includes(e))),
      startWith(name_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get communityVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => community_allowedRoutes.some(e => event.url.split('/')[2]?.includes(e))),
      startWith(community_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get filterActionsVisibility$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => filterActions_allowedRoutes.some(e => event.url.split('/')[2]?.includes(e))),
      startWith(filterActions_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e)))
    );
  }

  get travelStyleVisibility$(): Observable<boolean> {
    const route = this.router.url?.split('?')?.[0]?.split('#')?.[0];
    const value = travelStyle_allowedRoutes.some(e => this.router.url?.split('/')?.[2]?.includes(e))
    const initialValue = route === `/${currentLang()}` || value;
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => travelStyle_allowedRoutes.some(e => event.url?.split('/')?.[2]?.includes(e))),
      startWith(initialValue)
    );
  }

  get checkboxLabel$(): Observable<CheckboxLabel> {
    const labelByPath = (route: string): CheckboxLabel => {
      const path = route?.split('/')?.[2]?.split('?')?.[0]?.split('#')?.[0];
      switch (path) {
        case 'foodanddrink': return 'Cuisine';
        case 'thingstodo':   return 'Activities';
        case 'hotels':       return 'Amenities';
        default:             return 'List';
      }
    };
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => labelByPath(event.url)),
      startWith(labelByPath(this.router.url))
    );
  }

}
