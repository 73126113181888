import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { MyTripsComponent } from './my-trips/my-trips.component';

export const MY_TRIPS_DIALOG_ID = 'experience-finder-my-trips';

@Injectable()
export class ExperienceFinderMyTripsDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<MyTripsComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(MY_TRIPS_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(MyTripsComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: MY_TRIPS_DIALOG_ID,
      panelClass: MY_TRIPS_DIALOG_ID + '-overlay-panel',
      backdropClass: MY_TRIPS_DIALOG_ID + '-backdrop',
      width: '100vw',
      maxWidth: 'auto',
      minWidth: 0,
      height: 'calc(100vh - 70px)',
      scrollStrategy: this.overlay.scrollStrategies.noop() 
    });
  }

}
