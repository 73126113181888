import { SetUserActionDto } from '@hiptraveler/data-access/api';

export class NavigateToExternalLinkEvent {
  static readonly type = '[Event State] NavigateToExternalLinkEvent ';
  constructor(public payload: SetUserActionDto) { }
}

export class ExecuteShareEvent {
  static readonly type = '[Event State] ExecuteShareEvent ';
  constructor(public payload: SetUserActionDto) { }
}
