import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';

import { ItinerariesDataService, skeletons } from '@hiptraveler/modules/search-page-root';
import { RequestCancellationService, profilePlaceholder } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  viewProviders: [ ItinerariesDataService ],
  animations: [ opacityFade(), opacityFadeIn() ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperiencesComponent implements OnInit, OnDestroy {

  profilePlaceholder: string = profilePlaceholder;
  skeletons: number[] = skeletons;

  constructor(
    private cdRef: ChangeDetectorRef,
    public service: ItinerariesDataService,
    private requestCancellation: RequestCancellationService
  ) { }

  ngOnInit(): void {
    this.changeDetectionObserver();
    this.service.initializeDataAccess();
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
    this.requestCancellation.cancelAllSearchRequests();
  }

  private changeDetectionObserver(): void {
    this.service.itineraries$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.itineraries$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.itineraries$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.itineraries$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
  }

}
