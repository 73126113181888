import { Component, Input } from '@angular/core';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { DialogType } from '../common';

@Component({
  selector: 'result-details',
  template: `
    <ng-container [ngSwitch]="dialogType">
      <ng-container *ngSwitchCase="'adventure'">

        <activity-details [data]="image" [dialogType]="dialogType"></activity-details>

      </ng-container>
      <ng-container *ngSwitchCase="'hotel'">

        <highlight-select *ngIf="noHighlight !== ''"></highlight-select>
        <price-offers [data]="hotel"></price-offers>
        <policy-details [data]="hotel" *ngIf="hotel.checkInTime && hotel.checkOutTime"></policy-details>
        <amenity-details [data]="hotel" *ngIf="hotel.amenities.length"></amenity-details>

      </ng-container>
      <ng-container *ngSwitchCase="'food'">

        <activity-details [data]="image" [dialogType]="dialogType"></activity-details>

      </ng-container>
    </ng-container>
  `,
  host: { class: 'result-details' }
})
export class ResultDetailsComponent {

  @Input() image: ImageResultData;
  @Input() hotel: HotelResultData;
  @Input() dialogType: DialogType;
  @Input() noHighlight: string;

}
