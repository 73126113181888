import { Directive, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, delay, filter, takeUntil } from 'rxjs';

import { ScrollListenerService } from '@hiptraveler/common';

@Directive({
  selector: '[appScrollToViewListener]'
})
export class ScrollToViewListenerDirective implements OnInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private scrollListener: ScrollListenerService
  ) { }

  ngOnInit(): void {
    this.scrollPositionListener();
    this.fragmentObserver();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  scrollPositionListener(): void {
    this.scrollListener.scrollPosition$.pipe(filter(e => !e)).subscribe(() => {
      this.router.navigate([]);
    });
  }

  fragmentObserver(): void {
    this.route.fragment.pipe(
      filter(Boolean),
      delay(1000),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  scrollToViewByFragment(fragment: string): void {
    if (isPlatformServer(this.platformId)) return;
    this.document.getElementById(fragment)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

}
