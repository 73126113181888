<ng-container *ngIf="service.hotelInformation$ | async">

  <div class="highlight--itinerary-action-bubble" [@opacityFade]>
    <itinerary-action-bubble [active]="(data?.id | validateStatus | async)!">
      <itinerary-action [activityId]="data?.id || ''" [hotel]="dialogType === 'hotel'"></itinerary-action>
    </itinerary-action-bubble>
  </div>

</ng-container>

<div class="highlight--active ht-dialog-elevation">

  <ng-container *ngIf="control.mapViewDisplayState$ | async;else carouselView">
    <div leafletMap id="search-result-dialog-carousel-map" [data]="data"></div>
  </ng-container>

  <ng-template #carouselView>

    <ng-container *ngIf="(service.hotelInformation$ | async)?.imgs?.length">
      <carousel-button left (click)="nextImage('prev')" [@opacityFade]></carousel-button>
    </ng-container>

    <div
      class="highlight--active-favorite" matRipple toggleFavorite [hotel]="data!" [dialogType]="dialogType"
      [class.active]="dialogType === 'hotel' ? (stateService.hotelFavoriteActive$ | async) : (stateService.favoriteActive$ | async)"
      [class.pending]="dialogType === 'hotel' ? !(stateService.hotelInformation$ | async) : !(stateService.imageInformation$ | async) || (stateService.favoriteProcessing$ | async)">
      <mat-icon svgIcon="common.favorite-svg"></mat-icon>
      <ng-container *ngIf="!(dialogType === 'hotel' ? !(stateService.hotelInformation$ | async) : !(stateService.imageInformation$ | async))">
        <mat-progress-spinner class="primary" diameter="34" mode="indeterminate" *ngIf="stateService.favoriteProcessing$ | async"></mat-progress-spinner>
      </ng-container>
    </div>

    <asset-image [sourceImg]="(stateService.selectedHighlight$ | async) || data?.imgUrl || ''" bannerImage></asset-image>
    
    <ng-container *ngIf="(service.hotelInformation$ | async)?.imgs?.length">
      <carousel-button right (click)="nextImage('next')" [@opacityFade]></carousel-button>
    </ng-container>

  </ng-template>

  <div class="highlight--active-details">
    <h1>{{ data?.name }}</h1>
  
    <star-ratings [rating]="data?.starRating || 0" [size]="27"></star-ratings>
  
    <ng-container *ngIf="service.hotelInformation$ | async as hotelInformation;else loadingAddress">
  
      <h2>{{ hotelInformation.location }}</h2>
      
      <h3>
        <ng-container *ngIf="hotelInformation.distance">
          {{ 'search-result.value-miles-from-city-center' | translate: { value: hotelInformation.distance } }}&nbsp;
        </ng-container>
        <span translate="search-result.map" (click)="control.toggleDisplayMap()"></span>
      </h3>
  
    </ng-container>
    <ng-template #loadingAddress>
      
      <ngx-skeleton-loader first appearance="line" *ngIf="visibility"></ngx-skeleton-loader>
      <ngx-skeleton-loader last appearance="line" *ngIf="visibility"></ngx-skeleton-loader>

    </ng-template>
  </div>

</div>
