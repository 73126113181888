import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { StarRatingsComponent } from './star-ratings.component';
import { CalculateRatingPipe } from './calculate-rating.pipe';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  StarRatingsComponent
];

const PIPES = [
  CalculateRatingPipe
];


@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    StarRatingsComponent
  ]
})
export class StarRatingsModule { }
