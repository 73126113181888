import { Component, Input, ViewEncapsulation } from '@angular/core';

import { AuthMenuService } from './auth-menu.service';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { SocialMediaService, isWidget } from '@hiptraveler/common';

@Component({
  selector: 'auth-menu',
  templateUrl: './auth-menu.component.html',
  styleUrls: ['./auth-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ AuthMenuService, AuthDialogActionService, SocialMediaService ]
})
export class AuthMenuComponent {

  @Input() defaultImage: string;

  constructor(
    public service: AuthMenuService,
    public stateService: ExperienceFinderStateService,
    public authDialog: AuthDialogActionService,
    public socialMedia: SocialMediaService
  ) { }

  createAccount(): void {
    if (isWidget()) {
      this.stateService.dialogView$$.next('reg-menu');
    } else {
      this.authDialog.open('register')
    }
  }  

}
