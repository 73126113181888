import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, firstValueFrom } from 'rxjs';

import { TravelAgentData } from '@hiptraveler/data-access/api';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { AppListenerService, SearchLocationService, SearchPageControlStateService, brandCampaignId } from '@hiptraveler/common';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { DataAccess, DataService } from './data.service';

@Injectable()
export class TravelAgentsService extends DataService implements DataAccess {

  constructor(
    private store: Store,
    public searchPageControl: SearchPageControlStateService,
    public searchResultDialog: SearchResultDialogActionService,
    appListener: AppListenerService,
    searchLocation: SearchLocationService,
    leafletControl: LeafletMapControlStateService
  ) {
    super(appListener, searchLocation, leafletControl);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get travelAgents$(): Observable<TravelAgentData[] | null> {
    return this.store.select(SearchState.travelAgents)
  }

  async initializeDataAccess(): Promise<void> {
    await this.getBrandCampaign(this.store);
    try {

      await firstValueFrom(this.store.dispatch(new SearchAction.GetTravelAgentsContent({
        cId: brandCampaignId(), brand: 'cAgents', page: 1
      })));

    } catch (error) { }
  }

}
