import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom, map, takeUntil, tap } from 'rxjs';

import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { NavbarButtonData, NavbarControlStateService, RequestCancellationService, getWindowRef } from '@hiptraveler/common';
import { BlogDataAccessService, preventRemoveKey, ViewCommon } from '@hiptraveler/features/itinerary';

@Injectable()
export class CommonService extends ViewCommon {

  constructor(
    private store: Store,
    private requestCancellation: RequestCancellationService,
    private navbarControl: NavbarControlStateService,
    private dataAccess: BlogDataAccessService
  ) {
    super();
  }

  destroyComponentState(): void {
    this.subscription$.next();
    this.focusMode('off');
    this.dataAccess.subscription$.next();
    this.navbarControl.setActiveNavbarButtons([]);
    this.requestCancellation.cancelAllRequests();
    this.resetState();
  }

  focusMode(mode: 'on' | 'off'): void {
    this.navbarControl.navbarElevationState$$.next(mode === 'on');
    this.navbarControl.navbarToolbarVisibility$$.next(mode === 'off');
    this.navbarControl.navbarSearchBarVisibility$$.next(true);
    this.navbarControl.navbarToolbarPosition$$.next('none');
    if (mode === 'off') return;
    this.initializeNavbarButtons();
  }

  private initializeNavbarButtons(): void {

    const defaultItineraryActions: NavbarButtonData[] = [
      { name: 'navbar.action-text-save-trip', size: 'large', action: 'saveThisTrip', responsiveIcon: 'save' }
    ];

    const itineraryOwnerActions: NavbarButtonData[] = [
      { icon: 'print', action: 'viewTripCreatePDF' },
      { name: 'navbar.action-text-edit', size: 'small', theme: 'outlined', action: 'travelStoryEdit', responsiveIcon: 'edit', responsiveText: 'navbar.action-text-edit' },
      { name: 'navbar.action-text-publish', size: 'small', action: 'travelStoryPublish', responsiveIcon: 'upload', responsiveText: 'navbar.action-text-publish' }
    ];

    this.owner$.pipe(
      map((owner: boolean) => {
        owner && (getWindowRef()[preventRemoveKey] = undefined);
        return owner ? itineraryOwnerActions : defaultItineraryActions
      }),
      tap(navbarActions => this.navbarControl.setActiveNavbarButtons(navbarActions)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  async resetState(): Promise<void> {
    if (getWindowRef()[preventRemoveKey]) return;
    await firstValueFrom(this.store.dispatch(new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap' ])));
  }

}
