<mat-form-field [formGroup]="form">
  <mat-label translate="custom-activity-dialog.section.form.enter-offer"></mat-label>
  <textarea matInput [placeholder]="'custom-activity-dialog.section.form.enter-offer' | translate" type="text" rows="8" name="offer" formControlName="offer"></textarea>
</mat-form-field>

<mat-form-field [formGroup]="priceForm">
  <mat-label translate="custom-activity-dialog.section.form.enter-price"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-price' | translate" type="number" name="amount" formControlName="amount">
</mat-form-field>

<div class="form-field-action">

  <mat-form-field [formGroup]="durationForm">
    <mat-label translate="custom-activity-dialog.section.form.enter-duration"></mat-label>
    <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-duration' | translate" type="number" name="value" formControlName="value">
  </mat-form-field>

  <div class="form-field-action--select">
    <p class="form-field-action--select-label">
      {{ selectedDuration | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </p>
    <mat-form-field>
      <mat-select>
        <mat-option (click)="changeDuration('minute')" translate="custom-activity-dialog.section.form.enter-minutes"></mat-option>
        <mat-option (click)="changeDuration('hour')" translate="custom-activity-dialog.section.form.enter-hours"></mat-option>
        <mat-option (click)="changeDuration('day')" translate="custom-activity-dialog.section.form.enter-days"></mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>

<mat-form-field [formGroup]="form">
  <mat-label translate="custom-activity-dialog.section.form.enter-opening-hours"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-opening-hours' | translate" type="text" name="opening-hours" formControlName="openingHours">
</mat-form-field>

<mat-form-field [formGroup]="form">
  <mat-label translate="custom-activity-dialog.section.form.enter-closing-hours"></mat-label>
  <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-closing-hours' | translate" type="text" name="closing-hours" formControlName="closingHours">
</mat-form-field>
