import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter, map, switchMap, takeUntil, tap } from 'rxjs';

import { FiltersService } from './../filters.service';
import { AppListenerService, currentLang } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';
import * as Common from '../common';

@Component({
  selector: 'filters-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  host: { class: 'search-page-root-filters-mobile' },
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class FiltersMobileComponent implements OnInit {

  subscription$ = new Subject<void>();
  minDate = new Date().toISOString();

  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    public service: FiltersService,
    public appListener: AppListenerService,
    public view: Common.ViewService,
    public filtersService: Common.FiltersStateService
  ) { }

  ngOnInit(): void {

    this.updateComponentView(this.router.url);

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => this.updateComponentView(event.url)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.appListener.clientWidth$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.updateComponentView(this.router.url);
    });
  }

  private updateComponentView(route: string): void {
    const value = route.includes(`${currentLang()}/community`) ? 'none' : 'block';
    this.element.nativeElement.style.display = this.nonMobileView ? 'none' : value; 
  }

  get selectedLength$(): Observable<number> {
    return this.filtersService.selectedTravelStyleKeys$.pipe(
      switchMap((travelStyles: string[]) => this.filtersService.selectedItemKeys$.pipe(
        map((items: string[]) => travelStyles.length + items.length)
      ))
    );
  }

  get listPlaceholder$(): Observable<string> {
    const count$ = this.selectedLength$.pipe(
      map((count: number) => count ? `(${count})` : '')
    );
    return this.view.checkboxLabel$.pipe(
      switchMap((label: string) => count$.pipe(
        map((countLabel: string) => `${label} ${countLabel}`)
      ))
    );
  }

  private get nonMobileView(): boolean {
    return this.appListener.clientWidth$$.value > 520;
  }

}
