import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, map, skip, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { DialogType } from '../../common';

@Directive({
  selector: '[imageLayout]'
})
export class ImageLayoutDirective implements AfterViewInit {

  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;

  @Input() dialogType: DialogType;

  constructor(
    private store: Store,
    private element: ElementRef<HTMLElement>
  ) { }

  ngAfterViewInit(): void {

    this.store.select(ItineraryState.imageInformation)?.pipe(
      skip(1),
      map((result: ImageResultData | null) => {
        if (this.dialogType === 'adventure') {
          return !result?.imgAuthInfo && !result?.photos?.length;
        } else if (this.dialogType === 'food') {
          return !result?.yelpData?.yelpUrl && !result?.photos?.length;
        }
        return !result?.imgAuthInfo && !result?.photos?.length;
      }),
      take(1),
      filter(Boolean)
    ).subscribe(() => {
      const element = this.element.nativeElement;
      element.style.marginBottom = '0';
      if (element?.parentElement && element?.parentElement?.parentElement) {
        element.parentElement.parentElement.style.borderBottomLeftRadius = '4px';
        element.parentElement.parentElement.style.borderBottomRightRadius = '4px';
      }
    });
  }

}
