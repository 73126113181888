import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';

import { ExpFinderPanelAction, expFinderPanelAction } from '@hiptraveler/features/exp-finder-ai';
import { ExperienceFinderV2Service } from './experience-finder-v2.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-experience-finder-v2',
  template: `
    <button mat-raised-button color="primary" (click)="openExperienceFinderPanel()">Open Experience Finder</button>
    <ng-template #experienceFinder></ng-template>
  `,
  styles: [`
    .page-experience-finder-ai {
      position: fixed;
      height: 100dvh;
      width: 100dvw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `],
  host: { class: 'page-experience-finder-ai' },
  viewProviders: [ ExperienceFinderV2Service ],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderV2Component implements OnInit, AfterViewInit {

  @ViewChild('experienceFinder', { read: ViewContainerRef }) experienceFinderRef: ViewContainerRef;

  panelAction: ExpFinderPanelAction;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private service: ExperienceFinderV2Service
  ) { }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.panelAction?.closeExperienceFinder();
    }
    if (event.code === 'Space') {
      this.openExperienceFinderPanel();
    }
  }

  ngOnInit(): void {
    this.service.initialize();
  }
  
  ngAfterViewInit(): void {
    this.panelAction = expFinderPanelAction();
    this.openExperienceFinderPanel();
  }
  
  openExperienceFinderPanel(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.panelAction?.openExperienceFinderPanel(this.experienceFinderRef);
  }

}
