import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { map, tap } from 'rxjs';

import { SidenavService } from './sidenav.service';
import { AppListenerService } from '@hiptraveler/common';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  host: { class: 'page-profile-sidenav' },
  viewProviders: [ SidenavService ],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public appListener: AppListenerService,
    public service: SidenavService
  ) { }

  ngOnInit(): void {
    this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth <= 700),
      tap((state: boolean) => {
        this.element.nativeElement.style.display = state ? 'none' : 'block';
      })
    ).subscribe();
  }

}
