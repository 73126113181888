import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Output, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngxs/store';

import { SearchState } from '@hiptraveler/data-access/search';
import { SectionContent } from '../section-content';

@Component({
  selector: 'experiences-content',
  templateUrl: './experiences-content.component.html',
  styleUrls: ['../search.component.scss']
})
export class ExperiencesContentComponent extends SectionContent implements AfterViewInit {

  @Output() actionClicked = new EventEmitter<string>();

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private element: ElementRef<HTMLElement>,
    private store: Store
  ) {
    super(platformId);
  }

  ngAfterViewInit(): void {
    this.checkElementState(this.element.nativeElement, this.store.select(SearchState.itineraries));
  }

}
