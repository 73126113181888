import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SearchLocationData } from '@hiptraveler/common';

@Component({
  selector: 'main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.scss'],
  host: { class: 'page-profile-custom-activity-dialog-main-info' },
  encapsulation: ViewEncapsulation.None
})
export class MainInfoComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup
  ) { }

  async addressLocationChanges(locationData: SearchLocationData): Promise<void> {
    this.form.patchValue({ address: locationData?.location });
    this.form.get('locationMap')?.patchValue({
      city: locationData?.name,
      country: locationData?.country,
      id: locationData?.locId
    });
  }

  async cityLocationChanges(locationData: SearchLocationData): Promise<void> {
    this.form.get('locationMap')?.patchValue({
      city: locationData?.name
    });
  }

  async countryLocationChanges(locationData: SearchLocationData): Promise<void> {
    this.form.get('locationMap')?.patchValue({
      country: locationData?.country
    });
  }

}
