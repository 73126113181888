import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject, delay, filter, take, takeUntil, tap } from 'rxjs';

import { TravelAgent, RegistrationFormDestination } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { PATCH_DELAY, RegistrationFormComponent, RegistrationFormStateService } from '@hiptraveler/features/registration-form';
import { ProfileStateService } from '../../../common';
import { UserSettingsService } from '../user-settings.service';

@Injectable()
export class ProfessionalProfileFormService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private stateService: ProfileStateService,
    private userSettings: UserSettingsService,
    public taFormStateService: RegistrationFormStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.taFormStateService.form.reset();
    this.taFormStateService.socialForm.reset();
    this.taFormStateService.membershipForm.reset();
    this.taFormStateService.trExpForm.reset();
    this.taFormStateService.categoriesForm.clear();
    this.taFormStateService.subCategoriesForm.clear();
    this.taFormStateService.destinationsForm.clear();
    this.taFormStateService.langForm.clear();
  }

  observeSubmitByFormComponent(registerForm: RegistrationFormComponent): void {
    this.stateService.saveClicked$$.asObservable().pipe(
      takeUntil(this.subscription$)
    ).subscribe(async () => {
      this.userSettings.formProcessing$$.next(true);
      await registerForm.service.registerInterest();
      this.userSettings.formProcessing$$.next(false);
    });
  }

  patchInitialFormValues(): void {
    this.taFormStateService.submitProcessing$$.next(true);
    this.store.select(ProfileState.travelAgent).pipe(
      filter(Boolean),
      delay(PATCH_DELAY),
      tap((travelAgent: TravelAgent) => {
        this.taFormStateService.submitProcessing$$.next(false);
        const user = this.store.selectSnapshot(UserState.state)!;
        this.taFormStateService.form.patchValue({
          id: travelAgent.id,
          type: [ 'htAgent', 'htOperator', 'other' ].includes(user.userType) ? user.userType : 'htAgent',
          firstName: travelAgent.firstName,
          lastName: travelAgent.lastName,
          email: travelAgent.email,
          location: travelAgent.location,
          social: user.connectWith,
          certId: travelAgent.agentId || '',
          certType: travelAgent.idType || ''
        });

        travelAgent.categories?.forEach((item: string) => {
          this.taFormStateService.categoriesForm.push(new FormControl(item));
        });
        travelAgent.subCategories?.forEach((item: string) => {
          this.taFormStateService.subCategoriesForm.push(new FormControl(item));
        });
        travelAgent.languages?.forEach((item: string) => {
          this.taFormStateService.langForm.push(new FormControl(item));
        });
        travelAgent.destinations?.forEach((item: RegistrationFormDestination) => {
          this.taFormStateService.destinationsForm.push(this.fb.group(item));
        });

        this.taFormStateService.membershipForm.patchValue(travelAgent.subscription);
        this.taFormStateService.trExpForm.patchValue({ desc: travelAgent.desc });
      }),
      take(1)
    ).subscribe();
  }

}
