import { Injectable, Provider } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, catchError, filter, switchMap, tap } from 'rxjs';

import { ErrorResponse } from '@hiptraveler/data-access/api';
import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AuthHelperService, HipTravelerWebStateModel as State } from '@hiptraveler/common';

const ignoredEndpoints: string[] = [
  '/assets/i18n',
  'googleads',
  '/api/auth/login/',
  '/api/auth/register/',
  '/api/auth/refreshToken/',
  '/api/auth/loginx'
];

@Injectable()
export class _AuthInterceptor implements HttpInterceptor {

  constructor(
    private store: Store,
    private authHelper: AuthHelperService
  ) { }

  private get jwtHelper(): JwtHelperService {
    return new JwtHelperService();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const accessToken = this.store.selectSnapshot(AuthState.accessToken);
    const ignoredEndpoint = ignoredEndpoints.some(e => request.url.includes(e));

    if (!accessToken || ignoredEndpoint) {
      return next.handle(request);
    }

    if (this.jwtHelper.isTokenExpired(accessToken)) {
      return this.store.select(BrandState.brandCampaign).pipe(
        filter(Boolean),
        switchMap(({ cId }) => this.store.dispatch(new AuthAction.RefreshSessionWithCookies(cId))),
        tap(() => {
          console.log('@@@ jwtHelper.isTokenExpired ::', 'AuthAction.RefreshSessionWithCookies');
        }),
        switchMap((state: State) => next.handle(this.requestAuthHeader(request, state.state_auth.accessToken!))),
        catchError((value: ErrorResponse) => {
          this.authHelper.handleExpiredToken(value);
          return next.handle(request);
        })
      );
    }

    return next.handle(this.requestAuthHeader(request, accessToken));
  }

  private requestAuthHeader(request: HttpRequest<unknown>, accessToken: string): HttpRequest<unknown> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
  }

}

export const AuthInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _AuthInterceptor,
  multi: true,
}
