import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollToViewDirectiveModule } from '@hiptraveler/common';

import { StepperHeaderComponent } from './stepper-header.component';
import { OverlayVariableWidthDirective } from './overlay-variable-width.directive';
import { HeaderValueByIndexPipe } from './header-value-by-index.pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  TranslateModule
];

const LIBRARIES = [
  ScrollToViewDirectiveModule
];

const COMPONENTS = [
  StepperHeaderComponent
];

const DIRECTIVES = [
  OverlayVariableWidthDirective
];

const PIPES = [
  HeaderValueByIndexPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    StepperHeaderComponent
  ]
})
export class StepperHeaderModule { }
