import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { SocialMediaService } from '@hiptraveler/common';

@Component({
  selector: 'auth-dialog-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  viewProviders: [ SocialMediaService ]
})
export class LoginComponent {

  @Output() createAccountClicked = new EventEmitter();
  @Output() forgotPasswordClicked = new EventEmitter();
  
  constructor(
    public dialogRef: MatDialogRef<any>,
    public socialMedia: SocialMediaService
  ) { }

}
