
import { Directive, ElementRef, OnInit } from '@angular/core';

import { CampaignCustomCss } from '@hiptraveler/data-access/api';
import { CustomStyles } from '@hiptraveler/common';

@Directive({
  selector: '[customStyles]'
})
export class CustomStylesDirective extends CustomStyles implements OnInit {

  constructor(
    private element: ElementRef<HTMLFormElement>
  ) {
    super();
  }

  ngOnInit(): void {

    this.brandCampaign((customCss: Partial<CampaignCustomCss>) => {

      const topLeftRadius = customCss.borderTopLeftRadius;
      if (!topLeftRadius) return;

      const children = Array.from(this.element.nativeElement.getElementsByClassName('overlay-trip-planning--form-field')) as HTMLElement[];
      children.forEach((element: HTMLElement) => {
        element.style.borderTopLeftRadius = topLeftRadius;
      });
    });
  }

}
