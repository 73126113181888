import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationFormModule } from '@hiptraveler/features/registration-form';

import { ProfessionalProfileFormComponent } from './professional-profile-form.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  RegistrationFormModule
];

const COMPONENTS = [
  ProfessionalProfileFormComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ProfessionalProfileFormComponent
  ]
})
export class ProfessionalProfileFormModule { }
