import * as API from "@hiptraveler/data-access/api";
import { NavbarControlStateService, currentLang } from "@hiptraveler/common";

export function setActiveNavbarButtons(response: API.AddItineraryResponse, navbarControl: NavbarControlStateService): void {

  const pageTitle = response.data?.itiMap?.basicInfo?.pageTitle || response.data?.itiMap?.pageTitle;

  navbarControl.setActiveNavbarButtons([{
    name: 'navbar.action-text-open-trip',
    url: `${currentLang()}/compose/itinerary/${pageTitle}`,
    closeButton: true
  }]);
}
