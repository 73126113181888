import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'action-button',
  template: `
    <div
      class="actions-button" matRipple
      [ngStyle]="{
        transform: 'translateY('+transformY+'px)',
        margin: '0 '+marginY+'px'
      }">

      <ng-container *ngIf="stateService.actionProcessing$ | async;else default">
        <mat-spinner mode="indeterminate" diameter="20" strokeWidth="3"></mat-spinner>
      </ng-container>
      <ng-template #default>
        <p><ng-content></ng-content></p>
      </ng-template>

    </div>
  `,
  styleUrls: ['./action-button.component.scss'],
  host: { class: 'action-button' },
  encapsulation: ViewEncapsulation.None
})
export class ActionButtonComponent {

  @Input() transformY: number;
  @Input() marginY: number;

  constructor(
    public stateService: ExperienceFinderStateService
  ) { }

}
