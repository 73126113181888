import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ExperienceFinderScreenModule, ExperienceFinderMobileModule } from '../experience-finder-screen';

import { ExperienceFinderComponent } from './experience-finder.component';
import { BubbleComponent } from './bubble/bubble.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatDialogModule,
  MatProgressSpinnerModule
];

const LIBRARIES = [
  ExperienceFinderScreenModule,
  ExperienceFinderMobileModule
];

const COMPONENTS = [
  ExperienceFinderComponent,
  BubbleComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ExperienceFinderComponent
  ]
})
export class ExperienceFinderModule { }
