import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, filter, map, switchMap, take } from 'rxjs';

import { BasicInfoData, BrandCampaign, LocationData } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { UserState } from '@hiptraveler/data-access/user';
import { CDNReaderService } from '@hiptraveler/common';

@Injectable()
export class TravelDetailsService implements OnDestroy {

  @Select(UserState.field('userLoc')) userLoc$: Observable<string | null>;
  @Select(ItineraryState.basicInfo) basicInfo: Observable<BasicInfoData>;

  private locationList$$ = new BehaviorSubject<LocationData[]>([]);
  locationList$: Observable<LocationData[]> = this.locationList$$.asObservable();

  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    private cdnReader: CDNReaderService
  ) { }

  get travelImage$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.appCommon').pipe(
      switchMap((response: any) => this.store.select(BrandState.brandCampaign).pipe(
        map((brandCampaign: Partial<BrandCampaign> | null) => {
          return brandCampaign?.campaignCustom?.customCss?.travelSvg || response['flight'];
        })
      ))
    )
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }
  
  /**
   * Initializes the service's state by fetching initial data.
   */
  initializeState(): void {
    this.store.select(ItineraryState.basicInfo).pipe(
      filter(Boolean),
      map(e => this.locationList$$.next(e?.locationList || [])),
      take(1)
    ).subscribe();
  }

  /**
   * Returns an Observable emitting a partial BasicInfoData object.
   */
  get basicInfo$(): Observable<Partial<BasicInfoData>> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      filter(Boolean),
      take(1)
    );
  }

}
