import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as AuthApi from './interfaces';
import { handleErrorAndThrow } from '@hiptraveler/data-access/api';
import { brandCampaignId, ClientSource, clientSource, clientVID } from '@hiptraveler/common';
import { encryptSignInPayload } from './auth-encrypt';

interface AuthPayload {
  vId: string | null;
  cId: string;
  source: ClientSource;
};

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(
    private http: HttpClient
  ) { }

  private get authPayload(): Omit<AuthPayload, 'cId'> {
    return { vId: clientVID(), source: clientSource() };
  }

  private get authBrandPayload(): AuthPayload {
    return { ...this.authPayload, cId: brandCampaignId() };
  }

  refreshSessionWithCookies(cId?: string): Observable<AuthApi.SessionResponse> {
    return this.http.post<AuthApi.SessionResponse>(`/api/auth/refreshToken/${cId || brandCampaignId()}`, {}).pipe(
      tap(handleErrorAndThrow)
    );
  }

  register(payload: AuthApi.SignUpDto): Observable<AuthApi.AuthResponse> {
    payload = { ...payload, ...this.authPayload };
    return this.http.post<AuthApi.AuthResponse>(`/api/auth/register/${brandCampaignId()}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  login(payload: AuthApi.SignInDto): Observable<AuthApi.AuthResponse> {
    payload = { ...payload, ...this.authPayload };
    return this.http.post<AuthApi.AuthResponse>(`/api/auth/login/${brandCampaignId()}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  loginx(payload: AuthApi.SignInDto): Observable<AuthApi.AuthResponse> {
    const { vId, source } = this.authPayload;
    const encryptedPayload = encryptSignInPayload({ ...payload, vId, source });
    return this.http.post<AuthApi.AuthResponse>(`/api/auth/loginx/${brandCampaignId()}`, encryptedPayload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  signOut(): Observable<AuthApi.SignOutResponse> {
    return this.http.post<AuthApi.SignOutResponse>(`/api/auth/signout`, this.authBrandPayload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  authenticateWithFacebook(payload: AuthApi.AuthenticateSocialMediaDto): Observable<AuthApi.AuthResponse> {
    payload = { ...payload, ...this.authBrandPayload };
    return this.http.post<AuthApi.AuthResponse>(`/api/auth/login/facebook`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  authenticateWithGoogle(payload: AuthApi.AuthenticateSocialMediaDto): Observable<AuthApi.AuthResponse> {
    payload = { ...payload, ...this.authBrandPayload };
    return this.http.post<AuthApi.AuthResponse>(`/api/auth/login/google`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  forgotPassword(payload: AuthApi.ResetPasswordDto): Observable<AuthApi.ForgotPasswordResponse> {
    return this.http.post<AuthApi.ForgotPasswordResponse>(`/api/auth/getrpotp/${brandCampaignId()}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  verifyOtpCode(payload: AuthApi.VerifyOtpCodeDto): Observable<AuthApi.VerifyOtpCodeResponse> {
    return this.http.post<AuthApi.VerifyOtpCodeResponse>(`/api/auth/verifyrpotp/${brandCampaignId()}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  updatePassword(payload: AuthApi.UpdatePasswordDto): Observable<AuthApi.UpdatePasswordResponse> {
    return this.http.post<AuthApi.UpdatePasswordResponse>(`/api/auth/updatePassword/${brandCampaignId()}`, payload);
  }

  changePassword(payload: AuthApi.ChangePasswordDto): Observable<AuthApi.ChangePasswordResponse> {
    return this.http.post<AuthApi.ChangePasswordResponse>(`/api/auth/resetrp/${brandCampaignId()}`, payload).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getUserDetails(): Observable<AuthApi.UserDetailsResponse> {
    const params = { vId: `${clientVID()}`, source: clientSource() };
    return this.http.get<AuthApi.UserDetailsResponse>(`/api/auth/userdetails/${brandCampaignId()}`, { params }).pipe(
      tap(handleErrorAndThrow)
    );
  }

}
