import { Directive, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { flatten } from 'lodash';
import { filter, takeUntil } from 'rxjs';

import { PlaceLocation, UserProfile } from '@hiptraveler/data-access/api';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { LeafletMap, LeafletMapControlStateService, toGeoJSON } from '@hiptraveler/features/leaflet-map';
import { AppListenerService, SearchResultData } from '@hiptraveler/common';

@Directive({
  selector: '[leafletMap]'
})
export class ProfilePageDirective extends LeafletMap implements OnInit, OnDestroy {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private appListener: AppListenerService,
    router: Router,
    elementRef: ElementRef<HTMLElement>,
    leafletControl: LeafletMapControlStateService
  ) {
    super(router, elementRef, leafletControl);
  }

  ngOnInit(): void {

    if (isPlatformServer(this.platformId)) return;
   
    this.appListener.mapVisibilityState$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.setupMap();
    });
  }

  setupMap(): void {

    this.setMap();

    this.store.select(ProfileState.profileDetails).pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe((response: UserProfile) => {
    
      const location: any = [ response.currLat, response.currLng ];
      const data: any = { location: response.userLoc };

      this.setMapView(location, 3);
      this.setMarker({ location, data, icon: this.markerIcon.mapMarkerHighlightIcon });

      if (!response?.placesVisited) return

      const result: SearchResultData[] = flatten(Object.values(response.placesVisited)).map((place: PlaceLocation) => {
        return {
          id: '', title: '', address: {}, image: '',
          location: {
            type: 'Point',
            coordinates: [ place.latLng?.B || 0, place.latLng?.k || 0 ]
          },
          locationName: place.location,
          type: 'map'
        }
      });

      this.setGeoJSON(toGeoJSON(result), this.markerIcon.mapMarkerIcon);
    });
  }

}
