<div class="destination-specialties">

  <p class="section-title" translate="reg-form.dest-spec"></p>

  <ng-container *ngIf="(service.locations$ | async)?.length;else formUI">

    <div class="form-checkboxes">
      <mat-checkbox [checked]="location | locationSelected | async" (click)="service.checkboxChanges(i, element.checked)" *ngFor="let location of service.locations$ | async;let i = index" #element>{{ location.city }}</mat-checkbox>
    </div>    

  </ng-container>
  <ng-template #formUI>

    <mat-form-field underlined appearance="outline" customMapAutocomplete emitOnly position="absolute-relative" [cmaTopOffset]="65" (locationChanged)="locationChanges($event)">
      <mat-progress-spinner class="primary" mode="indeterminate" strokeWidth="3" diameter="20" *ngIf="searchLocation.disabled"></mat-progress-spinner>
      <mat-label>{{ 'reg-form.location' | translate }}</mat-label>
      <input matInput [placeholder]="'reg-form.placeholder-add-loc' | translate" type="text" name="location" autocomplete="off" #searchLocation>
    </mat-form-field>

    <div class="destination-specialties--" [class.disabled]="searchLocation.disabled">
      <div *ngFor="let destination of service.form.value?.destinations;">
        <button mat-icon-button (click)="service.removeAppendLocation(destination.id)"><mat-icon>close</mat-icon></button>
        <p>{{ destination?.location }}</p>
      </div>
    </div>

  </ng-template>

</div>
