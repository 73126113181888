import { Location } from "@angular/common";
import { currentLang } from "@hiptraveler/common";

export function replaceComposeUrlByNewItineraryResponse(location: Location, newPageTitle: string): void {
  // const pattern = /\/`${currentLang()}`\/compose\/itinerary\/[^/]+/;
  const pattern = new RegExp(`/${currentLang()}/compose/itinerary/[^/]+`);
  const modifiedUrl = location.path().replace(pattern, `/${currentLang()}/compose/itinerary/${newPageTitle}`);
  location.replaceState(modifiedUrl);
}

export function replaceUrlByNewItineraryResponse(location: Location, newPageTitle?: string): void {
  if (!newPageTitle) return;
  const pattern = new RegExp(`/${currentLang()}/itinerary/[^/]+`);
  const modifiedUrl = location.path().replace(pattern, `/${currentLang()}/itinerary/${newPageTitle}`);
  location.replaceState(modifiedUrl);
}
