import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

import { FiltersComponent2 } from './filters.component';
import { CustomStylesDirective } from './custom-styles.directive';
import { FilterCategoryPipe } from './filter-category.pipe';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  TranslateModule
];

const COMPONENTS = [
  FiltersComponent2
];

const DIRECTIVES = [
  CustomStylesDirective
];

const PIPES = [
  FilterCategoryPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    FiltersComponent2
  ]
})
export class FiltersModule { }
