import { Injectable } from '@angular/core';

import { ClientInfoStateService } from '../client-info-state.service';
import { ClientInfo } from '../client-info.model';

@Injectable()
export class ClientActionService {

  constructor(
    private clientInfoService: ClientInfoStateService
  ) { }

  get clientData(): ClientInfo[] {
    return this.clientInfoService.clientData$$.value;
  }

  addCount(index: number): void {
    const clientData = [ ...this.clientData ];
    clientData[index].count += 1;
    this.clientInfoService.clientData$$.next(clientData)
  }

  removeCount(index: number): void {
    const clientData = [ ...this.clientData ];
    clientData[index].count -= 1;
    this.clientInfoService.clientData$$.next(clientData)
  }

}
