import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateRating'
})
export class CalculateRatingPipe implements PipeTransform {

  transform(rating?: number): number[] {
    const result = [0, 0, 0, 0, 0];
    if (!rating) return result;
    if (rating === 1) {
      result[0] = 1;
      return result;
    } else if (rating > 1 && rating < 2) {
      result[0] = 1; result[1] = 2;
      return result;
    } else if (rating === 2) {
      result[0] = 1; result[1] = 1;
      return result;
    } else if (rating > 2 && rating < 3) {
      result[0] = 1; result[1] = 1; result[2] = 2;
      return result;
    } else if (rating === 3) {
      result[0] = 1; result[1] = 1; result[2] = 1;
      return result;
    } else if (rating > 3 && rating < 4) {
      result[0] = 1; result[1] = 1; result[2] = 1; result[3] = 2;
      return result;
    } else if (rating === 4) {
      result[0] = 1; result[1] = 1; result[2] = 1; result[3] = 1;
      return result;
    } else if (rating > 4 && rating < 5) {
      result[0] = 1; result[1] = 1; result[2] = 1; result[3] = 1; result[4] = 2;
      return result;
    } else if (rating === 5) {
      result.fill(1);
      return result;
    }
    return result;
  }

}
