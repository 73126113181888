import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateParam'
})
export class TranslateParamPipe implements PipeTransform {

  transform(value: string, param: any): string {
    return value.replace('{{value}}', param?.toString());
  }

}

@NgModule({
  declarations: [ TranslateParamPipe ],
  exports:      [ TranslateParamPipe ]
})
export class TranslateParamPipeModule { }

