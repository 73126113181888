import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, map } from 'rxjs';

import { AppListenerService, NavbarControlStateService } from '@hiptraveler/common';

@Component({
  selector: 'navbar-actions',
  templateUrl: './navbar-actions.component.html',
  styleUrls: ['./navbar-actions.component.scss', './navbar-actions-responsiveness.scss'],
  host: { class: 'site-navigation-navbar-actions' },
  encapsulation: ViewEncapsulation.None
})
export class NavbarActionsComponent {

  pendingButton?: string;

  constructor(
    private appListener: AppListenerService,
    public navbarControl: NavbarControlStateService,
  ) { }

  get responsiveIcon$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      // 900px to 700px
      map((clientWidth: number) => clientWidth <= 900 && clientWidth >= 700)
    );
  }

  get responsiveText$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      // Less than 700px
      map((clientWidth: number) => clientWidth < 700)
    );
  }

}
