import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Actions, Store, ofActionCompleted } from '@ngxs/store';
import { Observable, filter, map, switchMap, take, takeUntil } from 'rxjs';
import { Request } from 'express';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { WINDOW, currentLang, decodeHTMLEntities } from '@hiptraveler/common';
import * as Feature from '@hiptraveler/features/meta-tag';

@Injectable()
export class SearchAndDefaultPageObserverService {

  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(WINDOW) private window: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private action$: Actions,
    private metaTagService: Feature.MetaTagService
  ) { }

  get originalUrl(): string {
    return this.request?.originalUrl || this.window.location.pathname;
  }

  get initialConfig$(): Observable<Feature.ObserverConfig> {
    return this.config$.pipe(
      filter(() => !this.store.selectSnapshot(SearchState.locationData))
    );
  }

  get defaultConfig$(): Observable<Feature.ObserverConfig> {
    return this.config$.pipe(
      map(e => ({ ...e, url: e.url.replace(`/${Feature.getLocationByPath(e.url)}`, '') }))
    );
  }

  get rootConfig$(): Observable<Feature.ObserverConfig> {
    return this.store.select(BrandState.brandCampaign).pipe(
      filter(Boolean),
      map((brandCampaign: Partial<BrandCampaign>) => (<Feature.ObserverConfig>{
        lang: currentLang(),
        url: this.window.location.pathname,
        metadata: {
          title: brandCampaign?.cHashtag || Feature.defaultMetaTagData.title,
          description: brandCampaign.cTagline || Feature.defaultMetaTagData.description,
          imageUrl: brandCampaign?.cCoverImg?.cover || Feature.defaultMetaTagData.imageUrl
        }
      })),
      takeUntil(this.metaTagService.subscription$)
    );
  }

  get locationChanges$(): Observable<Feature.Metadata | null> {
    return this.action$.pipe(
      ofActionCompleted(SearchAction.GetLocationDataByQuery),
      filter(e => e.result.successful),
      switchMap(() => this.store.select(SearchState.locationData).pipe(take(2)) || null),
      map(locationData => (locationData ? {
        imageUrl: locationData?.coverImg?.imgUrl,
        description: decodeHTMLEntities(locationData?.description || Feature.defaultMetaTagData.description),
        callback: {
          location: locationData?.city
        }
      } as Feature.Metadata : locationData)),
      takeUntil(this.metaTagService.subscription$)
    );
  }

  private get config$(): Observable<Feature.ObserverConfig> {
    return this.metaTagService.imgUrl$.pipe(
      map((imageUrl: string) => {
        
        const serverConfigUrl = this.originalUrl.split('?')[0].split('#')[0];
        const serverConfig = { url: serverConfigUrl, lang: Feature.serverLang(serverConfigUrl) };
        const browserConfig = { url: this.window.location.pathname, lang: currentLang() };
        const config = isPlatformServer(this.platformId) ? serverConfig : browserConfig;

        return { metadata: { imageUrl }, ...config } as Feature.ObserverConfig;
      }),
      takeUntil(this.metaTagService.subscription$)
    )
  }

}
