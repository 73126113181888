import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject, delay, filter, map } from 'rxjs';

import { ListItem } from '../list-items';
import { WINDOW } from '@hiptraveler/common';

export function param(value: string): string {
  switch (value) {
    case 'thingstodo':   return 'activities';
    case 'hotels':       return 'amenities';
    case 'foodanddrink': return 'cuisine';
    default:             return 'activities';
  }
}

export function paramObject(values: any, listItems: any): any {
  const result: any = {};
  values.forEach((value: any) => {
    const matchedItem = listItems.find((item: any) => item.value === value);
    if (matchedItem) {
      const type = matchedItem.type === 'exp' ? 'experiences' : matchedItem.type;
      result[type] = result[type] || [];
      result[type].push(matchedItem.value);
    }
  });
  return result;
}

@Injectable()
export class  FiltersStateService {

  panelFilterApplyClicked$$ = new Subject<void>();
  panelFilterClearClicked$$ = new Subject<void>();

  travelDatesCloseActionState$$ = new BehaviorSubject<boolean>(false);
  
  searchPage$$ = new BehaviorSubject<string>('');
  searchPage$ = this.searchPage$$.asObservable().pipe(filter(Boolean),delay(0));

  selectedItems$$ = new BehaviorSubject<ListItem[]>([]);
  selectedItemKeys$ = this.selectedItems$$.asObservable().pipe(map(e => e.map(x => x.value)));
  get selectedItems(): ListItem[] { return this.selectedItems$$.value }
  updateSelectedItems(listItems: ListItem[]): void {
    this.selectedItems$$.next([ ...this.selectedItems$$.value, ...listItems ]);
  }
  
  selectedTravelStyles$$ = new BehaviorSubject<ListItem[]>([]);
  selectedTravelStyleKeys$ = this.selectedTravelStyles$$.asObservable().pipe(map(e => e.map(x => x.value)));
  get selectedTravelStyles(): ListItem[] { return this.selectedTravelStyles$$.value }
  updateSelectedTravelStyles(listItems: ListItem[]): void {
    this.selectedTravelStyles$$.next([ ...this.selectedTravelStyles$$.value, ...listItems ]);
  }

  constructor(
    @Inject(WINDOW) private window: any,
    private location: Location
  ) { }

  clearFilters(): void {
    this.location.replaceState(this.window.location.pathname);
    this.selectedItems$$.next([]);
    this.selectedTravelStyles$$.next([]);
  }

}
