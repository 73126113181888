<ng-container *ngIf="service.basicInfo$ | async as basicInfo">

  <p class="ht-digit">
    <span>{{ (basicInfo?.autoGenerated ? 'view-page.auto' : 'view-page.posted1') | translate }}</span>
    <span>{{ basicInfo?.pubDateString }}</span>
  </p>

  <ng-container *ngIf="basicInfo?.content as display">
    <br>
    <div class="itinerary-page-story-content-text-overview more-content" [innerHTML]="display | storyTextDisplay | safeHtml"></div>
    <p moreContentAction [display]="display" class="itinerary-page-story-content-text-action">more</p>
  </ng-container>

  <ng-container *ngIf="basicInfo?.autoSummary as display">
    <h3 translate="view-page.overview"></h3>
    <div class="itinerary-page-story-content-text-overview more-content" [innerHTML]="display | storyTextDisplay | safeHtml"></div>
    <p moreContentAction label="view-page.overview" [display]="display" class="itinerary-page-story-content-text-action">more</p>
  </ng-container>
  
  <ng-container *ngIf="basicInfo?.travelInfo?.[0] as display">
    <h3 translate="view-page.weather"></h3>
    <div class="itinerary-page-story-content-text-overview more-content" [innerHTML]="display | storyTextDisplay | safeHtml"></div>
    <p moreContentAction label="view-page.weather" [display]="display" class="itinerary-page-story-content-text-action">more</p>
  </ng-container>
  
  <ng-container *ngIf="basicInfo?.travelInfo?.[1] as display">
    <h3 translate="view-page.pack"></h3>
    <div class="itinerary-page-story-content-text-overview more-content" [innerHTML]="display | storyTextDisplay | safeHtml"></div>
    <p moreContentAction label="view-page.pack" [display]="display" class="itinerary-page-story-content-text-action">more</p>
  </ng-container>

</ng-container>
