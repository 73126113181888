import { Component } from '@angular/core';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'map',
  template: `
    <ng-container *ngIf="true">
      <div leafletMap id="search-page-root-map" style="height: calc(100dvh - 160px);" [@opacityFadeIn]></div>
    </ng-container>
  `,
  animations: [ opacityFadeIn(2000) ]
})
export class MapComponent { }
