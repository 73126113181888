import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PageBannerModule } from '@hiptraveler/features/page-banner';
import { ActivityDateListModule, ActivityDayListModule, ComponentStateService, ChecklistModule, ItineraryStateService, TravelDetailsModule, TravelOptionsModule, TravelShareHandlesModule, SkeletonModule } from '@hiptraveler/features/itinerary';
import { ItineraryDateViewMapModule } from '@hiptraveler/features/leaflet-map';
import { StoryContentModule } from './story-content/story-content.module';
import { StoryTravelAgentsModule } from './story-travel-agents/story-travel-agents.module';

import { ItineraryComponent } from './itinerary.component';
import { NavbarActionListenerDirective } from './navbar-action-listener.directive';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  PageBannerModule,
  ActivityDateListModule,
  ActivityDayListModule,
  TravelDetailsModule,
  TravelOptionsModule,
  TravelShareHandlesModule,
  ChecklistModule,
  ItineraryDateViewMapModule,
  StoryContentModule,
  StoryTravelAgentsModule,
  SkeletonModule
];

const PROVIDERS = [
  ItineraryStateService,
  ComponentStateService
];

const COMPONENTS = [
  ItineraryComponent
];

const DIRECTIVES = [
  NavbarActionListenerDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: ItineraryComponent
    }])
  ],
  providers: [
    PROVIDERS
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ]
})
export class ItineraryModule { }
