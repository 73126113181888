import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Subject, delay } from 'rxjs';

import { ITINERARY_ACTION_PANEL_BACKDROP_CLASS, LIST_PANEL_PANEL_BACKDROP_CLASS } from '@hiptraveler/common';

const MUTATION_OBSERVER_CONFIG: MutationObserverInit = { childList: true };

const backdropClasses = [ ITINERARY_ACTION_PANEL_BACKDROP_CLASS, LIST_PANEL_PANEL_BACKDROP_CLASS ];

@Injectable()
export class CdkOverlayObserverService {

  cdkOverlayContainer$ = new Subject<HTMLDivElement>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog
  ) { }

  observe(element: HTMLDivElement): void {

    if (isPlatformServer(this.platformId)) return;

    const bodyElement: HTMLBodyElement | null = element.parentElement?.parentElement as any;
    if (!bodyElement) return;

    const observer = new MutationObserver((mutationsList: MutationRecord[]) => {
      for (let mutation of mutationsList) {
        mutation.type === 'childList' && mutation.addedNodes.forEach((addedNode: Node) => {
          if (!(addedNode as HTMLDivElement)?.classList.contains('cdk-overlay-container')) return;
          setTimeout(() => this.cdkOverlayContainer$.next(addedNode as HTMLDivElement));
        })
      }
    });

    observer.observe(bodyElement, MUTATION_OBSERVER_CONFIG);

    this.cdkOverlayContainer$.pipe(delay(0)).subscribe((cdkOverlay: HTMLDivElement) => {
      observer.disconnect();
      
      const bodyElement: HTMLBodyElement = element.parentElement!.parentElement as any;
      const overlay: HTMLDivElement | null = bodyElement.getElementsByClassName('cdk-overlay-container').item(0) as any;
      if (!overlay) return;

      backdropClasses.forEach((backdropClass: string) => {
  
        if (overlay.getElementsByClassName(backdropClass).item(0)) {
          overlay.style.zIndex = '900';
        } else {
          overlay.style.zIndex = '1000';
        }
  
        const cdkOverlayObserver = new MutationObserver((mutationsList: MutationRecord[]) => {
          for (let mutation of mutationsList) {
            mutation.type === 'childList' && mutation.addedNodes.forEach((addedNode: Node) => {
              if (!(addedNode as HTMLDivElement)?.classList.contains(backdropClass)) return;
              if (this.searchResultDialogActive) return;
              overlay.style.zIndex = '900';
            });
            mutation.type === 'childList' && mutation.removedNodes.forEach((removedNode: Node) => {
              if (!(removedNode as HTMLDivElement)?.classList.contains(backdropClass)) return;
              overlay.style.zIndex = '1000';
            });
          }
        });
  
        cdkOverlayObserver.observe(cdkOverlay, MUTATION_OBSERVER_CONFIG);
      })
    });

  }

  private get searchResultDialogActive(): boolean {
    return !!this.dialog.getDialogById('search-result-dialog');
  }

}
