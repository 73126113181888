import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ItineraryState } from '.';

const STATES = [
  ItineraryState
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ]
})
export class ItineraryStateModule { }
