import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, map } from 'rxjs';

import { FilestackService } from '@hiptraveler/core/filestack';
import { UserInfoService } from './user-info.service';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { AppListenerService, authenticatedProfilePlaceholder, profilePlaceholder } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';
import { ProfileStateService } from '../../common';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss', './user-info-responsiveness.scss'],
  host: { class: 'page-profile-activity-content-user-info' },
  viewProviders: [ UserInfoService, SendMessageDialogActionService, FilestackService, AuthDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class UserInfoComponent {

  profilePlaceholder: string = profilePlaceholder;
  authenticatedProfilePlaceholder: string = authenticatedProfilePlaceholder;

  constructor(
    public appListener: AppListenerService, 
    public service: UserInfoService,
    public stateService: ProfileStateService
  ) { }

  get mobileAction$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth < 1401)
    )
  }

}
