import { ChangeDetectorRef, ElementRef, Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, firstValueFrom } from 'rxjs';
import { findIndex } from 'lodash';

import { LocationData, SearchApiService } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { RegistrationFormStateService } from '../common';
import { RegistrationFormComponent } from '../registration-form.component';
import { SearchLocationData } from '@hiptraveler/common';

@Injectable()
export class DestinationSpecialtiesService {

  @Select(BrandState.locations) locations$: Observable<LocationData[] | null>;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    private searchApi: SearchApiService,
    private stateService: RegistrationFormStateService,
    private root: RegistrationFormComponent
  ) { }

  get form(): FormGroup {
    return this.stateService.form;
  }

  async checkboxChanges(index: number, state: boolean): Promise<void> {
    !state ? this.addLocation(index) : this.removeLocation(index);
  }

  async appendLocation(locationData: SearchLocationData, input: ElementRef<HTMLInputElement>): Promise<void> {

    this.root.submitButton.disabled = true;

    const { locationInfo } = await firstValueFrom(this.searchApi.getLocationDataByQuery({
      q: locationData?.location,
      locId: locationData?.locId,
      format: 'city'
    }));

    this.stateService.destinationsForm.push(this.fb.group({
      id: locationInfo.id,
      type: locationInfo.type,
      country: locationInfo.country,
      formattedAddr: locationInfo.formattedAddr,
      location: locationInfo.city
    }));

    setTimeout(() => {
      input.nativeElement.focus();
      this.root.submitButton.disabled = false;
      this.changeDetectorRef.detectChanges();
    }, 1000);
  }

  removeAppendLocation(id: string): void {
    const formLocations = this.stateService.destinationsForm.value as LocationData[];
    const formLocationsIndex = findIndex(formLocations, { id });
    this.stateService.destinationsForm.removeAt(formLocationsIndex);
  }

  private addLocation(index: number): void {
    const locations  = this.store.selectSnapshot(BrandState.locations) || [];
    const { country, formattedAddr, id, city, type } = locations[index]
    const form = this.fb.group({ country, formattedAddr, id, type, location: city })
    this.stateService.destinationsForm.push(form);
  }

  private removeLocation(index: number): void {
    const locations  = this.store.selectSnapshot(BrandState.locations) || [];
    const formLocations = this.stateService.destinationsForm.value as LocationData[];
    const formLocationsIndex = findIndex(formLocations, { id: locations[index].id })
    this.stateService.destinationsForm.removeAt(formLocationsIndex);
  }

}
