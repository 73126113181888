import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { DriveDurationComponent } from './drive-duration.component';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  DriveDurationComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    DriveDurationComponent
  ]
})
export class DriveDurationModule { }
