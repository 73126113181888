import { Component, AfterViewInit, OnDestroy } from '@angular/core';

import { BlogDataAccessService } from '@hiptraveler/features/itinerary';
import { AppListenerService } from '@hiptraveler/common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-modify-blog',
  template: `
    <ng-container *ngIf="appListener.languageLoadState$ | async">
      <compose-blog-form navbarAction dispatchObserver [@opacityFadeIn]></compose-blog-form>
    </ng-container>
  `,
  viewProviders: [ BlogDataAccessService ],
  animations: [ opacityFadeIn() ]
})
export class ModifyBlogComponent implements OnDestroy, AfterViewInit {

  constructor(
    private dataAccess: BlogDataAccessService,
    public appListener: AppListenerService
  ) { }

  ngAfterViewInit(): void {
    this.dataAccess.getBlogData(this.ngOnDestroy.bind(this));
  }

  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
  }

}
