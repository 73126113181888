import { Meta, Title } from '@angular/platform-browser';
import { Directive } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { Metadata, defaultMetaTagData, serverLocale } from "@hiptraveler/features/meta-tag";
import { AppListenerService, DEFAULT_BRAND_NAME, Environment, subdomain } from '@hiptraveler/common';

@Directive()
export class MetaTag {

  constructor(
    protected platformId: Object,
    protected environment: Environment,
    protected meta: Meta,
    protected titleService: Title,
    protected appListener: AppListenerService
  ) { }

  /**
   * Updates the meta tags for the application based on the provided metadata.
   * This method configures meta tags for various social media platforms and search engines,
   * ensuring that the content is optimized for sharing and SEO.
   *
   * @param {Metadata} metadata - An object containing metadata for the page.
   */
  setTags(metadata: Metadata) {

    metadata = this.parsedMetaTag(metadata);
    this.appListener.metaData$$.next(metadata);

    if (isPlatformServer(this.platformId)) {
      console.log('@@@ metadata', JSON.stringify(metadata, null, 2))
    }
  
    if (isPlatformBrowser(this.platformId) && this.environment.local) {
      // console.log('@@@ metadata', JSON.stringify(metadata, null, 2))
    }
    
    this.searchEngineSEO(metadata);
    this.setCommonMetaTag(metadata);
    this.setTwitterMetaTag(metadata);
    this.setOpenGraphMetaTag(metadata);
    this.setInstagramOpenGraphMetaTag(metadata);
    this.setPinterestOpenGraphMetaTag();
  }

  /**
   * Schema.org JSON-LD
   * 
   * Helps search engines understand the content on your pages 
   * and can improve the way your page is represented in SERPs (Search Engine Results Pages). 
   */
  private searchEngineSEO(metadata: Metadata): void {
    this.meta.updateTag({
      type: 'application/ld+json', content: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": metadata.title,
        "description": metadata.description,
        'image': metadata.imageUrl,
        "url": metadata.url,
        'author': metadata.author || 'HipTraveler',
      })
    });
  }

  /* Common meta tags */
  private setCommonMetaTag(metadata: Metadata): void {

    /* Page title */
    this.titleService.setTitle(metadata.title);

    this.meta.updateTag({ name: 'description', content: metadata.description });
    this.meta.updateTag({ name: 'url', content: metadata.url });
    this.meta.updateTag({ rel: 'canonical', href: metadata.callback?.canonicalUrl || metadata.url });
    this.meta.updateTag({ name: 'copyright', content: `Copyright � ${this.currentYear} HipTraveler Inc.` });

    if (metadata.author) {
      this.meta.updateTag({ name: 'author', content: metadata.author });
    }
    if (metadata.keywords) {
      this.meta.updateTag({ name: 'keywords', content: metadata.keywords });
    }
  }

  /* Twitter meta tags */
  private setTwitterMetaTag(metadata: Metadata): void {

    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:site', content: '@hiptraveler' });
    this.meta.updateTag({ name: 'twitter:title', content: metadata.title });
    this.meta.updateTag({ name: 'twitter:description', content: metadata.description });
    this.meta.updateTag({ name: 'twitter:url', content: metadata.url });

    if (metadata.imageUrl) {
      this.meta.updateTag({ name: 'twitter:image', content: metadata.imageUrl });
    }
  }

  /* Open Graph meta tags (Facebook, Instagram, WhatsApp, Telegram, Viber, Line, LinkedIn and etc.) */
  private setOpenGraphMetaTag(metadata: Metadata): void {

    this.meta.updateTag({ property: 'fb:app_id', content: this.environment.apiKey.facebookAppId });
    this.meta.updateTag({ property: 'og:title', content: metadata.title });
    this.meta.updateTag({ property: 'og:description', content: metadata.description });
    this.meta.updateTag({ property: 'og:url', content: metadata.url });
    this.meta.updateTag({ property: 'og:type', content: metadata.type });
    this.meta.updateTag({ property: 'og:site_name', content: 'HipTraveler' });
    this.meta.updateTag({ property: 'og:locale', content: serverLocale(metadata.url) });
    this.meta.updateTag({ property: 'og:updated_time', content: Date.now().toString() });

    if (metadata.imageUrl) {
      this.meta.updateTag({ property: 'og:image', content: metadata.imageUrl });
      this.meta.updateTag({ property: 'og:image:alt', content: 'HipTraveler Trip Image' });
      this.meta.updateTag({ property: 'og:image:width', content: '1200' });
      this.meta.updateTag({ property: 'og:image:height', content: '630' });
    }
  }

  /* Instagram Open Graph meta tags */
  private setInstagramOpenGraphMetaTag(metadata: Metadata): void {
    this.meta.updateTag({ property: 'al:ios:app_name', content: 'Instagram' });
    this.meta.updateTag({ property: 'al:ios:url', content: `instagram://media?id=${metadata.url}` });
  }

  /* Pinterest meta tags */
  private setPinterestOpenGraphMetaTag(): void {
    this.meta.updateTag({ name: 'pinterest-rich-pin', content: 'true' });
  }

  private get currentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  private parsedMetaTag(value: Metadata): Metadata {

    const metatag = { ...value };
    const domain = this.environment.local ? 'localhost:4200' : 'hiptraveler';
    const topDomain = this.environment.local ? '' : '.com'

    if (metatag.url !== defaultMetaTagData.url) {
      const sub = subdomain() === DEFAULT_BRAND_NAME ? '' : `${subdomain()}.`;
      metatag.url = `http://${sub}${domain}${topDomain}${metatag.url}`;
    } else {
      metatag.url = defaultMetaTagData.url;
    }
    
    metatag.title = decodeURIComponent(metatag.title);

    return metatag;
  }

}
