import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { RegistrationFormStateService, Type, TypeData, professionTypeValues } from '../common';

@Injectable()
export class AgentDetailsService {

  @Select(UserState.state) userState$: Observable<UserStateModel | null>;

  formType: Type = 'travelAgent';
  typeData: TypeData[] = [];

  constructor(
    private store: Store,
    private stateService: RegistrationFormStateService
  ) { }

  get form(): FormGroup {
    return this.stateService.form;
  }

  setInitialTypeData(): void {
    this.typeData = professionTypeValues[`${this.formType}Data`];
  }
  
  importProfile(): void {
    const user = this.store.selectSnapshot(UserState.state);
    if (!user) return
    
    const connectWith = user.connectWith as any
    if (this.formType === 'ambassador') {
      delete connectWith.facebook
      delete connectWith.instagram
      delete connectWith.tiktok
      delete connectWith.twitter
    }
    
    this.form.patchValue({
      type: [ 'htAgent', 'htOperator', 'other' ].includes(user.userType) ? user.userType : 'htAgent',
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      location: user.userLoc,
      social: connectWith
    });
  }

}
