import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FeatureCardModule } from '@hiptraveler/ui/feature-card';
import { SearchSvgPathPipeModule } from '@hiptraveler/common';

import { ThingsToDoComponent } from './things-to-do.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  FeatureCardModule,
  SearchSvgPathPipeModule
];

const COMPONENTS = [
  ThingsToDoComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: ThingsToDoComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ThingsToDoModule { }
