import { Injectable } from '@angular/core';

import { ApiService, getWindowRef, openedWindowPopUp } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class SocialApiPopupService {

  constructor(
    private apiService: ApiService
  ) { }

  openPopupWindow(social: 'fb' | 'tw' | 'insta' | 'tk'): void {
    social === 'tk' && this.setupPopupWindowByUrl('/trip-planner/tk/oauth');
    social === 'tw' && this.setupPopupWindowByUrl('/trip-planner/tw/oauth');
    social === 'fb' && this.setupPopupWindowByUrl('/trip-planner/fb/business/auth');
    social === 'insta' && this.setupPopupWindowByUrl('/trip-planner/insta/auth');
  }

  private setupPopupWindowByUrl(url: string): void {

    url = this.apiService.hosted ? this.apiService.parseUrl(url) : url;

    getWindowRef()[openedWindowPopUp] = getWindowRef()?.open(url, 'popupWindow', this.windowFeatures);
  }

  private get windowFeatures(): string {
    const { width, height } = {width: 600, height: 700 };
    const { left, top } = {
      left: (getWindowRef()?.screen.width - width) / 2,
      top: (getWindowRef()?.screen.height - height) / 2
    };
    return `width=${width},height=${height},scrollbars=yes,left=${left},top=${top}`;
  }

}
