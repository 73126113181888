
<homepage-banner *ngIf="service.homepageState$ | async"></homepage-banner>

<search-banner-actions *ngIf="service.searchPageVisibility$ | async"></search-banner-actions>

<div
  class="search-page-root"
  [class.no-map]="!(service.mapVisibility$ | async)"
  [class.search]="service.searchPageState$ | async"
  [class.homepage]="service.homepageState$ | async"
  [class.search-page]="service.searchPageVisibility$ | async"
  [class.processing]="searchPageControl.featureCardProcessing$ | async">

  <filters class="search-page-root--filters"></filters>
  <filters-mobile class="search-page-root--filters-mobile"></filters-mobile>
  
  <div 
    [class.no-map]="!(service.mapVisibility$ | async)"
    [ngClass]="{
      'search-page-root--router-outlet': !(service.pageOnly$ | async),
      'search-page-root--router-page-outlet': service.pageOnly$ | async
    }">
    <ng-container *ngIf="loadMoreVisibility;else noResultUI">
      <ng-container *ngIf="!(appListener.loadMoreEventState$ | async);else loadMoreButtonUIProgress">
        <ui-action-button (click)="appListener.loadMoreEventState$$.next(true)">{{ 'search-page.load-more' | translate }}</ui-action-button>
      </ng-container>
      <ng-template #loadMoreButtonUIProgress>
        <mat-progress-spinner class="primary" mode="indeterminate"></mat-progress-spinner>
      </ng-template>
    </ng-container>
    <ng-template #noResultUI>
      <div class="search-page-root--router-break"></div>
    </ng-template>
    <router-outlet></router-outlet>
  </div>
  
  <ng-container *ngIf="service.mapVisibility$ | async">
    <map class="search-page-root--map"></map>
  </ng-container>

</div>
