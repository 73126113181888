import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeUrlPipeModule } from '@hiptraveler/common';

import { MediaCardComponent } from './media-card.component';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  MediaCardComponent
];

const LIBRARIES = [
  SafeUrlPipeModule
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    MediaCardComponent
  ]
})
export class MediaCardModule { }
