import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, map, switchMap, takeUntil } from 'rxjs';

import { FiltersStateService } from '../../common';

@Component({
  selector: 'list-button',
  template: `
    <ng-container *ngIf="travelStyle === '';else defaultUI">
      <input customInputBorder type="text" autocomplete="off" name="travel-style" [placeholder]="('search-page.travel-style' | translate) + ' ' + text" readonly>
    </ng-container>
    <ng-template #defaultUI>
      <p><em>{{ 'search-page.show-all' | translate }}</em></p>
    </ng-template>
  `,
  styleUrls: ['./list-button.component.scss']
})
export class ListButtonComponent implements OnInit, OnDestroy {

  @Input() travelStyle: string;

  subscription$ = new Subject<void>();
  text: string;

  constructor(
    public filtersService: FiltersStateService
  ) { }

  ngOnInit(): void {
    this.filtersService.selectedTravelStyleKeys$.pipe(
      switchMap((travelStyles: string[]) => this.filtersService.selectedItemKeys$.pipe(
        map((items: string[]) => {
          const count = travelStyles.length + items.length;
          this.text = count ? `(${count})` : '';
        }),
      )),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
