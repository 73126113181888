import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirm_password');

  const cond = password && confirmPassword && password.value !== confirmPassword.value;

  if (cond) confirmPassword.setErrors({ 'passwordMismatch': true });

  return cond ? { 'passwordMismatch': true } : null;
};
