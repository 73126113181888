import { Inject, Injectable } from '@angular/core';

import { ENVIRONMENT, Environment } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    @Inject(ENVIRONMENT) private env: Environment
  ) { }

  get hosted(): boolean {
    return !this.env.local;
  }

  parseUrl(url: string): string {
    return url
      .replace('/api/auth/', `${this.env.api?.auth!}/`)
      .replace('/trip-planner/', `${this.env.api?.tripPlanner!}/`);
  }

  validateUrl(url: string): boolean {
    return !url.includes(this.env.api?.auth || 'xx0')
      && !url.includes(this.env.api?.tripPlanner || 'xx0')
  }
  
}
