
export type AllowScroll = 'allow' | 'allow-up' | 'allow-down' | 'disable';

export type ScrollDirection = 'up' | 'down';

export type ScreenValue =
  'screen1' | 'screen2' | 'screen3' | 'screen4' | 'screen5' | 'screen6'
  | 'screen7' | 'screen8' | 'screen9' | 'screen10' | 'screen11' | 'screen12';

export type ScreenState = Record<ScreenValue, boolean>;

export type CarouselFilterType = 'experience' | 'travelStyle' | 'activity';

export type CarouselScreenValue = Extract<ScreenValue, 'screen3' | 'screen4' | 'screen5'>;

export type FormCarouselData = Record<CarouselScreenValue, string[]>;

export type SummaryItems = Record<string, any>[] | null;

export interface FormDateRange {
  start: Date | null;
  end: Date | null;
}

export interface FormClientInfo {
  adults: number;
  children: number;
  infants: number;
}

export interface FormValue {
  dateRange: FormDateRange;
  carouselData: FormCarouselData
  clientInfo: FormClientInfo;
}

export enum ScreenView {
  /** Welcome View */
  screen1 = 0,
  /** Main Selection View */
  screen2 = 400,
  /** Travel Carousel View */
  screen3 = 800,
  /** Activities Carousel View */
  screen4 = 1200,
  /** Location Carousel View */
  screen5 = 1600,
  screen5Mobile = 820,
  /** Calendar View */
  screen6 = 2000,
  screen6Mobile = 1000,
  /** Client Info View */
  screen7 = 2400,
  /** Summary View */
  screen8 = 2800,
  /** Screen 9 */
  screen9 = 3200,
  /** Screen 10 */
  screen10 = 3600,
  /** Screen 11 */
  screen11 = 4000,
  /** Screen 12 */
  screen12 = 4400
}
