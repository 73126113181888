import { NgModule } from '@angular/core';

import { CustomInputBorderDirective } from './custom-input-border.directive';

const DIRECTIVES = [
  CustomInputBorderDirective
];

@NgModule({
  declarations: DIRECTIVES,
  exports: [ CustomInputBorderDirective ]
})
export class CustomInputBorderDirectiveModule { }
