
import { Directive, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, filter, map, takeUntil, tap } from 'rxjs';

import { BrandCampaign, CampaignCustomCss } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';

@Directive()
export class CustomStyles implements OnDestroy {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;

  subscription$ = new Subject<void>();

  brandCampaign(callback: (customCss: Partial<CampaignCustomCss>) => void): void {
    this.brandCampaign$.pipe(
      filter(Boolean),
      map(e => e.campaignCustom.customCss),
      filter(Boolean),
      tap((customCss: Partial<CampaignCustomCss>) => callback(customCss)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
