import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { LoginComponent } from './login.component';
import { FormComponent } from './form/form.component';
import { SocialMediaButtonComponent } from './social-media-button/social-media-button.component';
import { FacebookSvgComponent } from './facebook-svg.component';
import { GoogleSvgComponent } from './google-svg.component';
import { OrDividerComponent } from './or-divider/or-divider.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
];

const COMPONENTS = [
  LoginComponent,
  FormComponent,
  SocialMediaButtonComponent,
  FacebookSvgComponent,
  GoogleSvgComponent,
  OrDividerComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
