import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PlanTripService {

  externalViewState$$ = new BehaviorSubject<boolean>(false);
  externalViewState$ = this.externalViewState$$.asObservable();

  processingViewState$$ = new BehaviorSubject<boolean>(false);
  processingViewState$ = this.processingViewState$$.asObservable();

  #itineraryRedirectUrl: string = '';
  set itineraryRedirectUrl(value: string) { this.#itineraryRedirectUrl = value; }
  get itineraryRedirectUrl(): string { return this.#itineraryRedirectUrl; }
  
}
