import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { combineLatest, filter, map, Observable, startWith, Subject, takeUntil } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { currentLang, Environment, ENVIRONMENT, isIframe, isWidget, NavbarControlStateService, WidgetStateService } from '@hiptraveler/common';

@Injectable()
export class SiteNavigationService implements OnDestroy {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<unknown | null>;

  subscription$ = new Subject<void>();

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private router: Router,
    private widgetService: WidgetStateService,
    private navbarControl: NavbarControlStateService,
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get navbarVisibility$(): Observable<boolean> {
    return combineLatest([
      this.widgetService.brandIframeWidget$,
      this.navbarControl.navbarToolbarVisibility$,
      this.rootPage$
    ]).pipe(
      map(([ brandIframeWidget, navbarToolbarVisibility, rootPage ]) => {

        if (!isWidget() && !isIframe() && !brandIframeWidget && rootPage) {
          return true
        }

        return navbarToolbarVisibility || !rootPage;
      }),
      takeUntil(this.subscription$)
    )
  }

  private get rootPage$(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      startWith(this.router.url),
      map(e => e === `/${currentLang()}`)
    );
  }

}
