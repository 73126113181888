
<navbar-view (actionClicked)="stateService.overlayState$$.next(false)">
  <navbar-action (click)="tripPlanningForm && tripPlanningForm.submit()">{{ 'exp-finder.lets-go' | translate }}</navbar-action>
</navbar-view>

<br><br><br>

<p class="mobile-view--title" translate="exp-finder.trip-planner"></p>

<plan-trip-form class="mobile-view"></plan-trip-form>

<br>

<p class="mobile-view--title" translate="exp-finder.add-your-travel-style"></p>

<p class="mobile-view--selection-title" translate="exp-finder.experiences"></p>
<item-selection selectedFilter="experience" mobile></item-selection>

<p class="mobile-view--selection-title" translate="exp-finder.travel-type"></p>
<item-selection selectedFilter="activity" mobile></item-selection>

<p class="mobile-view--selection-title" translate="exp-finder.activities"></p>
<item-selection selectedFilter="travelStyle" mobile></item-selection>

<p class="mobile-view--selection-title"> <!-- do not remove --> </p>

<br>

<div class="mobile-view--action-buttons">
  <div class="mobile-view--action" (click)="clearTravelStyle()">
    <p translate="exp-finder.clear-travel-style"></p>
  </div>
  <div class="mobile-view--action primary" (click)="tripPlanningForm && tripPlanningForm.submit()">
    <p translate="exp-finder.lets-go"></p>
  </div>
</div>

<!-- Brand info request pending UX -->
<request-pending-skeleton class="mobile"></request-pending-skeleton>
