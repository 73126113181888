import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { AppListenerService, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';

@Component({
  selector: 'blank-itinerary',
  templateUrl: './blank-itinerary.component.html',
  styleUrls: ['./blank-itinerary.component.scss'],
  viewProviders: [ AuthDialogActionService ]
})
export class BlankItineraryComponent implements OnDestroy {

  authHandle$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }

  createNewItinerary(): void {
    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'createNewItinerary';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(this.createNewItinerary.bind(this));
      return;
    }

    this.router.navigate([ `/${currentLang()}/compose/new-story` ]);
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
