import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { flattenDeep } from 'lodash';

import { ImageResultData } from '@hiptraveler/data-access/api';

@Directive({
  selector: '[additionalInfoContent]'
})
export class AdditionalInfoContentDirective implements OnInit {

  @Input('additionalInfoContent') data: ImageResultData;

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngOnInit(): void {
    const descriptions = this.data.tourData?.addInfo?.split('\n\n').map((e: string) => e.replace('<br />', '\n').split('\n')) || [];
    const newHTMLContent = flattenDeep(descriptions).map(e => `<p><em>${e}</em></p>`).join('');
    this.element.nativeElement.innerHTML = newHTMLContent;
  }

}
