import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subject, firstValueFrom, takeUntil, tap } from 'rxjs';

import { ProfileResultData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { AppListenerService, clientVID, currentLang, NavbarControlStateService, pendingAuthProcessKey } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class CommunityService implements OnDestroy {

  #processing$$ = new BehaviorSubject<string[]>([]);
  processing$ = this.#processing$$.asObservable();
  authHandle$ = new Subject<void>();
  #following: string[] = [];

  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private navbarControlState: NavbarControlStateService,
    private snackbar: SnackbarService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }
  
  initializeState(): void {
    this.processing$.subscribe((following: string[]) => (this.#following = following));
  }

  navigateToProfile(profile: ProfileResultData): void {
    if (!profile?.title) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
      return;
    }
    this.router.navigate([ currentLang(), 'profile', profile.title, 'stories' ]);
    this.navbarControlState.navbarProfileClicked$$.next();
  }

  async followByResultData(result: ProfileResultData): Promise<void> {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'communityPageFollowByResultData';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.followByResultData(result));
      return;
    }
    
    if (this.#following.includes(result.profId)) return;

    const following = this.store.selectSnapshot(UserState.following) || [];

    if (following.includes(result.profId)) {
      this.snackbar.open({ message: `You are already following ${result.profName}` });
      return;
    }

    try {
      this.#following.push(result.profId);
      this.#processing$$.next(this.#following);
      await firstValueFrom(this.store.dispatch(new UserAction.FollowProfileByUserId({
        followUserId: result.profId,
        vId: clientVID()
      })));
    } finally {
      this.#processing$$.next(this.#following.filter(e => e !== result.profId));
    }
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
