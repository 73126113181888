import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityDetailsModule } from './activity-details/activity-details.module';
import { HighlightSelectModule } from './highlight-select/highlight-select.module';
import { PriceOffersModule } from './price-offers/price-offers.module';
import { PolicyDetailsModule } from './policy-details/policy-details.module';
import { AmenityDetailsModule } from './amenity-details/amenity-details.module';

import { ResultDetailsComponent } from './result-details.component';

const MODULES = [
  CommonModule,
];

const LIBRARIES = [
  ActivityDetailsModule,
  HighlightSelectModule,
  PriceOffersModule,
  PolicyDetailsModule,
  AmenityDetailsModule
];

const COMPONENTS = [
  ResultDetailsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ResultDetailsComponent
  ]
})
export class ResultDetailsModule { }
