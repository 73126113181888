import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, filter, takeUntil } from 'rxjs';

import { BasicInfoData, UserData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Directive({
  selector: '[travelDetailsStyles]'
})
export class TravelDetailsStylesDirective implements OnInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef<HTMLDivElement>,
    private store: Store
  ) { }

  get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

  ngOnInit(): void {

    this.store.select(ItineraryState.basicInfo).pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe((data: Partial<BasicInfoData>) => {

      const icons = this.element.querySelectorAll('.host--content-travel-flight-nav-icon') as NodeListOf<HTMLElement>;

      icons.forEach(e => {
        // e.style.opacity = data.locationList?.length === 1 ? '0' : '1';
      });
    });

    this.store.select(UserState.state).pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe((data: UserData) => {

      const icons: any = this.element.querySelectorAll('.host--content-travel-flight-nav-icon');
      const svgs: any = this.element.querySelectorAll('.host--content-travel-flight-icon-svg');
      const dividers: any = this.element.querySelectorAll('.host--content-travel-flight-hr-wrapper');

      [ ...icons, ...svgs, ...dividers ].forEach(e => {
        e.style.opacity = data?.userLoc ? '1' : '0';
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
