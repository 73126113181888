import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { SearchResultType } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss'],
  host: { class: 'ht-feature-card' },
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class FeatureCardComponent implements OnInit {

  @Output() favClicked = new EventEmitter<void>();

  @Input() id: string;
  @Input() starRating?: number;
  @Input() type: SearchResultType;
  @Input() itineraryCard: string;
  @Input() actionCard: string;
  @Input() actionButton: string;
  @Input() date: number;
  @Input() noFavButton: string;

  ratings?: number[];

  ngOnInit(): void {
    if (this.type !== 'amusement' && this.starRating) {
      this.ratings = Array.from({ length: this.starRating }, () => 0);
    }
  }

}
