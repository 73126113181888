import { ChangeDetectorRef, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';

import { ActivityDateData, ItineraryApiService, UpdateItineraryActivityDto, UpdateItineraryActivityResponse } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchState } from '@hiptraveler/data-access/search';
import { SEARCH_RESULT_ID } from '@hiptraveler/dialogs/search-result-dialog';
import { AppListenerService, PromiseReject, PromiseResolve, ReplaceItineraryActivity, SearchPageControlStateService, currentLang, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { FeatureCardStateService } from '@hiptraveler/ui/feature-card';

@Injectable()
export class ItineraryActionPanelDataAccessService {

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
    private itineraryApi: ItineraryApiService,
    private appListener: AppListenerService,
    private searchPageControl: SearchPageControlStateService,
    @Optional() private cardStateService: FeatureCardStateService,
    private snackbar: SnackbarService
  ) { }

  /**
   * Adds all hotels associated with a given activity to the itinerary.
   *
   * @param activityId - The ID of the activity for which to add hotels.
   */
  async addAllHotelAction(activityId: string): Promise<void> {
    try {
      this.processCardByIdAndState(activityId, 'enter');

      const activeItineraryId = this.searchPageControl.activityDate$$.value?.itineraryId
        || this.store.selectSnapshot(ItineraryState.basicInfo)?.id 
        || '';

      await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
        action: 'add-all-hotel',
        param: getWindowRef()[requestEndpointParamValue],
        item: activityId,
        id: activeItineraryId,
        lang: currentLang(),
        type: 'hotel',
        day: -1
      })));
      this.syncResponseToState();
      this.snackbar.dismiss();
    } catch (response: any) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.processCardByIdAndState(activityId, 'exit');
      this.searchPageControl.featureCardProcessing$$.next(false);
    }
  }

  /**
   * Adds a specific activity to the itinerary.
   *
   * @param activity - The ActivityDateData object representing the activity to add.
   */
  async addItemAction(activityId: string | undefined, activity: ActivityDateData): Promise<void> {

    if (!activityId) {
      
      return;
    }

    try {
      this.processCardByIdAndState(activityId, 'enter');

      if (!this.store.selectSnapshot(ItineraryState.basicInfo)?.id) {
        this.snackbar.open({ message: 'Something went wrong. Please try again.' });
        return;
      }

      const selectedSearchResult = this.store.selectSnapshot(SearchState.searchResult(activityId)) as any;
      const dialogType = this.dialog.getDialogById(SEARCH_RESULT_ID)?.componentInstance?.data?.dialogType;
      let searchType: 'image' | 'hotel' = selectedSearchResult?.type === 'hotel' ? 'hotel' : 'image'
      if (dialogType) { searchType = dialogType === 'hotel' ? 'hotel' : 'image'; }

      const activeItineraryId = this.searchPageControl.activityDate$$.value?.itineraryId
        || this.store.selectSnapshot(ItineraryState.basicInfo)?.id 
        || '';

      await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
        id: activeItineraryId,
        day: activity.day,
        action: 'add-item',
        item: activityId,
        type: searchType,
        param: getWindowRef()[requestEndpointParamValue],
        lang: currentLang()
      })));
      this.syncResponseToState();
      this.snackbar.dismiss();
    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.processCardByIdAndState(activityId, 'exit');
      this.searchPageControl.featureCardProcessing$$.next(false);
    }
  }

  /**
   * Removes an old item from the itinerary and replaces it with a new one.
   *
   * @param activityId - The ID of the activity to be added.
   * @param data - An object containing information about the item to be removed and the replacement item.
   */
  async removeOldItemAction(activityId: string, data: ReplaceItineraryActivity): Promise<void> {

    const activity = this.searchPageControl.activityDate$$.value;
    const activities = [ ...(activity?.HotelArray || []), ...(activity?.ImgArray || []) ].map(e => e.id);

    if (activities.includes(activityId)) {
      this.snackbar.open({ message: 'The selected item already exists. Please choose a different one.', duration: 5000 });
    }

    try {
      if (!data || !activityId || activities.includes(activityId)) return;
      this.processCardByIdAndState(activityId, 'enter');

      const activeItineraryId = this.searchPageControl.activityDate$$.value?.itineraryId
        || this.store.selectSnapshot(ItineraryState.basicInfo)?.id 
        || '';

      await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
        action: 'add-item-remove-old-item',
        param: getWindowRef()[requestEndpointParamValue],
        item: activityId,
        id: activeItineraryId,
        lang: currentLang(),
        idToBeRemoved: data.idToBeRemoved,
        type: data.type,
        day: data.day
      })));

      this.syncResponseToState();
      this.snackbar.dismiss();
      this.searchPageControl.replaceItineraryActivity$$.next(null);
      this.router.navigate([ data.composeUrl ]);
    } catch (response: any) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
      this.searchPageControl.replaceItineraryActivity$$.next(null)
    } finally {
      this.processCardByIdAndState(activityId, 'exit');
      this.searchPageControl.featureCardProcessing$$.next(false);
    }
  }

  /**
   * Determines if an itinerary item requires island hopping for visitors.
   *
   * @param activityId The ID of the selected itinerary item.
   */
  requestIslandData(activityId: string, activityDay: ActivityDateData): Promise<void> {

    const selectedSearchResult = this.store.selectSnapshot(SearchState.searchResult(activityId)) as any;
    const dialogType = this.dialog.getDialogById(SEARCH_RESULT_ID)?.componentInstance?.data?.dialogType;
    let searchType: 'image' | 'hotel' = selectedSearchResult?.type === 'hotel' ? 'hotel' : 'image'
    if (dialogType) { searchType = dialogType === 'hotel' ? 'hotel' : 'image'; }

    const activeItineraryId = this.searchPageControl.activityDate$$.value?.itineraryId
      || this.store.selectSnapshot(ItineraryState.basicInfo)?.id 
      || 'undefined';

    const payload: UpdateItineraryActivityDto = {
      id: activeItineraryId,
      action: 'add-item',
      item: activityId,
      type: searchType,
      param: getWindowRef()[requestEndpointParamValue],
      lang: currentLang(),
      day: activityDay?.day || 1
    };
    
    this.searchPageControl.islandHopPayload$$.next(payload);
    this.searchPageControl.islandHopResponse$$.next(null);

    return new Promise<any>(async (resolve: PromiseResolve<UpdateItineraryActivityResponse>, reject: PromiseReject) => {
      try {
        const response = await firstValueFrom(this.itineraryApi.updateItineraryActivity(payload));
        this.searchPageControl.islandHopResponse$$.next(response);
        resolve(response);
      } catch (error) {
        this.searchPageControl.islandHopPayload$$.next(null);
        reject(error);
      }
    });
  }

  /**
   * Cleans up data created by the `requestIslandData` method.
   * 
   * @param activityId The ID of the selected itinerary item.
   */
  removeSelectedItinerary(activityId: string, activityDay: ActivityDateData): Observable<unknown> {

    const selectedSearchResult = this.store.selectSnapshot(SearchState.searchResult(activityId)) as any;
    const dialogType = this.dialog.getDialogById(SEARCH_RESULT_ID)?.componentInstance?.data?.dialogType;
    let searchType: 'image' | 'hotel' = selectedSearchResult?.type === 'hotel' ? 'hotel' : 'image'
    if (dialogType) { searchType = dialogType === 'hotel' ? 'hotel' : 'image'; }

    const activeItineraryId = this.searchPageControl.activityDate$$.value?.itineraryId
      || this.store.selectSnapshot(ItineraryState.basicInfo)?.id 
      || 'undefined';

    return this.itineraryApi.updateItineraryActivity({
      id: activeItineraryId,
      action: 'remove-item',
      item: selectedSearchResult.id,
      type: searchType,
      param: getWindowRef()[requestEndpointParamValue],
      lang: currentLang(),
      day: activityDay?.day || 1
    });
  }

  /**
   * Handles common processing logic for card actions, such as displaying a loading indicator and managing card states.
   *
   * @param id - The ID of the card being processed.
   * @param state - The state of the processing, either 'enter' or 'exit'.
   */
  processCardByIdAndState(id: string, state: 'enter' | 'exit'): void {
    if (state === 'enter') {
      setTimeout(() => this.cdRef.detectChanges(), 350);
      this.searchPageControl.featureCardProcessing$$.next(true);
      this.cardStateService?.pushCardProcessing(id);
      this.appListener.cardMenuPanelState$$.next('-');
    } else {
      this.cardStateService?.removeCardProcessing(id);
      this.searchPageControl.featureCardProcessing$$.next(false);
      setTimeout(() => this.cdRef.detectChanges());
    }
  }

  private syncResponseToState(): void {

    const stateDayValue = this.searchPageControl.activityDate$$.value?.day;
    if (!stateDayValue) return;
    
    const activity = this.store.selectSnapshot(ItineraryState.actDateMap)?.[stateDayValue];
    if (!activity) return;

    const activityDay: any = { ...activity };
    activityDay['actDate'] = this.store.selectSnapshot(ItineraryState.actDate) || [];
    activityDay['actDateMap'] = this.store.selectSnapshot(ItineraryState.actDateMap) || [];
    activityDay['pageTitle'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle;
    activityDay['itineraryId'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.id;
    this.searchPageControl.activityDate$$.next(activityDay);
  }

}
