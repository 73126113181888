import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { compact, flatten } from 'lodash';

import { ActivityDate, SearchPageControlStateService } from '@hiptraveler/common';

@Pipe({
  name: 'validateId'
})
export class ValidateIdPipe implements PipeTransform {

  constructor(
    private searchPageControl: SearchPageControlStateService
  ) { }

  /**
   * Validates the ID and returns an Observable with the result.
   * 
   * @param id The ID to validate.
   * @param day The day to check for the ID.
   * @returns An Observable that emits a boolean indicating whether the ID is valid.
   */
  transform(id?: string, day?: number): Observable<boolean> {
    return this.searchPageControl.activityDate$.pipe(
      map((activityDate: ActivityDate | null) => {
        const activityDates = activityDate?.actDate;
        if (!activityDates) return false;

        const hotelContents = activityDates
          .filter(e => e?.date?.day === day || e?.day === day)
          .map(e => e.HotelArray?.map(e => e?.id));

        const imageContents = activityDates
          .filter(e => e?.date?.day === day || e?.day === day)
          .map(e => e.ImgArray?.map(e => e?.id))

        const ids = compact(flatten([ ...hotelContents, ...imageContents ]));

        return ids.includes(id || '');
      })
    );
  }

}
