import { AutocompleteLocation, AutocompleteLocationDataResponse, LocationData } from "@hiptraveler/data-access/api";

export function responseToValue(response: AutocompleteLocationDataResponse): LocationData[] | null {

  if (response?.error) return null;

  return response.locations.map((location: AutocompleteLocation) => {
    return { ...location } as any
  });
}
