import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ActivityDateListSkeletonComponent } from './activity-date-list-skeleton.component';

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule
];

const COMPONENTS = [
  ActivityDateListSkeletonComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityDateListSkeletonComponent
  ]
})
export class SkeletonModule { }
