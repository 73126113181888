import { Directive, Inject, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { SupportedLanguage } from '@hiptraveler/common';

@Directive({
  selector: '[appCalendarI18nListener]',
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-gb'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ],
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS
    },
  ]
})
export class CalendarI18nListenerDirective implements OnInit {

  constructor(
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _adapter: DateAdapter<any>,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      switch (event.lang as SupportedLanguage) {
        case 'en': return this.setLocale('en-gb');
        case 'de': return this.setLocale('de');
        case 'es': return this.setLocale('es');
        case 'fr': return this.setLocale('fr');
        case 'it': return this.setLocale('it');
        case 'zh': return this.setLocale('zh-cn');
        default:   return this.setLocale('en-gb');
      }
    });
  }

  private setLocale(locale: string): void {
    this._locale = locale;
    this._adapter.setLocale(this._locale);
  }

}
