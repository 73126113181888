import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { PostActionButtonModule } from '@hiptraveler/features/post-action-button';
import { CustomActivityActionButtonModule } from './custom-activity-action-button/custom-activity-action-button.module';
import { UserInfoModule } from './user-info/user-info.module';
import { UserInfoMobileModule } from './user-info-mobile/user-info-mobile.module';
import { UserInfoMobileToolbarModule } from './user-info-mobile-toolbar/user-info-mobile-toolbar.module';
import { UserActivityModule } from './user-activity/user-activity.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { SelectionTabs } from './user-selection-tabs';

import { ActivityContentComponent } from './activity-content.component';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  PostActionButtonModule,
  CustomActivityActionButtonModule,
  UserInfoModule,
  UserInfoMobileModule,
  UserInfoMobileToolbarModule,
  UserActivityModule,
  UserSettingsModule,
  ButtonsModule,
  SelectionTabs
];

const COMPONENTS = [
  ActivityContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityContentComponent
  ]
})
export class ActivityContentModule { }
