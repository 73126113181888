import { Component, OnInit, Self } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthState } from '@hiptraveler/data-access/auth';
import { DefaultUiService } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SnackbarService } from '@hiptraveler/snackbar';
import { DontSellInfoService } from './dont-sell-info.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-dont-sell-info',
  templateUrl: './dont-sell-info.component.html',
  styleUrls: ['./dont-sell-info.component.scss'],
  viewProviders: [ DefaultUiService, AuthDialogActionService, DontSellInfoService ],
  animations: [ opacityFade() ]
})
export class DontSellInfoComponent implements OnInit {

  constructor(
    private store: Store,
    @Self() private defaultUI: DefaultUiService,
    private authDialog: AuthDialogActionService,
    private snackbar: SnackbarService,
    public service: DontSellInfoService
  ) { }

  ngOnInit(): void {
    this.service.authenticationObserver();
  }

  openAuthDialog(): any {
    if (this.store.selectSnapshot(AuthState.authenticated))
      return this.snackbar.open({ message: 'Already signed in.' });
    this.authDialog.open();
  }

}
