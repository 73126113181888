import { Component, Input, OnInit } from '@angular/core';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { MainMenuService } from './main-menu.service';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  providers: [ AuthDialogActionService, MainMenuService ]
})
export class MainMenuComponent implements OnInit {

  @Input() defaultImage: string;

  constructor(
    public stateService: ExperienceFinderStateService,
    public service: MainMenuService
  ) { }

  ngOnInit(): void {
    this.service.stateObserver();
  }

}
