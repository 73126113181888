import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SearchPageRootComponent } from './search-page-root.component';

const searchPageRootRoutes = [
  // Search
  {
    path: '',
    loadChildren: () => import ('../page--search/search.module').then(m => m.SearchModule),
    data: { homepage: true }
  },
  {
    path: 'search',
    loadChildren: () => import ('../page--search/search.module').then(m => m.SearchModule),
    data: { homepage: false }
  },
  {
    path: 'search/:location',
    loadChildren: () => import ('../page--search/search.module').then(m => m.SearchModule),
    data: { homepage: false }
  },
  // Experiences
  {
    path: 'experiences',
    loadChildren: () => import ('../page--experiences/experiences.module').then(m => m.ExperiencesModule)
  },
  {
    path: 'experiences/:location',
    loadChildren: () => import ('../page--experiences/experiences.module').then(m => m.ExperiencesModule)
  },
  // Things to do
  {
    path: 'thingstodo',
    loadChildren: () => import ('../page--things-to-do/things-to-do.module').then(m => m.ThingsToDoModule),
    data: { type: 'image' }
  },
  {
    path: 'thingstodo/:location',
    loadChildren: () => import ('../page--things-to-do/things-to-do.module').then(m => m.ThingsToDoModule),
    data: { type: 'image' }
  },
  // Hotels
  {
    path: 'hotels',
    loadChildren: () => import ('../page--hotels/hotels.module').then(m => m.HotelsModule),
    data: { type: 'hotel' }
  },
  {
    path: 'hotels/:location',
    loadChildren: () => import ('../page--hotels/hotels.module').then(m => m.HotelsModule),
    data: { type: 'hotel' }
  },
  // Food and drinks
  {
    path: 'foodanddrink',
    loadChildren: () => import ('../page--food-and-drink/food-and-drink.module').then(m => m.FoodAndDrinkModule),
    data: { type: 'image' }
  },
  {
    path: 'foodanddrink/:location',
    loadChildren: () => import ('../page--food-and-drink/food-and-drink.module').then(m => m.FoodAndDrinkModule),
    data: { type: 'image' }
  },
  // Communities
  {
    path: 'community',
    loadChildren: () => import ('../page--community/community.module').then(m => m.CommunityModule)
  },
  {
    path: 'community/:location',
    loadChildren: () => import ('../page--community/community.module').then(m => m.CommunityModule)
  },
  // Fallback url
  {
    path: '**',
    redirectTo: '../',
    pathMatch: 'full'
  }
];

const routes: Routes = [
  {
    path: '',
    component: SearchPageRootComponent,
    children: searchPageRootRoutes
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class SearchPageRootRoutingModule { }
