import { AfterViewInit, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { filter, skip, take } from 'rxjs';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { HighlightSelectService } from './highlight-select.service';
import { SearchResultDialogStateService } from './../../common';

@Component({
  selector: 'highlight-select',
  templateUrl: './highlight-select.component.html',
  styleUrls: ['./highlight-select.component.scss'],
  host: { class: 'highlight-select' },
  viewProviders: [ HighlightSelectService ],
  encapsulation: ViewEncapsulation.None
})
export class HighlightSelectComponent implements AfterViewInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: HighlightSelectService,
    public stateService: SearchResultDialogStateService
  ) { }

  ngAfterViewInit(): void {
    this.service.hotelInformation$.pipe(
      skip(1), take(1), filter(Boolean)
    ).subscribe((hotelInformation: HotelResultData) => {
      this.stateService.selectedHighlight$$.next(hotelInformation.imgs[0])
      this.element.nativeElement.style.display = hotelInformation?.imgs?.length ? 'block' : 'none';
    });
  }

}
