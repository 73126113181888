import { Injectable, Inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { AddItineraryDto } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SearchAction } from '@hiptraveler/data-access/search';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ExperienceFinderStateService, formStoreKey } from '@hiptraveler/features/experience-finder';
import { SnackbarService } from '@hiptraveler/snackbar';
import { FormFeatureService } from './form-feature.service';
import { PlanTripService } from '../plan-trip.service';
import { getRequestPayload } from './form-helper';
import * as Common from '@hiptraveler/common';

@Injectable()
export class FormSubmitService {
  
  constructor(
    @Inject(Common.ENVIRONMENT) private environment: Common.Environment,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private navbarControl: Common.NavbarControlStateService,
    private stateService: ExperienceFinderStateService,
    private searchLocation: Common.SearchLocationService,
    private searchPageControl: Common.SearchPageControlStateService,
    private snackbar: SnackbarService,
    private formFeature: FormFeatureService,
    private planTrip: PlanTripService
  ) { }

  async submit({ placeResult }: { placeResult: Common.PlaceResult }): Promise<void> {

    if (this.validateFormData(placeResult)) return;

    this.stateService.processing$$.next(true);
    
    if (!Common.isWidget()) {
      sessionStorage.setItem(Common.addItineraryUXStateKey, '1');
      sessionStorage.setItem(Common.requestCancellationIgnoredEndpointsKey, JSON.stringify([ '/trip-planner/addItinerary' ]));

      /* To do: For improvement / This code block needs to be improved -- START */
      const searchPage = `/${Common.currentLang()}/search`; 
      const pathname = `${searchPage}/${placeResult?.name}`;
      
      Common.getWindowRef()[Common.preventRequestCancellation] = '1';

      if (this.router.url.includes(`/${Common.currentLang()}/search`)) {
        await this.router.navigate([ '/',`${Common.currentLang()}`, 'thingstodo' ], { skipLocationChange: true });
      }

      this.searchLocation.emitPlaceResultDataToState(placeResult);

      await Common.promiseDelay(1000);
      this.navigateOnSubmission(pathname);

      try {
        const query = { q: placeResult?.formatted_address, locId: placeResult?.place_id };
        await firstValueFrom(this.store.dispatch(new SearchAction.GetLocationDataByQuery(query)));
      } finally { } /* To do -- END */
    }

    this.requestSnackbar();

    try {

      Common.isWidget() && this.runWidgetProcess({ processing: true });
      Common.isWidget() || this.stateService.overlayState$$.next(false);

      await firstValueFrom(this.store.dispatch(new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap' ])));

      Common.isWidget() || this.navbarControl.setActiveNavbarButtons([]);

      Common.getWindowRef()[Common.addItineraryRequestPending] = '1';
      const authenticated = this.store.selectSnapshot(AuthState.authenticated);
      const requestPayload = getRequestPayload({ form: this.formFeature.form, stateService: this.stateService, placeResult, authenticated });
      await firstValueFrom(this.store.dispatch(new ItineraryAction.AddItinerary(requestPayload)));
      Common.getWindowRef()[Common.addItineraryRequestPending] = undefined;

      Common.getWindowRef()[Common.requestEndpointParamValue] = 'itinerary';

      Common.isWidget() || this.processItineraryBanner(requestPayload);

      Common.isWidget() && this.runWidgetProcess({ processing: false });
      Common.isWidget() || this.navbarControl.navbarActionButtonState$$.next(true);

      this.runWidgetSuccessProcess(requestPayload);
      sessionStorage.removeItem(formStoreKey);

      this.snackbar.dismiss();
    } catch (response: any) {
      this.snackbar.open({ message: response.error, duration: 5000 });
      Common.isWidget() && this.runWidgetProcess({ processing: false });
    } finally {
      this.stateService.processing$$.next(false);
    }
  }

  private async navigateOnSubmission(pathname: string): Promise<void> {

    await this.router.navigate([ pathname ]);
    await Common.promiseDelay(1000);

    Common.getWindowRef()[Common.preventRequestCancellation] = undefined;
  }

  private validateFormData(placeResult: Common.PlaceResult): boolean {

    if (this.formFeature.form.value.itinerary === 'skip') {
      this.router.navigate([ `/${Common.currentLang()}/search/${placeResult?.name}` ]);
      return true;
    } 
    if (!this.formFeature.form.value.place) {
      this.snackbar.open({ message: 'Please enter a location to continue.', duration: 5000 });
      return true;
    }
    if (!placeResult) {
      this.snackbar.open({ message: 'Please select a location for your trip.', duration: 5000 });
      return true;
    }

    return false;
  }

  private runWidgetSuccessProcess(payload: AddItineraryDto): void {

    if (!Common.isWidget()) return;
    if (!this.store.selectSnapshot(UserState.itineraries)?.[0]) return;

    const fromUrl = `${Common.currentLang()}/itinerary`;
    const toUrl = `${Common.currentLang()}/compose/itinerary`;
    const urlPath = payload.customIti !== 'own' ? this.urlPath : this.urlPath.replace(fromUrl, toUrl)

    this.cdRef.detectChanges();
    const newWindow = Common.getWindowRef()?.open(urlPath, '_blank');

    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      console.log('@@@ ', 'Pop-up disabled!');
      this.planTrip.externalViewState$$.next(true);
      this.planTrip.itineraryRedirectUrl = urlPath;
    } else {
      console.log('@@@ ', 'Pop-up enabled!');
      this.stateService.dialogView$$.next('travel-style');
    }
  }

  private get urlPath(): string {

    const itinerary = this.store.selectSnapshot(UserState.itineraries)![0];

    let urlPath = `${Common.currentLang()}/${itinerary.isAutoGenerated ? 'itinerary' : 'compose/itinerary'}/${itinerary.pageTitle}`;

    const brand = this.store.selectSnapshot(BrandState.brandCampaign);

    if (Common.isBrandIframeWidget()) {
      const iframeLink = brand!.iframeLink!.replace( // For development only
        'http://localhost:8080/Hiptraveler/framewidget',
        `partners.hiptraveler.com/${Common.currentLang()}/trip-planner/framewidget/`
      );
      urlPath = `${iframeLink}#/${urlPath}`;
    } else {
      const protocol = this.environment.local ? 'http' : 'https';
      urlPath = `${protocol}://${brand!.cSubDomain}/${urlPath}`;
      urlPath = urlPath.replace('bahamas.localhost:8080/Hiptraveler', 'bahamas.localhost:4200'); // For development only
    }

    return urlPath;
  }

  private runWidgetProcess(value: { processing: boolean }): void {
    if (!Common.isWidget()) return;
    this.planTrip.processingViewState$$.next(value.processing)
    this.stateService.actionProcessing$$.next(value.processing);
  }

  private processItineraryBanner(payload: AddItineraryDto): void {

    if (this.store.selectSnapshot(UserState.authenticated)) return;

    const actDate = this.store.selectSnapshot(ItineraryState.actDate);
    const actDateMap = this.store.selectSnapshot(ItineraryState.actDateMap);
    const value = actDate?.[0] as any;
    if (!value) return;

    value['actDate'] = actDate || [];
    value['actDateMap'] = actDateMap;
    value['pageTitle'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle;
    value['itineraryId'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.id
    this.searchPageControl.activityDate$$.next(value);
    if (payload.customIti === 'own') {
      this.router.navigate([ `${Common.currentLang()}/compose/itinerary/${value?.pageTitle}` ]);
    } else {
      setTimeout(() => this.snackbar.open({ message: 'Your Trip is loaded in Trip Planner!' }), 350);
    }
  }
  
  private requestSnackbar(): void {
    if (Common.isWidget()) return;
    const message = this.formFeature.form.value.itinerary === 'auto'
      ? 'We are building your personalized itinerary...'
      : 'Processing your itinerary...';
    setTimeout(() => this.snackbar.open({ message, duration: Infinity }), 500);
  }

}
