import { findIndex } from 'lodash';

import * as Itinerary from '..';
import * as API from "@hiptraveler/data-access/api";

export function moveItineraryActivityValue(activity: Partial<API.ActivityDateData> | null, value: Itinerary.ItineraryActivityPosition): any {
  
  const { day, direction, id } = value;
  const imageResults = [...(activity?.ImgArray || [])];
  const selectedIndex = findIndex(imageResults, { id }) || 0;
  const nextIndex = direction === 'down' ? selectedIndex + 1 : selectedIndex - 1;

  const switchIndexes = (arr: any[], index1: number, index2: number): void => {
    if (index1 >= 0 && index1 < arr.length && index2 >= 0 && index2 < arr.length) {
      [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    }
  };

  switchIndexes(imageResults, nextIndex, selectedIndex);

  return { [`${day}`]: { ...activity, ImgArray: imageResults } };
}
