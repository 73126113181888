import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, map, switchMap, takeUntil, tap } from 'rxjs';

import { BasicInfoData, BrandCampaign, TravelAgentData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, clientVID, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class StoryTravelAgentsService {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;
  @Select(ItineraryState.travelAgents) travelAgents$: Observable<TravelAgentData[] | null>;

  #processing$$ = new BehaviorSubject<string[]>([]);
  processing$ = this.#processing$$.asObservable();
  authHandle$ = new Subject<void>();

  #following: string[] = [];

  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private sendMessage: SendMessageDialogActionService,
    private snackbar: SnackbarService
  ) { }
  
  initializeState(): void {
    this.processing$.subscribe((following: string[]) => (this.#following = following));
  }

  /**
   * Returns an observable value of the cover image from the brand state.
   */
  get coverImageFallback$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => brandCampaign?.cCoverImg?.cover || 'assets/img/blank.webp')
    );
  }

  /**
   * Returns an observable value to hide and display travel agent highlight card
   */
  get highlight$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      map((value: number) => (value < 945)),
      switchMap(value => this.travelAgents$.pipe(
        map(e => e?.length === 1 || value)
      ))
    );
  }
  
  /**
   * Returns true if there are travel agent in the basic info response
   */
  get hasTravelAgents$(): Observable<boolean> {
    return this.travelAgents$.pipe(
      map((travelAgents: TravelAgentData[] | null) => !!travelAgents?.length),
      filter(Boolean)
    );
  }
  
  /**
   * Opens the send message dialog to send a message to the travel agent
   * 
   * @param {string} profileId - Travel agent profile Id
   */
  sendTravelAgentMessage(profileId: string): void {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'sendTravelAgentMessage';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.sendTravelAgentMessage(profileId));
      return;
    }

    this.sendMessage.open({ profileId, travelAgent: true });
  }

  /**
   * Follow by result data action dispatch
   * 
   * @param {TravelAgentData} value - Travel agent data DTO
   */
  async followByResultData(travelAgent: TravelAgentData): Promise<void> {

    if (this.#following.includes(travelAgent.profId)) return;

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'followByResultData';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.followByResultData(travelAgent));
      return;
    }

    const following = this.store.selectSnapshot(UserState.following) || [];

    if (following.includes(travelAgent.profId)) {
      this.snackbar.open({ message: `You are already following ${name}` });
      return;
    }

    try {
      this.#following.push(travelAgent.profId);
      this.#processing$$.next(this.#following);

      await firstValueFrom(this.store.dispatch(new UserAction.FollowProfileByUserId({
        followUserId: travelAgent.profId,
        vId: clientVID()
      })));
      this.#processing$$.next(this.#following.filter(e => e !== travelAgent.profId));
    } finally {
      this.#processing$$.next(this.#following.filter(e => e !== travelAgent.profId));
    }
  }

  navigateToProfile(travelAgent: TravelAgentData): void {
    if (travelAgent?.title) {
      this.router.navigate([ currentLang(), 'profile', travelAgent.title, 'stories' ])
    } else {
      this.snackbar.open({ message: 'Travel agent profile not found.', duration: 5000 });
    }
  }

  /**
   * Reset auth handle process
   */
  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
