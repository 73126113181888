import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ConnectModule } from './connect/connect.module';

import { ConnectContentComponent } from './connect-content.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  ConnectModule
];

const COMPONENTS = [
  ConnectContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ConnectContentComponent
  ]
})
export class ConnectContentModule { }
