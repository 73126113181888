import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardContainerComponent } from './card-container.component';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  CardContainerComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CardContainerComponent
  ]
})
export class CardContainerModule { }
