import { NgModule } from '@angular/core';

import { SearchPageMapDirective } from './search-page-map.directive';

const DIRECTIVES = [
  SearchPageMapDirective
];

@NgModule({
  declarations: [
    DIRECTIVES,
  ],
  exports: [
    SearchPageMapDirective
  ]
})
export class SearchPageMapModule { }
