import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationLabel'
})
export class DurationLabelPipe implements PipeTransform {

  transform(value: any, unit?: string): string {

    const duration = +value;

    const label = unit?.[unit?.length - 1];

    if (/(minute|hour|day|week|month)/.test(`${value}`)) {
      return `${value}`
    }

    if (label?.toLowerCase() === 's') {
      return `${value} ${unit}`;
    } else {
      return `${value} ${unit}${duration > 1 ? 's' : ''}`;
    }
  }

}
