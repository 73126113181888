import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { titleCase } from 'title-case';

import { BrandState } from '@hiptraveler/data-access/brand';

@Pipe({
  name: 'followName'
})
export class FollowNamePipe implements PipeTransform {

  constructor(
    private store: Store
  ) { }

  transform(_: unknown): string {
    return titleCase(this.store.selectSnapshot(BrandState.brandCampaign)?.cTitle || 'HipTraveler');
  }

}
