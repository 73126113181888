import { ScreenValue } from "./model";

export const screens = [
  'screen1', 'screen2', 'screen3', 'screen4',
  'screen5', 'screen6', 'screen7', 'screen8'
] as ScreenValue[];

export function getActiveScreen(scrollTop: number, offset: number = 0): ScreenValue {
  const screenState = screenViewState(scrollTop, offset)
  return Object.keys(screenState).find((screen: any) => screenState[screen as ScreenValue] === true) as ScreenValue;
}

export function screenViewState(scrollTop: number, offset: number = 0): Record<ScreenValue, boolean> {
  return {
    screen1: scrollTop >= 0 && scrollTop < 400,
    screen2: scrollTop >= 400 - offset && scrollTop < 800 - offset,
    screen3: scrollTop >= 800 - offset && scrollTop < 1200 - offset,
    screen4: scrollTop >= 1200 - offset && scrollTop < 1600 - offset,
    screen5: scrollTop >= 1600 - offset && scrollTop < 2000 - offset,
    screen6: scrollTop >= 2000 - offset && scrollTop < 2400 - offset,
    screen7: scrollTop >= 2400 - offset && scrollTop < 2800 - offset,
    screen8: scrollTop >= 2800 - offset && scrollTop < 3200 - offset,
    screen9: scrollTop >= 3200 - offset && scrollTop < 3600 - offset,
    screen10: scrollTop >= 3600 - offset && scrollTop < 4000 - offset,
    screen11: scrollTop >= 4000 - offset && scrollTop < 4400 - offset,
    screen12: scrollTop >= 4400 - offset && scrollTop < 4800 - offset
  };
}
