
<div class="profile-map mat-elevation-z2">

  <div class="profile-map--label">
    <p class="profile-map--label-text" translate="profile.where-i've-been"></p>
    <ng-container *ngIf="stateService.selfProfile$ | async">
      <p class="profile-map--label-action" (click)="openDialog()" [@opacityFade]>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"></path></svg> 
        {{ 'profile.places' | translate }}
      </p>
    </ng-container>
  </div>
  
  <ng-container *ngIf="true">
    <div leafletMap id="profile-page-map" style="height: calc(100dvh - 122px);" [@opacityFadeIn]></div>
  </ng-container>

</div>
