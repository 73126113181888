import { OnInit, Directive, ElementRef, Input, Renderer2, Inject, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthorImageInfo } from '@hiptraveler/data-access/api';
import { WINDOW } from '@hiptraveler/common';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Directive({
  selector: '[authorImage]'
})
export class AuthorImageDirective implements OnInit {

  @Input() authorImage: AuthorImageInfo;

  constructor(
    @Inject(WINDOW) private window: any,
    private store: Store,
    private element: ElementRef<HTMLImageElement>,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

    const authorName = this.authorImage?.authorName || '';
    const authorProfId = this.authorImage?.authorProfId || '';

    if (this.foodanddrink) {
      this.renderer.setAttribute(this.element.nativeElement, 'src', 'assets/img/affiliates/yelp.png');
      this.renderer.setAttribute(this.element.nativeElement, 'alt', 'Yelp brand logo');
      return;
    }

    switch (authorProfId) {
      case 'getyourguide': {
        this.renderer.setAttribute(this.element.nativeElement, 'src', 'assets/img/affiliates/get-your-guide.png');
        this.renderer.setAttribute(this.element.nativeElement, 'alt', authorName || authorProfId);
        break;
      }
      case 'viator': {
        this.renderer.setAttribute(this.element.nativeElement, 'src', 'assets/img/affiliates/viator.png');
        this.renderer.setAttribute(this.element.nativeElement, 'alt', authorName || authorProfId);
        break;
      }
      case 'yelpData': {
        this.renderer.setAttribute(this.element.nativeElement, 'src', 'assets/img/affiliates/yelp.png');
        this.renderer.setAttribute(this.element.nativeElement, 'alt', authorName || authorProfId);
        break;
      }
      default: {
        this.renderer.setAttribute(this.element.nativeElement, 'src', 'assets/img/blank-white.webp');
        this.renderer.setAttribute(this.element.nativeElement, 'alt', authorName || authorProfId);
        break;
      }
    }
  }

  @HostListener('click')
  hostClicked(): void {
    if (!this.authorImage?.authorProfId && this.foodanddrink) {
      const externalLink = this.store.selectSnapshot(ItineraryState.imageInformation)?.yelpData?.yelpUrl;
      externalLink && this.window.open(externalLink, '_blank');
    }
  }

  private get foodanddrink(): boolean {
    const { pathname } = this.window.location;
    const lang = pathname.split('/')[1];
    return pathname.startsWith(`/${lang}/foodanddrink`);
  }

}
