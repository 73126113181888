import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as MessagingApi from './interfaces';
import { toFormData, toQueryString } from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class MessagingApiService {

  constructor(
    private http: HttpClient
  ) { }

  emailShare(payload: MessagingApi.EmailShareDto): Observable<MessagingApi.EmailShareResponse> {
    return this.http.post<MessagingApi.EmailShareResponse>(`/trip-planner/emailLink?${toQueryString({
      sharedTitle: payload.title,
      sharedUrl: payload.url,
      emailIds: payload.emailIds,
      msg: payload.message,
      cId: brandCampaignId(),
      vId: clientVID()
    })}`, {});
  }

  sendMessage(payload: MessagingApi.SendMessageDto): Observable<MessagingApi.EmailShareResponse> {
    return this.http.post<MessagingApi.EmailShareResponse>(`/trip-planner/sendMessage?${toQueryString({
      ...payload,
      cId: brandCampaignId(),
      vId: clientVID()
    })}`, {});
  }
  
  travelAgentSendMessage({ msg, profId }: MessagingApi.SendMessageDto): Observable<MessagingApi.EmailShareResponse> {
    return this.http.post<MessagingApi.EmailShareResponse>(`/trip-planner/connect/travelagent/${brandCampaignId()}/${profId}/?msgRequest=${msg}`, {});
  }

  newSignUp(payload: MessagingApi.NewsSignUpDto): Observable<MessagingApi.NewsSignUpResponse> {
    return this.http.post<MessagingApi.NewsSignUpResponse>(`/trip-planner/htNewsSignUp`, toFormData(payload));
  }

}
