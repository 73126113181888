import { Pipe, PipeTransform } from '@angular/core';
import { currentLang } from '@hiptraveler/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dayTime'
})
export class DayTimePipe implements PipeTransform {

  constructor(
    private i18n: TranslateService
  ) { }

  transform(value: string): string {
    return value.replace('pm', ` ${this.getTranslation('pm')}`).replace('am', ` ${this.getTranslation('am')}`).toUpperCase();
  }

  getTranslation(value: string): string {
    return this.i18n.translations?.[currentLang()]?.['search-result']?.[value] || '';
  }

}
