import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { NavbarControlStateService, NavbarToolbarPosition } from '@hiptraveler/common';

@Injectable()
export class PageBannerService {

  @Select(UserState.state) userState$: Observable<UserStateModel | null>;

  subscription$ = new Subject<void>();

  constructor(
    private navbarControl: NavbarControlStateService
  ) { }

  scrollPositionObserver(): void {
    this.navbarControl.navbarToolbarPosition$.pipe(
      tap((position: NavbarToolbarPosition) => this.navbarControl.navbarElevationState$$.next(position !== 'navbar')),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
