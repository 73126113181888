import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { DialogToolbarActionsService } from './dialog-toolbar-actions.service';
import { DialogStateService } from '../common';

@Component({
  selector: 'dialog-toolbar-actions',
  templateUrl: './dialog-toolbar-actions.component.html',
  styleUrls: ['./dialog-toolbar-actions.component.scss'],
  host: { class: 'custom-activity-dialog-dialog-toolbar-actions' },
  viewProviders: [ DialogToolbarActionsService ],
  encapsulation: ViewEncapsulation.None
})
export class DialogToolbarActionsComponent {

  @Output() actionClicked = new EventEmitter();

  constructor(
    public service: DialogToolbarActionsService,
    public stateService: DialogStateService
  ) { }

}
