import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TravelShareHandlesComponent } from './travel-share-handles.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const COMPONENTS = [
  TravelShareHandlesComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TravelShareHandlesComponent
  ]
})
export class TravelShareHandlesModule { }
