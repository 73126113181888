import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserItineraryData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { MyTripsComponent } from './my-trips.component';

@Injectable()
export class MyTripsService {

  @Select(UserState.itineraries) itineraries$: Observable<UserItineraryData[] | null>;

  constructor(
    private dialogRef: MatDialogRef<MyTripsComponent>,
    private stateService: ExperienceFinderStateService,
  ) { }

  createNewTrip(): void {
    const overlayState$$ = this.stateService.overlayState$$;
    overlayState$$.value ? this.dialogRef.close() : overlayState$$.next(true);
  }

}
