import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'travel-style',
  template: `
    <filters></filters>
    <item-selection *ngIf="itemSelectionVisibility$ | async"></item-selection>
    <action-button
      [marginY]="-36"
      (click)="stateService.dialogView$$.next('plan-trip')">
      {{ 'exp-finder.button-text-next' | translate }}
    </action-button>
  `,
  host: { class: 'travel-style-host selection-view-host' }
})
export class TravelStyleComponent {

  constructor(
    private store: Store,
    public stateService: ExperienceFinderStateService
  ) { }

  get itemSelectionVisibility$(): Observable<boolean> {
    return this.store.select(BrandState.experiences).pipe(
      map(e => !!e?.length)
    )
  }

}
