<div class="meeting-point ht-dialog-elevation">

  <h2 translate="search-result.meet-pick-up"></h2>
  <div class="meeting-point--section-list">
    <ng-container *ngIf="(service.imageInformation$ | async)?.tourData?.logistics?.departureTimeComments as sectionText;else noCommentsInformation">
      <div class="meeting-point--section">
        <p class="meeting-point--section-head" translate="search-result.details"></p>
        <p class="meeting-point--section-text">{{ sectionText }}</p>
      </div>
    </ng-container>
    <ng-template #noCommentsInformation>
      <div class="meeting-point--section" *ngIf="!(service.imageInformation$ | async)?.tourData?.logistics?.departDetails">
        <p class="meeting-point--section-head" translate="search-result.details"></p><br>
        <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
      </div>
    </ng-template>
    <ng-container *ngIf="(service.imageInformation$ | async)?.tourData?.logistics?.departDetails as sectionText;else noDetailsInformation">
      <div class="meeting-point--section" [@opacityFade]>
        <p class="meeting-point--section-head" translate="search-result.departure-point"></p>
        <p class="meeting-point--section-text">{{ sectionText }}</p>
      </div>
    </ng-container>
    <ng-template #noDetailsInformation>
      <div class="meeting-point--section" *ngIf="!(service.imageInformation$ | async)?.tourData?.logistics?.departureTimeComments">
        <p class="meeting-point--section-head" translate="search-result.related-tours"></p><br>
        <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
      </div>
    </ng-template>
  </div>

</div>
