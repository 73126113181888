import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DefaultPageRootRoutingModule } from './default-page-root-routing.module';

import { DefaultPageRootComponent } from './default-page-root.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  DefaultPageRootRoutingModule
];

const COMPONENTS = [
  DefaultPageRootComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class DefaultPageRootModule { }
