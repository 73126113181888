import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, of } from 'rxjs';

import { BrandInfo, Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { TripFilterType } from '../../experience-finder.model';

@Pipe({
  name: 'selection'
})
export class SelectionPipe implements PipeTransform {

  constructor(
    private store: Store,
    private stateService: ExperienceFinderStateService
  ) { }
  
  transform(selectedFilter?: TripFilterType): Observable<Partial<Experience>[]> {
    return selectedFilter ? this.getSpecificItemSelection(selectedFilter) : this.dynamicItemSelection;
  }

  getSpecificItemSelection(selectedFilter: TripFilterType): Observable<Partial<Experience>[]> {
    return this.store.select(BrandState.state).pipe(
      map((state: Partial<BrandInfo> | null) => (state?.experiences || []).filter(e => e.category === selectedFilter))
    );
  }
  
  get dynamicItemSelection(): Observable<Partial<Experience>[]> {
    return this.stateService.selectedFilter$$.asObservable().pipe(
      map((selectedFilter: TripFilterType) => {
        const experiences = this.store.selectSnapshot(BrandState.state)?.experiences || [];
        experiences.filter(e => e.category === selectedFilter);
        return experiences.filter(e => e.category === selectedFilter);
      })
    )
  }

}
