<form [formGroup]="stateFormService.profileForm">

  <div class="profile--section-info">
    <mat-form-field>
      <mat-label>{{ 'profile.first-name' | translate }}</mat-label>
      <input matInput [placeholder]="'profile.enter' | translate" type="text" name="first-name" formControlName="firstName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'profile.last-name' | translate }}</mat-label>
      <input matInput [placeholder]="'profile.enter' | translate" type="text" name="last-name" formControlName="lastName">
    </mat-form-field>
  </div>
  
  <div class="profile--section-social">
  
    <h2 translate="profile.social-media"></h2>
  
    <div class="profile--section-social-actions" *ngIf="service.selectedSocMed$$ | async as socMed">
      <div matRipple class="profile--section-social-button twitter" [class.active]="socMed === 'twitter'" (click)="service.updateSocialMedia('twitter', socMedInput)">
        <mat-icon svgIcon="page-profile-common.twitter-svg"></mat-icon>
      </div>
      <div matRipple class="profile--section-social-button facebook" [class.active]="socMed === 'facebook'" (click)="service.updateSocialMedia('facebook', socMedInput)">
        <mat-icon svgIcon="page-profile-common.facebook-svg"></mat-icon>
      </div>
      <div matRipple class="profile--section-social-button youtube" [class.active]="socMed === 'youtube'" (click)="service.updateSocialMedia('youtube', socMedInput)">
        <mat-icon svgIcon="page-profile-common.youtube-svg"></mat-icon>
      </div>
      <div matRipple class="profile--section-social-button pinterest" [class.active]="socMed === 'pinterest'" (click)="service.updateSocialMedia('pinterest', socMedInput)">
        <mat-icon svgIcon="page-profile-common.pinterest-svg"></mat-icon>
      </div>
      <div matRipple class="profile--section-social-button instagram" [class.active]="socMed === 'instagram'" (click)="service.updateSocialMedia('instagram', socMedInput)">
        <mat-icon svgIcon="page-profile-common.instagram-svg"></mat-icon>
      </div>
      <div matRipple class="profile--section-social-button tiktok" [class.active]="socMed === 'tiktok'" (click)="service.updateSocialMedia('tiktok', socMedInput)">
        <mat-icon svgIcon="page-profile-common.tiktok-svg"></mat-icon>
      </div>
      <div matRipple class="profile--section-social-button website" [class.active]="socMed === 'website'" (click)="service.updateSocialMedia('website', socMedInput)">
        <mat-icon svgIcon="page-profile-common.website-svg"></mat-icon>
      </div>
    </div>
    <br>
  
    <mat-form-field>
      <mat-label>{{ (service.socMedLabel$ | async)! | translate }}</mat-label>
      <input matInput [placeholder]="(service.socMedLabel$ | async)! | translate" name="social-media" type="text" #socMedInput>
      <button mat-icon-button class="add" (click)="service.addSocMed(socMedInput.value)"><mat-icon>add</mat-icon></button>
    </mat-form-field>
  
  </div>
  
  <mat-form-field>
    <mat-label>{{ 'profile.about' | translate }}</mat-label>
    <textarea matInput [placeholder]="'profile.enter' | translate" rows="4" type="text" autocomplete="off" name="user-desc" formControlName="userDesc"></textarea>
  </mat-form-field>
  
  <mat-form-field>
    <mat-label>{{ 'profile.fav-quote' | translate }}</mat-label>
    <textarea matInput [placeholder]="'profile.enter' | translate" rows="4" type="text" autocomplete="off" name="travel-quote" formControlName="travelQuote"></textarea>
  </mat-form-field>
  
  <h2 translate="profile.location"></h2>
  
  <div class="profile--section-field">
    <p translate="profile.hometown2"></p>
    <mat-form-field>
      <mat-label>{{ 'profile.hometown-location' | translate }}</mat-label>
      <input matInput [placeholder]="'profile.enter' | translate" type="text" name="hometown" formControlName="homeTown" #homeLocation>
    </mat-form-field>
  </div>
  
  <div class="profile--section-field">
    <p translate="profile.currently-in2"></p>
    <mat-form-field>
      <mat-label>{{ 'profile.curr-location' | translate }}</mat-label>
      <input matInput [placeholder]="'profile.enter' | translate" type="text" name="curr-loc" formControlName="currLoc" #currentLocation>
    </mat-form-field>
  </div>
  
  <h2 translate="profile.personal"></h2>
  
  <div class="profile--section-field">
    <p translate="profile.relationship"></p>
    <mat-form-field>
      <mat-label>{{ 'profile.relationship' | translate }}</mat-label>
      <mat-select formControlName="rStatus">
        <mat-option value="Single" translate="profile.single"></mat-option>
        <mat-option value="Taken" translate="profile.taken"></mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <div class="profile--section-field">
    <p translate="profile.birthday2"></p>
    <mat-form-field>
      <mat-label>{{ 'profile.choose-date' | translate }}</mat-label>
      <input matInput readonly [matDatepicker]="picker" (click)="picker.open()" name="date-of-birth" formControlName="dob">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  
</form>
