import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class RelatedToursService {

  @Select(ItineraryState.resultRelatedTours) resultRelatedTours$: Observable<ImageResultData[]>;

  constructor(
    public store: Store
  ) { }

  get cachedData(): { imageInformation: ImageResultData, resultRelatedTours: ImageResultData[] } {
    return {
      imageInformation: this.store.selectSnapshot(ItineraryState.imageInformation)!,
      resultRelatedTours: this.store.selectSnapshot(ItineraryState.resultRelatedTours)!
    }
  }

}
