import { Provider } from "@angular/core";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from "@angular/material/datepicker";
import * as moment from 'moment';

import { FormDateRange } from "../../shared";

export function formDateRange({ start, end }: FormDateRange): DateRange<moment.Moment> {
  const startDate = moment(start).utc().add(1, 'day').startOf('day').toISOString().split('T')[0];
  const endDate = moment(end).utc().add(1, 'day').startOf('day').toISOString().split('T')[0];
  return new DateRange(moment(startDate), moment(endDate))
}

export const CalendarDateRangeProviders: Provider[] = [
  {
    provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
    useClass: DefaultMatCalendarRangeStrategy,
  },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
  }
];
