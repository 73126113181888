import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom } from 'rxjs';

import { SocialApiService } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { setInstagramDIsplay } from './common';

@Directive({
  selector: '[instagramIframe]'
})
export class InstagramIframeDirective implements OnInit, OnDestroy {

  parentElement: HTMLDivElement;
  destroy$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private store: Store,
    private api: SocialApiService
  ) { }

  get instagramData(): Promise<any> {
    return firstValueFrom(this.api.getInstagramSearch(
      this.store.selectSnapshot(BrandState.campaignCustom)?.socialLinks?.instaId || 'HipTraveler'
    ));
  }

  async ngOnInit(): Promise<void> {

    this.parentElement = this.renderer.parentNode(this.element.nativeElement);

    const data = await this.instagramData;

    if (data.error) {
      setInstagramDIsplay(false);
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
