import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { RegistrationFormModule } from '@hiptraveler/features/registration-form';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { TravelAgentRegisterComponent } from './travel-agent-register.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  RegistrationFormModule,
  ButtonsModule
];

const COMPONENTS = [
  TravelAgentRegisterComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: TravelAgentRegisterComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ],
})
export class TravelAgentRegisterModule { }
