import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { TaCardModule } from '@hiptraveler/ui/ta-card';
import { ActionActiveModule } from '@hiptraveler/features/itinerary';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { HighlightCardComponent } from './highlight-card.component';
import { FollowProcessingModule } from '../follow-processing.pipe';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  TaCardModule,
  ButtonsModule,
  ActionActiveModule,
  FollowProcessingModule
];

const COMPONENTS = [
  HighlightCardComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    HighlightCardComponent
  ]
})
export class HighlightCardModule { }
