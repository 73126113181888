import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, skip, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Component({
  selector: 'yelp-reviews',
  templateUrl: './yelp-reviews.component.html',
  styleUrls: ['./yelp-reviews.component.scss']
})
export class YelpReviewsComponent implements OnInit {

  visibility: boolean = true;

  @Input() imageInformation?: ImageResultData | null;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    (this.store.select(ItineraryState.imageInformation) as any).pipe(
      skip(1),
      map((response: ImageResultData) => (this.visibility = 
        !!response?.yelpData
      )),
      take(1)
    ).subscribe();
  }

}
