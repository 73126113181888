
<div class="ambassador--banner" [class.disabled]="!(service.authenticated$ | async)" (click)="service.verifyAuthentication()">
  <div class="ambassador--banner-overlay"></div>
  <img [lazyLoad]="service.destinationSpecialistImg" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Travel destination banner">
</div>

<div class="ambassador--content" [class.disabled]="!(service.authenticated$ | async)" (click)="service.verifyAuthentication()">
  
  <div class="ambassador--content-pane">
    <div class="ambassador--content-pane-left">
      
      <img [lazyLoad]="(service.ambassadorHighlightImage$ | async)!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Casa Creadora">
      
      <h3 translate="ambassador.apply-to-casa-creadora"></h3>
      <h3 translate="ambassador.and-gain-access"></h3>
      <h3 translate="ambassador.and-properties">.</h3>

      <h3 class="info-text" translate="ambassador.apply-to-casa-creadora-full"></h3>
    
      <ui-action-button rounded (click)="tourAgentApply()">{{ 'ambassador.apply-to-casa-creadora-btn' | translate }}</ui-action-button>
  
    </div>
    <div class="ambassador--content-pane-right">

      <iframe [src]="youtubeUrl | safeUrl" frameborder="0" allowfullscreen></iframe>
  
    </div>
  </div>

  <br><br class="web"><br class="web"><br class="web">
  <registration-form socialMediaEvent type="ambassador"></registration-form>

</div>
