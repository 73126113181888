import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ProfileCardModule } from '@hiptraveler/ui/profile-card';

import { FollowersTabComponent } from './followers-tab.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  ProfileCardModule
];

const COMPONENTS = [
  FollowersTabComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    FollowersTabComponent
  ]
})
export class FollowersTabModule { }
