import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogCloseButtonComponent } from './dialog-close-button.component';
import { DialogCloseButtonLtsComponent } from './dialog-close-lts.component';

const MODULES = [
  MatButtonModule,
  MatIconModule,
  MatDialogModule
];

const COMPONENTS = [
  DialogCloseButtonComponent,
  DialogCloseButtonLtsComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    DialogCloseButtonComponent,
    DialogCloseButtonLtsComponent
  ]
})
export class DialogCloseButtonModule { }
