import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, filter } from 'rxjs';

import { SearchResultData } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class LeafletMapControlStateService {

  activeSearchResultData$$ = new BehaviorSubject<SearchResultData | null>(null);
  activeSearchResultData$ = this.activeSearchResultData$$.asObservable().pipe(filter(Boolean), delay(0));

  allSearchResultsData$$ = new BehaviorSubject<SearchResultData[]>([]);
  allSearchResultsData$ = this.allSearchResultsData$$.asObservable().pipe(
    filter(e => !!e?.length),
    delay(0)
  );

}
