import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value?: string): string {
    return `url("https://cdn.filestackcontent.com/A5yG40niNR3q9y7GBglCLz/resize=width:240/auto_image/compress/${value}")`;
  }

}
