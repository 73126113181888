import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SkyscannerWidgetDirectiveModule } from '@hiptraveler/common';

import { SkyscannerViewComponent } from './skyscanner-view.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  SkyscannerWidgetDirectiveModule
];

const COMPONENTS = [
  SkyscannerViewComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SkyscannerViewComponent
  ]
})
export class SkyscannerViewModule { }
