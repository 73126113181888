import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CustomActivityDialogComponent } from './custom-activity-dialog.component';

export const CUSTOM_ACTIVITY_DIALOG_ID = 'custom-activity';

@Injectable()
export class CustomActivityDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(formGroup: FormGroup): MatDialogRef<CustomActivityDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(CUSTOM_ACTIVITY_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(CustomActivityDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: CUSTOM_ACTIVITY_DIALOG_ID,
      panelClass: CUSTOM_ACTIVITY_DIALOG_ID + '-overlay-panel',
      width: '90vw',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: '100dvh',
      maxHeight: '100dvh',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: formGroup
    })
  }

}
