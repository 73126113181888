<div class="host--content-travel-flight-wrapper" travelDetailsStyles [ngStyle]="{ 'background-image': 'url('+(service.travelImage$ | async)+')' }">
  <div class="host--content-travel-flight">
    <div></div>
    <div class="host--content-travel-flight-icon-svg">
      <div class="flight-icon-svg">
        <svg xmlns="https://www.w3.org/2000/svg" width="25px" height="25px" fill="#fff" viewBox="0 0 24 24">
          <path d="M3.691 10h6.309l-3-7h2l7 7h5c1.322-.007 3 1.002 3 2s-1.69 1.993-3 2h-5l-7 7h-2l3-7h-6.309l-2.292 2h-1.399l1.491-4-1.491-4h1.399l2.292 2"></path>
        </svg>
      </div>
      <p>{{ service.userLoc$ | async }}</p>
    </div>
    <div matRipple class="host--content-travel-flight-nav-icon" [class.disabled]="travelIndex === 0" (click)="navigateLocation('left')">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="host--content-travel-flight-loc-icon-left-buffer" [class.animate-right]="animateRight">
      <p>{{ nextTravelCity }}</p>
    </div>
    <div matRipple class="host--content-travel-flight-loc-icon" [class.animate-left]="animateLeft" [class.animate-right]="animateRight">
      <p *ngIf="prevTravelCity;else processing">{{ prevTravelCity }}</p>
      <ng-template #processing>
        <mat-progress-spinner theme="white" mode="indeterminate" strokeWidth="2" diameter="20"></mat-progress-spinner>
      </ng-template>
    </div>
    <div class="host--content-travel-flight-loc-icon-right-buffer" [class.animate-left]="animateLeft">
      <p>{{ nextTravelCity }}</p>
    </div>
    <div matRipple class="host--content-travel-flight-nav-icon" [class.disabled]="(travelIndex+1) === (service.locationList$ | async)?.length" (click)="navigateLocation('right')">
      <mat-icon>chevron_right</mat-icon>
    </div>
    <div class="host--content-travel-flight-icon-svg">
      <div class="flight-icon-svg">
        <svg xmlns="https://www.w3.org/2000/svg" width="25px" height="25px" fill="#fff" viewBox="0 0 24 24">
          <path d="M3.691 10h6.309l-3-7h2l7 7h5c1.322-.007 3 1.002 3 2s-1.69 1.993-3 2h-5l-7 7h-2l3-7h-6.309l-2.292 2h-1.399l1.491-4-1.491-4h1.399l2.292 2"></path>
        </svg>
      </div>
      <p>{{ service.userLoc$ | async }}</p>
    </div>
    <div></div>
    <div class="host--content-travel-flight-hr-wrapper">
      <div class="host--content-travel-flight-hr"></div>
    </div>
  </div>
  <div matRipple class="host--content-travel-flight-button" (click)="openDialog()">
    <p translate="itinerary.check-flights"></p>
  </div>
</div>
