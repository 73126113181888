<form class="auth-dialog--form" (ngSubmit)="service.signInWithEmailAndPassword()" [formGroup]="service.form">
  
  <div class="auth-dialog--form-field">
    <mat-form-field>
      <mat-label>{{ 'auth.email' | translate }}</mat-label>
      <input matInput type="text" autocomplete="username" name="username" formControlName="username">
      <mat-error *ngIf="controlError('username')">{{ 'auth.invalid-email-format' | translate }}</mat-error>
      <mat-error *ngIf="requiredControl('username')">{{ 'auth.email-required' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div class="auth-dialog--form-field">
    <mat-form-field>
      <mat-label>{{ 'auth.password' | translate }}</mat-label>
      <input matInput type="password" autocomplete="current-password" name="password" formControlName="password">
      <mat-error *ngIf="controlError('password')">{{ 'auth.invalid-password-format' | translate }}</mat-error>
      <mat-error *ngIf="requiredControl('password')">{{ 'auth.password-required' | translate }}</mat-error>
    </mat-form-field>
    <span (click)="forgotPasswordClicked.emit()" translate="auth.forgot-password"></span>
  </div>

    <ui-action-button type="submit" [pending]="!!(service.pending$$ | async)">{{ 'auth.go' | translate }}</ui-action-button>

</form>
