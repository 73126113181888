import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'loader-screen',
  template: '',
  styleUrls: ['./loader-screen.component.scss'],
  host: { class: 'experience-finder-ai-loader-screen' },
  encapsulation: ViewEncapsulation.None
})
export class LoaderScreenComponent { }
