
<cover-image [title]="title" [coverImage]="coverImage" [author]="author"></cover-image>

<div class="page-toolbar">

  <user-profile [author]="author" [owner]="owner"></user-profile>

  <img [lazyLoad]="author?.authorProfPic || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" [alt]="author?.authorTitle" (click)="navigateToUserProfile()">  
  
  <div class="page-toolbar--rounded" [class.clipped]="(navbarControl.navbarToolbarPosition$ | async) === 'navbar'">
    <toolbar class="page-toolbar--component" component scrollToView [selections]="selections" *ngIf="!(efState$ | async)"></toolbar>
    <p class="page-toolbar--option" *ngFor="let item of selections?.slice(0, 2);">{{ item.name }}</p>
  </div>

</div>
