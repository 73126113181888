import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subject, fromEvent, map, takeUntil } from 'rxjs';

@Directive({
  selector: '[scrollElementBorder]'
})
export class ScrollElementBorderDirective implements OnDestroy, AfterViewInit {

  @Input() adjustOffset: string;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    /**
     * Temporarily disable the top border when the parent element is scrolled, as per the client's request.
     * This change affects all implementations throughout the entire application.
     * Upon client approval of this UX change, please remove the following line of code.
     */
    return;

    const element = this.element.nativeElement;

    if (this.adjustOffset === '' && element.scrollHeight === element.clientHeight) {
      element.style.paddingRight = '12px';      
    }

    const scroll$ = fromEvent(this.element.nativeElement, 'scroll').pipe(
      map(() => this.element.nativeElement.scrollTop)
    );
    
    scroll$.pipe(takeUntil(this.subscription$)).subscribe((scrollTopValue: number) => {
      this.element.nativeElement.style.borderTop = scrollTopValue > 0 ? '1px solid #525558' : 'none';
    });
  }

}
