import { Component, OnInit } from '@angular/core';

import { AppService } from './app.service';
import { AppListenerService } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [ opacityFade() ],
  viewProviders: [ AppService ]
})

export class AppComponent implements OnInit {

  constructor(
    public appListener: AppListenerService,
    public service: AppService
  ) { }

  ngOnInit(): void {
    this.service.setup();
  }

}
