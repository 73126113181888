import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, firstValueFrom } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { DialogType } from './common';

@Injectable()
export class SearchResultDialogService {
  
  @Select(ItineraryState.hotelInformation) hotelInformation$: Observable<HotelResultData>;
  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;

  #dialogType: DialogType = 'adventure';

  constructor(
    private store: Store,
  ) { }

  get dialogType(): DialogType {
    return this.#dialogType;
  }

  async getResultInformation(data: any): Promise<void> {
    this.#dialogType = data['dialogType'];
    try {
      switch (this.dialogType) {
        case 'adventure': {
          const payload = data as ImageResultData;
          const promises = [
            new ItineraryAction.GetImageInformation({ id: payload.id || '' }, data),
            new ItineraryAction.GetResultRelatedTours({ id: payload.id || '' })
          ].map(e => firstValueFrom(this.store.dispatch(e)));
          await Promise.all(promises);
          break;
        }
        case 'hotel': {
          const payload = data as HotelResultData;
          const promises = [
            new ItineraryAction.GetHotelInformation({ id: payload.id || '' }, data)
          ].map(e => firstValueFrom(this.store.dispatch(e)));
          await Promise.all(promises);
          break;
        }
        case 'food': {
          const payload = data as ImageResultData;
          const promises = [
            new ItineraryAction.GetFoodAndDrinkDialogData({ id: payload?.id || '' })
          ].map(e => firstValueFrom(this.store.dispatch(e)));
          await Promise.all(promises);
          break;
        }
      }
    } catch (e) { }
  }

  async resetState(): Promise<void> {
    return firstValueFrom(this.store.dispatch(new ItineraryAction.PartialResetItineraryState([ 'imageInformation', 'hotelInformation', 'resultRelatedTours' ])));
  }

}
