<div class="stepper-buffer" *ngIf="service.showOverlayStepper$ | async"></div>
<div class="stepper-wrapper" overlayVariableWidth [class.overlay]="service.showOverlayStepper$ | async">
  <div class="stepper">
  
    <div class="stepper--path"></div>
    
    <ng-container *ngIf="stateService.activeSelection$ | async as selection">
      <div class="stepper--header-item-container mat-elevation-z2" [class.active]="selection === 'general-information'">
        <div class="stepper--header-item" scrollToView="general-information">
          <p matRipple class="stepper--header-text ht-digit">{{ 1 | headerValueByIndex: type | translate }}</p>
        </div>
      </div>
      
      <div class="stepper--header-item-container mat-elevation-z2" [class.active]="selection === 'your-specialties'">
        <div class="stepper--header-item" scrollToView="your-specialties">
          <p matRipple class="stepper--header-text ht-digit">{{ 2 | headerValueByIndex: type | translate }}</p>
        </div>
      </div>
      
      <div class="stepper--header-item-container mat-elevation-z2" [class.active]="selection === 'register-your-interest'">
        <div class="stepper--header-item" scrollToView="register-your-interest">
          <p matRipple class="stepper--header-text ht-digit">{{ 3 | headerValueByIndex: type | translate }}</p>
        </div>
      </div>
    </ng-container>

  </div>
</div>
