import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { TaCardComponent } from './ta-card.component';
import { TaComponents, TaExportComponents } from './ta-card-ui';
import { TaCardDirectives, TaCardExportDirectives } from './ta-card-directive';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule
];

const COMPONENTS = [
  TaCardComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    TaComponents,
    TaCardDirectives
  ],
  exports: [
    TaCardComponent,
    TaExportComponents,
    TaCardExportDirectives
  ]
})
export class TaCardModule { }
