import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { map, of, switchMap, tap } from 'rxjs';

import { BrandStateModel, BrandAction } from '.';
import * as API from '@hiptraveler/data-access/api';
import * as Helper from './helpers';
import * as Brand from '.';
import { hiptravelerBrandInfo } from './brand-info';
import { isWidget, subdomain } from '@hiptraveler/common';

export const BRAND_STATE_TOKEN = new StateToken<Brand.BrandStateModel>('state_brand');

export const brandDefaults: any = null;

@State<Brand.BrandStateModel>({
  name: BRAND_STATE_TOKEN,
  defaults: brandDefaults
})

@Injectable()
export class BrandState {

  @Selector()
  static state(state: BrandStateModel): Brand.BrandStateModel | null {
    return state || null;
  }

  @Selector()
  static brandCampaign(state: BrandStateModel): Partial<API.BrandCampaign> | null {
    if (state?.brandCampaign?.cId !== 'hiptraveler' && state?.brandCampaign?.cTagline) {
      state.brandCampaign.cTagline = `${state.brandCampaign.cTagline} Powered by HipTraveler.`
    }
    return state?.brandCampaign || null;
  }

  @Selector()
  static campaignCustom(state: BrandStateModel): Partial<API.CampaignCustom> | null {
    return state?.brandCampaign?.campaignCustom || null;
  }

  @Selector()
  static hiptravelerBrand(state: BrandStateModel): Partial<API.BrandCampaign> | null {
    return subdomain() === 'hiptraveler' ? (state?.brandCampaign || null) : null;
  }

  @Selector()
  static experiences(state: BrandStateModel): Partial<API.Experience>[] | null {
    return state?.experiences || null;
  }

  @Selector()
  static locations(state: BrandStateModel): API.LocationData[] | null {
    return state?.cLocations || null;
  }

  @Selector()
  static defaultCountry(state: BrandStateModel): API.LocationData | null {
    return state?.countryList?.[0] || null;
  }

  constructor(
    private http: HttpClient,
    private brandApi: API.BrandApiService
  ) { }

  @Action(BrandAction.GetBrandInfo)
  getBrandInfo(ctx: StateContext<BrandStateModel>) {

    if (subdomain() === 'hiptraveler' && !isWidget()) return of(hiptravelerBrandInfo).pipe(
      switchMap((data: any) => this.http.get('assets/base64-collection/trip-planner-image.json').pipe(
        map((response: any) => {
          data['brandCampaign']['campaignCustom']['customCss']['tripPlannerSvg'] = response.value
          return data;
        })
      )),
      tap((data: Partial<API.BrandInfo>) => {
        return Helper.patchBrandInfoResponse({ ctx, data, brandApi: this.brandApi });
      })
    );
      
    return this.brandApi.getBrandInfo().pipe(
      tap(API.validateResponse),
      tap(({ data }: API.BrandInfoResponse) => Helper.patchBrandInfoResponse({ ctx, data, brandApi: this.brandApi }))
    );
  }

  @Action(BrandAction.BrandStateReset)
  reset(ctx: StateContext<BrandStateModel>) {
    ctx.setState(brandDefaults);
  }

}
