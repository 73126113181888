import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, filter, firstValueFrom, takeUntil } from 'rxjs';

import { SearchAction } from '@hiptraveler/data-access/search';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SnackbarService } from '@hiptraveler/snackbar';
import { promiseDelay, RequestCancellationService, SearchLocationService, toSearchLocation } from '@hiptraveler/common';
import { FiltersService } from '../filters.service';

const uxDelay: number = 10;

@Directive({
  selector: '[nameFilterAction]'
})
export class NameFilterActionDirective implements OnInit, OnDestroy {

  $subscription = new Subject<void>();

  pending: boolean = false;

  constructor(
    private element: ElementRef<HTMLInputElement>,
    private store: Store,
    private snackbar: SnackbarService,
    private searchLocation: SearchLocationService,
    private service: FiltersService,
    private requestCancellation: RequestCancellationService
  ) { }

  ngOnInit(): void {
    this.searchLocation.searchLocation$.pipe(
      filter(() => !this.pending),
      takeUntil(this.$subscription)
    ).subscribe(this.resetState.bind(this));
  }

  ngOnDestroy(): void {
    this.$subscription.next();
  }

  @HostListener('click')
  async hostClick(): Promise<void> {
    
    const defaultLocations = this.store.selectSnapshot(BrandState.locations)?.[0];
    this.element.nativeElement.readOnly = false;

    if (!this.searchLocation.data && defaultLocations) {
      this.searchLocation.updateSearchLocation(toSearchLocation(defaultLocations));
      this.pending = true;
      await promiseDelay(uxDelay);
      this.pending = false;
    }

    if (this.searchLocation.data || this.service.path === 'community') return;

    setTimeout(() => {
      this.resetState();
      this.element.nativeElement.readOnly = true;
      this.snackbar.open({ message: 'Please input a location first.' });
    }, 150);
  }

  @HostListener('keydown.enter')
  async hostClicked(): Promise<void> {

    const input = this.element.nativeElement.value;

    if (!input) return;

    this.resetState();
    await this.requestCancellation.cancelAllSearchRequests();
    switch (this.service.path) {
      case 'hotels': return this.hotels(input);
      case 'thingstodo': return this.adventures(input);
      case 'foodanddrink': return this.foods(input);
      case 'community': return this.community(input);
    }
  }
  
  private async hotels(input: string): Promise<void> {
    if (!this.searchLocation.data) return;
    try {
      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'hotels', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetHotelsTermByQuery({
        query: input,
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1'
      })));
    } finally {}
  }

  private async adventures(input: string): Promise<void> {
    if (!this.searchLocation.data) return;
    try {
      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'adventures', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetAdventureTermByQuery({
        query: input,
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1'
      })));
    } finally {}
  }
  
  private async foods(input: string): Promise<void> {
    if (!this.searchLocation.data) return;
    try {
      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'foods', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetFoodsTermByQuery({
        query: input,
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1'
      })));
    } finally {}
  }
  
  private async community(input: string): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'communities', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetCommunitiesByQuery({
        keyword: input,
        location: this.searchLocation.data?.location || '',
        searchCnt: '1'
      })));
    } finally {}
  }

  private resetState(): void {
    setTimeout(() => {
      this.element.nativeElement.blur();
      this.service.overlayState$$.next(false);
    }, uxDelay);
  }

}
