import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityDateModule } from './activity-date';
import { ScrollElementBorderDirectiveModule, ScrollToViewDirectiveModule } from '@hiptraveler/common';

import { ActivityDateListComponent } from './activity-date-list.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ActivityDateModule,
  ScrollToViewDirectiveModule,
  ScrollElementBorderDirectiveModule
];

const COMPONENTS = [
  ActivityDateListComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityDateListComponent
  ]
})
export class ActivityDateListModule { }
