import { Injectable, NgModule, Optional } from '@angular/core';
import { Actions } from '@ngxs/store';

import { ActivityApiService } from '@hiptraveler/data-access/api';
import { ProfileAction } from '@hiptraveler/data-access/profile';
import { AnalyticsService } from '../analytics.service';
import * as Helper from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class ProfileStateService extends AnalyticsService {

  constructor(
    actions$: Actions,
    api: ActivityApiService
  ) {
    
    super(actions$);

    this.observeActionDispatched(
      ProfileAction.ToggleProfileResultLike,
      (param: ProfileAction.ToggleProfileResultLike) => api.setUserAction({
        actionType: 'hip',
        objType: Helper.getHipObjectTypeByField(param?.data?.field)
      }, {
        actionId: param.data.id,
      })
    );
  }

}

@NgModule()
export class ProfileStateModule {
  constructor(
    @Optional() service: ProfileStateService
  ) { }
}
