<ng-container *ngIf="service.overlayState$ | async">
  <div class="filters-overlay" (click)="service.hideOverlay()" [@opacityFade]></div>
</ng-container>

<form formStyles formListener [formGroup]="service.form" [class.overlay]="service.overlayState$ | async">

  <ng-container *ngIf="!(view.communityVisibility$ | async)">
    <h3 class="filters--input-label" [translate]="(service.filterTitle$ | async)!"></h3>
    <div class="filters--input-container">
      <div customInputBorder customMapAutocomplete emitOnly targetElement="input" class="input-field" (locationChanged)="locationChanged($event)">
        <input type="text" autocomplete="off" name="location" [placeholder]="(service.filterInputPlaceholder$ | async)! | translate" formControlName="location" (focus)="service.showOverlay()" #searchLocation>
        <mat-icon class="caret" [class.active]="appListener.searchFilterDropdownState$ | async" (click)="searchLocation.focus()">expand_more</mat-icon>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="(view.searchVisibility$ | async) || (view.communityVisibility$ | async);else nonSearchView">
    <br>
    <h3 class="filters--input-label" translate="search-page.search-by-name"></h3>
  </ng-container>
  <ng-template #nonSearchView>
    <div class="filters--input-label-spacer"></div>
  </ng-template>
  
  <div class="filters--input-container">

    <div customInputBorder targetElement="input" class="input-field" *ngIf="view.nameVisibility$ | async">
      <input type="text" nameFilterAction autocomplete="off" name="query" [placeholder]="'search-page.name' | translate" formControlName="query" (focus)="service.showOverlay()" (blur)="service.hideOverlay()" #nameFilter>
      <ng-container *ngIf="nameFilter.value">
        <mat-icon class="input-field--close" clearNameAction [@opacityFade]>close</mat-icon>
      </ng-container>
    </div>

    <div customInputBorder travelDatesField targetElement="input" class="input-field input-field-selection" (click)="service.openCalendar(picker)" *ngIf="!(view.communityVisibility$ | async)">
      <input type="text" autocomplete="off" [placeholder]="'search-page.travel-dates' | translate" name="travel-dates" readonly [value]="service.form.value | toLocalTime">
      <ng-container *ngIf="startDateFilter.value">
        <mat-icon class="input-field--close" [@opacityFade] (click)="service.clearCalendarField()">close</mat-icon>
      </ng-container>
      <mat-form-field appearance="fill">
        <mat-date-range-input [rangePicker]="picker" [min]="minDate">
          <input matStartDate name="check-in-dates" formControlName="checkInDate" #startDateFilter>
          <input matEndDate name="check-out-dates" formControlName="checkOutDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker panelClass="search-page-root-filter-calendar-picker" (closed)="service.hideOverlay()" #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <ng-container *ngIf="(service.experiences$ | async)?.length">
      <div class="input-field input-field-selection" *ngIf="(view.travelStyleVisibility$ | async) && !(view.communityVisibility$ | async)">
        <ng-container *ngIf="view.listItems$ | async as listItems">
          <list-overlay travelStyle [data]="listItems.activities"></list-overlay>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <ng-container *ngIf="(service.experiences$ | async)?.length">
    <ng-container *ngIf="(view.searchVisibility$ | async) && !(view.communityVisibility$ | async);">
      <ng-container *ngIf="view.listItems$ | async as listItems">
        <br>
        <h3 class="filters--input-label">{{ view.checkboxLabel$ | cbLabelI18n | async }}</h3>
        <div class="filters--input-container checkbox">

          <ng-container *ngIf="(view.checkboxLabel$ | async) === 'Activities';else defaultCheckboxList">
            <mat-checkbox *ngFor="let item of listItems.display" (click)="toggleSelection(listItems.display, item.value);" [id]="item.value" [name]="item.name" [value]="item.value" [checked]="item | checked | async">{{ (item?.name || '') | translate }}</mat-checkbox>
          </ng-container>
          <ng-template #defaultCheckboxList>
            <mat-checkbox *ngFor="let item of listItems.display" (click)="toggleSelection(listItems.display, item.value);" [id]="item.value" [name]="item.name" [value]="item.value">{{ ("views.label." + item?.name) | translate }}</mat-checkbox>
          </ng-template>

          <list-overlay [data]="listItems.data" [header]="view.checkboxLabel$ | async"></list-overlay>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  
  <br>
  
  <div class="filters--actions" *ngIf="view.filterActionsVisibility$ | async">

    <ui-action-button size="small" rounded applyAction>{{ 'search-page.apply' | translate }}</ui-action-button>

    <ng-container *ngIf="service.filters$$ | async">
      <ui-action-button size="small" theme="white" rounded clearAction [@opacityFade]>{{ 'search-page.clear' | translate }}</ui-action-button>
    </ng-container>

  </div>
  
</form>
