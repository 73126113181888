import { Pipe, PipeTransform } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { ItineraryReservation } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Pipe({
  name: 'checklistBoxState'
})
export class ChecklistBoxStatePipe implements PipeTransform {

  @Select(ItineraryState.itineraryReservations) itineraryReservations$: Observable<ItineraryReservation[] | null>;

  /**
   * Transforms an item ID into an Observable boolean indicating whether it's booked.
   *
   * @param id The ID of the itinerary item to check for booking status.
   * @returns An Observable boolean indicating whether the item is booked.
   */
  transform(id: string, booked: boolean = true): Observable<boolean> {
    return this.itineraryReservations$.pipe(
      map(
        (reservations: ItineraryReservation[] | null) => !!reservations?.find(
          e => e?.objId === id && (booked ? e.booked : true)
        )
      ),
    );
  }

}
