
export function addUserLike(data: any[], id: string, userId: string, count: number): any[] {
  data = data || [];
  const profileResult = data.find(obj => obj.id === id);
  console.log('@@@ ', profileResult.id, profileResult.title);
  if ([ 'poi', 'itinerary', 'blog' ].includes(profileResult?.type)) {
    profileResult.userLikes = profileResult?.userLikes || [];
    profileResult.userLikes.push(userId);
  }
  if (profileResult?.type === 'hotel') {
    profileResult.likes = profileResult?.likes || [];
    profileResult.likes.push(userId);
  }
  return data;
};

export function removeUserLike(data: any[], id: string, userId: string, count: number): any[] {
  data = data || [];
  const profileResult = data.find(obj => obj.id === id);
  console.log('@@@ ', profileResult.id, profileResult.title);
  if ([ 'poi', 'itinerary', 'blog' ].includes(profileResult?.type)) {
    profileResult.userLikes = profileResult?.userLikes || [];
    profileResult.userLikes = profileResult.userLikes.filter((user: string) => user.slice(0, 7) !== userId.slice(0, 7)); 
  }
  if (profileResult?.type === 'hotel') {
    profileResult.likes = profileResult?.likes || [];
    profileResult.likes = profileResult.likes.filter((user: string) => user.slice(0, 7) !== userId.slice(0, 7)); 
  }
  return data;
};
