import { getWindowRef } from "./utilities";

export function isLocalhost(): boolean {
  const _window = getWindowRef();
  return _window?.location.hostname === 'localhost'
    || _window?.location.hostname === '127.0.0.1'
    || _window?.location.hostname === '[::1]';
}

export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(character: string) {

    const random = Math.random() * 16 | 0;
    const value = character == 'x' ? random : (random & 0x3 | 0x8);

    return value.toString(16);
  });
}

export function randomId(value: { length: number }): string{
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < value.length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
}

export function removeCircularReferences(): any {
  const seen = new WeakSet();
  return function(_: any, value: any) {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return undefined;
      }
      seen.add(value);
    }
    return value;
  };
}
