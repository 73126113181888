import { Directive } from '@angular/core';

@Directive({
  selector: '[taBackgroundImage]',
  host: { class: 'ta-background-image' }
})
export class TaBackgroundDirective {}

@Directive({
  selector: '[taProfileImage]',
  host: { class: 'ta-profile-image' }
})
export class TaProfileImageDirective {}

export const TaCardExportDirectives = [
  TaBackgroundDirective,
  TaProfileImageDirective
];

export const TaCardDirectives = [
  TaCardExportDirectives
];
