import { MetaTagOptions, Metadata, brandName } from "@hiptraveler/features/meta-tag";
import { PageType } from "@hiptraveler/common";

export const defaultMetaTagData: Metadata = {

  title: 'Write a travel story | Plan a trip | Book cheap flights & hotels | Meet fellow travelers - HipTraveler',

  description: 'Our custom trip planner tool - easy to use, easy to create and/or customize your trips. Search places, adventures, activities, things to do, hotels, flights based on location or keywords and create itineraries and travel stories and share with other travelers around the world.',

  imageUrl: 'https://c.tile.openstreetmap.org/2/2/1.png',

  author: 'HipTraveler Core',
  
  url: 'https://www.hiptraveler.com/',
  
  type: 'website',

  keywords: `${brandName()} trip planner, places, attractions, adventure, activities, food, drinks, places to stay, travel stories, itineraries, fellow travelers.`

}

export function metaTagValue({ location, url }: MetaTagOptions): Record<PageType, Metadata> {
  return {

    // shell--default-page-root

    travel: {
      ...defaultMetaTagData, url,
      // ex. Book Flights, Book Car Rental | HipTraveler
      title: `Book Flights, Book Car Rental | ${brandName()}`,
      description: `${brandName()} Trip Planner. Plan your next trip. Bundle your next trip with with a car rental or flight and you can save more.`
    },

    ambassador: {
      ...defaultMetaTagData, url,
      // ex. Become Ambassador | Casa Creadora | Earn rewards | HipTraveler
      title: `Become Ambassador | Casa Creadora | Earn rewards | ${brandName()}`
    },

    dnsmpi: {
      ...defaultMetaTagData, url,
      // ex. Do Not Sell My Personal Information | HipTraveler
      title: `Do Not Sell My Personal Information | ${brandName()}`,
    },

    privacyPolicy: {
      ...defaultMetaTagData, url,
      // ex. Privacy Policy | HipTraveler
      title: `Privacy Policy | ${brandName()}`,
      keywords: `privacy policy, terms of service, search results, places, attractions, adventure, activities, food, drinks, places to stay, travel stories, itineraries`
    },

    terms: {
      ...defaultMetaTagData, url,
      // ex. Terms And Conditions | HipTraveler
      title: `Terms And Conditions | ${brandName()}`,
      keywords: `privacy policy, terms of service, search results, places, attractions, adventure, activities, food, drinks, places to stay, travel stories, itineraries`
    },

    profile: {
      ...defaultMetaTagData, url,
      // ex. Profile | HipTraveler
      title: `Profile | ${brandName()}`
    },

    ['travel-agent-register']: {
      ...defaultMetaTagData, url,
      // ex. Destination Specialist Program | HipTraveler
      title: `Destination Specialist Program | ${brandName()}`
    },

    // shell--search-page-root

    search: {
      ...defaultMetaTagData, url,
      // ex. "Paris Trip Planner | HipTraveler" / "Trip Planner | HipTraveler"
      title: `${location ? `${location} ` : ``}Trip Planner | ${brandName()}`,
      description: `Get inspired by visual imagery results based on a location or keywords and plan your trip.`,
      keywords: `${brandName()} trip planner, places, attractions, adventure, activities, food, drinks, places to stay, travel stories, itineraries, fellow travelers.`
    },

    experiences: {
      ...defaultMetaTagData, url,
      // ex. "Travel Blogs and Itineraries for Paris | HipTraveler" / "Travel Blogs and Itineraries | HipTraveler"
      title: `Travel Blogs and Itineraries ${location ? `for ${location} ` : ``}| ${brandName()}`,
      description: `Hip experiences all around ${brandName()}.`,
      keywords: `${brandName()} trip planner, itineraries, itineraries, travel stories, travel blogs, travel experiences, trip planning`
    },

    thingstodo: {
      ...defaultMetaTagData, url,
      // ex. "Things to do in Paris | HipTraveler" / "Things to do | HipTraveler"
      title: `Things to do ${location ? `in ${location} ` : ``}| ${brandName()}`,
      description: `Things to do | ${brandName()} | Explore things to do, tour, activities by adding your custom travel styles and build your next trip`,
      keywords: `${brandName()} trip planner, things to do, places to go, hip places, attractions, must see, inspirations, nature, cultural, historic, artistic, scenic, beaches`
    },

    hotels: {
      ...defaultMetaTagData, url,
      // ex. "Best hotels in Paris | HipTraveler" / "Best hotels | HipTraveler"
      title: `Best hotels ${location ? `in ${location} ` : ``}| ${brandName()}`,
      description: `Find best deals on hotels on ${brandName()} and filter results by your custom travel style`,
      keywords: `${brandName()} trip planner, top hotels, boutique hotels, suites, bed and breakfast, resorts, apartments and condos, all-inclusive`
    },

    foodanddrink: {
      ...defaultMetaTagData, url,
      // ex. "Paris - Best restaurants | HipTraveler" / "Best restaurants | HipTraveler"
      title: `Best restaurants ${location ? `in ${location} ` : ``}| ${brandName()}`,
      description: `Find best restaurants for ${brandName()} and filter results by your custom travel style`,
      keywords: `${brandName()} trip planner, food, drinks, restaurants, bars, clubs, markets, pubs, gourmet food, local flavors`
    },

    community: {
      ...defaultMetaTagData, url,
      // ex. "Paris Trip Planner | HipTraveler" / "Trip Planner | HipTraveler"
      title: `${location ? `${location} ` : ``}Trip Planner | ${brandName()}`,
      description: `Find best restaurants for ${brandName()} and filter results by your custom travel style`,
      keywords: `${brandName()} trip planner, food, drinks, restaurants, bars, clubs, markets, pubs, gourmet food, local flavors`
    }

  };
}
