import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

import { ProfileStateFormService, ProfileStateService } from '../../../common';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { UserSettingsService } from '../user-settings.service';

@Injectable()
export class NotificationsFormService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    private stateService: ProfileStateService,
    private stateFormService: ProfileStateFormService,
    private userSettings: UserSettingsService
  ) {
    this.stateService.saveClicked$$.asObservable()
      .pipe(takeUntil(this.subscription$))
      .subscribe(this.submitListener.bind(this));
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  initializeFormValues(): void {
    const { noSellInfo, sendNewsLetter } = this.store.selectSnapshot(ProfileState.profileDetails)!
    this.stateFormService.notificationForm.patchValue({
      dnsmpi: noSellInfo,
      sendNL: sendNewsLetter
    });
  }

  async submitListener(): Promise<void> {
    const value = this.stateFormService.notificationForm.value;
    try {
      this.userSettings.formProcessing$$.next(true);
      await firstValueFrom(this.store.dispatch(new ProfileAction.UpdateProfileSettings(value)));
      this.userSettings.formProcessing$$.next(false);
      this.stateService.profileTabSelection$$.next('about');
      this.stateService.profileSettingsView$$.next(false);
    } finally { }
  }

}
