import { GetTemplateOption, MarkerPopUpOption, ProfileMarkerPopUpOption } from './leaflet-map.interface';
import { getWindowRef, leafletNamespaceKey } from '@hiptraveler/common';

var Leaflet = getWindowRef()[leafletNamespaceKey];
export function setNamespace(value: any): void {
  Leaflet = value;
}

export function getMarkerPopUpTemplate({ id, image, title, location }: MarkerPopUpOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-popup {id}">
      <img src="{image}" alt="{title}">
      <h2>{title}</h2>
      <p>{location}</p>
    </div>
  `, { image, title, location, id });
}

export function getProfileMarkerPopUpTemplate({ id, location }: ProfileMarkerPopUpOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-profile-page-popup {id}">
      <p>{location}</p>
    </div>
  `, { id, location });
}

export function getAdventureMarkerTemplate(option?: GetTemplateOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-marker {a}">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white" color="white" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M22 10V6c0-1.1-.9-2-2-2H4c-1.1 0-1.99.9-1.99 2v4c1.1 0 1.99.9 1.99 2s-.89 2-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2s.9-2 2-2zm-2-1.46c-1.19.69-2 1.99-2 3.46s.81 2.77 2 3.46V18H4v-2.54c1.19-.69 2-1.99 2-3.46 0-1.48-.8-2.77-1.99-3.46L4 6h16v2.54zM9.07 16L12 14.12 14.93 16l-.89-3.36 2.69-2.2-3.47-.21L12 7l-1.27 3.22-3.47.21 2.69 2.2z"></path></svg>
      <div class="ht-leaflet-marker--point"></div>
    </div>
  `, { a: option?.className || '' });
}

export function getHotelMarkerTemplate(option?: GetTemplateOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-marker {a}">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white" color="white" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7 14c1.66 0 3-1.34 3-3S8.66 8 7 8s-3 1.34-3 3 1.34 3 3 3zm0-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm12-3h-8v8H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4zm2 8h-8V9h6c1.1 0 2 .9 2 2v4z"></path></svg>
      <div class="ht-leaflet-marker--point"></div>
    </div>
  `, { a: option?.className || '' });
}

export function getFoodMarkerTemplate(option?: GetTemplateOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-marker {a}">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white" color="white" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z"></path></svg>
      <div class="ht-leaflet-marker--point"></div>
    </div>
  `, { a: option?.className || '' });
}

export function getProfileMarkerTemplate(option?: GetTemplateOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-marker {a}">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white" color="white" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"></path></svg>
      <div class="ht-leaflet-marker--point"></div>
    </div>
  `, { a: option?.className || '' });
}

export function getMapMarkerTemplate(option?: GetTemplateOption): string {
  return Leaflet.Util.template(`
    <div class="ht-leaflet-marker {a}">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="white" color="white" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M384 476.1L192 421.2V35.9L384 90.8V476.1zm32-1.2V88.4L543.1 37.5c15.8-6.3 32.9 5.3 32.9 22.3V394.6c0 9.8-6 18.6-15.1 22.3L416 474.8zM15.1 95.1L160 37.2V423.6L32.9 474.5C17.1 480.8 0 469.2 0 452.2V117.4c0-9.8 6-18.6 15.1-22.3z"/></svg>
      <div class="ht-leaflet-marker--point"></div>
    </div>
  `, { a: option?.className || '' });
}
