import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { UserActivityComponent } from './user-activity.component';
import { SocialExternalLinkDirective } from './social-external-link.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  UserActivityComponent
];

const DIRECTIVES = [
  SocialExternalLinkDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    UserActivityComponent
  ]
})
export class UserActivityModule { }
