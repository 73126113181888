import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { CertificationsLicensesComponent } from './certifications-licenses.component';

const MODULES = [
  CommonModule,
  FormsModule,
  MatRippleModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule
];

const COMPONENTS = [
  CertificationsLicensesComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CertificationsLicensesComponent
  ]
})
export class CertificationsLicensesModule { }
