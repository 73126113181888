import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ComposePrivateNoteDialogComponent } from './compose-private-note-dialog.component';
import { AddReservationDto } from '@hiptraveler/data-access/api';

export const COMPOSE_PRIVATE_NOTE_ID = 'prompt-dialog';

export interface Reservation extends Partial<AddReservationDto> {
  exists: boolean
}

@Injectable()
export class ComposePrivateNoteDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(data: Reservation): MatDialogRef<ComposePrivateNoteDialogComponent, Reservation> | unknown {
    const dialog = this.dialog.getDialogById(COMPOSE_PRIVATE_NOTE_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(ComposePrivateNoteDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: COMPOSE_PRIVATE_NOTE_ID,
      panelClass: COMPOSE_PRIVATE_NOTE_ID + '-overlay-panel',
      width: '550px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data
    })
  }

}
