import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { SafeHtmlPipeModule } from '@hiptraveler/common';
import { StoryTextDisplayPipeModule } from '../story-text-display.pipe';

import { MoreContentDetailsDialogComponent } from './more-content-details-dialog.component';

const MODULES = [
  CommonModule,
  MatDialogModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  SafeHtmlPipeModule,
  StoryTextDisplayPipeModule
];

const COMPONENTS = [
  MoreContentDetailsDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class MoreContentDetailsDialogModule { }
