<div class="banner-selection mat-elevation-z4">

  <div class="banner-selection-item" (click)="about()">
    <mat-icon svgIcon="search-banner-actions.about-icon"></mat-icon>
    <p class="banner-selection-item-label" translate="search-page.about"></p>
  </div>
  
  <div class="banner-selection-item" (click)="photoGallery()">
    <mat-icon svgIcon="search-banner-actions.photo-gallery-icon"></mat-icon>
    <p class="banner-selection-item-label" [innerHTML]="'search-page.photo-gallery' | translate"></p>
  </div>
  
  <div class="banner-selection-item" (click)="visaRequirements()">
    <mat-icon svgIcon="search-banner-actions.visa-requirements-icon"></mat-icon>
    <p class="banner-selection-item-label" [innerHTML]="'search-page.visa-requirements' | translate"></p>
  </div>
  
  <div class="banner-selection-item" (click)="covid19Information()">
    <mat-icon svgIcon="search-banner-actions.covid19-information-icon"></mat-icon>
    <p class="banner-selection-item-label" [innerHTML]="'search-page.covid-19-information' | translate"></p>
  </div>
  
  <div class="banner-selection-item" (click)="learnLanguage()">
    <mat-icon svgIcon="search-banner-actions.learn-language-icon"></mat-icon>
    <p class="banner-selection-item-label" [innerHTML]="'search-page.learn' | translate | translateParam: locationData.language"></p>
  </div>
  
  <div class="banner-selection-item">
    <p>$</p>
    <p class="banner-selection-item-label"><span>1</span>&nbsp;USD = <br><span>&nbsp;{{ locationData.exchangeRate | toFixed: 2 }}</span>&nbsp;{{ locationData.currency }}</p>
  </div>

</div>
