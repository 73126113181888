import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

import { LanguageSupportComponent } from './language-support.component';

const MODULES = [
  CommonModule,
  MatCheckboxModule,
  TranslateModule
];

const COMPONENTS = [
  LanguageSupportComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    LanguageSupportComponent
  ]
})
export class LanguageSupportModule { }
