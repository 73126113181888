import { NgModule } from '@angular/core';

import { SearchResultDialogMapDirective } from './search-result-dialog-map.directive';

const DIRECTIVES = [
  SearchResultDialogMapDirective
];

@NgModule({
  declarations: [
    DIRECTIVES,
  ],
  exports: [
    SearchResultDialogMapDirective
  ]
})
export class SearchResultDialogMapModule { }
