import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ParseCurrencyPipeModule } from '@hiptraveler/common';
import { ButtonVisibilityPipeModule } from '../../../common';

import { TourInformationComponent } from './tour-information.component';
import { DurationLabelPipe } from './duration-label.pipe';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  ParseCurrencyPipeModule,
  ButtonVisibilityPipeModule
];

const COMPONENTS = [
  TourInformationComponent
];

const PIPES = [
  DurationLabelPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    TourInformationComponent
  ]
})
export class TourInformationModule { }
