<div class="activity-date" [class.pending]="loading || (itineraryState.dispatchPending$ | async)">

  <button mat-icon-button type="button" class="activity-date--action" [class.hidden]="createAction === '' || hideClose" (click)="service.deleteActivityDay(activity)">
    <mat-icon>close</mat-icon>
  </button>

  <div matRipple *ngIf="createAction !== ''">
    <div [class.active]="(componentState.value$ | async)?.activeDay === activity.day">
      <p>{{ activity.date?.month || ('itinerary.day' | translate) }}</p>
      <p class="ht-digit" [class.multi-day]="activity.hasMultiDayTour">{{ (activity | multiDayTourDate) || activity.day }}</p>
    </div>
  </div>

  <div matRipple class="mat-elevation-z4" *ngIf="createAction === ''">
    <div (click)="service.addActivityDay()">

      <ng-container *ngIf="loading;else defaultUI">
        <mat-progress-spinner mode="indeterminate" strokeWidth="4" diameter="32"></mat-progress-spinner>
      </ng-container>

      <ng-template #defaultUI>

        <ng-container *ngIf="networkLoading;else defaultUIProgress">
          <mat-progress-spinner mode="indeterminate" strokeWidth="4" diameter="32"></mat-progress-spinner>
        </ng-container>

        <ng-template #defaultUIProgress>
          <p translate="itinerary.day"></p>
          <mat-icon>add</mat-icon>
        </ng-template>
      
      </ng-template>

    </div>
  </div>

</div>
