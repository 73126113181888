import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { isWidget } from '@hiptraveler/common';

@Directive({
  selector: '[dynamicStyles]'
})
export class DynamicStylesDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef<HTMLElement>
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {

    if (isWidget()) {
      this.element.classList.add('widget');
    }

    if (isWidget() && this.element.parentElement?.parentElement?.parentElement) {
      this.element.parentElement.parentElement.parentElement.classList.add('widget');
    }
  }

}
