import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { map, skip, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { AboutService } from './about.service';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  host: { class: 'content' },
  viewProviders: [ AboutService ],
  animations: [ opacityFade(animationDelay) ]
})
export class AboutComponent implements OnInit {
  
  @Input() data?: ImageResultData;
  
  constructor(
    private element: ElementRef<HTMLElement>,
    public service: AboutService
  ) { }

  ngOnInit(): void {
    this.service.imageInformation$.pipe(
      skip(1),
      map((response: ImageResultData) => {
        const visibility = 
        !!response?.imgDesc
        || !!response?.tourData?.tourAbstract
        || !!response?.tourData?.tourDescription
        || !!response?.tourData?.addInfo
        || !!response?.tourData?.cancellationPolicy
        || !!response?.tourData?.itinerary?.itineraryItemsList.length;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
  }

}
