import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NewOperatorDialogComponent } from './new-operator-dialog.component';

export const NEW_OPERATOR_DIALOG_ID = 'new-operator-dialog';

export interface DialogData { certificate: string; id: string };

@Injectable()
export class NewOperatorDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(data: DialogData): MatDialogRef<NewOperatorDialogComponent, string> | unknown {
    const dialog = this.dialog.getDialogById(NEW_OPERATOR_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(NewOperatorDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'input',
      id: NEW_OPERATOR_DIALOG_ID,
      width: '450px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data
    })
  }

  close(): void {
    const dialog = this.dialog.getDialogById(NEW_OPERATOR_DIALOG_ID);
    if (dialog) return dialog.close();
  }

}
