import { Injector } from "@angular/core";
import OpenAI from "openai";

import { OPENAI_API_KEY, OPENAI_CLIENT } from ".";

export class OpenAIFn {

  private openAiInstance: OpenAI | undefined;

  constructor(
    protected injector: Injector
  ) { }

  authInitialize(): void {
    
    const openai = new OpenAI({
      apiKey: OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });

    const injector = Injector.create({
      providers: [{ provide: OPENAI_CLIENT, useValue: openai }],
    });

    this.openAiInstance = injector.get(OPENAI_CLIENT);
  }

  get openaiClient(): OpenAI {

    if (!this.openAiInstance) {
      throw 'OpenAI client instance not found.'
    }

    return this.openAiInstance;
  }

}
