import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { MobileNavbarViewComponent } from './mobile-navbar-view.component';
import { NavbarActionComponent } from './navbar-action.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatDialogModule
];

const COMPONENTS = [
  MobileNavbarViewComponent,
  NavbarActionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    MobileNavbarViewComponent,
    NavbarActionComponent
  ]
})
export class MobileNavbarViewModule { }
