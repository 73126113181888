import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, firstValueFrom, map, switchMap, takeUntil, tap } from 'rxjs';

import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, NavbarButtonData, NavbarControlStateService, RequestCancellationService, SearchPageControlStateService, currentLang, getWindowRef, globalStoreItineraryDataKey } from '@hiptraveler/common';
import { ItineraryDataAccessService, preventRemoveKey, ViewCommon } from '@hiptraveler/features/itinerary';

@Injectable()
export class CommonService extends ViewCommon {

  #fromComposePage: boolean = false;
  processing$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private requestCancellation: RequestCancellationService,
    private navbarControl: NavbarControlStateService,
    private searchPageControl: SearchPageControlStateService,
    private dataAccess: ItineraryDataAccessService
  ) {
    super();
  }

  get processing$(): Observable<boolean> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map(e => !e),
      switchMap((state: boolean) => this.processing$$.asObservable().pipe(
        map((processing: boolean) => processing || state)
      ))
    );
  }

  destroyComponentState(): void {
    this.subscription$.next();
    this.focusMode('off');
    this.appListener.clearGlobalStore(globalStoreItineraryDataKey);
    this.dataAccess.subscription$.next();
    this.navbarControl.setActiveNavbarButtons([]);
    this.requestCancellation.cancelAllRequests();
    this.resetState();
  }

  /**
   * Updates the navbar UI state.
   * Toggles the appending of the page navigation selections to the navbar and the default navbar UI state.
   * 
   * @param {'on' | 'off'} mode - on: append, off: default navbar UI
   * @returns {void}
   */
  focusMode(mode: 'on' | 'off'): void {
    this.navbarControl.navbarElevationState$$.next(mode === 'on');
    this.navbarControl.navbarToolbarVisibility$$.next(mode === 'off');
    this.navbarControl.navbarSearchBarVisibility$$.next(true);
    this.navbarControl.navbarToolbarPosition$$.next('none');
    if (mode === 'off') return;
    this.initializeNavbarButtons();
  }

  private initializeNavbarButtons(): void {

    const defaultItineraryActions: NavbarButtonData[] = [
      { name: 'navbar.action-text-save-trip', size: 'large', action: 'saveThisTrip', responsiveIcon: 'save', responsiveText: 'Save' }
    ];

    const itineraryOwnerActions: NavbarButtonData[] = [
      { icon: 'print', action: 'viewTripCreatePDF' },
      { name: 'navbar.action-text-edit', size: 'small', theme: 'outlined', action: 'travelStoryEdit', responsiveIcon: 'edit', responsiveText: 'Edit' }
    ];

    this.owner$.pipe(
      map((owner: boolean) => {
        owner && (getWindowRef()[preventRemoveKey] = undefined);
        return owner ? itineraryOwnerActions : defaultItineraryActions
      }),
      tap(navbarActions => this.navbarControl.setActiveNavbarButtons(navbarActions)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.appListener.previousUrl$.pipe(
      takeUntil(this.subscription$)
    ).subscribe((previousUrl: string) => {
      if (previousUrl.includes(`/${currentLang()}/itinerary`)) return;
      this.#fromComposePage = previousUrl.includes(`/${currentLang()}/compose/itinerary`);
    });
  }

  get viewTransition(): boolean {
    const pathname: string = getWindowRef()?.location?.pathname;
    const previousUrl: string = this.appListener.previousUrl$$.value;
    return (pathname?.includes(`/${currentLang()}/itinerary`) && previousUrl?.includes(`/${currentLang()}/itinerary`))
      || (pathname?.includes(`/${currentLang()}/travel-story`) && previousUrl?.includes(`/${currentLang()}/travel-story`));
  }

  async resetState(): Promise<void> {

    const ResetAction = new ItineraryAction.PartialResetItineraryState([ 'basicInfo', 'actDateMap', 'itineraryReservations' ]);

    if (this.viewTransition) {
      await firstValueFrom(this.store.dispatch(ResetAction));
    }

    if (this.#fromComposePage) this.itineraryObjectData();

    if (getWindowRef()[preventRemoveKey] || this.#fromComposePage) return;
    await firstValueFrom(this.store.dispatch(ResetAction));
  }

  private itineraryObjectData(): void {

    const composePage = getWindowRef()?.location?.pathname?.includes(`/${currentLang()}/compose/itinerary`);
    if (composePage || this.viewTransition) return;

    const activityDay: any = this.store.selectSnapshot(ItineraryState.actDateMap)?.[1] ;
    activityDay['actDate'] = this.store.selectSnapshot(ItineraryState.actDate) || [];
    activityDay['actDateMap'] = this.store.selectSnapshot(ItineraryState.actDateMap) || {};
    activityDay['pageTitle'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle;
    activityDay['itineraryId'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.id;
    this.searchPageControl.activityDate$$.next(activityDay);
    this.navbarControl.setActiveNavbarButtons([{
      name: 'navbar.action-text-open-trip',
      url: `${currentLang()}/compose/itinerary/${activityDay['pageTitle']}`,
      closeButton: true
    }]);
  }

}
