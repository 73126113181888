import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, map, skip, take } from 'rxjs';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Directive({
  selector: '[photoLength]'
})
export class PhotoLengthDirective implements AfterViewInit {

  constructor(
    private store: Store,
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngAfterViewInit(): void {
    if (this.element.nativeElement.tagName !== 'DIV') return;

    this.store.select(ItineraryState.imageInformation)?.pipe(
      skip(1),
      map(e => !e?.photos?.length),
      take(1),
      filter(Boolean)
    ).subscribe(() => (this.element.nativeElement.style.display = 'none'));
  }

}
