import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { FoodData } from '@hiptraveler/data-access/api';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { FoodsDataService } from '@hiptraveler/modules/search-page-root';
import { AppListenerService } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'restaurants',
  templateUrl: './restaurants.component.html',
  viewProviders: [ FoodsDataService, SearchResultDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ],
})
export class RestaurantsComponent implements OnInit {

  constructor(
    public service: FoodsDataService,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    this.service.initializeDataAccess();
  }

  get skeletons$(): Observable<FoodData[]> {
    return this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth > 1400 ? Array(6).fill(0) : Array(4).fill(0))
    );
  }

}
