<div class="about mat-elevation-z2" *ngIf="service.profileDetails$ | async as profile">
  
  <p class="about--user" translate="profile.hi-im" [translateParams]="{ value: profile.firstName + ' ' + profile.lastName }"></p>

  <ng-container *ngIf="profile?.description">
    <br>
    <p class="about--section-header" translate="profile.about"></p>
    <p class="about--section-content">{{ profile.description }}</p>
  </ng-container>
  
  <ng-container *ngIf="profile?.favQuote">
    <br>
    <p class="about--section-header" translate="profile.favorite-travel-quote"></p>
    <p class="about--section-content">{{ profile.favQuote }}</p>
  </ng-container>

  <ng-container *ngIf="profile?.hometown || profile?.userLoc">
    <br>
    <p class="about--section-header" translate="profile.location"></p>
    <p class="about--section-content" *ngIf="profile?.hometown" translate="profile.hometown" [translateParams]="{ value: profile.hometown }"></p>
    <p class="about--section-content" *ngIf="profile?.userLoc" translate="profile.currently-in" [translateParams]="{ value: profile.userLoc }"></p>
  </ng-container>

  <ng-container *ngIf="profile?.rStatus || profile?.dob">
    <br>
    <p class="about--section-header" translate="profile.personal"></p>
    <p class="about--section-content" *ngIf="profile?.rStatus" translate="profile.relationship-status" [translateParams]="{ value: profile.rStatus }"></p>
    <p class="about--section-content" *ngIf="profile?.dob" translate="profile.birthday" [translateParams]="{ value: profile.dob }"></p>
  </ng-container>

  <ng-container *ngIf="service.stateService.selfProfile$ | async">
    <div matRipple class="about--action-button" (click)="service.editProfile()">
      <mat-icon>edit</mat-icon>
      <p translate="profile.edit-profile"></p>
    </div>
  </ng-container>

</div>
