import { Component, Self } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DefaultUiService } from '@hiptraveler/common';

@Component({
  selector: 'app-terms-and-privacy-policy',
  templateUrl: './terms-and-privacy-policy.component.html',
  styleUrls: ['./terms-and-privacy-policy.component.scss'],
  viewProviders: [ DefaultUiService ]
})
export class TermsAndPrivacyPolicyComponent {

  constructor(
    public route: ActivatedRoute,
    @Self() private defaultUI: DefaultUiService
  ) { }
  
}
