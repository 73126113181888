<div class="policy-details ht-dialog-elevation">

  <h2 translate="search-result.policies"></h2>

  <div class="policy-details--check-in-time">
    <p>{{ 'search-result.check-in' | translate }} <span class="ht-digit">{{ data.checkInTime }}</span></p>
    <p>{{ 'search-result.check-out' | translate }} <span class="ht-digit">{{ data.checkOutTime }}</span></p>
  </div>

</div>
