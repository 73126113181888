import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserProfile } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { ProfileStateService } from '../../../../common';

@Injectable()
export class AboutSectionService {

  @Select(ProfileState.profileDetails) profileDetails$: Observable<UserProfile | null>;

  constructor(
    private store: Store,
    public stateService: ProfileStateService
  ) { }

  editProfile(): void {
    if (this.verifyAuthentication()) return;
    this.stateService.profileSettingsView$$.next(true);
  }

  private verifyAuthentication(): boolean {
    if (this.store.selectSnapshot(UserState.id)) return false;
    return true;
  }

}
