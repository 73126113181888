import { AfterViewInit, Directive, ElementRef } from "@angular/core";

import { TranslationService } from "@hiptraveler/common";

@Directive()
export class CardRating implements AfterViewInit {

  constructor(
    private translate: TranslationService,
    private element: ElementRef<HTMLDivElement>
  ) { }
  
  ngAfterViewInit(): void {

    const text1 = this.translate.getText('cards', 'outstanding');
    const textContent = this.element.nativeElement.firstElementChild?.textContent;

    if (textContent === `${text1} 0`) {
      this.element.nativeElement.style.opacity = '0';
    }
  }

}
