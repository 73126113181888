import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';
import { FormModule as TripPlanningFormModule } from '../experience-finder-screen/plan-trip/form/form.module';
import { ItemSelectionModule as TravelStyleItemSelectionModule } from '../experience-finder-screen/travel-style/item-selection/item-selection.module';
import { RequestPendingSkeletonModule } from '../experience-finder-screen/request-pending-skeleton/request-pending-skeleton.module';

import { ExperienceFinderComponent } from './experience-finder.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  TranslateModule
];

const LIBRARIES = [
  MobileNavbarViewModule,
  TripPlanningFormModule,
  TravelStyleItemSelectionModule,
  RequestPendingSkeletonModule
];

const COMPONENTS = [
  ExperienceFinderComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ExperienceFinderComponent
  ]
})
export class ExperienceFinderMobileModule { }
