import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject, EMPTY, Observable, catchError, filter, switchMap, take, tap, map } from 'rxjs';
import { omit } from 'lodash';

import { Ambassador, RegistrationFormDestination } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { RegistrationFormStateService } from '@hiptraveler/features/registration-form';
import { CDNReaderService, getWindowRef } from '@hiptraveler/common';

const ASSET_IMAGE: string = 'https://cdn.filestackcontent.com/A5yG40niNR3q9y7GBglCLz/resize=width:1200/https://hiptraveler.s3.amazonaws.com/site/main/images/Destination-Specialist-1200.jpg';

interface SocialMediaProfile {
  section: string;
  param: string;
  id: string;
  username: string;
}

@Injectable()
export class AmbassadorService implements OnDestroy {

  @Select(UserState.authenticated) authenticated$: Observable<boolean>;

  destinationSpecialistImg: string = ASSET_IMAGE;

  subscription$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private authDialog: AuthDialogActionService,
    private taFormStateService: RegistrationFormStateService,
    private cdnReader: CDNReaderService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.taFormStateService.resetForm();
  }

  get ambassadorHighlightImage$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.ambassadorHighlight').pipe(
      map((e: any) => e.value)
    );
  }

  verifyAuthentication(): void {
    this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open();
  }

  observeSocialMediaPopUp(): void {
    getWindowRef()?.addEventListener('message', (event: MessageEvent<SocialMediaProfile>) => {
      const form = this.taFormStateService.socialForm;
      const { param, username, id } = event.data
      form.patchValue({ [param]: `${username}::${id}` });
    });
  }

  validateUser(): void {
    this.store.select(UserState.state).pipe(
      filter(Boolean),
      filter(userState => userState.ambUser),
      switchMap((userState: UserStateModel) => this.store.dispatch(new ProfileAction.GetAmbassador(userState.ambId)).pipe(
        catchError(() => EMPTY)
      )),
      tap(() => {

        const snapshot = this.store.selectSnapshot(ProfileState.ambassador);
        const ambassador = omit(snapshot, [ 'instaConnect', 'tkConnect', 'fbConnect', 'ytConnect', 'twConnect', 'website', 'rss', 'reference', 'experience' ]) as Ambassador;
        if (!ambassador) return;

        this.taFormStateService.form.patchValue({
          ...ambassador,
          promoCode: snapshot?.reference
        });

        const generateConnectString = (connect: { handle: string; id: string } | undefined) =>
          connect ? `${connect.handle}::${connect.id}` : '';

        this.taFormStateService.socialForm.patchValue({
          tiktok: generateConnectString(snapshot?.tkConnect),
          twitter: generateConnectString(snapshot?.twConnect),
          facebook: snapshot?.fbConnect?.handle,
          instagram: snapshot?.instaConnect?.handle,
          youtube: snapshot?.ytConnect?.handle,
          website: snapshot?.website || '',
          rss: snapshot?.rss || ''
        });
        snapshot?.categories?.forEach((item: string) => {
          this.taFormStateService.categoriesForm.push(new FormControl(item));
        });
        snapshot?.subCategories?.forEach((item: string) => {
          this.taFormStateService.subCategoriesForm.push(new FormControl(item));
        });
        snapshot?.languages?.forEach((item: string) => {
          this.taFormStateService.langForm.push(new FormControl(item));
        });
        snapshot?.destinations?.forEach((item: RegistrationFormDestination) => {
          this.taFormStateService.destinationsForm.push(this.fb.group(item));
        });
        this.taFormStateService.trExpForm.patchValue({
          desc: snapshot?.experience
        })
      }),
      take(1)
    ).subscribe();
  }

}
