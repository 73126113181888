import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as FollowApi from './interfaces';
import { handleErrorAndThrow, toFormData } from '@hiptraveler/data-access/api';
import { brandCampaignId } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class FollowApiService {

  constructor(
    private http: HttpClient
  ) { }

  followUser(payload: FollowApi.FollowUserDto): Observable<FollowApi.FollowUserResponse> {
    return this.http.post<FollowApi.FollowUserResponse>(`/trip-planner/followUser/${brandCampaignId()}`, toFormData(payload)).pipe(
      tap(handleErrorAndThrow)
    );
  }

}
