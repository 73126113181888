import { Component, Input, ViewEncapsulation } from '@angular/core';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { DialogType, SearchResultDialogStateService } from '../common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'result-actions',
  templateUrl: './result-actions.component.html',
  styleUrls: ['./result-actions.component.scss'],
  host: { class: 'result-actions' },
  viewProviders: [ AuthDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class ResultActionsComponent {

  @Input() image: ImageResultData
  @Input() hotel: HotelResultData;
  @Input() dialogType: DialogType;

  constructor(
    public stateService: SearchResultDialogStateService
  ) { }

}
