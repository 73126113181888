import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom } from 'rxjs';

import { AuthAction } from '@hiptraveler/data-access/auth';
import { RESET_PASSWORD_KEY } from '@hiptraveler/data-access/auth';
import { SnackbarService } from '@hiptraveler/snackbar';

export type CodeKeyType = 'code1' | 'code2' | 'code3' | 'code4' | 'code5' | 'code6';
export type OtpCodeType = Record<CodeKeyType, number | null>;

const RESEND_CODE_TIMER = 60;

@Injectable()
export class VerificationService {
  
  form: FormGroup;
  resendCodeTimer: number = RESEND_CODE_TIMER;
  resendProcessing: boolean = false;
  successListener$$ = new Subject<void>();
  pending$$ = new BehaviorSubject<boolean>(false);
  countdownTimer$$ = new BehaviorSubject<number>(RESEND_CODE_TIMER);
  activeCodeField$$ = new BehaviorSubject<CodeKeyType | null>(null);

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private snackbar: SnackbarService
  ) { }

  initializeForm(): void {
    this.form = this.fb.group({
      code1: [ null ],
      code2: [ null ],
      code3: [ null ],
      code4: [ null ],
      code5: [ null ],
      code6: [ null ],
    });
    this.otpChangesObserver();
  }
  
  private otpChangesObserver(): void {
    this.form.valueChanges.subscribe((value: OtpCodeType) => {
      const key: CodeKeyType[] = ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'];
      key.forEach((codeKey: CodeKeyType) => {
        if (value[codeKey]?.toString().length! > 1) {
          let code = +value[codeKey]?.toString()[1]!;
          this.form.get(codeKey)?.setValue(code, { emitEvent: false });
        } else {
          const sanitizedValue = value[codeKey]?.toString().replace(/^-|\./g, "");
          this.form.get(codeKey)?.setValue(sanitizedValue, { emitEvent: false });
        }
      });
    });
  }

  otpFocusChangesObserver(elements: Record<CodeKeyType, HTMLInputElement>): void {

    const hasValue = ($: Observable<number | null> | undefined): Observable<number | null> => {
      return $!.pipe(filter((value: number | null) => value! > -1 ? (value === 0 ? true : !!value) : false));
    };
    const hasNoValue = ($: Observable<number | null> | undefined): Observable<number | null> => {
      return $!.pipe(filter((value: number | null) => value! > -1 ? (value === 0 ? false : !value) : false));
    };

    hasValue(this.form.get('code1')?.valueChanges).subscribe(() => elements.code2.focus());
    hasValue(this.form.get('code2')?.valueChanges).subscribe(() => elements.code3.focus());
    hasValue(this.form.get('code3')?.valueChanges).subscribe(() => elements.code4.focus());
    hasValue(this.form.get('code4')?.valueChanges).subscribe(() => elements.code5.focus());
    hasValue(this.form.get('code5')?.valueChanges).subscribe(() => elements.code6.focus());

    hasNoValue(this.form.get('code2')?.valueChanges).subscribe(() => elements.code1.focus());
    hasNoValue(this.form.get('code3')?.valueChanges).subscribe(() => elements.code2.focus());
    hasNoValue(this.form.get('code4')?.valueChanges).subscribe(() => elements.code3.focus());
    hasNoValue(this.form.get('code5')?.valueChanges).subscribe(() => elements.code4.focus());
    hasNoValue(this.form.get('code6')?.valueChanges).subscribe(() => elements.code5.focus());
  }

  private get resetPasswordSessionData(): any {
    const sessionData = sessionStorage.getItem(RESET_PASSWORD_KEY);
    return sessionData ? JSON.parse(sessionData) : {};
  }

  async verifyOtpCode(): Promise<void> {
    if (this.pending$$.value) return;
    this.pending$$.next(true);
    try {
      await firstValueFrom(this.store.dispatch(new AuthAction.VerifyOtpCode(
        this.resetPasswordSessionData?.token,
        Object.values(this.form.value).join('')
      )));
      this.successListener$$.next();
    } catch (response: any) {
      this.pending$$.next(false);
      this.snackbar.open({ message: response?.error, duration: 5000 });
    } finally {
      this.form.reset();
    }
  }

  async resendOtpCode(): Promise<void> {
    if (this.pending$$.value) return;
    try {
      this.resendProcessing = true
      this.snackbar.open({ message: 'Re-sending the OTP code to your email. Please wait.', duration: Infinity });
      await firstValueFrom(this.store.dispatch(new AuthAction.ForgotPassword({
        email: this.resetPasswordSessionData.email
      })));
      this.snackbar.dismiss();
      this.startCountdown();
    } catch (response: any) {
      this.pending$$.next(false);
      this.snackbar.open({ message: response?.error, duration: 5000 });
    } finally {
      this.resendProcessing = false;
    }
  }

  private startCountdown(): void {
    this.countdownTimer$$.next(RESEND_CODE_TIMER - 1);
    let counter = RESEND_CODE_TIMER - 1;
    const intervalId = setInterval(() => {
      counter--;
      this.countdownTimer$$.next(counter);
      if (counter === 0) {
        clearInterval(intervalId);
        this.countdownTimer$$.next(RESEND_CODE_TIMER);
      }
    }, 1000);
  }

}
