import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateParam'
})
export class TranslateParam2Pipe implements PipeTransform {

  transform(value: string, param: any): string {
    return value.replace('{{value}}', param?.toString());
  }

}
