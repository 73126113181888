import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { UserItineraryData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AppListenerService, CDNReaderService, currentLang, globalStoreItineraryDataKey } from '@hiptraveler/common';

@Component({
  selector: 'plan-a-trip',
  templateUrl: './plan-a-trip.component.html',
  styleUrls: ['../action-display-styles.scss'],
  styles: [`
    :host { display: flex; justify-content: center; }
  `]
})
export class PlanATripComponent {

  @Select(AuthState.authenticated) authenticated$: Observable<boolean>;
  @Select(UserState.authenticated) userAuthenticated$: Observable<boolean>;
  @Select(UserState.itineraries) itineraries$: Observable<UserItineraryData[] | null>;

  lang: string = currentLang();

  constructor(
    private router: Router,
    private appListener: AppListenerService,
    public translateService: TranslateService,
    public stateService: ExperienceFinderStateService,
    private cdnReader: CDNReaderService
  ) { }

  get tripsImage$(): Observable<string> {
    return this.cdnReader.dataCache$.pipe(
      map((e: any) => e['response3']['trips-image'])
    );
  }

  selectItinerary(itinerary: UserItineraryData): void {
    this.stateService.overlayState$$.next(false);
    this.appListener.setGlobalStore(globalStoreItineraryDataKey, itinerary);
    this.router.navigate([ '/', currentLang(), 'itinerary', itinerary.pageTitle ]);
  }

}
