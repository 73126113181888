import { Component, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ProfileState } from '@hiptraveler/data-access/profile';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'about-tab',
  template: `
    <ng-container *ngIf="profileDetails$ | async; else skeletonUI">
      <about-section [@opacityFadeIn]></about-section>
      <social-handles-section [@opacityFadeIn]></social-handles-section>
    </ng-container>

    <ng-template #skeletonUI>
      <div class="about mat-elevation-z2">
        <ngx-skeleton-loader class="about--skeleton-head" [@opacityFadeIn]></ngx-skeleton-loader>
        <ngx-skeleton-loader count="5" class="about--skeleton-content" [@opacityFadeIn]></ngx-skeleton-loader>
      </div>
    </ng-template>
  `,
  styleUrls: ['./about-tab.component.scss'],
  host: { class: 'page-profile-about-tab' },
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn() ]
})
export class AboutTabComponent {

  @Select(ProfileState.profileDetails) profileDetails$: Observable<unknown>;

}
