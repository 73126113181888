import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { BrandState } from '@hiptraveler/data-access/brand';
import { SocialMediaLinkComponent } from './social-media-link.component';
import { RegistrationFormStateService, SocialMediaType } from '../../common';
import { getWindowRef, openedWindowPopUp } from '@hiptraveler/common';

@Directive({
  selector: '[socialMediaEvent]'
})
export class SocialMediaEventDirective implements OnInit {

  @Input() socialMediaEvent: [ SocialMediaLinkComponent, SocialMediaType ];

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private store: Store,
    private formService: RegistrationFormStateService
  ) { }
  
  ngOnInit(): void {

    this.formService.socialForm.valueChanges.subscribe((value) => {

      if (!value[this.socialMediaEvent[1]]) return;

      const element = this.element.nativeElement;
      const primaryColor = this.store.selectSnapshot(BrandState.campaignCustom)?.colorPalette?.mainColor || '#00a6ff';
      const text = element.getElementsByTagName('P').item(0) as HTMLElement;
      text.style.color = primaryColor;
      text.textContent = value[this.socialMediaEvent[1]].split('::')[0] || text.textContent;

      const icon = element.querySelectorAll('.complete-svg').item(0) as HTMLElement
      icon.style.display = 'block';

      getWindowRef()[openedWindowPopUp]?.close();
    });
  }

  @HostListener('click')
  hostClicked(): void {
    const value = this.formService.socialForm.value[this.socialMediaEvent[1]];
    if (value) return;
    const component = this.socialMediaEvent[0];
    const type = this.socialMediaEvent[1];
    component.socialClicked.emit(type);
  }

}
