<ng-container *ngIf="travelAgent">

  <div class="tah-card">

    <div class="tah-banner-img">
      <div class="tah-banner-img-overlay"></div>
      <img [lazyLoad]="travelAgent?.coverImage || ''" [defaultImage]="(service.coverImageFallback$ | async)!" [errorImage]="(service.coverImageFallback$ | async)!" draggable="false" [alt]="'view-page.specialist' | translate">
    </div>

    <div class="tah-agent">

      <div class="tah-agent--img-bg"></div>

      <img [lazyLoad]="travelAgent?.profImg || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" [alt]="'view-page.specialist' | translate">
      <p class="tah-agent--name">{{ travelAgent.name }}</p>
      <p class="tah-agent--location">{{ travelAgent?.location || subdomain | titlecase }}</p>
    </div>

    <p class="tah-banner-text">{{ 'view-page.specialist' | translate }}</p>
    
    <div class="tah-categories" *ngIf="travelAgent?.tags?.length">
      <p class="tah-categories-content">
        <strong>Experience:</strong>&nbsp;<span *ngFor="let tag of travelAgent?.tags;let isLast = last">{{ tag }}<span *ngIf="!isLast">&nbsp;•&nbsp;</span></span>
      </p>
    </div>

    <div class="tah-actions">
      <ui-action-button size="medium" theme="primary" [pending]="(travelAgent | followProcessing | async)!" [actionActive]="travelAgent.profId" (click)="service.followByResultData(travelAgent)"><p>{{ 'view-page.follow' | translate }}</p></ui-action-button>
      <ui-action-button size="medium" theme="outlined-white" (click)="service.sendTravelAgentMessage(travelAgent.profId)">{{ 'view-page.message' | translate }}</ui-action-button>
    </div>

  </div>

  <ht-ta-card [travelAgent]="travelAgent" [tags]="travelAgent?.tags || []">
    <img taBackgroundImage [lazyLoad]="travelAgent.coverImage || ''" [defaultImage]="(service.coverImageFallback$ | async)!" [errorImage]="(service.coverImageFallback$ | async)!" alt="Travel Agent Brand Affiliation Image" draggable="false">
    <img taProfileImage [lazyLoad]="travelAgent.profImg || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" alt="Travel Agent Profile Image" draggable="false">
    <ta-flag>{{ 'view-page.specialist' | translate }}</ta-flag>
    <ta-name (click)="service.navigateToProfile(travelAgent)">{{ travelAgent.name }}</ta-name>
    <ta-location>{{ travelAgent.location }}</ta-location>
    <ta-action svgIcon="common.follow-svg" [processing$]="travelAgent | followProcessing" [actionActive]="travelAgent.profId" (click)="service.followByResultData(travelAgent)">{{ 'view-page.follow' | translate }}</ta-action>
    <ta-action icon="mail" (click)="service.sendTravelAgentMessage(travelAgent.profId)">{{ 'view-page.message' | translate }}</ta-action>
  </ht-ta-card>

</ng-container>
