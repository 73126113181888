import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { filter, map, sampleTime } from 'rxjs';

import { AppListenerService } from '@hiptraveler/common';
import { EFAStateServiceService } from '../../shared';

@Component({
  selector: 'welcome-view',
  templateUrl: './welcome-view.component.html',
  styleUrls: ['./welcome-view.component.scss'],
  host: { class: 'experience-finder-ai--host-welcome-view experience-finder-ai--host-element' },
  encapsulation: ViewEncapsulation.None
})
export class WelcomeViewComponent implements OnInit {

  width: string = '170';
  brandLogoVisibility: boolean = true;

  constructor(
    private appListener: AppListenerService,
    private stateService: EFAStateServiceService
  ) { }

  ngOnInit(): void {
    this.brandLogoPulseResponsiveness();

    this.stateService.getInputMessageByScreen('screen1').subscribe(() => {
      this.stateService.scrollToView$$.next('next');
    });
  }

  private brandLogoPulseResponsiveness(): void {
    this.appListener.clientWidth$.pipe(
      map(e => e > 600), filter(Boolean)
    ).subscribe(() => {
      this.width = '170';
      this.toggleBrandLogoVisibility();
    });

    this.appListener.clientWidth$.pipe(
      sampleTime(1000)
    ).subscribe((clientWidth: number) => {
      if (clientWidth > 600) return;
      this.width = `${170 - +(((clientWidth-600)*-1)*0.2).toFixed(2)}`;
      this.toggleBrandLogoVisibility();
    });
  }

  private toggleBrandLogoVisibility(): void {
    this.brandLogoVisibility = false;
    setTimeout(() => (this.brandLogoVisibility = true), 100);
  }

}
