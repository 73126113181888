<ng-container *ngIf="service.profileState$ | async as profile">
  <div class="user-activity" [class.ambassador]="profile?.profileDetails?.ambStatus === 'confirmed' || !!profile.travelAgent">
  
    <ng-container *ngIf="stateService.selfProfile$ | async">
      <mat-icon (click)="stateService.profileSettingsView$$.next(true)" [@opacityFade]>settings</mat-icon>
    </ng-container>
  
    <div class="user-activity--badge">
      <p translate="profile.dest-specialist" *ngIf="profile?.profileDetails?.ambStatus === 'confirmed'"></p>
      <p translate="profile.ambassador-title" *ngIf="!!profile.travelAgent"></p>
      <img [lazyLoad]="(ambassadorBadge$ | async)!" defaultImage="assets/img/blank-white.webp" errorImage="assets/img/blank-white.webp" draggable="false" [alt]="'profile.ambassador-badge' | translate">  
    </div>
  
    <div class="user-activity--info">
      <div class="user-activity--info-item">
        <p translate="profile.countries"></p>
        <p class="ht-digit">{{ (service.placesVisited$ | async)?.length || 0 }}</p>
      </div>
      <div class="user-activity--info-item user-activity--info-item-action" (click)="service.switchTab('followers')">
        <p translate="profile.followers"></p>
        <p class="ht-digit">{{ profile?.followerCount || 0 }}</p>
      </div>
      <div class="user-activity--info-item user-activity--info-item-action" (click)="service.switchTab('follows')">
        <p translate="profile.following"></p>
        <p class="ht-digit">{{ profile?.followCount || 0 }}</p>
      </div>
    </div>  
  
    <div class="user-activity--social">
      <div matRipple class="user-activity--social-item facebook" socialExternalLink="facebook">
        <mat-icon svgIcon="page-profile-common.facebook-svg"></mat-icon>
      </div>
      <div matRipple class="user-activity--social-item twitter" socialExternalLink="twitter">
        <mat-icon svgIcon="page-profile-common.twitter-svg"></mat-icon>
      </div>
      <div matRipple class="user-activity--social-item youtube" socialExternalLink="youtube">
        <mat-icon svgIcon="page-profile-common.youtube-svg"></mat-icon>
      </div>
      <div matRipple class="user-activity--social-item pinterest" socialExternalLink="pinterest">
        <mat-icon svgIcon="page-profile-common.pinterest-svg"></mat-icon>
      </div>
      <div matRipple class="user-activity--social-item instagram" socialExternalLink="instagram">
        <mat-icon svgIcon="page-profile-common.instagram-svg"></mat-icon>
      </div>
      <div matRipple class="user-activity--social-item tiktok" socialExternalLink="tiktok">
        <mat-icon svgIcon="page-profile-common.tiktok-svg"></mat-icon>
      </div>
      <div matRipple class="user-activity--social-item website" socialExternalLink="website">
        <mat-icon svgIcon="page-profile-common.website-svg"></mat-icon>
      </div>
    </div>
  
    <div class="user-activity--divider"></div>
    
    <div class="user-activity--tab">
      <div class="user-activity--tab-item" [class.active]="(service.selection$ | async) === 'stories'" (click)="service.switchTab('stories')">
        <p translate="profile.stories-option"></p>
      </div>
      <div class="user-activity--tab-item" [class.active]="(service.selection$ | async) === 'about'" (click)="service.switchTab('about')">
        <p translate="profile.about-option"></p>
      </div>
      <div class="user-activity--tab-item" [class.active]="(service.selection$ | async) === 'itineraries'" (click)="service.switchTab('itineraries')">
        <p translate="profile.itineraries-option"></p>
      </div>
      <div class="user-activity--tab-item" [class.active]="(service.selection$ | async) === 'favorites'" (click)="service.switchTab('favorites')">
        <p translate="profile.favorites-option"></p>
      </div>
      <ng-container *ngIf="stateService.travelAgent$ | async">
        <div class="user-activity--tab-item" [class.active]="(service.selection$ | async) === 'uploads'" (click)="service.switchTab('uploads')">
          <p translate="profile.my-uploads-option"></p>
        </div>
      </ng-container>
    </div>
  
  </div>
</ng-container>
