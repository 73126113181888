import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialHandlesSectionComponent } from './social-handles-section.component';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  SocialHandlesSectionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SocialHandlesSectionComponent
  ]
})
export class SocialHandlesSectionModule { }
