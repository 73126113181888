import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { CampaignCustom } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { Selection } from '../common';

@Injectable()
export class HeaderService {

  constructor(
    private store: Store
  ) { }
  
  getSocialLink$(select: Selection): Observable<string> {
    return this.store.select(BrandState.campaignCustom).pipe(
      map((campaign: Partial<CampaignCustom> | null) => campaign?.socialLinks?.[select] || '')
    );
  }

}
