import { Store } from "@ngxs/store";
import { values } from 'lodash';

import * as API from "@hiptraveler/data-access/api";
import { UserAction, UserState } from "@hiptraveler/data-access/user";
import { brandCampaignId, isWidget } from "@hiptraveler/common";

export interface WidgetResponse extends API.AddItineraryWidgetResponse {}

export function addUserItineraryByAddItineraryResponse(store: Store, response: API.AddItineraryResponse | API.AddItineraryBoardResponse): void {
  const itineraries = store.selectSnapshot(UserState.itineraries) || [];

  const data = isWidget() ? {
    id: response?.data?.itiMap?.id || '',
    pageTitle: response?.data?.itiMap?.pageTitle || '',
    isAutoGenerated: response?.data?.itiMap?.isAutoGenerated || true,
    itiDays: []
  } : {
    id: response?.data?.itiMap?.basicInfo?.id || '',
    pageTitle: response?.data?.itiMap?.basicInfo?.pageTitle || '',
    itiDays: (values(response?.data?.itiMap?.itinerary.actDateMap) || []) as API.UserItineraryDayData[],
  };

  const itineraryData: API.UserItineraryData = {
    ...data,
    campgnReference: brandCampaignId(),
    datePublished: new Date().getTime(),
    isPackagedTour: false,
    isPublic: false,
    title: response?.data?.itiMap?.title || response.data?.itiMap?.basicInfo?.title
  };
  itineraries.unshift(itineraryData);
  store.dispatch(new UserAction.UpdateItineraryData(itineraries));
}
