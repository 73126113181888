import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'selectDisplay'
})
export class SelectDisplayPipe implements PipeTransform {

  transform(value: string, selectValues$: Observable<Partial<Experience>[]>): Observable<string> {
    return selectValues$.pipe(
      map((selectValues: Partial<Experience>[]) => {
        const select = selectValues.find(e => e.code === value);
        return select?.name ?? '';
      })
    );
  }

}
