import { Injectable, OnDestroy } from '@angular/core';
import { SearchPageControlStateService } from '@hiptraveler/common';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, filter, tap, takeUntil, merge, delay } from 'rxjs';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { ProfileStateService } from '../../../common';

@Injectable()
export class UploadsTabService implements OnDestroy {

  @Select(ItineraryState.actDate) actDate$: Observable<ActivityDateData[] | null>;

  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    public searchResultDialog: SearchResultDialogActionService,
    public searchPageControl: SearchPageControlStateService,
    private stateService: ProfileStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  invalidStateObserver(): void {
    merge(
      this.stateService.selfProfile$.pipe( filter(e => !e) ),
      this.store.select(AuthState.authenticated).pipe( filter(e => !e) )
    ).pipe(
      delay(0),
      tap(() => this.stateService.profileTabSelection$$.next('about')),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
