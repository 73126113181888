<ng-container *ngFor="let activity of actDate;let i = index">

  <activity-day [class.processing]="itineraryState.processing$ | async">

    <activity-day-header [scrollId]="'activity-day-' + activity.day" [activity]="activity" [index]="i" [view]="view" (navigateClicked)="service.navigate(activity, $event)"></activity-day-header>
    
    <activity-content [activity]="activity"></activity-content>

    <ng-container *ngFor="let hotelContent of activity?.HotelArray;let i = index">
      <hotel-card [@opacityFadeIn]
        [index]="i" [activity]="activity" [resultContent]="hotelContent" [view]="view === ''"
        (noteClicked)="service.openPrivateNote(hotelContent, 'hotel')"
        (removeClicked)="service.removeResultItinerary(hotelContent, 'hotel', activity?.day)"
        (readMoreClicked)="service.openReadMore(hotelContent, 'hotel')">
      </hotel-card>
    </ng-container>

    <ng-container *ngFor="let imageContent of activity?.ImgArray;let i = index;let isFirst = first;let isLast = last">
      <ng-container [ngSwitch]="imageContent.imgCategory">
        <ng-container *ngSwitchCase="'Food and Drink'">
          <consume-card
            [class.no-action-up]="isFirst" [class.no-action-down]="isLast" [@opacityFadeIn]
            [index]="i" [activity]="activity" [resultContent]="imageContent" [view]="view === ''"
            (positionMoved)="service.positionMoved($event)"
            (noteClicked)="service.openPrivateNote(imageContent, 'image')"
            (removeClicked)="service.removeResultItinerary(imageContent, 'image', activity?.day)"
            (readMoreClicked)="service.openReadMore(imageContent, 'food')">
          </consume-card>
        </ng-container>
        <ng-container *ngSwitchCase="'Hip Adventure'">
          <amusement-card
            [class.no-action-up]="isFirst" [class.no-action-down]="isLast" [@opacityFadeIn]
            [index]="i" [activity]="activity" [resultContent]="imageContent" [view]="view === ''"
            (positionMoved)="service.positionMoved($event)"
            (noteClicked)="service.openPrivateNote(imageContent, 'image')"
            (removeClicked)="service.removeResultItinerary(imageContent, 'image', activity?.day)"
            (readMoreClicked)="service.openReadMore(imageContent, 'adventure')">
          </amusement-card>
        </ng-container>
        <ng-container *ngSwitchCase="'Hip Place'">
          <amusement-card
            [class.no-action-up]="isFirst" [class.no-action-down]="isLast" [@opacityFadeIn]
            [index]="i" [activity]="activity" [resultContent]="imageContent" [view]="view === ''"
            (positionMoved)="service.positionMoved($event)"
            (noteClicked)="service.openPrivateNote(imageContent, 'image')"
            (removeClicked)="service.removeResultItinerary(imageContent, 'image', activity?.day)"
            (readMoreClicked)="service.openReadMore(imageContent, 'adventure')">
          </amusement-card>
        </ng-container>
        <ng-container *ngSwitchCase="'Chill time'">
          <amusement-card
            [class.no-action-up]="isFirst" [class.no-action-down]="isLast" [@opacityFadeIn]
            [index]="i" [activity]="activity" [resultContent]="imageContent" [view]="view === ''"
            (addActivity)="service.replaceItinerary(imageContent, 'image', activity?.day)"
            (positionMoved)="service.positionMoved($event)"
            (noteClicked)="service.openPrivateNote(imageContent, 'image')"
            (removeClicked)="service.removeResultItinerary(imageContent, 'image', activity?.day)">
          </amusement-card>
        </ng-container>
      </ng-container>
    </ng-container>

  </activity-day>

  <ng-container *ngFor="let media of activity.medias;let isFirst= first">
    <ng-container *ngIf="isFirst"> <br> </ng-container>
    <media-card [data]="media"></media-card>
  </ng-container>

  <ng-container *ngIf="view === '' && !activity.HotelArray?.length && type === 'itinerary'">
    <div class="activity-day-list--location">
      <div class="activity-day-list--location-icon mat-elevation-z2" (click)="service.navigate(activity, 'hotels')">
        <mat-icon svgIcon="itinerary.place-stay"></mat-icon>
      </div>
      <p (click)="service.findPlacesToStayNavigation(activity, i)" translate="itinerary.find-place-stay"></p>
    </div>
  </ng-container>

  <br><br>
</ng-container>
