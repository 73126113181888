import { Select, Store } from '@ngxs/store';
import { filter, map, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { UserState } from '@hiptraveler/data-access/user';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { AppListenerService, currentLang, getWindowRef, NavbarControlStateService, pendingAuthProcessKey } from '@hiptraveler/common';
import { ViewCommon } from './view-common';

export class ViewNavbarAction extends ViewCommon {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<BasicInfoData>;

  authHandle$ = new Subject<void>();

  constructor(
    protected store: Store,
    protected appListener: AppListenerService,
    protected navbarControl: NavbarControlStateService,
    protected authDialog: AuthDialogActionService
  ) {
    super();
  }

  observeCreatePDF(): void {
    this.appListener.globalSignalListener('viewTripCreatePDF').pipe(
      tap(this.navigateToPrintPDFUrl.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private navigateToPrintPDFUrl(): void {
    
    const basicInfoId = this.store.selectSnapshot(ItineraryState.basicInfo)?.id;
    if (!basicInfoId) throw 'No Itinerary data found.';
    if (!getWindowRef()) throw 'Browser window client is undefined';

    const apiUrl = `//partners.hiptraveler.com/${currentLang()}/pdfmake/itinerary/${basicInfoId}`;
    getWindowRef().open(apiUrl, '_break');
  }

  private dispatchNavbarActionClick(): void {

    const getElementByClass = (value: string): HTMLElement => {
      return document.getElementsByClassName(value).item(0) as HTMLElement;
    };

    const a = getElementByClass('navbar--action-button-text');
    const b = getElementByClass('navbar--action-icon-button-handle');
    const c = getElementByClass('navbar--action-button-responsive-icon')?.parentElement!;

    const element = [ a, b, c ].filter(Boolean)[0]
    if (!element) return;

    const clickEvent = new Event('click');
    element.dispatchEvent(clickEvent);
  }

  /**
   * Navbar actions observer:
   * Executes appropriate functions on each action button click
   */
  navbarActionStateObserver(): void {
    this.store.select(UserState.authenticated).pipe(
      switchMap(() => this.basicInfo$.pipe(map(res => !!res?.id))),
      tap((value: boolean) => this.navbarControl.navbarActionButtonState$$.next(value)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  authenticationProcess(): boolean {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'saveItineraryTripBlog';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        switchMap(() => this.owner$.pipe(take(1))),
        filter((owner: boolean) => !owner),
        takeUntil(this.authHandle$)
      ).subscribe(this.dispatchNavbarActionClick.bind(this));
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 500);
      return true;
    };

    return false
  }

  resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
