import { Component, Input } from "@angular/core";

@Component({
  selector: 'profile-name',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'profile-name' }
})
export class ProfileCardNameComponent {}

@Component({
  selector: 'profile-location',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'profile-location' }
})
export class ProfileCardLocationComponent {}

@Component({
  selector: 'profile-action-button',
  template: `<p>{{ (active ? 'cards.following' : 'cards.follow') | translate }}</p>`,
  host: { class: 'profile-action-button' },
  exportAs: 'profileActionButton'
})
export class ProfileCardActionButtonComponent {
  @Input() active: boolean;
}
