import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ExternalViewComponent } from './external-view.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  ClipboardModule,
  MatTooltipModule,
  MatIconModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  ExternalViewComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ExternalViewComponent
  ]
})
export class ExternalViewModule { }
