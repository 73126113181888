import { Directive, ElementRef, AfterViewInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppListenerService, currentLang, globalStoreItineraryDataKey, subdomain } from '@hiptraveler/common';
import { fill } from 'lodash';

import { ItineraryData, UserItineraryData } from '@hiptraveler/data-access/api';

@Directive({
  selector: '[itineraryCard]'
})
export class ItineraryCardDirective implements AfterViewInit {

  @Input() itinerary?: ItineraryData;

  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private appListener: AppListenerService
  ) { }

  ngAfterViewInit(): void {

    const cardDate = this.element.nativeElement.querySelector('.feature-card-date p')

    if (cardDate?.textContent?.includes('0') && cardDate.parentElement) {
      cardDate.parentElement.style.opacity = '0';
    }
  }

  @HostListener('click', [ '$event' ])
  hostClicked(event: any): void {

    let element = event.target.parentElement;
    for (let i = 0; i < 3; i++) {
      if (element?.classList?.contains('feature-card--favorite-button')) return;
      element = element.parentElement;
    }

    if (!this.itinerary) return;
    this.appListener.setGlobalStore(globalStoreItineraryDataKey, this.itineraryData);
    const routePath = this.itinerary.type === 'itinerary' ? 'itinerary' : 'travel-story';
    this.router.navigate([ '/', currentLang(), routePath, this.itinerary.pageTitle ]);
  }

  private get itineraryData(): UserItineraryData | null {
    if (!this.itinerary) return null;
    return {
      id: this.itinerary.id,
      campgnReference: subdomain(),
      datePublished: this.itinerary.datePublished,
      pageTitle: this.itinerary.pageTitle,
      title: this.itinerary.title,
      isPackagedTour: false,
      isPublic: false,
      itiDays: fill(Array(this.itinerary.numDays), {}) as any
    };
  }

}
