import { Directive } from '@angular/core';

@Directive({
  selector: '[featureCardImage]',
  host: { class: 'feature-card-image' }
})
export class FeatureCardImageDirective {}

@Directive({
  selector: '[featureCardItineraryImage]',
  host: { class: 'feature-card-itinerary-image' }
})
export class FeatureCardItineraryImageDirective {}

@Directive({
  selector: '[featureCardReviewImage]',
  host: { class: 'feature-card-review-image' }
})
export class FeatureCardReviewImageDirective {}

import { FeatureCardHoverDirective } from './feature-card-hover.directive';
import { ItineraryCardDirective } from './itinerary-card.directive';
import { FeatureCardUIDirectives } from './feature-card-ui.directive';

export const FeatureCardExportDirectives = [
  FeatureCardImageDirective,
  FeatureCardItineraryImageDirective,
  FeatureCardReviewImageDirective,
  FeatureCardHoverDirective,
  ItineraryCardDirective
];

export const FeatureCardDirectives = [
  FeatureCardExportDirectives,
  FeatureCardUIDirectives
];
