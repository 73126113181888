import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ActivityDateListService } from './activity-date-list.service';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';

@Component({
  selector: 'activity-date-list',
  templateUrl: './activity-date-list.component.html',
  styleUrls: ['./activity-date-list.component.scss'],
  viewProviders: [ ActivityDateListService, PromptDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class ActivityDateListComponent {

  @Input() view: string;

  constructor(
    public service: ActivityDateListService
  ) { }

}
