import { AfterViewInit, ChangeDetectorRef, Directive, ContentChild, Input, ViewChild, ElementRef, OnDestroy, Optional } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { filter, fromEvent, takeUntil } from 'rxjs';

import { ItineraryActionService } from './itinerary-action.service';
import { ItineraryActionPanelComponent } from './itinerary-action-panel';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { ItineraryActionPanelService } from './itinerary-action-panel/itinerary-action-panel.service';
import { AppListenerService, SearchPageControlStateService } from '@hiptraveler/common';

const itineraryActionToggleKey = 'x-itinerary-action-toggle';

@Directive()
export class ItineraryAction implements OnDestroy, AfterViewInit {

  @Input() active: boolean;
  @Input() actionPanel: string;
  
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  @ContentChild(ItineraryActionPanelComponent) itineraryAction: ItineraryActionPanelComponent;
  
  visibility: boolean;
  panelVisibility: boolean;
  delayBuffer: boolean;
  #panelRef: HTMLElement | null = null;

  constructor(
    private element: ElementRef<HTMLElement>,
    private cdRef: ChangeDetectorRef,
    protected appListener: AppListenerService,
    public searchPageControl: SearchPageControlStateService,
    private service: ItineraryActionService,
    @Optional() protected promptDialog: PromptDialogActionService,
    @Optional() protected actionPanelService: ItineraryActionPanelService
  ) { }

  ngOnDestroy(): void {
    this.appListener.subscription$.next();
  }

  ngAfterViewInit(): void {

    this.#panelRef = null;

    this.service.previousPanelVisibility$.subscribe(() => {
      const event = new Event('click');
      this.#panelRef && this.#panelRef.dispatchEvent(event);
    });

    this.service.initializeOverlay(this.overlay, this.itineraryAction.activityId, this.actionPanel, (visibility: boolean) => {
      this.actionPanel !== '' ? this.visibility = visibility : this.panelVisibility = visibility;
      this.cdRef.detectChanges();
    });

    const element = this.element.nativeElement;
    if (element.tagName === 'ITINERARY-ACTION-BUTTON' && element.getAttribute('actionPanel')?.toString() === '') {
      fromEvent(
        (element.getRootNode() as HTMLElement).getElementsByTagName('body').item(0) as HTMLElement,
        'click'
      ).pipe(
        filter(() => this.panelVisibility),
        takeUntil(this.appListener.subscription$)
      ).subscribe(() => this.toggleItineraryAction(element, false));
    }

    this.appListener.keyboardEvent$.pipe(
      filter(e => e.key === 'Escape' && this.panelVisibility),
      takeUntil(this.appListener.subscription$)
    ).subscribe(() => this.toggleItineraryAction(element, false));

    this.appListener.globalSignalListener(itineraryActionToggleKey).subscribe(() => {
      this.actionPanel !== '' ? this.visibility = false : this.panelVisibility = false;
      this.cdRef.detectChanges();
    });
  }

  /**
   * Toggles the visibility of the itinerary action panel.
   * 
   * @remarks If the searchPageControl.replaceItineraryActivity$$ value is true, replaces the itinerary activity instead of toggling visibility.
   */
  toggleItineraryAction(reference: HTMLElement | null = null, emit: boolean = true): void {

    emit && this.appListener.emitGlobalSignal(itineraryActionToggleKey);

    setTimeout(() => {
      this.#panelRef = reference;

      if (this.delayBuffer) return;
      this.delayBuffer = true;
      setTimeout(() => (this.delayBuffer = false), 10);
  
      if (this.searchPageControl.replaceItineraryActivity$$.value) {
        return this.itineraryAction.replaceItineraryActivity();
      }
  
      if (this.actionPanel !== '') {
        this.visibility = !this.visibility;
      } else {
        this.panelVisibility = !this.panelVisibility;
      }
  
      this.cdRef.detectChanges();      
    }, 100);
  }

  /**
   * Closes the itinerary action panel.
   */
  closeItineraryAction(): void {
    if (this.actionPanel !== '') {
      this.visibility = false;
    } else {
      this.panelVisibility = false;
    }

    this.cdRef.detectChanges();
  }

}
