import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLocale'
})
export class ToLocalePipe implements PipeTransform {

  transform(value?: number): string {
    return value?.toLocaleString() || '';
  }

}

@NgModule({
  declarations: [ ToLocalePipe ],
  exports:      [ ToLocalePipe ]
})
export class ToLocalePipeModule { }
