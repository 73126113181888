import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AppListenerService, currentLang, NavbarControlStateService } from '@hiptraveler/common';

const retainToolbarState_paths = [ '/travel' ]

@Injectable()
export class DefaultUiService implements OnDestroy {

  #experienceFinderVisibility?: boolean;
  #toolbarVisibility?: boolean;

  currentRoutePath: string | undefined;

  constructor(
    private router: Router,
    private appListener: AppListenerService,
    private ncStateService: NavbarControlStateService
  ) {
    setTimeout(() => this.initializeComponentState());
  }
  
  ngOnDestroy(): void {
    this.setComponentStateToDefault();
  }

  private initializeComponentState(): void {

    this.currentRoutePath = this.router.url;

    if (this.currentRoutePath.includes(`/${currentLang()}/profile/`)) {
      this.#experienceFinderVisibility = true;
      this.appListener.experienceFinderVisibility$$.next(false);
      this.#toolbarVisibility = true;
      this.ncStateService.navbarToolbarVisibility$$.next(false);
      return;
    }

    if (this.#experienceFinderVisibility === undefined) {
      this.#experienceFinderVisibility = this.appListener.experienceFinderVisibility$$.value?.valueOf();
      this.appListener.experienceFinderVisibility$$.next(false);
    }

    if (this.#toolbarVisibility === undefined) {
      if (retainToolbarState_paths.some(e => this.currentRoutePath!.endsWith(e))) return;
      this.#toolbarVisibility = this.ncStateService.navbarToolbarVisibility$$.value?.valueOf();
      this.ncStateService.navbarToolbarVisibility$$.next(false);
    }
  }
  
  private setComponentStateToDefault(): void {
    if (this.#experienceFinderVisibility !== this.appListener.experienceFinderVisibility$$.value) {
      this.appListener.experienceFinderVisibility$$.next(this.#experienceFinderVisibility!);
    }
    if (this.#toolbarVisibility !== this.ncStateService.navbarToolbarVisibility$$.value) {
      if (this.currentRoutePath && retainToolbarState_paths.some(e => this.currentRoutePath!.endsWith(e))) return;
      this.ncStateService.navbarToolbarVisibility$$.next(this.#toolbarVisibility!);
    }
  }

  displayToolbar(): void {
    setTimeout(() => this.ncStateService.navbarToolbarVisibility$$.next(true));
  }

}
