import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';

import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { HotelsDataService, skeletons } from '@hiptraveler/modules/search-page-root';
import { RequestCancellationService } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  viewProviders: [ HotelsDataService, SearchResultDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotelsComponent implements OnInit, OnDestroy {

  skeletons: number[] = skeletons;

  constructor(
    private cdRef: ChangeDetectorRef,
    public service: HotelsDataService,
    private requestCancellation: RequestCancellationService
  ) { }

  ngOnInit(): void {
    this.changeDetectionObserver()
    this.service.initializeDataAccess();
  }

  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
    this.requestCancellation.cancelAllSearchRequests();
  }

  private changeDetectionObserver(): void {
    this.service.hotels$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.hotels$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.hotels$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
    this.service.hotels$.pipe(
      takeUntil(this.service.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
  }

}
