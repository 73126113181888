import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, Inject, PLATFORM_ID } from '@angular/core';
import Typed from 'typed.js';

import { EFAStateServiceService } from '../../shared';

@Directive({
  selector: '[textTyped]'
})
export class TextTypedDirective implements AfterViewInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.initializeTextUX();
  }

  initializeTextUX(): void {

    const typed = new Typed('#welcome-view-header', {
      strings: [
        'Hiyaa traveler! How can I help you?',
        'Hey there, traveler! Where to next?',
        'Ready for your next adventure? Ask me anything!',
        'Hi traveler! Let\'s plan your perfect journey!',
        'Hi traveler! Where can I take you today?',
        'Hello, adventurer! How can I assist you?',
        'Hey! Ready to explore? How can I help?',
        'Let me guide you! What’s your next destination?',
        'Where would you like to wander today, traveler?',
        'Hiyaa traveler! How can I help you?'
      ],
      typeSpeed: 15,
      cursorChar: '',
      backDelay: 3500,
      loop: true,
      loopCount: 10
    });

    this.stateService.welcomeViewTypeState$$.asObservable().subscribe((state: boolean) => {
      if (state) {
        typed.start();
      } else {
        setTimeout(() => typed.reset(), 300);
      }
    });
  }

}
