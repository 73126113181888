import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

import { CustomActivityActionButtonComponent } from './custom-activity-action-button.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  TranslateModule
];

const COMPONENTS = [
  CustomActivityActionButtonComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CustomActivityActionButtonComponent
  ]
})
export class CustomActivityActionButtonModule { }
