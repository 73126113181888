import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ActiveSelectionModule } from '../common';

import { SpecializationComponent } from './specialization.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatRippleModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  ActiveSelectionModule
];

const COMPONENTS = [
  SpecializationComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SpecializationComponent
  ]
})
export class SpecializationModule { }
