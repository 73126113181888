<div class="prompt-dialog" [class.processing]="dialogRef.disableClose">

  <ng-container *ngIf="!dialogRef.disableClose">
    <dialog-close-button [@opacityFade]></dialog-close-button>
  </ng-container>
  
  <p class="prompt-dialog--title">{{ (data.title || '') | translate: { value: data?.titleParam || '' } }}</p>
  
  <p class="prompt-dialog--message">{{ data.message | translate: { value: data?.messageParam || '' } }}</p>
  
  <div class="prompt-dialog--actions">
    <ui-action-button [theme]="button.theme" *ngFor="let button of data.buttons;let i = index;" (click)="button.executable()">
      <ng-container *ngIf="(i | processing: stateService.progress$ | async);else defaultUI">
        <mat-progress-spinner diameter="18" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
      </ng-container>
      <ng-template #defaultUI>
        <span>{{ button.name | translate }}</span>
      </ng-template>
    </ui-action-button>
  </div>

</div>
