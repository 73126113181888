<div class="auth-dialog--social-media-buttons">

  <social-media-button class="auth-dialog--facebook-button" (click)="socialMedia.signInWithSocialMedia('facebook');dialogRef.close()">
    <facebook-svg id="icon"></facebook-svg>
    <span id="text" translate="auth.continue-with-facebook"></span>
  </social-media-button>

  <social-media-button class="auth-dialog--google-button g-signin2" (click)="socialMedia.signInWithSocialMedia('google');dialogRef.close()">
    <google-svg id="icon"></google-svg>
    <span id="text" translate="auth.continue-with-google"></span>
  </social-media-button>

</div>

<div class="auth-dialog--form">
  <or-divider></or-divider>
  <login-form (forgotPasswordClicked)="forgotPasswordClicked.emit()"></login-form>
</div>

<p class="auth-dialog--create-account">{{ 'auth.looking-to' | translate }} <span (click)="createAccountClicked.emit()">{{ 'auth.create-an-account' | translate }}</span></p>
