import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RegistrationFormStateService } from '../common';

@Injectable()
export class TravelExperienceService {

  constructor(
    private stateService: RegistrationFormStateService
  ) { }

  get form(): FormGroup {
    return this.stateService.form;
  }

}
