import { Component, AfterViewInit, ViewEncapsulation, ElementRef } from '@angular/core';

import { EFAStateServiceService } from '../../shared';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'brand-header',
  template: `
    <brand-logo-pulse size="80" [paused]="!(pulsing$ | async)"></brand-logo-pulse>
    <div id="brand-header-highlight" class="brand-header--highlight" textTyped></div>
    <div class="brand-header--background"></div>
  `,
  styleUrls: ['./brand-header.component.scss'],
  host: { class: 'experience-finder-ai--host-brand-header' },
  encapsulation: ViewEncapsulation.None
})
export class BrandHeaderComponent implements AfterViewInit {

  brandLogoState: boolean = true;

  constructor(
    private element: ElementRef<HTMLElement>,
    private stateService: EFAStateServiceService
  ) { }

  get pulsing$(): Observable<boolean> {
    return this.stateService.inputPending$.pipe(
      map((pending: boolean) => {
        return pending || this.brandLogoState;
      })
    );
  }

  ngAfterViewInit(): void {
    const host = this.element.nativeElement;
    const brandHeaderHighlight = this.element.nativeElement.querySelector('.ui-brand-logo-pulse');

    setTimeout(() => this.stateService.pauseBrandInfoUXState$$.next(true), 100);

    this.stateService.pauseBrandInfoUXState$$.asObservable().subscribe((state: boolean) => {
      this.brandLogoState = !state;
    });

    this.stateService.welcomeViewTypeState$$.asObservable().subscribe((state: boolean) => {
      if (state) {
        brandHeaderHighlight?.classList.remove('brand-logo-active');
        host.classList.remove('view-display');
        this.stateService.brandHeaderTypeState$$.next(false);
      } else {
        brandHeaderHighlight?.classList.add('brand-logo-active');
        host.classList.add('view-display');
        this.stateService.brandHeaderTypeState$$.next(true);
      }
    });
  }

}
