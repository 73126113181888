import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, startWith } from 'rxjs';

import { AppListenerService, SupportedLanguage, currentLang } from '@hiptraveler/common';

@Injectable()
export class DialogToolbarActionsService {

  constructor(
    private appListener: AppListenerService,
    private translateService: TranslateService
  ) { }

  changeLanguage(lang: SupportedLanguage): void {
    this.translateService.use(lang);
  }

  get actionButtonSize$(): Observable<'medium' | 'default'> {
    return this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth < 1000 ? 'medium' : 'default')
    )
  }

  get currentLang$(): Observable<string> {
    return this.translateService.onLangChange.pipe(
      map(e => e.lang),
      startWith(currentLang())
    );
  }
}
