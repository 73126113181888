<ng-container *ngIf="imageInformation?.tourExpire;else tourActive">
  
  <p class="tour-info--expired" translate="search-result.tour-not-available"></p>

</ng-container>
<ng-template #tourActive>

  <div class="tour-info--information">

    <ng-container *ngIf="data?.tourData?.price?.amount">
      <p>{{ 'search-result.from' | translate }} <span class="ht-digit">{{ data?.tourData?.price?.currency | parseCurrency: data?.tourData?.price?.amount }}</span> {{ 'search-result.per-individual' | translate }}</p>
    </ng-container>

    <ng-container *ngIf="data?.tourData?.durationList?.[0]?.value || imageInformation?.tourData?.duration as duration">
      <div class="tour-info--information-duration" [@opacityFade]>
        <mat-icon>schedule</mat-icon>
        <p>:&nbsp;</p>
        <p><span class="ht-digit">{{ (duration || data?.tourData?.durationList?.[0]?.value) | durationLabel: data?.tourData?.durationList?.[0]?.unit }}</span></p>
      </div>
    </ng-container>

  </div>

  <ng-container *ngIf="!(dialogType === 'adventure' && imageInformation?.tourData === null)">
    <ng-container *ngIf="dialogType | buttonVisibility | async">
      <ui-action-button hoverElevation theme="secondary" class="tour-info--check-availability">{{ 'search-result.check-availability' | translate }}</ui-action-button>
    </ng-container>
  </ng-container>

</ng-template>
