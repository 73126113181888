import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { filter, map, switchMap, tap } from 'rxjs';

import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { currentLang, getWindowRef, NavbarControlStateService, ofActionDispatchedSignOutNoAuthState, SearchLocationService } from '@hiptraveler/common';
import { CommonService } from '.';

@Injectable()
export class AuthStateObserverService {

  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private navbarControl: NavbarControlStateService,
    private searchLocation: SearchLocationService,
    private commonService: CommonService
  ) { }

  observe(): void {

    this.store.select(AuthState.authenticated).pipe(
      switchMap((auth: boolean) => this.store.select(UserState.authenticated).pipe(
        map((user: boolean) => auth && !user)
      )),
      filter(() => 
        !this.router.url.includes(`/${currentLang()}/itinerary/`)
          && !this.router.url.includes(`/${currentLang()}/travel-story/`)
      ),
      tap((state: boolean) => this.navbarControl.navbarActionButtonState$$.next(!state))
    ).subscribe();

    this.actions$.pipe(
      ofActionDispatched(AuthAction.SignOut, AuthAction.SignOutNoAuth),
      filter(() => this.searchLocation.rootSearchRoute || this.searchLocation.searchRoute)
    ).subscribe(() => {
      getWindowRef()[ofActionDispatchedSignOutNoAuthState] = '1';
      this.commonService.removeOpenTripProcess();
      this.navbarControl.setActiveNavbarButtons([]);
      setTimeout(() => (getWindowRef()[ofActionDispatchedSignOutNoAuthState] = undefined), 1000);
    });

  }

}
