<div class="highlights ht-dialog-elevation">
      
  <ng-container *ngIf="service.imageInformation$ | async as result;else noAdditionalInformation">
    <ng-container *ngIf="result.imgAuthInfo?.authorProfId === 'viator'">
      <ng-container *ngIf="result.tourData?.addInfo as data">
        <h2 *ngIf="data.length" translate="search-result.additional-information"></h2>
        <div class="highlights--list" [@opacityFade]>
          <div class="highlights--list-item highlights" *ngFor="let item of data;">
            <mat-icon svgIcon="search-result-dialog-common.highlight-svg"></mat-icon>
            <p>{{ item }}</p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noAdditionalInformation>
    <h2 translate="search-result.additional-information"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>
      
  <ng-container *ngIf="service.imageInformation$ | async as result;else noHighlights">
    <ng-container *ngIf="result.tourData?.highlights as data">
      <h2 *ngIf="data.length" translate="search-result.highlights"></h2>
      <div class="highlights--list" [@opacityFade]>
        <div class="highlights--list-item highlights" *ngFor="let item of data;">
          <mat-icon svgIcon="search-result-dialog-common.highlight-svg"></mat-icon>
          <p>{{ item }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noHighlights>
    <h2 translate="search-result.highlights"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>

  <ng-container *ngIf="service.imageInformation$ | async as result;else noInclusions">
    <ng-container *ngIf="result.tourData?.inclusions as data">
      <h2 *ngIf="data.length" translate="search-result.whats-included"></h2>
      <div class="highlights--list" [@opacityFade]>
        <div class="highlights--list-item inclusions" *ngFor="let item of data;">
          <mat-icon svgIcon="search-result-dialog-common.inclusion-svg"></mat-icon>
          <p>{{ item }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noInclusions>
    <h2 translate="search-result.whats-included"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>

  <ng-container *ngIf="service.imageInformation$ | async as result;else noExclusions">
    <ng-container *ngIf="result.tourData?.exclusions as data">
      <h2 *ngIf="data.length" translate="search-result.whats-not-included"></h2>
      <div class="highlights--list" [@opacityFade]>
        <div class="highlights--list-item exclusions" *ngFor="let item of data;">
          <mat-icon svgIcon="search-result-dialog-common.exclusion-svg"></mat-icon>
          <p>{{ item }}</p>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noExclusions>
    <h2 translate="search-result.whats-not-included"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate"></mat-progress-spinner>
  </ng-template>

</div>
