<form class="travel-experience" [formGroup]="service.form">
  
  <p class="section-title" translate="reg-form.travel-exp"></p>

  <mat-form-field underlined appearance="outline" formGroupName="trExp">
    <mat-label>{{ 'reg-form.travel-exp-b' | translate }}</mat-label>
    <input matInput [placeholder]="'reg-form.placeholder-tell-travel-exp' | translate" name="description" type="text" formControlName="desc">
  </mat-form-field>

</form>
