import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { RegistrationFormModule } from '@hiptraveler/features/registration-form';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { SafeUrlPipeModule } from '@hiptraveler/common';

import { AmbassadorComponent } from './ambassador.component';
import { SocialMediaEventDirective } from './social-media-event.directive';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  RegistrationFormModule,
  ButtonsModule,
  SafeUrlPipeModule
];

const COMPONENTS = [
  AmbassadorComponent
];

const DIRECTIVES = [
  SocialMediaEventDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: AmbassadorComponent
    }])
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ]
})
export class AmbassadorModule { }
