import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { ActivityInputOverlayComponent } from './activity-input-overlay.component';
import { ViewTitlePipe } from './view-title.pipe';
import { FilterDataPipe } from './filter-data.pipe';
import { SectionTitlePipe } from './section-title.pipe';
import { SelectionPipe } from './selection.pipe';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  MobileNavbarViewModule,
  ButtonsModule
];

const COMPONENTS = [
  ActivityInputOverlayComponent
];

const PIPES = [
  ViewTitlePipe,
  FilterDataPipe,
  SectionTitlePipe,
  SelectionPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ]
})
export class ActivityInputOverlayModule { }
