import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { DataSelectionComponent } from './data-selection.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule
];

const COMPONENTS = [
  DataSelectionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    DataSelectionComponent
  ]
})
export class DataSelectionModule { }
