import { NgModule } from '@angular/core';

import { ApiAnalyticsModule } from '@hiptraveler/modules/api-analytics';
import { MetaTagModule } from '@hiptraveler/features/meta-tag';

import { RequiredStateDirective } from './required-state.directive';
import { I18nSetupDirective } from './i18n-setup.directive';
import { ClientWidthListenerDirective } from './client-width-listener.directive';
import { ScrollToViewListenerDirective } from './scroll-to-view-listener.directive';
import { CalendarI18nListenerDirective } from './calendar-i18n-listener.directive';
import { CustomGoogleMapAutocompleteStateDirective } from './custom-google-map-autocomplete-state.directive';
import { LayoutStylesDirective } from './layout-styles.directive';

const LIBRARIES = [
  ApiAnalyticsModule
];

const DIRECTIVES = [
  RequiredStateDirective,
  I18nSetupDirective,
  ClientWidthListenerDirective,
  ScrollToViewListenerDirective,
  CalendarI18nListenerDirective,
  CustomGoogleMapAutocompleteStateDirective,
  LayoutStylesDirective
];

@NgModule({
  imports: LIBRARIES,
  declarations: DIRECTIVES,
  exports: [
    DIRECTIVES,
    MetaTagModule
  ]
})
export class AppDirectivesModule { }
