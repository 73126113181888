<div class="social-handles mat-elevation-z2" *ngIf="service.response$ | async as response">

  <h2>Casa Creadora: Social Media Stats</h2>
  
  <div class="social-handles--container">

    <div class="social-handles--row social-handles--row-header">
      <p style="border-top-left-radius: 4.5px;">Social Channels</p>
      <p>Likes</p>
      <p>Followers</p>
      <p>Handle</p>
      <p style="border-top-right-radius: 4.5px;">Link</p>
    </div>

    <div class="social-handles--row" *ngFor="let response of response.channelMetrics?.socialChannels;let isLast = last">
      <p [ngStyle]="{ 'border-bottom-left-radius' : isLast ? '4.5px' : 0 }">{{ response.channelName }}</p>
      <p>{{ response.likeCount }}</p>
      <p>{{ response.followerCount }}</p>
      <p>{{ response.handle }}</p>
      <p [ngStyle]="{ 'border-bottom-right-radius' : isLast ? '4.5px' : 0 }" (click)="service.visitProfileByLink(response.link)">Visit profile</p>
    </div>

  </div>

</div>
