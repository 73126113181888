import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { SocialHandlesSectionService } from './social-handles-section.service';

@Component({
  selector: 'social-handles-section',
  templateUrl: './social-handles-section.component.html',
  styleUrls: ['./social-handles-section.component.scss'],
  host: { class: 'profile-page-user-selections-tab-about-tab-social-handles' },
  viewProviders: [ SocialHandlesSectionService ],
  encapsulation: ViewEncapsulation.None
})
export class SocialHandlesSectionComponent implements OnInit {

  constructor(
    public service: SocialHandlesSectionService
  ) { }

  ngOnInit(): void {
    this.service.ambassadorUser$.subscribe(() => {
      this.service.getRequestData();
    });
  }

}
