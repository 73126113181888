import { Component } from '@angular/core';

import { FiltersService } from './filters.service';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  viewProviders: [ FiltersService ]
})
export class FiltersComponent2 {

  constructor(
    public service: FiltersService,
    public stateService: ExperienceFinderStateService
  ) { }

  clearFilters(): void {
    this.service.form.reset();
  }

}
