import { StateContext } from "@ngxs/store";
import { uniqBy as uniqByLodash } from 'lodash';

import { SearchStateField, SearchStateModel } from "..";

export function uniqBy(ctx: StateContext<SearchStateModel>, data: any, param1: SearchStateField, param2: string, field: string = 'id'): any[] {

  const stateData = (ctx.getState()?.[param1] || []) as any[];

  const newData = stateData.slice(0).concat(data?.[param2] || []) as any[];

  return uniqByLodash(newData.slice(0), field);
}
