import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, delay, takeUntil } from 'rxjs';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { DialogView } from '../../experience-finder.model';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'selection-view',
  templateUrl: './selection-view.component.html',
  styleUrls: ['./selection-view.component.scss', './select-slide-toggle.scss', './navigation-lts.scss'],
  animations: [ opacityFadeIn() ]
})
export class SelectionViewComponent implements OnInit, OnDestroy {

  @ViewChild('checkbox') checkbox?: ElementRef;

  #dialogView: DialogView = 'travel-style';
  subscription$ = new Subject<void>();

  constructor(
    public stateService: ExperienceFinderStateService,
  ) { }
  
  ngOnInit(): void {
    
    this.dialogViewObserver();

    this.stateService.dialogView$$.pipe(
      delay(0),
      takeUntil(this.subscription$)
    ).subscribe((view: DialogView) => {
      const checkbox = this.checkbox?.nativeElement as HTMLInputElement;
      checkbox && (checkbox.checked = (view === 'plan-trip'));
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get dialogView(): DialogView {
    return this.#dialogView;
  }

  updateSelection(view: DialogView): void {
    this.stateService.dialogView$$.next(view);
  }

  dialogViewObserver(): void {
    this.stateService.dialogView$$.asObservable().pipe(delay(0)).subscribe((dialogView: DialogView) => {
      this.#dialogView = dialogView;
    });
  }

}
