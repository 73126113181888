import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ImageCarouselComponent } from './image-carousel.component';
import { AssetImageModule } from '../../common';

const COMPONENTS = [
  ImageCarouselComponent
];

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  AssetImageModule
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ImageCarouselComponent
  ]
})
export class ImageCarouselModule { }
