import { Pipe, PipeTransform } from '@angular/core';
import { ActivityDateData } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'parseActivityDate'
})
export class ParseActivityDatePipe implements PipeTransform {

  transform(value: ActivityDateData[]): ActivityDateData[] {
    if (!value) return [];
    return value.map((data: ActivityDateData, index: number) => {
      
      if (!data?.date) {
        data.day = index + 1;
      }

      return data;
    });
  }

}
