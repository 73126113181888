import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';
import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';

import { LocationInputOverlayComponent } from './location-input-overlay.component';

const MODULES = [
  CommonModule,
  FormsModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  MobileNavbarViewModule,
  CustomMapAutocompleteModule
];

const COMPONENTS = [
  LocationInputOverlayComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class LocationInputOverlayModule { }
