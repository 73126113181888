import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';

import { AuthAction } from '@hiptraveler/data-access/auth';
import { SearchAction, setAdventuresQuery, setCommunitiesQuery, setFoodsQuery, setHotelsQuery, setItinerariesQuery } from '@hiptraveler/data-access/search';
import { getWindowRef, ofActionDispatchedSignOutNoAuthState } from '@hiptraveler/common';

@Injectable()
export class DispatchListenerService {

  constructor(
    private actions$: Actions
  ) { }

  observe(): void {

    this.actions$.pipe(
      ofActionDispatched(AuthAction.SignOutNoAuth),
    ).subscribe(() => {
      getWindowRef()[ofActionDispatchedSignOutNoAuthState] = '1';
    });

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetItinerariesContent,
        SearchAction.GetItinerariesByQuery
      )
    ).subscribe((value: any) => setItinerariesQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetAdventuresContent,
        SearchAction.GetAdventuresByQuery,
        SearchAction.GetAdventureTermByQuery
      )
    ).subscribe((value: any) => setAdventuresQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetHotelsContent,
        SearchAction.GetHotelsByQuery,
        SearchAction.GetHotelsTermByQuery
      )
    ).subscribe((value: any) => setHotelsQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetFoodsContent,
        SearchAction.GetFoodsByQuery,
        SearchAction.GetFoodsTermByQuery
      )
    ).subscribe((value: any) => setFoodsQuery(value.query));

    this.actions$.pipe(
      ofActionDispatched(
        SearchAction.GetCommunitiesByQuery,
      )
    ).subscribe((value: any) => setCommunitiesQuery(value.query));
  }

}
