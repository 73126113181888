import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { LocationData } from '@hiptraveler/data-access/api';
import { CustomMapAutocompleteService } from './custom-map-autocomplete.service';
import { SearchLocationData, SearchLocationService, toSearchLocation } from '@hiptraveler/common';

@Component({
  selector: 'custom-map-autocomplete',
  template: `
    <div class="cma-container mat-elevation-z2" cmaDisplay [attr.custom-maps-id]="customMapsId" [ngClass]="{ requesting }" [ngStyle]="{ display }">

      <ng-container *ngIf="requesting">
        <div matRipple *ngFor="let _ of [0,0,0];">
          <span></span><ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
        </div>
      </ng-container>
  
      <ng-container *ngFor="let result of (locations || (service.locations$ | async));">
        <div matRipple (pointerdown)="searchSelectedLocation(result);" *ngIf="result?.formattedAddr">
          <span></span><p>{{ result.formattedAddr }}</p>
        </div>
      </ng-container>
  
    </div>
  `,
  styleUrls: ['./custom-map-autocomplete.component.scss'],
  host: { class: 'features-custom-map-autocomplete' },
  viewProviders: [ CustomMapAutocompleteService ],
  encapsulation: ViewEncapsulation.None
})
export class CustomMapAutocompleteComponent implements OnInit {

  @Output() locationChanged = new EventEmitter<SearchLocationData>();

  customMapsId: string = '';
  locations: LocationData[] | null;
  requesting: boolean;
  emitOnly: boolean;
  display: 'block' | 'none' = 'none';

  constructor(
    private searchLocation: SearchLocationService,
    public service: CustomMapAutocompleteService
  ) { }

  ngOnInit(): void {
    this.service.requestState((locations: LocationData[] | null, requesting: boolean) => {
      this.requesting = requesting;
      if (locations?.length === 0) return;
      this.locations = locations;
    });
  }

  searchSelectedLocation(result: LocationData): void {
    this.emitOnly
      ? this.locationChanged.emit(toSearchLocation(result))
      : this.searchLocation.updateSearchLocation(toSearchLocation(result));
  }

}
