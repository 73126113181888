import { NgModule } from '@angular/core';

import { ProfilePageDirective } from './profile-page.directive';

const DIRECTIVES = [
  ProfilePageDirective
];

@NgModule({
  declarations: [
    DIRECTIVES,
  ],
  exports: [
    ProfilePageDirective
  ]
})
export class ProfilePageModule { }
