import { NgModule } from '@angular/core';

import { ScrollElementBorderDirective } from './scroll-element-border.directive';

const DIRECTIVES = [
  ScrollElementBorderDirective
];

@NgModule({
  declarations: [
    DIRECTIVES,
  ],
  exports: [ ScrollElementBorderDirective ]
})
export class ScrollElementBorderDirectiveModule { }
