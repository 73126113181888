<ng-container *ngIf="userInfoMobile$ | async;else desktopView">
  <user-info-mobile></user-info-mobile>
  <user-info-mobile-toolbar></user-info-mobile-toolbar>
</ng-container>

<ng-template #desktopView>
  <user-info></user-info>
</ng-template>

<ng-container *ngIf="stateService.profileSettingsView$$ | async;else defaultView">
  <user-settings [@opacityFadeIn]></user-settings>
</ng-container>

<ng-template #defaultView>

  <user-activity [@opacityFadeIn]></user-activity>

  <ng-container *ngIf="stateService.postButton$ | async">
    <br><post-action-button [elevation]="2" [@opacityFadeIn]></post-action-button>
  </ng-container>

  <ng-container *ngIf="stateService.uploadButton$ | async">
    <br><custom-activity-action-button [@opacityFadeIn]></custom-activity-action-button>
  </ng-container>

  <div class="profile-selection" [@opacityFadeIn]>

    <ng-container [ngSwitch]="stateService.profileTabSelection$$ | async">

      <stories-tab *ngSwitchCase="'stories'" [@opacityFadeIn]></stories-tab>

      <about-tab *ngSwitchCase="'about'" [@opacityFadeIn]></about-tab>

      <itineraries-tab *ngSwitchCase="'itineraries'" [@opacityFadeIn]></itineraries-tab>

      <favorites-tab *ngSwitchCase="'favorites'" [@opacityFadeIn]></favorites-tab>

      <follows-tab *ngSwitchCase="'follows'" [@opacityFadeIn]></follows-tab>

      <followers-tab *ngSwitchCase="'followers'" [@opacityFadeIn]></followers-tab>

      <uploads-tab *ngSwitchCase="'uploads'" [@opacityFadeIn]></uploads-tab>

    </ng-container>

    <ng-container *ngIf="loadMoreVisibility$ | async">
      <ng-container *ngIf="!(stateService.loadMoreProgressState$ | async);else loadMoreButtonUIProgress">
        <ui-action-button (click)="loadMoreSearchResults()">{{ 'profile.load-more' | translate }}</ui-action-button>
      </ng-container>
      <ng-template #loadMoreButtonUIProgress>
        <mat-progress-spinner class="primary load-more-results" mode="indeterminate"></mat-progress-spinner>
      </ng-template>
    </ng-container>

  </div>

</ng-template>
