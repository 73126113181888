import { Component, Input } from '@angular/core';

import { TravelAgentData } from '@hiptraveler/data-access/api';
import { StoryTravelAgentsService } from './../story-travel-agents.service';
import { profilePlaceholder, subdomain } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'highlight-card',
  templateUrl: './highlight-card.component.html',
  styleUrls: ['./highlight-card.component.scss', './highlight-card-styles.component.scss'],
  animations: [ opacityFade() ]
})
export class HighlightCardComponent {

  @Input() travelAgent: TravelAgentData;

  profilePlaceholder: string = profilePlaceholder;
  subdomain: string = subdomain();

  constructor(
    public service: StoryTravelAgentsService,
  ) { }

}
