import { Component, OnInit, Self, ViewEncapsulation } from '@angular/core';

import { ProfileService } from './profile.service';
import { AppListenerService, DefaultUiService } from '@hiptraveler/common';
import { DataAccessService, ProfileStateFormService, ProfileStateService } from './common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  host: { class: 'page-profile' },
  viewProviders: [ DefaultUiService, ProfileService, DataAccessService, ProfileStateService, ProfileStateFormService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn() ]
})
export class ProfileComponent implements OnInit {

  constructor(
    @Self() private defaultUI: DefaultUiService,
    private service: ProfileService,
    private appListener: AppListenerService,
    public stateService: ProfileStateService,
  ) { }

  ngOnInit(): void {
    this.service.initStateService();
    this.service.getProfileDetails();
    this.service.authenticationObserver();
    this.service.profileNavigationListener();
    setTimeout(() => this.appListener.experienceFinderVisibility$$.next(true), 100);
  }

}
