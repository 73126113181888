import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarModule } from './navbar/navbar.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { BottomNavbarModule } from './bottom-navbar/bottom-navbar.module';

import { SiteNavigationComponent } from './site-navigation.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  NavbarModule,
  ToolbarModule,
  BottomNavbarModule,
];

const COMPONENTS = [
  SiteNavigationComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SiteNavigationComponent
  ]
})
export class SiteNavigationModule { }
