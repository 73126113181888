<navbar-view (actionClicked)="dialogRef.close()"></navbar-view>

<dialog-close-button></dialog-close-button>

<div class="auth-register-dialog">

  <form class="auth-dialog--form" [formGroup]="service.form">
  
    <p class="auth-dialog--form-title" translate="auth.register"></p>

    <div class="auth-dialog--form-field">
      <mat-form-field>
        <mat-label>{{ 'auth.first-name' | translate }}</mat-label>
        <input matInput type="text" name="firstName" formControlName="firstName">
        <mat-error *ngIf="controlError('firstName')">{{ 'auth.invalid-first-name' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('firstName')">{{ 'auth.first-name-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-dialog--form-field">
      <mat-form-field>
        <mat-label>{{ 'auth.last-name' | translate }}</mat-label>
        <input matInput type="text" name="lastName" formControlName="lastName">
        <mat-error *ngIf="controlError('lastName')">{{ 'auth.invalid-last-name' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('lastName')">{{ 'auth.last-name-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-dialog--form-field">
      <mat-form-field>
        <mat-label>{{ 'auth.email' | translate }}</mat-label>
        <input matInput type="text" autocomplete="username" name="email" formControlName="email">
        <mat-error *ngIf="controlError('email')">{{ 'auth.invalid-email-format' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('email')">{{ 'auth.email-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-dialog--form-field">
      <mat-form-field>
        <mat-label>{{ 'auth.password' | translate }}</mat-label>
        <input matInput type="password" autocomplete="current-password" name="password" formControlName="password">
        <mat-error *ngIf="controlError('password')">{{ 'auth.invalid-password-format' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('password')">{{ 'auth.password-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-dialog--form-field">
      <mat-form-field>
        <mat-label>{{ 'auth.location' | translate }}</mat-label>
        <input matInput locationFieldInput type="text" name="location" formControlName="location">
        <mat-error *ngIf="controlError('userLoc')">{{ 'auth.invalid-location-format' | translate }}</mat-error>
        <mat-error *ngIf="requiredControl('userLoc')">{{ 'auth.location-required' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-dialog--form-field checkbox dropdown-checkbox" [ngClass]="{ checked: travelTradeCheckbox.checked, visibility: service.hasTravelAgents$ | async }">
      <mat-checkbox formControlName="travelTrade" #travelTradeCheckbox></mat-checkbox>
      <p (click)="travelTradeCheckbox.toggle()" translate="auth.travel-trade"></p>
    </div>
    <ng-container *ngIf="travelTradeCheckbox.checked">
      <div class="auth-dialog--form-field" [@inOutCollapse655]>
        <mat-form-field>
          <mat-label>{{ 'auth.travel-trade-option' | translate }}</mat-label>
          <mat-select formControlName="userType">
            <mat-option value="htAgent">{{ 'auth.travel-agent' | translate }}</mat-option>
            <mat-option value="htOperator">{{ 'auth.tour-operator' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="service.hasNewsLetter$ | async">
      <div class="auth-dialog--form-field checkbox">
        <mat-checkbox #newsletterCheckbox formControlName="acceptNewsLetter"></mat-checkbox>
        <p (click)="newsletterCheckbox.toggle()" translate="auth.receive-updates"></p>
      </div>
    </ng-container>

    <ui-action-button type="button" [pending]="!!(service.pending$$ | async)" (click)="service.signUpWithEmailAndPassword()">{{ 'auth.sign-up' | translate }}</ui-action-button>
    
  </form>

  <p class="auth-dialog--policy" *ngIf="service.hiptraveler">
    {{ 'auth.terms-agreement' | translate }} <span (click)="termsAndConditions.open()">{{ 'auth.terms-link' | translate }}</span> {{ 'auth.and' | translate }} <span (click)="privacyPolicy.open()">{{ 'auth.privacy-policy' | translate }}</span>
  </p>

  <p class="auth-dialog--policy" *ngIf="!service.hiptraveler">
    {{ 'auth.account-registration' | translate: { value: service.brandTitle$ | async } }} <span (click)="termsAndConditions.open()">{{ 'auth.terms-link' | translate }}</span> {{ 'auth.and' | translate }} <span (click)="privacyPolicy.open()">{{ 'auth.privacy-policy' | translate }}</span>
  </p>

</div>
