import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { LocationData } from '@hiptraveler/data-access/api';
import { OverlayCarouselDialogActionService } from '@hiptraveler/dialogs/overlay-carousel-dialog';
import { WINDOW } from '@hiptraveler/common';
import { SearchState } from '@hiptraveler/data-access/search';

@Component({
  selector: 'banner-selection-items',
  templateUrl: './banner-selection-items.component.html',
  styleUrls: ['./banner-selection-items.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ OverlayCarouselDialogActionService ]
})
export class BannerSelectionItemsComponent {

  @Input() locationData: LocationData;

  constructor(
    @Inject(WINDOW) private window: any,
    private store: Store,
    private overlayCarousel: OverlayCarouselDialogActionService
  ) { }

  about(): void {
    this.window.open(`https://en.wikipedia.org/wiki/${this.locationData.city}`, '_blank');
  }

  photoGallery(): void {
    this.store.selectSnapshot(SearchState.locationData) && this.overlayCarousel.open();
  }

  visaRequirements(): void {
    this.window.open(this.locationData.visaLink, '_blank');
  }

  covid19Information(): void {
    this.window.open(this.locationData.safetyLink, '_blank');
  }

  learnLanguage(): void {
    this.window.open('https://www.pimsleur.com/', '_blank');
  }

}
