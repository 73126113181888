import { Environment } from "@hiptraveler/common";

export const environment: Environment = {
  local: false,
  production: true,
  build: 'Production',
  apiKey: {
    filestack: 'A5yG40niNR3q9y7GBglCLz',
    facebookAppId: '1779459539145435',
    googleAuthClientId: '135150096307-q7l1fudksraomas4v46hajctb6hfcf6u.apps.googleusercontent.com'
  },
  api: {
    auth: `https://api.hiptraveler.com/api/auth`,
    tripPlanner: `https://api.hiptraveler.com/trip-planner`
  }
};
