import { AfterViewInit, Directive, ElementRef, Input, NgModule, OnDestroy } from '@angular/core';
import { Subject, fromEvent, map, takeUntil } from 'rxjs';

@Directive({
  selector: '[scrollElementBorder]'
})
export class ScrollElementBorderDirective implements OnDestroy, AfterViewInit {

  @Input() adjustOffset: string;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {

    const element = this.element.nativeElement;
    const scrollElement = element.parentElement?.parentElement?.parentElement!

    const scroll$ = fromEvent(scrollElement, 'scroll').pipe(
      map(() => scrollElement.scrollTop)
    );
    
    scroll$.pipe(takeUntil(this.subscription$)).subscribe((scrollTopValue: number) => {
      element.style.boxShadow = scrollTopValue > 0 ? '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)' : 'none';
    });
  }

}

@NgModule({
  declarations: [ ScrollElementBorderDirective ],
  exports:      [ ScrollElementBorderDirective ]
})
export class ScrollElementBorderDirectiveModule { }
