import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, filter, firstValueFrom, map, take, takeUntil, tap } from 'rxjs';

import { UserProfile } from '@hiptraveler/data-access/api';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { UserSettingsService } from '../user-settings.service';
import { clientVID } from '@hiptraveler/common';
import { ProfileStateService, ProfileStateFormService, SocialMedia } from '../../../common';

@Injectable()
export class ProfileFormService implements OnDestroy {

  selectedSocMed$$ = new BehaviorSubject<SocialMedia>('twitter');
  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    private stateService: ProfileStateService,
    private stateFormService: ProfileStateFormService,
    private userSettings: UserSettingsService
  ) {
    this.stateService.saveClicked$$.asObservable()
      .pipe(takeUntil(this.subscription$))
      .subscribe(this.submitListener.bind(this));
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  setInitialProfileFormData(): void {
    this.store.select(ProfileState.profileDetails).pipe(
      filter(Boolean),
      tap((profile: UserProfile) => this.stateFormService.profileForm.patchValue({
        firstName: profile.firstName,
        lastName: profile.lastName,
        userDesc: profile.description,
        travelQuote: profile.favQuote,
        homeTown: profile.hometown,
        currLoc: profile.userLoc,
        rStatus: profile.rStatus,
        dob: profile.dob ? new Date(profile.dob).toISOString() : ''
      })),
      take(1)
    ).subscribe();
  }

  get socMedLabel$(): Observable<string> {
    return this.selectedSocMed$$.asObservable().pipe(
      map((value: SocialMedia) => {
        switch (value) {
          case 'twitter': return 'profile.twitter';
          case 'facebook': return 'profile.facebook';
          case 'youtube': return 'profile.youtube';
          case 'pinterest': return 'profile.pinterest';
          case 'instagram': return 'profile.instagram';
          case 'tiktok': return 'profile.tiktok';
          case 'website': return 'profile.personal-site';
        }
      })
    )
  }

  async addSocMed(input: string): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new ProfileAction.UpdateProfileContent({
        action: 'profileSocial',
        id: this.store.selectSnapshot(ProfileState.profileDetails)!.profileId,
        connect_url: input,
        social: this.selectedSocMed$$.value,
        vId: clientVID()
      })));
    } finally { }
  }

  updateSocialMedia(value: SocialMedia, input: HTMLInputElement): void {
    setTimeout(() => {
      this.selectedSocMed$$.next(value);
      const socialMediaMap = this.store.selectSnapshot(ProfileState.profileDetails)?.connectWith;
      switch (value) {
        case 'twitter': return (input.value = socialMediaMap?.[value] || 'https://twitter.com/example');
        case 'facebook': return (input.value = socialMediaMap?.[value] || 'https://facebook.com/example');
        case 'youtube': return (input.value = socialMediaMap?.[value] || 'https://youtube.com/example');
        case 'pinterest': return (input.value = socialMediaMap?.[value] || 'https://pinterest.com/example');
        case 'instagram': return (input.value = socialMediaMap?.[value] || 'https://instagram.com/example');
        case 'tiktok': return (input.value = socialMediaMap?.[value] || 'https://www.tiktok.com/@example');
        case 'website': return (input.value = socialMediaMap?.[value] || 'https://www.mywebsite.com/example');
      }
    });
  }

  async submitListener(): Promise<void> {
    const value = this.stateFormService.profileForm.value;
    value.dob = new Date(value.dob).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    value.dob = value.dob === 'Invalid Date' ? '' : value.dob;
    value.id = this.store.selectSnapshot(ProfileState.profileDetails)!.profileId;
    try {
      this.userSettings.formProcessing$$.next(true);
      await firstValueFrom(this.store.dispatch(new ProfileAction.UpdateProfileContent(value)));
      this.userSettings.formProcessing$$.next(false);
      this.stateService.profileTabSelection$$.next('about');
      this.stateService.profileSettingsView$$.next(false);
    } catch (error) {
    } finally {
    }
  }

}
