import { Injectable, Injector } from '@angular/core';

import { ChatCompletion, ModerationCreateResponse } from 'openai/resources';
import { OpenAIFn, promptToCompletionParam, HIPTRAVELER_GPT, call, Conversation, toMessages, parseConversations } from '.';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChatCompletionsService extends OpenAIFn {

  conversations$$ = new BehaviorSubject<Conversation[]>([]);
  conversations$ = parseConversations(this.conversations$$)

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  async sendMessage(content: string, prompt: string[] = HIPTRAVELER_GPT, type?: 'ondemand'): Promise<ChatCompletion> {

    try {

      this.appendConversation({ message: content, sender: true });

      const moderation: ModerationCreateResponse = await call(this.openaiClient.moderations.create({
        model: "omni-moderation-latest",
        input: content,
      })); console.log('@@@ opanai :: moderation', moderation); // To do: Remove console log
  
      if (moderation.results[0].flagged) {
        throw new Error('Your message violates the moderation policy.');
      }

      const promptMessages = type === 'ondemand' ? [{ message: content, sender: true }] : [...this.conversations$$.value];

      console.log('@@@ openai :: - - - - -');
      console.log('@@@ opanai :: prompt-messages', promptMessages);
      console.log('@@@ opanai :: prompt', [ ...promptToCompletionParam(prompt), ...toMessages(promptMessages) ]);

      const chatCompletion: ChatCompletion = await call(this.openaiClient.chat.completions.create({
        model: "gpt-4o-mini", 
        temperature: 0.7,
        messages: [ ...promptToCompletionParam(prompt), ...toMessages(promptMessages) ]
      })); console.log('@@@ opanai :: chatCompletion -', chatCompletion.choices[0].message.content); // To do: Remove console log

      console.log('@@@ openai :: - - - - -');
  
      const messageContent = chatCompletion.choices[0].message.content;
  
      if (!messageContent) {
        throw new Error('Failed to get a valid response.');
      }
  
      this.appendConversation({ message: messageContent, sender: false });

      return chatCompletion;

    } catch (value: any) {

      console.error(value?.message || 'An error occurred while sending the message');
      throw value;

    }
  }

  appendConversation(conversation: Conversation): void {

    const conversations = [ ...this.conversations$$.value ];
    conversations.push(conversation);

    this.conversations$$.next(conversations);
  }

  clearConversations(): void {
    this.conversations$$.next([]);
  }

}
