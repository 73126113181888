<div class="mockup">
  
  <p style="font-size: 34px;font-weight: bold;" translate="terms.1"></p>

  <br>

  <p [innerHTML]="'terms.2' | translate"></p>
  
  <p [innerHTML]="'terms.3' | translate"></p>
  
  <p [innerHTML]="'terms.4' | translate"></p>
  
  <h5 [innerHTML]="'terms.5' | translate"></h5>

  <ol>
    <li translate="terms.6"></li>
    <li translate="terms.7"></li>
    <li translate="terms.8"></li>
    <li translate="terms.9"></li>
    <li translate="terms.10"></li>
    <li translate="terms.11"></li>
    <li translate="terms.12"></li>
  </ol>

  <h5 [innerHTML]="'terms.13' | translate"></h5>

  <p [innerHTML]="'terms.14' | translate"></p>

  <p [innerHTML]="'terms.15' | translate"></p>

  <p [innerHTML]="'terms.16' | translate"></p>

  <p [innerHTML]="'terms.17' | translate"></p>

  <h5 [innerHTML]="'terms.18' | translate"></h5>

  <p [innerHTML]="'terms.19' | translate"></p>

  <p [innerHTML]="'terms.20' | translate"></p>
  
  <ol>
    <li translate="terms.21"></li>
    <li translate="terms.22"></li>
    <li translate="terms.23"></li>
    <li translate="terms.24"></li>
    <li translate="terms.25"></li>
    <li translate="terms.26"></li>
    <li translate="terms.27"></li>
    <li translate="terms.28"></li>
    <li translate="terms.29"></li>
    <li translate="terms.30"></li>
    <li translate="terms.31"></li>
    <li translate="terms.32"></li>
    <li translate="terms.33"></li>
    <li translate="terms.34"></li>
    <li translate="terms.35"></li>
  </ol>
  
  <p [innerHTML]="'terms.36' | translate"></p>

  <p [innerHTML]="'terms.37' | translate"></p>
  
  <p [innerHTML]="'terms.38' | translate"></p>
  
  <p [innerHTML]="'terms.39' | translate"></p>
  
  <p [innerHTML]="'terms.40' | translate"></p>
  
  <p [innerHTML]="'terms.41' | translate"></p>
  
  <p [innerHTML]="'terms.42' | translate"></p>

  <p [innerHTML]="'terms.43' | translate"></p>
  
  <ol>
    <li translate="terms.44"></li>
    <li translate="terms.45"></li>
    <li translate="terms.46"></li>
    <li translate="terms.47"></li>
    <li translate="terms.48"></li>
    <li translate="terms.49"></li>
    <li translate="terms.50"></li>
  </ol>
  
  <p [innerHTML]="'terms.51' | translate"></p>
  
  <p [innerHTML]="'terms.52' | translate"></p>
  
  <p [innerHTML]="'terms.53' | translate"></p>
  
  <p [innerHTML]="'terms.54' | translate"></p>
  
  <p [innerHTML]="'terms.55' | translate"></p>

  <h5 [innerHTML]="'terms.56' | translate"></h5>

  <p [innerHTML]="'terms.57' | translate"></p>

  <h5 [innerHTML]="'terms.58' | translate"></h5>

  <p [innerHTML]="'terms.59' | translate"></p>

  <p [innerHTML]="'terms.60' | translate"></p>
  
  <p [innerHTML]="'terms.61' | translate"></p>
  
  <p [innerHTML]="'terms.62' | translate"></p>
  
  <p [innerHTML]="'terms.63' | translate"></p>
  
  <p [innerHTML]="'terms.64' | translate"></p>

  <h5 [innerHTML]="'terms.65' | translate"></h5>

  <p [innerHTML]="'terms.66' | translate"></p>
  
  <p [innerHTML]="'terms.67' | translate"></p>
  
  <p [innerHTML]="'terms.68' | translate"></p>
  
  <p [innerHTML]="'terms.69' | translate"></p>
  
  <p [innerHTML]="'terms.70' | translate"></p>
  
  <p [innerHTML]="'terms.71' | translate"></p>
  
  <p [innerHTML]="'terms.72' | translate"></p>
  
  <p [innerHTML]="'terms.73' | translate"></p>
  
  <p [innerHTML]="'terms.74' | translate"></p>
  
  <p [innerHTML]="'terms.75' | translate"></p>
  
  <p [innerHTML]="'terms.76' | translate"></p>

  <h5 [innerHTML]="'terms.77' | translate"></h5>
  
  <p [innerHTML]="'terms.78' | translate"></p>
  
  <p [innerHTML]="'terms.79' | translate"></p>
  
  <p [innerHTML]="'terms.80' | translate"></p>
  
  <p [innerHTML]="'terms.81' | translate"></p>
  
  <p [innerHTML]="'terms.82' | translate"></p>
  
  <p [innerHTML]="'terms.83' | translate"></p>
  
  <p [innerHTML]="'terms.84' | translate"></p>
  
  <p [innerHTML]="'terms.85' | translate"></p>
  
  <p [innerHTML]="'terms.86' | translate"></p>
  
  <ol>
    <li translate="terms.87"></li>
    <li translate="terms.88"></li>
    <li translate="terms.89"></li>
    <li translate="terms.90"></li>
    <li translate="terms.91"></li>
    <li translate="terms.92"></li>
    <li translate="terms.93"></li>
    <li translate="terms.94"></li>
    <li translate="terms.95"></li>
  </ol>
  
  <p [innerHTML]="'terms.96' | translate"></p>
  
  <p [innerHTML]="'terms.97' | translate"></p>
  
  <p [innerHTML]="'terms.98' | translate"></p>

  <p [innerHTML]="'terms.99' | translate"></p>

  <p [innerHTML]="'terms.100' | translate"></p>
  
  <p [innerHTML]="'terms.101' | translate"></p>
  
  <p [innerHTML]="'terms.102' | translate"></p>
  
  <p [innerHTML]="'terms.103' | translate"></p>
  
  <p [innerHTML]="'terms.104' | translate"></p>
  
  <p [innerHTML]="'terms.105' | translate"></p>
  
  <p [innerHTML]="'terms.106' | translate"></p>

  <p [innerHTML]="'terms.107' | translate"></p>

  <p [innerHTML]="'terms.108' | translate"></p>

  <ol>
    <li translate="terms.109"></li>
    <li translate="terms.110"></li>
    <li translate="terms.111"></li>
  </ol>
  
  <p [innerHTML]="'terms.112' | translate"></p>
  
  <p [innerHTML]="'terms.113' | translate"></p>
  
  <p [innerHTML]="'terms.114' | translate"></p>
  
  <p [innerHTML]="'terms.115' | translate"></p>
  
  <p [innerHTML]="'terms.116' | translate"></p>
  
  <h5 [innerHTML]="'terms.117' | translate"></h5>

  <p [innerHTML]="'terms.118' | translate"></p>

  <p [innerHTML]="'terms.119' | translate"></p>
  
  <p [innerHTML]="'terms.120' | translate"></p>
  
  <p [innerHTML]="'terms.121' | translate"></p>
  
  <p [innerHTML]="'terms.122' | translate"></p>
  
  <p [innerHTML]="'terms.123' | translate"></p>
  
  <p [innerHTML]="'terms.124' | translate"></p>
  
  <p [innerHTML]="'terms.125' | translate"></p>
  
  <p [innerHTML]="'terms.126' | translate"></p>
  
  <p [innerHTML]="'terms.127' | translate"></p>
  
  <p [innerHTML]="'terms.128' | translate"></p>
  
  <p [innerHTML]="'terms.129' | translate"></p>

  <br><br>

  <use-policy-and-copyright></use-policy-and-copyright>

</div>
