import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { BrandState } from '@hiptraveler/data-access/brand';
import { adventure, hotel, foodAndDrinks } from './icon-svg-collection';

@Pipe({
  name: 'searchSvgPath'
})
export class SearchSvgPathPipe implements PipeTransform {

  fallbackImage = { adventure, hotel, foodAndDrinks };

  constructor(
    private store: Store
  ) { }

  transform(type: 'adventure' | 'hotel' | 'foodAndDrinks'): Observable<string> {
    
    function svgToSrcUrl(svgText: string): Promise<string> {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(svgBlob);
    
        img.onload = function() {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
    
          const ctx = canvas.getContext('2d');
          ctx!.drawImage(img, 0, 0);
    
          const webpDataUrl = canvas.toDataURL('image/webp');
    
          resolve(webpDataUrl);
    
          URL.revokeObjectURL(url);
        };
        
        img.onerror = (error) => {
          reject(error);
        };
    
        img.src = url;
      });
    };
    
    const primaryColor = this.store.selectSnapshot(BrandState.campaignCustom)?.colorPalette?.mainColor || '#00a6ff'
    const svg = this.fallbackImage[type](primaryColor);

    return from(svgToSrcUrl(svg));
  }

}
