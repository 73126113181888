import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { TripFilterType } from '../../experience-finder.model';
import { LangChangeEvent, TranslationService, currentLang } from '@hiptraveler/common';

type Experiences = Partial<Experience>[];

@Pipe({
  name: 'filterCategory'
})
export class FilterCategoryPipe implements PipeTransform {

  value$$ = new BehaviorSubject<Experiences>([]);

  constructor(
    private store: Store,
    private translation: TranslationService
  ) { }

  transform(type: TripFilterType): Observable<Experiences> {
    let experiences = this.store.selectSnapshot(BrandState.state)?.experiences || [];

    experiences = experiences.filter(e => e.category === type);
    this.value$$.next(this.getMappedExperiences(experiences));
    
    this.translation.onLangChange$.subscribe((value: LangChangeEvent) => {
      this.value$$.next(this.getMappedExperiences(experiences, value.lang));
    });

    return this.value$$.asObservable()
  }

  getMappedExperiences(experiences: Experiences, lang: string = currentLang()): Experiences {
    return experiences.map((experience: Partial<Experience>) => {
      const translation = experience?.langMap?.[lang];
      if (experience?.name && translation) {
        experience.name = translation.name;
      }
      return experience;
    })
  }

}
