import { StateContext } from "@ngxs/store";

import { SearchStateModel, UpdateSearchResultLikePayload } from "../search.model";

export function addUserLike(data: any[], id: string, userId: string, count: number): any[] {
  data = data || [];
  const searchResult = data.find(obj => obj.id === id);
  console.log('@@@ ', searchResult.id, searchResult.name);
  if ([ 'poi', 'itinerary', 'blog' ].includes(searchResult?.type)) {
    searchResult.imgLikes = count;
    searchResult.userLikes = searchResult?.userLikes || [];
    searchResult.userLikes.push(userId);
  }
  if (searchResult?.type === 'hotel') {
    searchResult.likeCnt = count;
    searchResult.likes = searchResult?.likes || [];
    searchResult.likes.push(userId);
  }
  return data;
};

export function removeUserLike(data: any[], id: string, userId: string, count: number): any[] {
  data = data || [];
  const searchResult = data.find(obj => obj.id === id);
  console.log('@@@ ', searchResult.id, searchResult.name);
  if ([ 'poi', 'itinerary', 'blog' ].includes(searchResult?.type)) {
    searchResult.imgLikes = count;
    searchResult.userLikes = searchResult?.userLikes || [];
    searchResult.userLikes = searchResult.userLikes.filter((user: string) => user.slice(0, 7) !== userId.slice(0, 7)); 
  }
  if (searchResult?.type === 'hotel') {
    searchResult.likeCnt = count;
    searchResult.likes = searchResult?.likes || [];
    searchResult.likes = searchResult.likes.filter((user: string) => user.slice(0, 7) !== userId.slice(0, 7)); 
  }
  return data;
};

export function modifyResultFavorites(
  ctx: StateContext<SearchStateModel>,
  { id, results }: UpdateSearchResultLikePayload,
  userId: string
): any {

  results = results.map((result: string) => {
    return userId.includes(result) ? userId : result;
  });

  const adventure = ctx.getState()?.adventures?.find(e => e.id === id);
  const food = ctx.getState()?.foods?.find(e => e.id === id);
  const hotel = ctx.getState()?.hotels?.find(e => e.id === id);

  const field = adventure ? 'adventures' : food ? 'foods' : hotel ? 'hotels' : 'adventures';

  const data: any[] = [ ...ctx.getState()[field]! ];
  const favorites = data.find(e => e.id === id);

  if ((adventure || food) && favorites?.userLikes) {
    favorites.userLikes = results;
  }
    
  if (hotel && favorites?.likes) {
    favorites.likes = results;
  }

  ctx.patchState({ [field]: [] });
  setTimeout(() => ctx.patchState({ [field]: data }));
}
