import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TermsAndPrivacyPolicyModule as TermsAndPrivacyPolicyModuleUI } from '@hiptraveler/ui/terms-and-privacy-policy';

import { TermsAndPrivacyPolicyComponent } from './terms-and-privacy-policy.component';
import { ContactViewComponent } from './contact-view/contact-view.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  TermsAndPrivacyPolicyModuleUI
];

const COMPONENTS = [
  TermsAndPrivacyPolicyComponent,
  ContactViewComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: TermsAndPrivacyPolicyComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ],
})
export class TermsAndPrivacyPolicyModule { }
