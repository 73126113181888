<div  class="overlay-travel-experience---navigation mat-elevation-z2" matRipple (click)="navigateToLeft()">
  <mat-icon>chevron_left</mat-icon>
</div>
<div [id]="selectedContainerId" class="overlay-travel-experience--item-selection">

  <div class="item-selection--view-container" *ngIf="selectedFilter | selection | async as selections" [style]="'width: '+((mobile === '' ? 160 : 132)*selections.length)+'px;'">
    <div id="item-selection-view-start"></div>
    <ng-container *ngFor="let item of selections;">
      <div class="item-selection--view" [ngClass]="{ active: item.code! | includes | async, mobile: mobile === '' }" [ngStyle]="{ 'background-image': item.img | image, 'background-size': item.code === 'group_activities' ? '265%' : '130%' }" (click)="addValueToSelection(item.code!)">
        <div class="item-selection--view-selection-indicator">
          <mat-icon>done</mat-icon>
        </div>
        <div class="item-selection--view-text-wrapper">
          <p class="item-selection--view-text">{{ item.tname ? (item.tname | translate) : item.name }}</p>
        </div>
      </div>
    </ng-container>
    <div id="item-selection-view-end"></div>
  </div>
  
</div>
<div class="overlay-travel-experience---navigation mat-elevation-z2" matRipple (click)="navigateToRight()">
  <mat-icon>chevron_right</mat-icon>
</div>
