import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { SearchAction } from '@hiptraveler/data-access/search';
import { LOCATION, PlaceResult, SearchLocationService, getDecodedValue, queryStringToObject, subdomain } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';

@Injectable()
export class DataAccessService {

  constructor(
    @Inject(LOCATION) private location: any,
    private store: Store,
    private searchLocation: SearchLocationService,
  ) { }

  private get placeResult(): PlaceResult | null {
    const result = this.store.selectSnapshot(BrandState.locations)?.[0];
    return result ? {
      name: result.country,
      formatted_address: result.formattedAddr,
      place_id: result?.id || result?.countryId || result?.gPlaceId || 'undefined',
      geometry: {
        location: { lat: () => +result.latitude, lng: () => +result.longitude } as any
      }
    } : null;
  }

  private async setDefaultLocationForBrandRequest(): Promise<void> {
    if (subdomain() !== 'hiptraveler' && this.placeResult) {
      this.searchLocation.emitPlaceResultDataToState(this.placeResult);
      await new Promise(resolve => setTimeout(resolve, 50));
    }
  }

  async adventures(form: any): Promise<void> {
    try {
      form.location || await this.setDefaultLocationForBrandRequest();
      const query = queryStringToObject(this.location.search) as any;

      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'adventures', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetAdventuresByQuery({
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1',
        exp: decodeURIComponent(query.experiences),
        travelStyle: decodeURIComponent(query.travelStyle),
        activities: decodeURIComponent(query.activities)
      })));
    } catch (error) { }
  }
  
  async hotels(form: any): Promise<void> {
    try {
      form.location || await this.setDefaultLocationForBrandRequest();
      const query = queryStringToObject(this.location.search) as any;

      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'hotels', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetHotelsByQuery({
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1', 
        exp: decodeURIComponent(query.experiences),
        travelStyle: getDecodedValue(query?.travelStyle),
        amenities: getDecodedValue(query?.amenities)
      })));
    } catch (error) { }
  }
  
  async foods(form: any): Promise<void> {
    try {
      form.location || await this.setDefaultLocationForBrandRequest();
      const query = queryStringToObject(this.location.search) as any;

      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'foods', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetFoodsByQuery({
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1', 
        exp: decodeURIComponent(query.experiences),
        travelStyle: getDecodedValue(query?.travelStyle),
        amenities: getDecodedValue(query?.cuisine)
      })));
    } catch (error) { }
  }
  
  async itineraries(): Promise<void> {
    try {
      const query = queryStringToObject(this.location.search) as any;

      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'itineraries', 'pagination' ])));
      await firstValueFrom(this.store.dispatch(new SearchAction.GetItinerariesByQuery({
        q: 'most-hip',
        locId: this.searchLocation.data?.locId || '',
        location: this.searchLocation.data?.location || '',
        searchCnt: '1', 
        exp: decodeURIComponent(query.experiences),
        travelStyle: getDecodedValue(query?.travelStyle),
        activities: getDecodedValue(query?.activities)
      })));
    } catch (error) { }
  }

  forYouPage(form: any): void {
    this.itineraries();
    this.adventures(form);
    this.hotels(form);
    this.foods(form);
  }

}
