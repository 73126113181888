
<ng-container *ngIf="!(service.pending$ | async)">
  <dialog-close-button [@opacityFade]></dialog-close-button>
</ng-container>

<h1 translate="profile.where-have-been"></h1>

<mat-form-field customMapAutocomplete emitOnly position="absolute-relative" [cmaTopOffset]="16.5" (locationChanged)="service.addPlaceVisited($event)">
  <mat-label>{{ 'profile.add-places-map' | translate }}</mat-label>
  <input matInput type="text" placeholder="Add places you've been to map" autocomplete="off" name="street-address" [disabled]="service.pending$ | async" #streetAddress>
  <ng-container *ngIf="service.pending$ | async">
    <mat-progress-spinner class="primary" mode="indeterminate" strokeWidth="4" diameter="28" [@opacityFade]></mat-progress-spinner>
  </ng-container>
</mat-form-field>

<div class="places-list--container">

  <div class="places-list--item" *ngFor="let item of service.placesVisited$ | async;let parent = index">
    
    <ng-container *ngIf="item.data.length">
      <p class="places-list--item-label">{{ item.place }}:</p>
  
      <div class="places-list--item-sublabel" *ngFor="let place of item.data;let child = index">
        <p>{{ place.location }}</p>
        <ng-container *ngIf="!(service.pending$ | async)">
          <div class="places-list--item-action" matRipple (click)="service.removePlaceVisited(item.place, child)" [@opacityFade]>
            <mat-icon>close</mat-icon>
          </div>
        </ng-container>
      </div>
    </ng-container>

  </div>

</div>
