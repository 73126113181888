import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EmailShareDialogComponent } from './email-share-dialog.component';
import { EmailShareDialogData } from '.';

export const EMAIL_SHARE_DIALOG_ID = 'email-share-dialog-overlay-wrapper-id';

@Injectable()
export class EmailShareDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(data: EmailShareDialogData): MatDialogRef<EmailShareDialogComponent> | void {
    const dialog = this.dialog.getDialogById(EMAIL_SHARE_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open<EmailShareDialogComponent, EmailShareDialogData>(EmailShareDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: EMAIL_SHARE_DIALOG_ID,
      panelClass: EMAIL_SHARE_DIALOG_ID.replace('-id', ''),
      // backdropClass: EMAIL_SHARE_DIALOG_ID.replace('-id', '-backdrop'),
      width: '600px',
      maxWidth: 'auto',
      minWidth: 0,
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data
    });
  }

}
