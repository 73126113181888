<p class="search-host--title" translate="search-page.connect"></p>
<p class="search-host--subtitle" translate="search-page.join-conversation"></p>

<br>

<ng-container *ngIf="visibility;else skeleton">
  <connect [@opacityFadeIn]></connect>
</ng-container>
<ng-template #skeleton>
  <ngx-skeleton-loader [@opacityFadeIn] count="2"></ngx-skeleton-loader>
</ng-template>
