import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { FeatureCardStateService } from './feature-card-state.service';
import { UnitTranslationPipe } from './translations';

@Pipe({ name: 'validateStatus' })
export class ValidateStatusPipe implements PipeTransform {
  transform(id: string, activities: ActivityDateData[] | null): boolean {
    if (!activities) return false;
    const imgArray: any = activities.map(e => e?.['ImgArray']) || [];
    const hotelArray: any = activities.map(e => e?.['HotelArray']) || [];
    const flattened = [].concat(...hotelArray).concat(...imgArray) || [];
    return !!flattened.find((e: any) => e?.id === id);
  }
}

@Pipe({ name: 'displayLoading' })
export class DisplayLoadingPipe implements PipeTransform {
  constructor(private stateService: FeatureCardStateService) { }
  transform(id: string): Observable<boolean> {
    return this.stateService.cardProcessingState$.pipe(
      map((ids: string[]) => ids.includes(id))
    );
  }
}

@Pipe({ name: 'searchResultLocation' })
export class SearchResultLocationPipe implements PipeTransform {
  transform(searchResult?: any): string {
    const address = searchResult?.address;
    const addressLocation = [ address?.city, address?.state, address?.country ].filter(Boolean).join(', ');
    return searchResult?.location || addressLocation || address?.poi || '';
  }
}

@Pipe({ name: 'durationString' })
export class DurationStringPipe implements PipeTransform {
  transform(seconds?: number): string {
    const HOURS_IN_A_DAY = 24;
    const SECONDS_IN_A_DAY = 3600 * HOURS_IN_A_DAY;
    const remainingSeconds = seconds || 0;
    const days = Math.floor(remainingSeconds / SECONDS_IN_A_DAY);
    const hours = Math.floor((remainingSeconds % SECONDS_IN_A_DAY) / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);

    const result = [];
    if (days) result.push(`${days} day${days > 1 ? 's' : ''}`);
    if (hours) result.push(`${hours} hr${hours > 1 ? 's' : ''}`);
    if (minutes) result.push(`${minutes} min${minutes > 1 ? 's' : ''}`);

    const durationLabel = result.join(' ');
    const standalone = durationLabel.split(' ').length === 2;
    return standalone ? durationLabel.replace('hr', 'hour').replace('min', 'minute') : durationLabel;
  }
}

export const FeatureCardExportPipes = [
  ValidateStatusPipe,
  SearchResultLocationPipe
];

export const FeatureCardPipes = [
  FeatureCardExportPipes,
  DisplayLoadingPipe,
  UnitTranslationPipe,
  DurationStringPipe
];
