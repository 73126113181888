import { Component, Self, ViewEncapsulation } from '@angular/core';

import { DefaultUiService } from '@hiptraveler/common';

@Component({
  selector: 'app-travel',
  template: `
    <div class="travel">
      <skyscanner-view></skyscanner-view>
    </div>
  `,
  styleUrls: ['./travel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ DefaultUiService ]
})
export class TravelComponent {

  constructor(
    @Self() private defaultUI: DefaultUiService
  ) { }

}
