import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Event, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { takeUntil, tap, delay, filter, map, switchMap } from 'rxjs';

import { LocationData } from '@hiptraveler/data-access/api';
import { LeafletMap, LeafletMapControlStateService, toGeoJSON } from '@hiptraveler/features/leaflet-map';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { AppListenerService, SearchLocationService, SearchResultData } from '@hiptraveler/common';
import { SearchState } from '@hiptraveler/data-access/search';
import { BrandState } from '@hiptraveler/data-access/brand';

@Directive({
  selector: '[leafletMap]'
})
export class SearchPageMapDirective extends LeafletMap implements OnInit {

  pendingMarker: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private appListener: AppListenerService,
    private searchLocation: SearchLocationService,
    private store: Store,
    private searchResultDialog: SearchResultDialogActionService,
    router: Router,
    elementRef: ElementRef<HTMLElement>,
    leafletControl: LeafletMapControlStateService
  ) {
    super(router, elementRef, leafletControl);
  }

  ngOnInit(): void {

    if (isPlatformServer(this.platformId)) return;

    this.appListener.mapVisibilityState$.pipe(takeUntil(this.subscription$)).subscribe(() => {
      this.setupMap();
    });
  }

  setupMap(): void {

    this.setMap();

    setTimeout(() => this.observePopupPane(this.element), 1000);

    this.store.select(SearchState.locationData).pipe( /* Search result location changes observer */
      switchMap((locationData: LocationData | null) => {
        return this.store.select(BrandState.defaultCountry).pipe(
          tap((country: LocationData | null) => {
            this.clearAllLayers();
            this.setMapView(
              [ locationData?.latitude || country?.position?.[1] || 0,
                locationData?.longitude || country?.position?.[0] || 0 ],
              locationData ? 9 : country?.position?.length ? 7 : 2
            );
          })
        )
      })
    ).subscribe();

    this.searchLocation.searchLocationState$.pipe( /* Reset state UX observer */
      filter(e => !e),
      tap(() => this.setMapView([ 0, 0 ], 2)),
      takeUntil(this.subscription$)
    ).subscribe();

    this.leafletControl.allSearchResultsData$.pipe( /* Search results marker setup observer */
      delay(350),
      tap((results: SearchResultData[]) => {
        let icon;
        if (results[0].type === 'adventure') {
          icon = this.markerIcon.adventureMarkerIcon;
        }
        if (results[0].type === 'food') {
          icon = this.markerIcon.foodMarkerIcon;
        }
        if (results[0].type === 'hotel') {
          icon = this.markerIcon.hotelMarkerIcon;
        }
        if (results[0].type === 'profile') {
          icon = this.markerIcon.profileMarkerIcon;
        }
        icon && this.setGeoJSON(toGeoJSON(results), icon);
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.leafletControl.activeSearchResultData$.pipe( /* Search result card hover observer */
      tap((data: SearchResultData) => {

        if (this.pendingMarker || ![...data.location.coordinates!].filter(Boolean).length) return;

        this.pendingMarker = true;
        const location: any = data?.location?.coordinates ?? [];
        this.setMapView(location);

        setTimeout(() => {
          this.pendingMarker = false;
          this.setMarker({ location, data, icon: this.searchPageMarkerIcon.highlight, popup: true });
        }, 500);
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.selectedMarker$.pipe(
      map((id: string) => this.searchResultByStoreAndId(this.store, id)),
      tap(({ result, type }) => this.searchResultDialog.open(result, type))
    ).subscribe();

    this.router.events.pipe(
      filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
      tap(this.clearAllLayers.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
