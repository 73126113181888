import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SocialUser } from '@abacritt/angularx-social-login';
import { firstValueFrom } from 'rxjs';

import { AuthenticateSocialMediaDto } from '@hiptraveler/data-access/api';
import { AuthAction, SocialMedia, SocialMediaAuthService } from '@hiptraveler/data-access/auth';

@Injectable()
export class SocialMediaService {

  constructor(
    private store: Store,
    private socialMedia: SocialMediaAuthService,
  ) { }

  /**
   * Signs in a user using social media authentication.
   *
   * @param {SocialMedia} option - The social media platform to sign in with ('facebook' or 'google').
   * @returns {Promise<void>}
   */
  async signInWithSocialMedia(option: SocialMedia): Promise<void> {
    try {
      await this.dispatchByOption(option);
    } finally { }
  }

  /**
   * Dispatches authentication based on the specified social media option.
   *
   * @param {SocialMedia} option - The social media platform to authenticate with ('facebook' or 'google').
   * @returns {Promise<void>}
   */
  private async dispatchByOption(option: SocialMedia): Promise<void> {
    switch (option) {
      case 'facebook': {
        const response = await this.socialMedia.authenticateWithPopUp<fb.AuthResponse>(option);
        return this.dispatchAuth<AuthenticateSocialMediaDto>(AuthAction.AuthenticateWithFacebook, {
          authToken: response.accessToken || ''
        });
      }
      case 'google': {
        const response = await this.socialMedia.authenticateWithPopUp<SocialUser>(option);
        return this.dispatchAuth<AuthenticateSocialMediaDto>(AuthAction.AuthenticateWithGoogle, {
          authToken: response.idToken || ''
        });
      }
    }
  }

  /**
   * Dispatches authentication action with the specified payload.
   *
   * @template T - The type of payload.
   * @param {any} Action - The authentication action to dispatch.
   * @param {T} payload - The payload for the authentication action.
   * @returns {Promise<void>}
   */
  private async dispatchAuth<T>(Action: any, payload: T): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new Action(payload)));
    } finally { }
  }

}
