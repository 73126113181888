<div class="reviews ht-dialog-elevation">
      
  <ng-container *ngIf="service.hotelInformation$ | async as data;else noReviewsInfo">
    <h2 translate="search-result.reviews-title"></h2>
    <div class="reviews--highlight" [@opacityFade]>
      <div class="reviews--highlight-rating">
        <p><span class="ht-digit">{{ data?.guestRating }}</span></p>
      </div>
      <div class="reviews--highlight-information">
        <p translate="search-result.outstanding"></p>
        <p>{{ 'search-result.based-on' | translate }} <span class="ht-digit">{{ data?.numReviews }}</span> {{ 'search-result.reviews' | translate }}</p>
      </div>
    </div>

    <ng-container *ngIf="(service.hotelInformation$ | async)?.ssReviews as reviews">
      <div class="reviews--progress" (click)="progressSeeMore = !progressSeeMore">
        <div class="reviews--progress-value" *ngFor="let category of reviews?.categories?.slice(0, 4);">
          <p>{{ category?.name }}</p>
          <mat-progress-bar [value]="(category?.score || 0)*10"></mat-progress-bar>
        </div>
        <ng-container *ngIf="progressSeeMore && reviews.categories.slice(4).length === 2">
          <div class="reviews--progress-value" *ngFor="let category of reviews?.categories?.slice(4);" [@inOutCollapse36]>
            <p>{{ category?.name }}</p>
            <mat-progress-bar [value]="(category?.score || 0)*10"></mat-progress-bar>
          </div>
        </ng-container>
        <ng-container *ngIf="progressSeeMore && reviews.categories.length === 4">
          <div class="reviews--progress-value" *ngFor="let category of reviews?.categories?.slice(4);" [@inOutCollapse96]>
            <p>{{ category?.name }}</p>
            <mat-progress-bar [value]="(category?.score || 0)*10"></mat-progress-bar>
          </div>
        </ng-container>
      </div>
  
      <ui-action-button theme="outlined-white" (click)="progressSeeMore = !progressSeeMore" [@opacityFade]>
        {{ (progressSeeMore ? 'search-result.see-less' : 'search-result.see-more') | translate }}
      </ui-action-button>
    </ng-container>

  </ng-container>
  <ng-template #noReviewsInfo>
    <h2 translate="search-result.reviews-title"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>

</div>
