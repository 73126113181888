import { Component, ElementRef, AfterViewInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ProfileFormService } from './profile-form.service';
import { initializeGoogleMapsPlacesAutocomplete } from '@hiptraveler/common';
import { ProfileStateFormService } from '../../../common';

@Component({
  selector: 'profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss', './profile-form-responsiveness.scss'],
  viewProviders: [ ProfileFormService ]
})
export class ProfileFormComponent implements AfterViewInit {

  @ViewChild('socMedInput') socMedInput: ElementRef<HTMLInputElement>;
  @ViewChild('homeLocation') homeLocation: ElementRef<HTMLInputElement>;
  @ViewChild('currentLocation') currentLocation: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public service: ProfileFormService,
    public stateFormService: ProfileStateFormService
  ) { }

  ngAfterViewInit(): void {
    this.service.updateSocialMedia('twitter', this.socMedInput.nativeElement)
    this.service.setInitialProfileFormData();
    isPlatformBrowser(this.platformId) && initializeGoogleMapsPlacesAutocomplete(this.homeLocation, { default: true }).subscribe(
      () => this.stateFormService.profileForm.patchValue({ homeTown: this.homeLocation.nativeElement.value })
    );
    isPlatformBrowser(this.platformId) && initializeGoogleMapsPlacesAutocomplete(this.currentLocation, { default: true }).subscribe(
      () => this.stateFormService.profileForm.patchValue({ currLoc: this.currentLocation.nativeElement.value })
    );
  }

}
