import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AboutSectionModule } from './about-section/about-section.module';
import { SocialHandlesSectionModule } from './social-handles-section/social-handles-section.module';

import { AboutTabComponent } from './about-tab.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  AboutSectionModule,
  SocialHandlesSectionModule
];

const COMPONENTS = [
  AboutTabComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    AboutTabComponent
  ]
})
export class AboutTabModule { }
