import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ItineraryFeatureCardModule } from '@hiptraveler/ui/itinerary-feature-card';
import { ItineraryActionModule } from '@hiptraveler/features/itinerary-action';
import { DurationPipeModule } from './duration.pipe';
import { DriveDurationModule } from '../drive-duration/drive-duration.module';

import { HotelCardComponent } from './hotel-card.component';
import { ConsumeCardComponent } from './consume-card.component';
import { AmusementCardComponent } from './amusement-card.component';
import { ImageDataButtonNamePipe, HotelDataButtonNamePipe } from './button-name.pipe';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ItineraryFeatureCardModule,
  ItineraryActionModule,
  DriveDurationModule,
  DurationPipeModule
];

const COMPONENTS = [
  HotelCardComponent,
  ConsumeCardComponent,
  AmusementCardComponent
];

const PIPES = [
  ImageDataButtonNamePipe,
  HotelDataButtonNamePipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    HotelCardComponent,
    ConsumeCardComponent,
    AmusementCardComponent
  ]
})
export class FeatureCardsModule { }
