import { Component, Inject, OnDestroy, Provider, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipInputEvent, MatChipsDefaultOptions } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { catchError, tap } from 'rxjs';

import { SocialMediaShareHandleService } from '@hiptraveler/features/social-media-share-handle';
import { RequestCancellationService } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { HipTravelerRequestResponse } from '@hiptraveler/data-access/api';
import { RegexPattern } from '@hiptraveler/common';
import { EmailShareDialogData } from '.';

const ChipOptionProvider: Provider = {
  provide: MAT_CHIPS_DEFAULT_OPTIONS,
  useValue: { separatorKeyCodes: [ 13, 9 ] } as MatChipsDefaultOptions
};

@Component({
  selector: 'app-email-share-dialog',
  templateUrl: './email-share-dialog.component.html',
  styleUrls: ['./email-share-dialog.component.scss'],
  viewProviders: [ SocialMediaShareHandleService, ChipOptionProvider ],
  encapsulation: ViewEncapsulation.None
})
export class EmailShareDialogComponent implements OnDestroy {

  messageControl = new FormControl(['']);
  emailIds = new Set<string>([]);
  // messageControl = new FormControl(['Hello world']);
  // emailIds = new Set<string>(['jam@hiptraveler.com','jjgermitano@gmail.com']);
  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmailShareDialogData,
    private dialogRef: MatDialogRef<EmailShareDialogComponent>,
    public service: SocialMediaShareHandleService,
    private requestCancellation: RequestCancellationService,
    private socialMediaShareHandle: SocialMediaShareHandleService,
    private snackbar: SnackbarService
  ) { }
 
  ngOnDestroy(): void {
    this.requestCancellation.cancelAllRequests();
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (!event.value) return;
    if (!RegexPattern.email.test(event.value)) {
      this.snackbar.open({ message: 'Invalid e-mail address.', duration: 5000 });
    }
    if (event.value && RegexPattern.email.test(event.value)) {
      this.emailIds.add(event.value);
    }
    event.chipInput!.clear();
  }

  removeKeyword(keyword: string) {
    this.emailIds.delete(keyword);
  }

  sendEmail(): void {
    const emailIds = Array.from(this.emailIds).map(e => e.toLocaleUpperCase()).join(',');
    if (!emailIds.length || !this.messageControl.value || this.processing) return;
    this.processing = true;
    this.snackbar.open({ message: 'Please wait...', duration: Infinity });
    this.socialMediaShareHandle.emailShare({
      emailIds,
      message: this.messageControl.value,
      title: this.data.blogTitle,
      url: this.data.shareUrl
    }).pipe(
      tap((response: HipTravelerRequestResponse) => {
        this.processing = false;
        this.snackbar.open({ message: response.success ? 'Message sent successfully!' : response.message! });
        this.dialogRef.close();
      }),
      catchError((response: HttpErrorResponse) => {
        this.processing = false;
        this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
        this.messageControl.reset();
        this.emailIds.clear()
        throw response
      })
    ).subscribe();
  }

}
