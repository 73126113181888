import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { TourReviewsModule } from './tour-reviews/tour-reviews.module';
import { YelpReviewsModule } from './yelp-reviews/yelp-reviews.module';
import { TourInformationModule } from './tour-information/tour-information.module';
import { TripAdvisorModule } from '../../components';
import { ButtonVisibilityPipeModule } from '../../common';

import { ActivityDetailsComponent } from './activity-details.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  TourReviewsModule,
  YelpReviewsModule,
  TourInformationModule,
  TripAdvisorModule,
  ButtonVisibilityPipeModule
];

const COMPONENTS = [
  ActivityDetailsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityDetailsComponent
  ]
})
export class ActivityDetailsModule { }
