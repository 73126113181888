import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivationEnd, Event, NavigationStart, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';

import { AUTH_STATE_TOKEN } from '@hiptraveler/data-access/auth';
import { BrandAction } from '@hiptraveler/data-access/brand';
import { USER_STATE_TOKEN } from '@hiptraveler/data-access/user';
import { PROFILE_STATE_TOKEN } from '@hiptraveler/data-access/profile';
import { ITINERARY_STATE_TOKEN } from '@hiptraveler/data-access/itinerary';
import { SEARCH_STATE_TOKEN } from '@hiptraveler/data-access/search';
import { AppListenerService, appVersion, Environment, ENVIRONMENT, getWindowRef } from '@hiptraveler/common';

@Injectable()
export class RequiredStateService {

  #previousUrl: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ENVIRONMENT) private environment: Environment,
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService
  ) { }

  setup(): void {

    if (isPlatformServer(this.platformId)) return;

    this.setVersionNumberToTemplate();
    this.dispatchRequiredStateAction();
    this.observePathChanges();
    this.removeStateBrowserStorage();
  }

  setVersionNumberToTemplate(): void {
    const appRoot = this.element.nativeElement.parentElement;
    appRoot?.setAttribute('app-version', appVersion);
  }

  private removeStateBrowserStorage(): void {
    if (this.environment.local) return
    localStorage.removeItem(AUTH_STATE_TOKEN.getName())
    localStorage.removeItem(USER_STATE_TOKEN.getName())
    localStorage.removeItem(PROFILE_STATE_TOKEN.getName())
    localStorage.removeItem(ITINERARY_STATE_TOKEN.getName())
    localStorage.removeItem(SEARCH_STATE_TOKEN.getName())
  }
  
  private dispatchRequiredStateAction(): void {
    if (getWindowRef()?.location?.pathname?.includes('/addtoFavorites/experience-finder/')) return;
    setTimeout(() => this.store.dispatch(new BrandAction.GetBrandInfo));
  }

  private observePathChanges(): void {

    this.router.events.pipe(
      filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
      map((event: NavigationStart) => event.url),
      distinctUntilChanged(),
      tap(() => this.#previousUrl && this.appListener.previousUrl$$.next(this.#previousUrl)),
    ).subscribe();

    this.router.events.pipe(
      filter((event: Event): event is ActivationEnd => event instanceof ActivationEnd),
      filter(() => this.#previousUrl !== getWindowRef()?.location?.pathname),
      tap(() => (this.#previousUrl = getWindowRef()?.location?.pathname)),
    ).subscribe();

    this.router.events.pipe(
      // filter((event: Event): event is ActivationEnd => event instanceof ActivationEnd),
      tap((response: any) => {
        // console.log('@@@ router-events', response?.snapshot?.['_routerState'], response);
      })
    ).subscribe();
  }

}
