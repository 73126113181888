import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hours-prices',
  templateUrl: './hours-prices.component.html',
  styleUrls: ['./hours-prices.component.scss']
})
export class HoursPricesComponent {

  selectedDuration: string = 'custom-activity-dialog.section.form.enter-minutes';

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup
  ) { }

  get gygTourForm(): FormGroup {
    return this.form.get('gygTour') as FormGroup;
  }

  get priceForm(): FormGroup {
    return this.form.get('gygTour')?.get('price') as FormGroup;
  }

  get durationForm(): FormGroup {
    return this.form.get('gygTour')?.get('duration') as FormGroup;
  }

  changeDuration(duration: string): unknown {
    this.durationForm.patchValue({ unit: duration });
    switch (duration) {
      case 'minute': {
        return (this.selectedDuration = 'custom-activity-dialog.section.form.enter-minutes');
      }
      case 'hour': {
        return (this.selectedDuration = 'custom-activity-dialog.section.form.enter-hours');
      }
      case 'day': {
        return (this.selectedDuration = 'custom-activity-dialog.section.form.enter-days');
      }
      default: return;
    }
  }

  changeSelected(selected: string): void {
    setTimeout(() => (this.selectedDuration = selected), 500);
  }

}
