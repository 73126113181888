import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { PoiSelectCarouselComponent } from './poi-select-carousel.component';
import { ImagePipe } from './image.pipe';
import { IncludesPipe } from './includes.pipe';
import { SelectionPipe } from './selection.pipe';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  PoiSelectCarouselComponent
];

const PIPES = [
  ImagePipe,
  IncludesPipe,
  SelectionPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    PoiSelectCarouselComponent
  ]
})
export class PoiSelectCarouselModule { }
