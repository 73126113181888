import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { DialogType } from '../common';
import { BAHAMAS_BRAND_NAME, subdomain } from '@hiptraveler/common';

@Component({
  selector: 'result-content',
  template: `
    <ng-container [ngSwitch]="dialogType">
      <ng-container *ngSwitchCase="'adventure'">

        <!-- <highlight-carousel [data]="image" [dialogType]="dialogType" alignSize></highlight-carousel> -->
        <highlight-carousel [data]="image" [dialogType]="dialogType" [addToTripSignal]="addToTripSignal" alignSize [ltsSupport]="true" class="ht-dialog-elevation"></highlight-carousel>
        
        <br *ngIf="!(imageInformation$ | async)?.photos?.length">

        <div class="result-content-details-component ht-dialog-elevation">
          <result-details [image]="image!" [hotel]="hotel!" [dialogType]="dialogType" scrollElementBorder></result-details>
        </div>

        <about [data]="image"></about>
        <information [data]="image"></information>
        <meeting-point></meeting-point>
        <related-tours></related-tours>
  
      </ng-container>
      <ng-container *ngSwitchCase="'hotel'">

        <highlight-banner [data]="hotel" [dialogType]="dialogType" alignSize></highlight-banner>

        <div class="result-content-details-component">
          <image-carousel hotel></image-carousel>
        </div>

        <about-hotel [hotel]="(hotelInformation$ | async) || hotel"></about-hotel>
        <contact-information [hotelInformation$]="hotelInformation$"></contact-information>
        <reviews [data]="hotel" *ngIf="!bahamas"></reviews>

        <div class="result-content-details-component ht-dialog-elevation">
          <result-details [image]="image!" [hotel]="hotel!" [dialogType]="dialogType" noHighlight scrollElementBorder></result-details>
        </div>
        
      </ng-container>
      <ng-container *ngSwitchCase="'food'">
        
        <!-- <highlight-carousel [data]="image" alignSize></highlight-carousel> -->
        <highlight-carousel [data]="image" [dialogType]="dialogType" [addToTripSignal]="addToTripSignal" alignSize [ltsSupport]="true" class="mat-elevation-z2"></highlight-carousel>

        <br *ngIf="!(imageInformation$ | async)?.photos?.length">

        <div class="result-content-details-component ht-dialog-elevation">
          <result-details [image]="image!" [hotel]="hotel!" [dialogType]="dialogType" scrollElementBorder></result-details>
        </div>

        <about-food [image]="(imageInformation$ | async) || image"></about-food>
        <contact-information [imageInformation$]="imageInformation$"></contact-information>
        <hour-details></hour-details>

      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./result-content.component.scss'],
  host: { class: 'result-content' },
  encapsulation: ViewEncapsulation.None
})
export class ResultContentComponent implements OnInit {

  @Select(ItineraryState.hotelInformation) hotelInformation$: Observable<HotelResultData | null>;
  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData | null>;

  @Input() image?: ImageResultData;
  @Input() hotel?: HotelResultData;
  @Input() dialogType: DialogType;
  @Input() addToTripSignal: Subject<boolean>;

  bahamas: boolean = false;

  ngOnInit(): void {
    this.bahamas = subdomain() === BAHAMAS_BRAND_NAME;
  }

}
