import { HipTravelerRequestResponse } from "@hiptraveler/data-access/api";
import { SnackbarService } from "@hiptraveler/snackbar";

export const RESET_PASSWORD_KEY = 'reset_password';

export function handleResetPasswordSessionData(response: HipTravelerRequestResponse, key: string, value: any, snackbar: SnackbarService): void {
  const success = response?.data?.success;
  const data = JSON.parse(sessionStorage.getItem(RESET_PASSWORD_KEY) || "{}") || {};
  sessionStorage.setItem(RESET_PASSWORD_KEY, JSON.stringify({ ...data, [key]: !!success ? value : null }));
  success && snackbar.open({ message: success });
}
