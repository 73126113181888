import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { filter, takeUntil, tap } from 'rxjs';

import { ItineraryApiService } from '@hiptraveler/data-access/api';
import { ItineraryAction } from '@hiptraveler/data-access/itinerary';
import { AppListenerService } from '@hiptraveler/common';
import { handlePageMetadata, handlePageMetadata_server } from './itinerary-and-blog-page';
import * as Feature from '@hiptraveler/features/meta-tag';

@Injectable()
export class ItineraryAndBlogPageService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private action$: Actions,
    private appListener: AppListenerService,
    private api: ItineraryApiService,
    private metaTagService: Feature.MetaTagService
  ) { }

  observe(): void {
    this.setPageMetaData();
    this.observeRouteChanges();
    this.observeResetStateActions();
  }

  setPageMetaData(): void {
    if (isPlatformBrowser(this.platformId)) {
      handlePageMetadata(this.store, data => this.metaTagService.updateMetaData(data));
    }
    if (isPlatformServer(this.platformId) && this.appListener.serverRequest) {
      handlePageMetadata_server(this.api, data => this.metaTagService.updateMetaData(data));
    }
  }

  private observeRouteChanges(): void {
    this.metaTagService.navigationEnd$.subscribe(this.setPageMetaData.bind(this));
  }

  private observeResetStateActions(): void {
    this.action$.pipe(
      ofActionDispatched(ItineraryAction.PartialResetItineraryState),
      filter(e => e.fields.includes('basicInfo')),
      tap(() => this.metaTagService.updateMetaData(Feature.defaultMetaTagData)),
      takeUntil(this.metaTagService.subscription$)
    ).subscribe();
  }

}
