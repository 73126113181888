import { ComponentRef, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { Subject, debounceTime, distinctUntilChanged, filter, fromEvent, map, takeUntil } from 'rxjs';

import { CustomMapAutocompleteComponent } from './custom-map-autocomplete.component';
import { AppListenerService, isWidget, randomId, SearchLocationData } from '@hiptraveler/common';

@Directive({
  selector: '[customMapAutocomplete]'
})
export class CustomMapAutocompleteDirective implements OnInit, OnDestroy {
  
  @Output() locationChanged = new EventEmitter<SearchLocationData>();

  @Input() cmaIndex: number = 0;
  @Input() cmaZIndex: number = 0;
  @Input() cmaTopOffset: number = 0;
  @Input() cmaLeftOffset: number = 0;
  @Input() position: 'fixed' | 'absolute' | 'absolute-relative' = 'fixed';
  @Input() emitOnly: string;

  customMapAutocomplete?: ComponentRef<CustomMapAutocompleteComponent>;
  subscription$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) return;
    
    this.attachOverlay();

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
    ).subscribe(() => this.attachUiConfig());
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.customMapAutocomplete?.destroy();
  }

  private attachOverlay(): void {
    setTimeout(() => {
      this.attachComponentView(); // #1
      this.attachUiConfig(); // #2
      this.attachListeners(); // #3
    }, 150);
  }

  private attachComponentView(): void {
    this.customMapAutocomplete = this.viewContainerRef.createComponent(CustomMapAutocompleteComponent);
    this.customMapAutocomplete.instance.emitOnly = this.emitOnly === '';
    this.customMapAutocomplete.instance.locationChanged.asObservable().subscribe((value: SearchLocationData) => {
      this.locationChanged.emit(value);
    });
  }

  private attachUiConfig(): void {

    if (!this.customMapAutocomplete?.location) return;

    const element = this.element.nativeElement as HTMLElement;
    const component = this.customMapAutocomplete!.location.nativeElement as HTMLElement;
    const elementRect = element.getBoundingClientRect();

    component.style.position = `${this.position === 'fixed' ? 'fixed' : 'absolute'}`;

    if (this.cmaZIndex) {
      component.style.zIndex = `${this.cmaZIndex}`;
    }

    if (isWidget()) {

      component.classList.add('widget');
      component.style.width = `252px`;
    } else {
      
      const fixedHeight = `${elementRect.top + element.clientHeight + this.cmaTopOffset}px`;
      const absoluteHeight = `${element.clientHeight*2 + this.cmaTopOffset}px`;
  
      component.style.top = this.position === 'fixed' ? fixedHeight : absoluteHeight;
      component.style.left = `${elementRect.left + this.cmaLeftOffset}px`;
      component.style.width = `${element.clientWidth}px`;
    }

    if (!isWidget() && this.position === 'absolute-relative' && this.element.nativeElement.tagName === 'MAT-FORM-FIELD') {

      const parentRect = element.parentElement!.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      element.parentElement!.style.position = 'relative';
      component.style.top = `${element.clientHeight + this.cmaTopOffset}px`;
      component.style.left = `${elementRect.left - parentRect.left}px`;
    }

    try {
      element.parentElement?.insertBefore(element, component);
    } catch { }
  }

  private attachListeners(): void {

    const id = `${randomId({ length: 52 })}-${this.cmaIndex}`;

    this.inputElement?.setAttribute('custom-maps-id', id);

    this.customMapAutocomplete && (this.customMapAutocomplete.instance.customMapsId = id);

    this.eventListener(this.inputElement, 'click', id, true);
    this.eventListener(this.inputElement, 'focus', id, true);
    this.eventListener(this.inputElement, 'blur', id, false);

    this.inputElement && fromEvent(this.inputElement, 'input').pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(event => (event.target as HTMLInputElement).value),
      takeUntil(this.subscription$)
    ).subscribe(
      (value: string) => this.appListener.mapsAutocompleteInput$$.next({ [id]: value })
    );
  }

  private eventListener(input: HTMLElement | undefined, event: string, id: string, state: boolean): void {
    // this.customMapAutocomplete!.instance!.display = 'block';
    input && fromEvent(input, event).pipe(
      debounceTime(150),
      takeUntil(this.subscription$)
    ).subscribe(() => {
      const value = { ...this.appListener.mapsAutocompleteState$$.value, [id]: state };
      const instance = this.customMapAutocomplete?.instance;
      if (id === instance?.customMapsId) instance.display = state ? 'block' : 'none';
      this.appListener.mapsAutocompleteState$$.next(value);
    });
  }

  get inputElement(): HTMLInputElement | undefined {
    const element = this.element.nativeElement as HTMLInputElement | undefined;
    return element?.tagName === 'INPUT' ? element : this.element.nativeElement.getElementsByTagName('input').item(0) as HTMLInputElement | undefined;
  }

}
