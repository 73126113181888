import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { AppListenerService, pendingAuthProcessKey } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { PlacesDialogActionService } from './places-dialog';
import { ProfileStateService } from '../common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'map-content',
  templateUrl: './map-content.component.html',
  styleUrls: ['./map-content.component.scss'],
  host: { class: 'page-profile-map-content'},
  viewProviders: [ AuthDialogActionService, PlacesDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(), opacityFadeIn(2000) ]
})
export class MapContentComponent implements OnDestroy {

  authHandle$ = new Subject<void>();

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    public stateService: ProfileStateService,
    public dialog: PlacesDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }
  
  openDialog(): void {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'placesUserVisited';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(this.openDialog.bind(this));
      return;
    }
    
    this.dialog.open();
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }
  
}
