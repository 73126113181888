<form [formGroup]="form">
  
  <mat-form-field>
    <mat-label translate="custom-activity-dialog.section.form.enter-title"></mat-label>
    <input matInput [placeholder]="'custom-activity-dialog.section.form.enter-title' | translate" type="text" name="title" formControlName="title">
  </mat-form-field>
  
  <mat-form-field customMapAutocomplete emitOnly position="absolute-relative" [cmaTopOffset]="46" (locationChanged)="addressLocationChanges($event)">
    <mat-label translate="custom-activity-dialog.section.form.enter-address"></mat-label>
    <input matInput autocomplete="off" [placeholder]="'custom-activity-dialog.section.form.enter-address' | translate" type="text" name="address" formControlName="address">
  </mat-form-field>
  
  <mat-form-field customMapAutocomplete emitOnly position="absolute-relative" [cmaTopOffset]="111" (locationChanged)="cityLocationChanges($event)" class="half-width" formGroupName="locationMap">
    <mat-label translate="custom-activity-dialog.section.form.enter-city"></mat-label>
    <input matInput autocomplete="off" [placeholder]="'custom-activity-dialog.section.form.enter-city' | translate" type="text" name="city" formControlName="city">
  </mat-form-field>
  
  <mat-form-field customMapAutocomplete emitOnly position="absolute-relative" [cmaTopOffset]="111" (locationChanged)="countryLocationChanges($event)" class="half-width" formGroupName="locationMap">
    <mat-label translate="custom-activity-dialog.section.form.enter-country"></mat-label>
    <input matInput autocomplete="off" [placeholder]="'custom-activity-dialog.section.form.enter-country' | translate" type="text" name="country" formControlName="country">
  </mat-form-field>

</form>
