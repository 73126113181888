<div class="dont-sell-info--actions" [formGroup]="form" [class.pending]="pending">

  <mat-checkbox formControlName="dnsmpi">{{ 'dnsmpi.checkbox-text' | translate }}</mat-checkbox>

  <ng-container *ngIf="actionButton === ''">
    <ui-action-button [pending]="processing" (click)="actionClicked.next()">{{ 'dnsmpi.button-text-save' | translate }}</ui-action-button>
  </ng-container>

</div>

<p translate="dnsmpi.note"></p>
  
<ol>
  <li translate="dnsmpi.essential-cookies"></li>
  <li translate="dnsmpi.non-targeted-advertising"></li>
  <li translate="dnsmpi.share-personal-data"></li>
</ol>
