import { StateContext } from "@ngxs/store";

import * as Itinerary from '..';

export function toggleItineraryStateValue(
  ctx: StateContext<Itinerary.ItineraryStateModel>,
  action: Itinerary.ItineraryAction.ToggleLikeItinerary,
  response: any
): any {

  const { data, type } = action;
  const resultKey = type === 'image' ? 'imageInformation' : 'hotelInformation';
  const keyToUpdate = type === 'image' ? 'imgLikes' : 'likeCnt';
  const likesKey = type === 'image' ? 'userLikes' : 'likes';

  const resultData = { ...ctx.getState()![resultKey] } as any;
  const likes = data.hipType
    ? (resultData[likesKey] || [])?.filter((e: any) => e.slice(0, 7) !== data.userId)
    : [...(resultData[likesKey] || []), data.userId];
  const updateObject = { [keyToUpdate]: response.data!.hipCount, [likesKey]: likes };

  return { [resultKey]: { ...resultData, ...updateObject } };
}

export function favoriteResults(ctx: StateContext<Itinerary.ItineraryStateModel>, action: Itinerary.ItineraryAction.ToggleLikeItinerary, response: any): string[] {

  const value = toggleItineraryStateValue(ctx, action, response);

  return [
    value?.imageInformation?.userLikes,
    value?.hotelInformation?.likes
  ].filter(Boolean)?.[0] || [];
}
