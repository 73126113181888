import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulbCircleComponent } from './blub-circle.component';
import { BlubStylesDirective } from './blub-styles.directive';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  BulbCircleComponent
];

const DIRECTIVES = [
  BlubStylesDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    BulbCircleComponent
  ]
})
export class BulbCircleModule { }
