import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { Observable, Subject, delay, filter, fromEvent, merge, takeUntil, tap } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';

const MOUSE_DOWN = 'mouse-down';
const MOUSE_UP = 'mouse-up';
const ACTIVE = 'active';

@Directive({
  selector: '[mouseTransition]'
})
export class MouseTransitionDirective implements AfterViewInit {

  @Output() activated = new EventEmitter<any>();
  @Output() deactivated = new EventEmitter<any>();

  @Input() hotelData: HotelResultData;
  @Input() imageData: ImageResultData;
  @Input() state$: Observable<boolean>;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {

    this.removeClasses();
    this.stateObserver();

    fromEvent(this.element.nativeElement, 'mousedown').pipe(
      filter(() => !this.includes(MOUSE_UP)),
      tap(() => this.addClass(MOUSE_DOWN)),
      takeUntil(this.subscription$)
    ).subscribe();

    merge(
      fromEvent(this.element.nativeElement, 'mouseup'),
      fromEvent(this.element.nativeElement, 'mouseleave'),
      fromEvent(this.element.nativeElement, 'mouseout')
    ).pipe(
      filter(() => this.includes(MOUSE_DOWN)),
      tap(() => this.addClass(MOUSE_UP)),
      delay(150),
      tap(this.removeClasses.bind(this)),
      tap(this.updateState.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Observes state changes and updates the element's content and class accordingly.
   */
  stateObserver(): void {
    this.state$.pipe(takeUntil(this.subscription$)).subscribe((state: boolean) => {
      const element = this.element.nativeElement;
      if (state) {
        element.innerHTML = 'task_alt';
        this.addClass(ACTIVE)
      } else {
        element.innerHTML = 'radio_button_unchecked';
        this.renderer.removeClass(element, ACTIVE);
      }
    });
  }

  /**
   * Updates the component's state based on the element's current content and emits events.
   */
  private updateState(): void {
    const data = this.hotelData || this.imageData;
    const element = this.element.nativeElement;
    if (element.innerHTML === 'task_alt') {
      data && this.deactivated.emit(data);
    } else {
      data && this.activated.emit(data);
    }
  }

  /**
   * Checks if the element contains a specific class.
   *
   * @param className - The name of the class to check for.
   * @returns boolean True if the element contains the class, false otherwise.
   */
  private includes(className: string): boolean {
    const element = this.element.nativeElement;
    return element.classList.contains(className);
  }

  /**
   * Adds a class to the element, removing any existing MOUSE_DOWN or MOUSE_UP classes.
   *
   * @param className - The name of the class to add.
   */
  private addClass(className: string): void {
    this.removeClasses();
    const element = this.element.nativeElement;
    this.renderer.addClass(element, className);
  }
  
  /**
   * Removes the MOUSE_DOWN and MOUSE_UP classes from the element.
   */
  private removeClasses(): void {
    const element = this.element.nativeElement;
    this.renderer.removeClass(element, MOUSE_DOWN);
    this.renderer.removeClass(element, MOUSE_UP);
  }

}
