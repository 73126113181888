import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  transform(value: string): string {
    const date = new Date(value);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  }

}

@NgModule({
  declarations: [ LocalDatePipe ],
  exports:      [ LocalDatePipe ]
})
export class LocalDatePipeModule { }
