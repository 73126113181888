import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AssetImageComponent } from './asset-image.component';
import { ImageLayoutDirective } from './image-layout.directive';

const MODULES = [
  CommonModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const COMPONENTS = [
  AssetImageComponent
];

const DIRECTIVES = [
  ImageLayoutDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    AssetImageComponent
  ]
})
export class AssetImageModule { }
