import { AboutModule } from './about/about.module';
import { CategoriesModule } from './categories/categories.module';
import { ContactInfoModule } from './contact-info/contact-info.module';
import { CoverImageModule } from './cover-image/cover-image.module';
import { GalleryImagesModule } from './gallery-images/gallery-images.module';
import { HighlightsModule } from './highlights/highlights.module';
import { HoursPricesModule } from './hours-prices/hours-prices.module';
import { MainInfoModule } from './main-info/main-info.module';
import { IncludedModule } from './included/included.module';
import { NotIncludedModule } from './not-included/not-included.module';

export const CustomActivityDialogView = [
  AboutModule,
  CategoriesModule,
  ContactInfoModule,
  CoverImageModule,
  GalleryImagesModule,
  HighlightsModule,
  HoursPricesModule,
  MainInfoModule,
  IncludedModule,
  NotIncludedModule
];
