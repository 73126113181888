import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, delay, firstValueFrom, map, takeUntil } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { RegistrationFormStateService } from '../common';
import { FormControl } from '@angular/forms';

function getExperiences(experiences: Partial<Experience>[] | null): Partial<Experience>[] {
  return experiences?.filter(e => e.category === 'activity') || [];
}

@Injectable()
export class ActivitiesService {

  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    private stateService: RegistrationFormStateService
  ) { }

  get formValue$(): Observable<string[]> {
    return this.stateService.subCategoriesForm.valueChanges.pipe(
      delay(0),
      takeUntil(this.subscription$)
    );
  }

  get experiences$(): Observable<Partial<Experience>[]> {
    return this.store.select(BrandState.experiences).pipe(
      map((experiences: Partial<Experience>[] | null) => experiences?.filter(e => e.category === 'activity') || [])
    );
  }

  get experiences(): Promise<Partial<Experience>[]> {
    return firstValueFrom(this.experiences$);
  }

  initializeFormObserver(): void {
    this.stateService.initializeFormObserver(() => this.subscription$.next());
  }

  addActivity(selection: string): void {
    const experiences = getExperiences(this.store.selectSnapshot(BrandState.experiences));
    const selectionIndex = experiences.map(e => e.code).indexOf(selection);
    this.stateService.subCategoriesForm.push(
      new FormControl(experiences[selectionIndex].code)
    );
  }

  removeActivity(index: number): void {
    const experiences = getExperiences(this.store.selectSnapshot(BrandState.experiences));
    const formCategoriesValue = this.stateService.subCategoriesForm.value as string[];
    const formCategoriesIndex = formCategoriesValue.indexOf(experiences[index].code || '');
    this.stateService.subCategoriesForm.removeAt(formCategoriesIndex);
  }

}
