import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '../../list-items';

@Pipe({
  name: 'filterData'
})
export class FilterDataPipe implements PipeTransform {

  transform(value: ListItem[], sort: string): ListItem[] {
    return value?.filter(e => e.type === sort);
  }

}
