import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, fromEvent, takeUntil } from 'rxjs';

import { SearchResultDialogService } from './search-result-dialog.service';
import { RequestCancellationService, preventExperienceFinderBubbleStateKey } from '@hiptraveler/common';
import { SearchResultDialogStateService, SearchResultDialogControlService } from './common';

@Component({
  selector: 'app-search-result-dialog',
  templateUrl: './search-result-dialog.component.html',
  styleUrls: ['./search-result-dialog.component.scss', './search-result-dialog-responsiveness.scss'],
  viewProviders: [ SearchResultDialogService, SearchResultDialogStateService ],
  providers: [ SearchResultDialogControlService ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-search-result-dialog' }
})
export class SearchResultDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  addToTripSignal$ = new Subject<boolean>();
  subscription$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private element: ElementRef<HTMLElement>,
    public dialogRef: MatDialogRef<SearchResultDialogComponent>,
    public service: SearchResultDialogService,
    private requestCancellation: RequestCancellationService,
  ) { }

  async ngOnInit(): Promise<void> {

    await this.service.resetState();
    
    this.service.getResultInformation(this.data);
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem(preventExperienceFinderBubbleStateKey);
    this.subscription$.next();
    this.service.resetState();
    this.requestCancellation.cancelAllRequests();
  }

  ngAfterViewInit(): void {
    const resultContent = this.element.nativeElement.getElementsByClassName('result-content').item(0);
    
    fromEvent(resultContent!, 'scroll').pipe(takeUntil(this.subscription$)).subscribe(() => {
      
      if (resultContent?.scrollTop !== 0) {
        this.addToTripSignal$.next(false);
      }
    });
  }

  addToTrip(): void {
    this.addToTripSignal$.next(true);

    const resultContent = this.element.nativeElement.getElementsByClassName('result-content').item(0);
    resultContent!.scrollTop = 0;
    resultContent!.scrollTo({ top: 0 });
  }

}
