import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PoiSelectCarouselModule } from '../../components/poi-select-carousel';

import { CarouselLocationComponent } from './carousel-location.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  PoiSelectCarouselModule
];

const COMPONENTS = [
  CarouselLocationComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CarouselLocationComponent
  ]
})
export class CarouselLocationModule { }
