import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityContentComponent } from './activity-content.component';
import { DisplayTextPipe } from './display-text.pipe';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  ActivityContentComponent
];

const PIPES = [
  DisplayTextPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    ActivityContentComponent
  ]
})
export class ActivityContentModule { }
