import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, filter, map, takeUntil, tap } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { isWidget, subdomain } from '@hiptraveler/common';

@Directive({
  selector: '[layoutStyles]'
})
export class LayoutStylesDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    if (subdomain() === 'hiptraveler') return;

    if (!isWidget()) {
      this.element.nativeElement.style.background = '#fcfcfc';
    }

    const footerHeight = '310px';
    // const footerHeight = '340px';
    this.element.nativeElement.style.minHeight = `calc(100dvh - ${footerHeight} + 30px)`;

    this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => !!brandCampaign?.hasTravelAgents),
      takeUntil(this.subscription$),
      filter(Boolean),
      tap(() => (this.element.nativeElement.style.minHeight = `calc(100dvh - ${footerHeight})`))
    ).subscribe();
  }

}
