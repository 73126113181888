import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { UserState } from '.';

const STATES = [
  UserState
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ]
})
export class UserStateModule { }
