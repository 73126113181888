import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { EventState } from '.';

const STATES = [
  EventState
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ]
})
export class EventStateModule { }
