import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, firstValueFrom, Observable, Subject, takeUntil, tap } from 'rxjs';
import { pick } from 'lodash';

import { ActivityDateData, HotelResultData, ImageResultData, ItineraryParam, UpdateOrderItineraries } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ItineraryAction, ItineraryActivityPosition, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { getAdventuresQuery, getFoodsQuery, getHotelsQuery, SearchAction } from '@hiptraveler/data-access/search';
import { DialogType, SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { PromptDialogActionService, PromptDialogStateService } from '@hiptraveler/dialogs/prompt-dialog';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ComposePrivateNoteDialogActionService } from '@hiptraveler/dialogs/compose-private-note-dialog';
import { SnackbarService } from '@hiptraveler/snackbar';
import { AppListenerService, NavbarControlStateService, ScrollListenerService, SearchLocationService, SearchPageControlStateService, currentLang, getWindowRef, pendingAuthProcessKey, removeCircularReferences, updateOrderItinerariesKey } from '@hiptraveler/common';
import { ComponentStateService, ItineraryStateService, preventRemoveKey, preventUpdateFormStateByPlaceResult } from '@hiptraveler/features/itinerary';

@Injectable()
export class ActivityDayListService {

  itineraryType: ItineraryParam = 'itinerary';
  #savedChanges: { [key:number]: boolean } = {};
  authHandle$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService,
    private searchLocation: SearchLocationService,
    private searchPageControl: SearchPageControlStateService,
    private scrollListener: ScrollListenerService,
    private authDialog: AuthDialogActionService,
    private searchResultDialog: SearchResultDialogActionService,
    private promptDialog: PromptDialogActionService,
    private promptDialogState: PromptDialogStateService,
    private composePrivateNote: ComposePrivateNoteDialogActionService,
    private componentState: ComponentStateService,
    private stateService: ItineraryStateService,
    private snackbar: SnackbarService
  ) {
    sessionStorage.removeItem(updateOrderItinerariesKey);
  }

  /**
   * Returns activity date map data in array
   */
  get actDate$(): Observable<ActivityDateData[] | null> {
    return this.store.select(ItineraryState.actDateArr).pipe(
      distinctUntilChanged((p, c) => JSON.stringify(p, removeCircularReferences()) === JSON.stringify(c, removeCircularReferences()))
    );
  }

  activityDayListUiObserver(): void {

    this.scrollListener.scrollPosition$.subscribe((scrollTop: number) => {
      
      const activityData = this.componentState.subscription$$.value?.activeDayConfig || [];

      function getActivityDay(scrollTop: number): any {
        for (let i = 0; i < activityData.length; i++) {
          let currentActivity = activityData[i];
          let nextActivity = activityData[i + 1];
  
          if (!nextActivity) {
            if (scrollTop >= currentActivity.position) {
                return currentActivity.activityDay;
            }
          }
  
          if (scrollTop >= currentActivity.position && scrollTop < nextActivity.position) {
            return currentActivity.activityDay;
          }
        }
      }

      const value = getActivityDay(scrollTop);
      value && this.componentState.patch({ activeDay: value });
    });
  }

  /**
   * Handles the movement of an itinerary activity position.
   * 
   * @param data - Information about the moved itinerary activity.
   */
  async positionMoved(data: ItineraryActivityPosition): Promise<void> {

    if (!this.#savedChanges?.[data.day]) {
      this.snackbar.open({ message: `Please don't forget to click the "Save" button to save your changes!` });
      this.#savedChanges[data.day] = true
    }

    await firstValueFrom(this.store.dispatch(new ItineraryAction.MoveItineraryActivityPosition(data)));

    const updateOrderItineraries: UpdateOrderItineraries[] = JSON.parse(sessionStorage.getItem(updateOrderItinerariesKey) || '[]');
    const value: UpdateOrderItineraries = {
      day: data?.day || -1,
      imgArray: this.store.selectSnapshot(ItineraryState.actDateMap)?.[data.day]?.ImgArray?.map(e => e.id) || []
    };
    
    const existingItinerary = updateOrderItineraries.find(e => e.day === data?.day);
    if (existingItinerary) {
      existingItinerary.imgArray = value.imgArray;
    } else {
      updateOrderItineraries.push(value);
    }
    sessionStorage.setItem(updateOrderItinerariesKey, JSON.stringify(updateOrderItineraries));
  }

  /**
   * Navigates to a different page based on the selected activity and type.
   * 
   * @param activity - Data about the selected activity.
   * @param selection - The type of activity (thingstodo, hotels, or foodanddrink).
   */
  async navigate(activity: ActivityDateData, selection: 'thingstodo' | 'hotels' | 'foodanddrink'): Promise<void> {
    const activityDay: any = { ...activity };
    activityDay['actDate'] = this.store.selectSnapshot(ItineraryState.actDate) || [];
    activityDay['actDateMap'] = this.store.selectSnapshot(ItineraryState.actDateMap) || [];
    activityDay['pageTitle'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle;
    activityDay['itineraryId'] = this.store.selectSnapshot(ItineraryState.basicInfo)?.id;
    this.searchPageControl.activityDate$$.next(activityDay);
    try {
      await this.navigateToSearchPage(activityDay, selection);
    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    }
  }

  /**
   * Opens a private note dialog for the specified result data.
   * 
   * @param resultData - Data related to the private note.
   */
  openPrivateNote(resultData: HotelResultData | ImageResultData, type: 'image' | 'hotel'): void {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'openPrivateNoteItineraryPage';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => {
        const buttonClass = '.navbar--action-button';
        const button = document.body.querySelectorAll(buttonClass).item(1);
        button && button.dispatchEvent(new Event('click'));
      });
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 500);
      return;
    }

    const resultId = resultData?.id || '';
    const reservations = this.store.selectSnapshot(ItineraryState.itineraryReservations) || [];
    const reservation = reservations.find(e => e?.objId === resultId);

    this.composePrivateNote.open({
      dbId: this.store.selectSnapshot(ItineraryState.basicInfo)?.id,
      objId: reservation?.objId || resultData?.id,
      param: 'itinerary',
      objName: reservation?.objName || resultData?.name,
      location: reservation?.location || resultData?.address?.city,
      isBooked: reservation?.booked,
      checkIn: reservation?.checkIn,
      checkOut: reservation?.checkOut,
      exists: !!reservation, type,
      ...pick(reservation, [ 'notes', 'reference', 'contact' ])
    });
  }

  /**
   * Opens a read more dialog for the specified result data and type.
   * 
   * @param resultData - Data to be displayed in the dialog.
   * @param type - The type of dialog to open.
   */
  openReadMore(resultData: any, type: DialogType): void {
    this.searchResultDialog.open(resultData, type);
  }

  /**
   * Replace itinerary card.
   * 
   * @param resultData The data of the result item to be removed.
   * @param type The type of the result item, either 'image' or 'hotel'.
   * @param day The day of the itinerary from which to remove the item.
   */
  async replaceItinerary(resultData: any, type: 'image' | 'hotel', day: number = 1): Promise<void> {

    if (!day) return;

    const activity = this.store.selectSnapshot(ItineraryState.actDateMap)![day] as any
    const selection = resultData?.type === 'hotel' ? 'hotels'
      : resultData?.imgCategory?.toLowerCase()?.includes('food') ? 'foodanddrink'
      : 'thingstodo'

    try {
      await this.navigateToSearchPage(activity, selection, (url: string) => {
        this.searchPageControl.replaceItineraryActivity$$.next({
          composeUrl: url,
          idToBeRemoved: resultData.id,
          type, day
        });
      });
    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    }
  }

  /**
   * Removes a result item from the itinerary.
   * 
   * @param resultData The data of the result item to be removed.
   * @param type The type of the result item, either 'image' or 'hotel'.
   * @param day The day of the itinerary from which to remove the item.
   */
  async removeResultItinerary(resultData: any, type: 'image' | 'hotel', day: number = 1): Promise<void> {

    if (resultData.imgCategory === 'Chill time') {
      return this.removeChillTimeResultItinerary(resultData, type, day, 'remove-item');
    }

    const chillTimeExecutable = () => this.removeChillTimeResultItinerary(resultData, type, day, 'remove-item-chill');
    
    const replaceEventExecutable = () => {
      if (!day) return;
      this.promptDialog.instance.close();
      this.replaceItinerary(resultData, type, day);
    }

    setTimeout(() => this.appListener.emitGlobalSignal('removeClickedProgress'), 150);
    this.promptDialog.open({
      title: 'Options',
      message: `You can either mark this time as "Chill time" or replace the event with another activity.`,
      buttons: [
        { name: 'Chill time', theme: 'secondary', progressSpinner: true, executable: chillTimeExecutable },
        { name: 'Replace event', theme: 'action', executable: replaceEventExecutable }
      ]
    });
  }

  /**
   * Removes a result item from the itinerary, marking it as "Chill time" or removing it entirely.
   * 
   * @param resultData The data of the result item to be removed.
   * @param type The type of the result item, either 'image' or 'hotel'.
   * @param day The day of the itinerary from which to remove the item.
   * @param action The action to perform, either 'remove-item-chill' or 'remove-item'.
   */
  private async removeChillTimeResultItinerary(resultData: any, type: 'image' | 'hotel', day: number, action: 'remove-item-chill' | 'remove-item'): Promise<void> {
    
    const dialog = this.promptDialog.reference;
    try {
      
      if (!day) return;

      this.promptDialogState.updateButtonState(0, true);
      this.searchPageControl.featureCardProcessing$$.next(true);
      dialog && (dialog.disableClose = true);
      action === 'remove-item' && this.navbarControl.navbarActionButtonState$$.next(false);
      action === 'remove-item' && this.stateService.dispatchPending$$.next(true);
      console.log('@@@ try:action', action);

      await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
        action, day, type,
        param: this.itineraryType,
        item: resultData.id,
        id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '',
        lang: currentLang(),
      })));

    } catch (response: any) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.appListener.emitGlobalSignal('removeClickedProgress');
      this.promptDialogState.updateButtonState(0, false);
      this.searchPageControl.featureCardProcessing$$.next(false);
      dialog && (dialog.disableClose = false);
      dialog && (dialog.close())
      action === 'remove-item' && this.navbarControl.navbarActionButtonState$$.next(true);
      action === 'remove-item' && this.stateService.dispatchPending$$.next(false);
      console.log('@@@ finally:action', action);
    }
  }

  findPlacesToStayNavigation(activity: ActivityDateData, index: number): void {

    getWindowRef()[preventRemoveKey] = '1';
    this.navigate(activity, 'hotels');

    setTimeout(() => {
      getWindowRef()[preventRemoveKey] = undefined;
    }, 3000);

  }

  async navigateToSearchPage(activity: ActivityDateData, selection: string, callback?: (url: string) => void): Promise<void> {

    const url = getWindowRef()?.location?.pathname;
    const selectedActCity = activity?.dayLocMap?.city;
    const routePath = [ currentLang(), selection, selectedActCity || '' ].filter(Boolean);

    if (selectedActCity && !activity?.dayLocMap?.id) {
      const brandLocations = this.store.selectSnapshot(BrandState.locations);
      const matchesCityOrAddress = (e: any, location: any) =>
        [e.city, e.formattedAddr].includes(location?.city) ||
        [e.city, e.formattedAddr].includes(location?.formatted_address);
      const brandLocationId = brandLocations?.find(e => matchesCityOrAddress(e, activity.dayLocMap))?.id;
      activity['dayLocMap']['id'] = brandLocationId || '';
    }

    getWindowRef()[preventUpdateFormStateByPlaceResult] = '1';

    callback && callback(url);

    activity?.dayLocMap && this.searchLocation.updateSearchLocation({
      name: selectedActCity || '',
      location: activity.dayLocMap?.formatted_address || '',
      locId: activity.dayLocMap?.id || '',
      latitude: +activity.dayLocMap?.longitude || 0,
      longitude: +activity.dayLocMap?.latitude || 0
    });

    const query = selection === 'thingstodo' ? getAdventuresQuery()
      : selection === 'hotels' ? getHotelsQuery()
      : selection === 'foodanddrink' ? getFoodsQuery() : getAdventuresQuery();

    setTimeout(async () => {
      if (query?.name !== selectedActCity) {
        await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'adventures', 'hotels', 'foods' ])));
      }
      await firstValueFrom(this.store.dispatch(new SearchAction.ResetSearchState([ 'locationData' ])));
      await this.router.navigate(routePath);
      getWindowRef()[preventUpdateFormStateByPlaceResult] = undefined;
    }, 300);
  }

  resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
