<form class="auth-dialog--form" (ngSubmit)="service.verifyOtpCode()" [formGroup]="service.form">

  <p class="auth-dialog--form-title" translate="auth.create-new-password"></p>
  
  <p class="auth-dialog--form-sub-title" translate="auth.enter-new-password-instruction"></p>
  
  <input type="text" name="username" autocomplete="username" style="display: none;">
  <div class="auth-dialog--form-field">
    <mat-form-field>
      <mat-label>{{ 'auth.password' | translate }}</mat-label>
      <input matInput [type]="inputType" autocomplete="new-password" name="password" formControlName="password">
      <mat-error *ngIf="controlError('password')">{{ 'auth.invalid-password-format' | translate }}</mat-error>
      <mat-error *ngIf="requiredControl('password')">{{ 'auth.password-required' | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div class="auth-dialog--form-field">
    <mat-form-field>
      <mat-label>{{ 'auth.confirm-password' | translate }}</mat-label>
      <input matInput [type]="inputType" autocomplete="new-password" name="confirm" formControlName="confirm">
      <mat-error *ngIf="passwordMismatchControl()">
        {{ 'auth.passwords-not-match' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="auth-dialog--form-field">
    <mat-checkbox (click)="inputType = inputType === 'password' ? 'text' : 'password'">{{ 'auth.show-password' | translate }}</mat-checkbox>
  </div>

  <ui-action-button type="submit" [pending]="!!(service.pending$$ | async)">{{ 'auth.save-password' | translate }}</ui-action-button>

  <p class="auth-dialog--form-action">
    <mat-icon svgIcon="auth-dialog.caret-back-icon-svg"></mat-icon>
    <span (click)="backToForgotPasswordClicked.emit()" translate="auth.back-to-forgot-password"></span>
  </p>

</form>
