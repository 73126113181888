import { Inject, Injectable } from '@angular/core';

import { Client, FILESTACK_CLIENT, PickerFileMetadata, PickerInstance, PickerOptions, defaultClientOptions } from '@hiptraveler/core/filestack';
import { ComponentStateService } from '@hiptraveler/features/itinerary';

export type FilestackPickerOption = 'banner' | 'editor-image';

@Injectable()
export class FilestackPickerService {

  constructor(
    @Inject(FILESTACK_CLIENT) private filestackClient: Promise<Client>,
    private componentState: ComponentStateService,
  ) { }

  /**
   * Opens the Filestack picker with the specified options and handles file selection events.
   *
   * @param option - The type of file to be selected ('banner' or 'editor-image').
   * @param callback - An optional callback function to be invoked with the selected file metadata.
   */
  async openFilestackPicker(option: FilestackPickerOption, callback?: (metadata: PickerFileMetadata) => void): Promise<void> {
 
    let pickerInstance: PickerInstance | null = null;

    const options: PickerOptions = {
      ...defaultClientOptions,
      onOpen: () => {
        console.log('@@@ ', 'picker opened');
      },
      onCancel: () => {
        console.log('@@@ ', 'picker cancelled');
      },
      onClose: () => {
        option === 'banner' && this.componentState.patch({
          // bannerImage: uploadedFile.filesUploaded[0]
        });
        // callback && callback(uploadedFile.filesUploaded[0]);
      },
      onFileUploadFinished: (bannerImage: PickerFileMetadata) => {
        option === 'banner' && this.componentState.patch({ bannerImage, bannerAttribution: null });
        pickerInstance && pickerInstance.close();
        callback && callback(bannerImage);
      },
    };

    pickerInstance = (await this.filestackClient)?.picker(options) || null;
    pickerInstance?.open();
  }

}
