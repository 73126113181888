import { LocationData } from "@hiptraveler/data-access/api";
import { SearchLocationData } from '@hiptraveler/common';

export function toSearchLocation(result: LocationData): SearchLocationData {
  return {
    name: result.city,
    country: result?.country || 'undefined',
    location: result?.formattedAddr || 'undefined',
    locId: result?.id || result?.gPlaceId || result?.countryId || 'undefined',
    latitude: +result.latitude,
    longitude: +result.longitude
  };
}
