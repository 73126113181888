import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from './authenticated.guard';
import { ComposeComponent } from './compose.component';

const childRoutes: Routes = [
  {
    path: 'new-story',
    loadChildren: () => import('./new-blog/new-blog.module').then(m => m.NewBlogModule),
    data: { feature: 'blog' }
  },
  {
    path: 'travel-story/:blog',
    loadChildren: () => import('./modify-blog/modify-blog.module').then(m => m.ModifyBlogModule),
    data: { feature: 'blog' }
  },
  {
    path: 'itinerary/:itinerary',
    loadChildren: () => import('./modify-itinerary/modify-itinerary.module').then(m => m.ModifyItineraryModule),
    data: { feature: 'itinerary' }
  }
];

const routes: Routes = [
  {
    path: '', component: ComposeComponent,
    children: childRoutes,
    canActivateChild: [ AuthenticatedGuard ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    AuthenticatedGuard
  ],
  exports: [
    RouterModule
  ]
})
export class ComposeRoutingModule { }
