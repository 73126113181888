import { without } from 'lodash';

import { CarouselScreenValue, FormValue } from "..";

export function newFormValue(targetItem: Record<string, any>, data: FormValue): FormValue | null {

  const output = targetItem?.['output'];
  const formValue = { ...data } as FormValue;

  if (!output) {
    return null;
  }

  const screenKey = targetItem?.['carouselData'] as CarouselScreenValue;
  if (screenKey && formValue.carouselData[screenKey]) {
    const screenItem = output.replaceAll(' ', '_').toLowerCase();
    formValue.carouselData[screenKey] = without([ ...formValue.carouselData[screenKey] ], screenItem);
  }

  if (targetItem?.['dateRange']) {
    formValue.dateRange = {
      start: null, end: null
    }
  }

  if (targetItem.hasOwnProperty('clientInfo')) {
    formValue.clientInfo = {
      ...formValue.clientInfo,
      [targetItem['clientInfo']]: 0
    };
  }

  return formValue;
}
