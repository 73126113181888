import { Injectable, Provider } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { takeUntil, Observable } from 'rxjs';

import { RequestCancellationService } from '@hiptraveler/common';

const targetEndpoints = [
  '/trip-planner/search/location',
  '/trip-planner/search/hotels',
  '/trip-planner/search/Itineraries',
  '/trip-planner/search/Adventure/location',
  '/trip-planner/search/Food/location',
  '/trip-planner/search/community',
  '/trip-planner/getBrandContent'
];

@Injectable()
export class _SearchRequestCancellationInterceptor implements HttpInterceptor {

  constructor(
    private requestCancellation: RequestCancellationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return targetEndpoints.some((path: string) => request.url.includes(path))
      ? next.handle(request).pipe( takeUntil(this.requestCancellation.searchCancellationSignal$) )
      : next.handle(request);
  }

}

export const SearchRequestCancellationInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _SearchRequestCancellationInterceptor,
  multi: true
}
