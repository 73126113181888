
<button mat-icon-button (click)="updateCalendarDisplay('subtract')" [disabled]="disablePrevButton">
  <mat-icon>chevron_left</mat-icon>
</button>

<p class="experience-finder-ai--host-element-header">When would you like to hit the road?</p>

<mat-calendar [minDate]="minDate" [selected]="selectedDateRange$$.asObservable() | async" (selectedChange)="onSelectedChange($event)" #calendar1></mat-calendar>

<mat-calendar [selected]="selectedDateRange$$.asObservable() | async" (selectedChange)="onSelectedChange($event)" #calendar2></mat-calendar>

<button mat-icon-button (click)="updateCalendarDisplay('add')">
  <mat-icon>chevron_right</mat-icon>
</button>
