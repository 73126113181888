import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, map, startWith } from 'rxjs';

import { ToolbarOption } from '@hiptraveler/common';

@Pipe({
  name: 'optionName'
})
export class OptionNamePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(value: Partial<ToolbarOption>): Observable<string | null> {
    return this.translateService.onLangChange.pipe(
      map((event: LangChangeEvent) => {
        if (typeof value.name === 'string') return value.name;
        return value.name?.[event.lang] || null;
      }),
      startWith(value.name?.[this.translateService.currentLang] || value.name  || null)
    );
  }

}
