import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { UserInfoMobileToolbarComponent } from './user-info-mobile-toolbar.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const COMPONENTS = [
  UserInfoMobileToolbarComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    UserInfoMobileToolbarComponent
  ]
})
export class UserInfoMobileToolbarModule { }
