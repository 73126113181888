import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { HiptravelerGptComponent } from './hiptraveler-gpt.component';

const MODULES = [
  CommonModule,
  FormsModule,
  MatButtonModule,
  MatIconModule
];

const COMPONENTS = [
  HiptravelerGptComponent
];

@NgModule({
  imports: [
    MODULES,
    RouterModule.forChild([{
      path: '', component: HiptravelerGptComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    HiptravelerGptComponent
  ]
})
export class HiptravelerGptModule { }
