import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { currentLang } from '@hiptraveler/common';

function formatMonth(isoString: string): string {
  const months = [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ];
  const date = new Date(isoString);
  const monthIndex = date.getMonth();
  return months[monthIndex];
}

@Pipe({
  name: 'toLocalTime'
})
export class ToLocalTimePipe implements PipeTransform {

  constructor(
    private i18n: TranslateService
  ) { }

  transform(form: any): string {
    if (!form.checkInDate || !form.checkOutDate) return '';

    const startMonth = this.i18n.translations?.[currentLang()]?.['search-page']?.[
      formatMonth(form.checkInDate.toISOString())
    ];
    const endMonth = this.i18n.translations?.[currentLang()]?.['search-page']?.[
      formatMonth(form.checkOutDate.toISOString())
    ];

    return `${startMonth} ${new Date(form.checkInDate).getDate()} - ${startMonth === endMonth ? '' : endMonth} ${new Date(form.checkOutDate).getDate()}`;
  }

}
