import { Injectable, NgModule, Optional } from '@angular/core';
import { Actions } from '@ngxs/store';
import { of } from 'rxjs';

import { ActivityApiService } from '@hiptraveler/data-access/api';
import { SearchAction } from '@hiptraveler/data-access/search';
import { AnalyticsService } from '../analytics.service';
import { SearchLocationService } from '@hiptraveler/common';
import * as Helper from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class SearchStateService extends AnalyticsService {

  constructor(
    actions$: Actions,
    api: ActivityApiService,
    search: SearchLocationService
  ) {
    
    super(actions$);

    this.searchRequestAnalytics(api, search);

    this.observeActionDispatched(
      SearchAction.ToggleSearchResultLike,
      (param: SearchAction.ToggleSearchResultLike) => api.setUserAction({
        actionType: 'hip',
        objType: Helper.getHipObjectTypeByField(param?.data?.field)
      }, {
        actionId: param.data.id,
      })
    );
  }

  private searchRequestAnalytics(api: ActivityApiService, search: SearchLocationService): void {

    const executeRequest = () => {
      return search.rootSearchRoute || search.rootSearchPage
        ? of(null) : api.setUserAction({ actionType: 'view', objType: search.searchRoutePath });
    };

    this.observeActionDispatched(SearchAction.GetAdventuresContent, executeRequest);
    this.observeActionDispatched(SearchAction.GetFoodsContent, executeRequest);
    this.observeActionDispatched(SearchAction.GetHotelsContent, executeRequest);
    this.observeActionDispatched(SearchAction.GetItinerariesContent, executeRequest);

    this.observeActionDispatched(SearchAction.GetAdventuresByQuery, executeRequest);
    this.observeActionDispatched(SearchAction.GetFoodsByQuery, executeRequest);
    this.observeActionDispatched(SearchAction.GetHotelsByQuery, executeRequest);
    this.observeActionDispatched(SearchAction.GetItinerariesByQuery, executeRequest);
    this.observeActionDispatched(SearchAction.GetCommunitiesByQuery, executeRequest);

    this.observeActionDispatched(SearchAction.GetAdventureTermByQuery, executeRequest);
    this.observeActionDispatched(SearchAction.GetFoodsTermByQuery, executeRequest);
    this.observeActionDispatched(SearchAction.GetHotelsTermByQuery, executeRequest);
  }

}

@NgModule()
export class SearchStateModule {
  constructor(
    @Optional() service: SearchStateService
  ) { }
}
