import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ItineraryActionPanelComponent } from './itinerary-action-panel.component';
import { ActionPanelStylesDirective } from './action-panel-styles.directive';
import { ValidateStatusPipe } from './validate-status.pipe';
import { ValidateIdPipe } from './validate-id.pipe';
import { DateStringPipe } from './date-string.pipe';
import { ParseActivityDatePipe } from './parse-activity-date.pipe';
import { AddedToCurrentDayPipe } from './added-to-current-day.pipe';
import { AddedToAllDaysPipe } from './added-to-all-days.pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  ItineraryActionPanelComponent
];

const DIRECTIVES = [
  ActionPanelStylesDirective
];

const PIPES = [
  ValidateStatusPipe,
  ValidateIdPipe,
  DateStringPipe,
  ParseActivityDatePipe,
  AddedToCurrentDayPipe,
  AddedToAllDaysPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    ValidateStatusPipe,
    ItineraryActionPanelComponent
  ]
})
export class ItineraryActionPanelModule { }
