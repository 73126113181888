import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSelect } from '@angular/material/select';

import { AuthRegisterDialogService } from '@hiptraveler/dialogs/auth-dialog';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { PrivacyPolicyDialogActionService, TermsAndConditionsDialogActionService } from '@hiptraveler/dialogs/terms-and-privacy-policy-dialog';
import { FormService } from './form.service';

@Component({
  selector: 'registration-menu-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  host: { class: 'experience-finder-registration-menu-form' },
  viewProviders: [ FormService, AuthRegisterDialogService, PrivacyPolicyDialogActionService, TermsAndConditionsDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit {

  @ViewChild('travelTradeSelect') travelTradeSelect: MatSelect;

  @Input() submitClicked: EventEmitter<void>;

  constructor(
    public service: FormService,
    private stateService: ExperienceFinderStateService,
    public privacyPolicy: PrivacyPolicyDialogActionService,
    public termsAndConditions: TermsAndConditionsDialogActionService
  ) { }

  ngOnInit(): void {
    this.service.initializeForm();
  }
  
  ngAfterViewInit(): void {

    this.service.form.get('travelTrade')?.valueChanges.subscribe((checked: boolean) => {
      this.service.form.patchValue({ userType: checked ? 'htAgent': 'htUser' });
      checked && this.travelTradeSelect.open()
    });
    
    this.travelTradeSelect.selectionChange.asObservable().subscribe(({ value }) => {
      this.service.form.patchValue({ userType: value });
    });

    this.submitClicked.asObservable().subscribe(() => {
      this.service.signUpWithEmailAndPassword();
    });
  }

  controlError(control: string): boolean {
    if (this.requiredControl(control)) return false;
    return this.service.form.get(control)?.errors !== null;
  }

  requiredControl(control: string): boolean {
    const errors = this.service.form.get(control)?.errors;
    return errors?.hasOwnProperty('required') && errors?.['required'];
  }

  backToPreviousView(): void {
    this.stateService.dialogView$$.next('auth-menu');
  }

}
