import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, delay, map, takeUntil } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { RegistrationFormStateService } from '../common';
import { FormControl } from '@angular/forms';

function getExperiences(experiences: Partial<Experience>[] | null): Partial<Experience>[] {
  return experiences?.filter(e => e.category !== 'activity') || [];
}

@Injectable()
export class SpecializationService {

  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    private stateService: RegistrationFormStateService
  ) { }

  get formValue$(): Observable<string[]> {
    return this.stateService.categoriesForm.valueChanges.pipe(
      delay(0),
      takeUntil(this.subscription$)
    );
  }
  
  get experiences$(): Observable<Partial<Experience>[]> {
    return this.store.select(BrandState.experiences).pipe(
      map(getExperiences.bind(this))
    );
  }

  initializeFormObserver(): void {
    this.stateService.initializeFormObserver(() => this.subscription$.next());
  }

  addSpecialization(index: number): void {
    const experiences = getExperiences(this.store.selectSnapshot(BrandState.experiences));
    const selectionIndex = experiences.map((_, index: number) => index).indexOf(index);
    this.stateService.categoriesForm.push(
      new FormControl(experiences[selectionIndex].code)
    );
  }

  removeSpecialization(index: number): void {
    const experiences = getExperiences(this.store.selectSnapshot(BrandState.experiences));
    const formCategoriesValue = this.stateService.categoriesForm.value as string[];
    const formCategoriesIndex = formCategoriesValue.indexOf(experiences[index].code || '');
    this.stateService.categoriesForm.removeAt(formCategoriesIndex);
  }

}
