import { getWindowRef } from "@hiptraveler/common";

export const DEFAULT_BRAND_NAME: string = 'hiptraveler';
export const BAHAMAS_BRAND_NAME: string = 'bahamas';
export const VISITJORDAN_BRAND_NAME: string = 'visitjordan';
export const VISITFIJI_BRAND_NAME: string = 'visitfiji';

export function subdomain(): string {

  const hostname = getWindowRef()?.location?.hostname;

  if (hostname?.includes('dev.hiptraveler')) { // TO DO: TEST CASE ONLY
    const subdomain = getWindowRef()?.localStorage?.getItem('subdomain');
    if (subdomain === 'hiptraveler') return DEFAULT_BRAND_NAME;
    if (subdomain === 'visitjordan') return VISITJORDAN_BRAND_NAME;
    if (subdomain === 'visitfiji') return VISITFIJI_BRAND_NAME;
    // return BAHAMAS_BRAND_NAME;
    return DEFAULT_BRAND_NAME;
  } // TO DO: TEST CASE ONLY

  const parts = hostname?.split('.');
  
  if (hostname?.includes('localhost')) {
    return hostname?.includes('.') ? parts?.[0] : DEFAULT_BRAND_NAME;
  }

  if (hostname?.startsWith('www.hiptraveler.com')) {
    return DEFAULT_BRAND_NAME;
  }

  return parts?.length >= 3 ? parts?.[0] : DEFAULT_BRAND_NAME;
}

export function brandSite(): boolean {
  const origin  = getWindowRef()?.location?.host;
  const htSubdomain = subdomain() === DEFAULT_BRAND_NAME;
  const nonBrand = htSubdomain || origin?.includes('www.hiptraveler') || origin?.startsWith('localhost');
  return nonBrand ? false : true;
}
