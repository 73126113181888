import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { ExperienceFinderWidgetComponent } from './experience-finder-widget.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ButtonsModule
];

const COMPONENTS = [
  ExperienceFinderWidgetComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: ExperienceFinderWidgetComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ExperienceFinderWidgetModule { }
