import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { TaCardModule } from '@hiptraveler/ui/ta-card';
import { ActionActiveModule } from '@hiptraveler/features/itinerary';
import { HighlightCardModule } from './highlight-card/highlight-card.module';
import { FollowProcessingModule } from './follow-processing.pipe';

import { StoryTravelAgentsComponent } from './story-travel-agents.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  TaCardModule,
  HighlightCardModule,
  ActionActiveModule,
  FollowProcessingModule
];

const COMPONENTS = [
  StoryTravelAgentsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    StoryTravelAgentsComponent
  ]
})
export class StoryTravelAgentsModule { }
