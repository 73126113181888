import { Component, ElementRef, HostListener, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { InputControlService } from './input-control.service';
import { EFAStateServiceService, ScreenValue, ScreenView } from '../../shared';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./styles/input-control.component.scss'],
  host: { class: 'experience-finder-ai--host-input-control disabled' },
  viewProviders: [ InputControlService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(150) ]
})
export class InputControlComponent implements AfterViewInit {

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

  contentInput: string = '';

  constructor(
    public service: InputControlService,
    public stateService: EFAStateServiceService
  ) { }

  private get screenView(): ScreenValue {
    return this.stateService.screenViewValue$$.value || 'screen1';;
  }

  ngAfterViewInit(): void {
    this.service.observeRequest();
    this.service.screenChangesObserver();
  }

  @HostListener('mouseenter', ['$event'])
  async onMouseEnter(): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    if (this.screenView !== 'screen1' || isMobile) return;
    setTimeout(() => this.stateService.scrollToScreen$$.next(ScreenView.screen2), 500);
  }

  sendMessage(keyUp?: boolean): void {

    if (!this.contentInput || this.stateService.inputPending$$.value) return;

    this.stateService.inputMessage$$.next(this.contentInput);
    this.stateService.appendConversation({ message: this.contentInput, sender: true });

    if (this.screenView === 'screen8' && !keyUp)
        this.stateService.formSubmit$.next();

    this.resetInputState();
  }

  async nextScreen(): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    const screenViewValue = this.stateService.screenViewValue$$.value || 'screen1';
    if ([ 'screen2', 'screen8' ].includes(screenViewValue) || isMobile) return;
    this.stateService.scrollToView$$.next('next');
  }

  resetInputState(): void {
    this.contentInput = '';
    this.inputField.nativeElement.value = this.contentInput;
    this.stateService.inputMessage$$.next(this.contentInput);
  }

}
