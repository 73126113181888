import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { SignInDto } from '@hiptraveler/data-access/api';
import { AuthAction } from '@hiptraveler/data-access/auth';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class FormService {
  
  form: FormGroup;
  pending$$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private dialog: MatDialog,
    private store: Store,
    private snackbar: SnackbarService
  ) { }

  initializeForm(): void {
    this.form = this.fb.group({
      username: [ '' ],
      password: [ '' ]
    });
  }

  async signInWithEmailAndPassword(): Promise<void> {
    if (this.pending$$.value || this.form.invalid) return;
    const form: SignInDto = this.form.value;
    this.pending$$.next(true);
    try {
      await firstValueFrom(this.store.dispatch(new AuthAction.Login(form)));
    } catch (error) {
      this.snackbar.open({ message: 'Incorrect email or password. Please try again.', duration: 5000 });
      this.pending$$.next(false);
    }
    finally {
      this.form.reset();
    }
  }
  
}
