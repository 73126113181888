import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { WINDOW, currentLang } from '@hiptraveler/common';
import { BrandState } from '@hiptraveler/data-access/brand';

@Injectable()
export class FooterService {

  constructor(
    @Inject(WINDOW) private window: any,
    private router: Router,
    private store: Store
  ) { }

  get hasTravelAgents$(): Observable<boolean> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map(state => !!state?.hasTravelAgents)
    );
  }

  navigate(option: 'dmo-solutions' | 'consumer-portal' | 'travel-agent' | 'ambassador'): void {
    switch (option) {
      case 'dmo-solutions': {
        this.window.open('https://www.hiptraveler.net/', '_blank');
        break;
      }
      case 'consumer-portal': {
        this.window.open('https://www.hiptraveler.com/', '_blank');
        break;
      }
      case 'travel-agent': {
        this.router.navigateByUrl(`/${currentLang()}/travel-agent-register`);
        break;
      }
    }
  }  

}
