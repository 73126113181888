import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { EFAStateServiceService } from '../../shared';
import { SummaryService } from './summary.service';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  host: { class: 'experience-finder-ai--host-summary experience-finder-ai--host-element' },
  viewProviders: [ SummaryService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFadeIn() ]
})
export class SummaryComponent implements OnInit {

  requesting: boolean;

  constructor(
    public stateService: EFAStateServiceService,
    public service: SummaryService
  ) { }

  ngOnInit(): void {
    this.service.screenExitObserver((v: boolean) => (this.requesting = v));
  }

}
