import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AuthDialogComponent } from './auth-dialog.component';
import { isWidget, pendingAuthProcessKey } from '@hiptraveler/common';

export const AUTH_DIALOG_ID = 'auth-login-dialog-overlay-wrapper-id';
export type DialogDataView = 'login' | 'register' | 'forgot-password';

@Injectable()
export class AuthDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(view: DialogDataView = 'login', callbackLabel?: string): MatDialogRef<AuthDialogComponent, unknown> | unknown {

    if (callbackLabel) {
      sessionStorage.setItem(pendingAuthProcessKey, callbackLabel);
    }

    const dialog = this.dialog.getDialogById(AUTH_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(AuthDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: AUTH_DIALOG_ID,
      panelClass: AUTH_DIALOG_ID.replace('-id', ''),
      // backdropClass: AUTH_DIALOG_ID.replace('-id', '-backdrop'),
      data: view,
      width: '460px',
      maxWidth: 'auto',
      minWidth: 0,
      minHeight: 0,
      maxHeight: '100vh',
      hasBackdrop: !isWidget(),
      scrollStrategy: this.overlay.scrollStrategies.noop() 
    });
  }

  close(): void {
    const dialog = this.dialog.getDialogById(AUTH_DIALOG_ID);
    if (dialog) return dialog.close();
  }

}
