import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, firstValueFrom } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { UserAction } from '@hiptraveler/data-access/user';
import { clientVID } from '@hiptraveler/common';

@Injectable()
export class VidSessionObserverService {

  constructor(
    private store: Store
  ) { }

  async observe(): Promise<void> {
    await firstValueFrom(this.store.select(BrandState.state).pipe(filter(e => !!e && !!clientVID())));
    this.store.dispatch(new UserAction.CheckVisitorSession);
  }

}
