import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { LocationData } from '@hiptraveler/data-access/api';
import { CDNReaderService, WINDOW } from '@hiptraveler/common';

export type TravelOptionBgStyle = Record<'visa'|'insurance'|'covid', string>;

@Component({
  selector: 'travel-options',
  templateUrl: './travel-options.component.html',
  styleUrls: ['./travel-options.component.scss'],
  host: { class: 'itinerary-travel-options' },
  viewProviders: [ CDNReaderService ],
  encapsulation: ViewEncapsulation.None
})
export class TravelOptionsComponent {

  selectedLocation: LocationData;

  constructor(
    @Inject(WINDOW) private window: any,
    private cdnReader: CDNReaderService
  ) { }

  get travelOptionBgStyle$(): Observable<TravelOptionBgStyle> {
    return this.cdnReader.getCdnData('base64.travelOptionsBackground');
  }

  /**
   * @method selectOption(field?: 'visaLink' | 'safetyLink'): void
   * Handles selection of a travel option and opens the appropriate link.
   * 
   * @param field Optional field name indicating which link to open ('visaLink' or 'safetyLink').
   *              If not specified, opens a default travel insurance link.
   */
  selectOption(field?: 'visaLink' | 'safetyLink'): void {
    if (!this.selectedLocation) return;

    if (!field) {
      this.window.open('https://www.worldnomads.com/', '_blank');
      return;
    }
    this.window.open(this.selectedLocation[field], '_blank');
  }

}
