import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { PromptDialogComponent } from './prompt-dialog.component';
import { ProcessingPipe } from './processing.pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  ButtonsModule
];

const COMPONENTS = [
  PromptDialogComponent
];

const PIPES = [
  ProcessingPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ]
})
export class PromptDialogModule { }
