import { Injectable } from '@angular/core';
import { Actions, Select, ofActionCompleted } from '@ngxs/store';
import { Observable, delay, filter, tap } from 'rxjs';

import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { isWidget } from '@hiptraveler/common';
import { DialogView } from './experience-finder.model';

@Injectable()
export class ExperienceFinderService {

  @Select(AuthState.authenticated) authenticated$: Observable<boolean>;

  #dialogView: DialogView = 'travel-style';

  constructor(
    private actions$: Actions,
    private stateService: ExperienceFinderStateService
  ) { }

  authenticatedObserver(): void {
    this.actions$.pipe(
      ofActionCompleted(
        AuthAction.Register,
        AuthAction.Login,
        AuthAction.AuthenticateWithFacebook,
        AuthAction.AuthenticateWithGoogle
      ),
      filter(e => e.result.successful && isWidget()),
      delay(150),
      tap(() => this.authenticatedView()),
    ).subscribe();
  }

  dialogViewObserver(): void {
    this.stateService.dialogView$$.asObservable().pipe(delay(0)).subscribe((dialogView: DialogView) => {
      this.#dialogView = dialogView;
    });
  }
  
  get isWidget(): boolean {
    return isWidget();
  }

  get dialogView(): DialogView {
    return this.#dialogView;
  }

  private authenticatedView(): void {
    this.stateService.dialogView$$.next('main-menu');
  }

}
