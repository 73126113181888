
<ng-content select="[featureCardImage]"></ng-content>

<ng-content select="feature-card-price"></ng-content>

<div matRipple class="feature-card--favorite-button" (click)="favClicked.emit()" *ngIf="noFavButton !== ''">
  <mat-progress-spinner class="primary favorite-button-spinner" mode="indeterminate" diameter="35.88"></mat-progress-spinner>
  <mat-icon svgIcon="common.favorite-svg"></mat-icon>
</div>

<ng-content select="feature-card-date"></ng-content>

<div class="feature-card--information" *ngIf="actionButton === ''">
  <ng-content select="itinerary-action-bubble"></ng-content>
  <ng-content select="feature-card-title"></ng-content>
  <ng-content select="feature-card-subtitle"></ng-content>
  <ng-content select="feature-card-description"></ng-content>
</div>

<div class="feature-card--itinerary-details" *ngIf="itineraryCard === ''">
  <ng-content select="[featureCardItineraryImage]"></ng-content>
  <ng-content select="feature-card-itinerary-title"></ng-content>
  <ng-content select="feature-card-itinerary-snippet"></ng-content>
  <div class="feature-card-itinerary-background"></div>
</div>

<div class="feature-card--details" *ngIf="actionCard === ''">

  <!-- /hotels -->
  <ng-content select="feature-card-location"></ng-content>
  
  <!-- /hotels -->
  <ng-content select="feature-card-rating"></ng-content>
    
  <!-- /hotels -->
  <div class="ratings" *ngIf="type === 'hotel'">
    <star-ratings [rating]="ratings?.length || 0" [size]="15" [offsetY]="-8"></star-ratings>
  </div>

  <!-- /hotels -->
  <ng-content select="feature-card-reviews"></ng-content>

  <!-- /thingstodo -->
  <div class="ratings" *ngIf="type === 'amusement'">
    <star-ratings [rating]="starRating || 0" [size]="15" [offsetY]="-8"></star-ratings>
  </div>

  <!-- /thingstodo -->
  <div class="no-content" *ngIf="type === 'amusement'"> - </div>
  
  <!-- /foodanddrink -->
  <ng-content select="[featureCardReviewImage]"></ng-content>

  <!-- /foodanddrink-end -->
  <div class="no-content" *ngIf="type === 'consume'"> - </div>
  
  <!-- /thingstodo -->
  <!-- /foodanddrink-end -->
  <ng-content select="feature-card-rating-reviews"></ng-content>
  
  <!-- /foodanddrink-end -->
  <div class="no-content" *ngIf="type === 'consume'"> - </div>
  
  <!-- /thingstodo -->
  <ng-content select="feature-card-duration"></ng-content>

</div>

<div class="feature-card--actions" *ngIf="actionCard === ''">
  <ui-action-button size="small" theme="white" elevation="2">{{ 'cards.read-more' | translate }}</ui-action-button>
  <ng-content select="itinerary-action-button"></ng-content>
</div>

<ng-container *ngIf="id | displayLoading | async">
  <div class="feature-card--progress" [@opacityFade]>
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
</ng-container>
