import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { SkyscannerWidgetDialogComponent as Component } from './skyscanner-widget-dialog.component';
import { SkyscannerWidgetDialogResult as DialogResult } from '.';

export const SKYSCANNER_WIDGET_DIALOG_ID = 'skyscanner-widget-dialog-overlay-wrapper-id';

@Injectable()
export class SkyscannerWidgetDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<Component, DialogResult> | void {
    const dialog = this.dialog.getDialogById(SKYSCANNER_WIDGET_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open<Component, undefined, DialogResult>(Component, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: SKYSCANNER_WIDGET_DIALOG_ID,
      panelClass: SKYSCANNER_WIDGET_DIALOG_ID.replace('-id', ''),
      // backdropClass: SKYSCANNER_WIDGET_DIALOG_ID.replace('-id', '-backdrop'),
      width: '700px',
      maxWidth: 'auto',
      minWidth: 0,
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }

}
