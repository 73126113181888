<div class="contact">

  <p class="contact--title" translate="terms-policy.contact"></p>
  
  <div class="contact--row">
    <p><strong>{{ 'terms-policy.location' | translate }}</strong></p> 
    <div>
      <p translate="terms-policy.hq"></p>
      <p>864 Grand Ave, Suite# 901</p>
      <p>San Diego CA 92109</p>
    </div>
  </div>
  
  <div class="contact--row">
    <p><strong>{{ 'terms-policy.press' | translate }}</strong></p>
    <p><a href="mailto:media@hiptraveler.com">media@hiptraveler.com</a></p>
  </div>
  
  <div class="contact--row">
    <p><strong>{{ 'terms-policy.copyright' | translate }}</strong></p>
    <p><a href="mailto:copyright@hiptraveler.com">copyright@hiptraveler.com</a></p>
  </div>
  
  <div class="contact--row">
    <p><strong>{{ 'terms-policy.investors' | translate }}</strong></p>
    <p><a href="mailto:investors@hiptraveler.com">investors@hiptraveler.com</a></p>
  </div>
  
  <div class="contact--row">
    <p><strong>{{ 'terms-policy.careers' | translate }}</strong></p>
    <p><a href="mailto:jobs@hiptraveler.com">jobs@hiptraveler.com</a></p>
  </div>

</div>
