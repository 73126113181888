
import { trigger, style, animate, transition } from '@angular/animations';

export const inOutCollapse = (height: number = 65.5, duration: number = 300) => trigger(
  `inOutCollapse${height.toString().replace('.', '')}`, 
  [
    transition(
      ':enter', 
      [
        style({ height: 0, opacity: 0 }),
        animate(
          `${duration}ms ease-out`,
          style({ height, opacity: 1 }) 
        )
      ]
    ),
    transition(
      ':leave', 
      [
        style({ height, opacity: 1 }),
        animate(
          `${duration}ms ease-in`, 
          style({ height: 0, opacity: 0 })
        ),
      ]
    )
  ]
);
