import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CustomInputBorderDirectiveModule } from '@hiptraveler/common';
import { UtilsModule } from '../utils';

import { FiltersMobileComponent } from './mobile.component';
import { ActivityInputOverlayDirective } from './activity-input-overlay/';
import { LocationInputOverlayDirective } from './location-input-overlay/';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatNativeDateModule,
  MatIconModule,
  MatFormFieldModule,
  MatDatepickerModule,
  TranslateModule
];

const LIBRARIES = [
  CustomInputBorderDirectiveModule,
  UtilsModule
];

const COMPONENTS = [
  FiltersMobileComponent
];

const DIRECTIVES = [
  ActivityInputOverlayDirective,
  LocationInputOverlayDirective
];


@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    FiltersMobileComponent
  ]
})
export class FiltersMobileModule { }
