import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './new-operator-dialog-action.service';

@Component({
  selector: 'app-new-operator-dialog',
  templateUrl: './new-operator-dialog.component.html',
  styleUrls: ['./new-operator-dialog.component.scss']
})
export class NewOperatorDialogComponent implements OnInit {

  certificate: string;
  id: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    public ref: MatDialogRef<NewOperatorDialogComponent>
  ) { }

  ngOnInit(): void {
    this.id = this.data.id;
    this.certificate = this.data.certificate;
  }

  saveCustomCertificate(): void {
    this.ref.close({ certificate: this.certificate, id: this.id })
  }

}
