import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { HT_COOKIE_POLICY_KEY, currentLang, getWindowRef, isWidget } from '@hiptraveler/common';

@Component({
  selector: 'cookie-policy-banner',
  templateUrl: './cookie-policy-banner.component.html',
  styleUrls: ['./cookie-policy-banner.component.scss'],
  host: { class: 'feature-cookie-policy-banner' },
  encapsulation: ViewEncapsulation.None
})
export class CookiePolicyBannerComponent implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.initializeUIState();
  }
  
  closeBanner(): void {
    this.element.nativeElement.style.display = 'none';
    getWindowRef()?.localStorage?.setItem(HT_COOKIE_POLICY_KEY, '1'); 
  }

  get policyPage(): string[] {
    return [ `/${currentLang()}/about-us/legal/privacyPolicy` ];
  }

  private initializeUIState(): void {
    setTimeout(() => {
      const cookiePolicyState = !!getWindowRef()?.localStorage?.getItem(HT_COOKIE_POLICY_KEY) || isWidget();
      this.element.nativeElement.style.display = cookiePolicyState ? 'none' : '';
    });
  }

}
