import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ToolbarModule } from '../site-navigation';
import { CoverImageModule } from './cover-image/cover-image.module';
import { UserProfileModule } from './user-profile/user-profile.module';

import { PageBannerComponent } from './page-banner.component';

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ToolbarModule,
  CoverImageModule,
  UserProfileModule
];

const COMPONENTS = [
  PageBannerComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    PageBannerComponent
  ]
})
export class PageBannerModule { }
