import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { TravelDetailsComponent } from './travel-details.component';
import { TravelDetailsStylesDirective } from './travel-details-styles.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  TravelDetailsComponent,
];

const DIRECTIVES = [
  TravelDetailsStylesDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    TravelDetailsComponent
  ]
})
export class TravelDetailsModule { }
