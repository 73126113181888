import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';

import { BrandAction } from '@hiptraveler/data-access/brand';
import { AppListenerService, getWindowRef, HT_FROM_WIDGET_SIGNOUT, LOCATION, setClientCId, setClientVId } from '@hiptraveler/common';
import { EFDialogService } from '@hiptraveler/features/experience-finder';
import { environment } from '@hiptraveler/environments';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-experience-finder-widget',
  template: `
    <ui-action-button (click)="ngOnInit()" *ngIf="localEnvironment">Re-open</ui-action-button>
  `,
  styleUrls: ['./experience-finder-widget.component.scss'],
  host: { class: 'experience-finder-widget' },
  viewProviders: [ EFDialogService ],
  encapsulation: ViewEncapsulation.None,
})
export class ExperienceFinderWidgetComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(LOCATION) private location: any,
    private store: Store,
    private appListener: AppListenerService,
    private widget: EFDialogService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.changeUiState(false);
    this.widget.open();
    this.setClientDefaults();
    await this.getBrandInfoData();
  }
  
  ngOnDestroy(): void {
    this.changeUiState(true);
    this.location.reload();
  }

  setClientDefaults(): void {

    setClientCId(this.route.snapshot.params?.['cId']);

    const fromWidgetSignout = getWindowRef()?.localStorage?.getItem(HT_FROM_WIDGET_SIGNOUT);

    if (!fromWidgetSignout) {
      setClientVId(this.route.snapshot.params?.['vId']);
    }

    fromWidgetSignout && getWindowRef()?.localStorage?.removeItem(HT_FROM_WIDGET_SIGNOUT)
  }

  async getBrandInfoData(): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new BrandAction.GetBrandInfo));
    } finally { }
  }
  
  changeUiState(state: boolean): void {
    this.appListener.appSiteNavVisibility$$.next(state);
    this.appListener.appFooterVisibility$$.next(state);
    this.appListener.experienceFinderVisibility$$.next(state);
  }

  get localEnvironment(): boolean {
    return environment.local || this.location.hostname.includes('localhost');
  }

}
