<form [formGroup]="service.form">

  <div customInputBorder targetElement="input" class="input-field">
    <input locationInputOverlay readonly type="text" name="location" autocomplete="off" placeholder="Location" formControlName="location">
  </div>

  <div customInputBorder targetElement="input" class="input-field" (click)="service.openCalendar(picker)" *ngIf="!(view.communityVisibility$ | async)">
    <input type="text" autocomplete="off" name="travel-dates" placeholder="Travel Dates" readonly [value]="service.form.value | toLocalTime">
    <ng-container *ngIf="startDateFilter.value">
      <mat-icon class="input-field--close" [@opacityFade] (click)="service.clearCalendarField()">close</mat-icon>
    </ng-container>
    <mat-form-field appearance="fill">
      <mat-date-range-input [rangePicker]="picker" [min]="minDate">
        <input matStartDate name="check-in-date" formControlName="checkInDate" #startDateFilter>
        <input matEndDate name="check-out-date" formControlName="checkOutDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker panelClass="search-page-root-filter-calendar-picker" (closed)="service.hideOverlay()" #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

  <div customInputBorder targetElement="input" class="input-field" [ngClass]="{ active: selectedLength$ | async }">
    <input readonly activityInputOverlay type="text" autocomplete="off" name="query" [placeholder]="listPlaceholder$ | async" formControlName="query">
  </div>

</form>
