import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, delay, map, takeUntil } from 'rxjs';

import { FilestackService, FSProgressEvent, PickerFileMetadata } from '@hiptraveler/core/filestack';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class CoverImageService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup,
    private filestack: FilestackService,
    private snackbar: SnackbarService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get coverImage$(): Observable<string | null> {
    return this.form.valueChanges.pipe(
      map(e => e.cover?.imgUrl),
      delay(0),
      takeUntil(this.subscription$)
    );
  }

  addCoverImage(dialogRef: MatDialogRef<any>, form: FormGroup): void {

    this.snackbar.open({ message: 'Please wait.', duration: Infinity });

    const onOpen = () => {
      dialogRef.disableClose = true;
      console.log('@@@ ', 'picker opened');
      setTimeout(() => this.snackbar.dismiss(), 500);
    };
    const onCancel = () => {
      console.log('@@@ ', 'picker cancelled');
    };
    const onClose = () => {
      dialogRef.disableClose = false;
      console.log('@@@ ', 'onClose');
    };
    const onFileUploadFinished = (metadata: PickerFileMetadata) => {

      const urlBase = 'https://cdn.filestackcontent.com';
      const urlId = metadata.url.replace(`${urlBase}/`, '');

      form.get('cover')?.patchValue({
        imgUrl: `${urlBase}/resize=width:1024,fit:scale/auto_image/compress/${urlId}`,
        imgThumbnail: `${urlBase}/resize=width:400,height:400,fit:crop/auto_image/compress/${urlId}`,
        hasCover: true
      })
    };
    const onFileUploadProgress = (_: unknown, event: FSProgressEvent) => {
      console.log('@@@ ', 'onFileUploadProgress');
    };
    const onFileSelected = () => {
      console.log('@@@ ', 'onFileSelected');
    };
    const onFileUploadCancel = () => {
      console.log('@@@ ', 'onFileUploadCancel');
    };
    const onFileUploadFailed = () => {
      console.log('@@@ ', 'onFileUploadFailed');
    };
    const onFileUploadStarted = () => {
      console.log('@@@ ', 'onFileUploadStarted');
    };
    const onUploadDone = () => {
      console.log('@@@ ', 'onUploadDone');
    };
    const onUploadStarted = () => {
      console.log('@@@ ', 'onUploadStarted');
    };

    this.filestack.openFilestackPicker({
      onOpen: onOpen,
      onCancel: onCancel,
      onClose: onClose,
      onFileUploadFinished: onFileUploadFinished,
      onFileUploadProgress: onFileUploadProgress,
      onFileSelected: onFileSelected,
      onFileUploadCancel: onFileUploadCancel,
      onFileUploadFailed: onFileUploadFailed,
      onFileUploadStarted: onFileUploadStarted,
      onUploadDone: onUploadDone,
      onUploadStarted: onUploadStarted,
    })
  }

}
