import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ButtonVisibilityPipeModule, ToggleFavoriteDirectiveModule } from '../common';

import { ResultActionsComponent } from './result-actions.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  ToggleFavoriteDirectiveModule,
  ButtonVisibilityPipeModule
];

const COMPONENTS = [
  ResultActionsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ResultActionsComponent
  ]
})
export class ResultActionsModule { }
