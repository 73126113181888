import { AfterViewInit, Directive, ElementRef } from "@angular/core";

import { TranslationService } from "@hiptraveler/common";

@Directive()
export class CardReviews implements AfterViewInit {

  constructor(
    private translate: TranslationService,
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngAfterViewInit(): void {

    const text1 = this.translate.getText('cards', 'based-on');
    const text2 = this.translate.getText('cards', 'reviews');
    const textContent = this.element.nativeElement.firstElementChild?.textContent;

    if (textContent === `${text1} 0 ${text2}`) {
      this.element.nativeElement.style.opacity = '0';
    }
  }

}
