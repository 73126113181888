import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ScrollElementBorderDirectiveModule } from '@hiptraveler/common';

import { ListPanelComponent } from './list-panel.component';
import { TypeFilterPipe } from './type-filter.pipe';
import { SelectionPipe } from './selection.pipe';
import { ItemNamePipe } from './item-name.pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  ScrollElementBorderDirectiveModule
];

const COMPONENTS = [
  ListPanelComponent
];

const PIPES = [
  TypeFilterPipe,
  SelectionPipe,
  ItemNamePipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    ListPanelComponent
  ]
})
export class ListPanelModule { }
