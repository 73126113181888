import { Directive, ElementRef, Input, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { Map, GeoJSON, Marker, LatLng, DivIcon } from "leaflet";
import { Subject, fromEvent, takeUntil, tap } from "rxjs";
import { flatten } from 'lodash';
import { valid } from "geojson-validation";

import { SearchState } from "@hiptraveler/data-access/search";
import { ItineraryState } from "@hiptraveler/data-access/itinerary";
import { DialogType } from "@hiptraveler/dialogs/search-result-dialog";
import { LeafletMapControlStateService } from "./leaflet-map-control-state.service";
import { GeoJSONFeatureCollection, GeoJSONProperties, getWindowRef, leafletNamespaceKey } from "@hiptraveler/common";
import { MarkerIcon, MarkerIconRecord, SetMarkerOptions } from "./leaflet-map.interface";
import * as LeafletUtil from "./leaflet-map.util";
import * as LeafletValue from "./values";
import * as LeafletTemplate from "./templates";

const MUTATION_OBSERVER_CONFIG: MutationObserverInit = { childList: true, subtree: true };

@Directive()
export class LeafletMap implements OnDestroy {

  @Input() id: string;

  mutation$ = new Subject<void>();
  selectedMarker$ = new Subject<string>();
  subscription$ = new Subject<void>();
  mapView?: Map;
  mapMarker: Marker;
  geoJSONCollection: GeoJSON<any>[] = [];
  markerIcon: MarkerIconRecord;
  searchPageMarkerIcon: MarkerIcon;

  constructor(
    protected router: Router,
    protected elementRef: ElementRef<HTMLElement>,
    protected leafletControl: LeafletMapControlStateService
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get Leaflet(): any {
    return getWindowRef()[leafletNamespaceKey];
  }

  ngOnDestroy(): void {
    this.mapView?.remove();
    this.mapView = undefined;
    this.subscription$.next();
  }

  private setMarkersAndTemplates(): void {

    LeafletTemplate.setNamespace(this.Leaflet);

    this.markerIcon = LeafletValue.createMarkerIcons(this.Leaflet);
    this.searchPageMarkerIcon = LeafletValue.createSearchPageMarkerIcons(this.Leaflet);
  }

  setMap(): void {
    
    this.setMarkersAndTemplates();

    this.mapView = this.Leaflet?.map(this.id);
    this.setMapView([ 0, 0 ], 2);

    this.Leaflet?.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.mapView);
  }

  setMapView(coordinates: any, zoom: number = 15): void {
    if (Number.isNaN([...coordinates][0])) return;
    this.mapView && this.mapView.setView(coordinates, zoom, LeafletValue.zoomPanOptions);
  }

  setMarker(option: SetMarkerOptions): void {
    this.mapMarker?.remove();
    this.mapMarker = this.Leaflet?.marker(option.location, { icon: option.icon, zIndexOffset: 1000 });

    option.data?.image
      ? this.mapMarker.bindPopup(LeafletTemplate.getMarkerPopUpTemplate({
        id: option.data.id || '',
        title: LeafletUtil.parseTextData(option.data.title),
        location: LeafletUtil.parseTextData(option.data.locationName),
        image: option.data.image || ''
      }))
      : this.mapMarker.bindPopup(LeafletTemplate.getProfileMarkerPopUpTemplate({
        id: option.data.id || '',
        location: option.data.location as any
      }));

    option?.popup && this.mapMarker?.openPopup();

    this.mapView && this.mapMarker?.addTo(this.mapView);
  }

  setGeoJSON(result: GeoJSONFeatureCollection<GeoJSONProperties>, icon: DivIcon): void {

    LeafletUtil.parseGeoJson(result);

    if (!valid(result)) return;

    const geoJSONRef = this.Leaflet?.geoJSON(result, {
      pointToLayer: (value: any, latlng: LatLng) => this.Leaflet?.marker(latlng, { icon }).bindPopup(
        value.properties?.image
          ? LeafletTemplate.getMarkerPopUpTemplate({
            id: value.properties.id || '',
            title: LeafletUtil.parseTextData(value.properties.title),
            location: LeafletUtil.parseTextData(value.properties.location),
            image: value.properties.image || 'assets/img/blank.webp'
          })
          : LeafletTemplate.getProfileMarkerPopUpTemplate({
            id: value.properties.id || '',
            location: LeafletUtil.parseTextData(value.properties.location)
          })
      )
    }).addTo(this.mapView!);

    this.geoJSONCollection.push(geoJSONRef);
  }

  clearAllLayers(): void {
    this.leafletControl.allSearchResultsData$$.next([]);
    this.mapMarker?.remove();
    this.geoJSONCollection.forEach((res) => {
      res.remove();
      res.clearLayers();
    });
  }

  observePopupPane(element: HTMLElement): void {

    const popUpPane = element.querySelectorAll('.leaflet-popup-pane').item(0);
    if (!popUpPane) return;

    const observer = new MutationObserver((mutationsList: MutationRecord[]) => {
      this.mutation$.next();
      for (let mutation of mutationsList) {
        mutation.type === 'childList' && mutation.addedNodes.forEach((addedNode: any) => {
          if (!addedNode?.classList?.contains('ht-leaflet-popup')) return;
          
          setTimeout(() => this.observeEventClick(addedNode.querySelectorAll('h2').item(0)), 200);
        })
      }
    });

    observer.observe(popUpPane, MUTATION_OBSERVER_CONFIG);
  }

  private observeEventClick(element: HTMLElement): void {
    fromEvent(element, 'click').pipe(
      tap(() => {
        const identifier = element.parentElement!.classList.item(1) || '';
        this.selectedMarker$.next(identifier);
      }),
      takeUntil(this.mutation$)
    ).subscribe();
  }

  searchResultByStoreAndId(store: Store, id: string): { result: any; type: DialogType } {

    const searchAdventures = store.selectSnapshot(SearchState.adventures);
    const searchFood = store.selectSnapshot(SearchState.foods);
    const searchHotels = store.selectSnapshot(SearchState.hotels);

    const arrayMap = store.selectSnapshot(ItineraryState.actDateArr);
    const arrayMapAdventures = arrayMap?.map(e => e.ImgArray?.filter(x => !x.imgCategory.includes('Food'))) || [];
    const arrayMapFoods = arrayMap?.map(e => e.ImgArray?.filter(x => x.imgCategory.includes('Food'))) || [];
    const arrayMapHotels = arrayMap?.map(e => e.HotelArray) || [];

    const adventure = searchAdventures ? searchAdventures.find(e => e.id === id) : flatten(arrayMapAdventures).find(e => e?.id === id);
    const food = searchFood ? searchFood.find(e => e.id === id) : flatten(arrayMapFoods).find(e => e?.id === id);
    const hotel = searchHotels ? searchHotels.find(e => e.id === id) : flatten(arrayMapHotels).find(e => e?.id === id);

    const result = [ adventure, hotel, food ].filter(Boolean)[0];
    const type: DialogType = adventure ? 'adventure' : hotel ? 'hotel' : food ? 'food' : 'adventure';

    return { result, type };
  }

}
