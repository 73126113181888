import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as ProfileApi from './interfaces';
import { brandCampaignId, getWindowRef } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class ActivityApiService {

  constructor(
    private http: HttpClient
  ) { }

  setUserAction(payload: ProfileApi.SetUserActionDto, param?: ProfileApi.SetUserActionDto): Observable<ProfileApi.SetUserActionResponse> {

    const urlParam = param?.objectId || param?.actionId || brandCampaignId();
    const apiEndpoint = `/trip-planner/setUserAction/${urlParam}`;

    return this.http.post<ProfileApi.SetUserActionResponse>(apiEndpoint, {
      campgnId: brandCampaignId(),
      campaign: param?.actionId ? `${brandCampaignId()}-${param?.actionId}` : brandCampaignId(),
      source: getWindowRef()?.location?.href,
      ...payload
    });
  }

}
