import { Injectable, Provider } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, takeUntil } from 'rxjs';

import { RequestCancellationService } from '@hiptraveler/common';

const targetEndpoints = [
  '/trip-planner/profile/stories/',
  '/trip-planner/getUserBoards/',
  '/trip-planner/getMyLikes/',
  '/trip-planner/uploads/',
  '/trip-planner/followUsers/',
  '/trip-planner/following/',
];

@Injectable()
export class _ProfileSearchRequestCancellationInterceptor implements HttpInterceptor {

  constructor(
    private requestCancellation: RequestCancellationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return targetEndpoints.some((path: string) => request.url.includes(path))
      ? next.handle(request).pipe( takeUntil(this.requestCancellation.profileSearchCancellationSignal$) )
      : next.handle(request);
  }

}

export const ProfileSearchRequestCancellationInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _ProfileSearchRequestCancellationInterceptor,
  multi: true
}
