import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {

  transform(value: number, fractionDigits: number = 2): number {
    return parseFloat(value.toFixed(fractionDigits));
  }

}

@NgModule({
  declarations: [ ToFixedPipe ],
  exports:      [ ToFixedPipe ]
})
export class ToFixedPipeModule { }
