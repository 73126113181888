import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrandLogoPulseModule } from '@hiptraveler/ui/brand-logo-pulse';

import { BrandHeaderComponent } from './brand-header.component';
import { TextTypedDirective } from './text-typed.directive';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  BrandLogoPulseModule
];

const COMPONENTS = [
  BrandHeaderComponent
];

const DIRECTIVES = [
  TextTypedDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    BrandHeaderComponent
  ]
})
export class BrandHeaderModule { }
