import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { ExperienceFinderV2Component } from './experience-finder-v2.component';

const MODULES = [
  CommonModule,
  MatButtonModule
];

const COMPONENTS = [
  ExperienceFinderV2Component
];

@NgModule({
  imports: [
    MODULES,
    RouterModule.forChild([{
      path: '', component: ExperienceFinderV2Component
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ExperienceFinderV2Module { }
