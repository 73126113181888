import { NgModule } from '@angular/core';

import { MetaTagDirective } from './meta-tag.directive';

const DIRECTIVES = [
  MetaTagDirective
];

@NgModule({
  declarations: [
    DIRECTIVES
  ],
  exports: [
    MetaTagDirective
  ]
})
export class MetaTagModule { }
