import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SkyscannerViewModule } from '@hiptraveler/features/skyscanner-view';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { SkyscannerWidgetDirectiveModule } from '@hiptraveler/common';

import { SkyscannerWidgetDialogComponent } from './skyscanner-widget-dialog.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  SkyscannerViewModule,
  DialogCloseButtonModule,
  SkyscannerWidgetDirectiveModule
];

const COMPONENTS = [
  SkyscannerWidgetDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class SkyscannerWidgetDialogModule { }
