
export enum EditableSelectionStatus {
  ImageUpload,
  VideoUrl,
  InsertText
}

export interface Coordinates { top: string; left: string; left1: string; left2: string; }

export interface EventListener { event: string, handler: (event: any) => void };
