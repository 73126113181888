import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { AppListenerService, Environment, ENVIRONMENT, globalStoreKey } from '@hiptraveler/common';

@Injectable()
export class BrowserStorageInitService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(ENVIRONMENT) private environment: Environment,
    private appListener: AppListenerService
  ) { }

  initialize(): void {
    if (isPlatformServer(this.platformId)) return;
    
    this.prepareGlobalStore();
    this.disposeStateStoreKeys();
  }
  
  private prepareGlobalStore(): void {
    const globalStoreValue: Record<string, any> = JSON.parse(sessionStorage.getItem(globalStoreKey) || '{}');
    this.appListener.globalStore$$.next(globalStoreValue);
  }

  private disposeStateStoreKeys(): void {
    if (!isPlatformBrowser(this.platformId) || this.environment.local) return;

    const allKeys = Object.keys(localStorage).filter(e => e.includes('hiptraveler:'));

    allKeys.forEach((key: string) => {
      localStorage.removeItem(key)
    });
  }

}
