import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject, filter, takeUntil } from 'rxjs';

import { FormService } from './form.service';
import { PlaceResult, initializeGoogleMapsPlacesAutocomplete } from '@hiptraveler/common';

@Directive({
  selector: '[locationFieldInput]'
})
export class LocationFieldInputDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef<HTMLInputElement>,
    public service: FormService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {

    isPlatformBrowser(this.platformId) && initializeGoogleMapsPlacesAutocomplete(this.element, { default: true }).pipe(
      filter(Boolean),
      takeUntil(this.subscription$)
    ).subscribe((response: PlaceResult) => {
      this.service.form.patchValue({ userLoc: response?.formatted_address, location: response?.formatted_address });
    });
  }

}
