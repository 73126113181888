import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, map, skip, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { DialogType } from '../../common';

@Directive({
  selector: '[authorImageVisibility]'
})
export class AuthorImageVisibilityDirective implements AfterViewInit {

  @Input() dialogType: DialogType;

  constructor(
    private store: Store,
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngAfterViewInit(): void {
    if (this.element.nativeElement.tagName !== 'DIV') return;

    this.store.select(ItineraryState.imageInformation)?.pipe(
      skip(1),
      map((e: ImageResultData | null) => {
        if (this.dialogType === 'adventure') {
          return !e?.imgAuthInfo;
        } else if (this.dialogType === 'food') {
          return !e?.yelpData?.yelpUrl;
        }
        return !e?.imgAuthInfo;
      }),
      take(1),
      filter(Boolean)
    ).subscribe(() => (this.element.nativeElement.style.display = 'none'));
  }

}
