import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollToViewDirectiveModule } from '@hiptraveler/common';

import { ToolbarComponent } from './toolbar.component';
import { OptionHighlightDirective } from './option-highlight.directive';
import { HeadersPipe } from './headers.pipe';
import { OptionNamePipe } from './option-name.pipe';

const MODULES = [
  CommonModule,
  RouterModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ScrollToViewDirectiveModule
];

const COMPONENTS = [
  ToolbarComponent
];

const DIRECTIVES = [
  OptionHighlightDirective
];

const PIPES = [
  HeadersPipe,
  OptionNamePipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule { }
