import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CustomInputBorderDirectiveModule } from '@hiptraveler/common';

import { ListButtonComponent } from './list-button.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  CustomInputBorderDirectiveModule
];

const COMPONENTS = [
  ListButtonComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ListButtonComponent
  ]
})
export class ListButtonModule { }
