import { NgModule, Component, ViewEncapsulation, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlubStylesDirective } from './blub-styles.directive';

@Component({
  selector: 'brand-logo-pulse',
  template: `
    <div class="blub" blubStyles [size]="+size" [blub2Scale]="0.8" [blub3Scale]="0.6">
      <div class="blub-1" [ngClass]="{ paused }"></div>
      <div class="blub-2" [ngClass]="{ paused }"></div>
      <div class="blub-3" [ngClass]="{ paused }"></div>
      <img src="assets/img/logos/white-HT-logo.webp">
    </div>
  `,
  styleUrls: ['./brand-logo-pulse.component.scss'],
  host: { class: 'ui-brand-logo-pulse' },
  encapsulation: ViewEncapsulation.None
})
export class BrandLogoPulseComponent {
  @Input() paused: boolean;
  @Input() size: string = '150';
}

@NgModule({
  imports:      [ CommonModule ],
  declarations: [ BlubStylesDirective, BrandLogoPulseComponent ],
  exports:      [ BrandLogoPulseComponent ]
})
export class BrandLogoPulseModule { }
