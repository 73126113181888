import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, delay } from 'rxjs';

import { NavbarButtonData, NavbarToolbarPosition } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class NavbarControlStateService {

  /* An RxJS Subject for terminating the processing (progress-spinner) UI state of the active navbar action buttons. */
  navbarToolbarActionComplete$$ = new Subject<void>();
  
  /* An RxJS Subject used to listen for navbar profile clicks. */
  navbarProfileClicked$$ = new Subject<void>();
  
  /* A BehaviorSubject employed to set the page title in the navbar. */
  navbarTitleState$$ = new BehaviorSubject<string>('');

  /**
   * A BehaviorSubject utilized to manage the visibility of the navbar brand logo:
   * • 'true' indicates displaying the navbar brand logo
   * • 'false' indicates hiding the navbar brand logo
   */
  navbarLogoVisibility$$ = new BehaviorSubject<boolean>(true);

  /**
   * A BehaviorSubject is used to control the visibility of the navbar location input field:
   * • 'true' displays the input field.
   * • 'false' hides the input field.
   */
  navbarSearchBarVisibility$$ = new BehaviorSubject<boolean>(true);
  navbarSearchBarVisibility$ = this.navbarSearchBarVisibility$$.asObservable().pipe(delay(0))

  /**
   * A BehaviorSubject controls the visibility of the toolbar component.
   * • 'true' displays the toolbar component.
   * • 'false' hides the toolbar component.
   */
  navbarToolbarVisibility$$ = new BehaviorSubject<boolean>(true);
  navbarToolbarVisibility$ = this.navbarToolbarVisibility$$.asObservable().pipe(delay(0))

  /**
   * A BehaviorSubject is utilized to manually set the elevation in the navbar when the toolbar is hidden.
   * • 'true': Elevation is added to the navbar.
   * • 'false': Elevation is removed from the navbar.
   */
  navbarElevationState$$ = new BehaviorSubject<boolean>(true);
  navbarElevationState$ = this.navbarElevationState$$.asObservable().pipe(delay(0))

  /**
   * A BehaviorSubject controls the toolbar UI state.
   * 'none' - Default UI state with the navbar below the toolbar.
   * 'navbar' - The toolbar overlays the navbar when scrolled down.
   */
  navbarToolbarPosition$$ = new BehaviorSubject<NavbarToolbarPosition>('none');
  navbarToolbarPosition$ = this.navbarToolbarPosition$$.asObservable();
  
  /**
   * A BehaviorSubject for managing the disabled state of the active navbar action buttons' UI:
   * • 'true' indicates an enabled state.
   * • 'false' indicates a disabled state,
   */
  navbarActionButtonState$$ = new BehaviorSubject<boolean>(true); // Toggle en/disable button UX
  navbarActionButtonState$ = this.navbarActionButtonState$$.asObservable().pipe(delay(0));
  
  /**
   * A BehaviorSubject targets the "mobileView$" observable in the NavbarComponent,
   * conditioning it to enable or disable the observables's reactivity to client width changes.
   * 'true' - Allow the observable to react to the client width changes.
   * 'false' - Prevent the observable to react to the client width changes.
   */
  allowMobileView$$ = new BehaviorSubject<boolean>(true);
  allowMobileView$ = this.allowMobileView$$.asObservable();
  
  /**
   * A BehaviorSubject utilized for adding, removing, and modifying navbar action buttons.
   * Please refer to the interface for detailed information.
   */
  activeNavbarActionButtons$$ = new BehaviorSubject<NavbarButtonData[] | null>(null);
  activeNavbarActionButtons$ = this.activeNavbarActionButtons$$.asObservable().pipe(delay(100));

  setActiveNavbarButtons(navbarButtons: NavbarButtonData[]): void {
    this.activeNavbarActionButtons$$.next(navbarButtons);
  }

}
