import { DEFAULT_BRAND_NAME, SUPPORTED_LANG, SupportedLanguage, getWindowRef, subdomain } from "@hiptraveler/common";

export function validateRootPath(url: string): boolean {
  return url === '/' || SUPPORTED_LANG.map(e => `/${e}`).includes(url);
}

export function validateUrl(url?: string): boolean {

  if (!url) return false;

  const lang = url?.split('/')[1] as SupportedLanguage;

  return SUPPORTED_LANG.includes(lang);
}

export function serverLang(url?: string): SupportedLanguage {

  if (!url) return SUPPORTED_LANG[0];

  return url?.split('/')[1] as SupportedLanguage;
}

export function serverLocale(url: string): string {
  switch (serverLang(url)) {
    case 'en':  return 'en-US';
    case 'es':  return 'es-MX';
    case 'de':  return 'de-DE';
    case 'fr':  return 'fr-FR';
    case 'it':  return 'it-IT';
    case 'zh':  return 'zh-CN';
    default:    return 'en-US';
  }
}

export function hasLocationByPath(path: string): boolean {
  return !!path?.split('/')?.[3];
}

export function getLocationByPath(path: string): string {

  const location = path?.split('/')?.[3];
  const defaultLocation = subdomain() === DEFAULT_BRAND_NAME ? '' : brandName();

  return location?.[0].toUpperCase() + location?.slice(1) || defaultLocation;
}

export function brandName(): string {
  return subdomain()[0].toUpperCase() + subdomain().slice(1);
}

export function getPageTitle(): string {
  const pathname: string = getWindowRef()?.location?.pathname || '';
  return pathname.split('?')[0].split('#')[0].split('/').reverse()[0];
}

export function joinWithAnd(value: string[]): string {

  let joinedString = value.join(', ');

  const lastCommaIndex = joinedString.lastIndexOf(', ');
  if (lastCommaIndex !== -1) {
    joinedString = joinedString.substring(0, lastCommaIndex) + ' and ' + joinedString.substring(lastCommaIndex + 2);
  }

  return joinedString;
}