import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ClientActionComponent } from './client-action.component';

const MODULES = [
  CommonModule,
  MatIconModule
];

const COMPONENTS = [
  ClientActionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ClientActionComponent
  ]
})
export class ClientActionModule { }
