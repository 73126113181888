import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';

@Pipe({
  name: 'multiDayTourDate'
})
export class MultiDayTourDatePipe implements PipeTransform {

  /**
   * Transforms the activity data to format the date.
   * 
   * @param {ActivityDateData} activity The activity data to format.
   * @returns {string} The formatted date string.
   */
  transform(activity: ActivityDateData, option?: 'label'): string {

    if (!activity.hasMultiDayTour) return activity?.date?.day?.toString();

    const date = +activity?.date?.day;
    const tours = Object.values(activity?.multiDayTourList || {})[0];

    const fromDate = date ? date : +tours[0];
    const toDate = date ? (fromDate + Object.values(activity?.multiDayTourList || {}).length) : +tours[tours.length-1];

    if (!fromDate || !toDate) return '';

    const multiDateString = `${fromDate}-${toDate}`;
    return option === 'label' ? `Day ${multiDateString}` : multiDateString;
  }

}

@NgModule({
  declarations: [ MultiDayTourDatePipe ],
  exports:      [ MultiDayTourDatePipe ]
})
export class MultiDayTourDateModule { }
