import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';

import { FormComponent } from './form.component';
import { CustomStylesDirective } from './custom-styles.directive';
import { SelectDisplayPipe } from './select-display.pipe';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatIconModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatButtonModule,
  TranslateModule
];

const LIBRARIES = [
  CustomMapAutocompleteModule
];

const COMPONENTS = [
  FormComponent
];

const DIRECTIVES = [
  CustomStylesDirective
];

const PIPES = [
  SelectDisplayPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    FormComponent
  ]
})
export class FormModule { }
