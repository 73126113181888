import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { FiltersModule } from './filters/filters.module';
import { ItemSelectionModule } from './item-selection/item-selection.module';
import { ActionButtonModule } from '../shared';

import { TravelStyleComponent } from './travel-style.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  FiltersModule,
  ItemSelectionModule,
  ActionButtonModule
];

const COMPONENTS = [
  TravelStyleComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TravelStyleComponent
  ]
})
export class TravelStyleModule { }
