<div class="client-action--info">

  <p class="client-action--info-highlight">
    <ng-content select="[infoHighlight]"></ng-content>
  </p>
  <p class="client-action--info-details">
    <ng-content select="[infoDetails]"></ng-content>
  </p>

</div>

<div class="client-action--actions">

  <div class="client-action--action-button" matRipple [ngClass]="{ disabled: leftButtonLimit }" (click)="service.removeCount(index)">
    <mat-icon>remove</mat-icon>
  </div>

  <p class="client-action--action-info">
    <ng-content select="[actionInfo]"></ng-content>
  </p>

  <div class="client-action--action-button" matRipple (click)="service.addCount(index)">
    <mat-icon>add</mat-icon>
  </div>

</div>
