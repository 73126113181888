import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanUrlText'
})
export class CleanUrlTextPipe implements PipeTransform {

  transform(value?: string | null): string | null | undefined {
    return value?.startsWith('//') ? value.slice(2) : value;
  }

}
