import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Experience } from '@hiptraveler/data-access/api';
import { CarouselFilterType, CarouselService } from '../../shared';

@Pipe({
  name: 'selection'
})
export class SelectionPipe implements PipeTransform {

  constructor(
    private carousel: CarouselService
  ) { }
  
  transform(selectedFilter: CarouselFilterType): Observable<Partial<Experience>[]> {
    return this.carousel.getDataByFilter(selectedFilter) as Observable<Partial<Experience>[]>;
  }
  
}
