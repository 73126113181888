import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityDayComponent } from './activity-day.component';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  ActivityDayComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityDayComponent
  ]
})
export class ActivityDayModule { }
