import { NgModule } from '@angular/core';

import { ScrollToViewDirective } from './scroll-to-view.directive';

const DIRECTIVES = [
  ScrollToViewDirective
];

@NgModule({
  declarations: [
    DIRECTIVES,
  ],
  exports: [ ScrollToViewDirective ]
})
export class ScrollToViewDirectiveModule { }
