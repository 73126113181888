<form class="settings--password-assistance" [formGroup]="stateFormService.passwordAssistanceForm">
    
  <h2 translate="profile.pass-assist"></h2>

  <input autocomplete="username" name="email" type="email" style="display: none;">

  <mat-form-field>
    <mat-label>{{ 'profile.new-pass' | translate }}</mat-label>
    <input matInput autocomplete="new-password" name="password" type="password" formControlName="password" #newPassword>
    <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility(newPassword)">
      <mat-icon>{{ newPassword.type === 'password' ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="service.invalidPassword">{{ 'profile.invalid-pass' | translate }}</mat-error>
  </mat-form-field>
  
  <mat-form-field>
    <mat-label>{{ 'profile.confirm-new-pass' | translate }}</mat-label>
    <input matInput autocomplete="new-password" name="password" type="password" formControlName="confirm_password" #confirmPassword>
    <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility(confirmPassword)">
      <mat-icon>{{ confirmPassword.type === 'password' ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="service.passwordMismatch">{{ 'profile.check-pass-match' | translate }}</mat-error>
  </mat-form-field>

</form>

<div class="settings--delete-account">

  <h2 translate="profile.delete-acc"></h2>

  <div class="settings--delete-account-actions">
    <ui-action-button theme="action" (click)="service.deleteAccount()">{{ 'profile.delete' | translate }}</ui-action-button>
  </div>

</div>
