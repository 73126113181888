import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ToolbarModule } from '@hiptraveler/features/site-navigation';
import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { UploadProfilePhotoDirectiveModule } from './../common';

import { BannerComponent } from './banner.component';
import { UploadCoverPhotoDirective } from './upload-cover-photo.directive';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ToolbarModule,
  ButtonsModule,
  UploadProfilePhotoDirectiveModule
];

const COMPONENTS = [
  BannerComponent
];

const DIRECTIVES = [
  UploadCoverPhotoDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    BannerComponent
  ]
})
export class BannerModule { }
