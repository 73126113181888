import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SearchResultDialogStateService } from '../../common';

import { ImageCarouselService } from './image-carousel.service';

@Component({
  selector: 'image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  host: { class: 'image-carousel' },
  viewProviders: [ ImageCarouselService ],
  encapsulation: ViewEncapsulation.None
})
export class ImageCarouselComponent {
  
  @Input() hotel: string;
  @Input() image: string;

  constructor(
    public service: ImageCarouselService,
    public stateService: SearchResultDialogStateService
  ) { }

}
