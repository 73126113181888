import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as ImageApi from './interfaces';
import * as API from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class ImageApiService {

  constructor(
    private http: HttpClient
  ) { }

  getImageInformation({ id }: API.RequestByIdDto): Observable<ImageApi.GetImageInformationResponse> {
    return this.http.get<ImageApi.GetImageInformationResponse>(`/trip-planner/getImageInfo/${brandCampaignId()}/${id}/${currentLang()}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getYelpContent({ id }: API.RequestByIdDto): Observable<ImageApi.GetYelpContentResponse> {
    return this.http.get<ImageApi.GetYelpContentResponse>(`/trip-planner/getYelpContent/${id}/${currentLang()}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  toggleLikeItinerary({ id, hipType }: API.ToggleLikeItineraryDto, type: 'image' | 'hotel'): Observable<API.ToggleLikeItineraryResponse> {
    const payload = { hipType, cId: brandCampaignId(), vId: clientVID() };
    return this.http.post<API.ToggleLikeItineraryResponse>(`/trip-planner/hipIt/${type}/${id}`, API.toFormData(payload)).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

}
