import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';

import { AuthAction, RESET_PASSWORD_KEY } from '@hiptraveler/data-access/auth';
import { SnackbarService } from '@hiptraveler/snackbar';
import { RegexPattern, currentLang } from '@hiptraveler/common';

function passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.get('password');
  const confirmPassword = control.get('confirm');

  if (password?.value !== confirmPassword?.value) {
    confirmPassword?.setErrors({ 'passwordMismatch': true })
    return null;
  }

  return null;
}

@Injectable()
export class CreatePasswordService {
  
  form: FormGroup;
  successListener$$ = new Subject<void>();
  pending$$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private i18n: TranslateService,
    private snackbar: SnackbarService
  ) { }
  
  initializeForm(): void {
    this.form = this.fb.group({
      password: [ '', [ Validators.required, Validators.pattern(RegexPattern.password) ] ],
      confirm: [ '', [ Validators.required ] ],
    }, { validator: passwordMatchValidator });
  }

  private get resetPasswordSessionData(): any {
    const sessionData = sessionStorage.getItem(RESET_PASSWORD_KEY);
    return sessionData ? JSON.parse(sessionData) : {};
  }

  async verifyOtpCode(): Promise<void> {
    if (this.pending$$.value || this.form.invalid) return;
    this.pending$$.next(true);
    try {
      await firstValueFrom(this.store.dispatch(new AuthAction.ChangePassword(
        this.resetPasswordSessionData?.token || '',
        this.form.value.confirm
      )));
      this.successListener$$.next();
    } catch (response: any) {
      this.pending$$.next(false);
      const message = this.i18n.translations?.[currentLang()]?.['snackbar']?.['something-went-wrong'];
      response?.error && this.snackbar.open({ message: message || response.error });
    } finally {
      this.form.reset();
    }
  }

}
