import { NgModule } from '@angular/core';

import { ItineraryActionPanelModule } from './itinerary-action-panel';
import { ItineraryActionBubbleModule } from './itinerary-action-bubble/itinerary-action-bubble.module';
import { ItineraryActionButtonModule } from './itinerary-action-button/itinerary-action-button.module';

const LIBRARIES = [
  ItineraryActionPanelModule,
  ItineraryActionBubbleModule,
  ItineraryActionButtonModule
];

@NgModule({
  imports: [
    LIBRARIES
  ],
  exports: [
    ItineraryActionPanelModule,
    ItineraryActionBubbleModule,
    ItineraryActionButtonModule
  ]
})
export class ItineraryActionModule { }
