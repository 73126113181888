import { ElementRef, Injectable } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

import { EditableSelectionsComponent, EditableSelectionStatus } from '.';
import { insertImageWithSrc, insertVideoIframe } from './insert-editor-content';
import { ComponentState, ComponentStateService } from '@hiptraveler/features/itinerary';
import { getWindowRef, mediumEditorNamespaceKey, TranslationService } from '@hiptraveler/common';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { FilestackPickerService } from '..';
import { PickerFileMetadata } from '@hiptraveler/core/filestack';

@Injectable()
export class ContentEditableService {

  mediumEditor?: MediumEditor.MediumEditor;
  editableSelectionsComponent?: EditableSelectionsComponent;
  editableSelectionStatus: EditableSelectionStatus = EditableSelectionStatus.InsertText;
  showVideoLinkPlaceholder: boolean = false;
  yPosition: string = '0';
  subscription$ = new Subject<void>();
  
  constructor(
    private componentState: ComponentStateService,
    private filestackPicker: FilestackPickerService,
    private promptDialog: PromptDialogActionService,
    private i18n: TranslationService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  /**
   * Initialize medium editor to the component view
   *
   * @param {ElementRef<HTMLDivElement>} element - Reference view element
   * @returns {MediumEditor} Medium editor component instance
   */
  private initializeMediumEditor(element: ElementRef<HTMLDivElement>): MediumEditor.MediumEditor | undefined {
    const me = getWindowRef()[mediumEditorNamespaceKey];
    if (!me) return undefined;
    return new me(element.nativeElement, {
      placeholder: {
        text: this.i18n.getText('compose', 'editor-placeholder'),
        hideOnClick: false
      },
    });
  }

  /**
   * Medium editor setup bootstrap function
   *
   * @param {ElementRef<HTMLDivElement>} editable - Reference to the view element
   * @param {EditableSelectionsComponent} editableSelectionsComponent - Reference to the component instance
   * @param {Subject<void>} subscription$ - Reference to the component's subscription subject for observable unsubscription
   * @returns {void}
   */
  mediumEditorObserver(
    editable: ElementRef<HTMLDivElement>,
    editableSelectionsComponent: EditableSelectionsComponent
  ): void {

    this.editableSelectionsComponent = editableSelectionsComponent;

    this.eventListener(editable);

    this.mediumEditor = this.initializeMediumEditor(editable);

    this.mediumEditor?.subscribe('editableInput', (_, editable: HTMLElement) => {
      this.componentState.patch({ storyContent: editable.innerHTML });
    });

    this.componentState.value$.subscribe((value: ComponentState) => {
      const storyContent = value?.storyContent;

      if (!editable.nativeElement.innerHTML) {
        editable.nativeElement.innerHTML = storyContent || '';
      }

      storyContent && editable.nativeElement.removeAttribute('data-placeholder');
    });
  }
  
  /**
   * Event listener listens to keyboard inputs and executes specific tasks for key inputs.
   *
   * @param {ElementRef<HTMLDivElement>} editable - Reference to the view element
   * @returns {void}
   */
  private eventListener(editable: ElementRef<HTMLDivElement>): void {

    const keypressHandler = async (event: KeyboardEvent) => {
      if (!(event.key === 'Enter' && this.editableSelectionStatus === EditableSelectionStatus.VideoUrl)) return;
      this.showVideoLinkPlaceholder = false;
      try {
        await insertVideoIframe();
      } catch (response: any) {

        const clipboardErrMess = `Error: Failed to execute 'readText' on 'Clipboard': Read permission denied.`;
        if (response?.stack !== clipboardErrMess) return;

        this.mediumEditor?.execAction('undo');
        this.mediumEditor?.execAction('undo');
        const dialogRef = this.promptDialog.open({
          title: 'itinerary.permission-required',
          message: 'itinerary.clipboard-access-message',
          buttons: [
            { name: 'itinerary.close-button', theme: 'secondary', executable: () => dialogRef.close() }
          ]
        })
      }
    };
    editable.nativeElement.addEventListener('keypress', keypressHandler);

    const keyupHandler = (event: KeyboardEvent) => {
      const selection = getWindowRef()?.getSelection();
      const validSelection = selection && selection?.anchorNode;
      if (event.key === 'Backspace' && validSelection) {
        const element = selection?.anchorNode as HTMLParagraphElement;
        if (element.firstElementChild && element.firstElementChild.tagName === 'IFRAME') element.remove();
      }
    };
    editable.nativeElement.addEventListener('keyup', keyupHandler);

    this.subscription$.asObservable().subscribe(() => {
      editable.nativeElement.removeEventListener('keyup', keyupHandler)
      editable.nativeElement.removeEventListener('keypress', keypressHandler)
    });
  }
  
  /**
   * Listens to the Medium text editor's status changes.
   *
   * @param {EditableSelectionStatus} status - Medium text editor status types
   * @returns {void}
   */
  editableSelectionStatusChange(status: EditableSelectionStatus): void {
    this.editableSelectionStatus = status;
    this.showVideoLinkPlaceholder = status === EditableSelectionStatus.VideoUrl;

    setTimeout(this.setCursorOnPlaceholderClick.bind(this));

    if (status === EditableSelectionStatus.ImageUpload && this.editableSelectionsComponent) {
      this.filestackPicker.openFilestackPicker('editor-image', (metadata: PickerFileMetadata) => {
        const compressedImageUrl = `https://cdn.filestackcontent.com/resize=width:1024,fit:scale/auto_image/compress/${metadata.handle}`;
        insertImageWithSrc(compressedImageUrl, metadata.filename);
        this.componentState.patch({ storyContent: this.mediumEditor?.getContent(0) });
        this.editableSelectionsComponent!.service.showSelection$$.next(false);
        this.editableSelectionsComponent!.service.hostState = false;;
      });
    }
  }

  private setCursorOnPlaceholderClick(): void {

    const editable = (this.mediumEditor as any)?.['origElements'] as HTMLDivElement;
    const placeholder = editable.parentElement!.getElementsByClassName('host--content-videoUrl-placeholder').item(0) as HTMLParagraphElement;

    placeholder && fromEvent(placeholder, 'click').pipe(takeUntil(this.subscription$)).subscribe(() => {

      const highlight = editable?.getElementsByClassName('highlight').item(0) as HTMLParagraphElement;
      
      const range = document.createRange();
      range.setStart(highlight!, 0);
      range.collapse(true);
  
      const selection = window.getSelection();
      selection!.removeAllRanges();
      selection!.addRange(range);
  
      editable?.focus();
    });
  }

}
