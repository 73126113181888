import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { NavbarControlStateService } from '@hiptraveler/common';
import { ComponentState, ComponentStateService } from '@hiptraveler/features/itinerary';
import { FilestackPickerOption, FilestackPickerService } from '../../common';
import { PickerFileMetadata } from '@hiptraveler/core/filestack';

@Component({
  selector: 'story-image',
  templateUrl: './story-image.component.html',
  styleUrls: ['./story-image.component.scss']
})
export class StoryImageComponent {

  @Input() componentState$$: Observable<ComponentState>;
  @Input() itineraries: string[] = [];

  constructor(
    public navbarControl: NavbarControlStateService,
    public filestackPicker: FilestackPickerService,
    private componentState: ComponentStateService,
  ) { }

  /**
   * Opens the Filestack file picker with the given options.
   * 
   * @param {FilestackPickerOption} option - The options to configure the file picker.
   * @returns {void}
   */
  openFilePicker(option: FilestackPickerOption): void {
    this.filestackPicker.openFilestackPicker(option, (bannerImage: PickerFileMetadata) => {
      this.componentState.patch({ bannerImage })
    });
  }

}
