export type Selection = 'facebook' | 'instagram' | 'youtube' | 'twitter';

export const defaultSelection: Selection = 'facebook';
// export const defaultSelection: Selection = 'instagram';
// export const defaultSelection: Selection = 'youtube';
// export const defaultSelection: Selection = 'twitter2';

let _instagramDisplay: boolean = true;

export function getInstagramDisplay(): boolean {
  return _instagramDisplay;
}

export function setInstagramDIsplay(display: boolean): void {
  _instagramDisplay = display;
}
