import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { SearchPageControlStateService } from '@hiptraveler/common';

@Injectable()
export class ItineraryStateService {

  dispatchPending$$ = new BehaviorSubject<boolean>(false);
  dispatchPending$ = this.dispatchPending$$.asObservable();

  constructor(
    private store: Store,
    public searchPageControl: SearchPageControlStateService
  ) {
    this.searchPageControl.featureCardProcessing$.subscribe((res) => {
      console.log('@@@ activity-day-list :: featureCardProcessing$', res);
    });
    this.dispatchPending$.subscribe((res) => {
      console.log('@@@ activity-day-list :: dispatchPending$', res);
    });
    this.store.select(AuthState.authenticated).subscribe((res) => {
      console.log('@@@ activity-day-list :: AuthState.authenticated', res);
    });
    this.store.select(UserState.authenticated).subscribe((res) => {
      console.log('@@@ activity-day-list :: UserState.authenticated', res);
    });
  }

  get processing$(): Observable<boolean> {
    return combineLatest([
      this.searchPageControl.featureCardProcessing$,
      this.dispatchPending$,
      this.store.select(AuthState.authenticated),
      this.store.select(UserState.authenticated),
    ]).pipe(
      map(([ featureCardProcessing, dispatchPending, authState, userState ]) =>
        featureCardProcessing
        || dispatchPending
        || (authState && !userState)
      )
    );
  }

}
