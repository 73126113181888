import { ComponentRef, ViewContainerRef } from '@angular/core';

import { ExperienceFinderAiComponent } from '@hiptraveler/features/exp-finder-ai';

export type ExpFinderPanelAction = ExpFinderPanelActionService | null;

export function expFinderPanelAction(): ExpFinderPanelActionService {
  return new ExpFinderPanelActionService();
}

export class ExpFinderPanelActionService {

  private panelRef: ComponentRef<ExperienceFinderAiComponent> | null;
  private panelLoaded: boolean = false;

  async openExperienceFinderPanel(container: ViewContainerRef): Promise<void> {

    if (this.panelLoaded) {
      console.warn('Experience Finder panel is already open. Skipping duplicate initialization.');
      return;
    }

    this.panelLoaded = true;

    try {
      container.clear();
      await import('./host/experience-finder-ai.module');
      const resolver = await import('./host/experience-finder-ai.component');
      this.panelRef = container.createComponent(resolver.ExperienceFinderAiComponent);
    } catch (e) { }
  }

  closeExperienceFinder(): void {

    if (!this.panelRef) return;

    this.panelRef.destroy();
    this.panelRef = null;
    this.panelLoaded = false;
  }

}
