import { SearchStateModule } from './search-state';
import { ItineraryStateModule } from './itinerary-state';
import { ProfileStateModule } from './profile-state';
import { EventStateModule } from './event-state';

export const ProviderModules = [
  SearchStateModule,
  ItineraryStateModule,
  ProfileStateModule,
  EventStateModule
];
