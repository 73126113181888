import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShellModule } from './shell.module';
import { ProfileComponentModules } from './common';

import { ProfileComponent } from './profile.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ShellModule,
  ProfileComponentModules
];

const COMPONENTS = [
  ProfileComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class ProfileModule { }
