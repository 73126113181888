import { NgModule } from '@angular/core';

import { SearchSvgPathPipe } from './search-svg-path.pipe';

const PIPES = [
  SearchSvgPathPipe
];

@NgModule({
  declarations: PIPES,
  exports: [ SearchSvgPathPipe ]
})
export class SearchSvgPathPipeModule { }
