import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HipTravelerFooterModule } from './hiptraveler-footer/footer.module';
import { NonHipTravelerFooterModule } from './non-hiptraveler-footer/footer.module';

import { FooterNavigationComponent } from './footer-navigation.component';

const MODULES = [
  CommonModule,
  RouterModule,
  TranslateModule
];

const LIBRARIES = [
  HipTravelerFooterModule,
  NonHipTravelerFooterModule,
];

const COMPONENTS = [
  FooterNavigationComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    FooterNavigationComponent
  ]
})
export class FooterNavigationModule { }
