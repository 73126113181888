<div class="user-info-mobile" [@opacityFade]>

  <div class="user-info-mobile--image-wrapper mat-elevation-z4" [class.processing]="stateService.uploadImageProcessing$$ | async">
    <ng-container *ngIf="service.id$ | async;else notAuthenticated">
      <img class="user-info-mobile--image" [lazyLoad]="(service.picUrl$ | async)!" [defaultImage]="authenticatedProfilePlaceholder" [errorImage]="authenticatedProfilePlaceholder" draggable="false" alt="Profile image"> 
    </ng-container>
    <ng-template #notAuthenticated>
      <img class="user-info-mobile--image" [lazyLoad]="(service.picUrl$ | async)!" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" alt="Profile image"> 
    </ng-template>
  </div>

  <ng-container *ngIf="service.profileDetails$ | async as user">
    <div class="user-info-mobile--details" [@opacityFade]>
      <p>{{ user.firstName }} {{ user.lastName }}</p>
      <p> <mat-icon>location_on</mat-icon> {{ user.userLoc }}</p>
    </div>
  </ng-container>

</div>

<div class="user-info-mobile-spacer"></div>
