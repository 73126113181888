<div class="gallery-img--highlight-container">

  <div class="gallery-img--action" matRipple uploadImages>
    <mat-icon>add</mat-icon>
    <p translate="custom-activity-dialog.section.text.add-images"></p>
  </div>

  <ng-container *ngFor="let image of service.galleryImages$ | async; let i = index">
    <div class="gallery-img--highlight">
      <button mat-icon-button (click)="service.removeImageByIndex(i)">
        <mat-icon>close</mat-icon>
      </button>
      <img [lazyLoad]="image" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Cover image"> 
    </div>
  </ng-container>

</div>
