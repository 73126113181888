import { Router } from "@angular/router";
import { currentLang } from "@hiptraveler/common";

import { SnackbarService } from "@hiptraveler/snackbar";

export function handleGetItineraryRequestError(router: Router, snackbar: SnackbarService): void {
  router.navigate([ '/', currentLang(), 'experiences' ]);
  setTimeout(() => {
    snackbar.open({ message: 'Itinerary not found. Please try again.', duration: 5000 });
  }, 2000);
}
