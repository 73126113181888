import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, map, skip, take } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';

@Component({
  selector: 'contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
  host: { class: 'content' },
  encapsulation: ViewEncapsulation.None
})
export class ContactInformationComponent implements OnInit {

  @Input() hotelInformation$: Observable<HotelResultData | null>;
  @Input() imageInformation$: Observable<ImageResultData | null>;

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    (this.hotelInformation$ as any)?.pipe(
      skip(1),
      map((response: HotelResultData) => {
        const visibility = !!response?.addInfo;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
    (this.imageInformation$ as any)?.pipe(
      skip(1),
      map((response: ImageResultData) => {
        const visibility = !!response?.addInfo;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
  }

}
