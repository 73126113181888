import { Component } from '@angular/core';

import { AboutSectionService } from './about-section.service';

@Component({
  selector: 'about-section',
  templateUrl: './about-section.component.html',
  viewProviders: [ AboutSectionService ]
})
export class AboutSectionComponent {

  constructor(
    public service: AboutSectionService
  ) { }

}
