import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchPageControlStateService } from '@hiptraveler/common';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';

@Injectable()
export class FavoritesTabService {

  @Select(ItineraryState.actDate) actDate$: Observable<ActivityDateData[] | null>;

  constructor(
    public searchPageControl: SearchPageControlStateService,
    public searchResultDialog: SearchResultDialogActionService
  ) { }
  
}
