
export const PLACEHOLDER_ATTR = 'data-placeholder';

/**
 * Sets a highlight to the selected cursor element, handling both standard and iframe-based scenarios.
 *
 * @param {Element | null} cursorElement - The current cursor element.
 * @param {Selection | undefined} selection - The current text selection (optional).
 *
 * @remarks
 * - This function orchestrates highlighting for both standard and Medium Editor iframe elements.
 * - It delegates to specific functions for handling each case.
 * - It ensures consistent highlighting behavior across different Medium Editor contexts.
 */
export function setHighlightCursorElement(cursorElement: Element | null, selection?: Selection): void {
  setHighlightToSelectedIfameCursorElement(cursorElement, selection);
  setHighlightToSelectedCursorElement(cursorElement);
}

/**
 * Sets a highlight to the selected cursor element within a Medium Editor iframe.
 * 
 * @param {Element | null} cursorElement - The current cursor element.
 * @param {Selection | undefined} selection - The current text selection.
 * 
 * @remarks
 * - This function is specifically designed to handle highlighting within Medium Editor iframes.
 * - It addresses potential issues with nested iframes and ensures accurate highlighting of the intended element.
 */
function setHighlightToSelectedIfameCursorElement(cursorElement: Element | null, selection?: Selection): void {
  const includesMediumEditor = cursorElement?.classList?.toString().includes('medium-editor-element');
  const firstElementChild = (selection?.anchorNode as HTMLElement)?.firstElementChild as Element;

  if (includesMediumEditor && firstElementChild?.tagName === 'IFRAME') {
    const contentEditable = document.getElementsByClassName('medium-editor-element')[0].children;
    for (const child of Array.from(contentEditable)) {
      child.classList.remove('highlight');
    }
    getRootCursorElement(firstElementChild)?.classList?.add('highlight');
  }
}

/**
 * Sets a highlight style to the selected cursor element within a Medium Editor instance.
 *
 * @param {Element | null} cursorElement - The element at the cursor's position.
 *
 * @remarks
 * - If the cursor is within a Medium Editor element, no action is taken.
 * - The `highlight` class is removed from all elements within the Medium Editor.
 * - The `highlight` class is added to the root element of the cursor's position.
 * - Assumes a single Medium Editor instance on the page.
 */

function setHighlightToSelectedCursorElement(cursorElement: Element | null): void {
  const includesMediumEditor = cursorElement?.classList?.toString().includes('medium-editor-element');
  if (includesMediumEditor) return;
  const contentEditable = document.getElementsByClassName('medium-editor-element')[0].children;
  for (const child of Array.from(contentEditable)) {
    child.classList.remove('highlight');
  }
  getRootCursorElement(cursorElement)?.classList?.add('highlight');
}

/**
 * Retrieves the root cursor element.
 *
 * @param cursorElement The element where the cursor is currently located.
 * @returns The root cursor element, or null if not found.
 */
export function getRootCursorElement(cursorElement: Element | null): HTMLElement {

  if (cursorElement?.parentElement?.classList?.toString().includes('medium-editor-element')) {
    return cursorElement as HTMLElement;
  }

  let currentElement = cursorElement?.parentElement;
  while (currentElement) {
    if (currentElement?.parentElement?.classList?.toString().includes('medium-editor-element')) break;
    currentElement = currentElement.parentElement;
  }
  return currentElement as HTMLElement;
}

// For testing purposes only
export function logContent(editable: HTMLDivElement, event: string): void {
  return
  console.log('@@@ ', event, editable.children);
  [...(editable.children as any)].forEach((element: any) => {
    console.log('@@@ ', element.innerHTML, element);
  });
  console.log('@@@ ', '-----');
}
