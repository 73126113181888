import { Component, Input } from '@angular/core';

@Component({
  selector: 'navbar-action',
  template: `
    <div class="mobile-navbar-view--action" [class.icon]="iconLeft">
      <mat-icon *ngIf="iconLeft">{{ iconLeft }}</mat-icon>
      <p><ng-content></ng-content></p>
    </div>
  `,
  styleUrls: ['./navbar-action.component.scss']
})
export class NavbarActionComponent {

  @Input() iconLeft: string;

}
