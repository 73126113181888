import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { LocationInputOverlayComponent } from './location-input-overlay.component';

export const LOCATION_OFFERS_ID = 'location-overlay';

@Injectable()
export class LocationInputOverlayActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<LocationInputOverlayComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(LOCATION_OFFERS_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(LocationInputOverlayComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: LOCATION_OFFERS_ID,
      panelClass: LOCATION_OFFERS_ID + '-panel',
      backdropClass: LOCATION_OFFERS_ID + '-backdrop',
      width: '100vw',
      maxWidth: 'auto',
      minWidth: 0,
      height: 'calc(100vh - 70px)',
      scrollStrategy: this.overlay.scrollStrategies.block()
    })
  }

}
