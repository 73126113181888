import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { SendMessageDialogComponent } from './send-message-dialog.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  ButtonsModule
];

const COMPONENTS = [
  SendMessageDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class SendMessageDialogModule { }
