import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class SearchResultDialogStateService {

  selectedHighlight$$ = new BehaviorSubject<string>('');
  selectedHighlight$ = this.selectedHighlight$$.asObservable();

  favoriteProcessing$$ = new BehaviorSubject<boolean>(false);
  favoriteProcessing$ = this.favoriteProcessing$$.asObservable();

  constructor(
    private store: Store
  ) { }

  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;
  @Select(ItineraryState.hotelInformation) hotelInformation$: Observable<HotelResultData>;

  get favoriteActive$(): Observable<boolean> {
    const userId = this.store.selectSnapshot(UserState.id) || '';
    return this.store.select(ItineraryState.imageInformation).pipe(
      filter(Boolean),
      map((imageInformation: ImageResultData) => (imageInformation.userLikes || []).map(e => e.slice(0, 7)).includes(userId.slice(0, 7)))
    );
  }

  get hotelFavoriteActive$(): Observable<boolean> {
    const userId = this.store.selectSnapshot(UserState.id) || '';
    return this.store.select(ItineraryState.hotelInformation).pipe(
      filter(Boolean),
      map((hotelInformation: HotelResultData) => (hotelInformation.likes || []).map(e => e.slice(0, 7)).includes(userId.slice(0, 7)))
    );
  }

}
