import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../../common';

@Component({
  selector: 'tour-reviews',
  templateUrl: './tour-reviews.component.html',
  styleUrls: ['./tour-reviews.component.scss'],
  animations: [ opacityFade(animationDelay) ]
})
export class TourReviewsComponent {

  @Input() data?: Observable<ImageResultData>;

}
