import { Directive, ElementRef, HostListener, NgModule } from '@angular/core';

import { FiltersService } from './filters.service';

@Directive({
  selector: '[formListener]'
})
export class FormListenerDirective {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private filtersService: FiltersService
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement
  }

  @HostListener('click', ['$event'])
  handleKeyDown(event: PointerEvent) {

    const form = event.target as HTMLFormElement;

    if (form.tagName === 'INPUT') return;

    this.filtersService.overlayState$$.next(false);
  }

}

@NgModule({
  declarations: [ FormListenerDirective ],
  exports:      [ FormListenerDirective ]
})
export class FormLocationDirectiveModule { }
