import { getWindowRef, showdownNamespaceKey } from '@hiptraveler/common';

export function decodeHTMLEntities(text: string) {
  var tempElement = document.createElement('div');
  tempElement.innerHTML = text;
  return tempElement.textContent || tempElement.innerText;
}

export function limitString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength - 3) + "...";
  }
}

export function minifyHTML(htmlString: string): string {
  return htmlString
    .replace(/\n/g, '')
    .replace(/\t/g, '')
    .replace(/>\s+</g, '><')
    .replace(/\s{2,}/g, ' ');
}

export function markdownToHTML(markdown: string): string {
  const Converter = getWindowRef()?.[showdownNamespaceKey]?.Converter;
  const converter = new Converter();
  return converter.makeHtml(markdown);
}

export function HTMLToMarkdown(html: string): string {
  const Converter = getWindowRef()?.[showdownNamespaceKey]?.Converter;
  const converter = new Converter();
  return converter.makeMarkdown(html);
}
