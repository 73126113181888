<div class="itinerary-feature-card--action">
  <ng-content select="itinerary-activity-duration"></ng-content>
  <div class="itinerary-feature-card--action-buttons" *ngIf="buttonActions">
    <button mat-icon-button (click)="positionMoved.emit('up')">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button mat-icon-button (click)="positionMoved.emit('down')">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>

<div class="itinerary-feature-card--itinerary-image">
  <ng-content select="[itineraryImage]"></ng-content>
</div>

<div class="itinerary-feature-card--content">

  <div class="itinerary-feature-card--content-title">
    <ng-content select="itinerary-title"></ng-content>
    <div class="ratings" *ngIf="type === 'hotel'">
      <mat-icon svgIcon="itinerary-feature-card.star-svg" *ngFor="let _ of ratings;"></mat-icon>
    </div>
  </div>

  <div class="itinerary-feature-card--content-subtitle">
    <ng-content select="itinerary-subtitle"></ng-content>
  </div>
  
  <div class="itinerary-feature-card--content-description">
    <ng-content select="itinerary-description"></ng-content>
  </div>

  <div class="itinerary-feature-card--content-reviews">

    <!-- Left pane -->
    <ng-content select="itinerary-rating"></ng-content>
    <ng-content select="[itineraryReviewImage]"></ng-content>
    <div class="ratings" *ngIf="type === 'amusement'">
      <ng-container *ngFor="let option of starRating | halfRating;">
        <mat-icon svgIcon="itinerary-feature-card.star-svg" *ngIf="option === 1"></mat-icon>
        <mat-icon svgIcon="itinerary-feature-card.star-off-svg" *ngIf="option === 0"></mat-icon>
        <mat-icon svgIcon="itinerary-feature-card.star-half-svg" *ngIf="option === 2"></mat-icon>
      </ng-container>
    </div>
    
    <!-- Right pane -->
    <ng-content select="itinerary-reviews"></ng-content>
    <ng-content select="itinerary-rating-reviews"></ng-content>
    
  </div>
  
  <div class="itinerary-feature-card--content-actions">
    <ng-content select="itinerary-button"></ng-content>
    <ng-content select="itinerary-button"></ng-content>
    <ng-content select="itinerary-action-button"></ng-content>
  </div>

</div>
