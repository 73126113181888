import { StateContext } from '@ngxs/store';
import { EMPTY, catchError, forkJoin } from 'rxjs';

import * as API from '@hiptraveler/data-access/api';
import { BrandStateModel } from '..';

interface Option {
  ctx: StateContext<BrandStateModel>;
  brandApi: API.BrandApiService;
  data: Partial<API.BrandInfo>;
}

export function patchBrandInfoResponse({ ctx, brandApi, data }: Option): void {

  const handleError = () => {
    ctx.patchState({ ...data });
    return EMPTY;
  };

  if (data?.hasCustomLocations && data?.hasCustomExperiences) {
    forkJoin([
      brandApi.getCustomBrandData('locations', data.brandCampaign?.cId),
      brandApi.getCustomBrandData('filters', data.brandCampaign?.cId)
    ]).pipe(
      catchError(handleError)
    ).subscribe(([ { locations }, { experiences } ]) => ctx.patchState({ ...data, cLocations: locations, experiences }));
  }

  if (data?.hasCustomLocations && !data?.hasCustomExperiences) {
    forkJoin([
      brandApi.getCustomBrandData('locations', data.brandCampaign?.cId),
      brandApi.getCustomBrandData('filters', 'hiptraveler')
    ]).pipe(
      catchError(handleError)
    ).subscribe(([ { locations }, { experiences } ]) => ctx.patchState({ ...data, cLocations: locations, experiences }));
  }

  if (!data?.hasCustomLocations && data?.hasCustomExperiences) {
    brandApi.getCustomBrandData('filters', data.brandCampaign?.cId).pipe(
      catchError(handleError)
    ).subscribe(({ experiences }) => ctx.patchState({ ...data, experiences }));
  }

  if (!data?.hasCustomLocations && !data?.hasCustomExperiences) {
    forkJoin([
      brandApi.getCustomBrandData('filters', 'hiptraveler')
    ]).pipe(
      catchError(handleError)
    ).subscribe(([ { experiences } ]) => ctx.patchState({ ...data, experiences }));
  }
}
