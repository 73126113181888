import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ExternalLinkDirectiveModule } from '@hiptraveler/common';

import { ChecklistComponent } from './checklist.component';
import { ChecklistBoxStatePipe } from './checklist-box-state.pipe';
import { MouseTransitionDirective } from './mouse-transition.directive';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  ExternalLinkDirectiveModule
];

const COMPONENTS = [
  ChecklistComponent
];

const PIPES = [
  ChecklistBoxStatePipe
];

const DIRECTIVES = [
  MouseTransitionDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES,
    DIRECTIVES
  ],
  exports: [
    ChecklistComponent
  ]
})
export class ChecklistModule { }
