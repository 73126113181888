import { Location } from "@angular/common";
import { StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { assign, reduce } from 'lodash';

import * as API from "@hiptraveler/data-access/api";
import * as Helper from '.';
import { ItineraryStateModel } from "..";
import { NavbarControlStateService } from "@hiptraveler/common";

interface Value {
  location: Location;
  store: Store;
  ctx: StateContext<ItineraryStateModel>;
  navbarControl: NavbarControlStateService;
  data: API.UpdateItineraryActivityDto;
  response: API.UpdateItineraryActivityResponse;
  itineraryActivityId?: string;
}

const updateActions: string[] = [ 'add-item', 'remove-item', 'remove-item-chill', 'add-item-remove-old-item', 'update-location' ];

export function processUpdateItineraryActivity({ ctx, store, location, navbarControl, response, data, itineraryActivityId }: Value): void {

  API.validateResponse(response, { ignoreKey: 'actDateMap' });

  if (data.action === 'delete-itinerary') {
    ctx.setState(patch<ItineraryStateModel>({ basicInfo: patch<any>({ hasItinerary: false }) }));
  }
  if (data.action === 'delete-day') {
    ctx.patchState({ actDateMap: response.actDateMap });
    Helper.replaceComposeUrlByNewItineraryResponse(location, response.newPageTitle);
    Helper.updateUserItineraryByNewItineraryResponse(store, data.id, response);
  }
  if (data.action === 'add-all-hotel') {
    ctx.setState(patch<ItineraryStateModel>({ actDateMap: response.actDateMap }));
    Helper.updateUserItineraryByNewItineraryResponse(store, data.id, response);
  }
  if (updateActions.includes(data.action)) {

    const source = response.actDateMap[data?.day || 1];
    let days: any = [ data.day ];

    if (source?.hasMultiDayTour && source?.multiDayTourList && data?.item) {
      days = source.multiDayTourList[data.item]
    }

    const actDateMapArr = days.filter(Boolean).map((day: number) => ({
      [day]: response.actDateMap[day]
    }));

    const result = reduce(actDateMapArr, (acc, obj) => assign(acc, obj), {});

    if (itineraryActivityId && ctx.getState()?.basicInfo?.id !== itineraryActivityId) return;

    ctx.setState(patch<ItineraryStateModel>({
      actDateMap: patch<any>(result)
    }));

    Helper.updateUserItineraryByNewItineraryResponse(store, data.id, response);
  }
  if (data.action === 'add-item') {
    Helper.updateAddMyTripUrl(navbarControl, response.newPageTitle);
  }

}
