import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_PROGRESS_DATA: boolean[] = [ false, false, false, false ];

@Injectable({
  providedIn: 'root'
})
export class PromptDialogStateService {

  progress$$ = new BehaviorSubject<boolean[]>(DEFAULT_PROGRESS_DATA);
  progress$ = this.progress$$.asObservable();

  setProgressToDefault(): void {
    this.progress$$.next(DEFAULT_PROGRESS_DATA);
  }

  updateButtonState(index: number, state: boolean): void {
    const progressStates = [ ...this.progress$$.value ];
    progressStates[index] = state;
    this.progress$$.next(progressStates);
  }

}
