import { getWindowRef } from "./utilities";

export function isPopUpBlocked(): boolean {
  const newWindow = getWindowRef()?.open('', '_blank', 'height=100,width=100');

  if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
    return true;
  } else {
    newWindow.close();
    return false;
  }
}
