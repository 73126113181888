import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { BrandCampaign, CampaignCustomCss } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { PlanTripService } from './plan-trip.service';
import { FormComponent } from './form/form.component';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'plan-trip',
  template: `

    <ng-container *ngIf="service.externalViewState$ | async">
      <external-view [@opacityFade]></external-view>
    </ng-container>

    <ng-container *ngIf="service.processingViewState$ | async">
      <processing-view [@opacityFade]></processing-view>
    </ng-container>

    <img
      class="plan-trip--display-photo object"
      [src]="customStyles?.tripPlannerSvg"
      *ngIf="customCss$ | async as customStyles;else defaultImageView">

    <ng-template #defaultImageView>
      <div class="plan-trip--display-photo default" [ngStyle]="{ 'background-image': defaultImage }"></div>
    </ng-template>

    <plan-trip-form></plan-trip-form>

    <action-button (click)="formComponent.submit()">{{ 'exp-finder.button-text-view-results' | translate }}</action-button>
  `,
  styleUrls: ['./plan-trip.component.scss'],
  host: { class: 'experience-finder-screen-plan-trip' },
  animations: [ opacityFade() ],
  encapsulation: ViewEncapsulation.None
})
export class TripPlanningComponent implements OnInit {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;
  
  @ViewChild(FormComponent) formComponent: FormComponent;
  
  @Input() defaultImage: string;
  
  customCss$: Observable<Partial<CampaignCustomCss> | undefined>;

  constructor(
    public service: PlanTripService
  ) { }

  ngOnInit(): void {
    this.customCss$ = this.brandCampaign$.pipe(
      map((brandCampaign: BrandCampaign | null) => brandCampaign?.campaignCustom.customCss)
    );
  }

}
