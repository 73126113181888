import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { ProfileSelectionModule } from '@hiptraveler/features/profile-selection';
import { LoaderScreenModule } from './../components/loader-screen/loader-screen.module';

import { ExperienceFinderAiComponent } from './experience-finder-ai.component';
import { HostObserverDirective } from './host-observer.directive';
import { HostScrollDirective } from './host-scroll.directive';

const MODULES = [
  CommonModule,
  MatButtonModule
];

const LIBRARIES = [
  ProfileSelectionModule,
  LoaderScreenModule
];

const COMPONENTS = [
  ExperienceFinderAiComponent
];

const DIRECTIVES = [
  HostObserverDirective,
  HostScrollDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ]
})
export class ExperienceFinderAiModule { }
