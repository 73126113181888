import { Component, Input } from '@angular/core';
import { inOutCollapse } from '@hiptraveler/animations';

import { HotelResultData } from '@hiptraveler/data-access/api';

@Component({
  selector: 'amenity-details',
  templateUrl: './amenity-details.component.html',
  styleUrls: ['./amenity-details.component.scss'],
  host: { class: 'content' },
  animations: [ inOutCollapse(26) ]
})
export class AmenityDetailsComponent {

  @Input() data: HotelResultData;

  progressSeeMore: boolean = false;

}
