import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { TermsAndPrivacyPolicyModule } from '@hiptraveler/ui/terms-and-privacy-policy';

import { PrivacyPolicyDialogComponent } from './privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog/terms-and-conditions-dialog.component';

const MODULES = [
  CommonModule,
  MatDialogModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  TermsAndPrivacyPolicyModule
];

const COMPONENTS = [
  PrivacyPolicyDialogComponent,
  TermsAndConditionsDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class TermsAndPrivacyPolicyDialogModule { }
