import { Injectable, OnDestroy } from '@angular/core';

import { AppListenerService } from '@hiptraveler/common';

@Injectable()
export class ExperienceFinderV2Service implements OnDestroy {

  constructor(
    private appListener: AppListenerService
  ) { }
  
  ngOnDestroy(): void {
    this.changeUiState(true);
  }

  initialize(): void {
    this.changeUiState(false);
  }

  changeUiState(state: boolean): void {
    this.appListener.appSiteNavVisibility$$.next(state);
    this.appListener.appFooterVisibility$$.next(state);
    this.appListener.experienceFinderVisibility$$.next(state);
  }

}
