import { Provider } from '@angular/core';

export * from './itinerary-observer.service';
export * from './common.service';
export * from './auth-state-observer.service';
export * from './navigation-change-observer.service';
export * from './close-open-trip-button-observer.service';
export * from './vid-session-observer.service';

import { ItineraryObserverService } from './itinerary-observer.service';
import { AuthStateObserverService } from './auth-state-observer.service';
import { NavigationChangeObserverService } from './navigation-change-observer.service';
import { CloseOpenTripButtonObserverService } from './close-open-trip-button-observer.service';
import { VidSessionObserverService } from './vid-session-observer.service';
import { CommonService } from './common.service';

export const ItineraryProviders: Provider[] = [
  ItineraryObserverService,
  AuthStateObserverService,
  NavigationChangeObserverService,
  CloseOpenTripButtonObserverService,
  VidSessionObserverService,
  CommonService
];
