import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardSectionComponent } from './card-section.component';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  CardSectionComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CardSectionComponent
  ]
})
export class CardSectionModule { }
