import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ActivitiesComponent } from './activities.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  ActivitiesComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivitiesComponent
  ]
})
export class ActivitiesModule { }
