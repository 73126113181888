import { Component } from '@angular/core';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { FeatureCard } from './feature-cards';

@Component({
  selector: 'hotel-card',
  template: `
    <itinerary-feature-card featureCard type="hotel" [starRating]="resultContent.starRating" [buttonActions]="false" (mouseenter)="onFeatureCardHover(resultContent)">
      <img itineraryImage [lazyLoad]="resultContent?.imgUrl || ''" defaultImage="assets/img/chill-time-fallback-image.webp" errorImage="assets/img/chill-time-fallback-image.webp" [alt]="''">
      <itinerary-title>{{ resultContent?.name || ('itinerary.activity' | translate) }}</itinerary-title>
      <itinerary-subtitle>{{ resultContent.location }}</itinerary-subtitle>
      <itinerary-rating>{{ resultContent.guestRating }}</itinerary-rating>
      <itinerary-reviews>{{ resultContent.numReviews }}</itinerary-reviews>

      <itinerary-action-button [active]="false" actionPanel *ngIf="!(itineraryOwner$ | async)">
        <itinerary-action actionPanel [activityId]="resultContent.id" [hotel]="true"></itinerary-action>
      </itinerary-action-button>

      <itinerary-button (click)="primaryClick('hotel')" *ngIf="itineraryOwner$ | async">{{ 0 | hotelDataButtonName | async }}</itinerary-button>
      <itinerary-button [pending]="pendingSecondary" [appearance]="view ? '' : 'warn'" (click)="secondaryClick()">{{ (view ? 'itinerary.button-text-read-more' : 'itinerary.button-text-remove') | translate }}</itinerary-button>
    </itinerary-feature-card>
  `,
  host: { class: 'feature-card' }
})
export class HotelCardComponent extends FeatureCard<HotelResultData> { }
