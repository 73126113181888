import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FeatureCardModule } from '@hiptraveler/ui/feature-card';
import { SearchSvgPathPipeModule } from '@hiptraveler/common';

import { UploadsTabComponent } from './uploads-tab.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  FeatureCardModule,
  SearchSvgPathPipeModule
];

const COMPONENTS = [
  UploadsTabComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    UploadsTabComponent
  ]
})
export class UploadsTabModule { }
