import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { AppListenerService, currentLang } from '@hiptraveler/common';
import * as Feature from '@hiptraveler/features/meta-tag';

@Injectable()
export class SearchAndDefaultPageService {

  constructor(
    private router: Router,
    private appListener: AppListenerService,
    private metaTagService: Feature.MetaTagService,
    private service: Feature.SearchAndDefaultPageObserverService
  ) { }

  observe(): void {

    if (this.appListener.invalidRequest) return;

    setTimeout(this.prepareRootMetaTags.bind(this));

    if (!this.service.originalUrl || !Feature.validateUrl(this.service.originalUrl))
      return;

    this.prepareInitMetaTags();

    this.metaTagService.navigationEnd$.subscribe((config: Feature.ObserverConfig) => {
      this.updateRootMetaTags(config);
    });

    this.service.locationChanges$.subscribe((metadata: Feature.Metadata | null) => {
      metadata
        ? this.updateRootMetaTags({ metadata, url: this.router.url, lang: currentLang() })
        : this.service.defaultConfig$.subscribe(this.updateRootMetaTags.bind(this))
    });
  }

  private prepareRootMetaTags(): void {
    if (Feature.validateRootPath(this.router.url)) {
      this.setHomepageMetaTags();
    } else {
      this.metaTagService.rootConfigByUrl(this.router.url).subscribe((config: Feature.ObserverConfig) => {
        this.updateRootMetaTags(config);
      });
    }
  }

  private prepareInitMetaTags(): void {

    if (!Feature.hasLocationByPath(this.router.url)) return;

    this.service.initialConfig$.subscribe((config: Feature.ObserverConfig) => {
      this.updateRootMetaTags(config);
    });
  }

  private setHomepageMetaTags(): void {
    this.service.rootConfig$.pipe( take(1) ).subscribe((config: Feature.ObserverConfig) => {
      if (config?.metadata) {
        config.metadata['url'] = config.url
      }
      this.metaTagService.updateMetaData({ ...Feature.defaultMetaTagData, ...(config?.metadata || {}) });
    });
  }
    
  private updateRootMetaTags(config: Feature.ObserverConfig): void {
    if (Feature.validateRootPath(this.router.url)) {
      this.setHomepageMetaTags();
    } else {
      Feature.observeDefaultAndSearchPage(config, e => this.metaTagService.updateMetaData(e));
    }
  }

}
