import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as DiscoveryBoardApi from './interfaces';
import * as API from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class DiscoveryBoardApiService {

  constructor(
    private http: HttpClient
  ) { }

  geItineraryResults(payload: API.GetProfileSearchResultsDto): Observable<DiscoveryBoardApi.GetItineraryResultsResponse> {
    return this.http.get<DiscoveryBoardApi.GetItineraryResultsResponse>(`/trip-planner/getUserBoards/${brandCampaignId()}/${payload.userId}/${payload.page}/true/${currentLang()}`);
  }

  addItineraryBoard(id: string): Observable<DiscoveryBoardApi.AddItineraryBoardResponse> {
    return this.http.post<DiscoveryBoardApi.AddItineraryBoardResponse>(`/trip-planner/addBoard/${brandCampaignId()}/${id}/${currentLang()}`, {});
  }

  toggleLikeItinerary({ id, hipType, field }: API.ToggleLikeItineraryDto): Observable<API.ToggleLikeItineraryResponse> {
    const payload = API.toFormData({ hipType, cId: brandCampaignId(), vId: clientVID() });
    const param = field === 'hotelResults' ? 'hotel' : 'image';
    const endpoint = [ 'itineraryResults', 'blogResults' ].includes(field) ? 'hipBoard' : `hipIt/${param}`;
    return this.http.post<API.ToggleLikeItineraryResponse>(`/trip-planner/${endpoint}/${id}`, payload).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

}
