import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { MoreContentDetailsDialogComponent } from './more-content-details-dialog.component';

export const MORE_CONTENT_DETAILS_NOTE_ID = 'more-content-details';

export interface DialogData { label: string; display: string };

@Injectable()
export class MoreContentDetailsDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(data: DialogData): MatDialogRef<MoreContentDetailsDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(MORE_CONTENT_DETAILS_NOTE_ID);
    if (dialog) return dialog.close();
    data.label = data.label || 'view-page.content';
    return this.dialog.open(MoreContentDetailsDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: MORE_CONTENT_DETAILS_NOTE_ID,
      panelClass: MORE_CONTENT_DETAILS_NOTE_ID + '-overlay-panel',
      width: '550px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data
    })
  }

}
