<div class="footer-nav">

  <div class="footer-nav--powered-by">
    <p><em>{{ 'footer.this-site-is-powered-by' | translate }}&nbsp;</em></p>
    <img [src]="hiptravelerLogoImage" alt="hiptraveler-logo" height="auto" (click)="goToHipTravelerWebsite()">
  </div>

  <p (click)="service.navigate('dmo-solutions')">{{ 'footer.dmo-solutions' | translate }}&nbsp;<span>HipTraveler.net</span></p>
  <p (click)="service.navigate('consumer-portal')">{{ 'footer.consumer-portal' | translate }}&nbsp;<span>HipTraveler.com</span></p>
  <ng-container *ngIf="service.hasTravelAgents$ | async">
    <p (click)="service.navigate('travel-agent')"><span translate="footer.destination-specialist-program"></span></p>
  </ng-container>

  <hr>

  <div class="footer-nav--social-media">
    <mat-icon svgIcon="footer-c.facebook"></mat-icon>
    <mat-icon svgIcon="footer-c.instagram"></mat-icon>
    <mat-icon svgIcon="footer-c.twitter"></mat-icon>
    <mat-icon svgIcon="footer-c.pinterest"></mat-icon>
  </div>

</div>
