<feature-card actionCard actionButton toggleFavorite="foods" type="consume" [id]="food.id" [featureCardHover]="food" *ngFor="let food of service.viewDisplayItems$ | async" (click)="service.searchResultDialog.open(food, 'food')" [@opacityFade]>

  <itinerary-action-bubble [active]="!!(service.searchPageControl.activityDate$ | async) && (food.id | validateStatus: (service.actDate$ | async))">
    <itinerary-action [activityId]="food.id"></itinerary-action>
  </itinerary-action-bubble>
  <img featureCardImage [lazyLoad]="food?.thumbnail || ''" [defaultImage]="('foodAndDrinks' | searchSvgPath | async)!" [errorImage]="('foodAndDrinks' | searchSvgPath | async)!" [alt]="food.name">
  <feature-card-title class="amusement">{{ food.name }}</feature-card-title>
  <feature-card-subtitle>{{ food | searchResultLocation }}</feature-card-subtitle>
  <img featureCardReviewImage [lazyLoad]="food?.yelpData?.ratingImgUrl!" defaultImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" errorImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" width="63" height="12" [alt]="food.name">
  <feature-card-rating-reviews>{{ food?.yelpData?.reviewCnt || 0 }}</feature-card-rating-reviews>

</feature-card>

<ng-container *ngIf="!(service.food$ | async)">

  <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons$ | async;" [@opacityFadeIn]>
  </ngx-skeleton-loader>

</ng-container>

<ng-container *ngIf="(service.food$ | async)?.length === 0">
  <p class="ht-no-results" translate="search-page.no-results-found"></p>
</ng-container>

