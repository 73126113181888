import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { switchMap, takeUntil, tap } from 'rxjs';

import { BasicInfoData, LocationData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { TravelDetailsService } from './travel-details.service';
import { SkyscannerWidgetDialogActionService } from '@hiptraveler/dialogs/skyscanner-widget-dialog';
import { CDNReaderService } from '@hiptraveler/common';

@Component({
  selector: 'travel-details',
  templateUrl: './travel-details.component.html',
  styleUrls: ['./travel-details.component.scss'],
  viewProviders: [ TravelDetailsService, SkyscannerWidgetDialogActionService, CDNReaderService ]
})
export class TravelDetailsComponent implements OnInit {

  @Output() selectedLocation = new EventEmitter<LocationData>();

  travelIndex: number = 0;
  nextTravelCity: string = '';
  prevTravelCity: string = '';
  animating: boolean = false;

  animateRight: boolean = false;
  animateLeft: boolean = false;

  constructor(
    private store: Store,
    public service: TravelDetailsService,
    private skyscannerWidget: SkyscannerWidgetDialogActionService
  ) { }

  ngOnInit(): void {
    this.initializeCityName();
    this.service.initializeState();
  }

  /**
   * Initializes the city name display.
   */
  initializeCityName(): void {
    this.service.basicInfo.pipe(
      tap(() => (this.prevTravelCity = '')),
      switchMap(() => this.service.basicInfo$),
      takeUntil(this.service.subscription$)
    ).subscribe((data: Partial<BasicInfoData>) => {
      const locationList = data.locationList;
      this.prevTravelCity = locationList?.[this.travelIndex]?.city || '-';
      setTimeout(() => this.selectedLocation.emit(locationList?.[this.travelIndex]));
    });
  }

  /**
   * Navigates to the previous or next location in the list.
   * @param nav Direction to navigate ('left' or 'right').
   */
  navigateLocation(nav: 'left'|'right'): void {
    const data = this.store.selectSnapshot(ItineraryState.basicInfo) || null;
    if (!data || this.animating) return;
    const transitionDuration = 300;
    const locationList = data?.locationList || [];
    this.animating = true;
    if (nav === 'right') {
      if ((locationList.length-1) === this.travelIndex) {
        this.animating = false;
        return;
      }
      this.travelIndex++;
      this.animateRight = false;
      this.animateLeft = true;
      this.nextTravelCity = locationList?.[this.travelIndex]?.city || '';
      this.selectedLocation.emit(locationList?.[this.travelIndex]);
      setTimeout(() => {
        this.animateLeft = false;
        this.animating = false;
        this.prevTravelCity = locationList?.[this.travelIndex]?.city || '';
      }, transitionDuration);
    } else {
      if (this.travelIndex === 0) {
        this.animating = false;
        return;
      }
      this.travelIndex--;
      this.animateLeft = false;
      this.animateRight = true;
      this.nextTravelCity = locationList?.[this.travelIndex]?.city || '';
      this.selectedLocation.emit(locationList?.[this.travelIndex]);
      setTimeout(() => {
        this.animateRight = false;
        this.animating = false;
        this.prevTravelCity = locationList?.[this.travelIndex]?.city || '';
      }, transitionDuration);
    }
  }

  /**
   * Opens the Skyscanner widget dialog.
   */
  openDialog(): void {
    this.skyscannerWidget.open();
  }

}
