import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ProfileState } from '.';

const STATES = [
  ProfileState
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ]
})
export class ProfileStateModule { }
