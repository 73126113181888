import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { PoweredByHiptravelerModule } from '@hiptraveler/ui/powered-by-hiptraveler';
import { ActionButtonModule } from '../shared';

import { AuthMenuComponent } from './auth-menu.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatRippleModule,
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule
];

const LIBRARIES = [
  PoweredByHiptravelerModule,
  ActionButtonModule
];

const COMPONENTS = [
  AuthMenuComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    AuthMenuComponent
  ]
})
export class AuthMenuModule { }
