<div class="search-results">

  <feature-card itineraryCard toggleFavorite="itineraryResults" navigateToTrip [itinerary]="itinerary" [id]="itinerary.id" *ngFor="let itinerary of (dataAccess.itineraryResults$ | async);" [@opacityFade]>
    <img featureCardImage [lazyLoad]="itinerary?.coverImage?.imgUrl!" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="itinerary?.pageTitle">
    <feature-card-date>{{ itinerary?.numDays }}</feature-card-date>
    <feature-card-itinerary-title>{{ itinerary?.title }}</feature-card-itinerary-title>
    <feature-card-itinerary-snippet [class.no-content]="!itinerary?.snippet">{{ itinerary?.snippet }}</feature-card-itinerary-snippet>
  </feature-card>
  
  <ng-container *ngIf="!(dataAccess.itineraryResults$ | async)">
  
    <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
    </ngx-skeleton-loader>
  
  </ng-container>
  
  <ng-container *ngIf="(dataAccess.itineraryResults$ | async)?.length === 0">
    <p class="ht-no-results" style="text-align: center;" translate="profile.no-itineraries-yet"></p>
  </ng-container>

</div>
