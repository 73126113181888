import { ElementRef } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

import { placesAutocompleteOptions } from "./google-maps-autocomplete-options";
import { subdomain, VISITJORDAN_BRAND_NAME } from "../subdomain";
import { validatePlaceRestrictions } from "./google-maps-autocomplete.utils";
import { SearchLocationData } from "@hiptraveler/common";

export type PlacesResponse = google.maps.places.Autocomplete;
export type PlaceResult = google.maps.places.PlaceResult | null;
export type AutocompletePredictions = google.maps.places.AutocompletePrediction[] | null;

interface Coordinates { latitude: number, longitude: number };
type PromiseResolve<T = any> = (value: T | PromiseLike<T>) => void;
type PromiseReject<T = any> = (reason?: T) => void;

interface GoogleMapsPlacesAutocompleteOptions {
  default: boolean;
}

const noSupport = [ VISITJORDAN_BRAND_NAME ];

export function getCityByPlaceResult(response: PlaceResult): string {
  return response?.address_components?.find(e => e.types.includes('locality'))?.long_name || '';
}

export function getCountryByPlaceResult(response: PlaceResult): string {
  return response?.address_components?.find(e => e.types.includes('country'))?.long_name || '';
}

export function initializeGoogleMapsPlacesAutocomplete(inputElementRef?: ElementRef | null, options?: GoogleMapsPlacesAutocompleteOptions): Observable<PlaceResult> {

  if (!inputElementRef?.nativeElement) return of(null)
  
  if (!options?.default) {
    if (noSupport.includes(subdomain())) return of(null)
  }

  const places$$ = new BehaviorSubject<PlaceResult>(null);
  const gmpaOptions = placesAutocompleteOptions(!!options?.default);
  const autocomplete = new google.maps.places.Autocomplete(inputElementRef?.nativeElement, gmpaOptions);

  const autocompleteListener = (callback: (place: PlaceResult) => void) => {
    const place = autocomplete.getPlace();
    if (validatePlaceRestrictions(place)) {
      inputElementRef.nativeElement.value = '';
      places$$.next(null);
      return;
    }
    callback(place);
  }

  autocomplete.addListener("place_changed", () => {
    autocompleteListener(res => places$$.next(res))
  });
  
  autocomplete.addListener("place_activated", () => {
    autocompleteListener(res => places$$.next(res))
  });
  
  autocomplete.addListener("geocode", () => {
    autocompleteListener(res => places$$.next(res))
  });

  return places$$.asObservable();
}

export function getPlaceResultByLocationData(searchLocation: SearchLocationData): PlaceResult {
  return {
    place_id: searchLocation.locId,
    formatted_address: searchLocation.location
  };
}

export function getPlacePredictionsByInput(input: string, callback: (predictions: AutocompletePredictions) => void): void {

  const autocompleteService = new google.maps.places.AutocompleteService();

  autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      callback(predictions);
    } else {
      console.error('Get Place prediction request failed:', status);
    }
  });
}

export function getCoordinatesByPlaceId(placeId: string, callback: (result: PlaceResult) => void): void {

  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  
  placesService.getDetails({ placeId }, (result, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      callback(result);
    } else {
      console.error('Get details request failed:', status);
    }
  })
}

export function getCoordinatesByInput(input: string): Promise<Coordinates> {
  return new Promise<Coordinates>((resolve: PromiseResolve<Coordinates>, reject: PromiseReject) => {
    getPlacePredictionsByInput(input, (predictions: AutocompletePredictions) => {
      const placeId = predictions?.[0]?.place_id;
      if (!placeId) { reject(); return; }
      
      getCoordinatesByPlaceId(placeId, (result: PlaceResult) => {
        if (!result?.geometry?.location) { reject(); return; }

        const { lat, lng } = result?.geometry?.location;
        resolve({ latitude: lat(), longitude: lng() });
      });
    })
  });
}
