import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as BrandApi from './interfaces';
import { handleErrorAndThrow } from '@hiptraveler/data-access/api';
import { brandCampaignId, currentLang, isWidget } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class BrandApiService {

  constructor(
    private http: HttpClient
  ) { }

  getBrandInfo(): Observable<BrandApi.BrandInfoResponse> {
    return this.http.get<BrandApi.BrandInfoResponse>(`/trip-planner/getBrandInfo/${brandCampaignId()}/${currentLang()}`, {
      params: { param: isWidget() ? 'widget' : 'full' }
    }).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getBrandContent({ page, type }: BrandApi.GetBrandContentDto): Observable<any> {
    return this.http.get<any>(`/trip-planner/getBrandContent/${brandCampaignId()}/${currentLang()}/${type}/${page}`, {
      params: { param: isWidget() ? 'widget' : 'full' }
    }).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getCustomBrandData(param: string, cId?: string): Observable<any> {
    return this.http.get(`https://d1npdhl5v1lqq4.cloudfront.net/assets/clients/${param}/${cId}.json`);
  }

}
