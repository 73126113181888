import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { BlankItineraryComponent } from './blank-itinerary.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule
];

const COMPONENTS = [
  BlankItineraryComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    BlankItineraryComponent
  ]
})
export class BlankItineraryModule { }
