import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { BrandState } from '.';

const STATES = [
  BrandState
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ]
})
export class BrandStateModule { }
