import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ActivityInputOverlayComponent } from './activity-input-overlay.component';
import { FiltersStateService } from '../../common';

export const ACTIVITY_OVERLAY_ID = 'activity-overlay';

@Injectable()
export class ActivityInputOverlayActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(listItems: any, filtersService: FiltersStateService): MatDialogRef<ActivityInputOverlayComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(ACTIVITY_OVERLAY_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(ActivityInputOverlayComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: ACTIVITY_OVERLAY_ID,
      panelClass: ACTIVITY_OVERLAY_ID + '-panel',
      backdropClass: ACTIVITY_OVERLAY_ID + '-backdrop',
      width: '100vw',
      maxWidth: 'auto',
      minWidth: 0,
      height: 'calc(100vh - 70px)',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: { listItems, filtersService }
    })
  }

}
