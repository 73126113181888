import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { MultiDayTourDateModule } from '@hiptraveler/features/itinerary';

import { ActivityDateComponent } from './activity-date.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  MultiDayTourDateModule
];

const COMPONENTS = [
  ActivityDateComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivityDateComponent
  ]
})
export class ActivityDateModule { }
