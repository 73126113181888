import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { StarRatingsModule } from '@hiptraveler/ui/star-ratings';
import { LocalDatePipeModule, ToLocalePipeModule } from '@hiptraveler/common';

import { TourReviewsComponent } from './tour-reviews.component';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  StarRatingsModule,
  LocalDatePipeModule,
  ToLocalePipeModule
];

const COMPONENTS = [
  TourReviewsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TourReviewsComponent
  ]
})
export class TourReviewsModule { }
