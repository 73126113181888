import { Component, Input } from '@angular/core';

@Component({
  selector: 'social-media-button',
  templateUrl: './social-media-button.component.html',
  styleUrls: ['./social-media-button.component.scss'],
  host: {
    class: 'social-media-button'
  }
})
export class SocialMediaButtonComponent {

  @Input() class: string;

}
