import { Provider } from '@angular/core';

import { AppIdProvider } from './app-id';
import { SocialAuthServiceConfigProvider } from './social-auth-service-config';
import { EnvironmentProvider } from './environment';

export const AppProviders: Provider[] = [
  AppIdProvider,
  SocialAuthServiceConfigProvider,
  EnvironmentProvider
];
