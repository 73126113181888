import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComposeRoutingModule } from './compose-routing.module';

import { ItineraryStateService } from '@hiptraveler/features/itinerary';
import { ComponentStateService } from '@hiptraveler/features/itinerary';
import { FilestackPickerService } from './common';

import { ComposeComponent } from './compose.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ComposeRoutingModule
];

const PROVIDERS = [
  ItineraryStateService,
  ComponentStateService,
  FilestackPickerService
];

const COMPONENTS = [
  ComposeComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  providers: [
    PROVIDERS
  ],
  declarations: [
    COMPONENTS
  ]
})
export class ComposeModule { }
