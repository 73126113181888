<p class="search-host--title" translate="search-page.destination-specialists"></p>
<p class="search-host--subtitle" translate="search-page.travel-agent-expertise"></p>

<div class="search-host--router-outlet">
  <travel-agents></travel-agents>
</div>

<ng-container *ngIf="!(basicInfo$ | async) && !!(itineraries$ | async)?.length">
  <br><br>
</ng-container>
