import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';

import { PlacesDialogComponent } from './places-dialog.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatRippleModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  CustomMapAutocompleteModule
];

const COMPONENTS = [
  PlacesDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class PlacesDialogModule { }
