import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TranslateParamPipeModule } from '@hiptraveler/common';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { UsePolicyAndCopyrightComponent } from './use-policy-and-copyright/use-policy-and-copyright.component';
import { TranslateParam2Pipe } from './use-policy-and-copyright/translate-param.pipe';

const MODULES = [
  CommonModule,
  TranslateModule
];

// const LIBRARIES = [
//   TranslateParamPipeModule
// ];

const COMPONENTS = [
  PrivacyPolicyComponent,
  TermsAndConditionsComponent,
  UsePolicyAndCopyrightComponent
];

const PIPES = [
  TranslateParam2Pipe
];


@NgModule({
  imports: [
    MODULES,
    // LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    PrivacyPolicyComponent,
    TermsAndConditionsComponent
  ]
})
export class TermsAndPrivacyPolicyModule { }
