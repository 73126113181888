import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .auth-terms-and-policy-overlay-panel .mat-dialog-container {
      padding-top: 0;
      padding-bottom: 0;
      
      @media screen and (max-width: 750px) {
        border-radius: 0 !important;
      }
    }
  `]
})
export class PrivacyPolicyDialogComponent {}
