import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, delay, of } from 'rxjs';

import { ActivityDateData, ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { skeletonVisibilityDelay } from '../../common';

@Injectable()
export class HighlightCarouselService {

  @Select(ItineraryState.actDate) actDate$: Observable<ActivityDateData[] | null>;
  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;

  skeletonVisibility$ = of(true).pipe(
    delay(skeletonVisibilityDelay)
  );

}
