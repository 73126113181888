import { ChangeDetectorRef, Directive, HostListener, NgModule, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom } from 'rxjs';

import { UserAction } from '@hiptraveler/data-access/user';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { FilestackService, PickerFileMetadata } from '@hiptraveler/core/filestack';
import { SnackbarService } from '@hiptraveler/snackbar';
import { ProfileStateService } from '.';
import { clientVID } from '@hiptraveler/common';

@Directive({
  selector: '[uploadProfilePhoto]'
})
export class UploadProfilePhotoDirective implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private filestack: FilestackService,
    private snackbar: SnackbarService,
    private stateService: ProfileStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  @HostListener('click')
  hostClick(): void {
    this.openFilestackPicker();
  }

  async onFileUploadFinished(metadata: PickerFileMetadata): Promise<void> {

    const urlBase = 'https://cdn.filestackcontent.com';
    const urlId = metadata.url.replace(`${urlBase}/`, '');
    const picUrl = `${urlBase}/resize=width:400,height:400,fit:crop,align:faces/auto_image/compress/${urlId}`;

    try {
      await firstValueFrom(this.store.dispatch([
        new UserAction.UpdateProfileImage(picUrl),
        new ProfileAction.UpdateProfileContent({
          action: 'profileImage',
          id: this.store.selectSnapshot(ProfileState.profileDetails)!.profileId,
          picThumb: `${urlBase}/resize=width:768,fit:scale/auto_image/compress/${urlId}`,
          picUrl, vId: clientVID()
        })
      ]));
      
      this.stateService.uploadImageProcessing$$.next(false);
      setTimeout(() => this.cdRef.detectChanges());
    } finally { }
  }

  private openFilestackPicker(): void {

    this.snackbar.open({ message: 'Please wait.', duration: Infinity });

    const onOpen = () => {
      setTimeout(() => this.snackbar.dismiss(), 500);
    };
    const detectChanges = () => {
      setTimeout(() => this.cdRef.detectChanges());
    };
    const onUploadStarted = () => {
      this.stateService.uploadImageProcessing$$.next(true);
    };
    const onCancel = () => {
      this.snackbar.dismiss();
      this.stateService.uploadImageProcessing$$.next(false);
    };

    this.filestack.openFilestackPicker({
      onOpen: onOpen,
      onClose: detectChanges,
      onUploadDone: detectChanges,
      onUploadStarted: onUploadStarted,
      onFileUploadCancel: onCancel,
      onFileUploadFailed: onCancel,
      onFileUploadFinished: this.onFileUploadFinished.bind(this)
    })
  }

}

@NgModule({
  declarations: [ UploadProfilePhotoDirective ],
  exports:      [ UploadProfilePhotoDirective ]
})
export class UploadProfilePhotoDirectiveModule { }
