import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PoiSelectCarouselModule } from '../../components/poi-select-carousel';

import { CarouselActivitiesComponent } from './carousel-activities.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  PoiSelectCarouselModule
];

const COMPONENTS = [
  CarouselActivitiesComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CarouselActivitiesComponent
  ]
})
export class CarouselActivitiesModule { }
