<div class="dont-sell-info">

  <h1 translate="dsi.do-not-sell-info"></h1>

  <dont-sell-info
    [form]="service.form"
    [pending]="(service.actionPending$ | async)!"
    [processing]="service.processing"
    actionButton
    (actionClicked)="service.savePreferences()">
  </dont-sell-info>

  <p translate="dsi.note-for-guests"></p>
  
  <ng-container *ngIf="service.notAuthenticated$ | async">
    <p [@opacityFade]>{{ 'dsi.opt-out-associated' | translate }} <span (click)="openAuthDialog()">{{ 'dsi.log-in' | translate }}</span>.</p>
  </ng-container>

</div>
