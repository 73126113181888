import { SearchLocationData } from "../../services";
import { PlaceResult } from "./google-maps-autocomplete";

export function predictLocationDataByPlaceName(placeName: string): Promise<SearchLocationData> {
  return new Promise((resolve, reject) => {
    
    const autocompleteService = new google.maps.places.AutocompleteService();
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    autocompleteService.getPlacePredictions({ input: decodeURIComponent(placeName) }, (predictions, status) => {
      predictions = predictions || [];
      const { description, place_id } = predictions[0];

      if (status === google.maps.places.PlacesServiceStatus.OK && predictions.length > 0) {
        placesService.getDetails({ placeId: place_id }, function(place, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve({
              name: placeName,
              location: description,
              locId: place_id,
              latitude: place?.geometry?.location?.lat(),
              longitude: place?.geometry?.location?.lng()
            });
          } else {
            console.error('Error fetching place details:', status);
            reject();
          }
        });
      } else {
        console.error('Error fetching place predictions:', status);
        reject();
      }

    });

  });
}

export function validatePlaceRestrictions(place: PlaceResult): boolean {
  
  if (!place?.address_components) return false;

  const countryComponent = place.address_components.find(c => c.types.includes("country"));
  
  if (!countryComponent) return false;

  const countryCode = countryComponent.short_name;
  if ([ 'IL', 'RU' ].includes(countryCode)) return true;

  return false;
}
