import { Component, OnDestroy, OnInit } from '@angular/core';

import { UserSettingsService } from './user-settings.service';
import { ProfileStateService } from '../../common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  viewProviders: [ UserSettingsService ],
  animations: [ opacityFadeIn() ]
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  constructor(
    public service: UserSettingsService,
    public stateService: ProfileStateService
  ) { }

  ngOnInit(): void {
    this.service.notAuthenticatedListener();
  }

  ngOnDestroy(): void {
    this.stateService.settingsView$$.next('profile');
  }

}
