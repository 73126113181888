import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, map } from 'rxjs';

import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { getWindowRef, itineraryBasicInfoAuthorKey, itineraryBasicInfoItinerariesKey } from '@hiptraveler/common';

@Injectable()
export class DataAccessObserverService {

  constructor(
    private store: Store
  ) { }

  observe(): void {
    this.userStateObserver();
  }

  private userStateObserver(): void {
    this.store.select(UserState.state).pipe(
      filter(Boolean),
      map((userState: UserStateModel) => {

        getWindowRef()[itineraryBasicInfoItinerariesKey] = JSON.stringify(
          userState?.itinerary?.map(e => e.id) || []
        );

        getWindowRef()[itineraryBasicInfoAuthorKey] = JSON.stringify({
          authorProfPic: userState.picUrl,
          authorProfId: userState.id,
          authorName: userState.fullName || `${userState.firstName} ${userState.lastName}`,
          authorTitle: userState.uTitle,
          authorDesc: null,
          authorReference: userState.userType
        });
      })
    ).subscribe();
  }

}
