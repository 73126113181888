import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ItineraryActionService } from './../itinerary-action.service';
import { ItineraryAction } from '../itinerary-action';
import { ItineraryActionPanelService } from '../itinerary-action-panel/itinerary-action-panel.service';
import { ItineraryActionPanelDataAccessService } from '../itinerary-action-panel/itinerary-action-panel-data-access.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'itinerary-action-bubble',
  template: `

    <div matRipple class="itinerary-action-bubble mat-elevation-z2" [class.active]="active" [class.processing]="searchPageControl.itineraryActionProcessing$ | async" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="toggleItineraryAction(actionBubble)" #actionBubble>
      <mat-icon *ngIf="active;else alreadyActive">done</mat-icon>
      <ng-template #alreadyActive><mat-icon svgIcon="itinerary-action.add-svg" ></mat-icon></ng-template>
      <ng-container *ngIf="searchPageControl.itineraryActionProcessing$ | async">
        <div class="itinerary-action-bubble--progress" [@opacityFade]>
          <mat-progress-spinner mode="indeterminate" diameter="50.5" strokeWidth="4" theme="white"></mat-progress-spinner>
        </div>
      </ng-container>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="visibility">
      <ng-content select="itinerary-action"></ng-content>
    </ng-template>

  `,
  styleUrls: ['./itinerary-action-bubble.component.scss'],
  host: { class: 'itinerary-action-bubble' },
  providers: [ ItineraryActionService, ItineraryActionPanelService, ItineraryActionPanelDataAccessService ],
  animations: [ opacityFade() ]
})
export class ItineraryActionBubbleComponent extends ItineraryAction implements OnInit {

  @Input() clickSignal: Subject<boolean>;

  ngOnInit(): void {
    this.clickSignal?.asObservable().pipe(
      takeUntil(this.appListener.subscription$)
    ).subscribe((state: boolean) => state ? this.toggleItineraryAction() : this.closeItineraryAction());
  }

}
