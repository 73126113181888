import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { FeatureCardModule } from '@hiptraveler/ui/feature-card';

import { ExperiencesComponent } from './experiences.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  FeatureCardModule
];

const COMPONENTS = [
  ExperiencesComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ExperiencesComponent
  ]
})
export class ExperiencesModule { }
