import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { LoginModule } from './login/login.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { PoweredByHiptravelerModule } from '@hiptraveler/ui/powered-by-hiptraveler';
import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';

import { AuthDialogComponent } from './auth-dialog.component';
import { DynamicStylesDirective } from './dynamic-styles.directive';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  LoginModule,
  ForgotPasswordModule,
  DialogCloseButtonModule,
  PoweredByHiptravelerModule,
  MobileNavbarViewModule
];

const COMPONENTS = [
  AuthDialogComponent
];

const DIRECTIVES = [
  DynamicStylesDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ]
})
export class AuthDialogModule { }
