import { Component, EventEmitter } from '@angular/core';

import { RegistrationMenuService } from './registration-menu.service';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'registration-menu',
  templateUrl: './registration-menu.component.html',
  styleUrls: ['./registration-menu.component.scss'],
  viewProviders: [ RegistrationMenuService ]
})
export class RegistrationMenuComponent {

  submitClicked = new EventEmitter<void>();

  constructor(
    public service: RegistrationMenuService,
    public stateService: ExperienceFinderStateService
  ) { }

}
