import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { MyTripsService } from './my-trips.service';

@Component({
  selector: 'app-my-trips',
  templateUrl: './my-trips.component.html',
  styleUrls: ['./my-trips.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ MyTripsService ]
})
export class MyTripsComponent {

  constructor(
    public dialogRef: MatDialogRef<MyTripsComponent>,
    public service: MyTripsService
  ) { }

}
