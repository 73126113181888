import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { ProfessionalProfileFormService } from './professional-profile-form.service';
import { RegistrationFormComponent } from '@hiptraveler/features/registration-form';

@Component({
  selector: 'professional-profile-form',
  template: '<registration-form pageView></registration-form>',
  styleUrls: [`./professional-profile-form.component.scss`],
  host: { class: 'profile-page-activity-content-user-settings-professional-profile-form' },
  viewProviders: [ ProfessionalProfileFormService ],
  encapsulation: ViewEncapsulation.None
})
export class ProfessionalProfileFormComponent implements OnInit, AfterViewInit {

  @ViewChild(RegistrationFormComponent) registerForm: RegistrationFormComponent;

  constructor(
    public service: ProfessionalProfileFormService,
  ) { }

  ngOnInit(): void {
    this.service.patchInitialFormValues();
  }
    
  ngAfterViewInit(): void {
    this.service.observeSubmitByFormComponent(this.registerForm);
  }

}
