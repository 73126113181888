import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class StoryContentService {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;

}
