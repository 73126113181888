<ng-container *ngIf="travelOptionBgStyle$ | async as travelOptionBgStyle">
  <div class="trave-option">
    <img [src]="travelOptionBgStyle.visa" alt="visa">
    <div class="trave-option--bg"></div>
    <div matRipple class="trave-option--ripple" (click)="selectOption('visaLink')">
      <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" fill-rule="evenodd" viewBox="0 0 24 24">
        <path d="M23 8c0-.552-.447-1-1-1h-12c-.552 0-1 .448-1 1v15c0 .553.448 1 1 1h12c.553 0 1-.447 1-1v-15zm-12 14h10v-13h-10v13zm2-2h6v-1h-6v1zm3.751-15h-2.207l-1.245-2.671-9.97 4.649 3.671 7.873v4.731l-5.906-12.665c-.064-.137-.094-.28-.094-.422 0-.376.214-.737.578-.907l11.781-5.494c.137-.064.281-.094.422-.094.377 0 .738.214.907.578l2.063 4.422zm-.751 5.086c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4m-1.587 5.331l.005.025c.121.53.27.968.542 1.488-.759-.28-1.327-.767-1.723-1.513h1.176zm2.255 0c-.147.575-.353 1.02-.668 1.504-.329-.5-.525-.951-.668-1.504h1.336zm2.094 0c-.391.736-1.001 1.241-1.717 1.502.26-.496.439-1.012.542-1.502h1.175zm.299-1.938h-1.361c.031.32.034.726.013 1.042h1.348c.056-.346.055-.696 0-1.042m-2.261 0h-1.601c-.037.364-.038.757-.013 1.042h1.628c.027-.342.02-.703-.014-1.042m-2.5 0h-1.361c-.056.346-.056.696 0 1.042h1.348c-.023-.368-.017-.667.01-1.016l.003-.026zm4.462-.896c-.398-.745-.999-1.245-1.748-1.513.247.485.413.97.539 1.492l.005.021h1.204zm-2.127 0c-.155-.565-.346-1.01-.635-1.497-.271.428-.507 1.011-.636 1.497h1.271zm-3.398 0h1.205c.117-.521.32-1.065.548-1.523-.772.273-1.353.772-1.753 1.523"></path>
      </svg>
      <p translate="itinerary.visa-requirements"></p>
    </div>
  </div>
  <div class="trave-option">
    <img [src]="travelOptionBgStyle.insurance" alt="insurance">
    <div class="trave-option--bg"></div>
    <div matRipple class="trave-option--ripple" (click)="selectOption()">
      <svg fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.488 24h-.001c-.266 0-.487-.216-.487-.487v-.513c-1.104 0-2-.896-2-2v-13c0-1.104.896-2 2-2h2v-5c0-.552.448-1 1-1h4c.553 0 1 .449 1 1v5h2c1.105 0 2 .896 2 2v13c0 1.104-.895 2-2 2v.488c0 .283-.228.512-.512.512h-.001c-.265 0-.487-.214-.487-.487v-.513h-8v.488c0 .283-.229.512-.512.512m-.488-3h10v-13h-10v13zm2.008-10h-.508c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7c.277 0 .5.224.5.5s-.223.5-.5.5h-4.532c.262.304.463.665.578 1.003l1.289.003 3.528 3.542-2.833 2.823-3.529-3.543.003-1.476c-.607-.415-1.068-1.314-.996-2.352m3.642 3.271l1.821 1.821-.354.353-1.82-1.82.353-.354zm.684 1.745l-.354.353-1.214-1.214.354-.353 1.214 1.214zm-.154-2.275l1.821 1.821-.353.353-1.821-1.82.353-.354zm-1.572-.726c-.092.274-.333.462-.606.539.042.417.551.6.85.303.281-.278.139-.755-.244-.842m-2.097-2.015c-.053.687.156 1.287.494 1.682l.002-.682 1.003.002c-.189-.434-.466-.774-.764-1.002h-.735zm4.489-5v-3.5c0-.276-.223-.5-.5-.5h-3c-.276 0-.5.224-.5.5v3.5h4z"></path>
      </svg>
      <p translate="itinerary.travel-insurance"></p>
    </div>
  </div>
  <div class="trave-option">
    <img [src]="travelOptionBgStyle.covid" alt="covid">
    <div class="trave-option--bg"></div>
    <div matRipple class="trave-option--ripple" (click)="selectOption('safetyLink')">
      <svg fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.826 9h-2.086c.171-.487.262-.957.262-1.41 0-2.326-1.818-3.776-4.024-3.573-2.681.247-4.518 3.71-4.978 4.484-.527-.863-2.261-4.238-4.981-4.494-2.11-.199-4.019 1.181-4.019 3.582 0 3.109 4.347 7.084 9.001 11.615 1.16-1.127 2.285-2.208 3.324-3.243l.97 1.857c-1.318 1.302-2.769 2.686-4.294 4.181-6.164-6.037-11.001-10.202-11.001-14.403 0-3.294 2.462-5.526 5.674-5.596 2.163-.009 4.125.957 5.327 2.952 1.177-1.956 3.146-2.942 5.253-2.942 3.064 0 5.746 2.115 5.746 5.595 0 .464-.06.928-.174 1.395zm-11.094 4c-.346.598-.992 1-1.732 1-1.104 0-2-.896-2-2s.896-2 2-2c.74 0 1.386.402 1.732 1h1.222l1.88-2.71c.14-.202.376-.315.622-.299.245.016.464.161.576.38l2.27 4.437.813-1.45c.124-.221.357-.358.611-.358h5.274v2h-4.513l-1.759 2.908c-.132.219-.373.348-.629.337-.255-.01-.484-.16-.598-.389l-2.256-4.559-.989 1.406c-.131.186-.345.297-.573.297h-1.951z"></path>
      </svg>
      <p translate="itinerary.covid-19-information"></p>
    </div>
  </div>
</ng-container>
