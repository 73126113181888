import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { HomeMenuService } from './home-menu.service';

@Component({
  selector: 'home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ HomeMenuService ]
})
export class HomeMenuComponent {

  @Input() defaultImage: string;

  constructor(
    public stateService: ExperienceFinderStateService,
    public service: HomeMenuService
  ) { }

}
