<div class="agent-details--form-fields" [formGroup]="form">
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Facebook</mat-label>
    <input matInput type="text" name="facebook" formControlName="facebook">
  </mat-form-field>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Instagram</mat-label>
    <input matInput type="text" name="instagram" formControlName="instagram">
  </mat-form-field>
  <div class="agent-details--form-fields-break"></div>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>TikTok</mat-label>
    <input matInput type="text" name="tiktok" formControlName="tiktok">
  </mat-form-field>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>X / Twitter</mat-label>
    <input matInput type="text" name="twitter" formControlName="twitter">
  </mat-form-field>
  <div class="agent-details--form-fields-break"></div>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Youtube</mat-label>
    <input matInput type="text" name="youtube" formControlName="youtube">
  </mat-form-field>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Pinterest</mat-label>
    <input matInput type="text" name="pinterest" formControlName="pinterest">
  </mat-form-field>
  <div class="agent-details--form-fields-break"></div>
  <mat-form-field underlined appearance="outline" formGroupName="social">
    <mat-label>Website</mat-label>
    <input matInput type="text" name="website" formControlName="website">
  </mat-form-field>
</div>
