
<!-- Brand logo -->
<navbar-brand-logo [hiptravelerBrand$]="service.hiptravelerBrand$" [brandCampaign$]="service.brandCampaign$" [mobileView]="(mobileView$ | async)!" (changeLanguage)="service.changeLanguage($event)" *ngIf="service.navbarControl.navbarLogoVisibility$$ | async;else focusModeUI"></navbar-brand-logo>

<ng-template #focusModeUI>
  <!-- Focused mode UI (eg. /compose) -->
  <div class="navbar--focused-mode">
    <mat-icon class="navbar--focused-mode-action" (click)="exitFocusMode()">close</mat-icon>
    <ng-container *ngIf="service.navbarControl.navbarTitleState$$ | async as text">
      <p class="navbar--focused-mode-title">{{ text }}</p>
    </ng-container>
  </div>
</ng-template>

<!-- Non-mobile view -->
<ng-container *ngIf="!(mobileView$ | async)">

  <!-- Searchbar -->
  <ng-container *ngIf="service.navbarControl.navbarSearchBarVisibility$ | async">

    <explore-input-field customMapAutocomplete></explore-input-field>

    <powered-by-hiptraveler
      *ngIf="!(service.hiptravelerBrand$ | async)">
    </powered-by-hiptraveler>
  
  </ng-container>

</ng-container>

<!-- Non-mobile view or dynamic -->
<ng-container *ngIf="!(mobileView$ | async) || (mobileViewActions$ | async)">

  <!-- Action buttons -->
  <navbar-actions></navbar-actions>
  <div class="navbar--actions-spacer" *ngIf="mobileViewActions$ | async"></div>

</ng-container>

<!-- Non-mobile view -->
<ng-container *ngIf="!(mobileView$ | async)">

  <!-- Profile selection -->
  <profile-selection></profile-selection>

</ng-container>
