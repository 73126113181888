import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ExperienceFinderService } from './experience-finder.service';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { CDNReaderService } from '@hiptraveler/common';

@Component({
  selector: 'experience-finder-screen',
  templateUrl: './experience-finder.component.html',
  styleUrls: ['./experience-finder.component.scss'],
  host: { class: 'experience-finder-screen' },
  viewProviders: [ ExperienceFinderService, CDNReaderService ],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderComponent implements OnInit {

  defaultImage?: string;

  constructor(
    private dialog: MatDialog,
    public service: ExperienceFinderService,
    public state: ExperienceFinderStateService,
    private cdnReader: CDNReaderService
  ) { }

  ngOnInit(): void {
    this.state.reset();
    this.service.dialogViewObserver();
    this.service.authenticatedObserver();
    this.cdnReader.getMultipleCdnData(
      'base64.appCommon', 'base64.experienceFinderDefault', 'base64.experienceFinderAssets'
    ).subscribe((data: any) => {
      this.defaultImage = data['response2']['value'];
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && !this.dialog['_openDialogsAtThisLevel'].length) {
      this.state.overlayState$$.next(false);
    }
  }

}
