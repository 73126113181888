import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { of, tap } from 'rxjs';

import { AuthStateModel, AuthAction } from '.';
import * as API from '@hiptraveler/data-access/api';
import { SignOutNoAuthService } from './services';
import { UserAction } from '@hiptraveler/data-access/user';
import { SnackbarService } from '@hiptraveler/snackbar';
import { RESET_PASSWORD_KEY, handleResetPasswordSessionData } from './auth.helper';
import { getWindowRef, setClientVId, signoutAccessToken } from '@hiptraveler/common';

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('state_auth');

export const authStateDefaults: any = null;

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: authStateDefaults
})

@Injectable()
export class AuthState {

  @Selector()
  static state(state: AuthStateModel): AuthStateModel | null {
    return state || null;
  }

  @Selector()
  static authenticated(state: AuthStateModel): boolean {
    return !!state?.accessToken;
  }

  @Selector()
  static accessToken(state: AuthStateModel): string | null {
    return state?.accessToken || getWindowRef()?.[signoutAccessToken] || null;
  }

  constructor(
    private authApi: API.AuthApiService,
    private signOutNoAuthService: SignOutNoAuthService,
    private snackbar: SnackbarService
  ) { }

  @Action(AuthAction.SetAccessToken)
  setAccessToken(ctx: StateContext<AuthStateModel>, { accessToken }: AuthAction.SetAccessToken) {
    return of(ctx.patchState({ accessToken }));
  }

  @Action(AuthAction.Register)
  register(ctx: StateContext<AuthStateModel>, action: AuthAction.Register) {
    return this.authApi.register(action.data).pipe(
      tap(({ accessToken }) => ctx.patchState({ accessToken }))
    );
  }

  @Action(AuthAction.Login)
  login(ctx: StateContext<AuthStateModel>, action: AuthAction.Login) { // testing
    return this.authApi.login(action.data).pipe(
      tap(({ accessToken }) => ctx.patchState({ accessToken }))
    );
  }

  @Action(AuthAction.RefreshSessionWithCookies)
  refreshSessionWithCookies(ctx: StateContext<AuthStateModel>, { cId }: AuthAction.RefreshSessionWithCookies) {
    return this.authApi.refreshSessionWithCookies(cId).pipe(
      tap(API.validateResponse),
      tap(({ accessToken }) => ctx.patchState({ accessToken }))
    );
  }

  @Action(AuthAction.SignOut)
  signOut(_: StateContext<AuthStateModel>) {
    return this.authApi.signOut().pipe(
      tap(({ vId }) => vId && setClientVId(vId))
    );
  }

  @Action(AuthAction.SignOutNoAuth)
  signOutNoAuth(_: StateContext<AuthStateModel>) {
    return this.authApi.signOut().pipe(
      tap(({ vId }) => vId && setClientVId(vId)),
      tap(() => this.signOutNoAuthService.handle())
    );
  }

  @Action(AuthAction.AuthenticateWithFacebook)
  facebook(ctx: StateContext<AuthStateModel>, action: AuthAction.AuthenticateWithFacebook) {
    return this.authApi.authenticateWithFacebook(action.data).pipe(
      tap(({ accessToken }) => ctx.patchState({ accessToken }))
    );
  }

  @Action(AuthAction.AuthenticateWithGoogle)
  google(ctx: StateContext<AuthStateModel>, action: AuthAction.AuthenticateWithGoogle) {
    return this.authApi.authenticateWithGoogle(action.data).pipe(
      tap(({ accessToken }) => ctx.patchState({ accessToken }))
    );
  }

  @Action(AuthAction.ForgotPassword)
  forgotPassword(_: StateContext<AuthStateModel>, action: AuthAction.ForgotPassword) {
    return this.authApi.forgotPassword(action.data).pipe(
      tap(API.validateResponse),
      tap((response: API.ForgotPasswordResponse) => sessionStorage.setItem(
        RESET_PASSWORD_KEY, JSON.stringify({ email: action.data.email, token: response?.data?.token
      })))
    );
  }

  @Action(AuthAction.VerifyOtpCode)
  verifyOtpCode(_: StateContext<AuthStateModel>, action: AuthAction.VerifyOtpCode) {
    return this.authApi.verifyOtpCode(action).pipe(
      tap(API.validateResponse),
      tap((response: API.VerifyOtpCodeResponse) => handleResetPasswordSessionData(response, 'otpCode', action.otpCode, this.snackbar))
    );
  }

  @Action(AuthAction.ChangePassword)
  changePassword(_: StateContext<AuthStateModel>, action: AuthAction.ChangePassword) {
    return this.authApi.changePassword(action).pipe(
      tap(API.validateResponse)
    );
  }

  @Action(AuthAction.GetUserDetails)
  getUserDetails(ctx: StateContext<AuthStateModel>) {
    return this.authApi.getUserDetails().pipe(
      tap((response: API.UserDetailsResponse) => {
        API.validateResponse(response, { ignoreKey: 'user' });
        ctx.dispatch(new UserAction.SetBlogsAndItineraryData(response.user));
        response?.visitor?.vId && setClientVId(response.visitor.vId);
      }),
    );
  }

}
