import { NgModule } from '@angular/core';
import { NgxsModule, NoopNgxsExecutionStrategy, getActionTypeFromInstance } from '@ngxs/store';
import { NgxsRouterPluginModule, RouterDataResolved, RouterNavigation, RouterNavigated } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { AuthStateModule, AUTH_STATE_TOKEN } from '@hiptraveler/data-access/auth';
import { UserStateModule, USER_STATE_TOKEN } from '@hiptraveler/data-access/user';
import { BrandStateModule, BRAND_STATE_TOKEN } from '@hiptraveler/data-access/brand';
import { SearchStateModule, SEARCH_STATE_TOKEN } from '@hiptraveler/data-access/search';
import { ItineraryStateModule, ITINERARY_STATE_TOKEN } from '@hiptraveler/data-access/itinerary';
import { ProfileStateModule, PROFILE_STATE_TOKEN } from '@hiptraveler/data-access/profile';

import { appId } from '@hiptraveler/common';
import { KeyWithExplicitEngine, keyEngine } from './state-key-engine';
import { environment } from '../../../environments/environment';

const LOCAL_STORAGE: KeyWithExplicitEngine[] = [
  // keyEngine(AUTH_STATE_TOKEN, 'local'), // For testing only
  // keyEngine(USER_STATE_TOKEN, 'local'), // For testing only
  // keyEngine(PROFILE_STATE_TOKEN, 'local'), // For testing only
  // keyEngine(ITINERARY_STATE_TOKEN, 'local'), // For testing only
  // keyEngine(SEARCH_STATE_TOKEN, 'local'), // For testing only
  // keyEngine(, 'session')
];

const SESSION_STORAGE: KeyWithExplicitEngine[] = [
];

const STATE_MODULES_IMPORT = [
  AuthStateModule,
  UserStateModule,
  BrandStateModule,
  SearchStateModule,
  ItineraryStateModule,
  ProfileStateModule
];

const filterActionInNgxsLoggerPlugin = (action: any) =>
  getActionTypeFromInstance(action) !== RouterDataResolved.type &&
  getActionTypeFromInstance(action) !== RouterNavigation.type &&
  getActionTypeFromInstance(action) !== RouterNavigated.type;

@NgModule({
  imports: [
    NgxsModule.forRoot(undefined, {
      compatibility: {
        strictContentSecurityPolicy: true
      },
      selectorOptions: {
        suppressErrors: true,
        injectContainerState: false
      },
      executionStrategy: NoopNgxsExecutionStrategy
    }),
    NgxsStoragePluginModule.forRoot({
      namespace: appId,
      key: [
        ...LOCAL_STORAGE,
        ...SESSION_STORAGE
      ]
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.local,
      filter: filterActionInNgxsLoggerPlugin
    }),
    NgxsRouterPluginModule.forRoot(),
    STATE_MODULES_IMPORT
  ]
})
export class AppStoreModule { }
