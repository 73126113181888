import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Directive, ElementRef, OnDestroy, AfterViewInit, Inject, Input, PLATFORM_ID, Renderer2 } from '@angular/core';

import { SkyscannerWidgetType } from './skyscanner-widget-directive.model';
import { getDataCurrency, getDataLocale, getDataMarket } from './skyscanner-widget.utils';
import { TranslationService } from '@hiptraveler/common';

const SKYSCANNER_WIDGET_API_KEY = 'API_B2B_18650_00003';
const SKYSCANNER_WIDGET_ID = 'hiptraveler-skyscanner-widget-script-id';

@Directive({
  selector: '[skyscannerWidget]'
})
export class SkyscannerWidgetDirective implements AfterViewInit, OnDestroy {

  @Input() skyscannerWidget?: SkyscannerWidgetType;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private translation: TranslationService
  ) { }

  ngAfterViewInit(): void {

    this.translation.onLangChange$.subscribe(() => {
      const skyscannerWidget = this.element.querySelectorAll('.skyscanner-widget').item(0);
      const skyscannerScript = this.element.querySelectorAll('.hiptraveler-skyscanner-widget-script-id').item(0);
      this.renderer.removeChild(this.element, skyscannerWidget);
      this.renderer.removeChild(this.element, skyscannerScript);
      setTimeout(this.addSkyscannerWidget.bind(this));
    });

    this.addSkyscannerWidget();
  }

  ngOnDestroy(): void {
    const element = this.elementRef.nativeElement.getElementsByClassName(SKYSCANNER_WIDGET_ID)[0];
    Array.from(element.children).forEach((childElement: Element) => {
      element.removeChild(childElement);
    })
  }

  get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

  get widgetElement(): HTMLDivElement | null {
    if (isPlatformServer(this.platformId)) return null;
    const div = this.document.createElement('div');

    div.className = 'skyscanner-widget';

    // Widget
    div.setAttribute('data-associate-id', SKYSCANNER_WIDGET_API_KEY);
    div.setAttribute('data-skyscanner-widget', this.skyscannerWidget || 'FlightSearchWidget');

    // Options
    div.setAttribute('data-locale', getDataLocale());
    div.setAttribute('data-market', getDataMarket());
    div.setAttribute('data-currency', getDataCurrency());
    div.setAttribute('data-origin-geo-lookup', 'true');
    
    // Element preferences
    div.setAttribute('data-button-label', this.translation.getText('skyscanner', 'search-flights'));
    div.setAttribute('data-enable-placeholders', 'true');
    div.setAttribute('data-target', '_blank');

    // Styles
    div.setAttribute('data-powered-by-logo-colour', 'default');
    div.setAttribute('data-powered-by-size', '1.5');
    div.setAttribute('data-button-font-colour', '#fff');
    div.setAttribute('data-button-colour', '#00a6ff');
    div.setAttribute('data-font-colour', '#fff');
    div.setAttribute('data-widget-padding', '20px 20px');

    setTimeout(() => {
      div.style.opacity = '1';
    }, 500);

    return div;
  }

  get widgetScript(): HTMLScriptElement | null {
    if (isPlatformServer(this.platformId)) return null;
    const script = this.document.createElement('script');
    script.className = SKYSCANNER_WIDGET_ID;
    script.src = 'https://widgets.skyscanner.net/widget-server/js/loader.js';
    script.async = true;
    return script;
  }

  addSkyscannerWidget(): void {
    this.renderer.appendChild(this.element, this.widgetElement);
    this.renderer.appendChild(this.element, this.widgetScript);
  }

}
