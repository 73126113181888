import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, combineLatest, map, takeUntil, tap } from 'rxjs';

import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, NavbarControlStateService, ScrollListenerService, SearchLocationService, SearchPageControlStateService, addItineraryUXStateKey, currentLang, getPathname } from '@hiptraveler/common';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Injectable()
export class SearchService implements OnDestroy {

  @Select(ItineraryState.actDateMap) actDateMap$: Observable<unknown>;

  #connectContentDisplayed: boolean;
  subscription$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService,
    private searchLocation: SearchLocationService,
    private scrollListener: ScrollListenerService,
    private experienceFinder: ExperienceFinderStateService,
    private searchPageControl: SearchPageControlStateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get searchLocationState$(): Observable<boolean> {
    return this.searchLocation.searchLocationState$.pipe(
      takeUntil(this.subscription$)
    );
  }

  get basicInfo$(): Observable<unknown> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map(result => result?.type === 'blog' ? null : result)
    );
  }

  get connectContentVisibility$(): Observable<boolean> {
    return this.scrollListener.scrollPositionClient$.pipe(
      map((scrollTop: number) => scrollTop > 800 || this.#connectContentDisplayed),
      tap((state: boolean) => {
        state && (this.#connectContentDisplayed = state);
      })
    );
  }

  get activitiesContentSpaceVisibility$(): Observable<boolean> {
    return this.store.select(SearchState.adventures).pipe(
      map(adventures => 
        !adventures || (!!adventures && !!adventures.length)
      )
    );
  }

  get connectContentSpaceVisibility$(): Observable<boolean> {
    return combineLatest([
      this.store.select(SearchState.travelAgents),
      this.store.select(SearchState.itineraries),
      this.store.select(SearchState.adventures)
    ]).pipe(
      map(([ travelAgents, itineraries, adventures ]) => 
        !!travelAgents?.length || !!itineraries?.length || !!adventures?.length
        || !travelAgents || !itineraries || !adventures
      )
    );
  }

  observe(): void {
    this.route.data.pipe(takeUntil(this.subscription$)).subscribe((data: Data) => {
      const homepage = data['homepage'] as boolean;
      if (homepage) {
        this.navbarControl.navbarToolbarVisibility$$.next(false);
        this.searchLocation.updateSearchLocation(undefined);
      } else {
        this.navbarControl.navbarToolbarVisibility$$.next(true);
        !!sessionStorage.getItem(addItineraryUXStateKey) || this.experienceFinder.overlayState$$.next(false);
        sessionStorage.removeItem(addItineraryUXStateKey);
      }
    });
  }

  observeNavigationLocation(): void {

    const hasLocationData = !!this.searchLocation.data;
    const isFromHomePage = this.appListener.previousUrl$$.value === `/${currentLang()}`;
    const isHomePage = getPathname(this.router.url) === `/${currentLang()}`;

    if (
      (isFromHomePage && hasLocationData) ||
      (isHomePage && this.searchLocation.previousValue)
    ) {
      this.store.dispatch(new SearchAction.ResetSearchState);
    }
  }

  observeComposePageLocationState(): void {

    const fromComposePage = this.appListener.previousUrl$$.value.includes(`/${currentLang()}/compose/`);
    const locationDataCity = this.store.selectSnapshot(SearchState.locationData)?.city;
    const itineraryActivityCity = this.searchPageControl.activityDate$$.value?.dayLocMap?.city;

    if (
      fromComposePage && locationDataCity && 
      (itineraryActivityCity !== locationDataCity)
    ) {
      this.store.dispatch(new SearchAction.ResetSearchState);
    }
  }

}
