import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SocialMediaFieldComponent } from './social-media-field.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatInputModule,
  MatFormFieldModule
];

const COMPONENTS = [
  SocialMediaFieldComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SocialMediaFieldComponent
  ]
})
export class SocialMediaFieldModule { }
