import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { MainMenuModule } from './main-menu/main-menu.module';
import { AuthMenuModule } from './auth-menu/auth-menu.module';
import { RegistrationMenuModule } from './registration-menu';
import { HomeMenuModule } from './home-menu/home-menu.module';
import { SelectionViewModule } from './shared/selection-view/selection-view.module';
import { TravelStyleModule } from './travel-style/travel-style.module';
import { PlanTripModule } from './plan-trip/plan-trip.module';
import { RequestPendingSkeletonModule } from './request-pending-skeleton/request-pending-skeleton.module';

import { ExperienceFinderComponent } from './experience-finder.component';

const MODULES = [
  CommonModule,
  MatDialogModule,
];

const LIBRARIES = [
  MainMenuModule,
  AuthMenuModule,
  RegistrationMenuModule,
  HomeMenuModule,
  SelectionViewModule,
  TravelStyleModule,
  PlanTripModule,
  RequestPendingSkeletonModule
];

const COMPONENTS = [
  ExperienceFinderComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ExperienceFinderComponent
  ]
})
export class ExperienceFinderScreenModule { }
