<ng-container *ngIf="(service.imageInformation$ | async) && !(control.mapViewDisplayState$ | async)">
  <div class="highlight--itinerary-action-bubble" actionBubbleLayout [dialogType]="dialogType" [@opacityFade]>

    <itinerary-action-bubble [clickSignal]="addToTripSignal" [active]="(data?.id | validateStatus | async)!">
      <itinerary-action [activityId]="data?.id || ''" [hotel]="dialogType === 'hotel'"></itinerary-action>
    </itinerary-action-bubble>

  </div>
</ng-container>

<div class="highlight--active">

  <ng-container *ngIf="control.mapViewDisplayState$ | async;else carouselView">
    <div leafletMap id="search-result-dialog-carousel-map" [data]="data" style="height: 100%;"></div>
  </ng-container>

  <ng-template #carouselView>

    <ng-container *ngIf="(service.imageInformation$ | async)?.photos?.length! > 2">
      <carousel-button left (click)="nextImage('prev')" [@opacityFade]></carousel-button>
    </ng-container>

    <div
      class="highlight--active-favorite" matRipple toggleFavorite [image]="data!" [dialogType]="dialogType"
      [class.active]="dialogType === 'hotel' ? (stateService.hotelFavoriteActive$ | async) : (stateService.favoriteActive$ | async)"
      [class.pending]="dialogType === 'hotel' ? !(stateService.hotelInformation$ | async) : !(stateService.imageInformation$ | async) || (stateService.favoriteProcessing$ | async)">
      <mat-icon svgIcon="common.favorite-svg"></mat-icon>
      <ng-container *ngIf="(stateService.hotelInformation$ | async) || (stateService.imageInformation$ | async)">
        <mat-progress-spinner class="primary" diameter="34" mode="indeterminate" *ngIf="stateService.favoriteProcessing$ | async"></mat-progress-spinner>
      </ng-container>
    </div>

    <asset-image [sourceImg]="activePhoto || data?.imgUrl || ''" highlightImage></asset-image>

    <ng-container *ngIf="(service.imageInformation$ | async)?.photos?.length! > 2">
      <carousel-button right (click)="nextImage('next')" [@opacityFade]></carousel-button>
    </ng-container>

  </ng-template>

</div>

<!-- <div class="highlight--slider" mouseDrag photoLength> -->
<div class="highlight--slider" mouseDrag photoLength [class.lts]="ltsSupport">
  
  <ng-container *ngIf="service.imageInformation$ | async as imageInformation;else inProgress">
    <div class="highlight--slider-image" [class.active]="currentIndex === i" *ngFor="let photo of imageInformation.photos || [];let i = index;">
      <asset-image [sourceImg]="photo" sliderImage (selected)="updateHighlightImage(imageInformation.photos, i)"></asset-image>
    </div>
  </ng-container>
  <ng-template #inProgress>
    <ng-container *ngIf="service.skeletonVisibility$ | async">
      <ngx-skeleton-loader count="20" appearance="line" animation="pulse"
        [ngStyle]="{ height: '75px' }"
        [theme]="{ 'border-radius': '4px', height: '72px', width: '100px' }">
      </ngx-skeleton-loader>
    </ng-container>
  </ng-template>

</div>

<!-- <div class="highlight--author" authorImageVisibility [dialogType]="dialogType"> -->
<div class="highlight--author" authorImageVisibility [dialogType]="dialogType" [class.lts]="ltsSupport">

  <ng-container *ngIf="service.imageInformation$ | async as imageInformation">
    <img [authorImage]="imageInformation.imgAuthInfo!" draggable="false" [@opacityFade] (click)="goToAffiliateSource(imageInformation.tourData?.tourUrl)">
  </ng-container>
  <ng-template #inProgressAuthorData>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>

</div>
