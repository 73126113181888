<div class="user-settings mat-elevation-z2" [class.processing]="service.formProcessing$$ | async">

  <div class="user-settings--actions">
    <ui-action-button theme="white" size="medium" elevation="2" (click)="stateService.profileSettingsView$$.next(false)">{{ 'profile.cancel' | translate }}</ui-action-button>
    <ui-action-button theme="primary" size="medium" elevation="2" [pending]="(service.formProcessing$$ | async)!" (click)="stateService.saveClicked$$.next(0)">{{ 'profile.save' | translate }}</ui-action-button>
  </div>

  <div class="user-settings--selection" *ngIf="stateService.settingsView$$ | async as settingsView">
    <p [class.active]="settingsView === 'profile'" (click)="service.changeView('profile')" translate="profile.profile"></p>
    <p [class.active]="settingsView === 'settings'" (click)="service.changeView('settings')" translate="profile.settings"></p>
    <p [class.active]="settingsView === 'notifications'" (click)="service.changeView('notifications')" translate="profile.notifications"></p>
    <ng-container *ngIf="service.travelAgentUser$ | async">
      <p [class.active]="settingsView === 'professional-profile'" class="professional-profile" (click)="service.changeView('professional-profile')" translate="profile.prof-profile"></p>
    </ng-container>
  </div>
  <div class="user-settings--form" [ngSwitch]="stateService.settingsView$$ | async">

    <profile-form *ngSwitchCase="'profile'" [@opacityFadeIn]></profile-form>

    <settings-form *ngSwitchCase="'settings'" [@opacityFadeIn]></settings-form>

    <notifications-form *ngSwitchCase="'notifications'" [@opacityFadeIn]></notifications-form>

    <professional-profile-form *ngSwitchCase="'professional-profile'" [@opacityFadeIn] autoWidth></professional-profile-form>

    <div class="user-settings--form-actions">

      <ui-action-button theme="white" size="medium" elevation="2" (click)="stateService.profileSettingsView$$.next(false)" *ngIf="(stateService.settingsView$$ | async) !== 'professional-profile'">{{ 'profile.cancel' | translate }}</ui-action-button>

      <ui-action-button theme="primary" size="medium" elevation="2" *ngIf="(stateService.settingsView$$ | async) !== 'professional-profile'" [pending]="(service.formProcessing$$ | async)!" (click)="stateService.saveClicked$$.next(0)">{{ 'profile.save' | translate }}</ui-action-button>

    </div>

  </div>

</div>
