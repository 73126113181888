import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ToFixedPipeModule, TranslateParamPipeModule } from '@hiptraveler/common';

import { SearchBannerActionsComponent } from './search-banner-actions.component';
import { BannerSelectionItemsComponent } from './banner-selection-items/banner-selection-items.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ToFixedPipeModule,
  TranslateParamPipeModule
];

const COMPONENTS = [
  SearchBannerActionsComponent,
  BannerSelectionItemsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SearchBannerActionsComponent
  ]
})
export class SearchBannerActionsModule { }
