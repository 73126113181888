import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { DataSelectionModule } from './data-selection/data-selection.module';

import { SummaryComponent } from './summary.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule
];

const LIBRARIES = [
  DataSelectionModule
];

const COMPONENTS = [
  SummaryComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    SummaryComponent
  ]
})
export class SummaryModule { }
