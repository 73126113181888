import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderScreenComponent } from './loader-screen.component';

const MODULES = [
  CommonModule
];

const COMPONENTS = [
  LoaderScreenComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    LoaderScreenComponent
  ]
})
export class LoaderScreenModule { }
