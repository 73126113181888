import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as SearchApi from './interfaces';
import * as Util from './utils';
import * as API from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {

  constructor(
    private http: HttpClient,
  ) { }

  getLocationDataByQuery(payload: SearchApi.SearchLocationDataActionQuery): Observable<SearchApi.SearchLocationDataResponse> {
    const queryString = Util.getLocationDataQueryString(payload);
    return this.http.get<SearchApi.SearchLocationDataResponse>(`/trip-planner/search/location?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getAutocompleteLocation(payload: SearchApi.AutocompleteLocationActionQuery): Observable<SearchApi.AutocompleteLocationDataResponse> {
    const queryString = Util.getAutocompleteLocationQueryString(payload);
    return this.http.get<SearchApi.AutocompleteLocationDataResponse>(`/trip-planner/search/autocomplete?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getBrandContent({ cId, brand, page }: SearchApi.BrandContentDto): Observable<any> {
    return this.http.get<any>(`/trip-planner/getBrandContent/${cId}/${currentLang()}/${brand}/${page}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getHotelsByQuery(payload: SearchApi.SearchHotelsActionQuery): Observable<SearchApi.SearchHotelsResponse> {
    const queryString = Util.getHotelsQueryString(payload);
    return this.http.get<SearchApi.SearchHotelsResponse>(`/trip-planner/search/hotels?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getAdventuresByQuery(payload: SearchApi.SearchHotelsActionQuery): Observable<SearchApi.SearchAdventuresResponse> {
    const queryString = Util.getAdventuresQueryString(payload);
    return this.http.get<SearchApi.SearchAdventuresResponse>(`/trip-planner/search/Adventure/location?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getFoodsByQuery(payload: SearchApi.SearchFoodsActionQuery): Observable<SearchApi.SearchFoodsResponse> {
    const queryString = Util.getFoodsQueryString(payload);
    return this.http.get<SearchApi.SearchFoodsResponse>(`/trip-planner/search/Food/location?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getItinerariesByQuery(payload: SearchApi.SearchItinerariesActionQuery): Observable<SearchApi.SearchItinerariesResponse> {
    const queryString = Util.getItinerariesQueryString(payload);
    return this.http.get<SearchApi.SearchItinerariesResponse>(`/trip-planner/search/Itineraries/location?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getCommunitiesByQuery(payload: SearchApi.SearchCommunitiesActionQuery): Observable<SearchApi.SearchCommunitiesResponse> {
    const queryString = Util.getCommunitiesQueryString(payload);
    return this.http.get<SearchApi.SearchCommunitiesResponse>(`/trip-planner/search/community?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getHotelsTermByQuery(payload: SearchApi.SearchHotelsTermByQuery): Observable<SearchApi.SearchHotelsResponse> {
    const queryString = Util.getHotelsTermByQuery(payload);
    return this.http.get<SearchApi.SearchHotelsResponse>(`/trip-planner/search/Hotels/term?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getAdventuresTermByQuery(payload: SearchApi.SearchAdventuresTermByQuery): Observable<SearchApi.SearchAdventuresResponse> {
    const queryString = Util.getAdventuresTermByQuery(payload);
    return this.http.get<SearchApi.SearchAdventuresResponse>(`/trip-planner/search/Adventure/term?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  getFoodsTermByQuery(payload: SearchApi.SearchFoodsTermByQuery): Observable<SearchApi.SearchFoodsResponse> {
    const queryString = Util.getFoodsTermByQuery(payload);
    return this.http.get<SearchApi.SearchFoodsResponse>(`/trip-planner/search/Food/term?${queryString}`).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

  toggleLikeItinerary({ id, hipType, field }: API.ToggleLikeItineraryDto<API.SearchResultField>): Observable<API.ToggleLikeItineraryResponse> {
    const payload = API.toFormData({ hipType, cId: brandCampaignId(), vId: clientVID() });
    const param = field === 'hotels' ? 'hotel' : 'image';
    const endpoint = field === 'itineraries' ? 'hipBoard' : `hipIt/${param}`;
    return this.http.post<API.ToggleLikeItineraryResponse>(`/trip-planner/${endpoint}/${id}`, payload).pipe(
      tap(API.handleErrorAndThrow)
    );
  }

}
