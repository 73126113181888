export * from './search-and-default-page';
export * from './itinerary-and-blog-page';
export * from './meta-tag.service';

import { SearchAndDefaultPageService, SearchAndDefaultPageObserverService } from './search-and-default-page';
import { ItineraryAndBlogPageService } from './itinerary-and-blog-page';
import { MetaTagService } from './meta-tag.service';

export const Providers = [
  SearchAndDefaultPageService,
  SearchAndDefaultPageObserverService,
  ItineraryAndBlogPageService,
  MetaTagService
];
