import { Directive, ElementRef, NgModule, OnInit } from '@angular/core';

import { ScrollListenerService } from '@hiptraveler/common';
import { FormSelection } from './registration-form.model';
import { RegistrationFormStateService } from './registration-form-state.service';

const OFFSET = 500;

@Directive({
  selector: '[activeSelection]'
})
export class ActiveSelectionDirective implements OnInit {

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private scrollListener: ScrollListenerService,
    private stateService: RegistrationFormStateService
  ) { }

  get id(): FormSelection { return this.element.nativeElement.id as FormSelection }

  ngOnInit(): void {
    this.observeElementPosition();
  }

  observeElementPosition(): void {
    const position = this.element.nativeElement.getBoundingClientRect().top;
    this.stateService.selectionTargetPosition(this.id, position);
    this.scrollListener.scrollPosition$.subscribe((scrollTop: number) => {
      if (
        this.id === 'general-information'
        && scrollTop < position + OFFSET
        && scrollTop < this.stateService.getTargetPosition('your-specialties') + OFFSET
        && scrollTop < this.stateService.getTargetPosition('register-your-interest') + OFFSET) {
        this.stateService.activeSelection$$.next(this.id);
      }
      if (
        this.id === 'your-specialties'
        && scrollTop < position + OFFSET
        && scrollTop > this.stateService.getTargetPosition('general-information') + OFFSET
        && scrollTop < this.stateService.getTargetPosition('register-your-interest') + OFFSET) {
        this.stateService.activeSelection$$.next(this.id);
      }
      if (
        this.id === 'register-your-interest'
        && scrollTop < position + OFFSET
        && scrollTop > this.stateService.getTargetPosition('general-information') + OFFSET
        && scrollTop > this.stateService.getTargetPosition('your-specialties') + OFFSET) {
        this.stateService.activeSelection$$.next(this.id);
      }
    });
  }

}

@NgModule({
  declarations: [ ActiveSelectionDirective ],
  exports:      [ ActiveSelectionDirective ]
})
export class ActiveSelectionModule { }
