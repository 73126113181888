import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ItemSelectionComponent } from './item-selection.component';
import { ImagePipe } from './image.pipe';
import { IncludesPipe } from './includes.pipe';
import { SelectionPipe } from './selection.pipe';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  ItemSelectionComponent
];

const PIPES = [
  ImagePipe,
  IncludesPipe,
  SelectionPipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    ItemSelectionComponent
  ]
})
export class ItemSelectionModule { }
