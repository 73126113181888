import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ProfileCardModule } from '@hiptraveler/ui/profile-card';

import { CommunityComponent } from './community.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule,
  NgxSkeletonLoaderModule
];

const LIBRARIES = [
  ProfileCardModule
];

const COMPONENTS = [
  CommunityComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: CommunityComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class CommunityModule { }
