<navbar-view (actionClicked)="dialogRef.close()"></navbar-view>

<div class="my-trips--wrapper">
  <div class="my-trips">

    <p class="my-trips--action" (click)="service.createNewTrip()">
      <mat-icon>add</mat-icon>
      <span translate="exp-finder.create-new-trip"></span>
    </p>
    
    <ng-container *ngIf="(service.itineraries$ | async) as itineraries;">
      <ng-container *ngIf="itineraries.length;">
        
      <div class="my-trips--list">
        <div
          class="my-trips--list-item" 
          *ngFor="let itinerary of itineraries;let isFirst = first;let isLast = last"
          [ngClass]="{ first: isFirst, last: isLast }">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path d="M17 13h-10v-1h10v1zm0 2h-10v1h10v-1zm0 3h-10v1h10v-1zm5-16v22h-20v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h6v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 7h-16v13h16v-13zm-12-8c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm10 0c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2z"></path>
          </svg>
          <p>{{ itinerary.title }}</p>
        </div>
      </div>
    
      </ng-container>
    </ng-container>
    
  </div>
</div>
