import { BrandInfo } from "@hiptraveler/data-access/api";

export const hiptravelerBrandInfo: Partial<BrandInfo> = {
  brandCampaign: {
    cCoverImg: {
      cover: 'https://d36j46pvk6qi4f.cloudfront.net/site/main/images/Hero2021-2200px.jpg'
    },
    cHashtag: 'The world is yours to experience.',
    cTagline: 'We simply help you to plan the Journey.',
    cTitle: 'HipTraveler',
    cId: 'hiptraveler',
    cLogo: {
      headerImg: 'assets/img/logos/HT-Grey-20210721.png'
    },
    campaignCustom: {
      hasCreatorSuite: true,
      loginHeader: true,
      navHeaders: [
        {
          category: 'All',
          default: true,
          link: 'search',
          location: 'main',
          name: {
            de: 'For you',
            en: 'For you',
            es: 'Para ti',
            fr: 'Pour vous',
            it: 'For you',
            zh: '给你的'
          }
        },
        {
          category: 'Itineraries',
          default: false,
          link: 'experiences',
          location: 'main',
          name: {
            de: 'Experiencia',
            en: 'Experiences',
            es: 'Experiencia',
            fr: 'Experiencia',
            it: 'Experiencia',
            zh: '经验'
          }
        },
        {
          category: 'Adventure',
          default: false,
          link: 'thingstodo',
          location: 'main',
          name: {
            de: 'Aventura',
            en: 'Play',
            es: 'Aventura',
            fr: 'Aventura',
            it: 'Aventura',
            zh: '冒险'
          }
        },
        {
          category: 'Hotels',
          default: false,
          link: 'hotels',
          location: 'main',
          name: {
            de: 'Hoteles',
            en: 'Stay',
            es: 'Hoteles',
            fr: 'Hoteles',
            it: 'Hoteles',
            zh: '逗留'
          }
        },
        {
          category: 'Food',
          default: false,
          link: 'foodanddrink',
          location: 'main',
          name: {
            de: 'Taste',
            en: 'Taste',
            es: 'Comida y bebida',
            fr: 'Taste',
            it: 'Taste',
            zh: '味道'
          }
        }
      ],
      socialLinks: {
        facebook: 'hiptraveler',
        instaId: '17841401223854424',
        instagram: 'hiptraveler',
        twitter: 'hiptraveler'
      },
      customCss: {
        tripPlannerSvg: ''
      },
      widgetSetting: true
    }
  }
};
