import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { PromptDialogComponent } from './prompt-dialog.component';
import { PromptDialogData } from './prompt-dialog.model';

export const PROMPT_DIALOG_ID = 'prompt-dialog';

@Injectable()
export class PromptDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  get reference(): MatDialogRef<PromptDialogComponent, unknown> | undefined {
    return this.dialog.getDialogById(PROMPT_DIALOG_ID);
  }

  get instance(): MatDialogRef<PromptDialogComponent, unknown> {
    if (!this.reference) throw 'Dialog does not exist.';
    return this.reference;
  }

  open(data: PromptDialogData): MatDialogRef<PromptDialogComponent, unknown> {
    const dialog = this.dialog.getDialogById(PROMPT_DIALOG_ID);
    if (dialog) return dialog;
    return this.dialog.open(PromptDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: PROMPT_DIALOG_ID,
      panelClass: PROMPT_DIALOG_ID + '-overlay-panel',
      width: '450px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data
    })
  }

  close(value?: boolean): void {
    const dialog = this.dialog.getDialogById(PROMPT_DIALOG_ID);
    if (dialog) return dialog.close(value);
  }

}
