<div class="activity-details" [ngClass]="{ component: component === '' }">

  <div class="details--title details--text-section">

    <h1>
      <span>{{ data?.name }}</span>
      <mat-icon class="details--title-open-in-new">open_in_new</mat-icon>
    </h1>

    <ng-container *ngIf="component === '' && (dialogType | buttonVisibility | async)">
      <ui-action-button hoverElevation theme="secondary" class="tour-info--check-availability">{{ 'search-result.check-availability' | translate }}</ui-action-button>
    </ng-container>

  </div>
  
  <ng-container *ngIf="component !== ''">
  
    <ng-container *ngIf="(service.imageInformation$ | async)?.location || data?.location as location">
      <div class="details--location details--text-section">
        <p>{{ location }} <span (click)="control.toggleDisplayMap()" translate="search-result.map"></span></p>
      </div>
    </ng-container>

    <ng-container *ngIf="dialogType === 'adventure'">
      <tour-reviews [data]="service.imageInformation$"></tour-reviews>
    </ng-container>
  
    <ng-container *ngIf="dialogType === 'adventure'">
      <tour-information [data]="data" [dialogType]="dialogType" [imageInformation]="service.imageInformation$ | async"></tour-information>
    </ng-container>
  
    <ng-container *ngIf="dialogType === 'food'">
      <yelp-reviews [imageInformation]="service.imageInformation$ | async"></yelp-reviews>
    </ng-container>
  
    <trip-advisor [data]="(service.imageInformation$ | async)?.taDataMap"></trip-advisor>

  </ng-container>

</div>
