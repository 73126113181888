<div class="amenity-details ht-dialog-elevation">

  <h2 translate="search-result.amenities"></h2>

  <div class="amenity-details--list" [class.no-see-more]="data.amenities.length < 4">

    <div *ngFor="let amenity of data.amenities.slice(0, 4);">
      <mat-icon svgIcon="search-result-dialog-common.inclusion-svg"></mat-icon>
      <p>{{ amenity }}</p>
    </div>
    <ng-container *ngIf="progressSeeMore">
      <div *ngFor="let amenity of data.amenities.slice(4);" (click)="progressSeeMore = !progressSeeMore" [@inOutCollapse26]>
        <mat-icon svgIcon="search-result-dialog-common.inclusion-svg"></mat-icon>
        <p>{{ amenity }}</p>
      </div>
    </ng-container>

  </div>

  <ui-action-button theme="outlined-white" (click)="progressSeeMore = !progressSeeMore" *ngIf="data.amenities.length >= 4">
    {{ (progressSeeMore ? 'search-result.see-less' : 'search-result.see-more') | translate }}
  </ui-action-button>

</div>
