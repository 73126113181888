<form class="overlay-travel-experience--filters" [formGroup]="service.form" customStyles>

  <div class="filters--select" [class.active]="(stateService.selectedFilter$ | async) === 'experience'" (click)="stateService.selectedFilter$$.next('experience')">
    <span translate="exp-finder.filters-experience"></span>
    <span *ngIf="service.form.get('experience')?.value?.length as count">&nbsp;({{ count }})</span>
    <mat-icon (click)="experienceSelectForm.open()">arrow_drop_down</mat-icon>
    <mat-form-field color="accent">
      <mat-select formControlName="experience" panelClass="experience--class" multiple #experienceSelectForm>
        <mat-option *ngFor="let item of 'experience' | filterCategory | async" [value]="item.code">
          {{ item.tname ? (item.tname | translate) : item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="filters--select" [class.active]="(stateService.selectedFilter$ | async) === 'travelStyle'" (click)="stateService.selectedFilter$$.next('travelStyle')">
    <span translate="exp-finder.filters-travel-style"></span>
    <span *ngIf="service.form.get('travelStyle')?.value?.length as count">&nbsp;({{ count }})</span>
    <mat-icon (click)="travelStyleSelectForm.open()">arrow_drop_down</mat-icon>
    <mat-form-field color="accent">
      <mat-select formControlName="travelStyle" panelClass="travelStyle--class" multiple #travelStyleSelectForm>
        <mat-option *ngFor="let item of 'travelStyle' | filterCategory | async" [value]="item.code">
          {{ item.tname ? (item.tname | translate) : item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="filters--select" [class.active]="(stateService.selectedFilter$ | async) === 'activity'" (click)="stateService.selectedFilter$$.next('activity')">
    <span translate="exp-finder.filters-activities"></span>
    <span *ngIf="service.form.get('activity')?.value?.length as count">&nbsp;({{ count }})</span>
    <mat-icon (click)="activitiesSelectForm.open()">arrow_drop_down</mat-icon>
    <mat-form-field color="accent">
      <mat-select formControlName="activity" panelClass="activity--class" multiple #activitiesSelectForm>
        <mat-option *ngFor="let item of 'activity' | filterCategory | async" [value]="item.code">
          {{ item.tname ? (item.tname | translate) : item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <p class="filters--action" (click)="clearFilters()" translate="exp-finder.filters-clear"></p>

</form>
