import { generateUUID, getWindowRef } from "@hiptraveler/common";

const HT_VISITOR_ID_KEY: string = `_ht_vId`;

const DEFAULT_CONFIG: ClientVidConfig = {
  create: false,
  generate: false
};

interface ClientVidConfig {
  create?: boolean;
  generate?: boolean;

}

export function clientVID({ create, generate }: ClientVidConfig = DEFAULT_CONFIG): string | null {
  if (create) {
    return generateUUIDandStore();
  } else if (generate) {
    return getStorageValue() || generateUUIDandStore();
  } else {
    return getStorageValue();
  }
}

function generateUUIDandStore():string{
  const generatedVID = generateUUID();
    setClientVId(generatedVID);
    return generatedVID;
}

function getStorageValue(): string | null {
  return getWindowRef()?.localStorage?.getItem(HT_VISITOR_ID_KEY) || null;
}

export function setClientVId(value?: string): void {
  getWindowRef()?.localStorage?.setItem(HT_VISITOR_ID_KEY, value);
}

export function removeVID(): void {
  getWindowRef()?.localStorage?.removeItem(HT_VISITOR_ID_KEY);
}
