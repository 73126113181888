<dialog-close-button *ngIf="!service.processing"></dialog-close-button>

<h2 translate="send-message.title"></h2>

<mat-form-field appearance="outline" [ngClass]="{ processing: service.processing}">
  <mat-label>{{ 'send-message.message' | translate }}</mat-label>
  <textarea matInput [placeholder]="'send-message.type-message' | translate" rows="7" name="message" [formControl]="messageControl" #input></textarea>
</mat-form-field>

<div class="send-message--actions" [ngClass]="{ processing: service.processing}">
  <ui-action-button size="medium" theme="outlined-primary" (click)="messageControl.reset()">{{ 'send-message.clear' | translate }}</ui-action-button>
  <ui-action-button size="medium" iconRight="send" [pending]="service.processing" (click)="service.sendMessage(messageControl.value)">{{ 'send-message.send' | translate }}</ui-action-button>
</div>
