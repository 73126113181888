import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderModule } from './header/header.module';

import { ConnectComponent } from './connect.component';
import { FacebookIframeDirective } from './facebook-iframe.directive';
import { InstagramIframeDirective } from './instagram-iframe.directive';
import { YoutubeIframeDirective } from './youtube-iframe.directive';
import { TwitterIframeDirective } from './twitter-iframe.directive';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  HeaderModule
];

const COMPONENTS = [
  ConnectComponent
];

const DIRECTIVES = [
  FacebookIframeDirective,
  InstagramIframeDirective,
  YoutubeIframeDirective,
  TwitterIframeDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    ConnectComponent
  ]
})
export class ConnectModule { }
