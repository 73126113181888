import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { ComposePrivateNoteDialogComponent } from './compose-private-note-dialog.component';

const MODULES = [
  CommonModule,
  MatNativeDateModule,
  ReactiveFormsModule,
  MatDialogModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  ButtonsModule
];

const COMPONENTS = [
  ComposePrivateNoteDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    MatDialogModule
  ]
})
export class ComposePrivateNoteDialogModule { }
