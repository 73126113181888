import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';

@Injectable()
export class FeatureCardStateService {
  
  private cardProcessingState$$ = new BehaviorSubject<string[]>([]); // Toggle processing UX of search result card
  cardProcessingState$ = this.cardProcessingState$$.asObservable().pipe(delay(0));
  pushCardProcessing(id: string): void {
    const ids = [ ...this.cardProcessingState$$.value ];
    ids.push(id);
    this.cardProcessingState$$.next(ids);
  }
  removeCardProcessing(id: string): void {
    const ids = [ ...this.cardProcessingState$$.value ];
    this.cardProcessingState$$.next(ids.filter(e => e !== id));
  }

}
