<div class="user-info" [class.default]="service.hiptraveler$ | async">

  <div class="user-info--image-wrapper mat-elevation-z4" [class.processing]="stateService.uploadImageProcessing$$ | async">
    <ng-container *ngIf="service.id$ | async;else notAuthenticated">
      <img class="user-info--image" [lazyLoad]="(service.picUrl$ | async)!" [defaultImage]="authenticatedProfilePlaceholder" [errorImage]="authenticatedProfilePlaceholder" draggable="false" alt="Profile image"> 
    </ng-container>
    <ng-template #notAuthenticated>
      <img class="user-info--image" [lazyLoad]="(service.picUrl$ | async)!" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" draggable="false" alt="Profile image"> 
    </ng-template>
    <div class="user-info--image-overlay" matRipple uploadProfilePhoto *ngIf="stateService.selfProfile$ | async">
      <ng-container *ngIf="stateService.uploadImageProcessing$$ | async;else defaultHoverUI">
        <mat-progress-spinner mode="indeterminate" theme="white" [diameter]="50" [@opacityFade]></mat-progress-spinner>
      </ng-container>
      <ng-template #defaultHoverUI>
        <mat-icon svgIcon="page-profile-user-info.photo-svg" [@opacityFade]></mat-icon>
      </ng-template>
    </div>
  </div>
  
  <ng-container *ngIf="service.profileDetails$ | async as user">
    <div class="user-info--details" [@opacityFade]>
      <p>{{ user.firstName }} {{ user.lastName }}</p>
      <p>{{ user.userLoc }}</p>
    </div>
    <div class="user-info--action" [@opacityFade] *ngIf="!(stateService.selfProfile$ | async)">
      <ui-action-button [size]="(mobileAction$ | async) ? 'small' : 'medium'" [@opacityFade] (click)="service.sendMessage()">{{ 'profile.message' | translate }}</ui-action-button>
      <ui-action-button [size]="(mobileAction$ | async) ? 'small' : 'medium'" [pending]="!!(service.followProcessing$$ | async)" [@opacityFade] (click)="service.follow()">
        {{ ((service.followStatus$ | async) ? 'profile.following' : 'profile.follow') | translate  }}
      </ui-action-button>
    </div>
  </ng-container>

</div>
