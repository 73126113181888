import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { FooterService } from './footer.service';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { CDNReaderService } from '@hiptraveler/common';

@Component({
  selector: 'hiptraveler-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ FooterService, AuthDialogActionService, CDNReaderService ]
})
export class FooterComponent implements OnInit {

  constructor(
    public service: FooterService
  ) { }

  ngOnInit(): void {
    this.service.stateObserver();
  }

}
