import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { LocationDataGallery } from '@hiptraveler/data-access/api';
import { SearchState } from '@hiptraveler/data-access/search';
import { OverlayCarouselDialogService } from './overlay-carousel-dialog.service';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'app-overlay-carousel-dialog',
  templateUrl: './overlay-carousel-dialog.component.html',
  styleUrls: ['./overlay-carousel-dialog.component.scss'],
  viewProviders: [ OverlayCarouselDialogService ],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-overlay-carousel-dialog' },
  animations: [ opacityFade() ]
})
export class OverlayCarouselDialogComponent {

  currentIndex: number = 0;
  activePhoto?: string;

  constructor(
    private store: Store,
    public service: OverlayCarouselDialogService
  ) { }

  nextImage(option: 'next' | 'prev'): void {

    const gallery = this.store.selectSnapshot(SearchState.locationData)?.gallery || [];

    if (option === 'prev' && (this.currentIndex === 0)) {
      this.currentIndex = gallery.length;
      this.activePhoto = gallery?.[this.currentIndex]?.gImgUrl;
    }
    if (option === 'prev') {
      this.activePhoto = gallery?.[--this.currentIndex]?.gImgUrl;
    }
    if (option === 'next' && this.currentIndex < gallery.length) {
      this.activePhoto = gallery?.[++this.currentIndex]?.gImgUrl;
    }
    if (option === 'next' && (this.currentIndex === gallery.length)) {
      this.currentIndex = 0;
      this.activePhoto = gallery?.[this.currentIndex]?.gImgUrl;
    }
  }

  updateHighlightImage(photos: LocationDataGallery[] | null, index: number): void {
    this.activePhoto = (photos || [])[index]?.gImgUrl;
    this.currentIndex = index;
  }

}
