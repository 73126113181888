import { Pipe, PipeTransform } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { ActivityDate } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Pipe({
  name: 'addedToAllDays'
})
export class AddedToAllDaysPipe implements PipeTransform {

  @Select(ItineraryState.actDateMap) actDateMap$: Observable<ActivityDate | null>;

  transform(activityId: string): Observable<boolean> {
    return this.actDateMap$.pipe(
      map((actDateMap: ActivityDate | null) => {
        return Object.keys(actDateMap || {}).map((_, index: number) => {
          const includedFromHotels = actDateMap?.[index]?.HotelArray?.some(e => e.id === activityId);
          const includedFromImages = actDateMap?.[index]?.ImgArray?.some(e => e.id === activityId);
          return !(includedFromHotels || includedFromImages)
        }).every(Boolean);
      }),
    );
  }

}
