import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dont-sell-info',
  templateUrl: './dont-sell-info.component.html',
  styleUrls: ['./dont-sell-info.component.scss'],
  host: { class: 'ht-dont-sell-info' },
  encapsulation: ViewEncapsulation.None
})
export class DontSellInfoComponent {

  @Output() actionClicked = new EventEmitter<void>();

  @Input() form: FormGroup;
  @Input() pending: boolean;
  @Input() processing: boolean;
  @Input() actionButton: string;

}
