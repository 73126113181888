import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PostActionButtonModule } from '@hiptraveler/features/post-action-button';
import { TravelAgentsContentModule } from './travel-agents-content/travel-agents-content.module';
import { ExperiencesContentModule } from './experiences-content/experiences-content.module';
import { ActivitiesContentModule } from './activities-content/activities-content.module';
import { HotelsContentModule } from './hotels-content/hotels-content.module';
import { RestaurantsContentModule } from './restaurants-content/restaurants-content.module';
import { ConnectContentModule } from './connect-content/connect-content.module';
import { TripDetailContentModule } from './trip-detail-content/trip-detail-content.module';

import { SearchComponent } from './search.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  PostActionButtonModule,
  TravelAgentsContentModule,
  ExperiencesContentModule,
  ActivitiesContentModule,
  HotelsContentModule,
  RestaurantsContentModule,
  ConnectContentModule,
  TripDetailContentModule
];

const COMPONENTS = [
  SearchComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: SearchComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ]
})
export class SearchModule { }
