<ng-container *ngIf="travelStyle === '' || (service.searchPage$ | async) === 'thingstodo';else defaultUI">
  <div class="list-panel--header-selections">
    <h1 (pointerdown)="type = 'exp'" [class.active]="type === 'exp'" translate="search-page.experience"></h1>
    <h1 (pointerdown)="type = 'travelStyle'" [class.active]="type === 'travelStyle'" translate="search-page.travel-style"></h1>
    <ng-container [ngSwitch]="service.searchPage$ | async">
      <h1 *ngSwitchCase="'hotels'" (pointerdown)="type = 'amenities'" [class.active]="type === 'amenities'" translate="search-page.amenities"></h1>
      <h1 *ngSwitchCase="'foodanddrink'" (pointerdown)="type = 'amenities'" [class.active]="type === 'amenities'"translate="search-page.cuisine"></h1>
      <h1 *ngSwitchDefault (pointerdown)="type = 'activities'" [class.active]="type === 'activities'" translate="search-page.activities"></h1>
    </ng-container>
  </div>
</ng-container>
<ng-template #defaultUI>
  <h1>{{ header }}</h1>
</ng-template>

<div class="list-panel--list-items" scrollElementBorder [class.travel-style]="travelStyle === '' || (service.searchPage$ | async) === 'thingstodo'">
  <ng-container *ngFor="let item of data | typeFilter: type">
    <ng-container *ngIf="filtersService[highlight ? 'selectedTravelStyleKeys$' : 'selectedItemKeys$'] | async as selected">
      <!-- <p matRipple (pointerdown)="toggleSelection(item.value)" [class.active]="item | selection: selected"> -->
      <p (pointerdown)="toggleSelection(item.value)" [class.active]="item | selection: selected">
        {{ ("views.label." + item?.name) | translate | itemName }}
        <mat-icon *ngIf="item | selection: selected">done</mat-icon>
      </p>
    </ng-container>
  </ng-container>
</div>

<div class="list-panel--actions">
  <ui-action-button size="medium" (pointerdown)="applyFilters()">{{ 'search-page.apply' | translate }}</ui-action-button>
  <ui-action-button size="medium" theme="white" (pointerdown)="clearFilters()">{{ 'search-page.clear-filters' | translate }}</ui-action-button>
</div>
