<ng-content select="[profileImage]"></ng-content>

<div class="profile-card--info">
  <ng-content select="profile-name"></ng-content>
  <ng-content select="profile-location"></ng-content>
</div>

<div class="profile-card--action" [class.active]="active || processing">
  <ng-container *ngIf="processing;else defaultUI">
    <mat-progress-spinner class="primary" diameter="18" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
  </ng-container>
  <ng-template #defaultUI>
    <mat-icon svgIcon="common.follow-svg"></mat-icon>
    <ng-content select="profile-action-button"></ng-content>
  </ng-template>
</div>
