import { Component, ViewEncapsulation } from '@angular/core';

import { StoryContentService } from './story-content.service';
import { MoreContentDetailsDialogActionService } from './more-content-details';

@Component({
  selector: 'story-content',
  templateUrl: './story-content.component.html',
  styleUrls: ['./story-content.component.scss'],
  host: { class: 'story-content' },
  viewProviders: [ StoryContentService, MoreContentDetailsDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class StoryContentComponent {

  constructor(
    public service: StoryContentService
  ) { }

}
