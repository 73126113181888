import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ComponentStateService, preventUpdateFormStateByPlaceResult } from '@hiptraveler/features/itinerary';
import { ContentEditableService, EditableSelectionsComponent } from '../common';
import { ComposeBlogFormService } from './compose-blog-form.service';
import { SearchLocationData, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';

@Component({
  selector: 'compose-blog-form',
  templateUrl: './compose-blog-form.component.html',
  styleUrls: ['./compose-blog-form.component.scss'],
  host: { class: 'page-compose-compose-blog-form' },
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ ComposeBlogFormService ]
})
export class ComposeBlogFormComponent implements OnInit, AfterViewInit {

  @ViewChild(EditableSelectionsComponent) editableSelectionsComponent: EditableSelectionsComponent;
  @ViewChild('editable') editable: ElementRef<HTMLDivElement>;

  itineraryVisibility: boolean;
  pendingButton: boolean;

  constructor(
    private cdRef: ChangeDetectorRef,
    public service: ComposeBlogFormService,
    public componentState: ComponentStateService,
    public contentEditable: ContentEditableService
  ) { }
  
  ngOnInit(): void {
    getWindowRef()[requestEndpointParamValue] = 'blog';
    this.service.componentObserver();
  }

  ngAfterViewInit(): void {
    this.contentEditable.mediumEditorObserver(
      this.editable,
      this.editableSelectionsComponent
    );
  }

  async locationChanges(locationData: SearchLocationData): Promise<void> {
    if (getWindowRef()[preventUpdateFormStateByPlaceResult]) return;

    await this.componentState.requestAndPatchLocationToState(locationData, this.service.form.value.storyTitle);
    this.service.form.patchValue({ storyLocation: '' });
    this.cdRef.detectChanges();
  }

  removeLocationItem(index: number): void {
    if (this.componentState.subscription$$.value?.processing) return;
    this.editableSelectionsComponent?.service?.hideHostElement();
    this.componentState.removeItineraryLocation(index);
  }

  async addItinerary(): Promise<void> {
    const dayLocMap = this.componentState.subscription$$.value?.locationList?.[0];
    if (this.pendingButton || this.service.unsavedBlog) return;
    try {
      this.pendingButton = true;
      await firstValueFrom(this.service.dispatchAddItineraryDay(dayLocMap));
      this.itineraryVisibility = true;
      this.pendingButton = false;
    } catch(e) {
      this.pendingButton = false;
      this.addItinerary();
    }
  }
  
  async removeItinerary(): Promise<void> {
    if (this.pendingButton) return;
    try {
      this.pendingButton = true;
      await firstValueFrom(this.service.dispatchRemoveItinerary());
      this.itineraryVisibility = false;
    } finally {
      this.pendingButton = false;
    }
  }

}
