import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import { EmailShareResponse, MessagingApiService } from '@hiptraveler/data-access/api';
import { SnackbarService } from '@hiptraveler/snackbar';
import { DataParam, SEND_MESSAGE_DIALOG_ID } from './send-message-dialog-action.service';

@Injectable()
export class SendMessageDialogService {

  processing: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DataParam,
    private dialog: MatDialog,
    private messagingApi: MessagingApiService,
    private snackbar: SnackbarService
  ) { }

  async sendMessage(message: string): Promise<void> {

    if (this.processing || !message) return;

    if (!this.data.profileId) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.' });
      return;
    }

    try {
      this.processing = true;
      this.snackbar.open({ message: 'Please wait.', duration: Infinity });
      this.dialog.getDialogById(SEND_MESSAGE_DIALOG_ID)!.disableClose = true;
      const response = await this.sendMessageRequest(message);

      if (!response?.success) throw response?.message;

      this.snackbar.open({ message: 'Your message has been sent successfully!' });
      const dialog = this.dialog.getDialogById(SEND_MESSAGE_DIALOG_ID);
      if (dialog) return dialog.close();
    } catch (error) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.' });
    } finally {
      this.dialog.getDialogById(SEND_MESSAGE_DIALOG_ID)!.disableClose = false;
      this.processing = false;
    }
  }

  sendMessageRequest(message: string): Promise<EmailShareResponse> {
    return this.data.travelAgent 
      ? firstValueFrom(this.messagingApi.sendMessage({
        profId: this.data.profileId,
        msg: encodeURIComponent(message)
      })) 
      : firstValueFrom(this.messagingApi.travelAgentSendMessage({
        profId: this.data.profileId,
        msg: encodeURIComponent(message)
      }));
  }

}
