import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { UserInfoComponent } from './user-info.component';
import { UploadProfilePhotoDirectiveModule } from '../../common';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ButtonsModule,
  UploadProfilePhotoDirectiveModule
];

const COMPONENTS = [
  UserInfoComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    UserInfoComponent
  ]
})
export class UserInfoModule { }
