import { Component, OnInit } from '@angular/core';

import { NotificationsFormService } from './notifications-form.service';
import { ProfileStateFormService } from './../../../common';

@Component({
  selector: 'notifications-form',
  template: `
    <h1 translate="profile.notification-center"></h1>
    <dont-sell-info [form]="stateFormService.notificationForm"></dont-sell-info>
  `,
  styles: [`
    h1 {
      font-size: 24px;
      text-align: center;
    }
  `],
  viewProviders: [ NotificationsFormService ]
})
export class NotificationsFormComponent implements OnInit {

  constructor(
    public service: NotificationsFormService,
    public stateFormService: ProfileStateFormService
  ) { }

  ngOnInit(): void {
    this.service.initializeFormValues();
  }

}
