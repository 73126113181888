import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

const MAX_LENGTH: number = 500;

@Directive({
  selector: '[innerHTML]'
})
export class InnerHTMLDirective implements OnInit {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

    const element = this.elementRef.nativeElement;
    const elements = element.querySelectorAll('p');
    let paragraphContent = '';
    let targetElement: number | undefined;

    elements.forEach((element: HTMLParagraphElement, index: number) => {
      paragraphContent += element.textContent;

      if (index === 0 && element.textContent?.length) {
        targetElement = index;
      }
      if (paragraphContent.length < MAX_LENGTH && targetElement === undefined) {
        targetElement = index;
      }
      if (targetElement !== undefined && paragraphContent.length < MAX_LENGTH) {
        targetElement = targetElement + 1;
      }
    });
    
    paragraphContent = '';
    Array.from(elements).slice(0, targetElement! + 1).forEach((pElement: HTMLParagraphElement) => {
      pElement.classList.add('display');
      paragraphContent += pElement.textContent;

      if (paragraphContent.length > MAX_LENGTH) {
        const target = paragraphContent.length - MAX_LENGTH;
        const textContent = pElement.textContent!.slice(0, pElement.textContent!.length - target);
        pElement.textContent = `${textContent}...`;
      }

      this.renderer.appendChild(element, pElement);
    });
  }
  

}
