import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { StoryImageComponent } from './story-image.component';
import { DividerSvgIconComponent } from './divider-svg-icon.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  TranslateModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  StoryImageComponent,
  DividerSvgIconComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    StoryImageComponent
  ]
})
export class StoryImageModule { }
