import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { startWith } from 'rxjs';

import { FooterNavigationService } from './footer-navigation.service';
import { ENVIRONMENT, Environment, currentLang } from '@hiptraveler/common';
import { ImageSvgExternalService } from '@hiptraveler/data-access/external';

@Component({
  selector: 'app-footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss'],
  viewProviders: [ FooterNavigationService ]
})
export class FooterNavigationComponent {

  display$ = this.imageSvg.svgCollection$.pipe(startWith(false));

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private router: Router,
    private imageSvg: ImageSvgExternalService,
    public service: FooterNavigationService
  ) { }

  get currentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  devNavigation(): void {
    if (!this.environment.production && !this.environment.local) {
      this.router.navigateByUrl(`/${currentLang()}/5ec4e10998603520ceb30fe5/addtoFavorites/experience-finder/9da25d1a04c0e30d10df737d99738ac44deb69b2/menu`);
    }
  }

}
