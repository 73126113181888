export * from './profile.model';
export * from './profile-state.service';
export * from './profile-state-form.service';
export * from './state-service';
export * from './utilities';
export * from './data-access.service';
export { UploadProfilePhotoDirectiveModule } from './upload-profile-photo.directive';

import { BannerModule } from './../banner/banner.module';
import { SidenavModule } from './../sidenav/sidenav.module';
import { ActivityContentModule } from './../activity-content/activity-content.module';
import { MapContentModule } from './../map-content/map-content.module';

export const ProfileComponentModules = [
  BannerModule,
  SidenavModule,
  ActivityContentModule,
  MapContentModule
];
