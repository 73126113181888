import { Store } from "@ngxs/store";

import { BrandState } from "@hiptraveler/data-access/brand";
import { getWindowRef, globalStateManagementStoreKey } from "..";

/* */

export type ClientSource = 'iframe-widget' | 'widget' | 'site';

export function clientSource(): ClientSource {
  return isBrandIframeWidget() ? 'iframe-widget' : isWidget() ? 'widget' : 'site';
};

/* */

export function isIframe(): boolean {
  return getWindowRef()?.self !== getWindowRef()?.top;
}

/* */

export function isWidget(): boolean {
  return [ 'experience-finder', 'addtoFavorites' ].some((item: string) => {
    return getWindowRef()?.location?.pathname?.includes(item)
  });
}

/* */

export function isBrandIframeWidget(): boolean {

  const store = getWindowRef()?.[globalStateManagementStoreKey] as Store | undefined
  const brand = store?.selectSnapshot(BrandState.brandCampaign);

  return !!brand?.isIframe && !!brand?.iframeLink;
}

/* */

const WINDOW_WIDGET_RETURN_KEY: string = 'global_window_widget_return';

export function isWidgetReturn(): boolean {
  return getWindowRef()?.[WINDOW_WIDGET_RETURN_KEY] || false;
}

export function setWidgetReturnValue(value: boolean): void {
  getWindowRef()[WINDOW_WIDGET_RETURN_KEY] = value;
}

/* */

const WINDOW_FAVORITES_KEY: string = 'global_window_favorite_value';

export function isFavorites(): boolean {
  return getWindowRef()?.[WINDOW_FAVORITES_KEY] || false;
}

export function setFavoritesValue(value: boolean): void {
  getWindowRef()[WINDOW_FAVORITES_KEY] = value;
}

/* */

export function iframeWindowFixedTop(state: boolean): void {
  getWindowRef()?.top?.postMessage({
    message: 'ht_iframe_top',
    fixTop: state
  }, '*');
}
