import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { currentLang } from '@hiptraveler/common';

@Injectable()
export class TripDetailContentService implements OnDestroy {

  @Select(ItineraryState.basicInfo) basicInfo$: Observable<Partial<BasicInfoData> | null>;

  subscription$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store
  ) { }

  private get pageTItle(): string {
    return this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle || '';
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  navigateToItinerary(): void {
    this.router.navigate([ currentLang(), 'itinerary', this.pageTItle ]);
  }

}
