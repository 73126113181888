import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, map, of, switchMap, take, takeUntil } from 'rxjs';

import { LocationData, SearchApiService } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { BAHAMAS_BRAND_NAME, subdomain } from '@hiptraveler/common';
import { responseToValue } from './custom-map-autocomplete.util';

@Injectable()
export class CustomMapAutocompleteService implements OnDestroy {

  #subscription$ = new Subject<void>();
  
  cmaChanges$$ = new BehaviorSubject<string>('');

  constructor(
    private store: Store,
    private searchApi: SearchApiService
  ) { }

  ngOnDestroy(): void {
    this.#subscription$.next();
  }

  get locations$(): Observable<LocationData[] | null> {
    return this.cmaChanges$$.asObservable().pipe(
      map((value: string) => {
        const locations = this.store.selectSnapshot(BrandState.locations);

        if (subdomain() === BAHAMAS_BRAND_NAME) {
          return value ? null : locations;
        }

        return value ? (locations?.filter(e => e.city.toLowerCase().includes(value)) || locations) : locations;
      }),
      map((locations: LocationData[] | null) => {
        return locations?.map(e => ({ ...e, formattedAddr: e.city })) || null;
      }),
      takeUntil(this.#subscription$)
    );
  }

  requestState(callback: (location: LocationData[] | null, requesting: boolean) => void): void {
    this.locations$.pipe(
      switchMap((locations: LocationData[] | null) => {
        return this.cmaChanges$$.asObservable().pipe(
          switchMap((input: string) => {

            callback(locations, false);
            const requestApi = !!input && locations === null;
    
            if (requestApi) {
              callback([], true);
            }
    
            return requestApi
              ? this.searchApi.getAutocompleteLocation({ q: input }).pipe(
                  map(e => responseToValue(e)),
                  take(1)
                )
              : of(locations);
          })
        )
      }),
      takeUntil(this.#subscription$)
    ).subscribe((locations: LocationData[] | null) => {
      callback(locations, false);
    });
  }

}
