import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchResultDialogControlService {

  mapViewDisplayState$$ = new BehaviorSubject<boolean>(false);
  mapViewDisplayState$ = this.mapViewDisplayState$$.asObservable();

  constructor() { }

  toggleDisplayMap(): void {
    this.mapViewDisplayState$$.next(!this.mapViewDisplayState$$.value);
  }

}
