import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { HotelOffersDialogActionService } from '@hiptraveler/dialogs/hotel-offers-dialog';

@Injectable()
export class PriceOffersService {

  @Select(ItineraryState.hotelInformation) hotelInformation$: Observable<HotelResultData | null>;

  constructor(
    private dialogAction: HotelOffersDialogActionService
  ) { }

  openDialog(data: HotelResultData): void {
    data?.offers && this.dialogAction.open(data.offers);
  }

}
