import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { RestaurantsModule } from './restaurants/restaurants.module';

import { RestaurantsContentComponent } from './restaurants-content.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  RestaurantsModule
];

const COMPONENTS = [
  RestaurantsContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    RestaurantsContentComponent
  ]
})
export class RestaurantsContentModule { }
