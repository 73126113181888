import { NgModule } from '@angular/core';

import { ItineraryDateViewMapDirective } from './itinerary-date-view.directive';

const DIRECTIVES = [
  ItineraryDateViewMapDirective
];

@NgModule({
  declarations: [
    DIRECTIVES,
  ],
  exports: [
    ItineraryDateViewMapDirective
  ]
})
export class ItineraryDateViewMapModule { }
