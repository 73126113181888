import { Pipe, PipeTransform } from '@angular/core';
import { Type } from './common';

@Pipe({
  name: 'buttonTextByType'
})
export class ButtonTextByTypePipe implements PipeTransform {

  transform(value: Type): string {
    switch (value) {
      case 'travelAgent': return 'reg-form.button-text-register-interest';
      case 'ambassador':  return 'reg-form.button-text-save-changes';
      default:            return 'reg-form.button-text-register-interest';
    }
  }

}
