import { Client } from "./filestack.model";
import { getWindowRef, promiseDelay, PromiseReject, PromiseResolve } from "@hiptraveler/common";

declare const filestack: any;

export function filestackClient(apiKey: string): Promise<Client> {
  return new Promise(async (resolve: PromiseResolve, reject: PromiseReject) => {

    await promiseDelay(100);

    const windowRef = getWindowRef();

    let client: Client = null,
      bodyElement: HTMLBodyElement | null = null,
      scriptElement: HTMLScriptElement | null = null;
  
    if (typeof windowRef === 'object' && windowRef) {
      bodyElement = windowRef?.document?.body as HTMLBodyElement;
      scriptElement = windowRef?.document?.createElement('script') as HTMLScriptElement;
    }

    if (!bodyElement || !scriptElement) return;

    scriptElement.src = 'https://static.filestackapi.com/filestack-js/3.x.x/filestack.min.js';
    scriptElement.async = true;

    bodyElement.appendChild(scriptElement);

    scriptElement.onload = () => {
      try {
        
        client = filestack?.init(apiKey);

        client?.on('upload.error', (filestackError: any) => {
          reject(filestackError);
          console.warn('@@@ filestackError', filestackError);
        });

        resolve(client);
        console.log('Filestack script loaded.');
      } catch (innerError) {

        reject(innerError);
        console.error('Error initializing Filestack:', innerError);
      }
    };
  });
}
