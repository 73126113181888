import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { TravelOptionsComponent } from './travel-options.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  TravelOptionsComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TravelOptionsComponent
  ]
})
export class TravelOptionsModule { }
