import { Component, Inject, OnInit, Self, ViewEncapsulation } from '@angular/core';

import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { AmbassadorService } from './ambassador.service';
import { CDNReaderService, DefaultUiService, WINDOW } from '@hiptraveler/common';

@Component({
  selector: 'app-ambassador',
  templateUrl: './ambassador.component.html',
  styleUrls: ['./ambassador.component.scss', './ambassador-responsiveness.scss'],
  host: { class: 'page--ambassador' },
  viewProviders: [ DefaultUiService, AmbassadorService, AuthDialogActionService, CDNReaderService ],
  encapsulation: ViewEncapsulation.None
})
export class AmbassadorComponent implements OnInit {

  youtubeUrl: string = 'https://www.youtube.com/embed/Dc2KiTheatE';

  constructor(
    @Inject(WINDOW) private window: any,
    @Self() private defaultUI: DefaultUiService,
    public service: AmbassadorService
  ) { }

  ngOnInit(): void {
    this.service.validateUser();
    this.service.observeSocialMediaPopUp();
  }

  tourAgentApply(): void {
    this.window.scrollTo(0, 450);
  }

}
