import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { isBrandIframeWidget } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class WidgetStateService {

  constructor(
    private store: Store
  ) { }

  get brandIframeWidget$(): Observable<boolean> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map(() => isBrandIframeWidget())
    )
  }

}
