import { StateContext } from "@ngxs/store";

import { SearchStateField, SearchStateModel } from "@hiptraveler/data-access/search";

export function resetState(ctx: StateContext<SearchStateModel>, fields?: SearchStateField[]) {

  const emptyState = {
    hotels: null, adventures: null, foods: null,
    itineraries: null, communities: null, travelAgents: null,
    pagination: null
  };

  const partialEmptyState = fields ? {
    ...ctx.getState(),
    ...Object.fromEntries( fields.map(field => [ field, null ]) )
  } : { ...ctx.getState() };

  return fields ? partialEmptyState : emptyState;
}
