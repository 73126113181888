
export function toFormData<T>(payload: T): FormData {

  const formData = new FormData();
  const data = payload as Record<string, any>;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  return formData;
}
