import { Injectable } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Subject, delay } from 'rxjs';

import { AppListenerService, ITINERARY_ACTION_PANEL_BACKDROP_CLASS, ITINERARY_ACTION_PANEL_CLASS } from '@hiptraveler/common';

@Injectable()
export class ItineraryActionService {

  cardMenuPanelState$$ = new Subject<string>();
  cardMenuPanelState$ = this.cardMenuPanelState$$.asObservable().pipe(delay(0));

  #previousPanelVisibility$ = new Subject<void>();
  previousPanelVisibility$ = this.#previousPanelVisibility$.asObservable();

  constructor(
    private appListener: AppListenerService
  ) { }
  
  openPreviousActionPanel(): void {
    this.#previousPanelVisibility$.next();
  }

  /**
   * Initializes the configuration of a connected overlay for an itinerary action panel.
   *
   * @param overlay The CdkConnectedOverlay instance to configure.
   * @param identifier A unique identifier for the panel.
   * @param callback A callback function to be invoked when the panel's visibility changes.
   */
  initializeOverlay(overlay: CdkConnectedOverlay, identifier: string, actionPanel: string, callback: (visibility: boolean) => void): void {

    if (!overlay) return;

    overlay.width = '232px';
    overlay.hasBackdrop = actionPanel !== '';
    overlay.panelClass = ITINERARY_ACTION_PANEL_CLASS;
    overlay.backdropClass = ITINERARY_ACTION_PANEL_BACKDROP_CLASS;
    overlay.positions  =[
      { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
    ];

    // For testing only
    // if (true) { (identifier === "5cae6a2de4b0aad442c2e460") && setTimeout(() => callback(true)); return; }

    overlay.attach.subscribe(() => {
      this.appListener.cardMenuPanelState$$.next(identifier);
    });
    
    this.appListener.cardMenuPanelState$.subscribe((id: string) => {
      if (id === identifier) return;
      callback(false);
    });
    
    overlay.detach.subscribe(() => {
      callback(false);
    });
    overlay.backdropClick.subscribe(() => {
      callback(false);
    });
  }

}
