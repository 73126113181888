import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { SearchResultPagination } from '@hiptraveler/data-access/api';
import { ProfileState } from '@hiptraveler/data-access/profile';
import { AppListenerService } from '@hiptraveler/common';
import { ProfileStateService } from '../common';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'activity-content',
  templateUrl: './activity-content.component.html',
  styleUrls: ['./activity-content.component.scss'],
  host: { class: 'page-profile-activity-content' },
  animations: [ opacityFadeIn() ],
  encapsulation: ViewEncapsulation.None
})
export class ActivityContentComponent {

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    public stateService: ProfileStateService,
  ) { }

  get userInfoMobile$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth <= 900)
    );
  }

  get loadMoreVisibility$(): Observable<boolean> {
    return this.store.select(ProfileState.pagination).pipe(
      map((pagination: SearchResultPagination | null) => !!pagination?.moreResults)
    )
  }

  loadMoreSearchResults(): void {
    const page = this.store.selectSnapshot(ProfileState.pagination)?.page || -1;
    page && this.stateService.loadMoreEvent$$.next(page);
    
  }

}
