<div class="hour-details mat-elevation-z2">

  <ng-container *ngIf="service.imageInformation$ | async as imageInformation;else noHourDetails">
    <h2 [@opacityFade] translate="search-result.hours"></h2>
    <div class="hour-details--list" [@opacityFade]>
      <div class="hour-details--list-item" *ngFor="let item of imageInformation.yelpData?.hours;">
        <div class="hour-details--list-item-sched" *ngFor="let sched of item.open;">
          <p style="width: 100px;">{{ sched.day | calendarDay }}</p>
          <p style="width: 100px;" class="ht-digit">{{ sched.start | dayTime }}</p>
          <p style="width: 12px;" class="ht-digit">-</p>
          <p style="width: 100px;" class="ht-digit">{{ sched.end | dayTime }}</p>
          <p></p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noHourDetails>
    <h2 translate="search-result.hours"></h2>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>

</div>
