import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ComposeBlogFormModule } from './../compose-blog-form/compose-blog-form.module';
import { DispatchObserverDirectiveModule, BlogNavbarActionDirectiveModule } from '../common';

import { ModifyBlogComponent } from './modify-blog.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  ComposeBlogFormModule,
  BlogNavbarActionDirectiveModule,
  DispatchObserverDirectiveModule
];

const COMPONENTS = [
  ModifyBlogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES,
    RouterModule.forChild([{
      path: '', component: ModifyBlogComponent
    }])
  ],
  declarations: [
    COMPONENTS
  ],
})
export class ModifyBlogModule { }
