import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

import { BrandCampaign, SignInDto } from '@hiptraveler/data-access/api';
import { AuthAction } from '@hiptraveler/data-access/auth';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable()
export class AuthMenuService {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;
  
  pending$$ = new BehaviorSubject<boolean>(false);

  form: FormGroup = this.fb.group({
    username: [ '' ],
    password: [ '' ]
  });

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private snackbar: SnackbarService
  ) { }

  async signInWithEmailAndPassword(): Promise<void> {
    if (this.pending$$.value || this.form.invalid) return;
    const form: SignInDto = this.form.value;
    this.pending$$.next(true);
    this.snackbar.open({ message: 'Please wait...', duration: Infinity });
    try {
      await firstValueFrom(this.store.dispatch(new AuthAction.Login(form)));
      this.snackbar.dismiss();
    } catch (error) {
      this.snackbar.open({ message: 'Incorrect password. Please try again.', duration: 5000 });
      this.pending$$.next(false);
    }
    finally {
      this.form.reset();
    }
  }

}
