import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subject, filter, fromEvent, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[mouseDrag]'
})
export class MouseDragDirective implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    
    const container = this.element.nativeElement;
                    
    let startY = 0;
    let startX = 0;
    let scrollLeft = 0;
    let scrollTop = 0;
    let isDown = false;

    fromEvent(container, 'mousedown').pipe(
      tap((_: Event) => {
        const event = _ as MouseEvent;
        isDown = true;
        startY = event.pageY - container.offsetTop;
        startX = event.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
        scrollTop = container.scrollTop; 
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    fromEvent(container, 'mouseup').pipe(
      tap(() => (isDown = false)),
      takeUntil(this.subscription$)
    ).subscribe();

    fromEvent(container, 'mouseleave').pipe(
      tap(() => (isDown = false)),
      takeUntil(this.subscription$)
    ).subscribe();

    fromEvent(container, 'mousemove').pipe(
      filter(() => isDown),
      tap((_: Event) => {
        const event = _ as MouseEvent;
        event.preventDefault();
        const y = event.pageY - container.offsetTop;
        const walkY = y - startY;
        container.scrollTop = scrollTop - walkY;

        const x = event.pageX - container.offsetLeft;
        const walkX = x - startX;
        container.scrollLeft = scrollLeft - walkX;
      }),
      takeUntil(this.subscription$)
    ).subscribe();

  }

}
