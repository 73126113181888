import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[blubStyles]'
})
export class BlubStylesDirective implements OnInit {

  @Input() size: number = 150;
  @Input() blub2Scale: number = 0.8;
  @Input() blub3Scale: number = 0.6;

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.applyStyles();
  }

  applyStyles() {
    // Calculations for sizes and positions
    const blub3Width = this.size * this.blub3Scale;

    // Styles for .blub
    this.renderer.setStyle(this.el.nativeElement, 'width', `${this.size}px`);
    this.renderer.setStyle(this.el.nativeElement, 'height', `${this.size}px`);

    // Styles for .blub-1
    const blub1 = this.el.nativeElement.querySelector('.blub-1');
    if (blub1) {
      this.renderer.setStyle(blub1, 'width', `${this.size}px`);
      this.renderer.setStyle(blub1, 'height', `${this.size}px`);
    }

    // Styles for .blub-2
    const blub2 = this.el.nativeElement.querySelector('.blub-2');
    if (blub2) {
      const blub2Offset = this.size * (1 - this.blub2Scale) / 2;
      this.renderer.setStyle(blub2, 'top', `${blub2Offset}px`);
      this.renderer.setStyle(blub2, 'left', `${blub2Offset}px`);
      this.renderer.setStyle(blub2, 'width', `${this.size * this.blub2Scale}px`);
      this.renderer.setStyle(blub2, 'height', `${this.size * this.blub2Scale}px`);
    }

    // Styles for .blub-3
    const blub3 = this.el.nativeElement.querySelector('.blub-3');
    if (blub3) {
      const blub3Offset = this.size * (1 - this.blub3Scale) / 2;
      this.renderer.setStyle(blub3, 'top', `${blub3Offset}px`);
      this.renderer.setStyle(blub3, 'left', `${blub3Offset}px`);
      this.renderer.setStyle(blub3, 'width', `${blub3Width}px`);
      this.renderer.setStyle(blub3, 'height', `${this.size * this.blub3Scale}px`);
    }

    // Style for img inside .blub-3
    const img = this.el.nativeElement.querySelector('img');
    if (img) {
      this.renderer.setStyle(img, 'width', `${blub3Width * 0.85}px`);
    }
  }

}
