<div class="post-action-button" matRipple #postActionButton>

  <div class="post-action-button--profile">
    <ng-container *ngIf="service.picUrl$ | async as picUrl;else noPicUrl">
      <img [lazyLoad]="picUrl" [defaultImage]="authenticatedProfilePlaceholder" [errorImage]="authenticatedProfilePlaceholder" draggable="false" [alt]="service.picUrl$ | async">
    </ng-container>
    <ng-template #noPicUrl>
      <mat-icon svgIcon="post-action-button.user-profile-icon-svg" [class.primary]="service.id$ | async"></mat-icon>
    </ng-template>
  </div>

  <p translate="post-action.handle-text"></p>

  <div class="post-action-button--actions">
    <div class="post-action-button--icon">
      <mat-icon svgIcon="post-action-button.bolt-icon-svg"></mat-icon>
    </div>
    <div class="post-action-button--icon">
      <mat-icon svgIcon="post-action-button.camera-icon-svg"></mat-icon>
    </div>
    <div class="post-action-button--icon">
      <mat-icon svgIcon="post-action-button.media-icon-svg"></mat-icon>
    </div>
  </div>

</div>
