import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { filter, fromEvent, map, switchMap, takeUntil, throttleTime } from 'rxjs';

import { EFAStateServiceService, screens, ScreenValue, ScreenView } from '../shared';
import { promiseDelay } from '@hiptraveler/common';

@Directive({
  selector: '[hostScroll]'
})
export class HostScrollDirective implements AfterViewInit {

  currentScreen: ScreenValue = 'screen1';

  constructor(
    private elementRef: ElementRef<HTMLDivElement>,
    public stateService: EFAStateServiceService
  ) { }

  get element(): HTMLDivElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {

    this.setWelcomeViewDisplay('show');
    this.scrollObserver();

    this.stateService.scrollToView$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.scrollToView.bind(this));

    this.stateService.scrollToScreen$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.scrollTo.bind(this));
  }

  @HostListener('scroll', ['$event'])
  observeOnScroll(event: any): void {
    const scrollTop = event.target.scrollTop;
    this.setWelcomeViewDisplay(scrollTop < 8 ? 'show' : 'hide');
  }

  async setWelcomeViewDisplay(value: 'show' | 'hide'): Promise<void> {

    await promiseDelay(0)
    const uxTriggerClass = 'view-display';
    const welcomeView = this.element.querySelector('.experience-finder-ai--host-welcome-view');

    if (value === 'show' && !welcomeView?.classList.contains(uxTriggerClass)) {
      welcomeView?.classList.add(uxTriggerClass);
      this.stateService.welcomeViewTypeState$$.next(true);
    }
    
    if (value === 'hide' && welcomeView?.classList.contains(uxTriggerClass)) {
      welcomeView?.classList.remove(uxTriggerClass);
      this.stateService.welcomeViewTypeState$$.next(false);
    }
  }

  scrollObserver(): void {

    this.stateService.isMobile$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(state => {
      this.element.style.overflowY = state ? 'auto' :'hidden'; 
    });

    this.element.style.overflowY = 'hidden'; 

    fromEvent(this.element, 'wheel').pipe(
      switchMap(event => this.stateService.isMobile$.pipe(
        map(state => state || event)
      )),
      throttleTime(350),
      takeUntil(this.stateService.subscription$),
      filter(Boolean)
    ).subscribe((event: any) => {

      if (event.wheelDeltaX !== 0 || this.stateService.inputPending$$.value) return;
      const allowScroll = this.stateService.allowScroll$$.value;
      const scrollDirection = event.wheelDeltaY > 0 ? 'up' : 'down'
      const currentIndex = screens.indexOf(this.currentScreen);

      if (scrollDirection === 'down' && currentIndex !== -1 && currentIndex < screens.length - 1) {
        const newScreen = screens[currentIndex + 1];
        [ 'allow', 'allow-down' ].includes(allowScroll) && this.scrollTo(ScreenView[newScreen]);
      }
      if (scrollDirection === 'up' && currentIndex > 0) {
        const newScreen = screens[currentIndex - 1];
        [ 'allow', 'allow-up' ].includes(allowScroll) && this.scrollTo(ScreenView[newScreen]);
      }
    });
  }

  scrollTo(value: number): void {

    if (this.stateService.scrolling$$.value) return;

    this.currentScreen = ScreenView[value] as ScreenValue;
    this.stateService.scrolling$$.next(true);
    this.stateService.screenViewValue$$.next(this.currentScreen);

    this.element.scrollTo({
      top: value || 0, behavior: 'smooth'
    });

    setTimeout(() => {
      this.element.scrollTop = value;
    }, 400);
    setTimeout(() => {
      this.stateService.scrolling$$.next(false);
    }, 1200);
  }

  scrollToView(value: 'next' | 'previous'): void {
    const currentIndex = screens.indexOf(this.currentScreen) as any;
    if (value === 'next') {
      const screen = `screen${currentIndex + 2}` as ScreenValue;
      this.scrollTo(ScreenView[screen])
    }
    if (value === 'previous') {
      const screen = `screen${currentIndex - 2}` as ScreenValue;
      this.scrollTo(ScreenView[screen])
    }
  }

}
