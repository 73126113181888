<ng-container *ngIf="imageInformation?.yelpData as yelpData;else loadingYelpData">
  
  <p class="yelp--review-contact"><mat-icon>phone_in_talk</mat-icon> <span class="ht-digit">{{ yelpData?.phone }}</span></p>
  
  <img class="yelp--review-logo" lazyLoad="assets/img/affiliates/yelp.png" defaultImage="assets/img/blank-white.webp" errorImage="assets/img/blank-white.webp" draggable="false" alt="Yelp average review rating" [externalLink]="yelpData?.yelpUrl">
  
  <img class="yelp--review-rating" [lazyLoad]="yelpData?.ratingImgUrl || ''" defaultImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" errorImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" draggable="false" alt="Yelp average review rating" [externalLink]="yelpData?.yelpUrl">
  
  <p [externalLink]="yelpData?.yelpUrl">{{ 'search-result.based-on' | translate }} <span>{{ yelpData?.reviewCnt }} {{ 
  'search-result.reviews' | translate }}</span></p>
  
  <ng-container *ngIf="yelpData?.reviews as reviews">
    <div class="yelp--review-list">
      <div class="yelp--review" *ngFor="let review of reviews.slice(0, 1);">
      
        <div class="yelp--review-user" [externalLink]="review.user.profile">
          <img [lazyLoad]="review?.user?.image || ''" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Yelp average review rating">  
          <p>{{ review?.user?.name || '-' }}</p>
          <img [lazyLoad]="review?.ratingImg || ''" defaultImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" errorImage="https://s3.amazonaws.com/UA-Environment/brand-logos/yelp-stars/large_0.png" draggable="false" alt="Yelp review rating">  
        </div>
    
        <p>{{ review?.text || '-' }}</p>
      
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #loadingYelpData>
  <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin: 24px 0 0;" *ngIf="visibility"></mat-progress-spinner>
</ng-template>
