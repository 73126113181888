<div class="about ht-dialog-elevation">
      
  <h2 translate="search-result.about-this-tour"></h2>

  <ng-container *ngIf="service.imageInformation$ | async as imageInformation;else inProgress">

    <div class="about--message-content" [aboutThisTourContent]="imageInformation" [@opacityFade]></div>
  
    <ng-container *ngIf="imageInformation?.addInfo;else noAdditionalInfoContent">
      <ng-container *ngIf="imageInformation?.tourData?.addInfo">
        <hr>
        <div class="about--additional-information-content" [additionalInfoContent]="imageInformation" [@opacityFade]></div>
      </ng-container>
    </ng-container>
    <ng-template #noAdditionalInfoContent>
      <div style="border-top: 1px solid rgba(black, 0);padding: 16px 0;"></div>
    </ng-template>

    <ng-container *ngIf="imageInformation?.tourData?.itinerary?.itineraryItemsList as itineraryItemsList">
      <ng-container *ngIf="itineraryItemsList?.length">
        <h2 translate="search-result.what-to-expect"></h2>
        <div class="about--itinerary-list" [@opacityFade]>
          <div class="about--itinerary" *ngFor="let itineraryItem of itineraryItemsList;">
            <p class="about--itinerary-title">{{ itineraryItem.title }}</p>
            <p class="about--itinerary-description">{{ itineraryItem.description }}</p>
            <p class="about--itinerary-admission"><span class="ht-digit">{{ itineraryItem.duration || '-' }}</span> • {{ itineraryItem.admissionIncluded }}</p>
          </div>
        </div>
        <div style="border-top: 1px solid rgba(black, 0);padding: 16px 0;"></div>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="imageInformation?.tourData?.cancellationPolicy">
      <h2 [@opacityFade] translate="search-result.cancellation-policy"></h2>
      <p class="cancellation-policy" [@opacityFade]>{{ imageInformation?.tourData?.cancellationPolicy }}</p>
    </ng-container>

  </ng-container>
  <ng-template #inProgress>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate"></mat-progress-spinner>
  </ng-template>

</div>
