import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SafeHtmlPipeModule } from '@hiptraveler/common';
import { StoryTextDisplayPipeModule } from './story-text-display.pipe';

import { StoryContentComponent } from './story-content.component';
import { InnerHTMLDirective } from './inner-html.directive';
import { MoreContentActionDirective } from './more-content-action.directive';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  SafeHtmlPipeModule,
  StoryTextDisplayPipeModule
];

const COMPONENTS = [
  StoryContentComponent
];

const DIRECTIVES = [
  InnerHTMLDirective,
  MoreContentActionDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    StoryContentComponent
  ]
})
export class StoryContentModule { }
