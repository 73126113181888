<ng-container *ngIf="dialogType | buttonVisibility | async">
  <!-- <ui-action-button size="medium" class="result-actions--book-contact">Book/Contact</ui-action-button> -->
  <ui-action-button elevation="1" size="medium" class="result-actions--book-contact" [@opacityFade]>{{ 'search-result.book-contact' | translate }}</ui-action-button>
</ng-container>
<!-- <div class="result-actions--favorite" (click)="toggleLikeItinerary()"> -->
<div class="result-actions--favorite lts mat-elevation-z1" toggleFavorite [image]="image" [hotel]="hotel" [dialogType]="dialogType">

  <!-- <ng-container *ngIf="!processing && dialogType !== 'hotel'">
    <div class="result-actions--favorite-count ht-digit mat-elevation-z2" *ngIf="(service.imageInformation$ | async)?.imgLikes as likes">{{ likes }}</div>
  </ng-container>
  <ng-container *ngIf="!processing && dialogType === 'hotel'">
    <div class="result-actions--favorite-count ht-digit mat-elevation-z2" *ngIf="(service.hotelInformation$ | async)?.likeCnt as likes">{{ likes }}</div>
  </ng-container> -->

  <!-- <mat-progress-spinner class="primary" diameter="30" mode="indeterminate" *ngIf="(dialogType === 'hotel' ? !(service.hotelInformation$ | async) : !(service.imageInformation$ | async)) || processing"></mat-progress-spinner> -->

  <button 
    mat-icon-button disableRipple
    [class.active]="dialogType === 'hotel' ? (stateService.hotelFavoriteActive$ | async) : (stateService.favoriteActive$ | async)"
    [class.pending]="dialogType === 'hotel' ? !(stateService.hotelInformation$ | async) : !(stateService.imageInformation$ | async) || (stateService.favoriteProcessing$ | async)">
    <mat-icon svgIcon="common.favorite-svg"></mat-icon>
  </button>
</div>
