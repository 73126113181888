import { FiltersModule as _FiltersModule } from './filters.module';
import { FiltersMobileModule } from './mobile/mobile.module';

import { FiltersService } from './filters.service';
import { FiltersProviders as _FiltersProviders } from './common';

export const FiltersModule = [
  _FiltersModule,
  FiltersMobileModule
];

export const FiltersProviders = [
  FiltersService,
  _FiltersProviders
]
