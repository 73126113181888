import { Directive, ElementRef, Input, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, Subject, catchError, fromEvent, takeUntil, tap } from 'rxjs';

import { AppListenerService, NavbarButtonData, NavbarControlStateService } from '@hiptraveler/common';

@Directive({
  selector: '[executeNavbarAction]'
})
export class ExecuteNavbarActionDirective implements AfterViewInit, OnDestroy {

  @Output() pendingButton = new EventEmitter<string | undefined>();

  @Input('executeNavbarAction') data: NavbarButtonData;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService
  ) { }
  
  ngAfterViewInit(): void {

    this.clickNavigation(this.closeButtonClicked, [
      this.getElementByClass('navbar--action-button-event')
    ]).subscribe();

    this.clickNavigation(this.actionButtonClicked, [
      this.getElementByClass('navbar--action-button-text'),
      this.getElementByClass('navbar--action-icon-button-handle'),
      this.getElementByClass('navbar--action-button-responsive-icon')?.parentElement!
    ]).subscribe();

    this.navbarControl.navbarToolbarActionComplete$$.subscribe(
      () => this.terminateSpinnerUI()
    );
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  closeButtonClicked(): void {
    const buttons = [ ...(this.navbarControl.activeNavbarActionButtons$$.value || []) ].filter(e => e?.name !== this.data?.name);
    this.navbarControl.setActiveNavbarButtons(buttons);
  }

  actionButtonClicked(): unknown {

    this.initiateSpinnerUI();

    switch (this.data?.action) {
      case 'addItinerary':
      case 'viewTripCreatePDF':
      case 'travelStoryEdit':
      case 'travelStoryPublish':
      case 'saveThisTrip':
      case 'composePreview':
      case 'composeSave': {
        this.appListener.emitGlobalSignal(this.data.action);
        return;
      }
    }
    
    if (this.data?.url) {
      return this.router.navigateByUrl(this.data.url);
    }

    return;
  }

  initiateSpinnerUI(): void {
    this.pendingButton.emit(this.data?.name);
    const navbarActionButton = this.element.nativeElement.classList.contains('navbar--action-button');
    if (navbarActionButton) {
      this.element.nativeElement.style.pointerEvents = 'none';
      this.element.nativeElement.style.justifyContent = 'center';
      const spinner = this.element.nativeElement.getElementsByClassName('mat-progress-spinner')[0] as HTMLElement;
      Array.from(this.element.nativeElement.children).forEach((_element: Element) => {
        const element = _element as HTMLElement;
        element.style.display = 'none';
      });
      spinner.style.display = 'block';
    }
  }

  terminateSpinnerUI(): void {
    this.pendingButton.emit(undefined);
    const navbarActionButton = this.element.nativeElement.classList.contains('navbar--action-button');
    if (navbarActionButton) {
      this.element.nativeElement.style.pointerEvents = 'all';
      const spinner = this.element.nativeElement.getElementsByClassName('mat-progress-spinner')[0] as HTMLElement;
      Array.from(this.element.nativeElement.children).forEach((_element: Element) => {
        const element = _element as HTMLElement;
        if (element.classList.contains('mat-icon')) return;
        element.style.display = 'block';
      });
      spinner.style.display = 'none';
    }
  }

  private clickNavigation(callback: () => any, elements: HTMLElement[]): Observable<Event> {
    return fromEvent(elements.filter(e => !!e), 'click').pipe(
      catchError(() => EMPTY),
      tap(callback.bind(this)),
      takeUntil(this.subscription$)
    );
  }

  private getElementByClass(value: string): HTMLElement {
    return this.element.nativeElement.getElementsByClassName(value).item(0) as HTMLElement;
  }

}
