import { cleanObject, getDecodedValue, getWindowRef, queryStringToObject } from "@hiptraveler/common";
import * as moment from 'moment';

interface CheckInDateRange<T = string> {
  checkInDate?: T;
  checkOutDate?: T;
}

export function getCheckInDateRange(): CheckInDateRange {

  const query = queryStringToObject(getWindowRef()?.location?.search || '?') as any;

  return cleanObject({ // Format: MM-DD-YYYY
    checkInDate: getDecodedValue(query?.checkInDate),
    checkOutDate: getDecodedValue(query?.checkOutDate)
  });
}

export function toCheckInDateRange({ checkInDate, checkOutDate }: CheckInDateRange<Date>): CheckInDateRange {
  return { // Format: MM-DD-YYYY
    checkInDate: checkInDate ? moment(checkInDate).format('MM-DD-YYYY') : '',
    checkOutDate: checkOutDate ? moment(checkOutDate).format('MM-DD-YYYY') : '',
  }
}
