import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { PoweredByHiptravelerModule } from '@hiptraveler/ui/powered-by-hiptraveler';
import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';
import { ProfileSelectionModule } from '@hiptraveler/features/profile-selection';

import { NavbarComponent } from './navbar.component';
import { ExploreInputFieldComponent } from './explore-input-field/explore-input-field.component';
import { NavbarBrandLogoComponent, CampaignLogoDirective } from './navbar-brand-logo';
import { NavbarActionsComponent } from './navbar-actions/navbar-actions.component';
import { ExecuteNavbarActionDirective } from './navbar-actions/execute-navbar-action.directive';
import { LanguageSupportPipe } from './language-support.pipe';

const MODULES = [
  CommonModule,
  FormsModule,
  RouterModule,
  MatRippleModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  PoweredByHiptravelerModule,
  CustomMapAutocompleteModule,
  ProfileSelectionModule
];

const COMPONENTS = [
  NavbarComponent,
  ExploreInputFieldComponent,
  NavbarBrandLogoComponent,
  NavbarActionsComponent
];

const DIRECTIVES = [
  CampaignLogoDirective,
  ExecuteNavbarActionDirective
];

const PIPES = [
  LanguageSupportPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES,
    PIPES
  ],
  exports: [
    NavbarComponent
  ]
})
export class NavbarModule { }
