import { Component, OnDestroy, OnInit } from '@angular/core';

import { ItineraryDataAccessService } from '@hiptraveler/features/itinerary';
import { AppListenerService } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'app-modify-itinerary',
  template: `
    <ng-container *ngIf="appListener.languageLoadState$ | async">
      <compose-itinerary-form navbarAction dispatchObserver [@opacityFadeIn]></compose-itinerary-form>
    </ng-container>
  `,
  viewProviders: [ ItineraryDataAccessService, AuthDialogActionService ],
  animations: [ opacityFadeIn() ]
})
export class ModifyItineraryComponent implements OnInit, OnDestroy {

  constructor(
    private dataAccess: ItineraryDataAccessService,
    public appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    this.dataAccess.getItineraryData(this.ngOnDestroy.bind(this));
  }

  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
  }

}
