<ng-container *ngIf="actionState;else defaultState">
  <mat-form-field [@opacityFadeIn]>
    <mat-label translate="custom-activity-dialog.section.text.add-whats-included"></mat-label>
    <input matInput [placeholder]="'custom-activity-dialog.section.text.add-whats-included' | translate" type="text" [(ngModel)]="item" (keydown.enter)="add(formArray)" name="included">
    <button matSuffix mat-icon-button (click)="add(formArray)">
      <mat-icon>add</mat-icon>
    </button>
  </mat-form-field>
</ng-container>
<ng-template #defaultState>
  <section-action (click)="actionState = true" [@opacityFadeIn]>{{ 'custom-activity-dialog.section.text.add-whats-included' | translate }}</section-action>
</ng-template>

<div class="list-view">

  <ng-container *ngFor="let item of items;let i = index;">
    <p [@opacityFadeIn]>
      <mat-icon (click)="remove(formArray, i)">delete</mat-icon>
      {{ item }}
    </p>
  </ng-container>

</div>
