import { ElementRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AuthRegisterDialogService } from '@hiptraveler/dialogs/auth-dialog';

@Injectable()
export class FormService {

  constructor(
    private stateService: ExperienceFinderStateService,
    private authRegister: AuthRegisterDialogService
  ) { }

  get form(): FormGroup {
    return this.authRegister.form;
  }

  get pending$$(): BehaviorSubject<boolean> {
    return this.authRegister.pending$$;
  }

  get hiptraveler(): boolean {
    return this.authRegister.hiptraveler;
  }

  get bahamas(): boolean {
    return this.authRegister.bahamas;
  }

  get brandTitle$(): Observable<string> {
    return this.authRegister.brandTitle$
  }

  initializeForm(): void {
    this.authRegister.initializeForm();
  }

  initializeAutocomplete(element: ElementRef<HTMLInputElement>): void {
    this.authRegister.initializeAutocomplete(element);
  }

  async signUpWithEmailAndPassword(): Promise<void> {
    
    this.stateService.processing$$.next(true);
    this.stateService.actionProcessing$$.next(true);
    
    this.authRegister.signUpWithEmailAndPassword((state: boolean) => {
      
      this.stateService.processing$$.next(false);
      this.stateService.actionProcessing$$.next(false);

      if (state) return;
      this.stateService.dialogView$$.next('main-menu');
    });
  }

}
