import { Component, ViewEncapsulation } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { EFAStateServiceService, ScreenView } from '../../../shared';
import { DATA_SELECTIONS, DataSelection, DataSelectionKey } from './data-selection';

@Component({
  selector: 'summary-data-selection',
  template: `
    <div
      matRipple
      class="data-selection"
      [ngStyle]="{ display: data.display }"
      (click)="navigate(data.key)"
      *ngFor="let data of dataSelections;">
      <p class="data-selection--head">{{ data.head }} <mat-icon style="margin-left: 8px;">{{ data.icon }}</mat-icon></p>
      <p class="data-selection--info">{{ data.info }}</p>
    </div>
  `,
  styleUrls: ['./data-selection.component.scss'],
  host: { class: 'summary--data-selection' },
  encapsulation: ViewEncapsulation.None
})
export class DataSelectionComponent {

  dataSelections: DataSelection[] = DATA_SELECTIONS;

  constructor(
    private stateService: EFAStateServiceService
  ) { }

  async navigate(dataKey: DataSelectionKey): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    this.stateService.scrollToScreen$$.next({
      travel_style: ScreenView.screen3,
      activities: ScreenView.screen4,
      location: isMobile ? ScreenView.screen5Mobile : ScreenView.screen5,
      calendar: isMobile ? ScreenView.screen6Mobile : ScreenView.screen6,
      client_info: ScreenView.screen7
    }[dataKey]);
  }

}
