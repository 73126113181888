import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ErrorResponse } from '@hiptraveler/data-access/api';
import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { SnackbarService } from '@hiptraveler/snackbar';

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {

  constructor(
    private store: Store,
    private snackbar: SnackbarService
  ) { }

  handleExpiredToken(value: ErrorResponse): void {
    const authenticated = this.store.selectSnapshot(AuthState.authenticated);
    const cond = authenticated && value?.error === 'Refresh Token has expired.' && value?.status === 'FAILURE';
    if (!cond) return;
    authenticated && this.store.dispatch(new AuthAction.SignOut);
    this.snackbar.open({ message: 'Session expired. Please sign in again.', duration: 5000 });
  }

}
