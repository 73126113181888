<ng-container *ngIf="(componentState.value$ | async)?.hasStoryContent">
  <editable-selections [editable]="editable" (stateChanged)="contentEditable.editableSelectionStatusChange($event)" (yPositionChange)="contentEditable.yPosition = $event"></editable-selections>
</ng-container>

<div class="host--label-container"></div>

<form class="host--content" [formGroup]="service.form">

  <div class="host--content-section">

    <div class="host--content-label">
      <div class="host--content-label-container">
        <p translate="compose.story"></p>
      </div>
    </div>

    <div class="host--content-section-data">

      <div class="host--input-row">
        <div class="host--readonly-input" (click)="service.languageSelection()">
          <mat-icon>public</mat-icon>
          <p translate="compose.lang"></p>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'compose.edit-title' | translate }}</mat-label>
          <input matInput [placeholder]="'compose.edit-title' | translate" type="text" autocomplete="off" name="story-title" formControlName="storyTitle">
        </mat-form-field>
      </div>
  
      <div class="host--input-row">
        <mat-form-field appearance="outline" emitOnly customMapAutocomplete position="absolute" [cmaLeftOffset]="-200" [cmaTopOffset]="4" (locationChanged)="locationChanges($event)">
          <mat-label>{{ 'compose.edit-location' | translate }}</mat-label>
          <input matInput [placeholder]="'compose.edit-location' | translate" type="text" autocomplete="off" name="story-location" formControlName="storyLocation" #placesAutocomplete>
        </mat-form-field>
  
        <ng-container *ngIf="!(componentState.value$ | async)?.hasStoryContent;else removeStoryContent">
          <ui-action-button type="button" icon="add" (click)="componentState.patch({ hasStoryContent: true })" *ngIf="service.basicInfo$ | async;else pendingAddStoryButton">{{ 'compose.add' | translate }}</ui-action-button>
          <ng-template #pendingAddStoryButton>
            <ui-action-button type="button" icon="add" [pending]="true" [disabled]="true"></ui-action-button>
          </ng-template>
        </ng-container>
        <ng-template #removeStoryContent>
          <ui-action-button type="button" icon="remove" theme="action" (click)="componentState.patch({ hasStoryContent: false })">{{ 'compose.delete' | translate }}</ui-action-button>
        </ng-template>
  
      </div>
  
      <div class="host--location-selections" [class.disabled]="componentState.stateProcessing$ | async">
        <div *ngFor="let city of componentState.itineraries$ | async;let i = index">
          <mat-icon (click)="removeLocationItem(i)">close</mat-icon>
          <p>{{ city }}</p>
        </div>
      </div>
    
      <story-image
        [componentState$$]="componentState.value$"
        [itineraries]="(componentState.itineraries$ | async)!">>
      </story-image>
      
      <ng-container *ngIf="(componentState.value$ | async)?.hasStoryContent">
        <p class="host--content-title" [@opacityFade] translate="compose.share"></p>
        <p class="host--content-videoUrl-placeholder" [ngStyle]="{ top: contentEditable.yPosition }" [@opacityFade] *ngIf="contentEditable.showVideoLinkPlaceholder">{{ 'compose.paste-video-link' | translate }}</p>
      </ng-container>
      
      <div class="host--content-editable hiptraveler-content-editable" [class.visible]="(componentState.value$ | async)?.hasStoryContent" #editable></div>

      <br><br>

    </div>
  
  </div>

  <div class="host--content-section">

    <div class="host--content-label">
      <div class="host--content-label-container">
        <p translate="compose.itinerary"></p>
        <activity-date-list></activity-date-list>
      </div>
    </div>

    <activity-day-list class="host--content-section-data" type="itinerary"></activity-day-list>

  </div>

</form>

<div class="host--map-wrapper">
  <div class="host--map" leafletMap id="compose-itinerary-map"></div>
</div>
