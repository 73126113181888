import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, filter, take, tap } from 'rxjs';

import { UserProfile } from '@hiptraveler/data-access/api';
import { ProfileState } from '@hiptraveler/data-access/profile';

@Injectable()
export class StateService implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    public store: Store
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  protected executeWithUserId(callback: (userId: string) => void): void {
    this.store.select(ProfileState.profileDetails).pipe(
      filter(Boolean),
      tap((userProfile: UserProfile | null) => userProfile?.profileId && callback(userProfile.profileId)),
      take(1)
    ).subscribe();
  }

}
