import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { HotelsModule } from './hotels/hotels.module';

import { HotelsContentComponent } from './hotels-content.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  HotelsModule
];

const COMPONENTS = [
  HotelsContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    HotelsContentComponent
  ]
})
export class HotelsContentModule { }
