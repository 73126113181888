import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { UserProfileComponent } from './user-profile.component';

const MODULES = [
  CommonModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ButtonsModule
];

const COMPONENTS = [
  UserProfileComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    UserProfileComponent
  ]
})
export class UserProfileModule { }
