import { Component, Input } from '@angular/core';

@Component({
  selector: 'carousel-button',
  template: `
    <div class="inner-carousel-button mat-elevation-z2" matRipple>
      <mat-icon>
        <ng-container *ngIf="left === '' && right !== ''">chevron_left</ng-container>
        <ng-container *ngIf="right === ''">chevron_right</ng-container>
      </mat-icon>
    </div>
  `,
  styles: [`
    .inner-carousel-button {
      background-color: white;
      padding: 4px;
      border-radius: 32px;
      height: 32px;
      width: 32px;
      cursor: pointer;
    }
  `],
  host: { class: 'carousel-button' }
})
export class CarouselButtonComponent {

  @Input() left: string = '';
  @Input() right: string;

}
