import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemName'
})
export class ItemNamePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace('views.label.', '');
  }

}
