import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { HeaderService } from './header.service';
import { Selection, defaultSelection, getInstagramDisplay } from '../common';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { class: 'search-connect-content-header' },
  viewProviders: [ HeaderService ],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

  @Output() active = new EventEmitter<Selection>();

  activeSelection: Selection = defaultSelection;

  constructor(
    public service: HeaderService
  ) { }

  get instagramDisplay(): boolean {
    return getInstagramDisplay();
  }

  updateSelection(selection: Selection): void {
    this.active.emit(selection);
    this.activeSelection = selection;
  }

}
