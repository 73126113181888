import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ta-flag',
  template: '<div><p><ng-content></ng-content></p></div>',
  host: { class: 'ta-flag' }
})
export class TaFlagComponent {}

@Component({
  selector: 'ta-name',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'ta-name' }
})
export class TaNameComponent {}

@Component({
  selector: 'ta-location',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'ta-location' }
})
export class TaLocationComponent {}

@Component({
  selector: 'ta-action',
  template: `
    <div [class.processing]="processing$ | async">
      <mat-icon [svgIcon]="svgIcon">{{ icon }}</mat-icon>
      <p><ng-content></ng-content></p>
      <mat-progress-spinner class="primary" diameter="18" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
    </div>
  `,
  host: { class: 'ta-action' }
})
export class TaActionComponent {
  @Input() svgIcon: string;
  @Input() icon: string;
  @Input() processing$: Observable<boolean>;
}

export const TaExportComponents = [
  TaFlagComponent,
  TaNameComponent,
  TaLocationComponent,
  TaActionComponent
];

export const TaComponents = [
  TaExportComponents
];
