import { AdventureData } from "@hiptraveler/data-access/api";

export function mapAdventureResponse(adventures?: AdventureData[] | null): AdventureData[] | null {

  adventures = adventures?.filter(e => e.name);

  return adventures ? adventures.map((adventure: AdventureData) => {

    const tourData = adventure?.tourData;

    if (tourData) {
      
      const durationMap = Array.isArray(tourData?.durationMap) ? tourData?.durationMap[0] : tourData?.durationMap
      
      const durationLabel = tourData?.duration?.trim()?.split(' ');
      const durationLabelMap = durationLabel?.length === 2
        ? { value: +durationLabel?.[0], unit: durationLabel?.[1] }
        : undefined;
  
      const fallbackValue = { value: tourData?.duration as any, unit: '' };

      const newDurationLists = tourData!.durationList?.[0] || durationMap || durationLabelMap || fallbackValue || null;
      adventure['tourData']!['durationList'] = []
      newDurationLists && adventure['tourData']!['durationList'].push(newDurationLists);
    }

    return adventure;
  }) : null;
}
