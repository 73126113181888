import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { PrivacyPolicyDialogComponent } from './privacy-policy-dialog.component';

export const PRIVACY_POLICY_DIALOG_ID = 'auth-terms-and-policy';

@Injectable()
export class PrivacyPolicyDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<PrivacyPolicyDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(PRIVACY_POLICY_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(PrivacyPolicyDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: PRIVACY_POLICY_DIALOG_ID,
      panelClass: PRIVACY_POLICY_DIALOG_ID + '-overlay-panel',
      width: '750px',
      minWidth: 0,
      maxWidth: 'auto',
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }

}
