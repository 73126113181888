import { Injectable } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';
import { SearchPageControlStateService } from '@hiptraveler/common';
import { CloseOpenTripButtonObserverService, NavigationChangeObserverService, AuthStateObserverService, VidSessionObserverService } from '.';

@Injectable()
export class ItineraryObserverService {

  constructor(
    private searchPageControl: SearchPageControlStateService,
    private authState: AuthStateObserverService,
    private navigationChange: NavigationChangeObserverService,
    private closeOpenTripButton: CloseOpenTripButtonObserverService,
    private vidSession: VidSessionObserverService,
  ) { }

  observe(): void {
    this.authState.observe();
    this.navigationChange.observe();
    this.closeOpenTripButton.observe();
    this.vidSession.observe();

    this.searchPageControl.activityDate$.subscribe((activity: ActivityDateData | null) => {
      console.log('@@@ Activity location:', !!activity, activity?.dayLocMap?.formatted_address);
    });
  }

}
