import { ChangeDetectorRef, Directive, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom } from 'rxjs';

import { UserState } from '@hiptraveler/data-access/user';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { FilestackService, PickerFileMetadata } from '@hiptraveler/core/filestack';
import { SnackbarService } from '@hiptraveler/snackbar';
import { BannerService } from './banner.service';
import { clientVID } from '@hiptraveler/common';

@Directive({
  selector: '[uploadCoverPhoto]'
})
export class UploadCoverPhotoDirective implements OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private filestack: FilestackService,
    private snackbar: SnackbarService,
    private service: BannerService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  @HostListener('click')
  hostClick(): void {
    !!this.store.selectSnapshot(UserState.state)
    && !!this.store.selectSnapshot(ProfileState.state)
    && this.openFilestackPicker();
  }

  async onFileUploadFinished(metadata: PickerFileMetadata): Promise<void> {

    const urlBase = 'https://cdn.filestackcontent.com';
    const urlId = metadata.url.replace(`${urlBase}/`, '');

    try {
      await firstValueFrom(this.store.dispatch(new ProfileAction.UpdateProfileContent({
        action: 'coverImage',
        id: this.store.selectSnapshot(ProfileState.profileDetails)!.profileId,
        imgUrl: `${urlBase}/resize=width:1024,fit:scale/auto_image/compress/${urlId}`,
        imgThumbnail: `${urlBase}/resize=width:400,height:400,fit:crop/auto_image/compress/${urlId}`,
        original: `${urlBase}/${urlId}`,
        vId: clientVID()
      })));
      setTimeout(() => this.cdRef.detectChanges());
    } finally {
      this.service.uploadProgress$$.next(false);
    }
  }

  private openFilestackPicker(): void {

    this.snackbar.open({ message: 'Please wait.', duration: Infinity });

    const onOpen = () => {
      setTimeout(() => this.snackbar.dismiss(), 500);
    };
    const detectChanges = () => {
      this.snackbar.dismiss();
      setTimeout(() => this.cdRef.detectChanges());
    };
    const onUploadStarted = () => {
      this.service.uploadProgress$$.next(true);
    };
    const onCancel = () => {
      this.snackbar.dismiss();
      this.service.uploadProgress$$.next(false);
    };

    this.filestack.openFilestackPicker({
      onOpen: onOpen,
      onClose: detectChanges,
      onUploadDone: detectChanges,
      onUploadStarted: onUploadStarted,
      onFileUploadCancel: onCancel,
      onFileUploadFailed: onCancel,
      onFileUploadFinished: this.onFileUploadFinished.bind(this)
    })
  }

}
