<div class="promo-code" [formGroup]="stateService.form">
  
  <p class="section-title" translate="reg-form.referred"></p>

  <mat-form-field underlined appearance="outline">
    <mat-label>{{ 'reg-form.promo-code' | translate }}</mat-label>
    <mat-select formControlName="promoCode">
      <mat-option value="hiptraveler">HipTraveler</mat-option>
      <mat-option value="traveldudes">Travel Dudes</mat-option>
      <mat-option value="budgettraveler">Budget Traveler</mat-option>
      <mat-option value="other">Others</mat-option>
    </mat-select>
  </mat-form-field>

</div>
