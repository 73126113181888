<div class="highlight">

  <ng-container *ngIf="service.hotelInformation$ | async as hotelInformation;else loadingHighlightSelect">
    <div class="highlight-image" [class.active]="(stateService.selectedHighlight$ | async) ? image === (stateService.selectedHighlight$ | async) : i === 0" *ngFor="let image of hotelInformation.imgs || [];let i = index">
      <asset-image
        selectImage [sourceImg]="image"
        [imageAlt]="hotelInformation?.name + ' highlight image'"
        (selected)="stateService.selectedHighlight$$.next(image)">
      </asset-image>
    </div>
  </ng-container>
  <ng-template #loadingHighlightSelect>
    <div class="highlight--skeleton" *ngFor="let _ of [0,0,0,0,0,0,0,0,0,0,0,0];">
      <ngx-skeleton-loader appearance="line" animation="pulse" [theme]="{ 'border-radius': '4px' }">
      </ngx-skeleton-loader>
    </div>
  </ng-template>

</div>
