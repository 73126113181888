import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { CarouselService, CarouselView, EFAStateServiceService } from '../../shared';

@Component({
  selector: 'carousel-activities',
  template: `
    <p class="experience-finder-ai--host-element-header">What are your favorite Activities?</p>
    <poi-select-carousel selectedFilter="activity"></poi-select-carousel>
  `,
  host: { class: 'experience-finder-ai--host-carousel-activities experience-finder-ai--host-element experience-finder-ai--host-carousel' },
  viewProviders: [ ChatCompletionsService, CarouselService ],
  encapsulation: ViewEncapsulation.None
})
export class CarouselActivitiesComponent extends CarouselView implements OnInit {

  constructor(
    completions: ChatCompletionsService,
    stateService: EFAStateServiceService,
    carousel: CarouselService
  ) {
    super(completions, stateService, carousel);
  }

  ngOnInit(): void {
    this.initialize('screen4', 'activity');
  }

}
