import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';

import { AppListenerService } from '@hiptraveler/common';
import { CustomMapAutocompleteService } from './custom-map-autocomplete.service';

@Directive({
  selector: '[cmaDisplay]'
})
export class CmaDisplayDirective implements AfterViewInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private appListener: AppListenerService,
    private service: CustomMapAutocompleteService
  ) { }

  ngAfterViewInit(): void {

    const element = this.element.nativeElement.parentElement?.parentElement;

    if (!element) return;

    const inputElements = element.getElementsByTagName('input');
    const customMapsId = Array.from(inputElements).find((input) => input.getAttribute('custom-maps-id'))?.getAttribute('custom-maps-id') || '';

    this.appListener.mapsAutocompleteInput$.pipe(
      map((value: Record<string, string>) => value[customMapsId]),
      distinctUntilChanged(),
      takeUntil(this.subscription$),
    ).subscribe((value: string) => this.service.cmaChanges$$.next(value));
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
