import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map, Observable, skip, Subject, switchMap, take, takeUntil } from 'rxjs';

import { LocationData } from '@hiptraveler/data-access/api';
import { SearchState } from '@hiptraveler/data-access/search';
import { BrandState } from '@hiptraveler/data-access/brand';
import { currentLang } from '@hiptraveler/common';
import * as Feature from '@hiptraveler/features/meta-tag';

@Injectable()
export class MetaTagService {

  subscription$ = new Subject<void>();
  metaData$ = new Subject<Feature.Metadata>();

  constructor(
    private router: Router,
    private store: Store,
  ) { }

  get imgUrl$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      filter(Boolean),
      map( e => e?.cCoverImg?.cover || Feature.defaultMetaTagData.imageUrl ),
      takeUntil(this.subscription$)
    );
  }

  get navigationEnd$(): Observable<Feature.ObserverConfig> {;
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => e.url),
      skip(1),
      switchMap((url: string) => this.rootConfigByUrl(url)),
      takeUntil(this.subscription$)
    );
  }

  updateMetaData(value: Feature.Metadata): void {
    this.metaData$.next(value);
  }

  rootConfigByUrl(url: string): Observable<Feature.ObserverConfig> {
    return this.imgUrl$.pipe(
      switchMap((imageUrl: string) => this.store.select(SearchState.locationData).pipe(
        map((locationData: LocationData | null) => (<Feature.ObserverConfig>{
          metadata: { imageUrl, callback: { location: locationData?.city } },
          lang: currentLang(),
          url
        }))
      )),
      take(1)
    );
  }

}
