import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { EMPTY, catchError, of, tap } from 'rxjs';
import { omit, uniqBy } from 'lodash';

import { ProfileStateModel, ProfileAction, PlaceVisited } from '.';
import * as API from '@hiptraveler/data-access/api';
import * as Helper from './helpers';
import { AuthAction } from '@hiptraveler/data-access/auth';
import { clientVID } from '@hiptraveler/common';

export const PROFILE_STATE_TOKEN = new StateToken<ProfileStateModel>('state_profile');

export const profileStateDefaults: any = null;

@State<ProfileStateModel>({
  name: PROFILE_STATE_TOKEN,
  defaults: profileStateDefaults
})

@Injectable()
export class ProfileState {

  @Selector()
  static state(state: ProfileStateModel): ProfileStateModel | null {
    return state || null;
  }

  @Selector()
  static profileDetails(state: ProfileStateModel): API.UserProfile | null {
    return state?.profileDetails || null;
  }

  @Selector()
  static travelAgent(state: ProfileStateModel): API.TravelAgent | null {
    return state?.travelAgent || null;
  }

  @Selector()
  static ambassador(state: ProfileStateModel): API.Ambassador | null {
    return state?.ambassador || null;
  }

  @Selector()
  static itineraryResults(state: ProfileStateModel): API.ItineraryData[] | null {
    return ProfileState.getResults(state, 'itineraryResults', 'id');
  }

  @Selector()
  static blogResults(state: ProfileStateModel): Partial<API.BasicInfoData>[] | null {
    return ProfileState.getResults(state, 'blogResults', 'id');
  }

  @Selector()
  static imageResults(state: ProfileStateModel): API.ImageResultData[] | null {
    return ProfileState.getResults(state, 'imageResults', 'id');
  }

  @Selector()
  static followingResults(state: ProfileStateModel): API.ProfileResultData[] | null {
    return ProfileState.getResults(state, 'followingResults', 'profId');
  }

  @Selector()
  static followerResults(state: ProfileStateModel): API.ProfileResultData[] | null {
    return ProfileState.getResults(state, 'followerResults', 'profId');
  }

  @Selector()
  static hotelResults(state: ProfileStateModel): API.HotelResultData[] | null {
    return ProfileState.getResults(state, 'hotelResults', 'id');
  }

  @Selector()
  static placesVisitedRecord(state: ProfileStateModel): API.PlaceLocations | null {
    return state?.profileDetails.placesVisited || null;
  }

  @Selector()
  static placesVisited(state: ProfileStateModel): PlaceVisited[] | null {
    const data = state?.profileDetails.placesVisited || {};
    const result = Object.keys(data).map((value: string, index: number) => {
      const place = Object.values(data)[index];
      return place.length ? { place: value, data: place } : null
    }).filter(Boolean);
    return state?.profileDetails.placesVisited ? [...result] as PlaceVisited[] : null;
  }

  @Selector()
  static pagination(state: ProfileStateModel): API.SearchResultPagination | null {
    return state?.pagination || null;
  }

  static getResults(state: ProfileStateModel, prop: keyof ProfileStateModel, key: string): any[] | null {
    const results: any = state?.[prop];
    return results ? uniqBy(results, key).filter(Boolean) : null;
  }

  constructor(
    private authApi: API.AuthApiService,
    private profileApi: API.ProfileApiService,
    private discoveryBoardApi: API.DiscoveryBoardApiService,
    private followApi: API.FollowApiService
  ) { }

  @Action(ProfileAction.GetProfileDetails)
  getProfileDetails(ctx: StateContext<ProfileStateModel>, action: ProfileAction.GetProfileDetails) {
    return this.profileApi.getProfileDetails(action.userId).pipe(
      tap(API.validateResponse),
      tap(({ data }: API.GetProfileDetailsResponse) => {
        const results = omit(data, [ 'profile', 'travelagent' ]);
        ctx.patchState({ profileDetails: data?.profile, travelAgent: data?.travelagent, ...results });
      })
    );
  }

  @Action(ProfileAction.GetItineraryResults)
  geItineraryResults(ctx: StateContext<ProfileStateModel>, action: ProfileAction.GetItineraryResults) {
    return this.discoveryBoardApi.geItineraryResults(action.data).pipe(
      tap(API.validateResponse),
      tap(({ data }: API.GetItineraryResultsResponse) => ctx.patchState({
        itineraryResults: (ctx.getState()?.itineraryResults || []).concat(data?.boardMapList || []),
        pagination: { moreResults: !!data?.moreResults, page: data?.nextCount || -1 }
      }))
    );
  }

  @Action(ProfileAction.GetProfileStoryResults)
  getProfileStoryResults(ctx: StateContext<ProfileStateModel>, action: ProfileAction.GetProfileStoryResults) {
    return this.profileApi.getProfileStoryResults(action.data).pipe(
      tap((data: API.GetProfileStoryResultsResponse) => {
        API.validateResponse(data, { ignoreKey: 'blogList' });
        ctx.patchState({
          blogResults: (ctx.getState()?.blogResults || []).concat(data?.blogList || []),
          pagination: { moreResults: !!data?.moreResults, page: data?.nextCount || -1 }
        });
      })
    );
  }

  @Action(ProfileAction.GetProfileFavoriteResults)
  getProfileFavoriteResults(ctx: StateContext<ProfileStateModel>, action: ProfileAction.GetProfileStoryResults) {
    return this.profileApi.getProfileFavoriteResults(action.data).pipe(
      tap(API.validateResponse),
      tap(({ data }: API.GetProfileFavoriteResultsResponse) => ctx.patchState({
        imageResults: (ctx.getState()?.imageResults || []).concat(data?.imgList || []),
        itineraryResults: (ctx.getState()?.itineraryResults || []).concat(data?.albumList || []),
        hotelResults: (ctx.getState()?.hotelResults || []).concat(data?.hotelList || []),
        blogResults: (ctx.getState()?.blogResults || []).concat(data?.blogList || []),
        pagination: { moreResults: !!data?.moreResults, page: data?.moreResults ? 2 : -1 }
      }))
    );
  }

  @Action(ProfileAction.GetProfileUploadResults)
  getProfileUploadResults(ctx: StateContext<ProfileStateModel>, action: ProfileAction.GetProfileUploadResults) {
    return this.profileApi.getProfileUploadResults(action.data).pipe(
      tap(API.validateResponse),
      tap(({ data }: API.GetProfileUploadResultsResponse) => ctx.patchState({
        imageResults: (ctx.getState()?.imageResults || []).concat(data?.imgList || []),
        pagination: { moreResults: !!data?.moreResults, page: data?.moreResults ? 2 : -1 }
      })),
      catchError(() => { ctx.patchState({ imageResults: [] }); return EMPTY; })
    );
  }

  @Action(ProfileAction.GetFollowingUserResults)
  getFollowingUserResults(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.GetFollowingUserResults) {
    return this.profileApi.getFollowingUserResults(data).pipe(
      tap(API.validateResponse),
      tap(({ data }: API.GetProfileUsersResponse) => ctx.patchState({
        followingResults: (ctx.getState()?.followingResults || []).concat(data?.profResults || []),
        pagination: { moreResults: !!data?.moreResults, page: data?.nextCount || -1 }
      })),
    );
  }

  @Action(ProfileAction.GetFollowersUserResults)
  getFollowersUserResults(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.GetFollowersUserResults) {
    return this.profileApi.getFollowersUserResults(data).pipe(
      tap(API.validateResponse),
      tap(({ data }: API.GetProfileUsersResponse) => ctx.patchState({
        followerResults: (ctx.getState()?.followerResults || []).concat(data?.profResults || []),
        pagination: { moreResults: !!data?.moreResults, page: data?.nextCount || -1 }
      })),
    );
  }

  @Action(ProfileAction.GetTravelAgent)
  getTravelAgent(ctx: StateContext<ProfileStateModel>, { travelAgentId }: ProfileAction.GetTravelAgent) {
    return this.profileApi.getTravelAgent(travelAgentId).pipe(
      tap((response: API.TravelAgentResponse) => {
        API.validateResponse(response, { ignoreKey: 'travelagent' });
        ctx.patchState({ travelAgent: response.travelagent });
      })
    );
  }

  @Action(ProfileAction.GetAmbassador)
  getAmbassador(ctx: StateContext<ProfileStateModel>, { ambassadorId }: ProfileAction.GetAmbassador) {
    return this.profileApi.getAmbassador(ambassadorId).pipe(
      tap((response: API.AmbassadorResponse) => {
        API.validateResponse(response, { ignoreKey: 'ambassador' });
        ctx.patchState({ ambassador: response.ambassador });
      })
    );
  }

  @Action(ProfileAction.UpdateProfileContent)
  updateProfileContent(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.UpdateProfileContent) {
    return this.profileApi.updateProfileContent(data).pipe(
      tap(API.validateResponse),
      tap((response: API.UpdateProfileContentResponse<{ profile: API.UserProfile }>) => ctx.patchState({ profileDetails: response.data?.profile }))
    );
  }

  @Action(ProfileAction.UpdatePassword)
  updatePassword(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.UpdatePassword) {
    return this.authApi.updatePassword(data).pipe(
      tap(API.validateResponse),
      tap((response: API.UpdatePasswordResponse) => ctx.dispatch(new AuthAction.SetAccessToken(response.accessToken)))
    );
  }

  @Action(ProfileAction.UpdateProfileSettings)
  updateProfileSettings(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.UpdateProfileSettings) {
    return this.profileApi.updateProfileSettings(data).pipe(
      tap(API.validateResponse),
      tap((response: API.UpdateProfileContentResponse<{ dnsmpi: boolean, sendNL: boolean }>) => ctx.setState( patch({ profileDetails: patch({
        noSellInfo: response.data?.dnsmpi,
        sendNewsLetter: response.data?.sendNL
      })})))
    );
  }

  @Action(ProfileAction.UpdatePlacesVisited)
  updatePlacesVisited(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.UpdatePlacesVisited) {
    return this.profileApi.updatePlacesVisited(data).pipe(
      tap(API.validateResponse),
      tap((response: API.UpdatePlacesVisitedResponse) => ctx.setState(patch<ProfileStateModel>({
        profileDetails: patch<API.UserProfile>({
          placesVisited: response.data?.places,
          countriesVisited: response.data?.countriesVisited
        })
      })))
    );
  }

  @Action(ProfileAction.SaveTravelAgent)
  saveTravelAgent(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.SaveTravelAgent) {
    return this.profileApi.saveTravelAgent(data).pipe(
      tap((response: API.TravelAgentResponse) => {
        API.validateResponse(response, { ignoreKey: 'travelagent' });
        ctx.patchState({ travelAgent: response.travelagent });
      })
    );
  }

  @Action(ProfileAction.SaveAmbassador)
  saveAmbassador(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.SaveAmbassador) {
    return this.profileApi.saveAmbassador(data).pipe(
      tap((response: API.AmbassadorResponse) => {
        API.validateResponse(response, { ignoreKey: 'ambassador' });
        ctx.patchState({ ambassador: response.ambassador });
      })
    );
  }

  @Action(ProfileAction.SavePlaceDetails)
  savePlaceDetails(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.SavePlaceDetails) {
    return this.profileApi.savePlaceDetails(data).pipe(
      tap((response: API.SavePlaceDetailsResponse) => (response.data?.img && ctx.setState(patch<ProfileStateModel>({
        imageResults: insertItem(response.data.img)
      }))))
    );
  }

  @Action(ProfileAction.FollowProfileById)
  followUser(ctx: StateContext<ProfileStateModel>, action: ProfileAction.FollowProfileById) {
    return this.followApi.followUser({ followUserId: action.profileId, vId: clientVID() }).pipe(
      tap(API.validateResponse),
      tap(() => ctx.patchState({ followStatus: true }))
    );
  }

  @Action(ProfileAction.ToggleProfileResultLike)
  toggleProfileResultLike(ctx: StateContext<ProfileStateModel>, { data }: ProfileAction.ToggleProfileResultLike) {
    return this.discoveryBoardApi.toggleLikeItinerary(data).pipe(
      tap(API.validateResponse),
      tap((response: API.ToggleLikeItineraryResponse) => {
        const updatedSearchResults = data.hipType
          ? Helper.removeUserLike(ctx.getState()?.[data.field]!, data.id, data.userId, response.data!.hipCount) 
          : Helper.addUserLike(ctx.getState()?.[data.field]!, data.id, data.userId, response.data!.hipCount);
        ctx.patchState({ [data.field]: updatedSearchResults });
      })
    )
  }

  @Action(ProfileAction.VerifyStateWithAuthStateChanges)
  verifyAuthSelfProfile(ctx: StateContext<ProfileStateModel>, action: ProfileAction.VerifyStateWithAuthStateChanges) {
    const profileId = ctx.getState()?.profileDetails?.profileId;
    return profileId ? of(ctx.patchState({
      selfProfile: profileId === action.authUserId,
      followStatus: action.authFollowing?.includes(profileId)
    })) : of(null);
  }

  @Action(ProfileAction.DeleteAccountProfileById)
  deleteAccountProfileById(_: StateContext<ProfileStateModel>, { data }: ProfileAction.DeleteAccountProfileById) {
    return this.profileApi.deleteAccount(data).pipe(
      tap(API.validateResponse)
    );
  }

  @Action(ProfileAction.PartialResetProfileState)
  PartialResetItineraryState(ctx: StateContext<ProfileStateModel>, action: ProfileAction.PartialResetProfileState) {
    const data = Object.fromEntries( action.fields.map(field => [ field, null ]) );
    ctx.patchState({ ...ctx.getState(), ...data });
  }

  @Action(ProfileAction.ResetProfileState)
  resetProfileState(ctx: StateContext<ProfileStateModel>) {
    ctx.setState(profileStateDefaults);
  }

}
