import { Component, Inject } from '@angular/core';

import { WINDOW } from '@hiptraveler/common';
import { FooterService } from './footer.service';

@Component({
  selector: 'non-hiptraveler-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  viewProviders: [ FooterService ]
})
export class FooterComponent {

  hiptravelerLogoImage: string = `assets/img/logos/hiptraveler-logo-full-white-bg.webp`;

  constructor(
    @Inject(WINDOW) private window: any,
    public service: FooterService
  ) { }

  goToHipTravelerWebsite(): void {
    this.window.open('https://www.hiptraveler.com/', '_blank');
  }

}
