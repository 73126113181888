import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }
  
  transform(url?: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(url || '');
  }

}

@NgModule({
  declarations: [ SafeHtmlPipe ],
  exports:      [ SafeHtmlPipe ]
})
export class SafeHtmlPipeModule { }

