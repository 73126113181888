import { Directive, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppListenerService, LOCATION, SUPPORTED_LANG } from '@hiptraveler/common';
import { Location } from '@angular/common';

@Directive({
  selector: '[appI18nSetup]'
})
export class I18nSetupDirective implements OnInit {

  constructor(
    @Inject(LOCATION) private location: any,
    private _location: Location,
    private router: Router,
    private translateService: TranslateService,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    this.initializeI18n();
    this.observeI18nLanguageChanges();
    this.observeI18nLanguageFileLoaded();
  }

  initializeI18n(): void {
    const langInUrlPath = this.location.pathname.split('/')[1];

    const hasLangInUrlPath = !!SUPPORTED_LANG.find(e => e === langInUrlPath);
    const lang = hasLangInUrlPath ? langInUrlPath : 'en';

    this.translateService.setDefaultLang('en');
    this.translateService.use(lang);

    if (!hasLangInUrlPath) {
      this.router.navigate([ `/${lang}${this.location.pathname}` ]);
    }
  }

  observeI18nLanguageChanges(): void {
    this.translateService.onLangChange.subscribe((res) => {
      const langInUrlPath = this.location.pathname.split('/')[1];
      const newPathname = this.location.pathname.replace(`/${langInUrlPath}/`, `/${res.lang}/`);
      this._location.replaceState(`${newPathname}${this.location.search}`);
    });
  }

  observeI18nLanguageFileLoaded(): void {
    this.translateService.onLangChange.subscribe(
      () => this.appListener.languageLoadState$$.next(true)
    );
  }

}
