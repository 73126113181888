import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';

import { ResetPasswordDto } from '@hiptraveler/data-access/api';
import { AuthAction, RESET_PASSWORD_KEY } from '@hiptraveler/data-access/auth';
import { SnackbarService } from '@hiptraveler/snackbar';
import { RegexPattern, TranslationService, isWidget } from '@hiptraveler/common';

@Injectable()
export class ForgotPasswordService {
  
  form: FormGroup;
  pending$$ = new BehaviorSubject<boolean>(false);
  successListener$$ = new Subject<void>();

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private store: Store,
    private translation: TranslationService,
    private snackbar: SnackbarService,
  ) { }

  initializeForm(): void {
    sessionStorage.removeItem(RESET_PASSWORD_KEY);
    this.form = this.fb.group({
      email: [
        '',
        [ Validators.required, Validators.pattern(RegexPattern.email) ]
      ],
    });
  }

  get isWidget(): boolean {
    return isWidget();
  }

  async forgotPassword(): Promise<void> {
    if (this.pending$$.value || this.form.invalid) return;
    const form: ResetPasswordDto = this.form.value;
    this.pending$$.next(true);
    try {
      this.snackbar.open({ message: 'Please wait while we send the OTP to your email...', duration: Infinity });
      await firstValueFrom(this.store.dispatch(new AuthAction.ForgotPassword(form)));
      this.successListener$$.next();
    } catch (response: any) {
      this.pending$$.next(false);
      const message = this.translation.getText(response?.error || 'Email is invalid.');
      this.snackbar.open({ message, duration: 5000 });
    } finally {
      this.form.reset();
    }
  }
  
}
