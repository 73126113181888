import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { map, skip, take } from 'rxjs';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { HotelOffersDialogActionService } from '@hiptraveler/dialogs/hotel-offers-dialog';
import { PriceOffersService } from './price-offers.service';
import { opacityFade } from '@hiptraveler/animations';
import { animationDelay } from '../../common';

@Component({
  selector: 'price-offers',
  templateUrl: './price-offers.component.html',
  styleUrls: ['./price-offers.component.scss'],
  host: { class: 'content' },
  viewProviders: [ PriceOffersService, HotelOffersDialogActionService ],
  animations: [ opacityFade(animationDelay) ]
})
export class PriceOffersComponent implements OnInit {

  @Input() data: HotelResultData;

  progressVisibility: boolean = true;

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: PriceOffersService
  ) { }

  ngOnInit(): void {
    (this.service.hotelInformation$ as any).pipe(
      skip(1),
      map((response: HotelResultData) => {
        const visibility = 
          !!response?.offers?.length
          || !!response.lowestOffer
          || !!response.taDataMap;
        this.progressVisibility = !!response.lowestOffer;
        visibility || (this.element.nativeElement.style.display = 'none');
      }),
      take(1)
    ).subscribe();
  }

  openDialog(): void {
    this.service.openDialog(this.data);
  }

}
