import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { AppListenerService, CDNReaderService } from '@hiptraveler/common';
import { UserActivityService } from './user-activity.service';
import { ProfileStateService } from './../../common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss', './user-activity-responsiveness.scss'],
  host: { class: 'profile-page-activity-content-user-activity' },
  viewProviders: [ UserActivityService, CDNReaderService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class UserActivityComponent implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    public appListener: AppListenerService,
    public service: UserActivityService,
    public stateService: ProfileStateService,
    private cdnReader: CDNReaderService
  ) { }

  get ambassadorBadge$(): Observable<string> {
    return this.cdnReader.getCdnData('base64.appCommon').pipe(
      map((e: any) => e.ambassador)
    );
  }

  ngOnInit(): void {
    this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth <= 900),
      tap((state: boolean) => {
        this.element.nativeElement.style.display = state ? 'none' : 'block';
      })
    ).subscribe();
  }

}
