import { Component, Input } from "@angular/core";

@Component({
  selector: 'itinerary-title',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'itinerary-title' }
})
export class ItineraryFeatureCardTitleComponent {}

@Component({
  selector: 'itinerary-subtitle',
  template: '<p><ng-content></ng-content></p>',
  host: { class: 'itinerary-subtitle' }
})
export class ItineraryFeatureCardSubtitleComponent {}

@Component({
  selector: 'itinerary-description',
  template: '<p [innerHTML]="snippet"></p>',
  host: { class: 'itinerary-description' }
})
export class ItineraryFeatureCardDescriptionComponent {
  @Input() snippet?: string | null;
}

@Component({
  selector: 'itinerary-activity-duration',
  template: '<div><p class="ht-digit" [innerHTML]="html"></div>',
  host: { class: 'itinerary-activity-duration' }
})
export class ItineraryFeatureCardDurationComponent {
  @Input() html?: string;
}

@Component({
  selector: 'itinerary-rating',
  template: `<p>{{ 'cards.outstanding' | translate }} <span><ng-content></ng-content></span></p>`,
  host: { class: 'itinerary-rating' }
})
export class ItineraryFeatureCardRatingComponent {}

@Component({
  selector: 'itinerary-rating-reviews',
  template: `<p><span><ng-content></ng-content></span> {{ 'cards.reviews' | translate }}</p>`,
  host: { class: 'itinerary-rating-reviews' }
})
export class ItineraryFeatureCardRatingReviewsComponent {}

@Component({
  selector: 'itinerary-reviews',
  template: `<p>{{ 'cards.based-on' | translate }} <span><ng-content></ng-content></span> {{ 'cards.reviews' | translate }}</p>`,
  host: { class: 'itinerary-reviews' }
})
export class ItineraryFeatureCardReviewsComponent {}

@Component({
  selector: 'itinerary-button',
  template: `
    <div matRipple class="mat-elevation-z2" [ngClass]="{ warn: appearance === 'warn' }">
      <p  *ngIf="!pending"><ng-content></ng-content></p>
      <mat-progress-spinner mode="indeterminate" [diameter]="20" [strokeWidth]="3" *ngIf="pending"></mat-progress-spinner>
    </div>
  `,
  host: { class: 'itinerary-button' }
})
export class ItineraryFeatureCardButtonComponent {
  @Input() pending: boolean;
  @Input() appearance: string;
}

export const FeatureCardExportComponents = [
  ItineraryFeatureCardTitleComponent,
  ItineraryFeatureCardSubtitleComponent,
  ItineraryFeatureCardDescriptionComponent,
  ItineraryFeatureCardDurationComponent,
  ItineraryFeatureCardRatingComponent,
  ItineraryFeatureCardRatingReviewsComponent,
  ItineraryFeatureCardReviewsComponent,
  ItineraryFeatureCardButtonComponent
];

export const FeatureCardComponents = [
  FeatureCardExportComponents
];
