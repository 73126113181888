import { AfterViewInit, Directive, ElementRef, Input, NgModule, OnDestroy, Renderer2 } from '@angular/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil, tap } from 'rxjs';

import { AuthAction } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { currentLang } from '@hiptraveler/common';

/**
 * ActionActiveDirective
 *
 * Activates an element when a specific action is marked as "active" in the user's following list.
 * Typically used to highlight buttons or links that represent actions the user is currently following.
 */
@Directive({
  selector: '[actionActive]'
})
export class ActionActiveDirective implements OnDestroy, AfterViewInit {

  @Input() actionActive: string;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private store: Store,
    private actions$: Actions,
    private i18n: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  /**
   * Sets up the active state after the view has been initialized.
   */
  ngAfterViewInit(): void {

    this.actions$.pipe(
      ofActionDispatched(AuthAction.SignOut),
      tap(() => {
        this.renderer.removeClass(this.element.nativeElement, 'active');
        const element = this.element.nativeElement.getElementsByTagName('p').item(0);
        element && (element.innerText = this.getText('follow'));
      }),
      takeUntil(this.subscription$)
    ).subscribe();

    this.store.select(UserState.following).pipe(
      filter(Boolean),
      tap((following: string[]) => {
        if (!following.includes(this.actionActive)) return;
        this.renderer.addClass(this.element.nativeElement, 'active');
        const element = this.element.nativeElement.getElementsByTagName('p').item(0);
        element && (element.innerText = this.getText('following'));
      })
    ).subscribe();
  }

  getText(translation: string): string {
    return this.i18n.translations?.[currentLang()]?.['view-page']?.[translation] || translation;
  }

}

@NgModule({
  declarations: [ ActionActiveDirective ],
  exports:      [ ActionActiveDirective ]
})
export class ActionActiveModule { }
