import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { flattenDeep } from 'lodash';

import { ImageResultData } from '@hiptraveler/data-access/api';

@Directive({
  selector: '[aboutThisTourContent]'
})
export class AboutThisTourContentDirective implements OnInit {

  @Input('aboutThisTourContent') data: ImageResultData;

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngOnInit(): void {
    const tourAbstract = this.data.tourData?.tourAbstract || '';
    const descriptions = this.data.tourData?.tourDescription?.split('\n\n').map(e => e.split('\n')) || [];
    
    if (!tourAbstract && !descriptions.length) {
      this.setContent([ this.data.imgDesc ]);
      return;
    }

    if (this.data.imgAuthInfo?.authorProfId !== 'viator') {
      this.setContent([ tourAbstract, ...descriptions ]);
    } else {
      this.setContent([ ...descriptions ]);
    }
  }

  private setContent(content: any[]): void {
    const newHTMLContent = flattenDeep(content).map(e => `<p>${e}</p>`).join('');
    this.element.nativeElement.innerHTML = newHTMLContent;
  }

}
