<div class="blank-itinerary ht-feature-card">
  
  <div class="blank-itinerary--img-placeholder">
    <p translate="search-page.share-story"></p>
  </div>

  <div class="blank-itinerary--content">
    <ui-action-button rounded (click)="createNewItinerary()">{{ 'search-page.create-new' | translate }}</ui-action-button>
  </div>

</div>
