import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';

@Component({
  selector: 'about-hotel, about-food',
  template: `
    <div class="about-hotel ht-dialog-elevation" *ngIf="hotel?.locDesc || image?.imgDesc">
      <ng-container *ngIf="hotel">
        <h2 translate="search-result.about-this-hotel"></h2>
      </ng-container>
      <ng-container *ngIf="image">
        <h2 translate="search-result.about-this-place"></h2>
      </ng-container>
      <div class="about-hotel--message-content" [hotelDescription]="hotel" [imageDescription]="image"></div>
    </div>
  `,
  styles: [`
    .about-hotel--message-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  `],
  host: { class: 'content' }
})
export class AboutResultComponent implements OnInit {

  @Input() hotel?: HotelResultData;
  @Input() image?: ImageResultData;

  constructor(
    private element: ElementRef<HTMLElement>,
  ) { }

  ngOnInit(): void {
    if (this.hotel?.locDesc || this.image?.imgDesc) return;

    this.element.nativeElement.style.display = 'none';
  }

}
