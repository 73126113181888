<div style="width: 100dvw;height: 50px;"></div>

<div class="hiptraveler-gpt mat-elevation-z2">

  <button mat-icon-button (click)="resetConversation()" *ngIf="!pending && (conversations$ | async)?.length">
    <mat-icon>refresh</mat-icon>
  </button>

  <div class="hiptraveler-gpt--conversation" #conversationBox>
    <div class="hiptraveler-gpt--conversation-line" [ngClass]="{ sender: response.sender, receiver: !response.sender }" *ngFor="let response of conversations$ | async;">
      
      <div [innerHTML]="response.message"></div>
      <img *ngIf="!response.sender" src="https://wellfound.com/cdn-cgi/image/width=140,height=140,fit=scale-down,gravity=0.5x0.5,quality=90,format=auto/https://photos.wellfound.com/startups/i/235295-cdb4e3111a676942b828481c0f24b632-medium_jpg.jpg?buster=1455755035" alt="hiptraveler">

    </div>
    <ng-container *ngIf="pending">
      <div class="hiptraveler-gpt--conversation-line pending" [@opacityFadeIn]>
        <div class="loader"></div>
      </div>
    </ng-container>
  </div>

  <div class="hiptraveler-gpt--input" [ngClass]="{ initial: !(conversations$ | async)?.length }">

    <p *ngIf="!(conversations$ | async)?.length">AI Travel Assistance</p>

    <div class="hiptraveler-gpt--input-action">
      <button mat-icon-button [ngClass]="{ disabled: !inputField.value}" (click)="sendMessage()">
        <mat-icon>send</mat-icon>
      </button>
    </div>

    <input class="hiptraveler-gpt--input-field" type="text" placeholder="Message HipTravelerGPT" (keyup.enter)="sendMessage()"[(ngModel)]="contentInput" #inputField>

  </div>

</div>
