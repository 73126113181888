import { Component } from '@angular/core';

@Component({
  selector: 'blub-circle',
  template: `
    <div class="blub">
      <div class="blub-1"></div>
      <div class="blub-2" blubStyles></div>
      <div class="blub-3" blubStyles></div>

      <img src="assets/img/logos/white-HT-logo.webp">
    </div>
  `,
  styleUrls: ['./blub-circle.component.scss']
})
export class BulbCircleComponent {}
