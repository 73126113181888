import { Injectable } from '@angular/core';

interface Category { label: string, translation: string };

const CATEGORIES: Category[] = [
  { label: 'Kid Friendly', translation: 'profile.kid-friendly' },
  { label: 'Pets Allowed', translation: 'profile.pets-allowed' },
  { label: 'Good For Groups', translation: 'profile.good-for-groups' },
  { label: 'Wheelchair accessible', translation: 'profile.wheelchair-accessible' },
  { label: 'Romantic', translation: 'profile.romantic' },
  { label: 'Art & Museum', translation: 'profile.art-museum' },
  { label: 'Nature & Adventure', translation: 'profile.nature-adventure' },
  { label: 'Viewing Point', translation: 'profile.viewing-point' },
  { label: 'Environment Friendly', translation: 'profile.environment-friendly' },
  { label: 'Couples', translation: 'profile.couples' },
  { label: 'Family', translation: 'profile.family' },
  { label: 'Adults Only', translation: 'profile.adults-only' },
  { label: 'Children Playground', translation: 'profile.children-playground' },
  { label: 'Zoo & Aquarium', translation: 'profile.zoo-aquarium' },
  { label: 'Nightlife', translation: 'profile.nightlife' },
  { label: 'Event', translation: 'profile.event' },
  { label: 'Spa', translation: 'profile.spa' },
  { label: 'Budget', translation: 'profile.budget' },
  { label: 'Luxury', translation: 'profile.luxury' },
  { label: 'Package', translation: 'profile.package' },
  { label: 'Guided Tour', translation: 'profile.guided-tour' },
  { label: 'Private Tour', translation: 'profile.private-tour' },
  { label: 'Bike Rentals', translation: 'profile.bike-rentals' },
  { label: 'Bus & Minivan Tour', translation: 'profile.bus-minivan-tour' },
  { label: 'Lunch Package', translation: 'profile.lunch-package' },
  { label: 'Dinner Package', translation: 'profile.dinner-package' },
  { label: 'Beach', translation: 'profile.beach' },
  { label: 'Shopping', translation: 'profile.shopping' },
  { label: 'Relaxing', translation: 'profile.relaxing' },
  { label: 'Walking Tour', translation: 'profile.walking-tour' },
  { label: 'Meal included', translation: 'profile.meal-included' },
  { label: 'Snacks included', translation: 'profile.snacks-included' },
  { label: 'Sustainable Travel', translation: 'profile.sustainable-travel' },
  { label: 'Meaningful Travel', translation: 'profile.meaningful-travel' },
  { label: 'Cultural', translation: 'profile.cultural' },
  { label: 'Adventurous', translation: 'profile.adventurous' },
  { label: 'Historic', translation: 'profile.historic' },
  { label: 'Local Market', translation: 'profile.local-market' },
];

@Injectable()
export class CategoriesService {

  get categories(): Category[] {
    return CATEGORIES;
  }

}
