import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ExperiencesModule } from './experiences/experiences.module';

import { ExperiencesContentComponent } from './experiences-content.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  ExperiencesModule
];

const COMPONENTS = [
  ExperiencesContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ExperiencesContentComponent
  ]
})
export class ExperiencesContentModule { }
