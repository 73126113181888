export const carouselPrompt = (keyCodes: string[]): string => `

  const keyCodes = [ ${keyCodes.toString().replace(/,/g, ', ')} ];
  
  - Extract only the keywords from the keyCodes array that are relevant to the user's input and respond exclusively in the specified format.
`;


export const carouselScreenViewPrompts = (keyCodes: string[]): string[] => [
  carouselPrompt(keyCodes), `
   - Response Format:
     ["list_of_matching_keyCodes"]
   - Important: Ensure your response is strictly in the specified JSON format and contains only the keyCodes array.
`];
