import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TripViewPageRootComponent } from './trip-view-page-root.component';

const tripViewPageRootRoutes: Routes = [
  {
    path: 'travel-story/:blog',
    loadChildren: () => import ('../page--travel-story/travel-story.module').then(m => m.TravelStoryModule)
  },
  {
    path: 'itinerary/:itinerary',
    loadChildren: () => import ('../page--itinerary/itinerary.module').then(m => m.ItineraryModule)
  },
  {
    path: 'itinerary/:itinerary/:id',
    loadChildren: () => import ('../page--itinerary/itinerary.module').then(m => m.ItineraryModule)
  },
  {
    path: 'Itinerary/:itinerary',
    loadChildren: () => import ('../page--itinerary/itinerary.module').then(m => m.ItineraryModule)
  },
  {
    path: 'Itinerary/:itinerary/:id',
    loadChildren: () => import ('../page--itinerary/itinerary.module').then(m => m.ItineraryModule)
  }
];

const routes: Routes = [
  {
    path: '',
    component: TripViewPageRootComponent,
    children: tripViewPageRootRoutes
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class TripViewRootRoutingModule { }
