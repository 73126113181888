import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileStateService } from '../common';
import { currentLang } from '@hiptraveler/common';

@Injectable()
export class SidenavService {

  constructor(
    private router: Router,
    public stateService: ProfileStateService
  ) { }

  navigate(route: string): void {
    this.router.navigate([ currentLang(), route ]);
  }

}
