import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, switchMap } from 'rxjs';
import { uniq } from 'lodash';

import { ActivityDate, ActivityDateData, BasicInfoData } from '@hiptraveler/data-access/api';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { clientVID, currentLang } from '@hiptraveler/common';

@Pipe({
  name: 'validateStatus'
})
export class ValidateStatusPipe implements PipeTransform {

  constructor(
    private router: Router,
    private store: Store
  ) { }

  /**
   * Transforms the input activity data to a boolean value indicating
   * whether the given ID exists within the activity date map response.
   *
   * @param id The ID to search for within the activity data.
   */
  transform(id?: string): Observable<boolean> {

    if (new RegExp(`/${currentLang()}/(itinerary|travel-story|compose)/`).test(this.router.url)) {
      return this.store.select(ItineraryState.basicInfo).pipe(
        switchMap((basicInfo: Partial<BasicInfoData> | null) => this.store.select(UserState.profileId).pipe(
          map((profileId: string | null) => {
            return basicInfo?.author?.authorTitle === profileId
              || basicInfo?.author?.authorProfId === clientVID();
          })
        ))
      )
    }

    return this.store.select(ItineraryState.actDateMap).pipe(
      map((activities: ActivityDate | null) => {

        return uniq(Object.values(activities || {}).map((activity: Partial<ActivityDateData>, index: number) => {
          const imgArray: any = activity?.ImgArray || [];
          const hotelArray: any = activity?.HotelArray || [];
          const flattened = [].concat(...hotelArray).concat(...imgArray) || [];
          return !!flattened.filter(e => !!e).find((e: any) => e?.id === id) ? (index+1) : 0;
        })).filter(Boolean).some(e => e);
      })
    );
  }

}
