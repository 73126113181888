
<div class="ta-register--banner" [class.disabled]="!(service.authenticated$ | async)" (click)="service.verifyAuthentication()">
  <div class="ta-register--banner-overlay"></div>
  <img [lazyLoad]="service.destinationSpecialistImg" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" alt="Travel destination banner">
</div>

<div class="ta-register--content" [class.disabled]="!(service.authenticated$ | async)" (click)="service.verifyAuthentication()">
  
  <h1 translate="ta-register.destination"></h1>
  
  <h3 translate="ta-register.inbound-leads"></h3>
  <h3 translate="ta-register.export-itineraries"></h3>
  <h3 translate="ta-register.direct-messaging"></h3>

  <h3 class="info-text" translate="ta-register.apply-casa"></h3>

  <ui-action-button rounded (click)="tourAgentApply()">{{ 'ta-register.apply' | translate }}</ui-action-button>
  <br><br>
  <registration-form></registration-form>

</div>
