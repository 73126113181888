import { ChangeDetectorRef, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, delay, firstValueFrom, takeUntil } from 'rxjs';
import { pullAt } from 'lodash';

import { PlaceLocations } from '@hiptraveler/data-access/api';
import { PlaceVisited, ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { SnackbarService } from '@hiptraveler/snackbar';
import { StateService } from '../../common';
import { PlacesDialogComponent } from './places-dialog.component';
import { SearchLocationData } from '@hiptraveler/common';

@Injectable()
export class PlacesDialogService extends StateService {

  @Select(ProfileState.placesVisited) placesVisited$: Observable<PlaceVisited[] | null>;

  pending$$ = new BehaviorSubject<boolean>(false);
  pending$ = this.pending$$.asObservable();

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PlacesDialogComponent>,
    private snackbar: SnackbarService,
    store: Store
  ) {
    super(store);

    this.pending$.pipe(
      delay(100),
      takeUntil(this.subscription$)
    ).subscribe(() => this.cdRef.detectChanges());
  }

  addPlaceVisited(data: SearchLocationData): void {
    if (!this.placesVisited || this.pending$$.value || !data) return;

    this.pending$$.next(true);
    this.dialogRef.disableClose = true;
    const value = { ...this.placesVisited };
    
    if (!data?.name) {
      this.pending$$.next(false);
      this.dialogRef.disableClose = false;
      return;
    }

    const newPlace = { id: data?.locId ?? 'undefined', location: data?.location ?? 'undefined' };
    const country = data?.country ?? data?.name ?? 'undefined';
    
    (value[country] = value[country] || []).push(newPlace);

    this.updatePlacesVisited(value);
  }

  removePlaceVisited(parent: string, child: number): void {
    if (!this.placesVisited) return;
    
    this.pending$$.next(true);
    this.dialogRef.disableClose = true;
    const value = { ...this.placesVisited };

    pullAt(value[parent], child);

    this.updatePlacesVisited(value);
  }

  private get placesVisited(): PlaceLocations | null {
    return this.store.selectSnapshot(ProfileState.placesVisitedRecord);
  }

  private async updatePlacesVisited(data: PlaceLocations): Promise<void> {
    this.executeWithUserId(async (userId: string) => {
      try {
        await firstValueFrom(this.store.dispatch(new ProfileAction.UpdatePlacesVisited({ id: userId, data })))
      } catch(e) {
        this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
      } finally {
        this.pending$$.next(false);
        this.dialogRef.disableClose = false;
      }
    });
  }

}
