import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { ItineraryParam, SetUserActionShareMedium } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { EventAction } from '@hiptraveler/data-access/event';
import { AppListenerService, getWindowRef, pendingAuthProcessKey, requestEndpointParamValue } from '@hiptraveler/common';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SocialMediaShareHandleService } from '@hiptraveler/features/social-media-share-handle';
import { EmailShareDialogActionService } from '@hiptraveler/dialogs/email-share-dialog';

@Component({
  selector: 'travel-share-handles',
  templateUrl: './travel-share-handles.component.html',
  styleUrls: ['./travel-share-handles.component.scss'],
  viewProviders: [ SocialMediaShareHandleService, EmailShareDialogActionService, AuthDialogActionService ]
})
export class TravelShareHandlesComponent implements OnDestroy {

  authHandle$ = new Subject<void>();

  constructor(
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private socialMediaShareHandle: SocialMediaShareHandleService,
    private emailShareDialog: EmailShareDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }

  /**
   * @method handle(option: 'facebook' | 'twitter' | 'pinterest' | 'email'): unknown
   * Handles sharing functionality based on the selected sharing option.
   * 
   * @param option - The sharing option chosen by the user.
   */
  handle(option: 'facebook' | 'twitter' | 'pinterest' | 'email'): unknown {
    const value = this.store.selectSnapshot(ItineraryState.basicInfo) || null;

    if (!value) return;

    switch (option) {
      case 'facebook': {
        this.dispatchAnalyticsByShareMedium('facebook');
        return this.socialMediaShareHandle.facebookShare({
          title: value?.title || '',
          image: value?.coverImage?.imgThumbnail || '',
          url: value?.sharedUrlMap?.facebook || ''
        });
      }
      case 'twitter': {
        this.dispatchAnalyticsByShareMedium('twitter');
        return this.socialMediaShareHandle.twitterShare({
          title: value.title || '',
          url: value?.sharedUrlMap?.twitter || ''
        });
      }
      case 'pinterest': {
         this.dispatchAnalyticsByShareMedium('pinterest');
        return this.socialMediaShareHandle.pinterestShare({
          title: value?.title || '',
          image: value?.coverImage?.imgThumbnail || '',
          url: value?.sharedUrlMap?.pinterest || ''
        })
      }
      case 'email': {
        if (!this.store.selectSnapshot(UserState.authenticated)) {
          this.resetAuthHandle();
          const emitHandleKey = 'travelShareEmailHandle';
          this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
          this.appListener.globalSignalListener(emitHandleKey).pipe(
            tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
            takeUntil(this.authHandle$)
          ).subscribe(() => this.handle('email'));
          return;
        }
        this.dispatchAnalyticsByShareMedium('email');
        return this.emailShareDialog.open({
          blogTitle: value?.title || '',
          shareUrl: value?.sharedUrlMap?.email || ''
        });
      }
    }
  }

  private dispatchAnalyticsByShareMedium(shareMedium: SetUserActionShareMedium): void {

    const value = this.store.selectSnapshot(ItineraryState.basicInfo)!;
    const param: ItineraryParam = getWindowRef()?.[requestEndpointParamValue] || 'itinerary';

    this.store.dispatch(new EventAction.ExecuteShareEvent({ 
      actionId: value.id!, actionType: 'share', objType: param, shareMedium
     }));
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
