import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { HotelResultData, ImageResultData } from '@hiptraveler/data-access/api';

@Directive({
  selector: '[hotelDescription], [imageDescription]'
})
export class AboutResultDirective implements OnInit {

  @Input('hotelDescription') hotel?: HotelResultData;
  @Input('imageDescription') image?: ImageResultData;

  constructor(
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngOnInit(): void {
    const data = this.hotel?.locDesc || this.image?.imgDesc;
    if (!data) return
    this.element.nativeElement.innerHTML = data.replace(/<p><\/p>/g, '').replace(/<br>/g, '') || '';
  }

}
