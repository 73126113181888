import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchPageMapModule } from '@hiptraveler/features/leaflet-map';

import { MapComponent } from './map.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  SearchPageMapModule
];

const COMPONENTS = [
  MapComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    MapComponent
  ]
})
export class MapModule { }
