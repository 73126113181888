import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, delay, takeUntil } from 'rxjs';

import { RegistrationFormStateService } from '../common';

interface Language { code: string; display: string; checked: boolean };

@Injectable()
export class LanguageSupportService {

  subscription$ = new Subject<void>();

  languages: Language[] = [
    { code: 'en', display: 'English', checked: false },
    { code: 'fr', display: 'Français', checked: false },
    { code: 'es', display: 'Español', checked: false },
    { code: 'it', display: 'Italiano', checked: false },
    { code: 'de', display: 'Deutsche', checked: false },
    { code: 'zh', display: '中国', checked: false },
    { code: 'pt', display: 'Portuguese', checked: false }
  ];

  constructor(
    private stateService: RegistrationFormStateService,
  ) { }

  get formValue$(): Observable<string[]> {
    return this.stateService.langForm.valueChanges.pipe(
      delay(0),
      takeUntil(this.subscription$)
    );
  }

  checkboxChanges(index: number, state: boolean): void {
    !state ? this.addLanguage(index) : this.removeLanguage(index);
  }

  initializeFormObserver(): void {
    this.stateService.initializeFormObserver(() => this.subscription$.next());
  }

  private addLanguage(index: number): void {
    const control = new FormControl(this.languages[index].code)
    this.stateService.langForm.push(control);
  }

  private removeLanguage(index: number): void {
    const formLangValue = this.stateService.langForm.value as string[];
    const formLangIndex = formLangValue.indexOf(this.languages[index].code);
    this.stateService.langForm.removeAt(formLangIndex);
  }

}
