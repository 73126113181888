import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './footer.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  FooterComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    FooterComponent
  ]
})
export class NonHipTravelerFooterModule { }
