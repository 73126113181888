import { NavbarButtonData, NavbarControlStateService, currentLang } from "@hiptraveler/common";

export function updateAddMyTripUrl(navbarControl: NavbarControlStateService, newPageTitle: string): void {
  const buttons = navbarControl.activeNavbarActionButtons$$.value || [];
  const button = buttons.find(e => e?.name?.includes('action-text-open-trip'));
  if (!button) return;
  buttons.map((button: NavbarButtonData) => {
    if (button?.name?.includes('action-text-open-trip')) {
      button.url = `/${currentLang()}/compose/itinerary/${newPageTitle}`;
    }
    return button;
  });
}
