import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { OverlayCarouselDialogComponent } from './overlay-carousel-dialog.component';

export const OVERLAY_CAROUSEL_DIALOG_ID = 'overlay-carousel';

@Injectable()
export class OverlayCarouselDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<OverlayCarouselDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(OVERLAY_CAROUSEL_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(OverlayCarouselDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: OVERLAY_CAROUSEL_DIALOG_ID,
      panelClass: OVERLAY_CAROUSEL_DIALOG_ID + '-overlay-panel',
      backdropClass: OVERLAY_CAROUSEL_DIALOG_ID + '-overlay-backdrop',
      width: '800px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      // Content height + padding top + padding bottom
      height: '505px + 48px + 24px',
      maxHeight: '100dvh',
      scrollStrategy: this.overlay.scrollStrategies.block()
    })
  }

}
