import { Component, EventEmitter, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { UserBlogData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { CDNReaderService, currentLang } from '@hiptraveler/common';

@Component({
  selector: 'new-travelogue',
  templateUrl: './new-travelogue.component.html',
  styleUrls: ['../action-display-styles.scss'],
  styles: [`
    :host { display: flex; justify-content: center; }
  `]
})
export class NewTravelogueComponent {

  @Select(AuthState.authenticated) authenticated$: Observable<boolean>;
  @Select(UserState.authenticated) userAuthenticated$: Observable<boolean>;
  @Select(UserState.blogs) blogs$: Observable<UserBlogData[] | null>;

  @Output() actionClicked = new EventEmitter();
  
  lang: string = currentLang();

  constructor(
    public stateService: ExperienceFinderStateService,
    private cdnReader: CDNReaderService
  ) { }

  get travelogueImage$(): Observable<string> {
    return this.cdnReader.dataCache$.pipe(
      map((e: any) => e['response3']['travelogue-image'])
    );
  }

}
