import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';

@Injectable()
export class HomeMenuService {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<BrandCampaign | null>;

  get brandName$(): Observable<string> {
    return this.brandCampaign$.pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => {
        const title = brandCampaign?.cTitle;
        return title ? `${title[0].toUpperCase()}${title.slice(1)}` : '-';
      })
    );
  }

}
