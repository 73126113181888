import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { LangChangeEvent, TranslationService, currentLang } from '@hiptraveler/common';

@Pipe({
  name: 'headline1'
})
export class Headline1Pipe implements PipeTransform {

  value$$ = new BehaviorSubject<string>('');

  constructor(
    private translation: TranslationService
  ) { }

  transform(value: BrandCampaign): Observable<string> {

    this.value$$.next(value.campgnLangObj?.[currentLang()]?.hashtag || value.cHashtag || '');

    this.translation.onLangChange$.subscribe((event: LangChangeEvent) => {
      this.value$$.next(value.campgnLangObj?.[event?.lang || 'en']?.hashtag || value.cHashtag || '');
    });

    return this.value$$.asObservable();
  }

}
