import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToolbarOption } from '@hiptraveler/common';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss', './toolbar-responsiveness.scss']
})
export class ToolbarComponent {

  @Input() selections?: ToolbarOption[];
  @Input() component?: string;
  @Input() travelSelection?: string;
  @Input() connectSelection?: string;
  @Input() swipeIcon?: string;
  @Input() scrollToView?: string;
  @Input() disableHighlight?: string;

  constructor(
    public translateService: TranslateService
  ) { }

}
