import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { FooterComponent } from './footer.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  RouterModule,
  MatRippleModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule
];

const COMPONENTS = [
  FooterComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    FooterComponent
  ]
})
export class HipTravelerFooterModule { }
