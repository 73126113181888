import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { HotelOffer } from '@hiptraveler/data-access/api';
import { HotelOffersDialogComponent } from './hotel-offers-dialog.component';

export const HOTEL_OFFERS_ID = 'hotel-offers-dialog';

@Injectable()
export class HotelOffersDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(data: Partial<HotelOffer>[]): MatDialogRef<HotelOffersDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(HOTEL_OFFERS_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(HotelOffersDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: HOTEL_OFFERS_ID,
      panelClass: HOTEL_OFFERS_ID + '-overlay-panel',
      width: '400px',
      minWidth: 0,
      maxWidth: 'auto',
      height: '400px',
      minHeight: 0,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data
    })
  }

}
