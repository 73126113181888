import { StateContext } from '@ngxs/store';
import { pick } from 'lodash';

import * as API from '@hiptraveler/data-access/api';
import { ItineraryStateModel } from '..';

interface Param {
  ctx: StateContext<ItineraryStateModel>;
  basicInfoResponse?: Partial<API.BasicInfoData>;
  param: API.ItineraryParam;
  data: Partial<API.BasicInfoData>
}

export function updateItineraryState(value: Param): void {
  const updater = value.param === 'blog' ? updateBlog : updateItinerary;
  value.ctx.patchState({ basicInfo: updater(value) });
}

function updateBlog({ ctx, data, basicInfoResponse }: Param): Partial<API.BasicInfoData> {

  const state = ctx.getState()?.basicInfo || {};

  basicInfoResponse!.author = basicInfoResponse!.author || state.author;
  basicInfoResponse!.pubDateString = basicInfoResponse!.pubDateString || state.pubDateString;

  const response = pick(basicInfoResponse, [ 'content', 'snippet', 'title', 'pageTitle', 'author', 'pubDateString', 'id' ]);

  return { ...state, ...response, coverImage: data?.coverImage, pageUrl: parsePageUrl(basicInfoResponse) };
}

function updateBlog2({ ctx, basicInfoResponse }: Param): Partial<API.BasicInfoData> {
  const state = ctx.getState()?.basicInfo || {};
  return { ...state, ...basicInfoResponse, pageUrl: parsePageUrl(basicInfoResponse) };
}

function updateItinerary({ ctx, basicInfoResponse }: Param): Partial<API.BasicInfoData> {
  const state = (ctx.getState()?.basicInfo || {});
  return { ...state, ...basicInfoResponse };
}

// Helpers
function parsePageUrl(basicInfoResponse?: Partial<API.BasicInfoData>): string {
  return basicInfoResponse?.pageUrl?.replace('http://localhost:8080', 'http://localhost:4200') || '';
}
