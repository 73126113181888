import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GalleryImagesService } from './gallery-images.service';
import { FilestackService } from '@hiptraveler/core/filestack';

@Component({
  selector: 'gallery-images',
  templateUrl: './gallery-images.component.html',
  styleUrls: ['./gallery-images.component.scss'],
  viewProviders: [ FilestackService, GalleryImagesService ]
})
export class GalleryImagesComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup,
    public service: GalleryImagesService
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.form.patchValue(this.form.value));
  }

}
