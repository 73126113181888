import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, filter, map, takeUntil, tap } from 'rxjs';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { ItineraryDataAccessService, preventRemoveKey, ViewService } from '@hiptraveler/features/itinerary';
import { AppListenerService, clientVID, currentLang, getWindowRef, NavbarControlStateService, ScrollListenerService, setClientVId } from '@hiptraveler/common';
import { CommonService } from './common.service';

@Injectable()
export class ItineraryService extends ViewService implements OnDestroy {

  @Select(UserState.authenticated) authenticated$: Observable<boolean>;
  @Select(ItineraryState.actDate) actDate$: Observable<unknown>

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private store: Store,
    private appListener: AppListenerService,
    public dataAccess: ItineraryDataAccessService,
    private common: CommonService,
    navbarControl: NavbarControlStateService,
    scrollListener: ScrollListenerService
  ) {
    super(navbarControl, scrollListener);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.common.destroyComponentState();
  }

  get checklist$(): Observable<boolean> {
    return this.store.select(ItineraryState.actDate).pipe(
      map(actDate => !!actDate?.map(e => !!e?.HotelArray || !!e?.ImgArray).some(e => e)),
      takeUntil(this.dataAccess.subscription$)
    );
  }

  /**
   * Returns an observable state
   * • 'true' shows the story content UI section
   * • 'false' hides the story content UI section
   */
  get hasStoryContent$(): Observable<boolean> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map((basicInfo: Partial<BasicInfoData> | null) => {
        const hasDateString = !!basicInfo?.pubDateString;
        const hasNoContent = !!basicInfo?.content;
        const hasNoSummary = !!basicInfo?.autoSummary;
        const hasNoTravelInfo = !!basicInfo?.travelInfo?.length;
        return hasDateString || hasNoContent || hasNoSummary || hasNoTravelInfo;
      }),
      takeUntil(this.dataAccess.subscription$)
    );
  }

  get basicInfo$(): Observable<BasicInfoData> {
    return this.store.select(ItineraryState.basicInfo).pipe(
      map((basicInfo) => {
        if (basicInfo?.content) {
          basicInfo.content = basicInfo?.content?.replace(/<a href="/g, '<a target="_blank" href="');
        }
        return basicInfo as any;
      })
    )
  }

  observe(): void {
    this.observerUrlPath();
    this.authenticationObserver();
    this.redirectObserver();
    this.observerScrollPosition();
    this.getPageData().subscribe();
  }

  private authenticationObserver(): void {
    combineLatest([
      this.store.select(UserState.state),
      this.store.select(ItineraryState.basicInfo),
    ]).subscribe(([ userState, basicInfo ]) => {
      if (
        userState?.id
        && basicInfo?.author?.authorProfId === clientVID()
        && basicInfo?.author?.authorReference === 'htVisitor'
        && basicInfo?.author?.authorProfId !== userState?.id
        && this.location.path().includes(`/${currentLang()}/itinerary/`) 
      ) {
        this.store.dispatch(new ItineraryAction.UpdateBasicInfoAuthorData({
          authorProfPic: userState.picUrl,
          authorProfId: userState.id,
          authorName: userState.fullName || `${userState.firstName} ${userState.lastName}`,
          authorTitle: userState.uTitle,
          authorDesc: null,
          authorReference: userState.userType
        }));
      }
    });
  }

  private redirectObserver(): void {
    this.basicInfo$.pipe(
      filter(Boolean),
      filter(e => 
        !!e?.author?.authorProfId
        && e?.author?.authorReference === 'htVisitor' 
        && !this.appListener.previousUrl$$.value
        && !this.store.selectSnapshot(AuthState.authenticated)
      )
    ).subscribe((basicInfo) => {
      setClientVId(basicInfo.author.authorProfId);
    });
  }

  /**
   * Checks the URL for the 'id' parameter and remove it,
   * then replace "Itinerary" with "itinerary" in the path.
   */
  private observerUrlPath(): void {
    const id = this.route.snapshot.paramMap.get('id');
    const url = this.location.path()
      .replace(`/${id}`, '')
      .replace(`/${currentLang()}/Itinerary/`, `/${currentLang()}/itinerary/`);
    this.location.replaceState(url);
  }

  /**
   * Observer for route parameters that listens for changes in URL parameters 
   * whenever a new trip is chosen from the experience finder.
   */
  private getPageData(): Observable<Params> {
    return this.route.params.pipe(
      filter(() => {
        return !this.appListener.previousUrl$$.value.includes(`/${currentLang()}/compose`)
      }),
      tap(async (param) => {
        getWindowRef()[preventRemoveKey] = undefined;
        await this.common.resetState();
        this.dataAccess.getItineraryData(this.ngOnDestroy.bind(this));
        console.log('Param →', param['itinerary']);
      })
    );
  }

}
