import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { NavbarControlStateService } from '@hiptraveler/common';
import { NavbarBrandLogoService } from './navbar-brand-logo.service';

@Component({
  selector: 'navbar-brand-logo',
  templateUrl: './navbar-brand-logo.component.html',
  styleUrls: ['./navbar-brand-logo.component.scss'],
  viewProviders: [ NavbarBrandLogoService ]
})
export class NavbarBrandLogoComponent {

  @Output() changeLanguage = new EventEmitter<string>();

  @Input() hiptravelerBrand$: Observable<BrandCampaign>;
  @Input() brandCampaign$: Observable<unknown | null>;
  @Input() mobileView: boolean;

  logoPlaceholder: string = this.service.logoPlaceholder;

  constructor(
    public translateService: TranslateService,
    public navbarControl: NavbarControlStateService,
    public service: NavbarBrandLogoService
  ) { }

}
