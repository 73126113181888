import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';

import { BrandCampaign, CampaignLangSupport } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { Observable, filter, map } from 'rxjs';

@Pipe({
  name: 'languageSupport'
})
export class LanguageSupportPipe implements PipeTransform {

  constructor(
    private store: Store
  ) { }

  transform(_: unknown): Observable<Partial<CampaignLangSupport>[]> {
    return this.store.select(BrandState.brandCampaign).pipe(
      filter(Boolean),
      map((brandCampaign: Partial<BrandCampaign>) => brandCampaign.langSupport),
      filter(Boolean),
    )
  }

}
