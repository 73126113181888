import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, filter, take } from 'rxjs';

import { ImageResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { DialogType } from '../../common';

@Directive({
  selector: '[actionBubbleLayout]'
})
export class ActionBubbleLayoutDirective implements AfterViewInit {

  @Select(ItineraryState.imageInformation) imageInformation$: Observable<ImageResultData>;

  @Input() dialogType: DialogType;

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngAfterViewInit(): void {
    
    if (this.dialogType === 'hotel') return;

    this.imageInformation$.pipe(
      filter(Boolean),
      take(1)
    ).subscribe((data: ImageResultData) => {
      
      const noPhotos = (!data?.imgAuthInfo && !data?.photos?.length)
        && (!data?.yelpData?.yelpUrl && !data?.photos?.length);

      const noAuthor = !data?.imgAuthInfo && !data?.yelpData?.yelpUrl;

      if (noAuthor && noPhotos) {
        this.element.nativeElement.classList.add('no-content');
        return;
      }
      if (noAuthor) {
        this.element.nativeElement.classList.add('no-author');
      }
      if (noPhotos) {
        this.element.nativeElement.classList.add('no-photos');
      }
    });
  }

}
