import { AfterViewInit, Directive, ElementRef } from "@angular/core";

import { TranslationService } from "@hiptraveler/common";

@Directive()
export class CardRatingReviews implements AfterViewInit {

  constructor(
    private translate: TranslationService,
    private element: ElementRef<HTMLDivElement>
  ) { }

  ngAfterViewInit(): void {

    const text1 = this.translate.getText('cards', 'reviews');
    if (this.element.nativeElement.firstElementChild?.textContent !== `0 ${text1}`) return;

    this.element.nativeElement.style.opacity = '0' ;
    
    const ratingImg: HTMLImageElement | undefined = this.element.nativeElement.previousElementSibling?.previousElementSibling as any;

    if (ratingImg && (ratingImg?.tagName === 'IMG')) {
      ratingImg.style.opacity = '0';
    }
  }

}
