import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ActivitiesModule } from './activities/activities.module';

import { ActivitiesContentComponent } from './activities-content.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  ActivitiesModule
];

const COMPONENTS = [
  ActivitiesContentComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActivitiesContentComponent
  ]
})
export class ActivitiesContentModule { }
