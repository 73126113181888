import { PromiseReject, PromiseResolve } from "@hiptraveler/common";

export function cryptoJsClient(): Promise<any> {
  return new Promise((resolve: PromiseResolve, reject: PromiseReject) => {

    if (typeof window !== 'undefined' && (window as any).CryptoJS) {
      console.log('CryptoJS is already loaded.');
      resolve((window as any).CryptoJS);
      return;
    }

    if (typeof window === 'undefined') {
      reject(new Error('cryptoJsClient can only be used in a browser environment.'));
      return;
    }

    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.2.0/crypto-js.min.js';
    scriptElement.async = true;

    document.body.appendChild(scriptElement);

    scriptElement.onload = () => {
      if ((window as any).CryptoJS) {
        console.log('CryptoJS script loaded successfully.');
        resolve((window as any).CryptoJS);
      } else {
        console.error('CryptoJS loaded, but the library is not available.');
        reject(new Error('CryptoJS loaded, but the library is not available.'));
      }
    };

    scriptElement.onerror = () => {
      console.error('Failed to load the CryptoJS script.');
    };
  });
}
