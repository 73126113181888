import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';

import { StoryTravelAgentsService } from './story-travel-agents.service';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { profilePlaceholder } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'story-travel-agents',
  templateUrl: './story-travel-agents.component.html',
  styleUrls: ['./story-travel-agents.component.scss'],
  host: { class: 'story-travel-agents' },
  viewProviders: [ StoryTravelAgentsService, SendMessageDialogActionService, AuthDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade() ]
})
export class StoryTravelAgentsComponent implements OnInit, AfterViewInit {

  profilePlaceholder: string = profilePlaceholder;

  constructor(
    private element: ElementRef<HTMLElement>,
    public service: StoryTravelAgentsService
  ) { }

  ngOnInit(): void {
    this.service.initializeState();
  }

  ngAfterViewInit(): void {

    (this.element.nativeElement.parentElement!.firstElementChild! as HTMLElement).style.display = 'none';
    
    this.service.hasTravelAgents$.subscribe(() => {
      (this.element.nativeElement.parentElement!.firstElementChild! as HTMLElement).style.display = 'block';
    });
  }

}
