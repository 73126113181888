import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';

@Injectable()
export class ReviewsService {

  @Select(ItineraryState.hotelInformation) hotelInformation$: Observable<HotelResultData | null>;

}
