import { takeUntil } from 'rxjs';

import { getWindowRef, NavbarControlStateService, ScrollListenerService } from '@hiptraveler/common';
import { ViewCommon } from './view-common';

export class ViewService extends ViewCommon {

  constructor(
    private navbarControl: NavbarControlStateService,
    private scrollListener: ScrollListenerService
  ) {
    super();
  }

  /**
   * Scroll position observer:
   * Listens to the scroll position of the client.
   * Updates the navbar UI state depending on the scroll position condition.
   */
  public observerScrollPosition(): void {
    this.scrollListener.scrollPosition$.pipe(
      takeUntil(this.subscription$)
    ).subscribe((position: number) => {
      const BANNER_HEIGHT = getWindowRef()?.innerWidth > 1000 ? 450 
        : getWindowRef()?.innerWidth > 700 ? 350 : 250;
      const TOOLBAR_HEIGHT = 40;
      const height = BANNER_HEIGHT + TOOLBAR_HEIGHT;
      const visible = this.navbarControl.navbarSearchBarVisibility$$.value;
      const navElevation = getWindowRef()?.innerWidth >= 700;

      if (position > height) {

        if (!visible) return;

        this.navbarControl.navbarSearchBarVisibility$$.next(false);
        this.navbarControl.navbarToolbarPosition$$.next('navbar');

        if (navElevation) {
          this.navbarControl.navbarElevationState$$.next(true);
        }
      } else {
        
        if (visible) return;
        
        this.navbarControl.navbarSearchBarVisibility$$.next(true);
        this.navbarControl.navbarToolbarPosition$$.next('none');

        if (navElevation) {
          this.navbarControl.navbarElevationState$$.next(true);
        }
      }
    });
  }

}
