import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { Actions, Store, ofActionCompleted, ofActionErrored } from '@ngxs/store';
import { Observable, delay, filter, firstValueFrom, switchMap, take, tap } from 'rxjs';

import { ErrorResponse } from '@hiptraveler/data-access/api';
import { AuthAction, AuthState } from '@hiptraveler/data-access/auth';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { SnackbarService } from '@hiptraveler/snackbar';
import { AppListenerService, AuthHelperService, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';

@Injectable()
export class AuthObserverService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private appListener: AppListenerService,
    private authHelper: AuthHelperService,
    private snackbar: SnackbarService
  ) { }

  observe(): void {
    this.authActionObserver();
    this.refreshSessionWithCookies();
  }

  private async refreshSessionWithCookies(): Promise<void> {

    if (isPlatformServer(this.platformId)) return;
    // if (this.validateState()) return;

    try {
      this.validateAuthUserState();

      await firstValueFrom(this.store.select(BrandState.brandCampaign).pipe(
        filter(Boolean),
        switchMap(({ cId }) => this.store.dispatch(new AuthAction.RefreshSessionWithCookies(cId))),
      ));
      
      if (!this.store.selectSnapshot(UserState.authenticated)) {
        await firstValueFrom(this.store.dispatch(new AuthAction.GetUserDetails));
      }

      this.snackbar.dismiss();
    } catch (response: any) {
      const value = response as ErrorResponse;
      this.authHelper.handleExpiredToken(value);
    }
  }

  private validateAuthUserState(): void {
    const authenticated = this.store.selectSnapshot(AuthState.authenticated);
    const user = !!this.store.selectSnapshot(UserState.id);
    (user && !authenticated) && this.store.dispatch(new UserAction.ResetUserState);
  }

  private authActionObserver(): void {

    if (isPlatformServer(this.platformId)) return;

    this.actions$.pipe(
      ofActionErrored(AuthAction.RefreshSessionWithCookies),
      filter(() => this.router.url.startsWith(`/${currentLang()}/compose/itinerary/`))
    ).subscribe(() => this.router.navigate([ currentLang() ]));

    this.handleAuthAction(this.actions$.pipe(
      ofActionCompleted(
        AuthAction.Login,
        AuthAction.Register,
        AuthAction.AuthenticateWithFacebook,
        AuthAction.AuthenticateWithGoogle
      ),
      filter(completion => completion.result.successful),
    ));
  }

  private handleAuthAction<T>($: Observable<T>) {
    $.pipe(
      delay(150),
      switchMap(() => this.store.select(AuthState.authenticated).pipe(take(1))),
      filter(Boolean),
      tap(() => this.getUserDetails())
    ).subscribe()
  }

  private async getUserDetails(): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new AuthAction.GetUserDetails));
      
      const pendingProcess = sessionStorage.getItem(pendingAuthProcessKey);
      if (pendingProcess) {
        this.appListener.emitGlobalSignal(pendingProcess);
      }

      this.snackbar.dismiss();
    } catch (error) {
      // error handling      
    }
  }

  private validateState(): boolean {
    return JSON.parse(localStorage.getItem('hiptraveler:state_auth') || '{}')?.accessToken;
  }

}
