import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngxs/store';

import { chromaClient } from '@hiptraveler/core/chroma';
import { CampaignCustom } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { chromaNamespaceKey, getWindowRef } from '@hiptraveler/common';

@Injectable()
export class RootBrandStylesObserverService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: any,
    private store: Store
  ) { }

  observe(): void {

    if (isPlatformServer(this.platformId)) return;

    this.store.select(BrandState.campaignCustom).subscribe(async (campaignCustom: Partial<CampaignCustom> | null) => {
      await chromaClient();
      const chroma = getWindowRef()?.[chromaNamespaceKey];
      const secondaryColorHex = campaignCustom?.colorPalette?.secondaryColor || '#f4f5f7';
      const primaryColorHex = campaignCustom?.colorPalette?.mainColor || '#00a6ff';
      const primaryColorRgb = chroma(primaryColorHex).rgb() || [ 0, 166, 255 ];
      const [ r, g, b ] = primaryColorRgb;
      const primaryColorHexHover = chroma.rgb(r, g, b).brighten(0.15).hex() || '#1aafff';

      this.document.documentElement.style.setProperty('--ht-primary-color', primaryColorHex);
      this.document.documentElement.style.setProperty('--ht-primary-color-rgb', primaryColorRgb.join(','));
      this.document.documentElement.style.setProperty('--ht-primary-color-hover', primaryColorHexHover);
      // this.document.documentElement.style.setProperty('--ht-secondary-color', secondaryColorHex);
      this.document.documentElement.style.setProperty('--ht-secondary-color', '#f4f5f7');
    });
  }

}
