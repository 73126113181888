import { Directive, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom, map } from 'rxjs';

import { SocialApiService, YoutubeSearch } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { LOCATION } from '@hiptraveler/common';

@Directive({
  selector: '[youtubeIframe]'
})
export class YoutubeIframeDirective implements OnInit, OnDestroy {

  parentElement: HTMLDivElement;
  destroy$ = new Subject<void>();

  constructor(
    @Inject(LOCATION) private location: any,
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private store: Store,
    private api: SocialApiService
  ) { }

  get origin(): string {
    return encodeURIComponent(this.location.origin);
  }

  get youtubeData(): Promise<YoutubeSearch> {
    return firstValueFrom(this.api.getYoutubeSearch(
      this.store.selectSnapshot(BrandState.campaignCustom)?.socialLinks?.youtube || 'HipTraveler'
    ).pipe( map(e => e.youtube) ));
  }

  async ngOnInit(): Promise<void> {

    this.parentElement = this.renderer.parentNode(this.element.nativeElement);

    const data = await this.youtubeData;

    data.videoIds.forEach((id: string) => {
      this.parentElement.firstElementChild?.appendChild(this.getYIframeById(id));
    });

    this.destroy$.asObservable().subscribe(() => {
      const parentElement = this.element.nativeElement;
      while (parentElement.firstChild) {
        parentElement.removeChild(parentElement.firstChild);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getYIframeById(id: string): HTMLIFrameElement {

    const iframeElement = this.renderer.createElement('iframe') as HTMLIFrameElement;

    const brand = this.store.selectSnapshot(BrandState.campaignCustom)?.socialLinks?.youtube;
    const source = `https://www.youtube.com/embed/${id}?enablejsapi=1&amp;origin=${this.origin}&amp;widgetid=1;brand=${brand}`;

    iframeElement.src = source;
    iframeElement.width = '100%';
    iframeElement.height = '100%';
    iframeElement.classList.add('youtube-iframe');

    this.renderer.setAttribute(iframeElement, 'frameborder', '0');
    this.renderer.setAttribute(iframeElement, 'style', 'border-radius: 4px;');

    return iframeElement;
  }

}
