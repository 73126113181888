
export type Type = 'travelAgent' | 'ambassador';

type TypeValue = 'travelAgentData' | 'ambassadorData';

export interface TypeData {
  display: string;
  value?: string;
}

export const professionTypeValues: Record<TypeValue, TypeData[]> = {
  travelAgentData: [
    { display: 'reg-form.travel-advisor', value: 'htAgent' },
    { display: 'reg-form.tour-operator', value: 'htOperator' },
    { display: 'reg-form.others', value: 'other' }
  ],
  ambassadorData: [
    { display: 'reg-form.travel-blogger', value: 'travelblogger' },
    { display: 'reg-form.videographer', value: 'videographer' },
    { display: 'reg-form.others', value: 'other' }
  ]
};

export const headerTypeValues: Record<TypeValue, TypeData[]> = {
  travelAgentData: [
    { display: 'reg-form.general-info', value: 'general-information' },
    { display: 'reg-form.specialties', value: 'your-specialties' },
    { display: 'reg-form.register', value: 'register' }
  ],
  ambassadorData: [
    { display: 'reg-form.general-info', value: 'general-information' },
    { display: 'reg-form.niche-specialties', value: 'niche-or-specialties' },
    { display: 'reg-form.register', value: 'register' }
  ]
};
