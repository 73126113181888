<div class="auth-menu" *ngIf="(service.brandCampaign$ | async) as brandCampaign">

  <div class="auth-menu--navbar">

    <button mat-icon-button (click)="stateService.dialogView$$.next('main-menu')">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="auth-menu--navbar-image">

      <img [src]="brandCampaign?.cLogo?.headerImg" [alt]="brandCampaign?.cTitle">
      <powered-by-hiptraveler></powered-by-hiptraveler>

    </div>
  </div>

  <registration-menu-form [submitClicked]="submitClicked"></registration-menu-form>

  <action-button type="submit" (click)="submitClicked.emit()">{{ 'auth.sign-up' | translate }}</action-button>

</div>
