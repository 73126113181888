import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CustomMapAutocompleteComponent } from './custom-map-autocomplete.component';
import { CustomMapAutocompleteDirective } from './custom-map-autocomplete.directive';
import { CmaDisplayDirective } from './cma-display.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  NgxSkeletonLoaderModule
];

const COMPONENTS = [
  CustomMapAutocompleteComponent
];

const DIRECTIVES = [
  CustomMapAutocompleteDirective,
  CmaDisplayDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    CustomMapAutocompleteComponent,
    CustomMapAutocompleteDirective
  ]
})
export class CustomMapAutocompleteModule { }
