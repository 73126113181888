import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dynamicHeight]'
})
export class DynamicHeightDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef<HTMLElement>
  ) { }
  
  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const newHeight = entry.contentRect.height;

        if (newHeight === 48) {
          this.element.style.top = '-61px';
        } else if (newHeight === 88) {
          this.element.style.top = '-101px';
        } else if (newHeight === 128) {
          this.element.style.top = '-141px';
        } else if (newHeight === 168) {
          this.element.style.top = '-181px';
        } else {
          this.element.style.top = '-221px';
        }
      }
    });

    this.element && resizeObserver.observe(this.element);
  }

}
