import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { MobileNavbarViewModule } from '@hiptraveler/ui/mobile-navbar-view';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { AuthRegisterDialogComponent } from './auth-register-dialog.component';
import { LocationFieldInputDirective } from './location-field-input.directive';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  MobileNavbarViewModule,
  ButtonsModule
];

const COMPONENTS = [
  AuthRegisterDialogComponent
];

const DIRECTIVES = [
  LocationFieldInputDirective
];


@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ]
})
export class AuthRegisterDialogModule { }
