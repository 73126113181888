<ng-container *ngIf="(service.basicInfo$ | async)?.hasTravelAgents">

  <h3 translate="view-page.advisor"></h3>
  
  <p translate="view-page.advisors"></p>
  
  <br>

  <div class="story-travel-agents--card-highlight" *ngIf="service.highlight$ | async">
    <ng-container *ngIf="(service.travelAgents$ | async)?.[0] as travelAgent">
      
      <highlight-card [travelAgent]="travelAgent" [@opacityFade]></highlight-card>

    </ng-container>
  </div>

  <div class="story-travel-agents--card-list" *ngIf="!(service.highlight$ | async)">
    <ng-container *ngFor="let travelAgent of (service.travelAgents$ | async)?.slice(0, 3);">
    
      <ht-ta-card [travelAgent]="travelAgent" [tags]="travelAgent.tags" [@opacityFade]>
        <img taBackgroundImage [lazyLoad]="travelAgent.coverImage || ''" [defaultImage]="(service.coverImageFallback$ | async)!" [errorImage]="(service.coverImageFallback$ | async)!" alt="Travel Agent Brand Affiliation Image" draggable="false">
        <img taProfileImage [lazyLoad]="travelAgent.profImg || ''" [defaultImage]="profilePlaceholder" [errorImage]="profilePlaceholder" alt="Travel Agent Profile Image" draggable="false">
        <ta-flag>{{ 'view-page.specialist' | translate }}</ta-flag>
        <ta-name (click)="service.navigateToProfile(travelAgent)">{{ travelAgent.name }}</ta-name>
        <ta-location>{{ travelAgent.location }}</ta-location>
        <ta-action svgIcon="common.follow-svg" [processing$]="travelAgent | followProcessing" [actionActive]="travelAgent.profId" (click)="service.followByResultData(travelAgent)">{{ 'view-page.follow' | translate }}</ta-action>
        <ta-action icon="mail" (click)="service.sendTravelAgentMessage(travelAgent.profId)">{{ 'view-page.message' | translate }}</ta-action>
      </ht-ta-card>
  
    </ng-container>
  </div>

  <br>

</ng-container>
