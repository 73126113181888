import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { HourDetailsComponent } from './hour-details.component';
import { CalendarDayPipe } from './calendar-day.pipe';
import { DayTimePipe } from './day-time.pipe';

const MODULES = [
  CommonModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const COMPONENTS = [
  HourDetailsComponent
];

const PIPES = [
  CalendarDayPipe,
  DayTimePipe
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    HourDetailsComponent
  ]
})
export class HourDetailsModule { }
