import { Component, AfterViewInit, ViewChild, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Subject, merge, takeUntil } from 'rxjs';

import { FiltersStateService } from '../common';
import { ListOverlayService } from './list-overlay.service';
import { ListItem } from '../list-items';

@Component({
  selector: 'list-overlay',
  template: `
    <list-button
      cdkOverlayOrigin
      [travelStyle]="travelStyle"
      #trigger="cdkOverlayOrigin"
      (click)="toggleItineraryAction()">
    </list-button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="visibility">
      <list-panel
        [data]="data"
        [header]="header"
        [travelStyle]="travelStyle">
      </list-panel>
    </ng-template>
  `,
  styleUrls: ['./list-overlay.component.scss'],
  host: { class: 'search-page-root-filters-list-overlay' },
  viewProviders: [ ListOverlayService ],
  encapsulation: ViewEncapsulation.None
})
export class ListOverlayComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;

  @Input() travelStyle: string = '-';
  @Input() header: string | null;
  @Input() data: ListItem[];

  subscription$ = new Subject<void>();
  visibility: boolean;

  constructor(
    public service: ListOverlayService,
    public filtersService: FiltersStateService
  ) { }

  ngOnInit(): void {
    merge(
      this.filtersService.panelFilterApplyClicked$$,
      this.filtersService.panelFilterClearClicked$$
    ).pipe(
      takeUntil(this.subscription$)
    ).subscribe(() => (this.visibility = false));
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    this.service.initializeOverlay(this.overlay, this.travelStyle, (visibility: boolean) => {
      this.visibility = visibility;
    });
  }

  toggleItineraryAction(): void {
    this.visibility = !this.visibility
  }

}
