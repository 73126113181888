import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs';

import { ComponentStateService, preventUpdateFormStateByPlaceResult } from '@hiptraveler/features/itinerary';
import { ContentEditableService, EditableSelectionsComponent } from '../common';
import { ComposeItineraryFormService } from './compose-itinerary-form.service';
import { SearchLocationData, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'compose-itinerary-form',
  templateUrl: './compose-itinerary-form.component.html',
  styleUrls: ['./compose-itinerary-form.component.scss'],
  host: { class: 'page-compose-compose-itinerary-form' },
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ ComposeItineraryFormService ],
  animations: [ opacityFade(150) ]
})
export class ComposeItineraryFormComponent implements OnInit, AfterViewInit {

  @ViewChild(EditableSelectionsComponent) editableSelectionsComponent: EditableSelectionsComponent;
  @ViewChild('editable') editable: ElementRef<HTMLDivElement>;

  constructor(
    private cdRef: ChangeDetectorRef,
    public service: ComposeItineraryFormService,
    public componentState: ComponentStateService,
    public contentEditable: ContentEditableService
  ) { }

  ngOnInit(): void {
    getWindowRef()[requestEndpointParamValue] = 'itinerary';
    this.service.componentObserver();
  }

  ngAfterViewInit(): void {
    this.componentState.value$.pipe(takeUntil(this.service.subscription$)).subscribe(value => {
      value?.hasStoryContent && this.contentEditable.mediumEditorObserver(
        this.editable,
        this.editableSelectionsComponent
      );
    });
  }

  async locationChanges(locationData: SearchLocationData): Promise<void> {
    if (getWindowRef()[preventUpdateFormStateByPlaceResult]) return;

    await this.componentState.requestAndPatchLocationToState(locationData, this.service.form.value.storyTitle);
    this.service.form.patchValue({ storyLocation: '' });
    this.cdRef.detectChanges();
  }

  removeLocationItem(index: number): void {
    if (this.componentState.subscription$$.value?.processing) return;
    this.editableSelectionsComponent?.service?.hideHostElement();
    this.componentState.removeItineraryLocation(index);
  }

}
