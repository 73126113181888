import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { TripAdvisorComponent } from './trip-advisor.component';

const MODULES = [
  CommonModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  TripAdvisorComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    TripAdvisorComponent
  ]
})
export class TripAdvisorModule { }
