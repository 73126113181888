import { Component, ViewEncapsulation } from '@angular/core';

import { RegistrationFormStateService } from '../common';

@Component({
  selector: 'promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
  host: { class: 'registration-form-section-promo-code' },
  encapsulation: ViewEncapsulation.None
})
export class PromoCodeComponent {

  constructor(
    public stateService: RegistrationFormStateService
  ) { }

}
