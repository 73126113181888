import { fromPairs, merge, omitBy, sortBy, toPairs } from 'lodash';

import { ActivityDate, ActivityDateData } from "@hiptraveler/data-access/api";

export function parseActDate(actDateMap?: any): {
  arr: ActivityDateData[], map: ActivityDate
} | null {
  actDateMap = actDateMap || {};
  const actDateArr = Object.values(actDateMap)
    .filter((e: any) => e && e.hasMultiDayTour)
    .flatMap((e: any) => e.multiDayTourList)
    .filter(item => item[Object.keys(item)[0]].length > 0)
    .reduce((acc, item) => {
      const key = Object.keys(item)[0];
      const values = item[key];
      acc[values[0]] = actDateMap[values[0]];
      return acc;
    }, {});

  const defaultActDateMap = omitBy(actDateMap, { "hasMultiDayTour": true });
  const sortedObject = fromPairs(sortBy(toPairs(merge(actDateArr, defaultActDateMap)), ([key]) => key));

  const resultValue = {
    arr: Object.keys(sortedObject).map(index => sortedObject![+index]).filter(e => !!e) as ActivityDateData[],
    map: sortedObject as ActivityDate
  };

  return actDateMap ? resultValue : null;
}
