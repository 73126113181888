export type ListItemType = 'amenities' | 'exp' | 'travelStyle' | 'activities';

export interface ListItem {
  type: ListItemType;
  name: string;
  value: string;
};

export const amenities: ListItem[] = [
  { type: 'amenities', name: 'WiFi', value: 'wifi' },
  { type: 'amenities', name: 'Spa', value: 'spa' },
  { type: 'amenities', name: '24-Hour Front Desk Service', value: '24_hour_front_desk_service' },
  { type: 'amenities', name: 'Parking', value: 'parking' },
  { type: 'amenities', name: 'Non-Smoking', value: 'non_smoking' },
  { type: 'amenities', name: 'Fitness Center', value: 'fitness_center' },
  { type: 'amenities', name: 'Pets Allowed', value: 'pets_allowed' },
  { type: 'amenities', name: 'Wheelchair Accessible', value: 'wheelchair_accessible' },
  { type: 'amenities', name: 'Pool', value: 'pool' },
  { type: 'amenities', name: 'Air Conditioning', value: 'air_conditioning' },
  { type: 'amenities', name: 'Elevator', value: 'elevator' },
  { type: 'amenities', name: 'Bar', value: 'bar' },
  { type: 'amenities', name: 'Laundry', value: 'laundry' },
  { type: 'amenities', name: 'Airport Shuttle', value: 'airport_shuttle' },
  { type: 'amenities', name: 'Babysitting', value: 'baby-sitting' },
  { type: 'amenities', name: 'Breakfast Included', value: 'breakfast_included' },
  { type: 'amenities', name: 'Business Center', value: 'business_center' },
  { type: 'amenities', name: 'Casino', value: 'casino' },
  { type: 'amenities', name: 'Playground', value: 'playground' },
  { type: 'amenities', name: 'Cafe', value: 'cafe' },
  { type: 'amenities', name: 'Golf Course', value: 'golf_course' },
  { type: 'amenities', name: 'Poolside Bar', value: 'poolside_bar' },
  { type: 'amenities', name: 'Restaurant', value: 'restaurant' },
  { type: 'amenities', name: 'Meeting Facilities', value: 'meeting_facilities' },
  { type: 'amenities', name: 'Shops', value: 'shops' },
  { type: 'amenities', name: 'Tennis', value: 'tennis' },
  { type: 'amenities', name: 'Wedding Facilities', value: 'wedding_facilities' },
  { type: 'amenities', name: 'Watersports', value: 'watersports' },
  { type: 'amenities', name: 'Private Beach', value: 'private_beach' },
  { type: 'amenities', name: 'Cable TV', value: 'cable_tv' },
  { type: 'amenities', name: 'Cribs Available', value: 'cribs_available' },
  { type: 'amenities', name: 'Coffee Maker', value: 'coffee_maker' },
  { type: 'amenities', name: 'Game Room', value: 'game_room' },
  { type: 'amenities', name: 'Chapel', value: 'chapel' },
  { type: 'amenities', name: 'Safe Deposit Box', value: 'safe_deposit_box' },
  { type: 'amenities', name: 'Rooms with a View', value: 'rooms_with_a_view' },
  { type: 'amenities', name: 'Jacuzzi', value: 'jacuzzi' }
];

export const cuisine: ListItem[] = [
  { type: 'amenities', name: 'American', value: 'american' },
  { type: 'amenities', name: 'Argentinean', value: 'argentinean' },
  { type: 'amenities', name: 'Asian', value: 'asian' },
  { type: 'amenities', name: 'Bahamian', value: 'bahamian' },
  { type: 'amenities', name: 'Bakery', value: 'bakery' },
  { type: 'amenities', name: 'BBQ', value: 'bbq' },
  { type: 'amenities', name: 'Caribbean', value: 'caribbean' },
  { type: 'amenities', name: 'Buffet', value: 'buffet' },
  { type: 'amenities', name: 'Deli', value: 'deli' },
  { type: 'amenities', name: 'Dessert', value: 'dessert' },
  { type: 'amenities', name: 'French', value: 'french' },
  { type: 'amenities', name: 'European', value: 'european' },
  { type: 'amenities', name: 'Greek', value: 'greek' },
  { type: 'amenities', name: 'Indian', value: 'indian' },
  { type: 'amenities', name: 'Mediterranean', value: 'mediterranean' },
  { type: 'amenities', name: 'Italian', value: 'italian' },
  { type: 'amenities', name: 'Mexican', value: 'mexican' },
  { type: 'amenities', name: 'Sushi', value: 'sushi' },
  { type: 'amenities', name: 'Steakhouse', value: 'steakhouse' },
  { type: 'amenities', name: 'Afghani', value: 'afghani' },
  { type: 'amenities', name: 'African', value: 'african' },
  { type: 'amenities', name: 'Arabic', value: 'arabic' },
  { type: 'amenities', name: 'Apulian', value: 'apulian' },
  { type: 'amenities', name: 'Armenian', value: 'armenian' },
  { type: 'amenities', name: 'Austrian', value: 'austrian' },
  { type: 'amenities', name: 'Central Asian', value: 'central_asian' },
  { type: 'amenities', name: 'Central American', value: 'central_american' },
  { type: 'amenities', name: 'Campania', value: 'campania' },
  { type: 'amenities', name: 'Cantonese', value: 'cantonese' },
  { type: 'amenities', name: 'Cafe', value: 'cafe' },
  { type: 'amenities', name: 'British', value: 'british' },
  { type: 'amenities', name: 'Brazilian', value: 'brazilian' },
  { type: 'amenities', name: 'Brew Pub', value: 'brew_pub' },
  { type: 'amenities', name: 'Belgian', value: 'belgian' },
  { type: 'amenities', name: 'Beer Restaurants', value: 'beer_restaurants' },
  { type: 'amenities', name: 'Bar', value: 'bar' },
  { type: 'amenities', name: 'Barbecue', value: 'barbecue' },
  { type: 'amenities', name: 'Fusion', value: 'fusion' },
  { type: 'amenities', name: 'Fruit Parlours', value: 'fruit_parlours' },
  { type: 'amenities', name: 'Fast Food', value: 'fast_food' },
  { type: 'amenities', name: 'Filipino', value: 'filipino' },
  { type: 'amenities', name: 'Ethiopian', value: 'ethiopian' },
  { type: 'amenities', name: 'Egyptian', value: 'egyptian' },
  { type: 'amenities', name: 'Diner', value: 'diner' },
  { type: 'amenities', name: 'Dining Bars', value: 'dining_bars' },
  { type: 'amenities', name: 'Czech', value: 'czech' },
  { type: 'amenities', name: 'Contemporary', value: 'contemporary' },
  { type: 'amenities', name: 'Central European', value: 'central_european' },
  { type: 'amenities', name: 'Central Italian', value: 'central_italian' },
  { type: 'amenities', name: 'Chinese', value: 'chinese' },
  { type: 'amenities', name: 'Chilean', value: 'chilean' },
  { type: 'amenities', name: 'Ukrainian', value: 'ukrainian' },
  { type: 'amenities', name: 'Yunnan', value: 'yunnan' },
  { type: 'amenities', name: 'Vietnamese', value: 'vietnamese' },
  { type: 'amenities', name: 'Wine Bar', value: 'wine_bar' },
  { type: 'amenities', name: 'Tuscan', value: 'tuscan' },
  { type: 'amenities', name: 'Turkish', value: 'turkish' },
  { type: 'amenities', name: 'Szechuan', value: 'szechuan' },
  { type: 'amenities', name: 'Thai', value: 'thai' },
  { type: 'amenities', name: 'Swiss', value: 'swiss' },
  { type: 'amenities', name: 'Swedish', value: 'swedish' },
  { type: 'amenities', name: 'Spanish', value: 'spanish' },
  { type: 'amenities', name: 'Street Food', value: 'street_food' },
  { type: 'amenities', name: 'South American', value: 'south_american' },
  { type: 'amenities', name: 'Sicilian', value: 'sicilian' },
  { type: 'amenities', name: 'Portuguese', value: 'portuguese' },
  { type: 'amenities', name: 'Seafood', value: 'seafood' },
  { type: 'amenities', name: 'Soups', value: 'soups' },
  { type: 'amenities', name: 'Pub', value: 'pub' },
  { type: 'amenities', name: 'Pizza', value: 'pizza' },
  { type: 'amenities', name: 'Persian', value: 'persian' },
  { type: 'amenities', name: 'Moroccan', value: 'moroccan' },
  { type: 'amenities', name: 'Malaysian', value: 'malaysian' },
  { type: 'amenities', name: 'Middle Eastern', value: 'middle_eastern' },
  { type: 'amenities', name: 'Pakistani', value: 'pakistani' },
  { type: 'amenities', name: 'Lebanese', value: 'lebanese' },
  { type: 'amenities', name: 'Korean', value: 'korean' },
  { type: 'amenities', name: 'Japanese', value: 'japanese' },
  { type: 'amenities', name: 'Japanese Fusion', value: 'japanese_fusion' },
  { type: 'amenities', name: 'Jamaican', value: 'jamaican' },
  { type: 'amenities', name: 'Irish', value: 'irish' },
  { type: 'amenities', name: 'Indonesian', value: 'indonesian' },
  { type: 'amenities', name: 'Hawaiian', value: 'hawaiian' },
  { type: 'amenities', name: 'Gastropub', value: 'gastropub' },
  { type: 'amenities', name: 'Grill', value: 'grill' },
  { type: 'amenities', name: 'Healthy', value: 'healthy' },
  { type: 'amenities', name: 'International', value: 'international' }
];
