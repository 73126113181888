<editable-selections [editable]="editable" (stateChanged)="contentEditable.editableSelectionStatusChange($event)" (yPositionChange)="contentEditable.yPosition = $event"></editable-selections>

<div class="host--label-container"></div>

<form class="host--content" [formGroup]="service.form">

  <div class="host--content-section">

    <div class="host--content-label">
      <div class="host--content-label-container">
        <p translate="compose.story"></p>
      </div>
    </div>

    <div class="host--content-section-data">
      
      <div class="host--input-row">
        <div class="host--readonly-input" (click)="service.languageSelection()">
          <mat-icon>public</mat-icon>
          <p translate="compose.lang"></p>
        </div>
        <mat-form-field appearance="outline" class="default">
          <mat-label>{{ 'compose.edit-title' | translate }}</mat-label>
          <input matInput [placeholder]="'compose.lang' | translate" type="text" autocomplete="off" name="story-title" formControlName="storyTitle">
        </mat-form-field>
      </div>
  
      <div class="host--input-row">
        <mat-form-field appearance="outline" emitOnly customMapAutocomplete position="absolute" [cmaLeftOffset]="-200" [cmaTopOffset]="4" (locationChanged)="locationChanges($event)">
          <mat-label>{{ 'compose.edit-location' | translate }}</mat-label>
          <input matInput [placeholder]="'compose.edit-location' | translate" type="text" autocomplete="off" name="story-location" formControlName="storyLocation" #placesAutocomplete>
        </mat-form-field>

        <ng-container *ngIf="itineraryVisibility || (service.hasItinerary$ | async);else addItineraryButton">
          <ui-action-button type="button" icon="remove" theme="action" [pending]="pendingButton" (click)="removeItinerary()">{{ 'compose.remove' | translate }}</ui-action-button>
        </ng-container>
        <ng-template #addItineraryButton>
          <ui-action-button type="button" icon="add" [pending]="pendingButton" [disabled]="(service.unsavedBlog$ | async)!" (click)="addItinerary()">{{ 'compose.new-itinerary' | translate }}</ui-action-button>
        </ng-template>

      </div>
  
      <div class="host--location-selections" [class.disabled]="componentState.stateProcessing$ | async">
        <div *ngFor="let city of componentState.itineraries$ | async;let i = index">
          <mat-icon (click)="removeLocationItem(i)">close</mat-icon>
          <p>{{ city }}</p>
        </div>
      </div>
    
      <story-image
        [componentState$$]="componentState.value$"
        [itineraries]="(componentState.itineraries$ | async)!">>
      </story-image>
    
      <p class="host--content-title" translate="compose.share"></p>
      <p class="host--content-videoUrl-placeholder" [ngStyle]="{ top: contentEditable.yPosition }" *ngIf="contentEditable.showVideoLinkPlaceholder">{{ 'compose.paste-video-link' | translate }}</p>
      <div class="host--content-editable hiptraveler-content-editable visible" #editable></div>

    </div>

  </div>

  <ng-container *ngIf="itineraryVisibility || (service.hasItinerary$ | async)">
    <div class="host--content-section">
  
      <div class="host--content-label">
        <div class="host--content-label-container">
          <p translate="compose.itinerary"></p>
          <activity-date-list></activity-date-list>
        </div>
      </div>
  
      <activity-day-list class="host--content-section-data" type="blog"></activity-day-list>
  
    </div>
  </ng-container>

</form>

<div class="host--map-wrapper">
  <div class="host--map" leafletMap id="compose-blog-map"></div>
</div>
