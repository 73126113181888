import { Directive, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, filter, firstValueFrom, take, takeUntil, tap } from 'rxjs';

import { BasicInfoData, CoverImage } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AppListenerService, NavbarControlStateService, SearchPageControlStateService, currentLang, pendingAuthProcessKey, updateOrderItinerariesKey } from '@hiptraveler/common';
import { ComponentStateService } from '@hiptraveler/features/itinerary';
import { AUTH_DIALOG_ID, AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SEARCH_RESULT_ID } from '@hiptraveler/dialogs/search-result-dialog';
import { PROMPT_DIALOG_ID } from '@hiptraveler/dialogs/prompt-dialog';
import { toLocationObject } from '../common';
import { MatDialog } from '@angular/material/dialog';

/**
 * A directive that handles actions triggered from navbar buttons.
 */
@Directive({
  selector: '[navbarAction]'
})
export class NavbarActionDirective implements OnInit {

  subscription$ = new Subject<void>();
  authHandle$ = new Subject<void>();
  authenticationObserverHandler: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService,
    private searchPageControl: SearchPageControlStateService,
    private componentState: ComponentStateService,
    private authDialog: AuthDialogActionService
  ) { }

  ngOnInit(): void {
    this.authenticationObserver();
    this.navbarButtonListener('exitFocusMode', this.exitFocusMode);
    this.navbarButtonListener('composePreview', this.composePreview);
    this.navbarButtonListener('composeSave', this.composeSave);
  }

  ngOnDestroy(): void {
    this.resetAuthHandle();
    this.componentState.reset();
    this.subscription$.next();
  }

  @HostListener('document:keydown.escape', ['$event']) 
  handleEscape() {
    if (
      this.dialog.getDialogById(AUTH_DIALOG_ID)?.id
      || this.dialog.getDialogById(SEARCH_RESULT_ID)?.id
      || this.dialog.getDialogById(PROMPT_DIALOG_ID)?.id
    ) return;
    this.exitFocusMode();
  }

  /**
   * Set up a listener for a navbar button signal and execute a callback when the signal is emitted.
   */
  private navbarButtonListener(signal: string, method: () => void): void {
    this.appListener.globalSignalListener(signal).pipe(
      tap(method.bind(this)),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Navigate the user to the previous URL or the root page if the previous URL is null upon every user sign-out.
   */
  authenticationObserver(): void {

    setTimeout(() => {
      this.authenticationObserverHandler = true;
    }, 1000);

    this.store.select(AuthState.authenticated).pipe(
      filter(e => !e),
      tap(() => {
        if (
          !this.store.selectSnapshot(UserState.authenticated)
            && !!this.store.selectSnapshot(ItineraryState.basicInfo)
            && !!this.searchPageControl.activityDate$$.value
        ) return;
        this.authenticationObserverHandler && this.composePreview();
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  /**
   * Exit focus mode and navigate back to the previous page.
   */
  async exitFocusMode(): Promise<void> {
    this.appListener.previousUrl$.pipe(
      filter(e => e !== this.router.url),
      tap((previousUrl: string) => this.router.navigateByUrl(previousUrl || `/${currentLang()}`)),
      take(1)
    ).subscribe();
  }

  /**
   * Generate a preview of the blog and navigate to the preview page.
   */
  async composePreview(): Promise<void> {
    if (!this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle) {
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 350);
      return;
    }
    this.router.navigate([
      currentLang(), 'itinerary', this.store.selectSnapshot(ItineraryState.basicInfo)?.pageTitle
    ]);
    setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 350);
  }

  /**
   * Save the blog content to the server.
   */
  async composeSave(): Promise<void> {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'itineraryComposePage';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => {
        const buttonClass = '.navbar--action-button';
        const button = document.body.querySelectorAll(buttonClass).item(1);
        button && button.dispatchEvent(new Event('click'));
      });
      setTimeout(() => this.navbarControl.navbarToolbarActionComplete$$.next(), 500);
      return;
    }

    try {
      const updateOrderItineraries = sessionStorage.getItem(updateOrderItinerariesKey);
      const promises = [ this.basicInfoUpdate() ];
      updateOrderItineraries && promises.push(this.updateOrderUpdate(updateOrderItineraries));
      this.searchPageControl.featureCardProcessing$$.next(true);
      await Promise.all(promises);
    } finally {
      this.searchPageControl.featureCardProcessing$$.next(false);
      this.navbarControl.navbarToolbarActionComplete$$.next();
    }
  }

  /**
   * Update the order of itineraries in the store and dispatch a save action.
   */
  private async updateOrderUpdate(updateOrderItineraries: string): Promise<void> {
    return firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
      action: 'update-order',
      param: 'itinerary',
      days: JSON.parse(updateOrderItineraries),
      lang: currentLang(),
      id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || ''
    })));
  }

  /**
   * Update basic information of the blog in the store and dispatch a save action.
   */
  private async basicInfoUpdate(): Promise<void> {

    const basicInfo = this.store.selectSnapshot(ItineraryState.basicInfo);
    if (!basicInfo) return;
    
    let newBasicInfo = { ...basicInfo } as any;
    const value = this.componentState.subscription$$.value;
    newBasicInfo.title = this.navbarControl.navbarTitleState$$.value || basicInfo.title;
    newBasicInfo.content = value?.storyContent || basicInfo.content;
    newBasicInfo.blogContent = value?.hasStoryContent || basicInfo.blogContent;
    newBasicInfo.locations = value?.locationList?.map(e => toLocationObject(e)).filter(Boolean);
    newBasicInfo.coverImage = this.parsedCoverImageByComponentState;

    delete newBasicInfo['locationList'];

    return firstValueFrom(this.store.dispatch(new ItineraryAction.UploadItineraryBlog<Partial<BasicInfoData>>(newBasicInfo, 'itinerary')));
  }

  /**
   * Returns the object value of the cover image payload field.
   */
  private get parsedCoverImageByComponentState(): Partial<CoverImage> {
    const value = { ...this.componentState.subscription$$.value };
    return value?.bannerAttribution ? {
      // Object condition: Image source from location list
      imgUrl: value?.bannerImage?.url,
      imgThumbnail: value?.bannerImage?.url,
      mobileUrl: value?.bannerImage?.url,
      originalUrl: value?.bannerImage?.url,
      attribution: value?.bannerAttribution,
      hasCoverImage: true
      
    } : value?.bannerImage?.handle ? {
      // Object condition: Upload photo from Filestack API
      imgUrl: `https://cdn.filestackcontent.com/resize=width:1024,fit:scale/auto_image/compress/${value.bannerImage.handle}`,
      imgThumbnail: `https://cdn.filestackcontent.com/resize=width:400,height:400,fit:crop/auto_image/compress/${value.bannerImage.handle}`,
      mobileUrl: `https://cdn.filestackcontent.com/resize=width:400,height:500,fit:scale/auto_image/compress/${value.bannerImage.handle}`,
      originalUrl: value?.bannerImage?.url,
      attribution: "",
      hasCoverImage: true
    } : value?.bannerImage?.url ? {
      // Object condition: Has existing image URL values
      imgUrl: value?.bannerImage?.url,
      imgThumbnail: value?.bannerImage?.url,
      mobileUrl: value?.bannerImage?.url,
      originalUrl: value?.bannerImage?.url,
      attribution: "",
      hasCoverImage: true
    } : {
      // Object condition: Default object
      imgUrl: "https://www.hiptraveler.com/assets/img/business/contactimg.jpg",
      imgThumbnail: "https://www.hiptraveler.com/assets/img/business/contactimg.jpg",
      mobileUrl: "https://www.hiptraveler.com/assets/img/business/contactimg.jpg",
      originalUrl: "https://www.hiptraveler.com/assets/img/business/contactimg.jpg",
      attribution: "",
      hasCoverImage: true
    }
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
