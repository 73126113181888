import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { ActiveSelectionModule } from '../common';
import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';

import { AgentDetailsComponent } from './agent-details.component';
import { SocialMediaFieldModule } from './social-media-field/social-media-field.module';
import { SocialMediaLinkModule } from './social-media-link/social-media-link.module';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatIconModule,
  MatRadioModule,
  MatFormFieldModule,
  MatInputModule,
  TranslateModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ButtonsModule,
  ActiveSelectionModule,
  SocialMediaFieldModule,
  SocialMediaLinkModule,
  CustomMapAutocompleteModule
];

const COMPONENTS = [
  AgentDetailsComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    AgentDetailsComponent
  ]
})
export class AgentDetailsModule { }
