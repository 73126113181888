import { Component } from '@angular/core';

@Component({
  selector: 'activity-day',
  template: `
    <div class="activity-day--path"></div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./activity-day.component.scss']
})
export class ActivityDayComponent {}
