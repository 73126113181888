import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog.component';

export const TERMS_AND_CONDITIONS_DIALOG_ID = 'auth-terms-and-policy';

@Injectable()
export class TermsAndConditionsDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<TermsAndConditionsDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(TERMS_AND_CONDITIONS_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(TermsAndConditionsDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: TERMS_AND_CONDITIONS_DIALOG_ID,
      panelClass: TERMS_AND_CONDITIONS_DIALOG_ID + '-overlay-panel',
      width: '750px',
      minWidth: 0,
      maxWidth: 'auto',
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }

}
