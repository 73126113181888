<div class="homepage-banner" [class.iframe-widget]="widgetService.brandIframeWidget$ | async">

  <div class="banner--overlay" *ngIf="!(service.hiptravelerBrand$ | async) && !loader"></div>

  <img [lazyLoad]="(service.brandCampaign$ | async)?.cCoverImg?.cover || ''" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" [alt]="(service.brandCampaign$ | async)?.cHashtag" [ngClass]="{ 'loaded': !loader }" (onStateChange)="lazyloadStateChangeFn($event)">

  <div class="banner--skeleton" [ngClass]="{ 'loading': loader }">
    <ngx-skeleton-loader count="1" appearance="line" animation="pulse"></ngx-skeleton-loader>
  </div>

  <ng-container *ngIf="service.brandCampaign$ | async as brandCampaign">

    <div class="banner--headline">
      <h1>{{ brandCampaign | headline1 | async }}</h1>
      <h2>{{ brandCampaign | headline2 | async }}</h2>
    </div>

    <div class="banner--information" *ngIf="service.hiptravelerBrand$ | async">
      <p>Daily Inspiration • Trip Planner • Social Network</p>
    </div>

  </ng-container>
</div>
