import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrandLogoPulseModule } from '@hiptraveler/ui/brand-logo-pulse';

import { WelcomeViewComponent } from './welcome-view.component';
import { TextTypedDirective } from './text-typed.directive';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  BrandLogoPulseModule
];

const COMPONENTS = [
  WelcomeViewComponent
];

const DIRECTIVES = [
  TextTypedDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    WelcomeViewComponent
  ]
})
export class WelcomeViewModule { }
