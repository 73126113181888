import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, firstValueFrom, map, takeUntil, tap } from 'rxjs';

import { BrandCampaign, TravelAgentData } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserAction, UserState } from '@hiptraveler/data-access/user';
import { BrandState } from '@hiptraveler/data-access/brand';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { SendMessageDialogActionService } from '@hiptraveler/dialogs/send-message-dialog';
import { SnackbarService } from '@hiptraveler/snackbar';
import { AppListenerService, clientVID, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';

@Injectable()
export class TravelAgentsService implements OnDestroy {

  #processing$$ = new BehaviorSubject<string[]>([]);
  processing$ = this.#processing$$.asObservable();
  authHandle$ = new Subject<void>();

  #following: string[] = [];

  constructor(
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService,
    private sendMessage: SendMessageDialogActionService,
    private snackbar: SnackbarService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
  }

  get coverImageFallback$(): Observable<string> {
    return this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => brandCampaign?.cCoverImg?.cover || 'assets/img/blank.webp')
    );
  }

  navigateToAgentProfile(travelAgent: TravelAgentData): void {
    if (travelAgent?.title) {
      this.router.navigateByUrl(`/${currentLang()}/profile/${travelAgent.title}/stories`);
    } else {
      this.snackbar.open({ message: 'Travel agent profile not found.', duration: 5000 });
    }
  }
  
  sendTravelAgentMessage(profileId: string): void {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'sendTravelAgentMessage';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.sendTravelAgentMessage(profileId));
      return;
    }

    this.sendMessage.open({ profileId, travelAgent: true });
  }

  async followByResultData({ taId, name }: TravelAgentData): Promise<void> {

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'sendTravelAgentMessage';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.followByResultData({ taId, name } as any));
      return;
    }

    if (this.#following.includes(taId)) return;

    const following = this.store.selectSnapshot(UserState.following) || [];

    if (following.includes(taId)) {
      this.snackbar.open({ message: `You are already following ${name}` });
      return;
    }

    try {
      this.#following.push(taId);
      this.#processing$$.next(this.#following);

      await firstValueFrom(this.store.dispatch(new UserAction.FollowProfileByUserId({
        followUserId: taId,
        vId: clientVID()
      })));
      this.#processing$$.next(this.#following.filter(e => e !== taId));
    } finally {
      this.#processing$$.next(this.#following.filter(e => e !== taId));
    }
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

}
