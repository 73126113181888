<navbar-view (actionClicked)="dialogRef.close()"></navbar-view>

<dialog-close-button></dialog-close-button>

<ng-container *ngIf="!(service.brandCampaign$ | async)">
  <div class="auth-dialog-loader" [@opacityFade]>
    <mat-progress-spinner theme="white" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-container>

<div dynamicStyles class="auth-dialog">

  <div class="auth-dialog--logo-wrapper">
    <img class="auth-dialog--logo" [lazyLoad]="(service.logo$ | async)!" [defaultImage]="hiptravelerLogoBase46" [errorImage]="hiptravelerLogoBase46" [class.brand]="!(service.hiptraveler$ | async)" draggable="false" alt="auth-dialog-hiptraveler-logo">
    <powered-by-hiptraveler *ngIf="!(service.hiptraveler$ | async)"></powered-by-hiptraveler>
  </div>

  <ng-container [ngSwitch]="dialogView">
    <ng-container *ngSwitchCase="0">
      <auth-dialog-login [@opacityFadeIn]
        (forgotPasswordClicked)="forgotPassword()"
        (createAccountClicked)="createAccount()">
      </auth-dialog-login>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <auth-dialog-forgot-password [@opacityFadeIn]
        (allSignInOptionsClicked)="initialView()"
        (verifyOtpOpened)="forgotPassword('verification')">
      </auth-dialog-forgot-password>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <auth-dialog-forgot-password-verification [@opacityFadeIn]
        (backToForgotPasswordClicked)="forgotPassword()"
        (createPasswordClicked)="forgotPassword('create-password')">
      </auth-dialog-forgot-password-verification>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <auth-dialog-forgot-password-create-password [@opacityFadeIn]
        (backToForgotPasswordClicked)="forgotPassword()"
        (backToLoginClicked)="initialView()">
      </auth-dialog-forgot-password-create-password>
    </ng-container>
  </ng-container>

</div>
