import { Directive, HostListener, Inject, Input } from '@angular/core';

import { WINDOW } from '@hiptraveler/common';

@Directive({
  selector: '[externalLink]'
})
export class ExternalLinkDirective {

  @Input('externalLink') link?: string | null;

  constructor(
    @Inject(WINDOW) private window: any
  ) { }

  @HostListener('click')
  hostClicked(): void {
    this.link && this.window.open(this.link, '_blank');
  }

}
