import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dialog-close-button-lts',
  template: `
    <div class="dialog-close-button-inner-container-lts mat-elevation-z3" matDialogClose>
      <mat-icon>close</mat-icon>
    </div>
  `,
  styleUrls: ['./dialog-close-lts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'dialog-close-button-lts' }
})
export class DialogCloseButtonLtsComponent {}
