import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

import { AppListenerService } from '@hiptraveler/common';
import { Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[appCustomGoogleMapAutocompleteState]'
})
export class CustomGoogleMapAutocompleteStateDirective�implements OnDestroy, AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private appListener: AppListenerService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    this.appListener.mapsAutocompleteShadowState$.pipe(
      tap((state: boolean) => state ? this.addBoxShadow() : this.removeBoxShadow()),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private addBoxShadow(): void {
    this.elementContainer((element: HTMLElement) => {
      element.classList.remove('shadow-none');
    })
  }
  
  private removeBoxShadow(): void {
    this.elementContainer((element: HTMLElement) => {
      element.classList.add('shadow-none');
    })
  }

  private elementContainer(callback: (element: HTMLElement) => void): void {
    const element = this.element.nativeElement.parentElement?.parentElement;
    const pacContainer = element?.getElementsByClassName('pac-container');
    pacContainer && setTimeout(() => {
      Array.from(pacContainer as HTMLCollectionOf<HTMLElement>).forEach((element: HTMLElement) => {
        callback(element);
      });
    }, 1000);
  }

}
