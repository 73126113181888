import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BrandState, BrandStateModel } from '@hiptraveler/data-access/brand';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Injectable()
export class FiltersService {

  @Select(BrandState.state) brandState$: Observable<Partial<BrandStateModel> | null>;
  
  form: FormGroup;
  #preventLine: boolean = false;

  constructor(
    @Inject(FormBuilder) fb: FormBuilder,
    store: Store,
    stateService: ExperienceFinderStateService
  ) {
    this.form = fb.group({
      experience: [ null ],
      travelStyle: [ null ],
      activity: [ null ],
    });

    stateService.selectedFilters$$.subscribe((values: string[]) => {
      this.#preventLine = true;
      const updateFormValue = (key: string): void => {
        const experiences = store.selectSnapshot(BrandState.state)?.experiences || [];
        const options = experiences.filter(e => e.category === key);
        const intersectingValues = values.filter(value => options.some(option => option.code === value));
        this.form.patchValue({ [key]: intersectingValues });
      };
      updateFormValue('experience');
      updateFormValue('travelStyle');
      updateFormValue('activity');
      setTimeout(() => (this.#preventLine = false), 0);
    });

    this.form.valueChanges.subscribe((form: any) => {
      if (this.#preventLine) return;
      stateService.selectedFilters$$.next([
        form.experience || [],
        form.travelStyle || [],
        form.activity || []
      ].flat());
    });
  }

}
