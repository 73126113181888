<div class="search-results">

  <feature-card actionCard actionButton toggleFavorite="adventures" type="amusement" type="amusement" [id]="result.id" [starRating]="result?.tourData?.overallRating" [featureCardHover]="result" (click)="service.searchResultDialog.open(result, 'adventure')" *ngFor="let result of (dataAccess.imageResults$ | async);" [@opacityFade]>

    <itinerary-action-bubble [active]="!!(service.searchPageControl.activityDate$ | async) && (result.id | validateStatus: (service.actDate$ | async))">
      <itinerary-action [activityId]="result.id"></itinerary-action>
    </itinerary-action-bubble>
    <img featureCardImage [lazyLoad]="result?.thumbnail || ''" [defaultImage]="('adventure' | searchSvgPath | async)!" [errorImage]="('adventure' | searchSvgPath | async)!" [alt]="result.name">
    <feature-card-price [currency]="result.tourData?.price?.currency" [amount]="result?.tourData?.price?.amount"></feature-card-price>
    <feature-card-title class="amusement">{{ result.name }}</feature-card-title>
    <feature-card-subtitle>{{ result | searchResultLocation }}</feature-card-subtitle>
    <feature-card-rating-reviews>{{ result?.tourData?.numRating || 0 }}</feature-card-rating-reviews>
    <feature-card-duration [duration]="result?.duration"></feature-card-duration>
  
  </feature-card>
  
  <ng-container *ngIf="!(dataAccess.imageResults$ | async)">
  
    <ngx-skeleton-loader class="search-result-card" count="1" appearance="line" *ngFor="let _ of skeletons;" [@opacityFadeIn]>
    </ngx-skeleton-loader>
  
  </ng-container>
  
  <ng-container *ngIf="(dataAccess.imageResults$ | async)?.length === 0">
    <p class="ht-no-results" style="text-align: center;" translate="profile.no-uploads-yet"></p>
  </ng-container>

</div>
