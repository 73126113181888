import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AuthState } from '.';
import { SignOutNoAuthService, SocialMediaAuthService } from './services';
import { LogoutPluginProvider } from './plugins/logout.plugin';

const STATES = [
  AuthState
];

const PROVIDERS = [
  SocialMediaAuthService,
  SignOutNoAuthService,
  LogoutPluginProvider
];

@NgModule({
  imports: [
    NgxsModule.forFeature(STATES)
  ],
  providers: PROVIDERS
})
export class AuthStateModule {}
