import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, take } from 'rxjs';

import { chromaClient } from '@hiptraveler/core/chroma';
import { facebookClient } from '@hiptraveler/core/facebook';
import { googleMapsClient } from '@hiptraveler/core/google-maps';
import { googleTagManagerClient } from '@hiptraveler/core/gtm';
import { leafletClient } from '@hiptraveler/core/leaflet';
import { showdownClient } from '@hiptraveler/core/showdown';
import { mediumEditorClient } from '@hiptraveler/core/medium-editor';
import { cryptoJsClient } from '@hiptraveler/core/crypto-js';
import { BrandState } from '@hiptraveler/data-access/brand';
import { getWindowRef, globalStateManagementStoreKey, i18nServiceStoreKey, leafletNamespaceKey, mediumEditorNamespaceKey, setClientCId, showdownNamespaceKey } from '@hiptraveler/common';

@Injectable()
export class GlobalObjectsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private i18n: TranslateService
  ) { }

  async initialize(): Promise<void> {

    if (isPlatformServer(this.platformId)) return;

    getWindowRef()[i18nServiceStoreKey] = this.i18n;
    getWindowRef()[globalStateManagementStoreKey] = this.store;

    await Promise.all([
      chromaClient(), facebookClient(), googleMapsClient(), googleTagManagerClient(), cryptoJsClient()
    ]);

    const [ leafletResult, showdownResult, mediumEditorResult ] = await Promise.all([
      leafletClient(), showdownClient(), mediumEditorClient()
    ]);
    
    getWindowRef()[leafletNamespaceKey] = leafletResult;
    getWindowRef()[showdownNamespaceKey] = showdownResult;
    getWindowRef()[mediumEditorNamespaceKey] = mediumEditorResult;

    this.store.select(BrandState.brandCampaign).pipe(
      map(e => e?.cId),
      filter(Boolean),
      take(12)
    ).subscribe((cId: string) => setClientCId(cId));
  }

}
