import { Pipe, PipeTransform } from '@angular/core';
import { Type, headerTypeValues } from '../common';

@Pipe({
  name: 'headerValueByIndex'
})
export class HeaderValueByIndexPipe implements PipeTransform {

  transform(index: number , type: Type): string {
    return headerTypeValues[`${type}Data`][index-1].display;
  }

}
