import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, skip, take } from 'rxjs';

import { HotelResultData } from '@hiptraveler/data-access/api';
import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { HighlightBannerService } from './highlight-banner.service';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';
import { SearchPageControlStateService } from '@hiptraveler/common';
import { DialogType, SearchResultDialogControlService, SearchResultDialogStateService, animationDelay } from '../../common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'highlight-banner',
  templateUrl: './highlight-banner.component.html',
  styleUrls: ['../highlight-styles.scss', './highlight-banner.component.scss'],
  host: { class: 'highlight highlight-banner' },
  animations: [ opacityFade(animationDelay) ],
  viewProviders: [ HighlightBannerService, AuthDialogActionService, PromptDialogActionService ],
  encapsulation: ViewEncapsulation.None
})
export class HighlightBannerComponent implements OnInit {

  @Input() data?: HotelResultData;
  @Input() dialogType: DialogType;
  
  currentIndex: number = 0;
  visibility: boolean = true;

  constructor(
    private store: Store,
    public service: HighlightBannerService,
    public searchPageControl: SearchPageControlStateService,
    public control: SearchResultDialogControlService,
    public stateService: SearchResultDialogStateService
  ) { }

  ngOnInit(): void {
    (this.service.hotelInformation$ as any).pipe(
      skip(1),
      map((response: HotelResultData) => (this.visibility = !!response?.location)),
      take(1)
    ).subscribe();
  }

  nextImage(option: 'next' | 'prev'): void {
    const photos = this.store.selectSnapshot(ItineraryState.hotelInformation)?.imgs || [];
    if (option === 'prev' && (this.currentIndex === 0)) {
      this.currentIndex = photos.length;
    }
    if (option === 'prev') {
      --this.currentIndex;
    }
    if (option === 'next' && this.currentIndex < photos.length) {
      ++this.currentIndex;
    }
    if (option === 'next' && (this.currentIndex === photos.length)) {
      this.currentIndex = 0;
    }
    this.stateService.selectedHighlight$$.next(photos?.[this.currentIndex])
  }

}
