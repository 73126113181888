import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ExperienceFinderComponent } from './experience-finder.component';

export const EXPERIENCE_FINDER_DIALOG_ID = 'experience-finder-dialog';

@Injectable()
export class ExperienceFinderDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(): MatDialogRef<ExperienceFinderComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(EXPERIENCE_FINDER_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(ExperienceFinderComponent, {
      autoFocus: 'dialog',
      id: EXPERIENCE_FINDER_DIALOG_ID,
      panelClass: EXPERIENCE_FINDER_DIALOG_ID + '-overlay-panel',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: false,
      width: '632px',
      maxWidth: 'auto',
      maxHeight: '100vh',
      minWidth: 0,
      minHeight: 0
    });
  }

}
