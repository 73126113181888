import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { ListButtonModule } from './list-button/list-button.module';
import { ListPanelModule } from './list-panel/list-panel.module';

import { ListOverlayComponent } from './list-overlay.component';

const MODULES = [
  CommonModule,
  OverlayModule
];

const LIBRARIES = [
  ListButtonModule,
  ListPanelModule
];


const COMPONENTS = [
  ListOverlayComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ListOverlayComponent
  ]
})
export class ListOverlayModule { }
