<div class="price-offers ht-dialog-elevation" [class.no-lowest-offer]="!progressVisibility">
  
  <ng-container *ngIf="progressVisibility">
    <h2>{{ 'search-result.lowest-price' | translate }} <span (click)="openDialog()" *ngIf="(service.hotelInformation$ | async)?.offers?.length">{{ 'search-result.see-other-rates' | translate }}</span></h2>
  </ng-container>
  
  <ng-container *ngIf="(service.hotelInformation$ | async)?.lowestOffer as lowestOffer;else noLowestOfferData">
    <div class="price-offers--highlight" *ngIf="lowestOffer" [@opacityFade]>

      <ng-container *ngIf="lowestOffer.rate;else noRate">
        <img *ngIf="lowestOffer.logo" [src]="lowestOffer.logo" alt="offer logo" draggable="false" [externalLink]="lowestOffer.deeplink">
        <div class="price-offers--highlight-content">
          <p class="ht-digit">{{ lowestOffer.currency | parseCurrency: lowestOffer.rate }}</p>
          <p>{{ lowestOffer.meal_plan }}</p>
        </div>
      </ng-container>
      <ng-template #noRate>
        <img [src]="lowestOffer.logo" alt="offer logo" draggable="false" style="margin: auto;" [externalLink]="lowestOffer.deeplink">
      </ng-template>

    </div>
    <ui-action-button theme="secondary" class="price-offers--view-deal" [externalLink]="lowestOffer.deeplink">{{ 
    'search-result.view-deal' | translate }}</ui-action-button>
    <p translate="search-result.price-per-room"></p>
  </ng-container>
  <ng-template #noLowestOfferData>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin: 24px 0 0;" *ngIf="progressVisibility"></mat-progress-spinner>
  </ng-template>

  <trip-advisor [data]="(service.hotelInformation$ | async)?.taDataMap"></trip-advisor>

</div>
