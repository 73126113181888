import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { SendMessageDialogComponent } from './send-message-dialog.component';

export const SEND_MESSAGE_DIALOG_ID = 'send-message-dialog';

export interface DataParam {
  profileId: string;
  travelAgent?: boolean;
}

@Injectable()
export class SendMessageDialogActionService {

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) { }

  open(data: DataParam): MatDialogRef<SendMessageDialogComponent, unknown> | unknown {
    const dialog = this.dialog.getDialogById(SEND_MESSAGE_DIALOG_ID);
    if (dialog) return dialog.close();
    return this.dialog.open(SendMessageDialogComponent, {
      closeOnNavigation: true,
      autoFocus: 'dialog',
      id: SEND_MESSAGE_DIALOG_ID,
      panelClass: SEND_MESSAGE_DIALOG_ID + '-overlay-panel',
      width: '600px',
      minWidth: 0,
      maxWidth: 'auto',
      minHeight: 0,
      maxHeight: '100vh',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data
    })
  }

}
