import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { LocationData } from '@hiptraveler/data-access/api';
import { SearchState } from '@hiptraveler/data-access/search';

@Injectable()
export class SearchBannerActionsService {

  @Select(SearchState.locationData) locationData$: Observable<LocationData>;

}
