import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map, of } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ToolbarOption } from '@hiptraveler/common';

@Pipe({
  name: 'headers'
})
export class HeadersPipe implements PipeTransform {

  constructor(
    private store: Store
  ) { }

  transform(selections?: ToolbarOption[]): Observable<Partial<ToolbarOption>[] | undefined> {
    return selections ? of(selections) : this.store.select(BrandState.brandCampaign).pipe(
      map((brandCampaign: Partial<BrandCampaign> | null) => {
        return brandCampaign?.campaignCustom?.navHeaders?.map((item: any) => {
          if (item.category === 'All') {
            item['url'] = 'search';
          } else {
            item['url'] = item.link;
          }
          return item;
        });
      })
    )
  }

}
