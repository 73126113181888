import { DivIconOptions, ZoomPanOptions } from "leaflet";

import { getAdventureMarkerTemplate, getFoodMarkerTemplate, getHotelMarkerTemplate, getMapMarkerTemplate, getProfileMarkerTemplate } from "./templates";
import { currentLang } from "@hiptraveler/common";
import { MarkerIcon, MarkerIconRecord } from "./leaflet-map.interface";

export const zoomPanOptions: ZoomPanOptions = {
  animate: true,
  duration: 0.5,
  easeLinearity: 0.1,
  noMoveStart: true
};

export function createMarkerIcons(value: any): MarkerIconRecord {
  
  const marketIcon: DivIconOptions = {
    className: 'ht-leaflet-marker-container',
    iconSize: [ 28, 43 ],
    iconAnchor: [14, 10],
    popupAnchor: [0, 0],
  };

  return {

    adventureMarkerIcon: value.divIcon({
      ...marketIcon,
      html: getAdventureMarkerTemplate()
    }),

    hotelMarkerIcon: value.divIcon({
      ...marketIcon,
      html: getHotelMarkerTemplate()
    }),

    foodMarkerIcon: value.divIcon({
      ...marketIcon,
      html: getFoodMarkerTemplate()
    }),

    profileMarkerIcon: value.divIcon({
      ...marketIcon,
      html: getProfileMarkerTemplate()
    }),

    mapMarkerIcon: value.divIcon({
      ...marketIcon,
      html: getMapMarkerTemplate()
    }),

    adventureMarkerHighlightIcon: value.divIcon({
      ...marketIcon,
      html: getAdventureMarkerTemplate({ className: 'highlight' })
    }),

    hotelMarkerHighlightIcon: value.divIcon({
      ...marketIcon,
      html: getHotelMarkerTemplate({ className: 'highlight' })
    }),

    foodMarkerHighlightIcon: value.divIcon({
      ...marketIcon,
      html: getFoodMarkerTemplate({ className: 'highlight' })
    }),

    profileMarkerHighlightIcon: value.divIcon({
      ...marketIcon,
      html: getProfileMarkerTemplate({ className: 'highlight' })
    }),

    mapMarkerHighlightIcon: value.divIcon({
      ...marketIcon,
      html: getMapMarkerTemplate({ className: 'highlight' })
    })

  }
}

export function createSearchPageMarkerIcons(value: any): MarkerIcon {
  
  const fn: any = createMarkerIcons(value);

  if (location.pathname.includes(`/${currentLang()}/thingstodo`)) {
    return { default: fn.adventureMarkerIcon, highlight: fn.adventureMarkerHighlightIcon };
  }
  if (location.pathname.includes(`/${currentLang()}/hotels`)) {
    return { default: fn.hotelMarkerIcon, highlight: fn.hotelMarkerHighlightIcon };
  }
  if (location.pathname.includes(`/${currentLang()}/foodanddrink`)) {
    return { default: fn.foodMarkerIcon, highlight: fn.foodMarkerHighlightIcon };
  }
  if (location.pathname.includes(`/${currentLang()}/community`)) {
    return { default: fn.profileMarkerIcon, highlight: fn.profileMarkerHighlightIcon };
  }

  return { default: fn.adventureMarkerIcon, highlight: fn.adventureMarkerHighlightIcon };
}
