import { Inject, Injectable, Provider } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { takeUntil, Observable } from 'rxjs';

import { RequestCancellationService, WINDOW, dynamicRequestCancellationEndpointsKey } from '@hiptraveler/common';

@Injectable()
export class _DynamicRequestCancellationInterceptor implements HttpInterceptor {
  
  constructor(
    @Inject(WINDOW) private window: any,
    private requestCancellation: RequestCancellationService
  ) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const value = this.window?.sessionStorage?.getItem(dynamicRequestCancellationEndpointsKey);
    const targetEndpoints = JSON.parse(value || '[]');

    return targetEndpoints.some((path: string) => request.url.includes(path))
      ? next.handle(request).pipe( takeUntil(this.requestCancellation.dynamicCancellationSignal$) )
      : next.handle(request);
  }

}

export const DynamicRequestCancellationInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _DynamicRequestCancellationInterceptor,
  multi: true
}
