import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as ProfileApi from './interfaces';
import { GetProfileSearchResultsDto, toFormData } from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang } from '@hiptraveler/common';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(
    private http: HttpClient
  ) { }

  checkVisitorSession(): Observable<ProfileApi.CheckVisitorSessionResponse> {
    return this.http.get<ProfileApi.CheckVisitorSessionResponse>(`/trip-planner/checkVisitorSession/${brandCampaignId()}`, {
      params: { vId: clientVID()! }
    });
  }

  getProfileDetails(userId: string): Observable<ProfileApi.GetProfileDetailsResponse> {
    return this.http.get<ProfileApi.GetProfileDetailsResponse>(`/trip-planner/getProfile/${brandCampaignId()}/${userId}`);
  }

  getAmbassadorDataById(userId: string): Observable<ProfileApi.GetAmbassadorDataResponse> {
    return this.http.get<ProfileApi.GetAmbassadorDataResponse>(`/trip-planner/getAmbdata/${userId}`);
  }

  getProfileStoryResults(payload: GetProfileSearchResultsDto): Observable<ProfileApi.GetProfileStoryResultsResponse> {
    return this.http.get<ProfileApi.GetProfileStoryResultsResponse>(`/trip-planner/profile/stories/${brandCampaignId()}/${payload.userId}/${payload.page}`);
  }

  getProfileFavoriteResults(payload: GetProfileSearchResultsDto): Observable<ProfileApi.GetProfileFavoriteResultsResponse> {
    return this.http.get<ProfileApi.GetProfileFavoriteResultsResponse>(`/trip-planner/getMyLikes/${brandCampaignId()}/${payload.userId}/${payload.page}`);
  }

  getProfileUploadResults(payload: GetProfileSearchResultsDto): Observable<ProfileApi.GetProfileUploadResultsResponse> {
    return this.http.get<ProfileApi.GetProfileUploadResultsResponse>(`/trip-planner/uploads/${brandCampaignId()}/${payload.userId}/${payload.page}`);
  }

  getFollowingUserResults(payload: GetProfileSearchResultsDto): Observable<ProfileApi.GetProfileUsersResponse> {
    return this.http.get<ProfileApi.TravelAgentResponse>(`/trip-planner/following/${brandCampaignId()}/${payload.userId}/${payload.page}`);
  }

  getFollowersUserResults(payload: GetProfileSearchResultsDto): Observable<ProfileApi.GetProfileUsersResponse> {
    return this.http.get<ProfileApi.TravelAgentResponse>(`/trip-planner/followUsers/${brandCampaignId()}/${payload.userId}/${payload.page}`);
  }

  getTravelAgent(travelAgentId: string): Observable<ProfileApi.TravelAgentResponse> {
    return this.http.get<ProfileApi.TravelAgentResponse>(`/trip-planner/gettravelagent/${brandCampaignId()}/${travelAgentId}`);
  }

  getAmbassador(ambassadorId: string): Observable<ProfileApi.AmbassadorResponse> {
    return this.http.get<ProfileApi.AmbassadorResponse>(`/trip-planner/getAmbassador/${ambassadorId}`);
  }

  updateProfile(payload: ProfileApi.UpdateProfileDto): Observable<ProfileApi.UpdateProfileContentResponse> {
    return this.http.post<ProfileApi.UpdateProfileContentResponse>(`/trip-planner/updateProfile`, toFormData(payload));
  }

  updateProfileContent(payload: ProfileApi.UpdateProfileDto): Observable<ProfileApi.UpdateProfileContentResponse> {
    return this.http.post<ProfileApi.UpdateProfileContentResponse>(`/trip-planner/updateProfileContent`, payload);
  }

  updateProfileSettings(payload: ProfileApi.UpdateProfileSettingsDto): Observable<any> {
    return this.http.post<any>(`/trip-planner/updateProfileSettings`, payload);
  }

  updatePlacesVisited(payload: ProfileApi.UpdatePlacesVisitedDto): Observable<ProfileApi.UpdatePlacesVisitedResponse> {
    return this.http.post<ProfileApi.UpdatePlacesVisitedResponse>(`/trip-planner/updatePlacesVisited/${payload.id}`, payload.data);
  }

  saveTravelAgent(payload: ProfileApi.SaveTravelAgentDto): Observable<ProfileApi.TravelAgentResponse> {
    return this.http.post<ProfileApi.TravelAgentResponse>(`/trip-planner/savetravelagent/${brandCampaignId()}/${currentLang()}`, payload);
  }

  saveAmbassador(payload: ProfileApi.SaveAmbassadorDto): Observable<ProfileApi.AmbassadorResponse> {
    return this.http.post<ProfileApi.AmbassadorResponse>(`/trip-planner/saveambassador`, payload);
  }

  savePlaceDetails(payload: ProfileApi.TourDataDto): Observable<ProfileApi.SavePlaceDetailsResponse> {
    return this.http.post<ProfileApi.SavePlaceDetailsResponse>(`/trip-planner/savePlaceDetails`, payload);
  }

  deleteAccount(payload: ProfileApi.DeleteAccountDto): Observable<ProfileApi.TravelAgentResponse> {
    return this.http.post<ProfileApi.TravelAgentResponse>(`/trip-planner/deleteAccount/${payload.userId}`, {}, {
      params: { vId: `${clientVID()}` }
    });
  }

}
