import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { CustomMapAutocompleteModule } from '@hiptraveler/features/custom-map-autocomplete';

import { DestinationSpecialtiesComponent } from './destination-specialties.component';
import { LocationSelectedPipe } from './location-selected.pipe';

const MODULES = [
  CommonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  CustomMapAutocompleteModule
];

const COMPONENTS = [
  DestinationSpecialtiesComponent
];

const PIPES = [
  LocationSelectedPipe
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    PIPES
  ],
  exports: [
    DestinationSpecialtiesComponent
  ]
})
export class DestinationSpecialtiesModule { }
