import { BAHAMAS_BRAND_NAME, DEFAULT_BRAND_NAME, subdomain, VISITJORDAN_BRAND_NAME } from "../subdomain";

/**
 * Places Types documentation/references:
 * 
 * https://developers.google.com/maps/documentation/places/web-service/supported_types
 * https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
 **/ 
const placeTypes: string[] = [
  /**
   * - Countries (e.g., United States, Brazil, Japan).
   * - Major sovereign states.
   */
  'country',
  /**
   * - Specific cities, towns, or villages (e.g., San Francisco, Tokyo).
   * - Autonomous regions or smaller administrative divisions within a country.
   */
  'locality',
  /**
   * - States or provinces within a country (e.g., California, Ontario).
   * - Primary administrative divisions within a nation.
   */
  'administrative_area_level_1',
  /**
   * - Residential areas within a city or town (e.g., Greenwich Village in New York City).
   * - Named districts within larger cities (e.g., Soho in London).
   * - Smaller communities within larger municipalities (e.g., The Castro in San Francisco).
   */
  'neighborhood',
  /**
   * - Natural landmarks and features (e.g., mountains, lakes, rivers).
   * - Significant natural formations (e.g., the Grand Canyon, Mount Everest).
   * - Other notable geographic features (e.g., forests, deserts).
   */
  'natural_feature'
];

const options = {
  fields: [ 'formatted_address', 'geometry', 'name', 'place_id', 'address_components' ],
  strictBounds: false,
  types: placeTypes
} as google.maps.places.AutocompleteOptions;

export function placesAutocompleteOptions(bypassOption: boolean): google.maps.places.AutocompleteOptions {

  if (bypassOption) {
    delete options.componentRestrictions
    return options;
  }

  if (subdomain() === BAHAMAS_BRAND_NAME) {
    options.componentRestrictions = {
      country: [ 'BS' ]
    };
  }

  if (subdomain() === VISITJORDAN_BRAND_NAME) {
    options.componentRestrictions = {
      country: [ 'JO' ]
    };
  }
  
  if (subdomain() === DEFAULT_BRAND_NAME) {
    delete options.componentRestrictions
  }

  return options; 
}
