import { Injectable, NgModule, Optional } from '@angular/core';
import { ActionCompletion, Actions, Store } from '@ngxs/store';

import { ActivityApiService } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { AnalyticsService } from '../analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ItineraryStateService extends AnalyticsService {

  constructor(
    store: Store,
    actions$: Actions,
    api: ActivityApiService
  ) {

    super(actions$);

    this.observeActionCompleted(
      ItineraryAction.GetItineraryPageDataByPageTitle,
      () => api.setUserAction({
        actionType: 'view',
        objType: 'itinerary'
      }, {
        actionId: store.selectSnapshot(ItineraryState.basicInfo)?.id
      })
    );

    this.observeActionCompleted(
      ItineraryAction.GetItineraryByPageTitle,
      () => api.setUserAction({
        actionType: 'view',
        objType: 'itinerary'
      }, {
        actionId: store.selectSnapshot(ItineraryState.basicInfo)?.id
      })
    );

    this.observeActionCompleted(
      ItineraryAction.GetBlogByPageTitle,
      () => api.setUserAction({
        actionType: 'view',
        objType: 'blog'
      }, {
        actionId: store.selectSnapshot(ItineraryState.basicInfo)?.id
      })
    );

    this.observeActionCompleted(
      ItineraryAction.UploadItineraryBlog, 
      () => api.setUserAction({
        actionType: 'saveBlog',
        objType: 'blog'
      }, {
        actionId: store.selectSnapshot(ItineraryState.basicInfo)?.id
      }),
      (param: ActionCompletion<any, any>) => param?.action?.data?.action !== 'publish-review'
    );
  
    this.observeActionDispatched(
      ItineraryAction.UpdateItineraryActivity,
      (param: ItineraryAction.UpdateItineraryActivity) => api.setUserAction({
        actionType: 'addtotrip',
        objType: param?.data?.type
      }, {
        actionId: param?.data?.id,
        objectId: param?.data?.item
      }),
      (param: ItineraryAction.UpdateItineraryActivity) => param.data.action === 'add-item'
    );

    this.observeActionDispatched(
      ItineraryAction.GetImageInformation,
      (param: ItineraryAction.GetImageInformation) => api.setUserAction({
        actionType: 'view',
        objType: 'image'
      }, {
        actionId: param.data.id
      }),
    );

    this.observeActionDispatched(
      ItineraryAction.GetFoodAndDrinkDialogData,
      (param: ItineraryAction.GetFoodAndDrinkDialogData) => api.setUserAction({
        actionType: 'view',
        objType: 'image'
      }, {
        actionId: param.data.id
      }),
    );

    this.observeActionDispatched(
      ItineraryAction.GetHotelInformation,
      (param: ItineraryAction.GetHotelInformation) => api.setUserAction({
        actionType: 'view',
        objType: 'hotel'
      }, {
        actionId: param.data.id
      }),
    );

    this.observeActionDispatched(
      ItineraryAction.ToggleLikeItinerary,
      (param: ItineraryAction.ToggleLikeItinerary) => api.setUserAction({
        actionType: 'hip',
        objType: param.type
      }, {
        actionId: param.data.id
      }),
    );
  }

}

@NgModule()
export class ItineraryStateModule {
  constructor(
    @Optional() service: ItineraryStateService
  ) { }
}
