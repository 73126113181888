import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { map, tap } from 'rxjs';
import { uniqBy } from 'lodash';

import { UserStateModel, UserAction } from '.';
import * as API from '@hiptraveler/data-access/api';
import { UserBlogData, UserDataField, UserItineraryData } from '@hiptraveler/data-access/api';

export const USER_STATE_TOKEN = new StateToken<UserStateModel>('state_user');

export const userStateDefaults: any = null;

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: userStateDefaults
})

@Injectable()
export class UserState {

  @Selector()
  static state(state: UserStateModel): UserStateModel | null {
    return state || null;
  }

  @Selector()
  static authenticated(state: UserStateModel): boolean {
    return !!state?.id;
  }

  @Selector()
  static id(state: UserStateModel): string | null {
    return state?.id || null;
  }

  @Selector()
  static profileId(state: UserStateModel): string | null {
    return state?.uTitle || null;
  }

  @Selector()
  static blogs(state: UserStateModel): UserBlogData[] | null {
    return state?.blogs || null;
  }

  @Selector()
  static itineraries(state: UserStateModel): UserItineraryData[] | null {
    return state?.itinerary ? uniqBy(state.itinerary, 'id') : null;
  }

  @Selector()
  static following(state: UserStateModel): string[] | null {
    return state?.following || null;
  }

  static field(type: UserDataField) {
    return createSelector([UserState], (state: UserStateModel) => {
      return state?.[type] || null;
    });
  }

  constructor(
    private profileApi: API.ProfileApiService,
    private followApi: API.FollowApiService
  ) { }

  @Action(UserAction.SetBlogsAndItineraryData)
  signUpWithEmailAndPassword(ctx: StateContext<UserStateModel>, action: UserAction.SetBlogsAndItineraryData) {
    ctx.setState(
      patch<UserStateModel>({ ...action.data, itinerary: append(action.data.itinerary!) as any })
    );
  }

  @Action(UserAction.FollowProfileByUserId)
  followProfileByUserId(ctx: StateContext<UserStateModel>, { data }: UserAction.FollowProfileByUserId) {
    return this.followApi.followUser(data).pipe(
      tap(API.validateResponse),
      tap(() => ctx.setState(
        patch<UserStateModel>({
          following: append<string>([ data.followUserId ])
        })
      ))
    );
  }

  @Action(UserAction.CheckVisitorSession)
  checkVisitorSession(ctx: StateContext<UserStateModel>) {
    return this.profileApi.checkVisitorSession().pipe(
      map((response: API.CheckVisitorSessionResponse) => {
        API.validateResponse(response, { ignoreKey: 'vId' });
        ctx.patchState({ itinerary: response?.visitor?.itinerary || null })
      })
    )
  }

  @Action(UserAction.UpdateItineraryData)
  updateItineraryData(ctx: StateContext<UserStateModel>, { data }: UserAction.UpdateItineraryData) {
    ctx.patchState({ itinerary: data });
  }

  @Action(UserAction.UpdateProfileImage)
  updateProfileImage(ctx: StateContext<UserStateModel>, { data }: UserAction.UpdateProfileImage) {
    ctx.patchState({ picUrl: data });
  }

  @Action(UserAction.ResetUserState)
  resetUserState(ctx: StateContext<UserStateModel>) {
    ctx.setState(userStateDefaults);
  }

}
