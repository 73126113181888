import { Component } from '@angular/core';

@Component({
  selector: 'section-action',
  template: `
    <div class="section-action">
      <mat-icon>add</mat-icon>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: [`./section-action.component.scss`]
})
export class SectionActionComponent {}
