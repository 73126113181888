<ng-container *ngIf="!ref.disableClose">
  <dialog-close-button [@opacityFade]></dialog-close-button>
</ng-container>

<div class="compose-note" [class.processing]="ref.disableClose">

  <h1 translate="compose-note.private-note-reservation"></h1>

  <form [formGroup]="service.form">

    <mat-form-field appearance="outline">
      <mat-label>{{ 'compose-note.note' | translate }}</mat-label>
      <textarea matInput [placeholder]="'compose-note.enter-your-note-here' | translate" rows="4" type="text" autocomplete="off" name="notes" formControlName="notes"></textarea>
    </mat-form-field>

    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'compose-note.confirmation-no' | translate }}</mat-label>
        <input matInput [placeholder]="'compose-note.optional' | translate" type="text" autocomplete="off" name="reference" formControlName="reference">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'compose-note.contact' | translate }}</mat-label>
        <input matInput [placeholder]="'compose-note.optional' | translate" type="text" autocomplete="off" name="contact" formControlName="contact">
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'compose-note.name' | translate }}</mat-label>
      <input matInput type="text" autocomplete="off" name="obj-name" formControlName="objName">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'compose-note.location' | translate }}</mat-label>
      <input matInput type="text" autocomplete="off" name="location" formControlName="location">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'compose-note.check-in-check-out' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker" [min]="minDate" (click)="picker.open()">
        <input matStartDate [placeholder]="'compose-note.check-in-date' | translate" autocomplete="off" name="start-date" formControlName="startDate">
        <input matEndDate [placeholder]="'compose-note.check-out-date' | translate" autocomplete="off" name="end-date" formControlName="endDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

  </form>

  <div class="compose-note--actions" *ngIf="data?.exists;else addReservationButtons">
    <ui-action-button type="button" theme="secondary" [pending]="service.saveNotePending" (click)="service.saveNote()">{{ 'compose-note.update' | translate }}</ui-action-button>
    <ui-action-button type="button" theme="action" [pending]="service.deleteNotePending" (click)="service.deleteNote()">{{ 'compose-note.delete' | translate }}</ui-action-button>
  </div>
  <ng-template #addReservationButtons>
    <div class="compose-note--actions">
      <ui-action-button type="button" theme="secondary" [pending]="service.saveNotePending" (click)="service.saveNote()">{{ 'compose-note.save' | translate }}</ui-action-button>
    </div>
  </ng-template>

</div>
