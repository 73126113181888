<div class="activity-day-header" [ngClass]="{ empty: !activity?.ImgArray && !activity?.HotelArray, view: view === '' }">

  <div [id]="scrollId"></div>

  <div class="activity-day-header--info">
    <div class="activity-day-header--info-title">

      <ng-container *ngIf="view === '';else modifyTypeUI">
        <p (click)="navigateToSearch(activity)">{{ activity?.dayLocMap?.city }}</p>
      </ng-container>
      <ng-template #modifyTypeUI>
        <mat-form-field underlined appearance="outline" customMapAutocomplete emitOnly position="absolute-relative" [cmaIndex]="index" [cmaZIndex]="999" [cmaTopOffset]="-4" (locationChanged)="locationChanges($event)">
          <mat-label>{{ 'itinerary.where-to-explore' | translate }}</mat-label>
          <input matInput [placeholder]="'itinerary.placeholder-enter-loc' | translate" name="location" type="text" [value]="activity?.dayLocMap?.formatted_address" (blur)="placesAutocomplete.value = activity?.dayLocMap?.formatted_address || ''" #placesAutocomplete>
          <ng-container *ngIf="service.updatingLocation">
            <mat-progress-spinner mode="indeterminate" strokeWidth="4" diameter="28" [@opacityFade]></mat-progress-spinner>
          </ng-container>
        </mat-form-field>
      </ng-template>

    </div>
    <div class="activity-day-header--info-date">
      <p>{{ activity?.date | dateString }} {{ activity | multiDayTourDate: 'label' }} {{ activity | datePlaceholder: activity?.date | async }}</p>
    </div>
  </div>

  <div class="activity-day-header--selections" *ngIf="view !== ''">
    <div class="activity-day-header--selection" (click)="navigateToHeaderSelection('thingstodo')">
      <div class="activity-day-header--selection-icon">
        <mat-icon svgIcon="activity-day-header.to-do-svg"></mat-icon>
      </div>
      <p translate="itinerary.add-things-to-do"></p>
    </div>
    <div class="activity-day-header--selection" (click)="navigateToHeaderSelection('hotels')">
      <div class="activity-day-header--selection-icon">
        <mat-icon svgIcon="activity-day-header.hotel-svg"></mat-icon>
      </div>
      <p translate="itinerary.add-hotel"></p>
    </div>
    <div class="activity-day-header--selection" (click)="navigateToHeaderSelection('foodanddrink')">
      <div class="activity-day-header--selection-icon">
        <mat-icon svgIcon="activity-day-header.dining-svg"></mat-icon>
      </div>
      <p translate="itinerary.add-dining"></p>
    </div>
  </div>
</div>
