import { Inject, Injectable, PLATFORM_ID, Provider } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { takeUntil, Observable } from 'rxjs';

import { RequestCancellationService, WINDOW, requestCancellationIgnoredEndpointsKey } from '@hiptraveler/common';

const ignoredEndpoints = [
  '/assets/i18n',
  'assets/base64-collection',
  'assets/icon-svg-collection.json',
  'googleads',
  'cloudfront.net',
  '/api/auth/refreshToken',
  '/api/auth/userdetails/',
  '/api/auth/signout/',
  '/trip-planner/getBrandInfo/',
  '/trip-planner/search/location',
  '/trip-planner/search/hotels',
  '/trip-planner/search/Itineraries',
  '/trip-planner/search/Adventure/location',
  '/trip-planner/search/Food/location',
  '/trip-planner/search/community',
  '/trip-planner/getBrandContent',
];

@Injectable()
export class _RequestCancellationInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: any,
    private requestCancellation: RequestCancellationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    ignoredEndpoints.push('/trip-planner/itinerary/');
    ignoredEndpoints.push('/trip-planner/readBlog/');
    if (isPlatformServer(this.platformId)) {
    }

    const value = this.window?.sessionStorage?.getItem(requestCancellationIgnoredEndpointsKey);
    const additionalEndpoints = JSON.parse(value || '[]');

    return ignoredEndpoints.concat(additionalEndpoints).some((path: string) => request.url.includes(path))
      ? next.handle(request)
      : next.handle(request).pipe( takeUntil(this.requestCancellation.cancellationSignal$) );
  }

}

export const RequestCancellationInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: _RequestCancellationInterceptor,
  multi: true
}
