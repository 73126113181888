<div class="mockup">
  
  <p style="font-size: 34px;font-weight: bold;" translate="privacy-policy.1"></p>

  <br>

  <p translate="privacy-policy.2"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.3"></h5>
  <ol>
      <li translate="privacy-policy.4"></li>
      <li translate="privacy-policy.5"></li>
      <li translate="privacy-policy.6"></li>
      <li translate="privacy-policy.7"></li>
      <li translate="privacy-policy.8"></li>
      <li translate="privacy-policy.9"></li>
      <li translate="privacy-policy.10"></li>
      <li translate="privacy-policy.11"></li>
      <li translate="privacy-policy.12"></li>
      <li translate="privacy-policy.13"></li>
      <li translate="privacy-policy.14"></li>
      <li translate="privacy-policy.15"></li>
      <li translate="privacy-policy.16"></li>
      <li translate="privacy-policy.17"></li>
  </ol>

  <h5 style="font-weight: bold;" translate="privacy-policy.18"></h5>
  <p translate="privacy-policy.19"></p>

  <ul>
      <li translate="privacy-policy.20"></li>
      <li translate="privacy-policy.21"></li>
  </ul>

  <h5 style="font-weight: bold;" translate="privacy-policy.22"></h5>
  <p translate="privacy-policy.23"></p>

  <ol>
      <li translate="privacy-policy.24"></li>
      <li translate="privacy-policy.25"></li>
      <li translate="privacy-policy.26"></li>
      <li translate="privacy-policy.27"></li>
  </ol>

  <p translate="privacy-policy.28"></p>

  <ol>
      <li translate="privacy-policy.29"></li>
  </ol>

  <h5 style="font-weight: bold;" translate="privacy-policy.30"></h5>
  <p translate="privacy-policy.31"></p>

  <ul>
      <li translate="privacy-policy.32"></li>
      <li translate="privacy-policy.33"></li>
      <li translate="privacy-policy.34"></li>
      <li translate="privacy-policy.35"></li>
      <li translate="privacy-policy.36"></li>
      <li translate="privacy-policy.37"></li>
      <li translate="privacy-policy.38"></li>
  </ul>

  <h5 style="font-weight: bold;" translate="privacy-policy.39"></h5>
  <p>{{ 'privacy-policy.40' | translate }} <a href="https://aws.amazon.com/compliance/gdpr-center/" target="_blank">https://aws.amazon.com/compliance/gdpr-center/</a></p>
  <p translate="privacy-policy.41"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.42"></h5>
  <p translate="privacy-policy.43"></p>
  <p translate="privacy-policy.44"></p>
  <p translate="privacy-policy.45"></p>
  <p translate="privacy-policy.46"></p>
  <p>{{ 'privacy-policy.47' | translate }} <a href="mailto:privacy@hiptraveler.com">privacy@hiptraveler.com</a></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.48"></h5>
  <p translate="privacy-policy.49"></p>

  <ul>
      <li [innerHTML]="'privacy-policy.50' | translate"></li>
      <li [innerHTML]="'privacy-policy.51' | translate"></li>
      <li [innerHTML]="'privacy-policy.52' | translate"></li>
      <li [innerHTML]="'privacy-policy.53' | translate"></li>
      <li [innerHTML]="'privacy-policy.54' | translate"></li>
      <li [innerHTML]="'privacy-policy.55' | translate"></li>
  </ul>

  <p [innerHTML]="'privacy-policy.56' | translate"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.57"></h5>
  <p translate="privacy-policy.58"></p>

  <p [innerHTML]="'privacy-policy.59' | translate"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.60"></h5>
  <p translate="privacy-policy.61"></p>

  <ul>
      <li translate="privacy-policy.62"></li>
      <li translate="privacy-policy.63"></li>
      <li translate="privacy-policy.64"></li>
      <li translate="privacy-policy.65"></li>
      <li translate="privacy-policy.66"></li>
      <li translate="privacy-policy.67"></li>
  </ul>

  <h5 style="font-weight: bold;" translate="privacy-policy.68"></h5>
  <p translate="privacy-policy.69"></p>

  <ul>
      <li [innerHTML]="'privacy-policy.70' | translate"></li>
      <li [innerHTML]="'privacy-policy.71' | translate"></li>
      <li translate="privacy-policy.72"></li>
  </ul>

  <h5 style="font-weight: bold;" translate="privacy-policy.73"></h5>
  <p translate="privacy-policy.74"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.75"></h5>
  <p translate="privacy-policy.76"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.77"></h5>
  <p translate="privacy-policy.78"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.79"></h5>
  <p [innerHTML]="'privacy-policy.80' | translate"></p>

  <h5 style="font-weight: bold;" translate="privacy-policy.81"></h5>
  <p translate="privacy-policy.82"></p>

  <p [innerHTML]="'privacy-policy.83' | translate"></p>

  <p translate="privacy-policy.84"></p>

  <br><br>

  <use-policy-and-copyright></use-policy-and-copyright>

</div>
