<ng-container *ngIf="(service.coordinates$ | async) && (service.showSelection$ | async)">

  <div matRipple [matTooltip]="'compose.edit-post' | translate" [@opacityFade] (click)="changeHostState()"
    [ngStyle]="{ top: (service.coordinates$$ | async)?.top, left: (service.coordinates$$ | async)?.left }">
    <mat-icon [ngClass]="{ active: service.hostState }">add</mat-icon>
  </div>
  
  <div matRipple [matTooltip]="'compose.add-photo' | translate" class="selection" [ngClass]="{ active: service.hostState }" (click)="changeHostState(0)"
    [ngStyle]="{ top: (service.coordinates$$ | async)?.top, left: service.hostState ? (service.coordinates$$ | async)?.left1 : (service.coordinates$$ | async)?.left }">
    <mat-icon>image</mat-icon>
  </div>
  
  <div matRipple [matTooltip]="'compose.embed-video' | translate" class="selection" [ngClass]="{ active: service.hostState }" (click)="changeHostState(1)"
    [ngStyle]="{ top: (service.coordinates$$ | async)?.top, left: service.hostState ? (service.coordinates$$ | async)?.left2 : (service.coordinates$$ | async)?.left }">
    <mat-icon>play_arrow</mat-icon>
  </div>
  
</ng-container>
