import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { ScrollElementBorderDirectiveModule, ParseCurrencyPipeModule } from '@hiptraveler/common';

import { HotelOffersDialogComponent } from './hotel-offers-dialog.component';

const MODULES = [
  CommonModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  DialogCloseButtonModule,
  ScrollElementBorderDirectiveModule,
  ParseCurrencyPipeModule
];

const COMPONENTS = [
  HotelOffersDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class HotelOffersDialogModule { }
