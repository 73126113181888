import { Component, HostListener } from '@angular/core';

import { CustomActivityDialogActionService } from './custom-activity-dialog';
import { ProfileStateFormService } from '../../common';

@Component({
  selector: 'custom-activity-action-button',
  templateUrl: './custom-activity-action-button.component.html',
  styleUrls: ['./custom-activity-action-button.component.scss'],
  viewProviders: [ CustomActivityDialogActionService ]
})
export class CustomActivityActionButtonComponent {

  constructor(
    private stateService: ProfileStateFormService,
    private customActivityDialog: CustomActivityDialogActionService
  ) { }

  @HostListener('click')
  hostClick(): void {
    this.customActivityDialog.open(this.stateService.activityDialogForm);
  }

}
