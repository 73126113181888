import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EmailShareDto, EmailShareResponse, MessagingApiService, toQueryString } from '@hiptraveler/data-access/api';
import { WINDOW } from '@hiptraveler/common';
import { FacebookShareRequest, PinterestShareRequest, TwitterShareRequest } from './social-media-share-handle.model';

@Injectable()
export class SocialMediaShareHandleService {

  constructor(
    @Inject(WINDOW) private window: any,
    private messagingApi: MessagingApiService
  ) { }

  facebookShare(request: FacebookShareRequest): void {
    const queryString = toQueryString({
      app_id: '717552431619054',
      name: 'HipTraveler',
      link: request.url,
      picture: request.image,
      caption: encodeURIComponent(request.title),
      description: encodeURIComponent('The ultimate travel tool for finding, collecting, and sharing travel discoveries, itineraries, and experiences.'),
      redirect_uri: 'https://www.hiptraveler.com/popupClose.html',
      display: 'popup',
    });
    const url = `https://www.facebook.com/dialog/feed?${queryString}`;
    this.window.open(url, 'feedDialog', 'toolbar=0,status=0,width=626,height=436'); 
  }

  twitterShare(request: TwitterShareRequest): void {
    const queryString = toQueryString({
      title: request.title,
      url: request.url,
      via: 'hiptraveler'
    });
    const url = `https://twitter.com/share?${queryString}`;
    this.window.open(url, '_blank');
  }

  pinterestShare(request: PinterestShareRequest): void {
    const queryString = toQueryString({
      description: request.title,
      url: request.url,
      media: request.image
    });
    const url = `https://pinterest.com/pin/create/button?${queryString}`;
    this.window.open(url, '_blank');
  }

  emailShare(request: EmailShareDto): Observable<EmailShareResponse> {
    return this.messagingApi.emailShare(request);
  }

}
