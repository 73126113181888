import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, filter, map, takeUntil } from 'rxjs';

import { LOCATION, currentLang } from '@hiptraveler/common';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';
import { AuthState } from '@hiptraveler/data-access/auth';

@Component({
  selector: 'app-shell',
  template: `<router-outlet></router-outlet>`
})
export class ShellComponent implements OnInit, OnDestroy {

  subscription$ = new Subject<void>();

  constructor(
    @Inject(LOCATION) private location: Location,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(UserState.state).pipe(
      filter(() => this.location.pathname === `/${currentLang()}/profile`),
      map((userState: UserStateModel | null) => {
        this.store.selectSnapshot(AuthState.authenticated)
          ? this.router.navigate([ currentLang(), 'profile', userState?.uTitle, 'stories' ])
          : this.router.navigate([ currentLang(), 'search' ]);
      }),
      takeUntil(this.subscription$)
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

}
