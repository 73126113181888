
<p
  class="header--option"
  [class.active]="activeSelection === 'facebook'"
  (click)="updateSelection('facebook')"
  *ngIf="service.getSocialLink$('facebook') | async">
  facebook
</p>

<ng-container *ngIf="instagramDisplay">
  <p
    class="header--option"
    [class.active]="activeSelection === 'instagram'"
    (click)="updateSelection('instagram')"
    *ngIf="service.getSocialLink$('instagram') | async">
    instagram
  </p>
</ng-container>

<p
  class="header--option"
  [class.active]="activeSelection === 'youtube'"
  (click)="updateSelection('youtube')"
  *ngIf="service.getSocialLink$('youtube') | async">
  youtube
</p>

<p
  class="header--option"
  [class.active]="activeSelection === 'twitter'"
  (click)="updateSelection('twitter')"
  *ngIf="service.getSocialLink$('twitter') | async">
  X
</p>

<div class="header--spacer"></div>

<ng-container [ngSwitch]="activeSelection">
  
  <ng-container *ngSwitchCase="'facebook'">
    <div matRipple socialMediaButtonAction="facebook" class="header--social-media-button" [class.facebook]="activeSelection === 'facebook'">
      <mat-icon svgIcon="connect-content-header.fb-icon"></mat-icon>
      <p translate="search-page.follow" [translateParams]="{ value: '' | followName }"></p>
    </div>
  </ng-container>
  
  <ng-container *ngSwitchCase="'youtube'">
    <div matRipple socialMediaButtonAction="youtube" class="header--social-media-button" [class.youtube]="activeSelection === 'youtube'">
      <mat-icon svgIcon="connect-content-header.yt-icon"></mat-icon>
      <p translate="search-page.subscribe" [translateParams]="{ value: '' | followName }"></p>
    </div>
  </ng-container>
  
  <ng-container *ngSwitchCase="'twitter'">
    <div matRipple socialMediaButtonAction="twitter" class="header--social-media-button" [class.twitter]="activeSelection === 'twitter'">
      <mat-icon svgIcon="connect-content-header.x-icon"></mat-icon>
      <p translate="search-page.follow" [translateParams]="{ value: '' | followName }"></p>
    </div>
  </ng-container>

</ng-container>
