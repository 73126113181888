<ng-container *ngIf="service.basicInfo$ | async as basicInfo">
  <div class="trip-detail" *ngIf="basicInfo?.title" [@opacityFadeIn]>
  
    <div class="trip-detail--overlay"></div>
  
    <img [lazyLoad]="basicInfo?.coverImage?.originalUrl || basicInfo?.coverImage?.imgUrl || basicInfo?.coverImage?.imgThumbnail || basicInfo?.coverImage?.mobileUrl || 'https://www.hiptraveler.com/assets/img/business/contactimg.jpg'" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="basicInfo.pageTitle" class="mat-elevation-z4">
  
    <h1>{{ basicInfo.title }}</h1>
  
    <ui-action-button (click)="service.navigateToItinerary()">{{ 'search-page.my-itinerary' | translate }}</ui-action-button>  
  
  </div>
</ng-container>
