import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

import { ItineraryState } from '@hiptraveler/data-access/itinerary';
import { BasicInfoData } from '@hiptraveler/data-access/api';
import { currentLang } from '@hiptraveler/common';

@Component({
  selector: 'activity-date-list-container',
  templateUrl: './activity-date-list-skeleton.component.html',
  styleUrls: ['./activity-date-list-skeleton.component.scss'],
  host: { class: 'itinerary-page-activity-date-list-skeleton' },
  encapsulation: ViewEncapsulation.None
})
export class ActivityDateListSkeletonComponent implements OnInit, OnDestroy {

  @Input() count: number;
  @Input() actDate: Observable<any>;

  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(ItineraryState.basicInfo).pipe(
      tap((basicInfo: Partial<BasicInfoData> | null) => {
        if (!basicInfo?.hasItinerary && this.router.url.includes(`/${currentLang()}/travel-story`)) {
          this.element.nativeElement.parentElement!.style.position = 'absolute';
          this.element.nativeElement.parentElement!.style.opacity = '0';
        } else {
          this.element.nativeElement.parentElement!.style.position = 'relative';
          this.element.nativeElement.parentElement!.style.opacity = '1';
        }
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get loop(): string[] {
    return new Array(this.count).fill('');
  }

}
