import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

import { FiltersStateService } from '../common';

@Directive({
  selector: '[travelDatesField]'
})
export class TravelDatesFieldDirective implements AfterViewInit {

  subscription$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef<HTMLDivElement>,
    private stateService: FiltersStateService
  ) { }

  ngAfterViewInit(): void {
    fromEvent(this.element, 'mouseover').pipe(
      takeUntil(this.subscription$)
    ).subscribe((res: any) => {
      const state = !!res?.target.classList.contains('input-field--close');
      this.stateService.travelDatesCloseActionState$$.next(state);
    });
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

}
