import { Component } from '@angular/core';

@Component({
  selector: 'divider-svg-icon',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" fill="#fff" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12"></circle>
    </svg>
  `
})
export class DividerSvgIconComponent {}
