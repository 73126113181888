
<!-- Navigation button / Left -->
<div  class="poi-select-carousel---navigation mat-elevation-z2" matRipple (click)="navigateToLeft()">
  <mat-icon>chevron_left</mat-icon>
</div>

<!-- Selection carousel container -->
<div [id]="selectedContainerId" class="poi-select-carousel--item-selection">

  <div class="item-selection--view-container" *ngIf="selectedFilter | selection | async as selections" [style]="'width: '+((mobile === '' ? 160 : 132)*selections.length)+'px;'">

    <ng-container *ngFor="let item of selections;">
      <div class="item-selection--view" [ngClass]="{ active: item.code! | includes: selectedFilter | async, mobile: mobile === '' }" [ngStyle]="{ 'background-image': item.img | image, 'background-size': item.code === 'group_activities' ? '265%' : item.category === 'experience' ? 'cover' : '130%' }" (click)="addValueToSelection(item.code!)">
        <div class="item-selection--view-selection-indicator">
          <mat-icon>done</mat-icon>
        </div>
        <div class="item-selection--view-text-wrapper">
          <p class="item-selection--view-text">{{ item.tname ? (item.tname | translate) : item.name }}</p>
        </div>
      </div>
    </ng-container>

  </div>
  
</div>

<!-- Navigation button / Right -->
<div class="poi-select-carousel---navigation mat-elevation-z2" matRipple (click)="navigateToRight()">
  <mat-icon>chevron_right</mat-icon>
</div>
