import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, filter, map } from 'rxjs';

import { AppListenerService, NavbarControlStateService, currentLang } from '@hiptraveler/common';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  viewProviders: [ NavbarService ]
})
export class NavbarComponent {

  constructor(
    public location: Location,
    private appListener: AppListenerService,
    private navbarControl: NavbarControlStateService,
    public service: NavbarService,
  ) { }

  get mobileView$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      filter(() => this.navbarControl.allowMobileView$$.value),
      map((width: number) => width < 700)
    );
  }

  get mobileViewActions$(): Observable<boolean> {
    const allowedPaths = [ `${currentLang()}/compose/new-story`, `${currentLang()}/compose/travel-story` ];
    return this.appListener.clientWidth$.pipe(
      map((width: number) => {
        return width < 700 && allowedPaths.some((path: string) => this.location.path().includes(path))
      })
    );
  }

  exitFocusMode(): void {
    this.appListener.emitGlobalSignal('exitFocusMode');
  }

}
