import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { ProfileAction } from '@hiptraveler/data-access/profile';
import { DialogStateService } from './common';

@Injectable()
export class CustomActivityDialogService {

  constructor(
    @Inject(MAT_DIALOG_DATA) private form: FormGroup,
    private dialog: MatDialogRef<any>,
    private store: Store,
    private stateService: DialogStateService
  ) { }

  async saveChanges(): Promise<void> {
    if (this.form.invalid) return;
    
    try {
      this.stateService.formProcessing$$.next(true);
      await firstValueFrom(this.store.dispatch(new ProfileAction.SavePlaceDetails(this.form.value)));
      this.dialog.close();
    } finally {
      this.stateService.formProcessing$$.next(false);
    }
  }

}
