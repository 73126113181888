
<dialog-close-button></dialog-close-button>

<div class="email-share" [class.processing]="processing">

  <p class="email-share--title" translate="email-share.title" [translateParams]="{ value: data.blogTitle }"></p>

  <mat-form-field class="example-chip-list" appearance="outline">
    <mat-label>{{ 'email-share.email' | translate }}</mat-label>
    <mat-chip-list #chipList aria-label="Video keywords" multiple>
      <mat-chip *ngFor="let email of emailIds" [selected]="email" [value]="email" (removed)="removeKeyword(email)">
        <span>{{ email }}</span>
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input type="text" [placeholder]="'email-share.placeholder-email' | translate" name="email" [matChipInputFor]="chipList" (matChipInputTokenEnd)="addKeywordFromInput($event)">
    </mat-chip-list>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'email-share.message' | translate }}</mat-label>
    <textarea matInput [placeholder]="'email-share.placeholder-message' | translate" name="message" rows="7" [formControl]="messageControl"></textarea>
  </mat-form-field>

  <div class="email-share--actions">
    <ui-action-button size="medium" theme="outlined-primary" matDialogClose>{{ 'email-share.button-text-close' | translate }}</ui-action-button>
    <ui-action-button size="medium" theme="primary" iconRight="send" (click)="sendEmail()">{{ 'email-share.button-text-send' | translate }}</ui-action-button>
  </div>

</div>
