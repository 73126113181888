<div class="selection-view--selection-tabs" [@opacityFadeIn]>
  
  <div class="selection-view--selection" [class.active]="dialogView === 'main-menu'" (click)="updateSelection('main-menu')" [class.travelStyle]="dialogView === 'travel-style'">
    <mat-icon>menu</mat-icon>
  </div>

  <!-- <div class="select-slide-toggle--container">
    <div class="select-slide-toggle--slider-path">
  
      <input type="checkbox" name="checkbox" [ngStyle]="{ display: 'none' }" #checkbox>
  
      <div class="select-slide-toggle--slider-buttons" [class.none]="dialogView === 'main-menu'">
        <span class="select-slide-toggle--slider-button-highlight" *ngIf="dialogView !== 'main-menu'"></span>
        <div class="select-slide-toggle--slider-button" (click)="updateSelection('travel-style');checkbox.checked = false">
          <p class="select-slide-toggle--slider-button-text" [ngStyle]="{ color: checkbox.checked ? 'white' : '#404040' }">What's your travel style</p>
        </div>
        <div class="select-slide-toggle--slider-button" (click)="updateSelection('plan-trip');checkbox.checked = true">
          <p class="select-slide-toggle--slider-button-text" [ngStyle]="{ color: checkbox.checked ? '#404040' : 'white' }">Want to plan a trip?</p>
        </div>
      </div>
  
    </div>
  </div> -->
  <div class="sst-lts--buffer" *ngIf="dialogView === 'travel-style'"></div>
  <div class="sst-lts--slider-button-container" [ngClass]="{
      none: dialogView === 'main-menu',
      travelStyle: dialogView === 'travel-style',
      planTrip: dialogView === 'plan-trip'
    }">
    <div class="sst-lts--slider-button" (click)="updateSelection('travel-style');">
      <p class="sst-lts--slider-button-text" translate="exp-finder.travel-style"></p>
      <div class="sst-lts--slider-button-buffer" *ngIf="dialogView === 'travel-style'"></div>
    </div>
    <div class="sst-lts--slider-button" (click)="updateSelection('plan-trip');">
      <p class="sst-lts--slider-button-text" translate="exp-finder.plan-trip"></p>
    </div>
  
  </div>

</div>

<ng-content></ng-content>
