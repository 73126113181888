import { getWindowRef } from "@hiptraveler/common";

/**
 * Inserts an image with the specified source URL and alt message into a content-editable element.
 *
 * @param {string} srcUrl - The URL of the image to insert.
 * @param {string} altMessage - The alt text for the image.
 * @returns {Promise<void>} A promise that resolves when the image has been inserted.
 *
 * @remarks
 * - This function assumes the presence of a content-editable element with the class "hiptraveler-content-editable".
 * - It handles both cases where the user has selected a text element and where they have not.
 * - It adds a new paragraph element to hold the image, and inserts a line break after the image.
 * - It updates the highlight class to indicate the current editing position.
 */
export async function insertImageWithSrc(srcUrl: string, altMessage: string): Promise<void> {

  const insertImage = (src: string) => {
    return `<img
      class="content-editable-inserted-image"
      src="${src}"
      alt="${altMessage}-blog-post-image">`;
  }

  const appendNewElement = (contentEditable: HTMLDivElement) => {

    let selectedElement = contentEditable.getElementsByClassName('highlight')[0] as HTMLElement;
    const newLine = document.createElement('p');
    newLine.innerHTML = '<br>';

    if (selectedElement) {

      selectedElement.innerHTML = insertImage(srcUrl);
      selectedElement.parentNode?.insertBefore(newLine, selectedElement.nextSibling);

      const nextElementSibling = selectedElement?.nextElementSibling as HTMLElement;
      nextElementSibling && nextElementSibling.focus();

      selectedElement.classList.remove('highlight');
      nextElementSibling.classList.add('highlight');
    } else {

      const paragraphElement = document.createElement("p");
      paragraphElement.innerHTML = insertImage(srcUrl);
      contentEditable.appendChild(paragraphElement);

      newLine.classList.add('highlight');
      contentEditable.appendChild(newLine);
    }
  }

  const element = document.getElementsByClassName('hiptraveler-content-editable')[0] as HTMLDivElement;
  element && appendNewElement(element);
}

/**
 * Inserts a video iframe into the content editable area based on a YouTube or Vimeo URL in the clipboard.
 *
 * @async
 * @function
 * @returns {Promise<void>} A promise that resolves when the iframe has been inserted or no valid URL is found.
 */
export async function insertVideoIframe(): Promise<void> {

  const clipboard = await navigator.clipboard.readText();
  const selection = getWindowRef()?.getSelection();

  const isValidYouTubeURL = (url: string) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  }

  const isValidVimeoURL = (url: string) => {
    return url.includes('vimeo.com');
  }

  const insertVideoIframe = (clipboardData: string) => {
    const videoId = getVideoId(clipboardData);
    if (videoId) {
      if (isValidYouTubeURL(clipboardData)) {
        return `
          <iframe
            class="content-editable-youtube-iframe"
            src="https://www.youtube.com/embed/${videoId}"
            frameborder="0" allowfullscreen>
          </iframe>
        `;
      } else if (isValidVimeoURL(clipboardData)) {
        return `
          <iframe
            class="content-editable-vimeo-iframe"
            src="https://player.vimeo.com/video/${videoId}"
            frameborder="0" allowfullscreen>
          </iframe>
        `;
      }
    }
    return clipboardData;
  }

  const getVideoId = (clipboardData: string) => {
    const youtubeVideoIdRegex = /(?:v=|\/embed\/|\/v\/|youtu\.be\/|\/\d{11})([A-Za-z0-9_-]{11})/;
    const vimeoVideoIdRegex = /https:\/\/vimeo\.com\/(?:.*\/)?(\d+)/;
    
    const youtubeMatch = clipboardData.match(youtubeVideoIdRegex);
    if (youtubeMatch) {
      return youtubeMatch[1];
    }
    
    const vimeoMatch = clipboardData.match(vimeoVideoIdRegex);
    if (vimeoMatch) {
      return vimeoMatch[1];
    }
    
    return null;
  }

  const appendNewElement = (currentElement: HTMLParagraphElement) => {
    const previousElementSibling = currentElement.previousElementSibling as HTMLParagraphElement;
    const nextElementSibling = currentElement.nextElementSibling as HTMLParagraphElement;
    if (isValidYouTubeURL(clipboard) || isValidVimeoURL(clipboard)) {
      previousElementSibling && (previousElementSibling.innerHTML = insertVideoIframe(previousElementSibling.innerHTML));
      nextElementSibling && nextElementSibling.focus();
    } else {
      nextElementSibling && nextElementSibling.focus();
    }
  }

  if (selection && selection?.anchorNode) {
    appendNewElement(selection.anchorNode as HTMLParagraphElement);
  }
}
