import { Component, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';

import { SearchLocationData, SearchLocationService } from '@hiptraveler/common';

@Component({
  selector: 'explore-input-field',
  template: `
    <input
      type="text" [placeholder]="'navbar.explore-input-field-placeholder' | translate" name="explore" autocomplete="off"
      [(ngModel)]="formField" (focus)="setInputOverflow(true)" (blur)="setInputOverflow(false)" #searchLocation>
    <mat-icon (click)="removeSelectedLocation()">{{ searchLocation.value ? 'close' : 'search' }}</mat-icon>
  `,
  styleUrls: ['./explore-input-field.component.scss']
})
export class ExploreInputFieldComponent implements OnInit, AfterViewInit {

  formField: string = '';
  subscription$ = new Subject<void>();
  efOverlayState: string | null = null;

  constructor(
    private searchLocation: SearchLocationService,
    private element: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.initializeFormFieldValue();
    
    this.searchLocation.searchLocationState$.pipe(
      filter(e => !e),
      takeUntil(this.subscription$)
    ).subscribe(() => (this.formField = ''));

    this.searchLocation.searchLocation$.pipe(
      takeUntil(this.subscription$)
    ).subscribe((data: SearchLocationData) => this.formField = data.location);
  }

  ngAfterViewInit(): void {
    this.setInputOverflow(false);
  }

  private initializeFormFieldValue(): void {
    this.formField = this.searchLocation.data?.location || '';
  }

  search(location: string = this.formField): void {
    if (!location || location === this.formField) return;
    this.formField = location;
  }

  removeSelectedLocation(): void {
    this.formField = '';
    this.searchLocation.updateSearchLocation(undefined);
  }

  setInputOverflow(state: boolean): void {
    const targetElement = this.element.nativeElement.parentElement?.parentElement;
    targetElement && setTimeout(() => {
      targetElement.style.zIndex = state ? '2000' : '1000';
    }, state ? 0 : 300);
  }

}
