import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { ActivitiesService } from './activities.service';
import { NewActivityDialogActionService } from './new-activity-dialog';

@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
  viewProviders: [ ActivitiesService, NewActivityDialogActionService ]
})
export class ActivitiesComponent implements OnInit {

  selection: string[] = [];
  customActivities: string[] = [];

  constructor(
    public service: ActivitiesService,
    public newActivityDialog: NewActivityDialogActionService
  ) { }

  ngOnInit(): void {
    this.service.initializeFormObserver();
    this.service.formValue$.subscribe((value: string[]) => (this.selection = value));
  }

  toggleSelection(selection: string = '', custom: boolean = false): void {
    const indexInSelection = this.selection.indexOf(selection);
    custom && this.customActivities.splice(this.customActivities.indexOf(selection), 1);
    if (indexInSelection !== -1) {
      this.selection.splice(indexInSelection, 1);
      this.service.removeActivity(indexInSelection);
    } else {
      this.selection.push(selection);
      this.service.addActivity(selection);
    }
  }

  openNewActivity(): void {
    const ref = this.newActivityDialog.open() as MatDialogRef<unknown, string>;
    ref.beforeClosed().pipe(filter(Boolean)).subscribe((input: string) => {
      this.customActivities.push(input);
    });
  }

}
