import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, take, tap } from 'rxjs';

import { BrandState } from '@hiptraveler/data-access/brand';
import { ExperienceFinderStateService } from './experience-finder-state.service';
import { DialogView } from '@hiptraveler/features/experience-finder';
import { isWidget } from '@hiptraveler/common';

@Component({
  selector: 'app-experience-finder',
  templateUrl: './experience-finder.component.html',
  styleUrls: ['./experience-finder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store,
    private stateService: ExperienceFinderStateService
  ) { }

  ngOnInit(): void {
    this.widgetParameterViewObserver();
    this.stateService.overlayState$$.next(true);
  }

  widgetParameterViewObserver(): void {
    this.store.select(BrandState.brandCampaign).pipe(
      filter(Boolean),
      filter(() => isWidget()),
      tap(() => {
        const param = this.router.url.split('/').reverse()[0];
        const dialogView: DialogView = param === 'login' ? 'auth-menu' : param === 'menu' ? 'main-menu' : 'main-menu'
        this.stateService.dialogView$$.next(dialogView);
      }),
      take(1)
    ).subscribe()
  }

}
