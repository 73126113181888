import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, firstValueFrom, takeUntil, tap } from 'rxjs';

import { ProfileResultField, ResultField, SearchResultField } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { UserState } from '@hiptraveler/data-access/user';
import { ProfileAction, ProfileState } from '@hiptraveler/data-access/profile';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { AppListenerService, currentLang, pendingAuthProcessKey } from '@hiptraveler/common';
import { verifyUserExists } from '.';

@Directive({
  selector: '[toggleFavorite]',
  providers: [ AuthDialogActionService ]
})
export class ToggleFavoriteDirective implements OnDestroy, AfterViewInit {

  @Input() id: string;
  @Input() toggleFavorite: ResultField;

  pendingRequest: boolean = false;  
  authHandle$ = new Subject<void>();
  subscription$ = new Subject<void>();

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private router: Router,
    private store: Store,
    private appListener: AppListenerService,
    private authDialog: AuthDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.resetAuthHandle();
    this.subscription$.next();
  }

  ngAfterViewInit(): void {
    this.favButtonSpinner();
    this.buttonUIStateObserver();
  }

  @HostListener('favClicked')
  async favClicked(): Promise<void> {

    if (!this.id || !this.favButton || !this.spinner || this.pendingRequest) return;

    if (!this.store.selectSnapshot(UserState.authenticated)) {
      this.resetAuthHandle();
      const emitHandleKey = 'toggleFavoriteClicked';
      this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open('login', emitHandleKey);
      this.appListener.globalSignalListener(emitHandleKey).pipe(
        tap(() => sessionStorage.removeItem(pendingAuthProcessKey)),
        takeUntil(this.authHandle$)
      ).subscribe(() => this.favClicked());
      return;
    }

    try {
      this.pendingRequest = true;
      this.favButtonSpinner();

      if (this.router.url.includes(`/${currentLang()}/profile/`)) {
        await firstValueFrom(this.store.dispatch(new ProfileAction.ToggleProfileResultLike({
          id: this.id,
          userId: this.store.selectSnapshot(UserState.id) || '',
          hipType: verifyUserExists(this.profileUserLikes, this.store.selectSnapshot(UserState.id)),
          field: this.toggleFavorite as ProfileResultField
        })));
      } else {
        await firstValueFrom(this.store.dispatch(new SearchAction.ToggleSearchResultLike({
          id: this.id,
          userId: this.store.selectSnapshot(UserState.id) || '',
          hipType: verifyUserExists(this.searchUserLikes, this.store.selectSnapshot(UserState.id)),
          field: this.toggleFavorite as SearchResultField
        })));
      }

      this.updateFavoriteButtonClass();
    } finally {
      this.pendingRequest = false;
      this.favButtonSpinner();
    }
  }

  private get favButton(): HTMLDivElement {
    return this.element.nativeElement.getElementsByClassName('feature-card--favorite-button').item(0) as HTMLDivElement;
  }

  private get spinner(): HTMLDivElement {
    return this.element.nativeElement.getElementsByClassName('favorite-button-spinner').item(0) as HTMLDivElement;
  }

  private get searchUserLikes(): string[] {
    const searchResult: any = this.store.selectSnapshot(SearchState[this.toggleFavorite as SearchResultField] as any);
    if (!searchResult) return [];
    return this.toggleFavorite === 'hotels' 
      ? ((searchResult as any).find((result: any) => result.id === this.id)?.likes || [])
      : ((searchResult as any).find((result: any) => result.id === this.id)?.userLikes || [])
  }

  private get profileUserLikes(): string[] {
    const searchResult: any = this.store.selectSnapshot(ProfileState[this.toggleFavorite as ProfileResultField] as any);
    if (!searchResult) return [];
    return this.toggleFavorite === 'hotelResults' 
      ? ((searchResult as any).find((result: any) => result.id === this.id)?.likes || [])
      : ((searchResult as any).find((result: any) => result.id === this.id)?.userLikes || [])
  }

  private buttonUIStateObserver(): void {
    this.store.select(UserState.authenticated).pipe(
      tap((state: boolean) =>
        state ? this.updateFavoriteButtonClass() : this.removeActiveStateToElement()
      ),
      takeUntil(this.subscription$)
    ).subscribe();
  }

  private updateFavoriteButtonClass(): void {

    const userId: string = this.store.selectSnapshot(UserState.id) || '';

    if (!this.id || !userId || !this.favButton) return;

    const validation = this.router.url.includes(`/${currentLang()}/profile/`)
      ? verifyUserExists(this.profileUserLikes, userId)
      : verifyUserExists(this.searchUserLikes, userId);

    validation ? this.favButton.classList.add('active') : this.removeActiveStateToElement();
  }
  
  private favButtonSpinner(): void {
    if (!this.id || !this.spinner) return;
    this.spinner.style.display = this.pendingRequest ? 'block' : 'none';
  }

  private resetAuthHandle(): void {
    this.authHandle$.next();
    sessionStorage.removeItem(pendingAuthProcessKey);
  }

  private removeActiveStateToElement(): void {
    this.favButton.classList.remove('active');
  }

}
