import { FormClientInfo } from "../../../shared";
import { ClientInfo } from "../client-info.model";

export const CLIENT_DATA: ClientInfo[] = [
  {
    category: 'Adults', details: 'Ages 13 or above', count: 0
  },
  {
    category: 'Children', details: 'Ages 2-12', count: 0
  },
  {
    category: 'Infants', details: 'Ages 2-12', count: 0
  }
];

export function convertClientInfo(clientInfo: FormClientInfo): ClientInfo[] {
  return [
    {
      category: 'Adults',
      details: 'Ages 13 or above',
      count: clientInfo?.adults || 0
    },
    {
      category: 'Children',
      details: 'Ages 2-12',
      count: clientInfo?.children || 0
    },
    {
      category: 'Infants',
      details: 'Ages 0-2',
      count: clientInfo?.infants || 0
    }
  ];
}
