<ng-container *ngIf="display$ | async">

  <ng-container *ngIf="service.hiptravelerBrand$ | async;else nonHipTraveler">
    <hiptraveler-footer></hiptraveler-footer>
  </ng-container>
  <ng-template #nonHipTraveler>
    <non-hiptraveler-footer></non-hiptraveler-footer>
  </ng-template>

</ng-container>

<div class="footer-nav-copyright">

  <p (click)="devNavigation()">{{ 'footer.copyright-�-hiptraveler-inc-2013' | translate }} - {{ currentYear }} {{ 'footer.all-rights-reserved' | translate }}</p>

  <div class="footer-nav-copyright--selection">
    <p (click)="service.navigate('terms')">{{ 'footer.terms' | translate }}</p>
    <p>|</p>
    <p (click)="service.navigate('policy')">{{ 'footer.privacy-policy' | translate }}</p>
    <p>|</p>
    <p (click)="service.navigate('dnsmpi')">{{ 'dnsmpi.checkbox-text' | translate }}</p>
  </div>

</div>
