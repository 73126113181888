import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, filter, map, startWith } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { AppListenerService, currentLang, getPathname, isBrandIframeWidget } from '@hiptraveler/common';

@Component({
  selector: 'experience-finder',
  templateUrl: './experience-finder.component.html',
  styleUrls: ['./experience-finder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExperienceFinderComponent implements OnInit {

  @Select(BrandState.brandCampaign) brandCampaign$: Observable<Partial<BrandCampaign> | null>;

  constructor(
    private router: Router,
    public appListener: AppListenerService,
    public stateService: ExperienceFinderStateService
  ) { }

  ngOnInit(): void {
    this.stateService.stateObserver();
  }

  get activeBubble$(): Observable<boolean> {
    return this.appListener.clientWidth$.pipe(
      map((clientWidth: number) => clientWidth < 1100)
    );
  }

  get iframeWidget$(): Observable<any> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => e.url),
      startWith(this.router.url),
      map(url => getPathname(url)),
      map(e => e === `/${currentLang()}` && isBrandIframeWidget())
    )
  }

}
