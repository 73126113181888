<div class="related-tours ht-dialog-elevation">
      
  <h2 translate="search-result.related-tours"></h2>

  <ng-container *ngIf="service.resultRelatedTours$ | async as tours;else noRelatedTours">

    <div class="tours--item" matRipple *ngFor="let result of tours;" [@opacityFade] (click)="openTour(result)">

      <img [lazyLoad]="result?.imgUrl || ''" defaultImage="assets/img/blank.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="result.imgCategory">  
  
      <p class="tours--item-title">{{ result.name }}</p>
  
      <star-ratings [rating]="result.tourData?.overallRating || 0" [size]="15" [offsetY]="-3"></star-ratings>
  
      <p class="tours--item-rating"><span class="ht-digit">{{ result.tourData?.numRating | toLocale | reviewText }}</span></p>
  
      <p class="tours--item-amount"><span class="ht-digit">{{ result.tourData?.price?.currency | parseCurrency: result.tourData?.price?.amount }}</span></p>
  
      <div class="tours--item-space"></div>
  
    </div>

  </ng-container>
  <ng-template #noRelatedTours>
    <mat-progress-spinner class="primary" diameter="60" mode="indeterminate" style="margin-bottom: 24px;"></mat-progress-spinner>
  </ng-template>

</div>
