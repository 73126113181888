import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ContentEditableService } from './content-editable.service';
import { PromptDialogActionService } from '@hiptraveler/dialogs/prompt-dialog';

import { EditableSelectionsComponent } from './editable-selections.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatTooltipModule,
  TranslateModule
];

const PROVIDERS = [
  ContentEditableService,
  PromptDialogActionService
];

const COMPONENTS = [
  EditableSelectionsComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  providers: [
    PROVIDERS
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    EditableSelectionsComponent 
  ]
})
export class EditableSelectionsModule { }
