import { Injectable } from '@angular/core';
import { ScrollListenerService } from '@hiptraveler/common';
import { BehaviorSubject, Observable, map, switchMap, tap } from 'rxjs';
import { RegistrationFormStateService } from '../common';

@Injectable()
export class StepperHeaderService {

  showOverlayStepper$$ = new BehaviorSubject<boolean>(false);

  constructor(
    public scrollListener: ScrollListenerService,
    private stateService: RegistrationFormStateService
  ) { }

  get showOverlayStepper$(): Observable<boolean> {
    return this.showOverlayStepper$$.asObservable().pipe(
      switchMap((showOverlayStepper: boolean) => this.stateService.submitProcessing$$.asObservable().pipe(
        map((submitProcessing: boolean) => showOverlayStepper && !submitProcessing)
      ))
    );
  }

  scrollObserver(): void {
    this.scrollListener.scrollPosition$.pipe(
      map((value: number) => value > 620),
      tap((activated: boolean) => this.showOverlayStepper$$.next(activated))
    ).subscribe();
  }

}
