import { minifyHTML } from "@hiptraveler/common";

export const serverSideShellHTML: string = minifyHTML(`
  <div class="toolbar" style="box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12);display: flex;height: 50px;align-items: center;justify-content: space-between;">

    <div style="width: 200px;display: flex;justify-content: center;">
      <!-- <img style="height: 30.95px;" src="hiptraveler-logo-full-default.webp" alt="HipTraveler Logo"> -->
    </div>

    <div style="width: 75px;display: flex;justify-content: center;cursor: pointer;">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#3f3f3f" viewBox="0 0 24 24" style="cursor: pointer;"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z"></path></svg>
    </div>

  </div>

  <div class="banner" style="background: #ababab;height: 500px;width: 100vw;border-bottom-left-radius: 140px;"></div>

  <style>
    .banner {
      @media screen and (max-width: 1100px) {
        height: 400px !important;
      }
      @media screen and (max-width: 700px) {
        height: 350px !important;
        border-bottom-left-radius: 60px !important;
      }
      @media screen and (max-width: 500px) {
        height: 300px !important;
      }
    }
  </style>
`.trim());
