import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

import { ProfileFormModule } from './profile-form/profile-form.module';
import { SettingsFormModule } from './settings-form/settings-form.module';
import { NotificationsFormModule } from './notifications-form/notifications-form.module';
import { ProfessionalProfileFormModule } from './professional-profile-form/professional-profile-form.module';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { UserSettingsComponent } from './user-settings.component';
import { AutoWidthDirective } from './auto-width.directive';

const MODULES = [
  CommonModule,
  MatRippleModule,
  TranslateModule
];

const LIBRARIES = [
  ProfileFormModule,
  SettingsFormModule,
  NotificationsFormModule,
  ProfessionalProfileFormModule,
  ButtonsModule
];

const COMPONENTS = [
  UserSettingsComponent
];

const DIRECTIVES = [
  AutoWidthDirective
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    UserSettingsComponent
  ]
})
export class UserSettingsModule { }
