import { Store } from "@ngxs/store";

import { UserAction, UserState } from "@hiptraveler/data-access/user";

export function updateUserItineraryByNewItineraryResponse(store: Store, id: string, response: { newPageTitle: string, newTitle: string }): void {
  let itineraries = store.selectSnapshot(UserState.itineraries) || [];
  itineraries = itineraries.map(itinerary => {
    if (itinerary.id === id) {
      response?.newPageTitle && (itinerary.pageTitle = response.newPageTitle)
      response?.newTitle && (itinerary.title = response.newTitle);
    }
    return itinerary;
  }).slice(0);
  store.dispatch(new UserAction.UpdateItineraryData(itineraries));
}
