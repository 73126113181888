import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AboutResultComponent } from './about-result.component';
import { AboutResultDirective } from './about-result-description.directive';

const MODULES = [
  CommonModule,
  TranslateModule
];

const COMPONENTS = [
  AboutResultComponent
];

const DIRECTIVES = [
  AboutResultDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    AboutResultComponent
  ]
})
export class AboutResultModule { }
