import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

import { ActionButtonComponent } from './action-button/action-button.component';
import { CarouselButtonComponent } from './carousel-button/carousel-button.component';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatIconModule
];

const COMPONENTS = [
  ActionButtonComponent,
  CarouselButtonComponent
];

export * from './action-button/action-button.component';

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ActionButtonComponent,
    CarouselButtonComponent
  ]
})
export class ButtonsModule { }
