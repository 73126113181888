
<p>Loading Experience Finder for you ...</p>

<div class="request-pending-skeleton--loaders">
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'border-radius': '4px' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'border-radius': '4px' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'border-radius': '4px' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'border-radius': '4px' }"></ngx-skeleton-loader>
</div>

