import { currentLang } from '@hiptraveler/common';

export function getDataLocale(): string {
  switch (currentLang()) {
    case 'en':  return 'en-US';
    case 'es':  return 'es-MX';
    case 'de':  return 'de-DE';
    case 'fr':  return 'fr-FR';
    case 'it':  return 'it-IT';
    case 'zh':  return 'zh-CN';
    default:    return 'en-US';
  }
}

export function getDataMarket(): string {
  switch (currentLang()) {
    case 'en':  return 'UK';
    case 'es':  return 'ES';
    case 'de':  return 'DE';
    case 'fr':  return 'FR';
    case 'it':  return 'IT';
    case 'zh':  return 'HK';
    default:    return 'UK';
  }
}

export function getDataCurrency(): string {
  switch (currentLang()) {
    case 'en':  return 'USD';
    case 'es':  return 'EUR';
    case 'de':  return 'EUR';
    case 'fr':  return 'EUR';
    case 'it':  return 'EUR';
    case 'zh':  return 'CNY';
    default:    return 'USD';
  }
}
