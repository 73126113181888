
<ng-container *ngIf="navbarVisibility">
  <div class="site-nav--navbar-rounded" [class.mat-elevation-z2]="!((navbarControl.navbarToolbarVisibility$ | async) && (service.brandCampaign$ | async)) && (navbarControl.navbarElevationState$ | async)" [ngClass]="{ mobile: mobileView }">
    <navbar></navbar>
  </div>
</ng-container>

<ng-container *ngIf="(appListener.clientWidth$ | async)! < 700">
  <div #bubbleElevation class="bottom-nav--experience-finder-bubble-elevation mat-elevation-z4"></div>
  <bottom-navbar (visibilityChanged)="bubbleElevation.style.display = $event"></bottom-navbar>
</ng-container>

<ng-container *ngIf="(navbarControl.navbarToolbarVisibility$ | async) && (service.brandCampaign$ | async)">
  <div class="site-nav--toolbar-rounded" [@slideInFromTop] [ngClass]="{ mobile: mobileView }">
    <toolbar travelSelection connectSelection swipeIcon></toolbar>
  </div>
</ng-container>
