import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RequestCancellationService, SearchLocationService, currentLang, requestCancellationIgnoredEndpointsKey } from '@hiptraveler/common';
import { SearchService } from './search.service';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  viewProviders: [ SearchService ]
})
export class SearchComponent implements OnInit, OnDestroy {

  connectVisibility: boolean = !this.searchLocation.data;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private requestCancellation: RequestCancellationService,
    public service: SearchService,
    private efStateService: ExperienceFinderStateService,
    public searchLocation: SearchLocationService,
  ) { }

  ngOnInit(): void {
    this.service.observe();
    this.service.observeNavigationLocation();
    this.service.observeComposePageLocationState();

    this.service.searchLocationState$.subscribe((state: boolean) => {
      this.connectVisibility = !state;
    });
  }

  ngOnDestroy(): void {
  
    this.route.snapshot.data['homepage'] === true
      && this.efStateService.overlayState$$.next(false);

    if (sessionStorage.getItem(requestCancellationIgnoredEndpointsKey)) return;
    this.requestCancellation.cancelAllRequests();
    this.requestCancellation.cancelAllSearchRequests();
  }

  navigate(path: string): void {
    this.router.navigate([ currentLang(), path ]);
  }

}
