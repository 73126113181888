
<ng-container *ngIf="highlightImage === '' || bannerImage === ''">
  <img imageLayout [lazyLoad]="sourceImg" defaultImage="assets/img/blank-white.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="imageAlt" [dialogType]="dialogType" [ngClass]="{ 'highlight-loaded': !loader, 'banner-loaded': !loader }" (onStateChange)="lazyloadStateChangeFn($event)" (click)="selected.emit()">
</ng-container>

<ng-container *ngIf="sliderImage === ''">
  <img [lazyLoad]="sourceImg" defaultImage="assets/img/blank-white.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="imageAlt" class="asset-image--highlight-slider-image" [ngClass]="{ 'slider-loaded': !loader }" (onStateChange)="lazyloadStateChangeFn($event)" (click)="selected.emit()">
</ng-container>

<ng-container *ngIf="selectImage === ''">
  <img [lazyLoad]="sourceImg" defaultImage="assets/img/blank-white.webp" errorImage="assets/img/blank.webp" draggable="false" [alt]="imageAlt" class="asset-image--highlight-select-image" [ngClass]="{ 'select-loaded': !loader }" (onStateChange)="lazyloadStateChangeFn($event)" (click)="selected.emit()">
</ng-container>

<div class="asset-image--skeleton asset-image--highlight-image" [ngClass]="{ 'highlight-loading': loader }" *ngIf="highlightImage === ''">
  <div class="asset-image--skeleton-placeholder"></div>
  <ngx-skeleton-loader count="1" appearance="line" animation="pulse"
    [theme]="{ 'border-radius': '4px' }">
  </ngx-skeleton-loader>
</div>

<div class="asset-image--skeleton asset-image--banner-image" [ngClass]="{ 'banner-loading': loader }" *ngIf="bannerImage === ''">
  <div class="asset-image--skeleton-placeholder"></div>
  <ngx-skeleton-loader count="1" appearance="line" animation="pulse"
    [theme]="{ 'border-radius': '4px' }">
  </ngx-skeleton-loader>
</div>

<div class="asset-image--skeleton asset-image--slider-image" [ngClass]="{ 'slider-loading': loader }" *ngIf="sliderImage === ''">
  <div class="asset-image--skeleton-placeholder"></div>
  <ngx-skeleton-loader count="1" appearance="line" animation="pulse"
    [ngStyle]="{ height: '75px' }"
    [theme]="{ 'border-radius': '4px', height: '72px', width: '100px' }">
  </ngx-skeleton-loader>
</div>

<div class="asset-image--skeleton asset-image--select-image" [ngClass]="{ 'select-loading': loader }" *ngIf="selectImage === ''">
  <div class="asset-image--skeleton-placeholder"></div>
  <ngx-skeleton-loader count="1" appearance="line" animation="pulse"
    [theme]="{ 'border-radius': '4px' }">
  </ngx-skeleton-loader>
</div>
