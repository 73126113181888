<div class="auth-menu" *ngIf="(service.brandCampaign$ | async) as brandCampaign">

  <div class="auth-menu--navbar">

    <button mat-icon-button (click)="stateService.dialogView$$.next('main-menu')">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="auth-menu--navbar-image">

      <img [src]="brandCampaign?.cLogo?.headerImg" [alt]="brandCampaign?.cTitle">
      <powered-by-hiptraveler></powered-by-hiptraveler>

    </div>
  </div>

  <div class="auth-menu--display">
    
    <img
      class="auth-menu--display-photo object"
      [src]="customStyles?.tripPlannerSvg"
      *ngIf="(brandCampaign?.campaignCustom?.customCss) as customStyles;else defaultImageView">

    <ng-template #defaultImageView>
      <div class="auth-menu--display-photo background" [ngStyle]="{ 'background-image': defaultImage }"></div>
    </ng-template>

  </div>

  <form class="auth-menu--selection" (keyup.enter)="service.signInWithEmailAndPassword()" [formGroup]="service.form">

    <p class="selection--info" translate="exp-finder.create-profile-prompt"></p>

    <p class="selection--label" translate="exp-finder.continue-with"></p>

    <div class="selection--actions">
      <div class="selection--action" matRipple (click)="socialMedia.signInWithSocialMedia('facebook')">
        <mat-icon svgIcon="common.facebook-square"></mat-icon>
        <p>Facebook</p>
      </div>
      <div class="selection--action" matRipple (click)="socialMedia.signInWithSocialMedia('google')">
        <mat-icon svgIcon="common.google-default"></mat-icon>
        <p>Google</p>
      </div>
    </div>

    <p class="selection--label" translate="exp-finder.use-email"></p>

    <mat-form-field underlined appearance="outline">
      <mat-label>{{ 'exp-finder.email' | translate }}</mat-label>
      <input matInput type="text" autocomplete="username" [placeholder]="'exp-finder.placeholder-enter-here' | translate" name="username" formControlName="username">
    </mat-form-field>

    <mat-form-field underlined appearance="outline">
      <mat-label>{{ 'exp-finder.password' | translate }}</mat-label>
      <input matInput type="password" autocomplete="current-password" [placeholder]="'exp-finder.placeholder-enter-here' | translate" name="password" formControlName="password">
    </mat-form-field>

    <div class="selection-form-actions">
      <div class="selection-form-action">
        <p (click)="createAccount()" translate="exp-finder.create-account"></p>
      </div>
      <div class="selection-form-action">
        <p (click)="authDialog.open('forgot-password')" translate="exp-finder.forgot-password"></p>
      </div>
    </div>
    
  </form>

  <action-button type="submit" (click)="service.signInWithEmailAndPassword()">{{ 'exp-finder.button-text-login' | translate }}</action-button>

</div>
