import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Subject, EMPTY, Observable, catchError, filter, switchMap, take } from 'rxjs';

import { AuthState } from '@hiptraveler/data-access/auth';
import { ProfileAction } from '@hiptraveler/data-access/profile';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { UserState, UserStateModel } from '@hiptraveler/data-access/user';

const ASSET_IMAGE: string = 'https://cdn.filestackcontent.com/A5yG40niNR3q9y7GBglCLz/resize=width:1200/https://hiptraveler.s3.amazonaws.com/site/main/images/Destination-Specialist-1200.jpg';

@Injectable()
export class TravelAgentRegisterService implements OnDestroy {

  @Select(UserState.authenticated) authenticated$: Observable<boolean>;

  destinationSpecialistImg: string = ASSET_IMAGE;

  subscription$ = new Subject<void>();

  constructor(
    private store: Store,
    private authDialog: AuthDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  verifyAuthentication(): void {
    this.store.selectSnapshot(AuthState.authenticated) || this.authDialog.open();
  }

  validateUser(): void {
    this.store.select(UserState.state).pipe(
      filter(Boolean),
      filter((userState) => (userState.userType === 'htAgent')),
      switchMap((userState: UserStateModel) => this.store.dispatch(new ProfileAction.GetTravelAgent(userState.taId)).pipe(
        catchError(() => EMPTY)
      )),
      take(1)
    ).subscribe();
  }

}
