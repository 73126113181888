import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import * as BookApi from './interfaces';
import { RequestByIdDto, handleErrorAndThrow } from '@hiptraveler/data-access/api';
import { brandCampaignId, currentLang } from '@hiptraveler/common';
import { getHotelInformationParam } from './utils';

@Injectable({
  providedIn: 'root'
})
export class BookApiService {

  constructor(
    private http: HttpClient
  ) { }

  getHotelInformation({ id }: RequestByIdDto): Observable<BookApi.GetHotelInformationResponse> {
    return this.http.get<BookApi.GetHotelInformationResponse>(`/trip-planner/hotelInfo/${brandCampaignId()}/${id}/${currentLang()}`, { params: getHotelInformationParam() }).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getTripAdvisorData({ id }: RequestByIdDto): Observable<BookApi.GetTripAdvisorDataResponse> {
    return this.http.get<BookApi.GetTripAdvisorDataResponse>(`/trip-planner/ta-ratings/image/${id}/${currentLang()}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

  getResultRelatedTours({ id }: RequestByIdDto): Observable<BookApi.GetResultRelatedToursResponse> {
    return this.http.get<BookApi.GetResultRelatedToursResponse>(`/trip-planner/getRelatedTours/image/${id}/${currentLang()}`).pipe(
      tap(handleErrorAndThrow)
    );
  }

}
