import { Component, OnDestroy, OnInit } from '@angular/core';

import { UploadsTabService } from './uploads-tab.service';
import { RequestCancellationService } from '@hiptraveler/common';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { DataAccessService, skeletons } from '../../../common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'uploads-tab',
  templateUrl: './uploads-tab.component.html',
  viewProviders: [ UploadsTabService, SearchResultDialogActionService ],
  animations: [ opacityFade(), opacityFadeIn() ]
})
export class UploadsTabComponent implements OnInit, OnDestroy {

  skeletons: number[] = skeletons;

  constructor(
    public service: UploadsTabService,
    private requestCancellation: RequestCancellationService,
    public dataAccess: DataAccessService
  ) { }

  ngOnInit(): void {
    this.service.invalidStateObserver();
    this.dataAccess.getProfileUploadResults();
  }
  
  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
    this.dataAccess.resetProfileStateByField([ 'imageResults', 'pagination' ]);
    this.requestCancellation.cancelAllProfileSearchRequests();
  }

}
