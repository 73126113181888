import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MatIcon } from '@angular/material/icon';
import { Observable, Subject, filter, firstValueFrom, map, takeUntil } from 'rxjs';
import { pick, uniqBy } from 'lodash';

import { AddReservationDto, HotelResultData, ImageResultData, ItineraryReservation } from '@hiptraveler/data-access/api';
import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SearchResultDialogActionService } from '@hiptraveler/dialogs/search-result-dialog';
import { ComposePrivateNoteDialogActionService } from '@hiptraveler/dialogs/compose-private-note-dialog';
import { clientVID, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';

@Injectable()
export class ChecklistService implements OnDestroy {

  @Select(ItineraryState.itineraryReservations) itineraryReservations$: Observable<ItineraryReservation[] | null>;

  subscription$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private store: Store,
    public searchResultDialog: SearchResultDialogActionService,
    private composePrivateNote: ComposePrivateNoteDialogActionService
  ) { }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  /**
   * Retrieves an observable stream of unique ImageResultData objects for the current itinerary date.
   * 
   * @returns An observable emitting an array of unique ImageResultData objects, or null if no data is available.
   */
  get tours$(): Observable<ImageResultData[] | null> {
    return this.store.select(ItineraryState.actDate).pipe(
      filter(Boolean),
      map(arr => [].concat(...(arr?.map(x => x?.ImgArray || []) as any) || [])),
      map(arr => uniqBy(arr, 'name'))
    );
  }

  /**
   * Retrieves an observable stream of unique HotelResultData objects for the current itinerary date.
   * 
   * @returns An observable emitting an array of unique HotelResultData objects, or null if no data is available.
   */
  get hotels$(): Observable<HotelResultData[] | null> {
    return this.store.select(ItineraryState.actDate).pipe(
      filter(Boolean),
      map(arr => [].concat(...(arr?.map(x => x?.HotelArray || []) as any) || [])),
      map(arr => uniqBy(arr, 'name'))
    );
  }

  /**
   * Initializes the state of a host element, initially disabling it and enabling it later based on observable data.
   * 
   * @param element The host element to modify.
   */
  initializeHostState(element: HTMLElement): void {
    this.renderer.addClass(element, 'disabled');
    this.itineraryReservations$.pipe(
      takeUntil(this.subscription$)
    ).subscribe(() => this.renderer.removeClass(element, 'disabled'));
  }

  /**
   * Opens a private note dialog for a given result item, considering its booking status.
   * 
   * @param resultData The result object containing data for the note.
   */
  openPrivateNote(resultData: HotelResultData | ImageResultData, type: 'image' | 'hotel'): void {

    const resultId = resultData?.id || '';
    const reservations = this.store.selectSnapshot(ItineraryState.itineraryReservations) || [];
    const reservation = reservations.find(e => e?.objId === resultId);

    this.composePrivateNote.open({
      dbId: this.store.selectSnapshot(ItineraryState.basicInfo)?.id,
      objId: reservation?.objId || resultData?.id,
      param: 'itinerary',
      objName: reservation?.objName || resultData?.name,
      location: reservation?.location || resultData?.address?.city,
      isBooked: reservation?.booked,
      checkIn: reservation?.checkIn,
      checkOut: reservation?.checkOut,
      exists: !!reservation, type,
      ...pick(reservation, [ 'notes', 'reference', 'contact' ])
    });
  }

  /**
   * Toggles the "booked" state of a tour/hotel checklist item.
   * 
   * @param element The MatIcon element representing the checklist item.
   * @param data The HotelResultData object containing the hotel data.
   * @param state The new booked state to set (true or false).
   */
  toggleChecklistItem(element: MatIcon, data: HotelResultData | ImageResultData): void {
    this.renderer.addClass(element._elementRef.nativeElement, 'pending');
    this.renderer.setStyle(element._elementRef.nativeElement, 'pointer-events', 'none');
    this.dispatchPayload(element, {
      objId: data.id,
      objName: data?.name || '',
      location: data?.address?.city || '',
      type: (data as HotelResultData)?.type === 'hotel' ? 'hotel' : 'image',
      vId: clientVID()
    });
  }

  /**
   * Dispatches an action to add a reservation to the itinerary.
   * 
   * @param element The MatIcon element associated with the action.
   * @param value The partial AddReservationDto object containing reservation details.
   */
  private async dispatchPayload(element: MatIcon, value: Partial<AddReservationDto>): Promise<void> {

    const reservations = this.store.selectSnapshot(ItineraryState.itineraryReservations) || [];
    const reservation = reservations.find(e => e?.objId === value.objId);

    const updateReservationPayload: Partial<AddReservationDto> = {
      ...reservation,
      id: reservation?.id || 'untitled',
      param: getWindowRef()[requestEndpointParamValue],
      isBooked: !reservation?.booked,
      type: value.type
    };
    
    const createReservationPayload: Partial<AddReservationDto> = {
      id: 'untitled',
      dbId: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '',
      param: getWindowRef()[requestEndpointParamValue],
      checkIn: { date: '', time: '' },
      checkOut: { date: '', time: '' },
      notes: '', reference: '', contact: '', vId: clientVID(),
      isBooked: true,
      ...value
    };

    try {
      await firstValueFrom(this.store.dispatch(new ItineraryAction.AddReservation(
        (reservation ? updateReservationPayload : createReservationPayload) as AddReservationDto
      )));
    } catch (error) {
    } finally {
        this.renderer.removeClass(element._elementRef.nativeElement, 'pending');
        this.renderer.setStyle(element._elementRef.nativeElement, 'pointer-events', 'all');
    }
  }

}
