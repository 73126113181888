import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulbCircleModule } from './blub-circle/blub-circle.module';

import { ProcessingViewComponent } from './processing-view.component';

const MODULES = [
  CommonModule
];

const LIBRARIES = [
  BulbCircleModule
];

const COMPONENTS = [
  ProcessingViewComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    ProcessingViewComponent
  ]
})
export class ProcessingViewModule { }
