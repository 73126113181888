import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppListenerService, NavbarButtonData, NavbarControlStateService, RequestCancellationService, SearchPageControlStateService } from '@hiptraveler/common';

@Component({
  selector: 'app-compose',
  template: `
    <div class="app-compose router-page-container">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [`
    .app-compose {
      padding-top: 48px;
    }
  `]
})
export class ComposeComponent implements OnInit, OnDestroy {

  constructor(
    private appListenerService: AppListenerService,
    private navbarControl: NavbarControlStateService,
    private requestCancellation: RequestCancellationService,
    private searchPageControl: SearchPageControlStateService
  ) { }
  
  ngOnInit(): void {
    setTimeout(() => this.focusMode('on'));
    this.setNavbarButtons();
    this.searchPageControl.islandHopResponse$$.next(null);
  }
  
  ngOnDestroy(): void {
    setTimeout(() => this.focusMode('off'));
    this.navbarControl.setActiveNavbarButtons([]);
    this.requestCancellation.cancelAllRequests();
  }

  /**
   * Updates the navbar UI state.
   * Toggles the appending of the page navigation selections to the navbar and the default navbar UI state.
   * 
   * @param {'on' | 'off'} mode - on: append, off: default navbar UI
   * @returns {void}
   */
  focusMode(mode: 'on'|'off'): void {
    this.appListenerService.experienceFinderVisibility$$.next(mode === 'off');
    this.appListenerService.appFooterVisibility$$.next(mode === 'off');
    this.navbarControl.navbarLogoVisibility$$.next(mode === 'off');
    this.navbarControl.navbarToolbarVisibility$$.next(mode === 'off');
    this.navbarControl.navbarSearchBarVisibility$$.next(mode === 'off');
    this.navbarControl.allowMobileView$$.next(mode === 'off')
  }

  /**
   * Sets the active buttons to be displayed on the navbar.
   * Determines the appropriate buttons based on whether the user is composing a new story.
   *
   * @returns {void}
   */
  setNavbarButtons(): void {
    const navbarButtons: NavbarButtonData[] = [
      {
        name: 'navbar.action-text-preview', size: 'small', theme: 'outlined', action: 'composePreview',
        responsiveIcon: 'preview', responsiveText: 'navbar.action-text-preview' },
      {
        name: 'navbar.action-text-save', size: 'small', action: 'composeSave',
        responsiveIcon: 'save', responsiveText: 'navbar.action-text-save' }
    ];
    this.navbarControl.setActiveNavbarButtons(navbarButtons);
  }

}
