import { Component } from '@angular/core';

import { TripDetailContentService } from './trip-detail-content.service';
import { opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'trip-detail-content',
  templateUrl: './trip-detail-content.component.html',
  styleUrls: ['./trip-detail-content.component.scss'],
  viewProviders: [ TripDetailContentService ],
  animations: [ opacityFadeIn() ]
})
export class TripDetailContentComponent {

  constructor(
    public service: TripDetailContentService
  ) { }

}
