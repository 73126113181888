import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { HomepageBannerComponent } from './homepage-banner.component';
import { Headline1Pipe } from './headline-1.pipe';
import { Headline2Pipe } from './headline-2.pipe';

const MODULES = [
  CommonModule,
  NgxSkeletonLoaderModule,
  LazyLoadImageModule
];

const COMPONENTS = [
  HomepageBannerComponent
];

const PIPES = [
  Headline1Pipe,
  Headline2Pipe
];


@NgModule({
  declarations: [
    COMPONENTS,
    PIPES
  ],
  imports: [
    MODULES
  ],
  exports: [
    HomepageBannerComponent
  ]
})
export class HomepageBannerModule { }
