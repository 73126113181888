import { Injectable } from '@angular/core';

@Injectable()
export class RequestPendingSkeletonService {

  toggleChildrenVisibility(parentElement: HTMLElement, hide: boolean) {
    const children: HTMLElement[] = parentElement.children as any;
    const lastChildIndex = children.length - 1;
  
    for (let i = 0; i < children.length; i++) {
      if (i !== lastChildIndex) {
        children[i].style.display = hide ? 'none' : '';
      }
    }
  }

}
