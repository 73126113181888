import { Directive, HostListener, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FilestackService, FSProgressEvent, PickerFileMetadata } from '@hiptraveler/core/filestack';
import { SnackbarService } from '@hiptraveler/snackbar';

@Directive({
  selector: '[uploadImages]'
})
export class UploadImagesDirective {

  constructor(
    @Inject(MAT_DIALOG_DATA) public form: FormGroup,
    private dialog: MatDialogRef<any>,
    private filestack: FilestackService,
    private snackbar: SnackbarService
  ) { }

  @HostListener('click')
  addGalleryImages(): void {

    this.snackbar.open({ message: 'Please wait.', duration: Infinity });

    const onOpen = () => {
      this.dialog.disableClose = true;
      console.log('@@@ ', 'picker opened');
      setTimeout(() => this.snackbar.dismiss(), 500);
    };
    const onCancel = () => {
      console.log('@@@ ', 'picker cancelled');
    };
    const onClose = () => {
      this.dialog.disableClose = false;
      console.log('@@@ ', 'onClose');
    };
    const onFileUploadFinished = (metadata: PickerFileMetadata) => {
      const control = new FormControl(metadata.url);
      const galleryImages = this.form.get('tourMedia') as FormArray;
      galleryImages.push(control);
    };
    const onFileUploadProgress = (_: unknown, event: FSProgressEvent) => {
      console.log('@@@ ', 'onFileUploadProgress');
    };
    const onFileSelected = () => {
      console.log('@@@ ', 'onFileSelected');
    };
    const onFileUploadCancel = () => {
      console.log('@@@ ', 'onFileUploadCancel');
    };
    const onFileUploadFailed = () => {
      console.log('@@@ ', 'onFileUploadFailed');
    };
    const onFileUploadStarted = () => {
      console.log('@@@ ', 'onFileUploadStarted');
    };
    const onUploadDone = () => {
      console.log('@@@ ', 'onUploadDone');
    };
    const onUploadStarted = () => {
      console.log('@@@ ', 'onUploadStarted');
    };

    this.filestack.openFilestackPicker({
      onOpen: onOpen,
      onCancel: onCancel,
      onClose: onClose,
      onFileUploadFinished: onFileUploadFinished,
      onFileUploadProgress: onFileUploadProgress,
      onFileSelected: onFileSelected,
      onFileUploadCancel: onFileUploadCancel,
      onFileUploadFailed: onFileUploadFailed,
      onFileUploadStarted: onFileUploadStarted,
      onUploadDone: onUploadDone,
      onUploadStarted: onUploadStarted,
      maxFiles: 24
    });
  }

}
