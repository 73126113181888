import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseCurrency'
})
export class ParseCurrencyPipe implements PipeTransform {

  transform(currency?: string, amount?: number, decimal?: boolean): string {
  
    const numberFormat = (new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency || 'USD',
    })).format(amount || 0);

    return decimal ? numberFormat.split('.')[0] : numberFormat;
  }

}

@NgModule({
  declarations: [ ParseCurrencyPipe ],
  exports:      [ ParseCurrencyPipe ]
})
export class ParseCurrencyPipeModule { }
