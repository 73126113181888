import { StateContext } from '@ngxs/store';
import { findIndex } from 'lodash';

import * as API from '@hiptraveler/data-access/api';
import { ItineraryStateModel } from '..';

export function addReservation(ctx: StateContext<ItineraryStateModel>, response: API.AddReservationResponse): void {

  const itineraryReservations = [ ...ctx.getState()?.itineraryReservations || [] ];
  const index = findIndex(itineraryReservations, { objId: response?.reservation?.objId });

  if (index === -1) {
    itineraryReservations.push(response?.reservation);
  } else {
    itineraryReservations[index] = response?.reservation;
  }
  
  ctx.patchState({ itineraryReservations });
}
