<ng-container *ngIf="brandCampaign$ | async">

  <div
    class="experience-finder-overlay"
    [class.transparent]="stateService.transparentBackdrop$ | async"
    (click)="stateService.overlayState$$.next(false)" 
    *ngIf="(stateService.overlayState$ | async) && !(stateService.transparentBackdrop$ | async)">
  </div>
  
  <div
    class="experience-finder"
    [ngClass]="{
      active: (activeBubble$ | async) || (stateService.overlayPositionState$ | async),
      'iframe-widget': iframeWidget$ | async
    }">
    
    <experience-finder-bubble></experience-finder-bubble>
  
    <experience-finder-screen
      [ngClass]="{ active: stateService.overlayState$ | async }">
    </experience-finder-screen>
  
  </div>
  
  <div class="experience-finder-mobile-wrapper" [ngClass]="{ active: stateService.overlayState$ | async }">
    <experience-finder-mobile></experience-finder-mobile>
  </div>

</ng-container>
