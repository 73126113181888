import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ItineraryAction, ItineraryStateField } from '@hiptraveler/data-access/itinerary';
import { removeVID, SearchPageControlStateService } from '@hiptraveler/common';

@Injectable()
export class CommonService {

  constructor(
    private store: Store,
    private searchPageControl: SearchPageControlStateService
  ) { }

  removeOpenTripProcess(): void {
    const fields: ItineraryStateField[] = [ 'basicInfo', 'actDateMap', 'itineraryReservations', 'travelAgents' ];
    this.store.dispatch(new ItineraryAction.PartialResetItineraryState(fields));
    this.searchPageControl.activityDate$$.next(null);
    removeVID();
  }

}
