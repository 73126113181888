import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { ButtonsModule } from '@hiptraveler/ui/buttons';

import { EmailShareDialogComponent } from './email-share-dialog.component';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatRippleModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatChipsModule,
  MatDialogModule,
  TranslateModule
];

const LIBRARIES = [
  DialogCloseButtonModule,
  ButtonsModule
];

const COMPONENTS = [
  EmailShareDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ],
})
export class EmailShareDialogModule { }
