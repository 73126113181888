<ng-container *ngIf="data">
  <div class="trip-advisor-view" [@opacityFade]>
    <mat-icon svgIcon="search-result-dialog-common.trip-advisor-svg" (click)="navigateToExternal(data.webUrl || '')"></mat-icon>

    <div class="trip-advisor-view--categories" (click)="navigateToExternal(data.webUrl || '')">
      <ng-container *ngFor="let item of data.attractionTypes;let isFirst = first">
        <p *ngIf="!isFirst">•</p>
        <p>{{ item }}</p>
      </ng-container>
    </div>

    <div class="trip-advisor-view--review" (click)="navigateToExternal(data.webUrl || '')">
      <img [src]="data.ratingImgUrl" alt="Trip Advisor rating image">
      <p>{{ 'search-result.based-on' | translate }} <span class="ht-digit">{{ data.numReviews }} {{ 'search-result.reviews' | translate }}</span></p>
    </div>
    <p class="ht-digit" (click)="navigateToExternal(data.webUrl || '')">{{ data.ranking }}</p>
    <p class="ht-digit" (click)="navigateToExternal(data.webUrl || '')">{{ data.subRanking }}</p>
  </div>
</ng-container>
