import { Directive } from "@angular/core";
import { FormArray, FormControl } from "@angular/forms";

@Directive()
export class ListView {

  actionState: boolean;
  items: string[] = [];
  item: string;

  add(formArray: FormArray): void {

    if (this.items.includes(this.item)) {
      this.item = '';
      return;
    }

    formArray.push(new FormControl(this.item));
    this.items.push(this.item);
    this.item = '';
    this.actionState = false;
  }

  remove(formArray: FormArray, index: number): void {
    this.items.splice(index, 1);
    formArray.removeAt(index);
  }

}
