import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { DialogCloseButtonModule } from '@hiptraveler/ui/dialog-close-button';
import { MouseDragDirectiveModule } from '@hiptraveler/common';

import { OverlayCarouselDialogComponent } from './overlay-carousel-dialog.component';

const MODULES = [
  CommonModule,
  LazyLoadImageModule
];

const LIBRARIES = [
  ButtonsModule,
  DialogCloseButtonModule,
  MouseDragDirectiveModule
];

const COMPONENTS = [
  OverlayCarouselDialogComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS
  ]
})
export class OverlayCarouselDialogModule { }
