import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CookiePolicyBannerComponent } from './cookie-policy-banner.component';

const MODULES = [
  CommonModule,
  RouterModule,
  MatIconModule,
  TranslateModule
];

const COMPONENTS = [
  CookiePolicyBannerComponent
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS
  ],
  exports: [
    CookiePolicyBannerComponent
  ],
})
export class CookiePolicyBannerModule { }
