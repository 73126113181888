import { Injectable } from '@angular/core';
import { LangChangeEvent as _LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { currentLang } from '@hiptraveler/common';

export type LangChangeEvent = _LangChangeEvent;

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private translate: TranslateService
  ) { }

  getText(key: string, value?: string): string {
    if (value) {
      return this.storeTranslations?.[key]?.[value] || value;
    } else {
      return this.storeTranslations?.[key] || value;
    }
  }

  get onLangChange$(): Observable<LangChangeEvent> {
    return this.translate.onLangChange.asObservable();
  }

  private get storeTranslations(): Record<string, any> {
    const data = this.translate.store.translations;
    return data?.[currentLang()] || {};
  }

}
