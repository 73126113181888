import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';

@Directive({
  selector: '[actionPanelStyles]'
})
export class ActionPanelStylesDirective implements AfterViewInit {

  @Input() actionPanelStyles: string;
  @Input() activityDate: ActivityDateData[];

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngAfterViewInit(): void {
    if (this.actionPanelStyles !== '') return;

    const count = (this.activityDate?.length || 0) + 1;
    const element = this.element.nativeElement.parentElement;
    element!.style.height = `${(count * 40) + 16}px`;
  }

}
