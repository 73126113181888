import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BasicInfoData } from '@hiptraveler/data-access/api';
import { RequestCancellationService, currentLang } from '@hiptraveler/common';
import { SnackbarService } from '@hiptraveler/snackbar';
import { skeletons, DataAccessService, ProfileStateService } from '../../../common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'stories-tab',
  templateUrl: './stories-tab.component.html',
  animations: [ opacityFade(), opacityFadeIn() ]
})
export class StoriesTabComponent implements OnInit, OnDestroy {

  skeletons: number[] = skeletons;

  constructor(
    private router: Router,
    private requestCancellation: RequestCancellationService,
    public dataAccess: DataAccessService,
    private stateService: ProfileStateService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.dataAccess.getProfileStoryResults();
  }
  
  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
    this.requestCancellation.cancelAllProfileSearchRequests();
    this.dataAccess.resetProfileStateByField([ 'blogResults', 'pagination' ]);
  }

  async navigateToTrip({ pageTitle, published }: Partial<BasicInfoData>): Promise<void> {

    const selfProfile = await firstValueFrom(this.stateService.selfProfile$);

    if (!published && !selfProfile) {
      this.snackbar.open({ message: 'Sorry, this blog is private and cannot be viewed.', duration: 5000 });
      return;
    }

    pageTitle && this.router.navigateByUrl(`/${currentLang()}/travel-story/${pageTitle}`);
  }

}
