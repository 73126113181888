import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { currentLang } from '@hiptraveler/common';

@Injectable()
export class ActivityInputOverlayService {

  constructor(
    private router: Router
  ) { }

  get selectionList(): boolean {
    const url = this.router.url;
    return url.includes(`/${currentLang()}`)
      || url.includes(`/${currentLang()}/experiences`)
      || url.includes(`/${currentLang()}/thingstodo`);
  }
  
}
