import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { LocationData, RegistrationFormDestination } from '@hiptraveler/data-access/api';
import { RegistrationFormStateService } from '../common';

@Pipe({
  name: 'locationSelected'
})
export class LocationSelectedPipe implements PipeTransform {

  selected$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private stateService: RegistrationFormStateService
  ) { }

  transform(value: LocationData): Observable<boolean> {

    this.stateService.destinationsForm.valueChanges.pipe(
      map((form: RegistrationFormDestination) => form.id === value.id)
    ).subscribe(v => this.selected$$.next(v));

    return this.selected$$.asObservable();
  }

}
