import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@hiptraveler/ui/buttons';
import { StarRatingsModule } from '@hiptraveler/ui/star-ratings';
import { ItineraryActionModule } from '@hiptraveler/features/itinerary-action';
import { ParseCurrencyPipeModule, ToggleFavoriteDirectiveModule } from '@hiptraveler/common';

import { FeatureCardStateService } from './feature-card-state.service';

import { FeatureCardComponent } from './feature-card.component';
import { FeatureCardComponents, FeatureCardExportComponents } from './feature-card-ui';
import { FeatureCardDirectives, FeatureCardExportDirectives } from './feature-card-directive';
import { FeatureCardPipes, FeatureCardExportPipes } from './feature-card-pipe';

const MODULES = [
  CommonModule,
  MatRippleModule,
  MatIconModule,
  MatProgressSpinnerModule,
  TranslateModule
];

const LIBRARIES = [
  ButtonsModule,
  StarRatingsModule,
  ParseCurrencyPipeModule
];

const PROVIDERS = [
  FeatureCardStateService
];

const COMPONENTS = [
  FeatureCardComponent
];

@NgModule({
  imports: [
    MODULES,
    LIBRARIES
  ],
  declarations: [
    COMPONENTS,
    FeatureCardComponents,
    FeatureCardDirectives,
    FeatureCardPipes
  ],
  providers: [
    PROVIDERS
  ],
  exports: [
    ItineraryActionModule,
    ToggleFavoriteDirectiveModule,
    FeatureCardComponent,
    FeatureCardExportComponents,
    FeatureCardExportDirectives,
    FeatureCardExportPipes
  ]
})
export class FeatureCardModule { }
