import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import { MainSelectionComponent } from './main-selection.component';
import { HeightResizerDirective } from './height-resizer.directive';
import { MessageChatDynamicDirective } from './message-chat-dynamic.directive';
import { OptionActionDirective } from './selection-option-action.directive';

const MODULES = [
  CommonModule,
  MatRippleModule
];

const COMPONENTS = [
  MainSelectionComponent
];

const DIRECTIVES = [
  HeightResizerDirective,
  MessageChatDynamicDirective,
  OptionActionDirective
];

@NgModule({
  imports: [
    MODULES
  ],
  declarations: [
    COMPONENTS,
    DIRECTIVES
  ],
  exports: [
    MainSelectionComponent
  ]
})
export class MainSelectionModule { }
