<div class="main-menu">

  <div class="main-menu--navbar">

    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <p translate="exp-finder.main-menu"></p>

  </div>

  <div class="main-menu--display">
    
    <ng-container [ngSwitch]="service.stateService.mainMenuView$$ | async">
      <ng-container *ngIf="!service.isWidget">
        <plan-a-trip *ngSwitchCase="'plan_a_trip'"></plan-a-trip>
      </ng-container>
      <new-travelogue (actionClicked)="service.optionSelected('new_travelogue')" *ngSwitchCase="'my_travelogues'"></new-travelogue>
      <ng-container *ngSwitchDefault>

        <img
          class="main-menu--display-photo object"
          [src]="customStyles?.tripPlannerSvg"
          *ngIf="((service.brandCampaign$ | async)?.campaignCustom?.customCss) as customStyles;else defaultImageView">

        <ng-template #defaultImageView>
          <div class="main-menu--display-photo background" [ngStyle]="{ 'background-image': defaultImage }"></div>
        </ng-template>

      </ng-container>
    </ng-container>

  </div>

  <div class="main-menu--selection-list">

    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('profile')" *ngIf="service.isWidget">
      <mat-icon svgIcon="experience-finder-screen-shared.profile-icon-svg"></mat-icon>
      <p translate="exp-finder.profile"></p>
    </div>

    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('new_trip')">
      <mat-icon svgIcon="experience-finder-screen-shared.add-icon-svg"></mat-icon>
      <p translate="exp-finder.new-trip"></p>
    </div>

    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('plan_a_trip')" [ngClass]="{ active: (service.stateService.mainMenuView$$| async) === 'plan_a_trip' }">
      <mat-icon svgIcon="experience-finder-screen-shared.trips-icon-svg"></mat-icon>
      <p translate="exp-finder.my-trips"></p>
    </div>
    
    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('my_favorites')">
      <mat-icon svgIcon="experience-finder-screen-shared.favorites-icon-svg"></mat-icon>
      <p translate="exp-finder.my-favorites"></p>
    </div>
    
    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('new_travelogue')" *ngIf="!service.isWidget">
      <mat-icon svgIcon="experience-finder-screen-shared.new-icon-svg"></mat-icon>
      <p translate="exp-finder.new-travelogue"></p>
    </div>
    
    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('my_travelogues')" [ngClass]="{ active: (service.stateService.mainMenuView$$ | async) === 'my_travelogues' }" *ngIf="!service.isWidget">
      <mat-icon svgIcon="experience-finder-screen-shared.my-icon-svg"></mat-icon>
      <p translate="exp-finder.my-travelogues"></p>
    </div>

    <div class="main-menu--selection" mat-ripple (click)="service.optionSelected('logout')" *ngIf="service.isWidget">
      <mat-icon svgIcon="experience-finder-screen-shared.logout-icon-svg"></mat-icon>
      <p translate="exp-finder.logout"></p>
    </div>

  </div>

</div>
