import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { BrandCampaign } from '@hiptraveler/data-access/api';
import { AuthState } from '@hiptraveler/data-access/auth';
import { BrandState } from '@hiptraveler/data-access/brand';
import { NavbarControlStateService, LOCATION } from '@hiptraveler/common';

@Injectable()
export class NavbarService {

  @Select(AuthState.authenticated) authenticated$: Observable<boolean>;
  @Select(BrandState.brandCampaign) brandCampaign$: Observable<unknown | null>;
  @Select(BrandState.hiptravelerBrand) hiptravelerBrand$: Observable<BrandCampaign>;

  constructor(
    @Inject(LOCATION) private location: any,
    private router: Router,
    private translateService: TranslateService,
    public navbarControl: NavbarControlStateService,
  ) { }

  changeLanguage(language?: string): void {
    if (!language) return;
    const previousLang = this.translateService.currentLang.slice(0);
    this.translateService.use(language).subscribe(() => {
      this.router.navigate([ `/${this.location.pathname.replace(previousLang, language)}` ]);
    });
  }

}
