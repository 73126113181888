import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[animateSendButton]'
})
export class AnimateSendButtonDirective {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostListener('mousedown')
  mousedown(): void {
    this.renderer.addClass(this.element, 'active');
  }

  @HostListener('mouseup')
  mouseup(): void {
    this.renderer.removeClass(this.element, 'active');
  }

  @HostListener('touchstart')
  touchstart(): void {
    this.renderer.addClass(this.element, 'active');
  }

  @HostListener('touchend')
  touchend(): void {
    this.renderer.removeClass(this.element, 'active');
  }

}
