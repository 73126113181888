import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

import { FormComponent } from '../experience-finder-screen/plan-trip/form/form.component';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';

@Component({
  selector: 'experience-finder-mobile',
  templateUrl: './experience-finder.component.html',
  styleUrls: ['./experience-finder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'experience-finder-mobile' }
})
export class ExperienceFinderComponent {

  @ViewChild(FormComponent) tripPlanningForm: FormComponent;

  constructor(
    public stateService: ExperienceFinderStateService
  ) { }
  
  clearTravelStyle(): void {
    this.stateService.selectedFilters$$.next([]);
  }

}
