import { ElementRef, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { ItineraryAction, ItineraryState } from '@hiptraveler/data-access/itinerary';
import { SnackbarService } from '@hiptraveler/snackbar';
import { PlaceResult, SearchPageControlStateService, currentLang, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';
import { ComponentStateService } from '../../common';

@Injectable()
export class ActivityDayHeaderService {

  updatingLocation: boolean;

  constructor(
    private store: Store,
    private searchPageControl: SearchPageControlStateService,
    private componentState: ComponentStateService,
    public snackbar: SnackbarService
  ) { }

  /**
   * Monitors the position of an element relative to the scroll position and updates the active day state accordingly.
   * 
   * @param scrollId - The unique identifier for the element being tracked, likely related to the day it represents.
   * @param index - The index of the element within a collection, potentially used for fine-tuning position calculations.
   * @param element - The ElementRef for the HTMLDivElement to observe.
   */
  observeElementPosition(scrollId: string, element: ElementRef<HTMLDivElement>): void {

    const activityDay = +scrollId.split('-').reverse()[0];
    const targetElement = element.nativeElement.firstElementChild?.firstElementChild;

    let position = targetElement!.getBoundingClientRect().top;
    if (activityDay > 1) {
      
      const stepOffset = 43 * activityDay - 1;
      const offset = stepOffset > 172 ? 172 : stepOffset;

      position = targetElement!.getBoundingClientRect().top - offset;
    }

    const activeDayConfig = this.componentState.subscription$$.value?.activeDayConfig?.slice(0) || [];
    activeDayConfig.push({ activityDay, position });
    this.componentState.patch({ activeDayConfig: activeDayConfig });
  }

  /**
   * Updates the location of an activity within an itinerary, presumably based on user input or API results.
   * 
   * @param response - The PlaceResult object containing details about the new location.
   * @param day? - The optional day number for which to update the location.
   */
  async updateNewLocation(response: PlaceResult, day?: number): Promise<void> {
    try {

      if (!day) return;
      
      this.searchPageControl.featureCardProcessing$$.next(true);

      this.updatingLocation = true;
      await firstValueFrom(this.store.dispatch(new ItineraryAction.UpdateItineraryActivity({
        action: 'update-location',
        param: getWindowRef()[requestEndpointParamValue],
        id: this.store.selectSnapshot(ItineraryState.basicInfo)?.id || '',
        lang: currentLang(),
        locId: response?.place_id,
        location: response?.formatted_address,
        day
      })));

    } catch (response: any) {
      this.snackbar.open({ message: 'Something went wrong. Please try again.', duration: 5000 });
    } finally {
      this.updatingLocation = false;
      this.searchPageControl.featureCardProcessing$$.next(false);
    }
  }

}
