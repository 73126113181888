import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivityDateData } from '@hiptraveler/data-access/api';

@Component({
  selector: 'activity-content',
  template: '<div class="activity-content-wrapper" [innerHTML]="activity.dayContent | displayText"></div>',
  styleUrls: ['./activity-content.component.scss'],
  host: { class: 'activity-content' },
  encapsulation: ViewEncapsulation.None,
})
export class ActivityContentComponent implements OnInit {

  @Input() activity: ActivityDateData;

  constructor(
    private element: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.activity.dayContent || (this.element.nativeElement.style.display = 'none');
  }

}
