import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RegexPattern, brandCampaignId, clientVID } from '@hiptraveler/common';
import { passwordMatchValidator } from './form-validators';

@Injectable()
export class ProfileStateFormService {

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder
  ) { }

  profileForm: FormGroup = this.fb.group({
    id: [ '' ],
    firstName: [ '' ],
    lastName: [ '' ],
    userDesc: [ '' ],
    travelQuote: [ '' ],
    homeTown: [ '' ],
    currLoc: [ '' ],
    rStatus: [ '' ],
    dob: [ '' ],
    action: [ 'profileContent' ],
    vId: [ clientVID() ]
  });

  passwordAssistanceForm: FormGroup = this.fb.group({
    password: [ '', [ Validators.pattern(RegexPattern.password) ] ],
    confirm_password: [ '', [ Validators.pattern(RegexPattern.password) ] ],
    vId: [ clientVID() ]
  }, { validators: passwordMatchValidator });

  notificationForm: FormGroup = this.fb.group({
    dnsmpi: [ false ]
  });

  activityDialogForm: FormGroup = this.fb.group({
    campgnId: [ brandCampaignId() ],
    id: [ 'untitled' ],
    title: [ '', [ Validators.required ] ],
    address: [ '', [ Validators.required ] ],
    locationMap: this.fb.group({
      city: [ '', [ Validators.required ] ],
      country: [ '', [ Validators.minLength(4) ] ],
      id: [ '' ],
      state: [ '' ]
    }),
    desc: [ '' ],
    category: [ 'Hip Adventure' ],
    amenities: this.fb.array([]),
    cover: this.fb.group({
      imgUrl: [ '' ],
      imgThumbnail: [ '' ],
      hasCover: [ true ]
    }),
    highlights: this.fb.array([]),
    inclusions: this.fb.array([]),
    exclusions: this.fb.array([]),
    tourMedia: this.fb.array([]),
    offer: [ '' ],
    openingHours: [ '' ],
    closingHours: [ '' ],
    gygTour: this.fb.group({
      tourId: [ 'untitled' ],
      price: this.fb.group({
        amount: [ '' ],
        description: [ '' ],
        payment_method: [ '' ]
      }),
      duration: this.fb.group({
        value: [ '' ],
        unit: [ 'minute' ]
      }),
      amenities: this.fb.array([])
    }),
    otherInfo: this.fb.group({
      booking: [ '' ],
      website: [ '' ],
      phone: [ '' ],
      email: [ '' ],
      twitter: [ '' ],
      facebook: [ '' ],
      instagram: [ '' ],
      whatsapp: [ '' ],
      pinterest: [ '' ],
      wiki: [ '' ],
      licence: [ '' ]
    }),
    yelpData: this.fb.group({
      yelpId: [ 'untitled' ],
      price: [ '$' ],
      amenities: this.fb.array([]),
      cuisine: this.fb.array([])
    }),
    es: this.fb.group({
      desc: [ '' ],
      vendorInfo: [ '' ],
      vendorType: [ '' ],
      offer: [ '' ],
      title: [ '' ],
      durationUnit: [ '' ],
      highlights: this.fb.array([]),
      inclusions: this.fb.array([]),
      exclusions: this.fb.array([]),
      price: this.fb.group({
        amount: [ '' ],
        discount: [ '' ],
        description: [ '' ]
      }),
    }),
    route: [ 'site' ]
  });

}
