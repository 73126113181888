import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ItineraryService } from './itinerary.service';
import { ItineraryDataAccessService, TravelOptionsComponent } from '@hiptraveler/features/itinerary';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { ComposePrivateNoteDialogActionService } from '@hiptraveler/dialogs/compose-private-note-dialog';
import { CommonService } from './common.service';
import { AppListenerService, ToolbarOption, currentLang, getWindowRef, requestEndpointParamValue } from '@hiptraveler/common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

const STORY_SCROLL_ID = 'itinerary-page-story-section';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['../../features/itinerary/itinerary-page-styles.scss'],
  viewProviders: [ ItineraryService, CommonService, ItineraryDataAccessService, AuthDialogActionService, ComposePrivateNoteDialogActionService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(), opacityFadeIn(2000) ]
})
export class ItineraryComponent implements OnInit {

  @ViewChild(TravelOptionsComponent) travelOptions: TravelOptionsComponent;

  selections: ToolbarOption[];

  constructor(
    private i18n: TranslateService,
    public service: ItineraryService,
    public common: CommonService,
    private appListener: AppListenerService
  ) { }

  ngOnInit(): void {
    getWindowRef()[requestEndpointParamValue] = 'itinerary';
    this.service.observe();
    this.selectionUIObserver();
    setTimeout(() => this.common.focusMode('on'));

    this.appListener.languageLoaded$.subscribe(() => {
      this.selections = [
        { name: this.getText('story'), scrollToViewId: STORY_SCROLL_ID },
        { name: this.getText('itinerary'), scrollToViewId: 'itinerary-page-itinerary-section' },
        { name: this.getText('travel'), scrollToViewId: 'itinerary-page-travel-section' },
      ];
    });

    this.service.checklist$.subscribe((value: boolean) => {
      if (this.selections.find(e => e.scrollToViewId?.includes('itinerary-page-checklist-section'))) return;

      const toolbarOption: ToolbarOption = { name: this.getText('checklist'), scrollToViewId: 'itinerary-page-checklist-section' };

      if (value) {
        this.selections.push(toolbarOption);
        return;
      }

      const index = this.selections.findIndex(option => 
        option.name === toolbarOption.name && 
        option.scrollToViewId === toolbarOption.scrollToViewId
      );

      if (index !== -1) {
          this.selections.splice(index, 1);
      }
    });

    this.common.owner$.subscribe(() => {
      this.selections = this.selections.filter(e => !e.scrollToViewId?.includes('itinerary-page-checklist-section'))
    });
  }

  /**
   * Observes story content components.
   * Checks if the component has or has no content for UI display.
   * Sets the scroll view ID to hidden if no content is present.
   * 
   * @returns {void}
   */
  selectionUIObserver(): void {
    this.selections && this.service.hasStoryContent$.subscribe((state: boolean) => {
      this.selections[0].scrollToViewId = state ? STORY_SCROLL_ID : 'hidden';
      this.selections = this.selections;
    });
  }

  private getText(value: string): string {
    return this.i18n.translations?.[currentLang()]?.['view-page']?.[value] || value;
  }

}
