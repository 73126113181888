import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthorData } from '@hiptraveler/data-access/api';
import { AuthDialogActionService } from '@hiptraveler/dialogs/auth-dialog';
import { UserProfileService } from './user-profile.service';
import { currentLang, profilePlaceholder } from '@hiptraveler/common';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: { class: 'user-profile' },
  viewProviders: [ UserProfileService, AuthDialogActionService ],
  animations: [ opacityFade(150) ]
})
export class UserProfileComponent implements OnInit {

  @Input() owner?: boolean;
  @Input() author?: AuthorData;

  profilePlaceholder: string = profilePlaceholder;

  constructor(
    private router: Router,
    public service: UserProfileService
  ) { }

  ngOnInit(): void {
    this.service.buttonVisibilityObserver();
  }
  
  navigateToUserProfile(): void {
    this.author?.authorTitle
      && this.router.navigateByUrl(`/${currentLang()}/profile/${this.author?.authorTitle}/stories`);
  }

}
