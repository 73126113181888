import { Component, OnDestroy, OnInit } from '@angular/core';

import { RequestCancellationService } from '@hiptraveler/common';
import { skeletons, DataAccessService } from '../../../common';
import { opacityFade, opacityFadeIn } from '@hiptraveler/animations';

@Component({
  selector: 'itineraries-tab',
  templateUrl: './itineraries-tab.component.html',
  animations: [ opacityFade(), opacityFadeIn() ]
})
export class ItinerariesTabComponent implements OnInit, OnDestroy {

  skeletons: number[] = skeletons;

  constructor(
    private requestCancellation: RequestCancellationService,
    public dataAccess: DataAccessService
  ) { }

  ngOnInit(): void {
    this.dataAccess.getItineraryResults();
  }
  
  ngOnDestroy(): void {
    this.dataAccess.subscription$.next();
    this.requestCancellation.cancelAllProfileSearchRequests();
    this.dataAccess.resetProfileStateByField([ 'itineraryResults', 'pagination' ]);
  }

}
