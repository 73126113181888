import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

import { SocialMedia } from '../auth.model';
import { PromiseReject, PromiseResolve } from '@hiptraveler/common';

@Injectable()
export class SocialMediaAuthService {

  constructor(
    private socialAuth:SocialAuthService
  ) { }

  /**
   * Initiates a Facebook login request.
   *
   * This function returns a Promise that resolves with the Facebook authentication response
   * if the Facebook SDK is loaded and the user successfully logs in. If the Facebook SDK is
   * not loaded or if the user cancels the login process, the Promise is rejected with an
   * appropriate error message.
   *
   * @returns {Promise<fb.AuthResponse>} A Promise that resolves with the Facebook authentication response.
   * @throws {string} If the Facebook SDK is not loaded or if the user cancels the login process.
   */
  private get facebookLoginRequest(): Promise<fb.AuthResponse> {
    return new Promise<fb.AuthResponse>((resolve: PromiseResolve<fb.AuthResponse>, reject: PromiseReject) => {

      if (typeof FB === 'undefined') {
        reject('Facebook SDK not loaded.');
        return;
      }

      FB.login((response: fb.StatusResponse) => {
        if (!response.authResponse) return;
        resolve(response.authResponse);
      }, { scope: 'public_profile,email' });

    });
  }

  /**
   * Initiates a Google login request.
   *
   * This function returns a Promise that resolves with the Google authentication response
   * if the Google SDK is loaded and the user successfully logs in. If the Google SDK is
   * not loaded or if there are issues with the login process, the Promise is rejected with an
   * appropriate error message.
   *
   * @returns {Promise<any>} A Promise that resolves with the Google authentication response.
   * @throws {string} If the Google SDK is not loaded or if there are issues with the login process.
   */
  private get googleLoginRequest(): Promise<SocialUser> {
    return new Promise<SocialUser>((resolve: PromiseResolve<SocialUser>, reject: PromiseReject) => {
      
      this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID)
        .then(resolve.bind(this))
        .catch(reject.bind(this));

    });
  }

  /**
   * Initiates social media authentication using a pop-up window.
   *
   * This function initiates the authentication process for the specified social media platform
   * using a pop-up window. It returns a Promise that resolves with the authentication response
   * if the user successfully logs in. If there are issues with the authentication process,
   * the Promise may be rejected with an appropriate error message.
   *
   * @param {SocialMedia} socialMedia - The social media platform to authenticate with ('facebook' or 'google').
   * @returns {Promise<T>} A Promise that resolves with the authentication response.
   * @throws {string} If there are issues with the authentication process.
   */
  authenticateWithPopUp<T = any>(socialMedia: SocialMedia): Promise<T> {
    switch (socialMedia) {
      case 'facebook': {
        return this.facebookLoginRequest as Promise<any>;
      }
      case 'google': {
        return this.googleLoginRequest as Promise<any>;
      }
    }
  }
  
}
