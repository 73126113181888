import { Directive, ElementRef, NgModule, OnInit, Renderer2 } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map, merge, Observable, startWith, takeUntil } from 'rxjs';

import { FiltersService } from './filters.service';
import { ExperienceFinderStateService } from '@hiptraveler/features/experience-finder';
import { currentLang, getPathname, SearchLocationData, SearchLocationService } from '@hiptraveler/common';

@Directive({
  selector: '[formStyles]'
})
export class FormStylesDirective implements OnInit {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private router: Router,
    private renderer: Renderer2,
    private service: FiltersService,
    private searchLocation: SearchLocationService,
    private efStateService: ExperienceFinderStateService
  ) { }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngOnInit(): void {

    this.searchLocationClassObserver();
    
    this.zIndexStore$.subscribe((zIndex: number) => {
      this.renderer.setStyle(this.element, 'z-index', zIndex);
    });

    this.rootPageListener$.subscribe((state: boolean) => {
      const property = state ? 'addClass' : 'removeClass';
      this.renderer[property](this.element, 'root-page');
    });

    setTimeout(() => this.renderer.setStyle(this.element, 'z-index', 900), 100);
  }

  searchLocationClassObserver(): void {

    const setClassLocationCheck = () => {
      if (this.searchLocation.data) {
        if (!this.router.url.includes(`/${currentLang()}/search`)) return;
        this.renderer.addClass(this.element, 'search-page-location');
      } else {
        this.renderer.removeStyle(this.element, 'search-page-location');
      };
    }

    setClassLocationCheck();

    this.searchPageListener$.subscribe((state: boolean) => {
      if (state) {
        setClassLocationCheck();
      } else {
        this.renderer.removeClass(this.element, 'search-page');
      }
    });

    this.searchLocation.searchLocation$$.subscribe((state: SearchLocationData | undefined) => {
      if (state && !this.router.url.includes(`/${currentLang()}/search`)) return;
      const property = !!state ? 'addClass' : 'removeClass';
      this.renderer[property](this.element, 'search-page-location');
    });
  }

  private get currentUrl$(): Observable<string> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      map(e => getPathname(e?.url || '')),
      startWith(getPathname(this.router.url || '')),
      takeUntil(this.service.subscription$)
    );
  }

  private get rootPageListener$(): Observable<boolean> {
    return this.currentUrl$.pipe(
      map(url => getPathname(url || '') === `/${currentLang()}`),
      takeUntil(this.service.subscription$)
    );
  }

  private get searchPageListener$(): Observable<boolean> {
    return this.currentUrl$.pipe(
      map(url => getPathname(url || '').includes(`/${currentLang()}/search`)),
      takeUntil(this.service.subscription$)
    );
  }

  private get zIndexStore$(): Observable<number> {
    return merge(
      this.service.overlayState$.pipe(
        map(state => (state ? 1000 : 900)),
        takeUntil(this.service.subscription$)
      ),
      this.efStateService.overlayState$.pipe(
        map(state => (state ? 900 : 1000)),
        takeUntil(this.service.subscription$)
      )
    ).pipe(takeUntil(this.service.subscription$));
  };

}

@NgModule({
  declarations: [ FormStylesDirective ],
  exports:      [ FormStylesDirective ]
})
export class FormStylesDirectiveModule { }
