import { Directive, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { CampaignCustom } from '@hiptraveler/data-access/api';
import { BrandState } from '@hiptraveler/data-access/brand';
import { chromaNamespaceKey, getWindowRef } from '@hiptraveler/common';
import { chromaClient } from '@hiptraveler/core/chroma';

interface CallbackStyle { blub2: string; blub3: string };

@Directive({
  selector: '[blubStyles]'
})
export class BlubStylesDirective implements OnInit {

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store
  ) { }

  ngOnInit(): void {
    const element = this.element.nativeElement;
    const blub2 = element.classList.contains('blub-2');
    const blub3 = element.classList.contains('blub-3');

    blub2 && this.brandStyles(({ blub2 }) => {
      element.style.background = blub2;
    })

    blub3 && this.brandStyles(({ blub3 }) => {
      element.style.background = blub3;
    })
  }

  brandStyles(callback: (styles: CallbackStyle) => void): void {
    this.store.select(BrandState.campaignCustom).subscribe(async (campaignCustom: Partial<CampaignCustom> | null) => {

      await chromaClient();
      const chroma = getWindowRef()?.[chromaNamespaceKey];
      const primaryColorHex = campaignCustom?.colorPalette?.mainColor || '#00a6ff';
      const primaryColorRgb = chroma(primaryColorHex).rgb() || [ 0, 166, 255 ];
      const [ r, g, b ] = primaryColorRgb;

      const blub2PrimaryColor = chroma.rgb(r, g, b).darken(0.13).hex() || '#0090de';
      const blub3PrimaryColor = chroma.rgb(r, g, b).darken(0.20).hex() || '#0085cc';

      callback({ blub2: blub2PrimaryColor, blub3: blub3PrimaryColor });
    }).unsubscribe();
  }

}
