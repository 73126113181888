import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateChange } from 'ng-lazyload-image';

import { HomepageBannerService } from './homepage-banner.service';
import { WidgetStateService } from '@hiptraveler/common';

@Component({
  selector: 'homepage-banner',
  templateUrl: './homepage-banner.component.html',
  styleUrls: ['./homepage-banner.component.scss'],
  host: { class: 'search-page-root-homepage-banner' },
  encapsulation: ViewEncapsulation.None,
  viewProviders: [ HomepageBannerService ],
})
export class HomepageBannerComponent implements OnInit {

  loader: boolean = true;

  constructor(
    public widgetService: WidgetStateService,
    public service: HomepageBannerService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.service.observeScrollChanges();
  }

  lazyloadStateChangeFn(event: StateChange): void {
    switch (event.reason) {
      case 'setup':
      case 'observer-emit':
      case 'start-loading': {
        this.loader = true;
        break;
      }
      case 'mount-image':
      case 'loading-succeeded':
      case 'loading-failed': {
        this.loader = false;
        break;
      }
    }
    this.cdRef.detectChanges();
  }

}
