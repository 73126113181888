<dialog-close-button></dialog-close-button>

<h1 translate="reg-form.custom"></h1>

<mat-form-field appearance="outline">
  <mat-label>{{ 'reg-form.cert-type' | translate }}</mat-label>
  <input matInput name="certificate" [placeholder]="'reg-form.placeholder-cert' | translate" type="text" [(ngModel)]="certificate">
</mat-form-field>

<mat-form-field appearance="outline">
  <mat-label>{{ 'reg-form.cert-id' | translate }}</mat-label>
  <input matInput name="certificate-id" [placeholder]="'reg-form.placeholder-id' | translate" type="text" [(ngModel)]="id">
</mat-form-field>

<ui-action-button size="medium" (click)="saveCustomCertificate()">{{ 'reg-form.button-text-save' | translate }}</ui-action-button>
