import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ClientActionService } from './client-action.service';

@Component({
  selector: 'client-action',
  templateUrl: './client-action.component.html',
  host: { class: 'experience-finder-ai--host-client-info-client-action client-info-client-action' },
  viewProviders: [ ClientActionService ],
  encapsulation: ViewEncapsulation.None
})
export class ClientActionComponent {

  @Input() index: number;
  @Input() leftButtonLimit: boolean;

  constructor(
    public service: ClientActionService
  ) { }

}
