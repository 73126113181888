import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, filter, firstValueFrom, takeUntil, tap } from 'rxjs';

import { ProfileResultData } from '@hiptraveler/data-access/api';
import { SearchAction, SearchState } from '@hiptraveler/data-access/search';
import { LeafletMapControlStateService } from '@hiptraveler/features/leaflet-map';
import { AppListenerService, SearchLocationData, SearchLocationService, WINDOW, queryStringToObject, cleanObject, RequestCancellationService } from '@hiptraveler/common';
import { DataAccess, DataService } from './data.service';

@Injectable()
export class ProfileListService extends DataService implements DataAccess {

  @Select(SearchState.communities) communities$: Observable<ProfileResultData[]>;

  constructor(
    @Inject(WINDOW) private window: any,
    private route: ActivatedRoute,
    private store: Store,
    private requestCancellation: RequestCancellationService,
    appListener: AppListenerService,
    searchLocation: SearchLocationService,
    leafletControl: LeafletMapControlStateService
  ) {
    super(appListener, searchLocation, leafletControl);
  }

  ngOnDestroy(): void {
    this.subscription$.next();
  }

  get searchResults$(): Observable<ProfileResultData[] | null> {
    return this.getSearchResultData(this.store.select(SearchState.communities));
  }

  async initializeDataAccess(): Promise<void> {
    await this.getBrandCampaign(this.store);
    this.getResultsByLocationData();
    this.locationChanges(this);
    this.profileListResultLocations(this.communities$, 'profile');
    this.observeLoadMoreEventClicks();
  }

  async getResultsByLocationData(): Promise<void> {
    await this.resetSearchState(this, [ 'pagination' ]);
    this.getSearchResultsByLocation(this.searchLocation.data);
  }

  getSearchResultsByLocation(data: SearchLocationData | null): void {
    this.requestCancellation.cancelAllSearchRequests();

    this.dispatchActionRequest(async () => {
      await firstValueFrom(this.store.dispatch(new SearchAction.GetCommunitiesByQuery({
        ...cleanObject(data), ...queryStringToObject(this.window.location.search)
      })));
      this.appListener.loadMoreEventState$$.next(false);
    });
  }

  observeLoadMoreEventClicks(): void {
    this.appListener.loadMoreEventState$.pipe(
      filter(Boolean),
      tap(() => {
        const data = this.searchLocation.data || {} as any;
        const currentPage = this.store.selectSnapshot(SearchState.pagination)?.page;
        currentPage && this.getSearchResultsByLocation({
          ...data,
          name: this.route.snapshot.params['location'] || '',
          searchCnt: `${currentPage + 1}`
        })
      }),
      takeUntil(this.subscription$)
    ).subscribe();
  }

}
