import { Directive, HostListener } from '@angular/core';

import { LocationInputOverlayActionService } from './location-input-overlay-action.service';

@Directive({
  selector: '[locationInputOverlay]',
  providers: [ LocationInputOverlayActionService ]
})
export class LocationInputOverlayDirective {

  constructor(
    private overlayService: LocationInputOverlayActionService
  ) { }

  @HostListener('click')
  hostClicked(): void {
    this.overlayService.open();
  }

}
